import React from 'react';
import { GU } from '@aragon/ui';
import { Box, InnerBox, BoxLine } from '../../../../components';
import { displayFullDate } from '../../../../utils/helpers/common';
import { useTranslation } from 'react-i18next';

export const CompanyRegistrationBox = ({ data }) => {
  const { i18n } = useTranslation();
  return (
    <Box heading={'Registration details'} padding={0}>
      <InnerBox paddingTop={GU} paddingBottom={GU}>
        <BoxLine
          title={'Registration date'}
          data={displayFullDate(new Date(data.registrationDate), true, i18n.language)}
        />
        <BoxLine title={'Registration number'} data={data.registrationNumber} />
        <BoxLine title={'LEI'} data={data.lei} />
        <BoxLine title={'Country of registration'} data={data.countryOfRegistration} />
        <BoxLine title={'Tax residency'} data={data.taxResidency} />
        <BoxLine title={'Tax Number'} data={data.taxNumber} />
      </InnerBox>
    </Box>
  );
};
