import React, { useEffect } from 'react';
import { SubmitTransferPage } from '../../../../pages/investor';
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '../../../../store/user';
import { history } from '../../../../routing';
import { investorApi } from '../../../../api/investor';

export const SubmitTransferContainer = () => {
  const dispatch = useDispatch();
  const { newTransfer } = useSelector((state) => state.wallet);

  useEffect(() => {
    dispatch(userActions.hideAppContainer());

    return () => {
      dispatch(userActions.showAppContainer());
    };
  }, []);

  const sendCode = (type) => {
    investorApi.transferSendCode(type).then(console.log);
  };

  const submitTransfer = (code) => {
    investorApi.submitTransfer(code).then((res) => {
      if (res.status === 200) {
        investorApi.makeTransfer(newTransfer).then((res) => {
          if (res.status === 200) {
            history.push('/wallet');
          }
        });
      }
    });
  };

  return <SubmitTransferPage sendCode={sendCode} submitTransfer={submitTransfer} />;
};
