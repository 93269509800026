import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { InvestorProfile } from '../../pages/investor';
import { ResetPassword } from '../../pages';
import { DocumentRequest } from '../../containers/investor';
import { Questionnaire, SumsubQuestionnaire, Product } from '../../modules';
import { ReferralProfileView } from '../../pages/investor/ReferralProfile';

export const InvestorRoutes = () => {
  return (
    <Switch>
      {/*<Route exact path="/dashboard" component={DashboardContainer} />*/}
      {/*<Route exact path="/assets" component={AssetsContainer} />*/}
      {/*<Route exact path="/assets/available" component={AvailableAssetsContainer} />*/}
      {/*<Route exact path="/assets/self" component={MyAssetsContainer} />*/}
      {/*<Route exact path="/assets/:id" component={AssetInfoContainer} />*/}
      {/*<Route exact path="/orders" component={OrdersContainer} />*/}
      {/*<Route exact path="/order/:id" component={OrderDetailsContainer} />*/}
      {/*<Route exact path="/wallet" component={WalletContainer} />*/}
      {/*<Route exact path="/submit-transfer" component={SubmitTransferContainer} />*/}
      {/*<Route exact path="/transaction/:id" component={TransactionDetailsContainer} />*/}
      {/*<Route exact path="/deals" component={DealsContainer} />*/}
      {/*<Route exact path="/deal/:id" component={DealDetailsContainer} />*/}
      {/*<Route exact path="/checkout" component={CheckoutContainer} />*/}
      {/*<Route exact path="/tier-1" component={Tier1Page} />*/}

      {/*<Route exact path="/tier-2" component={Tier2Page} />*/}
      {/*<Route exact path="/tier-3" component={Tier3Page} />*/}

      {/*<Route exact path="/wishlist" component={InvestorWishlist} />*/}
      {/*<Route exact path="/operationdetail" component={OperationDetail} />*/}
      {/*<Route exact path="/upcomingoffering" component={UpcomingOffering} />*/}

      <Route exact path='/profile' component={InvestorProfile} />
      <Route exact path='/questionnaire/:id' component={Questionnaire} />
      <Route exact path='/product/:id' component={Product} />
      <Route exact path='/sumsub-verification' component={SumsubQuestionnaire} />
      <Route exact path={'/documents/request/:id'} component={DocumentRequest} />
      <Route exact path='/referral/:id' component={ReferralProfileView} />
      <Route exact path='/referral/:referralId/product/:id' render={() => <Product referralView />} />
      <Route exact path='/referral/:referralId/questionnaire/:id' render={() => <Questionnaire referralView />} />
      <Route exact path='/referral/:referralId/documents/request/:id' render={() => <DocumentRequest referralView />} />

      <Route exact path='/change-password' component={ResetPassword} />

      <Redirect to={'/profile'} />
    </Switch>
  );
};
