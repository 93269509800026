import React from 'react';
import { GU } from '@aragon/ui';
import { history } from '../../routing';
import { OptionCard, RegContainer } from '../../components';
import { Container, Row } from '../../ui';
import CreateAccountIcon from '../../assets/entry-pages/create-account.svg';
import LoginAccountIcon from '../../assets/entry-pages/login-account.svg';
import project_logo_spirit from '../../assets/logo-spirit-capital.png';
import { useTranslation } from 'react-i18next';

export const EnterPage = () => {
  const { t } = useTranslation();

  return (
    <RegContainer type='centered'>
      <Container marginBottom={5 * GU}>
        <img src={project_logo_spirit} width={200} />
      </Container>

      <Row justifyContent={'space-between'}>
        <OptionCard
          title={t('Create a new \nverification account')}
          onClick={() => history.push('/registration/investor')}
          icon={CreateAccountIcon}
        />

        <OptionCard
          title={t('Login in existing \nverification account')}
          onClick={() => history.push('/login')}
          icon={LoginAccountIcon}
        />
      </Row>
    </RegContainer>
  );
};
