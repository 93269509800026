import React, { useMemo } from 'react';
import { Flex, Select } from 'antd';

const productsTypeList = [
  {
    label: 'Gold',
    value: 'Gold'
  },
  {
    label: 'Renewable Energy',
    value: 'Renewable Energy'
  },
  {
    label: 'Real estate investment (Bond)',
    value: 'Real estate investment (Bond)'
  }
];

function removeDuplicates(arr, key) {
  const seen = new Map();
  return arr.filter((obj) => {
    const value = obj[key];
    const trimmedValue = value !== null && value !== undefined ? String(value).trim() : value;
    if (seen.has(trimmedValue)) {
      return false;
    }
    seen.set(trimmedValue, true);
    return true;
  });
}

export const ProductFilters = (
  {
    setCompanyFilter,
    setTypeFilter,
    setTermFilter,
    data
  }) => {

  const productList = useMemo(() => (data ? data.data : null), [data]);

  const productsCompanyList = useMemo(() => {
    if (productList) {
      const filteredProductCompanyList = removeDuplicates(productList, 'company');

      return filteredProductCompanyList
        .filter((product) => product.company)
        .map((product) => ({
          label: product.company,
          value: product.company
        }));

    } else {
      return [];
    }
  }, [productList]);

  const productsTermList = useMemo(() => {
    if (productList) {
      const filteredProductTermList = removeDuplicates(productList, 'type');

      return filteredProductTermList
        .filter((product) => product.type)
        .map((product) => ({
          label: product.type,
          value: product.type
        }));

    } else {
      return [];
    }
  }, [productList]);

  return (
    <div style={{display: 'flex', gap: '8px'}}>
      <Select
        options={productsCompanyList}
        style={{ width: 200 }}
        size='large'
        allowClear
        placeholder='Company'
        onChange={setCompanyFilter}
      />
     <Select
        options={productsTypeList}
        style={{ width: 200 }}
        size='large'
        allowClear
        placeholder='Type of Investments'
        onChange={setTypeFilter}
      />
     <Select
        options={productsTermList}
        style={{ width: 200 }}
        size='large'
        allowClear
        placeholder='Investment term'
        onChange={setTermFilter}
      />
    </div>
  );
};