import React, { useState } from 'react';
import { GU } from '@aragon/ui';
import { CompanyInfo, RepresentativeInfo, IssuesList } from './subPages';
import { BackBar, Tabs } from '../../../../../components';
import { PageContainer, Title2 } from '../../../../../ui';

export const IssuerDetailsView = ({ issuer }) => {
  const [selectedTab, setSelectedTab] = useState(0);

  if (issuer) {
    return (
      <PageContainer paddingTop={3 * GU}>
        <Title2 marginBottom={3 * GU}>Profile</Title2>

        <BackBar />

        <Tabs
          items={['Company information', 'Representative data', 'Issues']}
          selected={selectedTab}
          onChange={setSelectedTab}
          containerProps={{ marginTop: 3 * GU, marginBottom: 2.5 * GU }}
        />

        {selectedTab === 0 && (
          <CompanyInfo data={{ addresses: issuer.addressesCompany, ...issuer.company }} />
        )}

        {selectedTab === 1 && (
          <RepresentativeInfo
            data={{
              addresses: issuer.addressesIssuer,
              issuer: issuer.issuer,
              idDocument: issuer.idDocumentsIssuer[0]
            }}
          />
        )}

        {selectedTab === 2 && <IssuesList issuerId={issuer.issuer.id} />}
      </PageContainer>
    );
  }

  return null;
};
