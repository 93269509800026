import axios from 'axios';
import { API_URL } from '../../../utils/static';

export const getDocumentSections = () => {
  return axios.get(`${API_URL}/api/kyc/v1/editingDocument/sections`);
};

export const getAllDocumentSections = () => {
  return axios.get(`${API_URL}/api/kyc/v1/editingDocument/sections/all`);
};

const createDocumentSection = (documentSection) => {
  return axios.post(
    `${API_URL}/api/kyc/v1/editingDocument/sections`,
    {
      name: documentSection.name,
      type: documentSection.type,
    }
  )
};

const updateDocumentSection = (documentSection) => {
  return axios.put(
    `${API_URL}/api/kyc/v1/editingDocument/sections`,
    {
      kycDocumentSectionId: documentSection.id,
      name: documentSection.name,
      name_RU: documentSection.name_RU,
      name_LV: documentSection.name_LV,
      type: documentSection.type,
    }
  )
};

const deleteDocumentSection = (section) => {
  console.log('tysectionpe',section.id)
  return axios.delete(`${API_URL}/api/kyc/v1/editingDocument/sections`, {
    data: { kycDocumentSectionId: section.id }
  });
};

export const getDocumentTypes = (id) => {
  return axios.get(`${API_URL}/api/kyc/v1/editingDocument/types`, {
    params: { kycDocumentSectionId: id}
  });
};

export const createDocumentType = (type, kycDocumentSectionId) => {
  return axios.post(
    `${API_URL}/api/kyc/v1/editingDocument/types`,
    {
      kycDocumentSectionId: kycDocumentSectionId,
      name: type.name,
      name_RU: type.name_RU,
      name_LV: type.name_LV,
      title: type.title,
      title_RU: type.title_RU,
      title_LV: type.title_LV,
      descriptions: type.descriptions,
      descriptions_RU: type.descriptions_RU,
      descriptions_LV: type.descriptions_LV,
      expirationMonths: type.expirationMonths,
      expirationText: type.expirationText,
      expirationText_RU: type.expirationText_RU,
      expirationText_LV: type.expirationText_LV
    }
  )
};

const updateDocumentType = (type) => {
  return axios.put(
    `${API_URL}/api/kyc/v1/editingDocument/types`,
    {
      kycTypeDocumentId: type.id,
      name: type.name,
      name_RU: type.name_RU,
      name_LV: type.name_LV,
      title: type.title,
      title_RU: type.title_RU,
      title_LV: type.title_LV,
      descriptions: type.descriptions,
      descriptions_RU: type.descriptions_RU,
      descriptions_LV: type.descriptions_LV,
      expirationMonths: type.expirationMonths,
      expirationText: type.expirationText,
      expirationText_RU: type.expirationText_RU,
      expirationText_LV: type.expirationText_LV,
    }
  )
};

const deleteDocumentType = (type) => {
  return axios.delete(`${API_URL}/api/kyc/v1/editingDocument/types`, {
    data: { kycTypeDocumentId: type.id }
  });
};

const addRoleDocumentSection = (kycDocumentSectionId, role) => {
  return axios.post(
    `${API_URL}/api/kyc/v1/editingDocument/sections/role`,
    {
      kycDocumentSectionId: kycDocumentSectionId,
      role: role
    }
  )
};
const deleteRoleDocumentSection = (kycDocumentSectionId, role) => {
  return axios.delete(`${API_URL}/api/kyc/v1/editingDocument/sections/role`, {
    data: {
      kycDocumentSectionId: kycDocumentSectionId,
      role: role
    }
  });
};

const addRoleDocumentType = (kycTypeDocumentId, role, isRequired) => {
  return axios.post(
    `${API_URL}/api/kyc/v1/editingDocument/types/role`,
    {
      kycTypeDocumentId: kycTypeDocumentId,
      role: role,
      isRequired: isRequired,
    }
  )
};

const deleteRoleDocumentType = (kycTypeDocumentId, role) => {
  return axios.delete(`${API_URL}/api/kyc/v1/editingDocument/types/role`, {
    data: {
      kycTypeDocumentId: kycTypeDocumentId,
      role: role,
    }
  });
};


export const ConstructorApi = {
  getAllDocumentSections,
  getDocumentSections,
  createDocumentSection,
  updateDocumentSection,
  deleteDocumentSection,
  getDocumentTypes,
  createDocumentType,
  updateDocumentType,
  deleteDocumentType,
  addRoleDocumentSection,
  deleteRoleDocumentSection,
  addRoleDocumentType,
  deleteRoleDocumentType

};
