import React from 'react';
import { GU } from '@aragon/ui';
import { TotalTurnoverTable, DealsTableBox } from '../../../../../components';
import { PageContainer, Title2 } from '../../../../../ui';

export const DealsView = ({ totalTurnover, deals, DEALS_ON_PAGE, fetchDeals }) => {
  return (
    <PageContainer marginTop={3 * GU}>
      <Title2 marginBottom={2 * GU}>Deals</Title2>

      <TotalTurnoverTable data={totalTurnover} />

      <DealsTableBox deals={deals && deals} DEALS_ON_PAGE={DEALS_ON_PAGE} fetchDeals={fetchDeals} />
    </PageContainer>
  );
};
