import React from 'react';
import { Table, TableRow } from '@aragon/ui';
import { Badge, StatusLine } from '../../../../components';
import { Cell, DetailsButton, TableHeader } from '../../components';
import {
  SIDE_CONFIG,
  VALID_TILL_DATE,
  ORDER_TYPES,
  ORDER_STATUSES
} from '../../../../utils/static';
import { dateString, displayFullDate } from '../../../../utils/helpers';
import styled from 'styled-components';
import { history } from '../../../../routing';

const defHeader = [
  'ID',
  'Placed time',
  'Ticker',
  'Side',
  'Amount',
  'Price',
  'Execution\n Amount',
  'Execution\n Price (AVG)',
  'Order\n type',
  'Valid Till',
  'Status',
  'Execution\n Time'
];
export const OrdersTable = ({ header = defHeader, data = [], onDetailsSideEffect }) => {
  const onDetailsClick = (id) => {
    onDetailsSideEffect();
    history.push(`/order/${id}`);
  };

  const headerItems = header.map((item, key) => <TableHeader title={item} key={key} />);

  const bodyItems =
    data && data.length > 0 ? (
      data.map((item, key) => (
        <TableRow key={key}>
          <Cell>{item.id}</Cell>

          <Cell>{displayFullDate(new Date(item.createdAt))}</Cell>

          <Cell>{item.asset.ticker}</Cell>

          <Cell>
            <Badge title={SIDE_CONFIG[item.side].title} />
          </Cell>

          <Cell>{item.quantity}</Cell>

          <Cell>
            {item.totalPrice} {item.asset.currency}
          </Cell>

          <Cell>{item.executionAmount}</Cell>

          <Cell>
            {item.executionPrice} {item.asset.currency}
          </Cell>

          <Cell>{ORDER_TYPES[item.type]}</Cell>

          <Cell>
            {VALID_TILL_DATE[item.orderValidTillOption] ||
              dateString(new Date(item.orderValidTill))}
          </Cell>

          <Cell>
            {ORDER_STATUSES[item.status] && (
              <StatusLine statusTypes={ORDER_STATUSES} type={item.status} />
            )}
          </Cell>

          {/*<Cell>{item.account}</Cell>*/}

          <Cell>{item.executionTime ? dateString(new Date(item.executionTime)) : ''}</Cell>

          <Cell cellProps={{ align: 'right' }}>
            <DetailsButton onClick={() => onDetailsClick(item.id)} />
          </Cell>
        </TableRow>
      ))
    ) : (
      <TableRow>
        <Cell cellProps={{ style: { border: 'none' } }}>No orders</Cell>
      </TableRow>
    );

  return (
    <StyledTable
      header={<TableRow style={{ height: '48px' }}>{headerItems}</TableRow>}
      noSideBorders
    >
      {bodyItems}
    </StyledTable>
  );
};

const StyledTable = styled(Table)`
  th {
    border-left: none;
    border-right: none;
    white-space: pre-line;
    line-height: 16px;
  }
`;
