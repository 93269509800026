import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Typography } from 'antd';

import { history } from '../../../routing';
import { Container, Title2 } from '../../../ui';
import { Box } from '../../../components';
import { useProducts } from '../hooks';

export const ProductsList = () => {
  const { t } = useTranslation();
  const { data, isLoading } = useProducts();

  return (
    <>
      <Title2 marginBottom={30}>
        {t('Product List')}
      </Title2>

      {isLoading && <div>Loading...</div>}

      {data?.map((product) => (
        <Box heading={product.type} padding={16} key={product.type}>
          {product.name && <Typography.Title level={5}>{product.name}</Typography.Title>}
          <Typography.Text level={5}>{product.title}</Typography.Text>

          <Container marginTop={12}>
            <Button
              mode={'strong'}
              onClick={() => history.push(`/constructorProducts/${product.id}`)}
              type='primary'
            >
              Edit
            </Button>
          </Container>
        </Box>
      ))}
    </>
  );
};
