import React from 'react';
import styled from 'styled-components';
import { Col, Container, Row } from '../../../../ui/layout';
import { Body1Light, Title4 } from '../../../../ui/typography';
import telegramIcon from '../../../../assets/icons/telegram_icon.svg';
import whatsappIcon from '../../../../assets/icons/whatsapp_icon.svg';
import { ExternalLink } from '../../../../ui/atoms/Link';
import { useTranslation } from 'react-i18next';
import { Typography } from 'antd';
import brandBook from '../../../../assets/Spirit Brand Book.pdf'



export const Footer = () => {
  const { t } = useTranslation();

  return (
    <FooterWrapper>
      <FooterContainer>
        <Row margin={-16} justifyContent={'center'} alignCenter>
          <Body1Light>{t('Contact us')}:</Body1Light>

          <Col auto>
            <Row margin={-8} alignCenter>
              <ExternalLink href={'mailto:office@spiritcapital.eu'} external>
                office@spiritcapital.eu
              </ExternalLink>
              <Col auto>
                {t('Tel')}.:&ensp;
                <ExternalLink href={'tel:37167885886'} external>
                  +37167885886
                </ExternalLink>
              </Col>
              {/*<Col auto>*/}
              {/*  <a href={'https://t.me/DavidSkorohod'} target={'_blank'}>*/}
              {/*    <TelegramIcon src={telegramIcon} />*/}
              {/*  </a>*/}
              {/*</Col>*/}
              {/*<Col auto>*/}
              {/*  <a href={'https://api.whatsapp.com/send?phone=972545954925'} target={'_blank'}>*/}
              {/*    <WhatsappIcon src={whatsappIcon} />*/}
              {/*  </a>*/}
              {/*</Col>*/}
            </Row>
          </Col>
        </Row>

        <DescriptionContainer marginTop={16}>
          <Typography.Title level={5}>Spirit Capital Investments SIA</Typography.Title>
          <Typography.Text>{t('Registration Number:')} 40203160700</Typography.Text>
          <Typography.Text>{t('Avotu iela 34a, Rīga LV 1009, Latvia')}</Typography.Text>
          <Typography.Text>{t('License Number:')} 27-55/2023/4</Typography.Text>

          <br/>

          {/*<Typography.Link href={brandBook} target="_blank">*/}
          {/*  Spirit brand book*/}
          {/*</Typography.Link>*/}

          <br/>

          <Typography.Paragraph>{t('Page is under construction.')}</Typography.Paragraph>
        </DescriptionContainer>
      </FooterContainer>
    </FooterWrapper>
  );
};


const FooterWrapper = styled.footer`
  background: white;
  padding: 16px 24px 16px 16px;
  min-height: 60px;
  box-shadow: 0px -1px 3px rgb(0 0 0 / 15%);
  margin-top: 20px;
`;
const FooterContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto
`;
const TelegramIcon = styled.img`
   width: 26px;
   height: 26px;
   display: block;
`;
const WhatsappIcon = styled(TelegramIcon)``;
const DescriptionContainer = styled(Container)`
  > * {
    display: block;
  }
`;
