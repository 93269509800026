import React, { useEffect, useState } from 'react';
import { Header, GU, IconUser, LoadingRing } from '@aragon/ui';
import {
  BackBar, ProfileProducts, ProfileStatus, Tabs
} from '../../../components';
import { Body2Light, Row, Title2, Title4 } from '../../../ui';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { investorApi } from '../../../api';
import styled from 'styled-components';
import { userActions } from '../../../store/user';
import { useDispatch, useSelector } from 'react-redux';
import { ProfileDocuments } from '../../../containers';

export const ReferralProfileView = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { id: paramId } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(undefined);
  const [referralName, setReferralName] = useState('');
  const [selectedTab, setSelectedTab] = useState(0);
  const { referralToken } = useSelector((state) => state.user);

  useEffect(() => {
    if (paramId) {
      (async () => {
        try {
          const res = await investorApi.getReferralToken(paramId);

          if (res) {
            dispatch(userActions.setReferralToken(res.data.accessToken));
            setReferralName(`${res.data.investor.firstName} ${res.data.investor.lastName}`);
            setLoading(false);
            if (error) setError(undefined);
          }
        } catch (e) {
          setError(e.response.data.message);
          setLoading(false);
        }
      })()
    }
  }, [paramId]);

  return (
    <Container>
      <Header
        primary={
          <Row margin={-GU} alignItems={'center'}>
            <Title2>{t('Referral info')}</Title2>
            <Row nowrap alignItems={'center'}>
              <IconUser />
              <Body2Light>{referralName}</Body2Light>
            </Row>
          </Row>
        }
      />

      <BackBar url={'/profile'} />

      <Tabs
        items={[t('Questionnaires'), t('Documents'),t('Products')]}
        selected={selectedTab}
        onChange={setSelectedTab}
        containerProps={{ marginTop: 3 * GU }}
      />

      {loading ? <Loading/> : <>
        {(error && !referralToken) ?
          <Title4 style={{marginTop: '30px'}}>{error === 'USER_NOT_FOUND' ? 'User not found.' : error}</Title4> :
          <>
            {/*Questionnaires*/}
            {selectedTab === 0 && <ProfileStatus referralView referralId={paramId} />}

            {/*Documents*/}
            {selectedTab === 1 && <ProfileDocuments referralView referralId={paramId} />}

            {/*Products*/}
            {selectedTab === 2 && <ProfileProducts referralView referralId={paramId} />}
          </>
        }
      </>}

    </Container>
  );
};

const Container = styled.div`
  padding-bottom: 32px;
  max-width: 1200px;
  margin: 0 auto;
`;

const Loading = styled(LoadingRing)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;

  svg {
    height: 80px;
    width: 80px;
  }
`;
