import React from 'react';
import { ProductGroup } from '../ProductGroup';
import { Container } from '../../../../ui';
import { sortWithNulls } from '../../../../utils/helpers';

export const Section = ({ section, ...props }) => {
  return (
    <Container>
      {section.productGroups &&
        section.productGroups.sort((a, b) => sortWithNulls(Number(a.sortOrder), Number(b.sortOrder))).map((productGroup, index) => (
          <ProductGroup productGroup={productGroup} key={index} {...props} />
        ))}
    </Container>
  );
};
