import React from 'react';
import { Button, Body3Light } from '../../../../ui';
import { useTranslation } from 'react-i18next';

export const DetailsButton = ({ ...props }) => {
  const { t } = useTranslation();

  return (
    <Button size={'small'} width={'75'} {...props} style={{ marginLeft: 'auto' }}>
      <Body3Light>{t('Details')}</Body3Light>
    </Button>
  )
};
