import React from 'react';
import { useController } from 'react-hook-form';
import { AutoComplete, Input } from '../../../../ui';
import { useCountries } from '../../../../hooks';
import { useTranslation } from 'react-i18next';
import { Select } from 'antd';

export const CountryInput = ({ form, label = 'Country', disabled, required, ...props }) => {
  const { t } = useTranslation();
  const { countries } = useCountries();
  const {
    field: { ref, ...field },
    fieldState: { error }
  } = useController({
    ...form,
    rules: {
      ...form.rules,
      validate: (v) =>
        !countries || !v || countries.filter((item) => item.country === v)[0] || t('Select country from list')
    },
    defaultValue: ''
  });

  if (disabled) {
    return <Input {...field} label={label} error={error && error.message} {...props} disabled />;
  }

  return (
    <AutoComplete
      {...field}
      label={label}
      items={countries && countries.map((item) => item.country)}
      error={error && error.message}
      required={required}
      wide
      {...props}
    />
  );
};

export const ResidencyInput = (props) => {
  const { t } = useTranslation();
  return <CountryInput {...props} label={t('Residency')} />;
};

export const CitizenshipInput = (props) => {
  const { t } = useTranslation();
  return <CountryInput {...props} label={t('Citizenship')} />;
};

export const CountrySelect = (props) => {
  const { countries } = useCountries();

  return (
    <Select
      showSearch
      filterOption={(input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
      }
      options={countries?.map(item => ({ value: item.id, label: item.country }))}
      {...props}
    />
  );
};
