import React from 'react';
import { Text, Tooltip } from '../../../../ui';
import styled from 'styled-components';

export const TextList = ({ question }) => {
  return (
    <>
      {question.question && (
        <Title>
          {question.question}
          {question.tooltip && <Tooltip text={question.tooltip}/>}
        </Title>
      )}
      <List>
        {question?.availableAnswers?.map((answer, key) => {
          return (
            <li key={key}>
              <Text>{answer.answer}</Text>
            </li>
          );
        })}
      </List>
    </>
  );
};

const Title = styled.p`
  margin-bottom: 15px;
`;

const List = styled.ul`
  margin-left: 20px;
`;