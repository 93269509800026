import React, { useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { GU } from '@aragon/ui';
import { Button } from '../../../../ui';
import { Container, Row, Col } from '../../../../ui/layout';
import { Title4 } from '../../../../ui/typography';
import { history } from '../../../../routing';
import styled from 'styled-components';
import { COLORS } from '../../../../utils/static';
import {
  ENABLED_QUESTS_TO_VIEW_STATUSES_ADMIN,
  ENABLED_STATUSES_INVESTOR, STATUS_NEW, STATUS_PASSED, STATUSES_IN_PROGRESS
} from '../../../../utils/static/kyc';
import { useTranslation } from 'react-i18next';
import { ProductBox } from '../../molecules';
import { AddProductModal } from '../../../../modules/Product/components/modals';

const isEnabled = (accountStatus, questStatus, adminView) => {
  if (accountStatus !== 'ACTIVE' && !adminView) return false;
  return adminView
    ? ENABLED_QUESTS_TO_VIEW_STATUSES_ADMIN[questStatus]
    : ENABLED_STATUSES_INVESTOR[questStatus];
};

export const ProfileProductsView = (
  {
    data,
    investor,
    adminView = false,
    rejectedProducts,
    refetchProducts,
    referralView = false,
    referralId
  }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const [addProductVisible, setAddProductVisible] = useState(false);

  const productsSorted = useMemo(() => {
    if (data) {
      try {
        const productNew = [];
        const productInProgress = [];
        const productPassed = [];

        data.data.forEach((product) => {
          const productStatus = product.status;
          const error =
            productStatus === 'UNACCEPTED' && rejectedProducts
              ? rejectedProducts[product.id]
              : null;
          const formattedProduct = {
            ...product,
            error: error,
            enabled: isEnabled(investor.accountStatus, productStatus, adminView),
            onClick: (id) => {
              history.push(
                adminView ? `/investor/${investor.id}/product/${id}` : referralView ? `/referral/${referralId}/product/${id}` : `/product/${id}`,
                { prevPath: location.pathname, nextState: { lastProfileTab: investor.lastProfileTab } }
              );
            },
            onClickEdit: (id) => {
              history.push(
                adminView ? `/investor/${investor.id}/product/${id}/edit` : `/product/${id}`,
                { prevPath: location.pathname, nextState: { lastProfileTab: investor.lastProfileTab } }
              );
            }
          };

          if (productStatus === STATUS_NEW) productNew.push(formattedProduct);
          else if (productStatus === STATUS_PASSED) productPassed.push(formattedProduct);
          else if (STATUSES_IN_PROGRESS[productStatus]) productInProgress.push(formattedProduct);
        });

        return {
          new: productNew,
          inProgress: productInProgress,
          passed: productPassed
        };
      } catch (e) {
      }
    }

    return null;
  }, [data, rejectedProducts, adminView, investor, location]);

  if (productsSorted && investor) {
    return (
      <Container style={{ position: 'relative' }}>
        {productsSorted.new.length > 0 || productsSorted.new.length > 0 || productsSorted.passed.length > 0 ?
          <>
            {productsSorted.inProgress && productsSorted.inProgress.length > 0 && (
              <ProductsList title={t('Products in progress')} list={productsSorted.inProgress} adminView={adminView}/>
            )}
            {productsSorted.new && productsSorted.new.length > 0 && (
              <ProductsList title={t('Products new')} list={productsSorted.new} adminView={adminView}/>
            )}
            {productsSorted.passed && productsSorted.passed.length > 0 && (
              <ProductsList title={t('Products passed')} list={productsSorted.passed} adminView={adminView}/>
            )}
          </> : <Title4 style={{marginTop: '30px'}}>No products.</Title4>
        }
        {!referralView && !adminView && <AddProductContainer>
          <Button
            label={'+'}
            onClick={() => setAddProductVisible(true)}
            paddingX={'20'}
            size={'small'}
            mode={'strong'}
            disabled={investor.accountStatus !== 'ACTIVE'}
          />
          <AddProductModal
            visible={addProductVisible}
            onClose={() => setAddProductVisible(false)}
            user={adminView ? 'admin' : 'investor'}
            onSuccess={refetchProducts}
          />
        </AddProductContainer>
        }

      </Container>
    );
  }

  return null;
};


const ProductsList = ({ title = '', list, adminView }) => {
  if (list) {
    return (
      <ListContainer>
        <Title4 marginBottom={12} color={COLORS.default}>{title}</Title4>

        <Row cols={3} margin={-8} colsMarginBottom={2 * GU}>
          {list.map((item, index) => (
            <Col key={index}>
              <ProductBox
                data={item}
                // status={STATUS_DISPLAY[item.status]}
                onClick={() => item.onClick(item.id)}
                onClickEdit={() => item.onClickEdit(item.id)}
                adminView={adminView}
              />
            </Col>
          ))}
        </Row>
      </ListContainer>
    );
  }

  return null;
};

const AddProductContainer = styled.div`
    position: absolute;
    top: 0;
    right: 0;
`;
const ListContainer = styled.div`
    position: relative;
    margin-top: 50px;
`;
