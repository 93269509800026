import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Button, Checkbox, Modal, Radio } from 'antd';

import { useRelatedQuestionnaire, useRelatedSections } from '../hooks';


export const AddCondition = ({ parentQuestionnaireId, questionSectionTypeId, closeModalCheckTrigger, closeModalAddCondition, availableAnswers }) => {
  const { t } = useTranslation();

  const { handleGet } = useRelatedSections();
  const { handleCreate } = useRelatedQuestionnaire();

  const [sections, setSections] = useState([]);

  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [groupObj, setGroupObj] = useState(null);
  const [conditions, setConditions] = useState(availableAnswers);

  const [loading, setLoading] = useState(true);
  console.log('parentQuestionnaireId', parentQuestionnaireId);


  const fetchData = async () => {
    setLoading(true);
    const res = await handleGet(parentQuestionnaireId);
    if (res?.data) {
      setSections(res.data);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [parentQuestionnaireId, availableAnswers]);

  const onAdd = () => {
    closeModalAddCondition(conditions, groupObj);
  }



  const onChange = (e, availableAnswer, questionGroup) => {
    if (e.target.checked) {
      setGroupObj(questionGroup);
      let availableAnswers = [];
      availableAnswers.push(availableAnswer);
      setConditions(availableAnswers);
      setSelectedAnswers(prevState => ({
        ...prevState,
        [questionGroup.id]: availableAnswer.id
      }));
    } else {
      setConditions([]);
    }
  };

  if (loading) return (
    <div>Loading...</div>
  );

  return (
    <Container>
      <ScrollBox>
        {sections.map(section => (
          <SectionBox key={section.id}>
            <SectionTitle>{section.section}</SectionTitle>
            <QuestionGroupBox>
              {section.questionGroups.map(questionGroup => (
                <QuestionBox key={questionGroup.id}>
                  <QuestionGroupTitle>{questionGroup.title}</QuestionGroupTitle>
                  <AnswersBox>
                    {questionGroup.questions.map(question => (
                      <QuestionItem key={question.id}>
                        <QuestionText>{question.question}</QuestionText>
                        <AvailableAnswersBox>
                          {question.availableAnswers.map(availableAnswer => (
                            <CheckboxItem key={availableAnswer.id}>
                              <Checkbox
                                checked={selectedAnswers[questionGroup.id] === availableAnswer.id}
                                onChange={(e) => onChange(e, availableAnswer, questionGroup)}
                              >
                                {availableAnswer.answer}
                              </Checkbox>
                            </CheckboxItem>
                          ))}
                        </AvailableAnswersBox>
                      </QuestionItem>
                    ))}
                  </AnswersBox>
                </QuestionBox>
              ))}
            </QuestionGroupBox>
          </SectionBox>
        ))}
      </ScrollBox>
      <ButtonContainer>
        <Button onClick={onAdd} disabled={Object.keys(selectedAnswers).length === 0} type="primary" style={{ marginTop: '30px' }}>
          Add trigger
        </Button>
      </ButtonContainer>
    </Container>
  );
};

const QuestionBox = styled.div`
  margin-bottom: 30px;
`
const Container = styled.div`
  padding: 20px;
`
const ScrollBox = styled.div`
  height: 800px;
  overflow-y: scroll;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 15px;
  background-color: #f9f9f9;
`
const SectionBox = styled.div`
  margin-bottom: 30px;
  padding: 10px;
  border-bottom: 1px solid #e0e0e0;
`
const SectionTitle = styled.h2`
  margin: 0;
  font-size: 1.5em;
`
const QuestionGroupBox = styled.div`
  margin-top: 10px;
`
const QuestionGroupTitle = styled.h3`
  margin: 0;
  font-size: 1.2em;
`
const AnswersBox = styled.div`
  margin-top: 10px;
`
const QuestionItem = styled.div`
  margin-bottom: 15px;
`
const QuestionText = styled.p`
  margin: 0;
  font-weight: bold;
`
const AvailableAnswersBox = styled.div`
  margin-top: 5px;
`
const CheckboxItem = styled.div`
  margin-bottom: 5px;
`
const ButtonContainer = styled.div`
  text-align: right;
`
