import React, { useEffect, useState } from 'react';
import { adminApi } from '../../../../../api';
import { useDispatch, useSelector } from 'react-redux';
import { ordersActions } from '../../../../../store/orders';
import { ordersFiltersToBack } from '../../../../../utils/helpers';
import { OrdersView } from '../View';

export const Orders = () => {
  const dispatch = useDispatch();
  const { filters } = useSelector((state) => state.orders);
  const [orders, setOrders] = useState(null);

  useEffect(() => {
    fetchOrders(ordersFiltersToBack(filters));
    // fetchTotalInstruments();
  }, []);

  const fetchOrders = (params) => {
    adminApi.getOrders({ ...params }).then((res) => {
      if (res.status === 200) {
        setOrders(res.data);
      }
    });
  };

  const fetchTotalInstruments = () => {
    adminApi.getTotalInstruments().then(console.log);
  };

  const saveFilters = (filters) => {
    dispatch(ordersActions.setFilters(filters));
  };

  return (
    <OrdersView
      orders={orders && orders}
      fetchOrders={fetchOrders}
      savedFilters={filters}
      saveFilters={saveFilters}
    />
  );
};
