import React from 'react';
import { GU } from '@aragon/ui';
import { useController } from 'react-hook-form';
import { UploadDocumentsBox } from '../../../../components';
import { Body3Light, ExternalLink } from '../../../../ui';
import { API_URL, COLORS } from '../../../../utils/static';

export const SignatureForm = ({ form, label, question, required }) => {
  const {
    field: { ref, ...field },
    fieldState: { error }
  } = useController({
    ...form,
    defaultValue: null
  });

  return (
    <div>
      <UploadDocumentsBox
        label={label}
        required={required}
        docs={field.value ? [field.value] : []}
        setDocs={(files) => field.onChange(files)}
        template={question.additionalData && question.additionalData.templateLink}
      />

      {error && (
        <Body3Light color={COLORS.red} marginTop={1.5 * GU}>
          {error.message}
        </Body3Light>
      )}
    </div>
  );
};
