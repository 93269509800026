import React from 'react';
import { IconCircleCheck, IconClock, IconProhibited, IconConnect } from '@aragon/ui';
import { COLORS } from '../../../utils/static/colors';

export const VALID_TILL_CANCEL_OPTION = 'Good till cancel';
export const VALID_TILL_DATE_OPTION = 'Good till date';
export const VALID_TILL_DAY_OPTION = 'Day';

export const VALID_TILL_OPTIONS = [
  VALID_TILL_CANCEL_OPTION,
  VALID_TILL_DATE_OPTION,
  VALID_TILL_DAY_OPTION
];

export const VALID_TILL_DATE = {
  [VALID_TILL_CANCEL_OPTION]: 'GTC',
  [VALID_TILL_DATE_OPTION]: null,
  [VALID_TILL_DAY_OPTION]: 'day'
};

export const ORDER_TYPES_LIST = ['TRADE'];

export const ORDER_TYPES = {
  TRADE: 'Trade'
};

export const ORDER_STATUSES = {
  WORKING: {
    title: 'Working',
    color: COLORS.aragonBlue,
    icon: IconConnect
  },
  EXPIRED: {
    title: 'Expired',
    color: COLORS.red,
    icon: IconClock
  },
  PARTIALLY_EXECUTED: {
    title: 'Partially executed',
    color: COLORS.yellow,
    icon: IconCircleCheck
  },
  CANCELED: {
    title: 'Canceled',
    color: COLORS.iconColor,
    icon: IconProhibited
  },
  REJECTED: {
    title: 'Rejected',
    color: COLORS.red,
    icon: IconProhibited
  },
  EXECUTED: {
    title: 'Executed',
    color: COLORS.mintGreen,
    icon: IconCircleCheck
  },
  EXECUTED_DEAL: {
    title: 'Executed',
    color: COLORS.mintGreen,
    icon: IconCircleCheck
  },
  IN_PROCESS_DEAL: {
    title: 'Executed',
    color: COLORS.mintGreen,
    icon: IconCircleCheck
  }
};

export const CANCELED_ORDER_STATUSES = ['WORKING'];

export const ORDER_STATUS_TABS = ['All orders', 'Processed orders', 'Current orders'];

export const ORDER_TAB_STATUSES = {
  'All orders': null,
  'Processed orders': ['WORKING', 'PARTIALLY_EXECUTED'],
  'Current orders': [
    'CANCELED',
    'REJECTED',
    'EXPIRED',
    'EXECUTED',
    'EXECUTED_DEAL',
    'IN_PROCESS_DEAL'
  ]
};

export const MIFID_STATUSES = ['EXECUTION'];
export const DISPLAY_MIFID_STATUS = {
  EXECUTION: 'Execution'
};

export const SIDE_CONFIG = {
  BUY: {
    title: 'Buy',
    method: 'Payment method'
  },
  SELL: {
    title: 'Sell',
    method: 'RECEPTION METHOD'
  }
};

export const RESPONSIBLE_BROKER = 'STO.Broker';
export const EXECUTION_PLACE = 'STO.Broker';

export const SETTLEMENT_TYPES = ['DVP'];

export const ORDERS_ON_PAGE = 5;
