import React from 'react';
import { useTranslation } from 'react-i18next';
import { Collapse } from 'antd';

import { Title4 } from '../../../ui';
import { useSections } from '../hooks';
import {Section} from './Section';
import {StyledPanel} from '../styled'

export const SectionList = ({questionnaireId = ''}) => {
  const { t } = useTranslation();
  const {data, isError, isLoading} = useSections(questionnaireId);

  console.log('SectionList: data', data);
  return (
    <>
      {isLoading && <div>Loading...</div>}

      <Title4>{t('Sections')}</Title4>

      <Collapse accordion>
        {data?.map(section => (
          <StyledPanel header={section.section}
                       key={section.id}
          >
            <Section sectionProp={section} />
          </StyledPanel>
        ))}
      </Collapse>

    </>
  );
};
