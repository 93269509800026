import React, { useMemo, useState } from 'react';
import { history } from '../../../../routing';
import { Table, TableRow } from '@aragon/ui';
import { ProfitLine, AccordionButton } from '../../../../components';
import { Cell, DetailsButton, TableHeader } from '../../components';
import styled from 'styled-components';
import { COLORS, ASSET_TYPES } from '../../../../utils/static';
import { displayFullDate } from '../../../../utils/helpers';

const defHeader = [
  '',
  'Asset ID',
  'Ticker',
  'Type',
  'Issuer',
  'Issue date',
  'Quantity',
  'Price:',
  'Issuance',
  'Last Purchase',
  'Profit/Loss'
];
const defHelperCols = ['Price:'];

export const MyAssetsTable = ({
  header = defHeader,
  data = [],
  helperCols = defHelperCols,
  onDetailsSideEffect = null
}) => {
  const [openRow, setOpenRow] = useState(null);

  const onDetailsClick = (id) => {
    onDetailsSideEffect && onDetailsSideEffect();
    history.push(`/assets/${id}`);
  };

  const toggleAccordion = (id) => {
    openRow !== id ? setOpenRow(id) : setOpenRow(null);
  };

  //TableHeader
  const headerItems = header.map((item, key) =>
    helperCols.includes(item) ? (
      <TH title={item} key={key} />
    ) : (
      <TableHeader title={item} key={key} />
    )
  );

  //TableBody
  const bodyItems = useMemo(() => {
    const body = [];

    data && data.length > 0
      ? data.forEach((item) => {
          //asset row
          body.push(renderTableRow(item, openRow, toggleAccordion, onDetailsClick));

          // const buyOrders = item.orders.filter(item => item.side === 'BUY');
          // //inner order rows
          // if (buyOrders.length > 1) {
          //   buyOrders.forEach(order => {
          //     body.push(renderInnerRow(item.asset, order, openRow))
          //   })
          // }
        })
      : body.push(
          <TableRow key={'noAssets'}>
            <Cell cellProps={{ style: { border: 'none' } }}>No assets</Cell>
          </TableRow>
        );

    return body;
  }, [openRow, data]);

  return (
    <StyledTable header={<TableRow>{headerItems}</TableRow>} noSideBorders>
      {bodyItems}
    </StyledTable>
  );
};

const renderTableRow = (item, openRow, toggleAccordion, onDetailsClick) => {
  // const isAccordion = item.ordersCount > 1;
  const isAccordion = false;
  const isActive = openRow === item.asset.id;

  return (
    <StyledRow
      isAccordion={isAccordion}
      active={isActive}
      onClick={() => {
        if (isAccordion) toggleAccordion(item.asset.id);
      }}
      key={`asset${item.asset.id}`}
    >
      <Cell>{isAccordion && <AccordionButton opened={isActive} />}</Cell>
      <Cell>{item.asset.id}</Cell>
      <Cell>{item.asset.ticker}</Cell>
      <Cell>{ASSET_TYPES[item.asset.type]}</Cell>
      <Cell>{item.asset.issuer}</Cell>
      <Cell>{displayFullDate(new Date(item.asset.issueDate), true)}</Cell>
      <Cell>{item.quantity}</Cell>
      <Cell />
      <Cell>{item.issuance}</Cell>
      <Cell>{item.lastpurchase}</Cell>
      <Cell>{/*<ProfitLine />*/}</Cell>

      <Cell cellProps={{ align: 'right' }}>
        <DetailsButton onClick={() => onDetailsClick(item.asset.id)} />
      </Cell>
    </StyledRow>
  );
};

const renderInnerRow = (asset, order, openRow) => {
  const orderPrice = order.pricePerCapita * order.quantity;
  const currentPrice = asset.price * order.quantity;
  const profit = order.side === 'BUY' ? currentPrice - orderPrice : orderPrice - currentPrice;
  const isOpened = openRow === asset.id;

  return (
    <InnerRow opened={isOpened} key={`order${order.id}`}>
      <Cell />
      <Cell />
      <Cell />
      <Cell />
      <Cell />
      <Cell />
      <Cell>{order.quantity}</Cell>
      <Cell />
      <Cell>
        {orderPrice} {asset.currency}
      </Cell>
      <Cell>
        {currentPrice} {asset.currency}
      </Cell>
      <Cell>
        <ProfitLine
          profit={profit}
          profitPercent={((profit / orderPrice) * 100).toFixed(2)}
          currency={asset.currency}
        />
      </Cell>

      <Cell />
    </InnerRow>
  );
};

const StyledTable = styled(Table)`
  th {
    border-left: none;
    border-right: none;
  }
`;

const TH = styled(TableHeader)`
  color: ${COLORS.greyLight};
`;
const StyledRow = styled(TableRow)`
  border-left: 3px solid transparent;

  ${(p) =>
    p.isAccordion
      ? `
   cursor: pointer;
  `
      : ''}

  ${(p) =>
    p.active
      ? `
   border-left: 3px solid #abbecf;
  `
      : ''}
`;
const InnerRow = styled(TableRow)`
  border-left: 3px solid #abbecf;

  td,
  td > * {
    transition: height, line-height, padding 0.2s ease-out;
    background: #f1f3f7;
  }

  ${(p) =>
    p.opened
      ? ``
      : `
    td, td > * {
      visibility: hidden;
      padding-top: 0!important;
      padding-bottom: 0!important;
      line-height: 0;
      height: 0;
      border: none;
    }
  `}
`;
