import React, { useState, useRef, useEffect } from 'react'
import {LinkButton} from "../../../ui";
import { GU } from '@aragon/ui';
import {useTranslation} from "react-i18next";
import styled from "styled-components";

const CodeCountdown = ({sendCode, isInitialCall = false}) => {
    const Ref = useRef(null);
    const { t } = useTranslation();
    const [timer, setTimer] = useState('00:00');
    const [showText, setShowText] = useState(false);
    const [isCodeSent, setIsCodeSent] = useState(false);

    useEffect(() => {
        clearTimer(getDeadTime());
        setShowText(true);
        setIsCodeSent(true);
        if(isInitialCall) {
            sendCode();
        }
    }, []);

    const handleSendCode = () => {
        sendCode().then(() => {
            clearTimer(getDeadTime());
            setIsCodeSent(true)
        });
    };

    const getTimeRemaining = (e) => {
        const total = Date.parse(e) - Date.parse(new Date());
        const seconds = Math.floor((total / 1000) % 60);
        const minutes = Math.floor((total / 1000 / 60) % 60);
        return {
            total, minutes, seconds
        };
    }

    const startTimer = (e) => {
        let { total, minutes, seconds }
            = getTimeRemaining(e);
        if (total >= 0) {
            setTimer(
                (minutes > 9 ? minutes : '0' + minutes) + ':'
                + (seconds > 9 ? seconds : '0' + seconds)
            )
        } else {
            setIsCodeSent(false)
        }
    }

    const clearTimer = (e) => {
        setTimer('00:59');
        if (Ref.current) clearInterval(Ref.current);
        const id = setInterval(() => {
            startTimer(e);
        }, 1000)
        Ref.current = id;
    }

    const getDeadTime = () => {
        let deadline = new Date();
        deadline.setSeconds(deadline.getSeconds() + 59);
        return deadline;
    }

    return (
        <CodeSend>
            {showText && <>
                {isCodeSent ? <>
                    <span>{t('You can resend code in')} </span>
                    <span>{timer}</span>
                </> : <>
                    <span>{t('Didn’t receive the code?')} </span>
                    <LinkButton onClick={() => handleSendCode()}>{t('Try again')}</LinkButton>
                </>
                }
            </>
            }
        </CodeSend>
    )
}

const CodeSend = styled.div`
  height: ${3 * GU}px;
`;

export default CodeCountdown;
