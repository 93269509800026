import React, { useEffect, useState } from 'react';
import { RegTitle } from '../../../ui/typography';
import { RegContainer } from '../../../components';
import { BackButton, Button, LinkButton, IconView, Container } from '../../../ui';
import { useForm } from 'react-hook-form';
import { FormInput } from '../../../modules/kycModule/components';
import { authApi } from '../../../api';
import { useDispatch, useSelector } from 'react-redux';
import { entitiesActions } from '../../../store/entities';
import { PASSWORD_REGEX } from '../../../utils/static';
import { history } from '../../../routing';
import { useTranslation } from 'react-i18next';


export const ResetPassword = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const user = useSelector(state => state.user);
  const { getValues, control, handleSubmit } = useForm();
  const [codeSentEmail, setCodeSentEmail] = useState((user && user.email) || '');
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);

  useEffect(() => {
    console.log(user)
    if (user && user.email) {
      sendCode({ email: user.email });
    }
  }, []);

  const sendCode = async (data) => {
    try {
      if (user && user.email) {
        await authApi.changePasswordSendCode(data.email);
      } else {
        await authApi.resetPasswordSendCode(data.email);
      }
      setCodeSentEmail(data.email);
      dispatch(entitiesActions.addToast(user ? t('Code sent to your email') : t('Code sent')));
    } catch (e) {
      if (e?.response?.data?.message === 'INVESTOR_NOT_FOUND') {
        dispatch(entitiesActions.addToast(t('Account not found')));
      } else {
        dispatch(entitiesActions.addToast(t('Something went wrong')));
      }
    }
  };

  const resetPassword = async (data) => {
    try {
      if (user && user.email) {
        await authApi.changePassword({
          code: data.code,
          newPassword: data.password
        });
      } else {
        await authApi.resetPassword({
          email: codeSentEmail,
          code: data.code,
          newPassword: data.password
        });
      }
      dispatch(entitiesActions.addToast(`${user ? t('Password successfully changed') : t('Password successfully reset')}`));
      history.push(user ? '/profile' : '/login');
    } catch (e) {
      if (e?.response?.data?.message === 'CONFIRM_CODE_IS_WRONG') {
        dispatch(entitiesActions.addToast(t('Wrong code')));
      } else {
        dispatch(entitiesActions.addToast(t('Something went wrong')));
      }
    }
  };


  return (
    <RegContainer type='centered'>
      <Container marginBottom={48} style={{ textAlign: 'left' }}>
        <BackButton onClick={() => history.goBack()} />
      </Container>

      <RegTitle>{user ? t('Change password') : t('Reset password')}</RegTitle>

      {!codeSentEmail && <div>
        <FormInput
          label={t('E-mail')}
          form={{ name: 'email', control, rules: { required: t('Required') } }}
          textTransform={'lowercase'}
          marginBottom={24}
        />

        <Button
          label={t('Send code')}
          onClick={() => handleSubmit(sendCode)()}
          mode={'strong'}
        />
      </div>}

      {codeSentEmail && <div>
        {!user && <Container style={{ textAlign: 'left' }} marginBottom={16}>
          <LinkButton onClick={() => setCodeSentEmail('')}>
            {t('Change account')}
          </LinkButton>
        </Container>}

        <FormInput
          label={t('Code')}
          form={{ name: 'code', control, rules: { required: t('Required') } }}
          marginBottom={16}
        />
        <FormInput
          label={t('New password')}
          form={{
            name: 'password',
            control,
            rules: {
              required: t('Required'),
              validate: (v) => PASSWORD_REGEX.test(v) || t('At least 8 characters, both numbers and latin symbols in upper- and lowercase.'),
              deps: ['repeatPassword']
            }
          }}
          type={showPassword ? 'text' : 'password'}
          icon={<IconView onClick={() => setShowPassword(!showPassword)} />}
          marginBottom={16}
        />
        <FormInput
          label={t('Repeat password')}
          form={{
            name: 'repeatPassword',
            control,
            rules: {
              required: t('Required'),
              validate: (v) => getValues('password') === v || t('Passwords must match')
            }
          }}
          type={showPassword2 ? 'text' : 'password'}
          icon={<IconView onClick={() => setShowPassword2(!showPassword2)} />}
          marginBottom={24}
        />

        <Button
          label={`${user ? t('Change password') : t('Reset password')}`}
          onClick={() => handleSubmit(resetPassword)()}
          mode={'strong'}
        />
      </div>}
    </RegContainer>
  );
};
