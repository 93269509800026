import React from 'react';
import { GU } from '@aragon/ui';
import { Box, ImageUpload, ImageUploadBox, Info } from '../../../../components';
import { Input, Row, Label2 } from '../../../../ui';

export const CompanyInfoInputBox = ({ data, changeData, required = false }) => {
  return (
    <Box paddingBottom={3 * GU}>
      <ImageUploadBox
        label={'Company name'}
        value={data.logo}
        onChange={(file) => changeData('logo', file)}
        required={required}
        labelMarginBottom={1.5 * GU}
      />

      <Row
        cols={2}
        margin={-GU}
        marginTop={3 * GU}
        colsMarginBottom={2 * GU}
        alignItems={'flex-end'}
      >
        <Input
          label={'Company name'}
          value={data.name}
          onChange={(e) => changeData('name', e.target.value)}
          required
        />

        <Input
          label={'Company type'}
          value={data.type}
          onChange={(e) => changeData('type', e.target.value)}
          required
        />

        <Input
          label={'E-mail'}
          value={data.email}
          onChange={(e) => changeData('email', e.target.value)}
          required
        />

        <Input
          label={'Website'}
          value={data.website}
          onChange={(e) => changeData('website', e.target.value)}
          required
        />

        <Input
          label={'Rating'}
          value={data.rating}
          onChange={(e) => changeData('rating', e.target.value)}
          required
        />
      </Row>
    </Box>
  );
};
