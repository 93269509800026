import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { GU } from '@aragon/ui';

import { OfferBox } from '../../../common';
import { Row, Col, Title4, Container } from '../../../../ui';

/**
 * @param offers (array of requested offers)
 */
export const ProfileOffers = (
  {
    offers
  }) => {
  const { t } = useTranslation();

  return (
    <Container style={{ position: 'relative' }}>
      <OffersContainer>
        {/*<Title4 marginBottom={12}>{title}</Title4>*/}
        <Row cols={3} margin={-8} colsMarginBottom={2 * GU}>
          {offers.map((item, index) => (
            <Col key={index}>
              <OfferBox data={item} />
            </Col>
          ))}
        </Row>
      </OffersContainer>
    </Container>
  );
};

const OffersContainer = styled.div`
  margin-top: 50px;
`;
