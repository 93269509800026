import React, { useContext, useEffect, useState } from 'react';
import { Modal, Button, Popconfirm, Input } from 'antd';
import { MessageContext } from '../../../../app/MessageProvider';
import { Container } from '../../../../ui';
import { useTranslation } from 'react-i18next';
import {useParams} from "react-router-dom";
import { adminApi } from '../../../../api';
import { Label, Space } from '../../../../features/constructorCommon/styled';

export const UpdateNameModal = ({ open, onClose, initialFirstName, initialLastName, refetchInvestorInfo }) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const messageApi = useContext(MessageContext);
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);

  useEffect(() => {
    if (initialFirstName) setFirstName(initialFirstName);
    if (initialLastName) setLastName(initialLastName);
  }, [initialFirstName, initialLastName]);

  const handleClose = () => {
    setFirstName(null);
    setLastName(null);
    onClose();
  }

  const changeUserName = async () => {
    try {
      const result = await adminApi.updateInvestor({ investorId: id, firstName: firstName, lastName: lastName });
      if(result) {
        refetchInvestorInfo();
        messageApi.success(t('User name is changed'));
        onClose();
      }
    } catch (e) {
      console.error(e);
      messageApi.error(e.message);
    }
  };

  return (
    <Modal open={open} onCancel={handleClose} title={t('Change user name')}
           footer={[
             <Button key="back" onClick={handleClose}>
               {t("Cancel")}
             </Button>,
             <Popconfirm
               title={t("Change user name")}
               description={t("Are you sure you want to change the user's name?")}
               okText={t("Yes")}
               cancelText={t("No")}
               onConfirm={changeUserName}
             >
               <Button type="primary" disabled={lastName === initialLastName && firstName === initialFirstName} style={{marginLeft: '4px'}}>
                 {t('Change')}
               </Button>
             </Popconfirm>
           ]}>
      <Container marginTop={16} marginBottom={10}>
        <Label>{t('First name')}</Label>
        <Input
          name='firstName'
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />
        <Space />
        <Label>{t('Last name')}</Label>
        <Input
          name='lastName'
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
        />
        <Space />
      </Container>
    </Modal>
  );
};
