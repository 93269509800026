import React from 'react';
import styled from 'styled-components';
import { history } from '../../../../routing';
import { Table, TableRow } from '@aragon/ui';
import { ProfileViewer, ProfitLine } from '../../../../components';
import { Cell, DetailsButton, TableHeader, EmptyBody } from '../../components';
import { COLORS, ASSET_TYPES } from '../../../../utils/static';

const defHeader = [
  'ID',
  'Title',
  'Type',
  'Issuer',
  'Instruments\n count:',
  'Remain/Sold/General',
  'Price:',
  'Basic/Current/Last purchase',
  'Profit/loses'
];
const disabledHeader = ['Instruments\n count:', 'Price:'];
export const InstrumentsTable = ({ header = defHeader, data = [], onDetailsClick = () => {} }) => {
  const headerItems = header.map((item, key) => {
    const disabled = disabledHeader.includes(item);
    return (
      <TableHeader
        title={item}
        color={disabled && COLORS.greyLight}
        textAlign={disabled && 'right'}
        key={key}
      />
    );
  });

  const bodyItems =
    data && data.length > 0 ? (
      data.map((item, key) => {
        const { company } = item.issue.issuer;

        return (
          <TableRow key={key}>
            <Cell>{item.id}</Cell>
            <Cell>{item.fullName}</Cell>
            <Cell>{ASSET_TYPES[item.assetType]}</Cell>

            <Cell>
              <ProfileViewer firstName={company.name} avatarUrl={company.logoURL} size={'small'} />
            </Cell>

            <Cell />

            <Cell>
              <div style={{ opacity: 0.5 }}>$80,000 $80,000 $80,000</div>
            </Cell>

            <Cell />

            <Cell>
              <div style={{ opacity: 0.5 }}>$80,000 $80,000 $80,000</div>
            </Cell>

            <Cell>
              <div style={{ opacity: 0.5 }}>
                <ProfitLine profit={2} profitPercent={3} currency={item.currency} />
              </div>
            </Cell>

            <Cell cellProps={{ align: 'right' }}>
              <DetailsButton onClick={() => history.push(`/asset/${item.id}`)} />
            </Cell>
          </TableRow>
        );
      })
    ) : (
      <EmptyBody title={'No assets'} />
    );

  return (
    <StyledTable
      header={<TableRow style={{ height: '48px' }}>{headerItems}</TableRow>}
      noSideBorders
    >
      {bodyItems}
    </StyledTable>
  );
};

const StyledTable = styled(Table)`
  th {
    border-left: none;
    border-right: none;
    white-space: pre-line;
    line-height: 16px;
  }
`;
