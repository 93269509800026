import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { assetsActions } from '../../../../store/assets';
import { AvailableAssets } from '../../../../pages';

const assetsInTable = 5;

export const AvailableAssetsContainer = () => {
  const dispatch = useDispatch();
  const { assets, filters } = useSelector((state) => state.assets);

  const fetchAssets = (params) => {
    dispatch(
      assetsActions.getAssets({
        take: assetsInTable,
        ...params
      })
    );
  };

  const saveFilters = (filters) => {
    dispatch(assetsActions.setFilters(filters));
  };

  return (
    <AvailableAssets
      assets={assets && assets}
      assetsInTable={assetsInTable}
      updateFilters={fetchAssets}
      savedFilters={filters}
      saveFilters={saveFilters}
    />
  );
};
