import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { investorActions } from '../../../../../store/investor';
import { InvestorDetailsView } from '../View';
import { adminKYCApi } from '../../../../../api';
import { entitiesActions } from '../../../../../store/entities';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';

export const InvestorDetails = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { investor } = useSelector((state) => state);
  const { refetch, isFetching } = useQuery(
    ['fetchInvestorProfile'],
    () => investorActions.fetchInvestorProfile(id, dispatch),
    { retry: 1, enabled: false }
  );

  useEffect(() => {
    isFetching ? dispatch(entitiesActions.showLoader()) : dispatch(entitiesActions.hideLoader());
  }, [isFetching]);

  useEffect(() => {
    if (id) refetch();
  }, [id]);

  const setInvestorStatus = async (status) => {
    if (status) {
      try {
        await adminKYCApi.setInvestorStatus({
          accountStatus: status,
          investorId: investor.id
        });

        await refetch();
      } catch (err) {
        console.error('UPDATE_COMPLIANCE_STATUS', err);
        dispatch(entitiesActions.addToast(t('Something went wrong')));
      }
    }
  };

  const approveInvestor = async () => {
    await setInvestorStatus('ACTIVE');
    // const {tier, expectationTier, id} = investor;
    // if (tier + 1 === expectationTier) {
    //   await adminKYCApi.upgradeTier(expectationTier, id);
    // }
  };
  const rejectInvestor = async () => {
    await setInvestorStatus('NOT_ACTIVE');
  };

  if (!isFetching) {
    return (
      <InvestorDetailsView
        investorData={investor}
        approveInvestor={approveInvestor}
        rejectInvestor={rejectInvestor}
        refetchInvestorInfo={refetch}
      />
    );
  }

  return null;
};
