import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import { kycApi } from '../../../../api';
import { Loading, ProfileProductsView } from '../../../common';

export const ProfileProducts = ({ referralView = false, referralId }) => {
  const { referralToken } = useSelector((state) => state.user);
  const [isProductsGenerated, setIsProductsGenerated] = useState(false);
  const { investor } = useSelector((state) => state);
  const [rejectedProducts, setRejectedProducts] = useState(null);
  const { data, isLoading, refetch } = useQuery(['getMyProducts', referralToken], referralView ? () => kycApi.getMyReferralProducts(referralToken) : kycApi.getMyProducts, {
    enabled: (referralView && !!referralToken) || !referralView,
    refetchInterval: 60000,
    refetchOnMount: true,
    refetchOnWindowFocus: true,
    onSuccess: async (res) => {
      try {
        const productLength = res.data?.length || 0;
        if (productLength !== 0) {
          const hasUnaccepted = res.data.some((item) => {
            return item.status === 'UNACCEPTED';
          });

          if (hasUnaccepted) {
            const rejRes = await kycApi.getRejectedProductEntities();
            const sectionTypes = rejRes.data.sectionTypes;
            if (sectionTypes.length > 0) {
              const rejectedQuests = {};
              sectionTypes.forEach((item) => {
                rejectedQuests[item.id] = item.comment[item.comment.length - 1]?.comment;
              });

              setRejectedProducts(rejectedQuests);
            }
          }
        }
      } catch (e) {
        console.error(e);
      }
    }
  });

  useEffect(() => {
    if (data && !isProductsGenerated) {
      const questLength = data.data.length || 0;
      if (questLength === 0) {
        (async () => {
          await refetch();
          setIsProductsGenerated(true);
        })();
      }
    }
  }, [data, isProductsGenerated]);

  if (isLoading) return <Loading/>;

  if (!isLoading) {
    return (
      <ProfileProductsView
        data={data}
        investor={investor}
        rejectedProducts={rejectedProducts}
        refetchProducts={refetch}
        referralView={referralView}
        referralId={referralId}
        referralToken={referralToken}
      />
    );
  }

  return null;
};
