import React from 'react';
import { Button } from '@aragon/ui';
import styles from '../style.module.css';

/**
 * Component to handle file upload. Works for image
 * uploads, but can be edited to work for any file.
 */
function FileUpload() {
  // State to store uploaded file
  const [file, setFile] = React.useState('');

  // Handles file upload event and updates state
  function handleUpload(event) {
    setFile(event.target.files[0]);

    // Add code here to upload file to server
    // ...
  }

  return (
    <div className={styles['upload-box']}>
      {file && <p className={styles['fileName']}>{file.name}</p>}
      {!file && (
        <div>
          <label htmlFor='chooseFilesButton' className={styles['chooseFilesLabel']}>
            Choose files
          </label>
          <input
            type='file'
            className={styles['chooseFilesButton']}
            id='chooseFilesButton'
            onChange={handleUpload}
          />
        </div>
      )}
    </div>
  );
}

export default function App() {
  return <FileUpload />;
}
