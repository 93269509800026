import React, { useState } from 'react';
import { GU } from '@aragon/ui';
import { TableBoxContainer, IssuersTable, Pagination } from '../../../../components';
import { SearchInput, ExportButton, Row, Col } from '../../../../ui';

export const IssuersTableBox = ({ issuers, fetchIssuers, ISSUERS_ON_PAGE }) => {
  const [selected, setSelected] = useState(0);
  const [search, setSearch] = useState('');

  const refetchIssuers = (selectedPage = selected) => {
    fetchIssuers({
      search: search,
      skip: selectedPage * ISSUERS_ON_PAGE
    });
  };

  const onUpdateSearch = () => {
    refetchIssuers(0);
    setSelected(0);
  };

  const onUpdatePage = (value) => {
    refetchIssuers(value);
    setSelected(value);
  };

  if (issuers) {
    return (
      <TableBoxContainer
        title={'Issuers'}
        aside={
          <Row margin={-GU}>
            <Col>
              <SearchInput
                value={search}
                onChange={setSearch}
                handleSearch={onUpdateSearch}
                placeholder={'Search'}
                minWidth={304}
              />
            </Col>

            <Col>
              <ExportButton />
            </Col>
          </Row>
        }
        table={<IssuersTable data={issuers?.issuers} />}
        paginationSettings={{
          visible: issuers.count > ISSUERS_ON_PAGE,
          selected: selected,
          pages: Math.ceil(issuers.count / ISSUERS_ON_PAGE),
          onChange: onUpdatePage
        }}
      />
    );
  }

  return null;
};
