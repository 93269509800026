import { useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { adminLocalizationApi, adminLocalizationKeys } from '../../../api';
import { ConstructorApi } from '../api';
import { queryClient } from '../../../app/QueryClientProvider';
import { MessageContext } from '../../../app/MessageProvider';

export const useProductAnswer = ({productId}) => {
  const messageApi = useContext(MessageContext);
  const { t } = useTranslation();
  const [isCreating, setIsCreating] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const handleCreate = useCallback(async (answer, questionId) => {
    try {
      setIsCreating(true);
      await ConstructorApi.createAnswer(answer, questionId);
      await queryClient.refetchQueries({ queryKey: [adminLocalizationKeys.getProductsSections], queryFn: () => adminLocalizationApi.getProductsSections({ productId: productId }) });
      messageApi.success(t('Created'));
    } catch (e) {
      messageApi.error(e?.message || 'Something went wrong');
    } finally {
      setIsCreating(false);
    }
  }, []);

  const handleUpdate = useCallback(async (answer) => {
    try {
      setIsUpdating(true);
      await ConstructorApi.updateAnswer(answer);
      await queryClient.refetchQueries({ queryKey: [adminLocalizationKeys.getProductsSections], queryFn: () => adminLocalizationApi.getProductsSections({ productId: productId }) });
      messageApi.success(t('Saved'));
    } catch (e) {
      messageApi.error(e?.message || 'Something went wrong');
    } finally {
      setIsUpdating(false);
    }
  }, []);

  const handleDelete = useCallback(async (answer) => {
    try {
      setIsDeleting(true);
      await ConstructorApi.deleteAnswer(answer);
      await queryClient.refetchQueries({ queryKey: [adminLocalizationKeys.getProductsSections], queryFn: () => adminLocalizationApi.getProductsSections({ productId: productId }) });
      messageApi.success(t('Saved'));
    } catch (e) {
      messageApi.error(e?.message || 'Something went wrong');
    } finally {
      setIsDeleting(false);
    }
  }, []);

  return {
    handleCreate,
    handleUpdate,
    handleDelete,
    isCreating,
    isUpdating,
    isDeleting
  }
}
