import axios from 'axios';
import { API_URL } from '../../../utils/static';

const createProduct = (product) => {
  return axios.post(
    `${API_URL}/api/kyc/v1/editingProduct/product`,
    {
      type: product.type,
      name: product.name,
      title: product.title,
      descriptions: product.descriptions
    }
  );
};
const updateProduct = (product) => {
  const formData = new FormData();
  formData.append('productId', product.id);
  if (product.type) formData.append('type', product.type);
  if (product.name) formData.append('name', product.name);
  if (product.name_RU) formData.append('name_RU', product.name_RU);
  if (product.name_LV) formData.append('name_LV', product.name_LV);
  if (product.title) formData.append('title', product.title);
  if (product.title_RU) formData.append('title_RU', product.title_RU);
  if (product.title_LV) formData.append('title_LV', product.title_LV);
  if (product.descriptions) formData.append('descriptions', product.descriptions);
  if (product.descriptions_RU) formData.append('descriptions_RU', product.descriptions_RU);
  if (product.descriptions_LV) formData.append('descriptions_LV', product.descriptions_LV);
  if (product.typeProduct) formData.append('typeProduct', product.typeProduct);
  if (product.typeProduct_RU) formData.append('typeProduct_RU', product.typeProduct_RU);
  if (product.typeProduct_LV) formData.append('typeProduct_LV', product.typeProduct_LV);
  if (product.interestRate) formData.append('interestRate', product.interestRate);
  if (product.interestRate_RU) formData.append('interestRate_RU', product.interestRate_RU);
  if (product.interestRate_LV) formData.append('interestRate_LV', product.interestRate_LV);
  if (product.isin) formData.append('isin', product.isin);
  if (product.term) formData.append('term', product.term);
  if (product.term_RU) formData.append('term_RU', product.term_RU);
  if (product.term_LV) formData.append('term_LV', product.term_LV);
  if (product.interestPaid) formData.append('interestPaid', product.interestPaid);
  if (product.interestPaid_RU) formData.append('interestPaid_RU', product.interestPaid_RU);
  if (product.interestPaid_LV) formData.append('interestPaid_LV', product.interestPaid_LV);
  if (product.redemption) formData.append('redemption', product.redemption);
  if (product.redemption_RU) formData.append('redemption_RU', product.redemption_RU);
  if (product.redemption_LV) formData.append('redemption_LV', product.redemption_LV);
  if (product.offerPeriodUntil) formData.append('offerPeriodUntil', product.offerPeriodUntil);
  if (product.processingFee) formData.append('processingFee', product.processingFee);
  if (product.staggeredArrangement) formData.append('staggeredArrangement', product.staggeredArrangement);
  if (product.minimalAmount) formData.append('minimalAmount', product.minimalAmount);
  if (product.stockDeposit) formData.append('stockDeposit', product.stockDeposit);
  if (product.offerAndConditions) formData.append('offerAndConditions', product.offerAndConditions);
  if (product.offerAndConditions_RU) formData.append('offerAndConditions_RU', product.offerAndConditions_RU);
  if (product.offerAndConditions_LV) formData.append('offerAndConditions_LV', product.offerAndConditions_LV);
  if (product.company) formData.append('company', product.company);
  if (product.company_RU) formData.append('company_RU', product.company_RU);
  if (product.company_LV) formData.append('company_LV', product.company_LV);
  if (product.linkToCentralBankOfLatvia) formData.append('linkToCentralBankOfLatvia', product.linkToCentralBankOfLatvia);
  if (product.isRequired) formData.append('isRequired', product.isRequired);
  if (product.logoURL) formData.append('logoURL', product.logoURL);

  return axios.put(
    `${API_URL}/api/kyc/v1/editingProduct/product`,
    formData
  )
};
const deleteProduct = (product) => {
  return axios.delete(`${API_URL}/api/kyc/v1/editingProduct/product`, {
    data: { productId: product.id }
  });
};
const addFileToProduct = ({ file, productId, name, descriptions }) => {
  const formData = new FormData();
  formData.append('productId', productId);
  formData.append('file', file);
  if (name) formData.append('name', name);
  if (descriptions) formData.append('descriptions', descriptions);

  return axios.post(
    `${API_URL}/api/kyc/v1/editingProduct/productFileToDownload`,
    formData
  )
};
const deleteFileFromProduct = (productFileToDownloadId) => {
  return axios.delete(
    `${API_URL}/api/kyc/v1/editingProduct/productFileToDownload`,
    {
      data: {
        productFileToDownloadId: productFileToDownloadId
      }
    }
  )
};

const createSection = (productId, section) => {
  return axios.post(
    `${API_URL}/api/kyc/v1/editingProduct/productSection`,
    {
      productId: productId,
      section: section.section,
      section_RU: section.section_RU,
      section_LV: section.section_LV,
      section_DE: section.section_DE,
      sortOrder: section.sortOrder
    }
  )
};
const updateSection = (section) => {
  return axios.put(
    `${API_URL}/api/kyc/v1/editingProduct/productSection`,
    {
      productSectionId: section.id,
      section: section.section,
      section_RU: section.section_RU,
      section_LV: section.section_LV,
      section_DE: section.section_DE,
      sortOrder: section.sortOrder
    }
  )
};
const deleteSection = (section) => {
  return axios.delete(`${API_URL}/api/kyc/v1/editingProduct/productSection`, {
    data: { productSectionId: section.id }
  });
};

const createGroup = (group, sectionId) => {
  return axios.post(
    `${API_URL}/api/kyc/v1/editingProduct/productGroup`,
    {
      productSectionId: sectionId,
      title: group.title,
      title_RU: group.title_RU,
      title_LV: group.title_LV,
      title_DE: group.title_DE,
      isMultiple: group.isMultiple,
      multipleMin: group.multipleMin,
      multipleMax: group.multipleMax,
      sortOrder: group.sortOrder,
      visibleConditions: group.visibleConditions,
      visibleOperator: group.visibleOperator
    }
  )
};
const updateGroup = (group) => {
  return axios.put(
    `${API_URL}/api/kyc/v1/editingProduct/productGroup`,
    {
      productGroupId: group.id,
      title: group.title,
      title_RU: group.title_RU,
      title_LV: group.title_LV,
      title_DE: group.title_DE,
      isMultiple: group.isMultiple,
      multipleMin: group.multipleMin,
      multipleMax: group.multipleMax,
      sortOrder: group.sortOrder,
      visibleConditions: group.visibleConditions,
      visibleOperator: group.visibleOperator
    }
  )
};
const deleteGroup = (group) => {
  return axios.delete(`${API_URL}/api/kyc/v1/editingProduct/productGroup`, {
    data: { productGroupId: group.id }
  });
};

const createQuestion = (question, productGroupId) => {
  return axios.post(
    `${API_URL}/api/kyc/v1/editingProduct/productQuestion`,
    {
      productGroupId: productGroupId,
      question: question.question,
      type: question.type,
      question_RU: question.question_RU,
      question_LV: question.question_LV,
      question_DE: question.question_DE,
      tooltip: question.tooltip,
      isRequired: question.isRequired,
      sortOrder: question.sortOrder,
      visibleConditions: question.visibleConditions,
      visibleOperator: question.visibleOperator,
      preFilledValue: question.preFilledValue,
      dateForSelect: question.dateForSelect,
      minCountSymbols: question.minCountSymbols,
      maxCountSymbols: question.maxCountSymbols,
      countFirstLetterSymbolsCountry: question.countFirstLetterSymbolsCountry
    }
  );
};
const updateQuestion = (question) => {
  return axios.put(
    `${API_URL}/api/kyc/v1/editingProduct/productQuestion`,
    {
      productQuestionId: question.id,
      question: question.question,
      question_RU: question.question_RU,
      question_LV: question.question_LV,
      question_DE: question.question_DE,
      tooltip: question.tooltip,
      isRequired: question.isRequired,
      sortOrder: question.sortOrder,
      visibleConditions: question.visibleConditions,
      visibleOperator: question.visibleOperator,
      preFilledValue: question.preFilledValue,
      dateForSelect: question.dateForSelect,
      minCountSymbols: question.minCountSymbols,
      maxCountSymbols: question.maxCountSymbols,
      countFirstLetterSymbolsCountry: question.countFirstLetterSymbolsCountry,
    }
  )
};
const deleteQuestion = (question) => {
  return axios.delete(`${API_URL}/api/kyc/v1/editingProduct/productQuestion`, {
    data: { productQuestionId: question.id }
  });
};

const createAnswer = (answer, questionId) => {
  return axios.post(
    `${API_URL}/api/kyc/v1/editingProduct/productAvailableAnswer`,
    {
      productQuestionId: questionId,
      answer: answer.answer,
      answer_RU: answer.answer_RU,
      answer_LV: answer.answer_LV,
      answer_DE: answer.answer_DE,
      sortOrder: answer.sortOrder,
      points: answer.points
    }
  )
};
const updateAnswer = (answer) => {
  return axios.put(
    `${API_URL}/api/kyc/v1/editingProduct/productAvailableAnswer`,
    {
      productAvailableAnswerId: answer.id,
      answer: answer.answer,
      answer_RU: answer.answer_RU,
      answer_LV: answer.answer_LV,
      answer_DE: answer.answer_DE,
      sortOrder: answer.sortOrder,
      points: answer.points
    }
  )
};
const deleteAnswer = (answer) => {
  return axios.delete(`${API_URL}/api/kyc/v1/editingProduct/productAvailableAnswer`, {
    data: { productAvailableAnswerId: answer.id }
  });
};

export const ConstructorApi = {
  createProduct,
  updateProduct,
  deleteProduct,
  addFileToProduct,
  deleteFileFromProduct,
  createSection,
  updateSection,
  deleteSection,
  createGroup,
  updateGroup,
  deleteGroup,
  createQuestion,
  updateQuestion,
  deleteQuestion,
  createAnswer,
  updateAnswer,
  deleteAnswer
};
