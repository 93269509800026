import {withTranslation} from "react-i18next";
import {Container} from "../../../../ui";
import {ProfileViewer, ProfileViewerSkeleton} from "../ProfileViewer";
import {Typography} from "antd";
import React from "react";

export const AccountsList = withTranslation()(({ t, accounts, emptyTitle = t('Nothing to display') }) => {
        const { error, list, isLoading } = accounts;

        if (isLoading) return (<Container marginTop={16}>
            {[1, 2, 3].map((item => (
                <Container marginBottom={12} key={item}>
                    <ProfileViewerSkeleton />
                </Container>
            )))}
        </Container>);

        if (error) return <Typography.Text type={'danger'}>{t('An error occurred')}</Typography.Text>;

        if (!list || list.length < 1) {
            return <Typography.Text>{emptyTitle}</Typography.Text>;
        }

        return (
            <Container marginTop={16}>
                {list.map(item => (
                    <ProfileViewer
                        avatarUrl={item.avatar}
                        name={`${item.firstName} ${item.lastName}`}
                        size={'medium'}
                        marginBottom={12}
                        key={item.id}
                    />
                ))}
            </Container>
        );
    }
);