import React from 'react';
import { useController } from 'react-hook-form';
import { Input } from '../../../../ui';

export const FormInput = ({ form, ...props }) => {
  const {
    field: { ref, ...field },
    fieldState: { error }
  } = useController({
    ...form,
    defaultValue: ''
  });

  return <Input {...field} {...props} error={error && error.message} ref={ref} />;
};
