import { useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { adminLocalizationApi, adminLocalizationKeys } from '../../../api';
import { ConstructorApi } from '../api';
import { queryClient } from '../../../app/QueryClientProvider';
import { MessageContext } from '../../../app/MessageProvider';

export const useRelatedQuestionnaire = () => {
  const messageApi = useContext(MessageContext);
  const { t } = useTranslation();
  const [isCreating, setIsCreating] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const handleGet = useCallback(async (questionSectionTypeId) => {
    try {
      setIsCreating(true);
      return  await ConstructorApi.getRelatedQuestionnaire({ questionSectionTypeId: questionSectionTypeId })
    } catch (e) {
      messageApi.error(e?.message || 'Something went wrong');
    }
  }, []);

  const handleCreate = useCallback(async (questionSectionTypeId, groupId, conditions, questions) => {
    try {
      setIsCreating(true);
      await ConstructorApi.createRelatedQuestionnaire(questionSectionTypeId, groupId, conditions, questions);
      messageApi.success(t('Created'));
    } catch (e) {
      messageApi.error(e?.message || 'Something went wrong');
    } finally {
      setIsCreating(false);
    }
  }, []);

  const handleDelete = useCallback(async (relatedQuestionnaireId) => {
    try {
      setIsDeleting(true);
      await ConstructorApi.deleteRelatedQuestionnaire(relatedQuestionnaireId);
      messageApi.success(t('Saved'));
    } catch (e) {
      messageApi.error(e?.message || 'Something went wrong');
    } finally {
      setIsDeleting(false);
    }
  }, []);

  return {
    handleGet,
    handleCreate,
    handleDelete,
    isCreating,
    isDeleting
  }
}
