import React, { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import {adminReferralApi, adminReferralApiKeys, investorApi, investorKeys} from '../../../api';
import { useDispatch, useSelector } from 'react-redux';
import { AccountsList, AccountsTree, Box } from '../../../components';
import { Button, Col, Input, Row, Typography } from 'antd';
import { AntdTypographyWrapper, Container } from '../../../ui';
import { useTranslation } from 'react-i18next';
import { MessageContext } from '../../../app/MessageProvider';
import { queryClient } from '../../../app/QueryClientProvider';
import { getErrorMessage } from '../../../utils/helpers';
import { investorActions } from '../../../store/investor';
import styled from "styled-components";
import {UserAddOutlined} from "@ant-design/icons";
import {COLORS} from "../../../utils/static";
import {SetAgentModal, SetAssistantModal} from "../../../components/admin/modals";


export const InvestorReferralRequests = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { investor } = useSelector(state => state);
  const [code, setCode] = useState('');
  const [codeOpened, setCodeOpened] = useState(false);
  const [addAssistantOpened, setAddAssistantOpened] = useState(false);
  const [addAgentOpened, setAddAgentOpened] = useState(false);
  const messageApi = useContext(MessageContext);
  const dispatch = useDispatch();

  // const { data: agentRequest } = useQuery({
  //   queryKey: [adminReferralApiKeys.getInvestorRequest, 'ROLE', 'AGENT', id],
  //   queryFn: () => adminReferralApi.getInvestorRequest({
  //     investorId: +id,
  //     role: 'AGENT',
  //     requestType: 'ROLE'
  //   }),
  //   select: data1 => data1.data
  // });
  // const { data: assistantRequest } = useQuery({
  //   queryKey: [adminReferralApiKeys.getInvestorRequest, 'ROLE', 'RECOMMENDER', id],
  //   queryFn: () => adminReferralApi.getInvestorRequest({
  //     investorId: +id,
  //     role: 'RECOMMENDER',
  //     requestType: 'ROLE'
  //   }),
  //   select: data1 => data1.data
  // });

  const { data: codeRequest } = useQuery({
    queryKey: [adminReferralApiKeys.getInvestorRequest, 'REFERRAL_CODE', id],
    queryFn: () => adminReferralApi.getInvestorRequest({
      investorId: +id,
      role: investor.accountRole,
      requestType: 'REFERRAL_CODE'
    }),
    select: data1 => data1.data,
    enabled: Boolean(investor.accountRole)
  });

  // const { data: referralCodes } = useQuery({
  //     queryKey: [adminReferralApiKeys.getReferralCodes, id],
  //     queryFn: () => adminReferralApi.getReferralCodes({
  //       investorId: +id
  //     }),
  //     select: data1 => data1.data
  //   });

  const referralsTree = useQuery({
    queryKey: [adminReferralApiKeys.getReferralsTree, id],
    queryFn: () => adminReferralApi.getReferralsTree({
      investorId: +id
    }),
    select: data1 => data1.data,
    onError: () => messageApi.error(t('Referrals fetch error'))
  });

  const agents = useQuery({
    queryKey: [adminReferralApiKeys.getAgentsList, id],
    queryFn: () => adminReferralApi.getAgentsList({
      investorId: +id
    }),
    select: data1 => data1.data,
    onError: () => messageApi.error(t('Agents fetch error'))
  });

  const recommenders = useQuery({
    queryKey: [adminReferralApiKeys.getRecommendersList, id],
    queryFn: () => adminReferralApi.getRecommendersList({
      investorId: +id
    }),
    select: data1 => data1.data,
    onError: () => messageApi.error(t('Recommenders fetch error'))
  });


  const updateReferralCode = async () => {
    try {
      await adminReferralApi.setRefferalCode({ investorId: +id, role: investor.accountRole, code: code });
      if (codeRequest && codeRequest.id) {
        await adminReferralApi.resolveRequest(codeRequest.id);
      }

      await queryClient.refetchQueries([adminReferralApiKeys.getInvestorRequest, 'REFERRAL_CODE']);
      dispatch(investorActions.setInvestorParams({ referralCode: code }));
      setCode('');
      setCodeOpened(false);
      messageApi.success(t('Referral code successfully changed'));
    } catch (e) {
      messageApi.error(t(getErrorMessage(e)));
    }
  };


  // const approveAgentRequest = async () => {
  //   try {
  //     await adminReferralApi.resolveRequest(agentRequest.id);
  //
  //     await queryClient.refetchQueries([adminReferralApiKeys.getInvestorRequest, 'ROLE']);
  //     messageApi.success(t('Agent request successfully approved'));
  //   } catch (e) {
  //     console.error(e);
  //     messageApi.error(t('Something went wrong'));
  //   }
  // };
  //
  // const approveAssistantRequest = async () => {
  //   try {
  //     await adminReferralApi.resolveRequest(assistantRequest.id);
  //
  //     await queryClient.refetchQueries([adminReferralApiKeys.getInvestorRequest, 'ROLE']);
  //     messageApi.success(t('Assistant request successfully approved'));
  //   } catch (e) {
  //     console.error(e);
  //     messageApi.error(t('Something went wrong'));
  //   }
  // };

  return (
    <AntdTypographyWrapper>
      <Box>
        <Row wrap={false} gutter={24}>
          {investor.accountRole && <Col span={6}>
            <Typography.Title level={4}>
              {t('Set referral code')}
              {codeRequest && <Typography.Text type={'warning'} style={{ marginLeft: '12px' }}>
                {t('Requested')}
              </Typography.Text>}
            </Typography.Title>

            {investor.referralCode && <Typography.Title level={5}>
              {t('Current code')}: {investor.referralCode}
            </Typography.Title>}


            {!codeOpened && <Button onClick={() => setCodeOpened(true)} type={'primary'}>
              {t('Set code')}
            </Button>}

            {codeOpened && <Container marginTop={20}>
              <Input
                value={code}
                onChange={(e) => setCode(e.target.value)}
                placeholder={t('Enter code')}
              />
              <Row gutter={8} style={{ marginTop: '16px' }}>
                <Col>
                  <Button onClick={() => setCodeOpened(false)}>
                    {t('Cancel')}
                  </Button>
                </Col>
                <Col>
                  <Button onClick={updateReferralCode} type={'primary'}>
                    {t('Save')}
                  </Button>
                </Col>
              </Row>
            </Container>}
          </Col>}

          <Col span={6}>
            <Typography.Title level={4} style={{marginTop: '4.8px'}}>
              {t('Referrals')}
            </Typography.Title>
            <AccountsTree
              accounts={{ list: referralsTree.data, error: referralsTree.isError, isLoading: referralsTree.isLoading }}
              emptyTitle={t('No referrals yet')}
              adminView
            />
          </Col>

          <Col span={6}>
            <Typography.Title level={4}>
              {t('Assistants')} <AddButton onClick={() => setAddAssistantOpened(true)} />
            </Typography.Title>
            <AccountsList
                accounts={{ list: recommenders.data, error: recommenders.isError, isLoading: recommenders.isLoading }}
                emptyTitle={t('No assistants yet')}
            />
          </Col>

          <Col span={6}>
            <Typography.Title level={4}>
              {t('Agents')} <AddButton onClick={() => setAddAgentOpened(true)} />
            </Typography.Title>
            <AccountsList
                accounts={{ list: agents.data, error: agents.isError, isLoading: agents.isLoading }}
                emptyTitle={t('No agents yet')}
            />
          </Col>

          {/*<Col span={investor.accountRole ? 8 : 12}>*/}
          {/*  <Typography.Title level={4}>*/}
          {/*    {t('Approve assistant')}*/}
          {/*    {assistantRequest && <Typography.Text type={'warning'} target={'_blank'} style={{ marginLeft: '8px' }}>*/}
          {/*      {t('Requested')}*/}
          {/*    </Typography.Text>}*/}
          {/*  </Typography.Title>*/}

          {/*  {assistantRequest && assistantRequest.recommender && <Typography.Paragraph>*/}
          {/*    {t('Requested assistant')}:*/}
          {/*    <Typography.Link*/}
          {/*      href={`${window.location.origin}/investor/${assistantRequest.recommender.id}`}*/}
          {/*      target={'_blank'}*/}
          {/*      style={{ marginLeft: '6px' }}*/}
          {/*    >*/}
          {/*      {assistantRequest.recommender.firstName} {assistantRequest.recommender.lastName}*/}
          {/*    </Typography.Link>*/}
          {/*  </Typography.Paragraph>}*/}

          {/*  <Button onClick={approveAssistantRequest} type={'primary'} disabled={!assistantRequest}>*/}
          {/*    {t('Approve')}*/}
          {/*  </Button>*/}
          {/*</Col>*/}

          {/*<Col span={investor.accountRole ? 8 : 12}>*/}
          {/*  <Typography.Title level={4}>*/}
          {/*    {t('Approve agent')}*/}
          {/*    {agentRequest && <Typography.Text type={'warning'} style={{ marginLeft: '12px' }}>*/}
          {/*      {t('Requested')}*/}
          {/*    </Typography.Text>}*/}
          {/*  </Typography.Title>*/}

          {/*  {agentRequest && agentRequest.agent && <Typography.Paragraph>*/}
          {/*    {t('Requested agent')}:*/}
          {/*    <Typography.Link*/}
          {/*      href={`${window.location.origin}/investor/${agentRequest.agent.id}`}*/}
          {/*      target={'_blank'}*/}
          {/*      style={{ marginLeft: '6px' }}*/}
          {/*    >*/}
          {/*      {agentRequest.agent.firstName} {agentRequest.agent.lastName}*/}
          {/*    </Typography.Link>*/}
          {/*  </Typography.Paragraph>}*/}

          {/*  <Button onClick={approveAgentRequest} type={'primary'} disabled={!agentRequest}>*/}
          {/*    {t('Approve')}*/}
          {/*  </Button>*/}
          {/*</Col>*/}
        </Row>
      </Box>

      <SetAssistantModal
          open={addAssistantOpened}
          onClose={() => setAddAssistantOpened(false)}
          isFirst={recommenders.data && recommenders.data.length === 0}
      />

      <SetAgentModal
          open={addAgentOpened}
          onClose={() => setAddAgentOpened(false)}
          isFirst={agents.data && agents.data.length === 0}
      />


    </AntdTypographyWrapper>
  );
};

const AddButton = styled(UserAddOutlined)`
  width: 32px;
  height: 32px;
  color: ${COLORS.aragonBlue};
  cursor: pointer;
  margin-left: 8px;
  border-radius: 6px;
  transition: 0.2s;
  :hover {
    box-shadow: 0px 2px 10px 2px rgba(8, 190, 229, 0.15) inset;
  }
  :active {
    box-shadow: 0px 2px 10px 2px rgba(8, 190, 229, 0.32) inset;
  }

  > svg {
    width: 100%;
  }
`;
