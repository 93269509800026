import { checkoutReducer } from '../../../store/checkout';

export const setCheckoutInfo = (info, callback) => (dispatch) => {
  dispatch(checkoutReducer.setInfo(info));

  if (callback) callback();
};

export const deleteCheckoutInfo = () => (dispatch) => {
  dispatch(checkoutReducer.setInfo(null));
};
