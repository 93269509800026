import { useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { adminLocalizationApi, adminLocalizationKeys } from '../../../api';

import { getDocumentSections, getDocumentTypes } from '../api/index';
import { ConstructorApi } from '../api';
import { queryClient } from '../../../app/QueryClientProvider';
import { MessageContext } from '../../../app/MessageProvider';
import { entitiesActions } from '../../../store/entities';
import { useDispatch } from 'react-redux';

export const useDocumentType = ({kycDocumentSectionId}) => {
  const messageApi = useContext(MessageContext);
  const { t } = useTranslation();
  const [isCreating, setIsCreating] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isChangingRole, setIsChangingRole] = useState(false);
  const [isChangingIsRequired, setIsChangingIsRequired] = useState(false);
  const dispatch = useDispatch();
  const handleCreate = useCallback(async (section) => {
    try {
      setIsCreating(true);
      const res = await ConstructorApi.createDocumentType(section, kycDocumentSectionId);
      await queryClient.refetchQueries({ queryFn: () => getDocumentTypes(kycDocumentSectionId) });
      messageApi.success(t('Created'));
      return res;
    } catch (e) {
      const errorMessage = e.response?.data?.message;
      if (errorMessage === 'NAME_EXIST') {
        dispatch(entitiesActions.addToast(t('NAME EXIST')));
      }
      messageApi.error(e?.message || 'Something went wrong');
    } finally {
      setIsCreating(false);
    }
  }, []);

  const handleUpdate = useCallback(async (section) => {
    try {
      setIsUpdating(true);
      await ConstructorApi.updateDocumentType(section);
      await queryClient.refetchQueries({ queryFn: () => getDocumentTypes(kycDocumentSectionId) });
      messageApi.success(t('Saved'));
    } catch (e) {
      const errorMessage = e.response?.data?.message;
      if (errorMessage === 'NAME_EXIST') {
        dispatch(entitiesActions.addToast(t('NAME EXIST')));
      }
      messageApi.error(e?.message || 'Something went wrong');
    } finally {
      setIsUpdating(false);
    }
  }, []);

  const handleDelete = useCallback(async (type) => {
    try {
      setIsDeleting(true);
      await ConstructorApi.deleteDocumentType(type);
      await queryClient.refetchQueries({  queryFn: () => getDocumentTypes(kycDocumentSectionId) });
      messageApi.success(t('Deleted'));
    } catch (e) {
      messageApi.error(e?.message || 'Something went wrong');
    } finally {
      setIsDeleting(false);
    }
  }, []);

  const handleChangeRole = useCallback(async (role, checked, section) => {
    try {
      setIsChangingRole(true);
      const isRequired = false;
      if (checked) {
        await ConstructorApi.addRoleDocumentType(section.id, role, isRequired);
      } else {
        await ConstructorApi.deleteRoleDocumentType(section.id, role);
      }
      await queryClient.refetchQueries({ queryKey: [getDocumentTypes(section.kycDocumentSectionId)] });

      if (checked) {
        messageApi.success(t('Role Added'));
      } else {
        messageApi.success(t('Role Removed'));
      }

    } catch (e) {
      messageApi.error(e?.message || 'Something went wrong');
    } finally {
      setIsChangingRole(false);
    }
  }, []);


  const handleChangeIsRequired = useCallback(async (role, checked, section) => {
    try {
      setIsChangingIsRequired(true);
      let isRequired = checked;

      await ConstructorApi.addRoleDocumentType(section.id, role, isRequired);

      await queryClient.refetchQueries({ queryKey: [getDocumentTypes(section.kycDocumentSectionId)] });

      if (checked) {
        messageApi.success(t('Is Required ' + role));
      } else {
        messageApi.success(t('Not Required ' + role));
      }

    } catch (e) {
      messageApi.error(e?.message || 'Something went wrong');
    } finally {
      setIsChangingIsRequired(false);
    }
  }, []);

  return {
    handleCreate,
    handleUpdate,
    handleDelete,
    handleChangeRole,
    handleChangeIsRequired,
    isCreating,
    isUpdating,
    isDeleting,
    isChangingRole,
    isChangingIsRequired,
  }
}
