import React, { useEffect, useState } from 'react';
import { GU } from '@aragon/ui';
import { SearchInput, DateRangePicker } from '../../../../components';
import { Row, Col, Container, DropDown, Body3Light } from '../../../../ui';
import { newObject } from '../../../../utils/helpers';
import {
  ORDER_TYPES,
  ORDER_TYPES_LIST,
  ASSET_TYPES,
  ASSET_TYPES_LIST,
  VALID_TILL_OPTIONS,
  COLORS
} from '../../../../utils/static';

export const OrdersHistoryFilters = ({ filters, changeFilters, ...props }) => {
  return (
    <Container {...props}>
      <Row cols={4} margin={-GU}>
        <Col marginBottom={2 * GU} width={'50%'}>
          <SearchInput
            value={filters.ticker}
            onChange={(val) => changeFilters('ticker', val)}
            placeholder={'Ticker'}
            wide
          />
        </Col>

        <Col marginBottom={2 * GU}>
          <DropDown
            items={CURRENCIES}
            selected={filters.currencyIndex}
            onChange={(index) => changeFilters('currencyIndex', index)}
            placeholder={<Body3Light color={COLORS.iconColor}>Currency</Body3Light>}
            width='100%'
          />
        </Col>

        <Col marginBottom={2 * GU}>
          <DateRangePicker
            startDate={filters.dateRange.start}
            endDate={filters.dateRange.end}
            onChange={(range) => changeFilters('dateRange', range)}
          />
        </Col>
      </Row>

      <Row cols={4} margin={-GU}>
        <Col marginBottom={2 * GU}>
          <DropDown
            items={['All security types', ...ASSET_TYPES_LIST.map((item) => ASSET_TYPES[item])]}
            selected={filters.securityTypeIndex}
            onChange={(index) => changeFilters('securityTypeIndex', index)}
            placeholder={<Body3Light color={COLORS.iconColor}>Security type</Body3Light>}
            width='100%'
          />
        </Col>

        <Col marginBottom={2 * GU}>
          <DropDown
            items={['All order types', ...ORDER_TYPES_LIST.map((item) => ORDER_TYPES[item])]}
            selected={filters.orderTypeIndex}
            onChange={(index) => changeFilters('orderTypeIndex', index)}
            placeholder={<Body3Light color={COLORS.iconColor}>Order type</Body3Light>}
            width='100%'
          />
        </Col>

        <Col marginBottom={2 * GU}>
          <DropDown
            items={['All', ...VALID_TILL_OPTIONS]}
            selected={filters.validTillIndex}
            onChange={(index) => changeFilters('validTillIndex', index)}
            placeholder={<Body3Light color={COLORS.iconColor}>Valid till</Body3Light>}
            width='100%'
          />
        </Col>
      </Row>
    </Container>
  );
};

const CURRENCIES = ['All', 'EUR'];
const CURRENCY_VALUE = {
  All: null,
  EUR: 'EUR'
};
