import React from 'react';
import { GU, IconAdd } from '@aragon/ui';
import { StatusLine, Box, InnerBox } from '../../../../components';
import styled from 'styled-components';
import { Body2Light, Input } from '../../../../ui';

export const AccountSelector = ({ accounts, selected, onSetAccount, onAddAccount, ChangeName }) => {
  return (
    <div>
      <Box padding={0} innerPadding={'0'}>
        {accounts &&
          accounts.map((account, key) => (
            <InnerBox key={key}>
              {!account.edited ? (
                <AccountSelectButton active={key === selected} onClick={() => onSetAccount(key)}>
                  <Body2 marginBottom={0.5 * GU}>{account.accountName}</Body2>
                  <StatusLine type={'accepted'} title={'Verified'} colored />
                </AccountSelectButton>
              ) : (
                <AccountSelectButton
                  active={key === selected}
                  onClick={() => onSetAccount(key)}
                  padding={2 * GU}
                >
                  <Input
                    label={'Account name'}
                    marginBottom={'0'}
                    value={account.accountName}
                    onChange={ChangeName}
                  />
                </AccountSelectButton>
              )}
            </InnerBox>
          ))}
      </Box>

      {onAddAccount && (
        <AddButtonWrapper onClick={onAddAccount}>
          <AddIcon />
          <Body2>Add another account</Body2>
        </AddButtonWrapper>
      )}
    </div>
  );
};

const Body2 = styled(Body2Light)``;
const AddIcon = styled(IconAdd)``;
const AccountSelectButton = styled.div`
  border-left: ${({ active }) => (active ? '2px solid #08BEE5' : '2px solid transparent')};

  padding: ${({ padding }) => (padding ? `${padding}px` : `${3 * GU}px`)};
  border-radius: 2px;
  margin-left: -1px;
  cursor: pointer;

  ${Body2} {
    font-weight: ${({ active }) => (active ? `400` : '')};
  }
`;
const AddButtonWrapper = styled.div`
  margin-top: ${2 * GU}px;
  padding: ${2 * GU}px;
  display: flex;
  align-items: center;
  border-radius: 3px;
  background: #ffffff;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15);
  color: #8fa4b5;
  cursor: pointer;

  ${Body2} {
    color: #8fa4b5;
    line-height: 32px;
    position: relative;
    top: 2px;
  }

  ${AddIcon} {
    width: 32px;
    height: 32px;
    margin-right: 12px;
  }
`;
