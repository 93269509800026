import React, { useEffect, useMemo, useState } from 'react';
import { SyncIndicator } from '@aragon/ui';
import { useParams, useLocation } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import { adminKYCApi } from '../../../../api/admin/kyc';
import { history, scrollToTop } from '../../../../routing';
import { ENABLED_QUESTS_TO_INTERACT_STATUSES_ADMIN, SHOWED_SECTIONS_COUNT } from '../../../../utils/static/kyc';
import { Toast } from '../../../../modules/kycModule/components/Toast';
import { RejectModal } from '../../../../modules/Questionnaire/components/modals';
import { userActions } from '../../../../store/user';
import { useDispatch } from 'react-redux';
import { kycHelpers } from '../../../../utils/helpers/kyc';
import { entitiesActions } from '../../../../store/entities';
import { useTranslation } from 'react-i18next';
import { ProductView } from '../../../../modules';

export const ProductAnswers = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { id, productId } = useParams();
  const { state } = useLocation();
  const [toast, setToast] = useState('');
  const [currentSection, setCurrentSection] = useState(0);
  const [rejectedQuestions, setRejectedQuestions] = useState(null);
  const [isModalActive, setModalActive] = useState(false);
  const [comment, setComment] = useState('');
  const [commentError, setCommentError] = useState('');

  const { data: res, refetch: refetchProduct, isFetching } = useQuery(
    ['getProductAnswers'],
    () => adminKYCApi.getProductAnswers({ investorId: id, investorProductId: productId }),
    {
      cacheTime: 'Infinity',
      onSuccess: async ({ data: product }) => {
        try {
          if (product?.status === 'QUESTION_UNACCEPTED' || product?.status === 'UNACCEPTED') {
            const res = await adminKYCApi.getProductRejectedEntities(id);
            const rejectedQuestions = res?.data?.question;

            const { rejectedObj } = kycHelpers.getProductRejectedObject(
              product,
              rejectedQuestions
            );

            if (rejectedObj) setRejectedQuestions(rejectedObj);
          }
        } catch (e) {
        }
      }
    }
  );
  const { mutateAsync: setProductStatus } = useMutation(({ status, comment }) =>
    adminKYCApi.setProductStatus({
      investorId: id,
      status: status,
      productId: productId,
      comment: comment
    })
  );

  useEffect(() => {
    dispatch(userActions.hideAppContainer());
    return () => dispatch(userActions.showAppContainer());
  }, []);

  const stepsCount = useMemo(() => {
    if (res?.data?.productSections)
      return Math.ceil(res.data.productSections.length / SHOWED_SECTIONS_COUNT);
    return 0;
  }, [res]);

  const statusSettings = useMemo(() => {
    if (res?.data) {
      const status = res?.data?.status;
      const isRejected = status === 'UNACCEPTED';
      const isApproved = status === 'ACCEPTED';
      return {
        isRejected: isRejected,
        isApproved: isApproved,
        allowReject: !isRejected && ENABLED_QUESTS_TO_INTERACT_STATUSES_ADMIN[status],
        allowApprove: !isApproved && ENABLED_QUESTS_TO_INTERACT_STATUSES_ADMIN[status]
      };
    }

    return null;
  }, [res]);

  const rejectProduct = async () => {
    try {
      if (comment) {
        await setProductStatus({ status: 'UNACCEPTED', comment });
        setToast(t('Rejected'));
        await refetchProduct();
      } else {
        setCommentError(t('Required'));
      }
    } catch (e) {
      let errorMessage = e?.response?.data?.error?.message;
      if (!errorMessage) errorMessage = t('Something went wrong');

      dispatch(entitiesActions.addToast(errorMessage));
      console.log(e);
    }
  };
  const acceptProduct = async () => {
    try {
      await setProductStatus({ status: 'ACCEPTED' });
      setToast(t('Accepted'));
      await refetchProduct();
    } catch (e) {
      let errorMessage = e?.response?.data?.error?.message;
      if (!errorMessage) errorMessage = t('Something went wrong');

      dispatch(entitiesActions.addToast(errorMessage));
      console.log(e);
    }
  };

  const onNext = () => {
    if (currentSection + 1 < stepsCount) {
      setCurrentSection(currentSection + 1);
      scrollToTop();
    }
  };
  const onBack = () => {
    if (currentSection > 0) {
      setCurrentSection(currentSection - 1);
      scrollToTop();
    } else {
      state && state.prevPath ? history.push(state.prevPath) : history.goBack();
    }
  };

  const addRejectedQuestion = (questionId) => {
    setRejectedQuestions({ ...rejectedQuestions, [questionId]: 'fake_comment' });
  };

  // const loading =
  //   isFetching || !res || res?.data?.investorQuestionSectionTypeId !== questionnaireId;

  const loading = isFetching;
  return (
    <div>
      {!loading && res?.data && (
        <ProductView
          product={res.data}
          currentSection={currentSection}
          onNext={onNext}
          onBack={onBack}
          adminView
          rejectProduct={() => setModalActive(true)}
          acceptProduct={acceptProduct}
          rejectedQuestions={rejectedQuestions}
          addRejectedQuestion={addRejectedQuestion}
          statusSettings={statusSettings}
          stepsCount={stepsCount}
          setCurrentSection={setCurrentSection}
        />
      )}
      {!loading && !res?.data && <div>
        {t('Something went wrong')}
      </div>}

      <RejectModal
        active={isModalActive}
        onClose={() => setModalActive(false)}
        comment={comment}
        setComment={setComment}
        error={commentError}
        onSubmit={rejectProduct}
      />

      <SyncIndicator visible={loading}>{t('Loading')}...</SyncIndicator>
      <Toast toast={toast} removeToast={() => setToast('')} />
    </div>
  );
};
