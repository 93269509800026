import { COLORS } from '../colors';
import { IconCircleCheck, IconClock } from '@aragon/ui';

export const DEAL_STATUSES = {
  EXECUTED_DEAL: {
    title: 'Executed',
    color: COLORS.mintGreen,
    icon: IconCircleCheck
  },
  IN_PROCESS_DEAL: {
    title: 'In process...',
    color: COLORS.iconColor,
    icon: IconClock
  }
};

export const DEAL_STATUS_TABS = ['All deals', 'In process', 'Executed'];

export const DEAL_TAB_STATUSES = [null, 'IN_PROCESS_DEAL', 'EXECUTED_DEAL'];
