import React from 'react';
import { Table, TableRow, Text } from '@aragon/ui';
import { StatusLine, DirectionBadge } from '../../../../components';
import { DetailsButton, Cell, TableHeader } from '../../components';
import { Row, Link } from '../../../../ui';
import styled from 'styled-components';
import {
  COLORS,
  ASSET_TYPES,
  DEAL_STATUSES,
  SIDE_CONFIG,
  BUY_ASSET_FEE_PERCENT
} from '../../../../utils/static';
import { displayFullDate } from '../../../../utils/helpers';
import { history } from '../../../../routing';

const defHeader = [
  'Deal ID',
  'trade date',
  'Asset id',
  'asset Type',
  'Quantity ',
  'Status',
  'Price',
  'Fee',
  'Direction'
];
export const DealsTable = ({ header = defHeader, data = [], onDetailsSideEffect }) => {
  const onDetailsClick = (id) => {
    onDetailsSideEffect();
    history.push(`/deal/${id}`);
  };

  const headerItems = header.map((item, key) => <TableHeader title={item} key={key} />);

  const bodyItems =
    data && data.length > 0 ? (
      data.map((item, key) => (
        <TableRow key={key}>
          <Cell>{item.id}</Cell>
          <Cell>{item.tradeDate ? displayFullDate(new Date(item.tradeDate)) : ''}</Cell>

          <Cell>
            <Link to={`/assets/${item.assetId}`} color={COLORS.blue} external={'true'}>
              {item.assetId}
            </Link>
          </Cell>

          <Cell>{item.asset && ASSET_TYPES[item.asset.assetType]}</Cell>
          <Cell>
            {item.quantity} {item.asset.ticker}
          </Cell>

          <Cell>
            {DEAL_STATUSES[item.status] && (
              <StatusLine statusTypes={DEAL_STATUSES} type={item.status} />
            )}
          </Cell>

          <Cell>
            {item.totalPrice} {item.asset.currency}
          </Cell>
          <Cell>
            {BUY_ASSET_FEE_PERCENT}%&ensp;
            {item.asset && (
              <Text color={COLORS.greyMedium} style={{ whiteSpace: 'nowrap' }}>
                {item.fee} {item.asset.currency}
              </Text>
            )}
          </Cell>

          <Cell>
            <Row justifyContent={'center'} wide>
              <DirectionBadge title={item.side} />
            </Row>
          </Cell>

          {/*<Cell>*/}
          {/*<ProfitLine*/}
          {/*  profit={item.profit}*/}
          {/*  profitPercent={item.profitPercent}*/}
          {/*/>*/}
          {/*</Cell>*/}

          <Cell cellProps={{ align: 'right' }}>
            <DetailsButton onClick={() => onDetailsClick(item.id)} />
          </Cell>
        </TableRow>
      ))
    ) : (
      <TableRow>
        <Cell cellProps={{ style: { border: 'none' } }}>No deals</Cell>
      </TableRow>
    );

  return (
    <StyledTable header={<TableRow>{headerItems}</TableRow>} noSideBorders>
      {bodyItems}
    </StyledTable>
  );
};

const StyledTable = styled(Table)`
  th {
    border-left: none;
    border-right: none;
  }
`;
