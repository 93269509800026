import React, { useState } from 'react';
import { history } from '../../../../../routing';
import { GU, Split } from '@aragon/ui';
import {
  OrderDetailsBox,
  BackBar,
  Box,
  ProfileViewerExtendedByDetails
} from '../../../../../components';
import {
  PageContainer,
  Container,
  Row,
  Col,
  Title2,
  RejectButton,
  ExecuteButton,
  DropDown
} from '../../../../../ui';
import styled from 'styled-components';
import { SETTLEMENT_TYPES } from '../../../../../utils/static/orders';

export const OrderDetailsView = ({ order }) => {
  const [settlementTypeIndex, setSettlementTypeIndex] = useState(0);

  if (order) {
    return (
      <div>
        <PageContainer paddingTop={3 * GU} paddingBottom={2 * GU}>
          <Title2 marginBottom={2 * GU}>Order details</Title2>
          <BackBar />

          <Container marginTop={3 * GU}>
            <Split
              primary={
                <div>
                  <OrderDetailsBox order={order} />

                  <Box heading={'Client'} paddingY={2 * GU} paddingX={3 * GU}>
                    <ProfileViewerExtendedByDetails
                      firstName={order.investor.firstName}
                      lastName={order.investor.lastName}
                      avatarUrl={order.investor.avatarUrl}
                      verified={order.investor.status === 'ACTIVE'}
                      handleDetails={() => history.push(`/investor/${order.investor.id}`)}
                    />
                  </Box>
                </div>
              }
              secondary={<Box heading={'Note'}></Box>}
            />
          </Container>
        </PageContainer>

        <ExecuteBox paddingLeft={'37'} paddingRight={6 * GU}>
          <Row justifyContent={'space-between'} alignItems={'flex-end'}>
            <Col auto>
              <RejectButton />
            </Col>

            <Col auto>
              <Row margin={-GU} alignItems={'flex-end'}>
                <Col>
                  <DropDown
                    label={'Settlement type'}
                    items={SETTLEMENT_TYPES}
                    selected={settlementTypeIndex}
                    onChange={(item) => setSettlementTypeIndex(item)}
                    minWidth={'252px'}
                  />
                </Col>
                <Col>
                  <ExecuteButton />
                </Col>
              </Row>
            </Col>
          </Row>
        </ExecuteBox>
      </div>
    );
  }

  return null;
};

const ExecuteBox = styled(Box)`
  position: sticky;
  bottom: 0;
  margin-left: -${2 * GU}px;
  margin-right: -${3 * GU}px;
`;
