import React, { useCallback, useEffect, useRef, useState } from 'react';
import { IconCalendar } from '@aragon/ui';
import { Input } from '../Input';
import { COLORS } from '../../../utils/static';
import styled from 'styled-components';
import { dateString } from '../../../utils/helpers';
import DatePickerAragon from '@aragon/ui/dist/DatePicker';
import { default as ReactDatePicker, registerLocale } from 'react-datepicker';
import ru from 'date-fns/locale/ru';
import de from 'date-fns/locale/de';
import { useTranslation } from 'react-i18next';

const tzOffset = new Date().getTimezoneOffset() * 60000; //timezone offset in milliseconds
const clientHeight = document.documentElement.clientHeight;
const clientWidth = document.documentElement.clientWidth;
const PICKER_HEIGHT = 350;
const PICKER_WIDTH = 300;

export const DatePicker = ({
  label,
  tooltip,
  selected,
  onChange,
  disabled,
  required = false,
  error,
  showYearDropdown = false,
  ...props
}) => {
  const {i18n} = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [posTop, setPosTop] = useState(false);
  const [posLeft, setPosLeft] = useState(false);
  const ref = useRef();
  registerLocale('ru', ru);
  registerLocale('de', de);

  const openModal = () => {
    !disabled && setShowModal(true);
  };

  const hideModal = useCallback((e) => {
    if (!e.target.closest('div[data-picker-modal]')) {
      setShowModal(false);
    }
  }, []);

  useEffect(() => {
    if (showModal) {
      document.addEventListener('click', hideModal);

      try {
        const { bottom, left } = ref.current.getBoundingClientRect();

        if (clientHeight - bottom < PICKER_HEIGHT) setPosTop(true);
        if (clientWidth - left < PICKER_WIDTH) setPosLeft(true);
      } catch (e) {
        console.log(e);
      }
    } else {
      document.removeEventListener('click', hideModal);
      setPosTop(false);
      setPosLeft(false);
    }
  }, [showModal]);

  const onSelect = (value) => {
    onChange(value ? new Date(value - tzOffset) : value);
    setShowModal(false);
  };

  return (
    <Wrapper data-picker-modal>
      {showYearDropdown && (
        <ReactDatePicker
          showYearDropdown
          showMonthDropdown
          selected={selected || null}
          onChange={onSelect}
          maxDate={new Date().setFullYear(new Date().getFullYear() + 200)}
          disabled={props.adminView || disabled}
          locale={i18n.language}

          customInput={
            <Input
              label={label}
              tooltip={tooltip}
              isRequired={required}
              value={selected ? dateString(new Date(selected)) : ''}
              adornment={<IconCalendar color={COLORS.iconColor} />}
              adornmentPosition={'end'}
              wide
              readOnly
              error={error ? error.message : ''}
            />
          }
          {...props}
        />
      )}

      {!showYearDropdown && (
        <div onClick={openModal} ref={ref}>
          <Input
            label={label}
            tooltip={tooltip}
            value={selected ? dateString(new Date(selected)) : ''}
            adornment={<IconCalendar color={COLORS.iconColor} />}
            adornmentPosition={'end'}
            wide
            readOnly
            required={required}
            error={error ? error.message : ''}
          />
        </div>
      )}

      {!showYearDropdown && (
        <PickerModal showModal={showModal} posTop={posTop} posLeft={posLeft}>
          <DatePickerAragon
            datesRangeStart={selected || new Date()}
            datesRangeEnd={null}
            onSelect={onSelect}
            {...props}
          />
        </PickerModal>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  cursor: pointer;

  input {
    cursor: pointer;
  }
  > .react-datepicker-wrapper {
    width: 100%;
  }
`;
const PickerModal = styled.div`
  position: absolute;
  top: ${(p) => (p.posTop ? 'unset' : 'calc(100% + 2px)')};
  bottom: ${(p) => (!p.posTop ? 'unset' : 'calc(100% + 2px)')};
  left: ${(p) => (p.posLeft ? 'inset' : '0')};
  right: ${(p) => (!p.posLeft ? 'inset' : '0')};
  z-index: 99;
  display: flex;
  justify-content: center;
  background: white;
  width: 270px;
  border-radius: 8px;
  padding: 8px;
  border: 1px solid #9da0a3;
  transition: opacity 0.15s ease-out;
  transform: scale(0);
  opacity: 0;

  ${(p) =>
    p.showModal
      ? `
    transform: none;
    opacity: 1;
  `
      : ''}
`;
