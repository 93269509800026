import React from 'react';
import { GU } from '@aragon/ui';
import { RobotoBody4 } from '../../../../ui';
import styled from 'styled-components';

export const DirectionBadge = ({ title }) => {
  return (
    <Container>
      <RobotoBody4>{title}</RobotoBody4>
    </Container>
  );
};

const Container = styled.div`
  background: rgba(149, 236, 255, 0.5);
  border-radius: 20px;
  padding: 0 ${GU}px;
  min-height: 18px;
`;
