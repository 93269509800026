import { adminLocalizationApi, adminLocalizationKeys } from '../../../api';
import { useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MessageContext } from '../../../app/MessageProvider';

export const useRelatedSections = () => {
  const messageApi = useContext(MessageContext);
  const { t } = useTranslation();
  const [isCreating, setIsCreating] = useState(false);

  const handleGet = useCallback(async (parentQuestionnaireId) => {
    try {
      setIsCreating(true);
      return  await adminLocalizationApi.getQuestionnaireSections({ questionSectionTypeId: parentQuestionnaireId })
    } catch (e) {
      messageApi.error(e?.message || 'Something went wrong');
    } finally {
      setIsCreating(false);
    }
  }, []);


  return {
    handleGet,
    isCreating,
  }
}

