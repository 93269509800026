import React from 'react';
import { Header, GU } from '@aragon/ui';
import { IssuesTableBox } from '../../../../../containers';
import { IssuersTableBox } from '../../../../../components';
import { Button, PageContainer, Title2, Link, Row } from '../../../../../ui';

export const IssuesView = ({ issuers, fetchIssuers, ISSUERS_ON_PAGE }) => {
  return (
    <PageContainer>
      <Header
        primary={<Title2>Issues</Title2>}
        secondary={
          <Row margin={-1.5 * GU}>
            <Link to={'/create-issue'}>
              <Button label={'New issue'} />
            </Link>
            <Link to={'/create-issuer'}>
              <Button label={'Create issuer profile'} mode={'strong'} paddingX={'14'} />
            </Link>
          </Row>
        }
      />

      <IssuesTableBox />

      <IssuersTableBox
        issuers={issuers}
        fetchIssuers={fetchIssuers}
        ISSUERS_ON_PAGE={ISSUERS_ON_PAGE}
      />
    </PageContainer>
  );
};
