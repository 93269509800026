import React from 'react';
import { Header, Button } from '@aragon/ui';

const MainPage = () => {
  return (
    <div>
      <Header primary='Tokens' secondary={<Button mode='strong' label='Add tokens' />} />
    </div>
  );
};

export default MainPage;
