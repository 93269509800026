import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Checkbox, Button } from 'antd';

import { useQuestionnaireSections } from '../hooks';

export const QuestionnaireDependedAnswers = ({onAddAnswers, visibleConditions}) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const {data} = useQuestionnaireSections(id);
  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [answers, setAnswers] = useState([]);

  useEffect(() => {
    if (!data || !data.length) return;

    let qsts = [];
    let answrs = [];
    data.forEach(section => {
      if (Array.isArray(section.questionGroups)) {
        section.questionGroups.forEach(group => {
          if(Array.isArray(group.questions)) {
            group.questions.forEach(question => {
              if(question.availableAnswers.length) {
                qsts.push(question);
                question.availableAnswers.forEach((answer) => {
                  if(Array.isArray(visibleConditions)) {
                    const found = visibleConditions.find(cond => cond.value === answer.id);
                    if (found) {
                      answrs.push(answer);
                    }
                  }
                })
              }
            })
          }
        })
      }
    })

    setLoading(false);
    setQuestions(qsts);
    setAnswers(answrs);
  }, [data, visibleConditions])

  const onChange = (e, answer) => {
    let newAnsws;

    if (e.target.checked) {
      newAnsws = [...answers, answer]
    } else {
      newAnsws = answers.filter(a => a.id !== answer.id)
    }

    setAnswers(newAnsws);
  }

  const onAdd = () => {
    onAddAnswers(answers);
  }

  if (loading) return (
    <div>Loading...</div>
  )

  return (
    <div>
      <Box>
        {questions.map(question => (
          <QuestionBox>
            <div>{question.question}</div>
            <div>
              {question.availableAnswers.map(answer => (
                <AnswerBox>
                  <Checkbox onChange={(e) => onChange(e, answer)}
                            checked={answers.includes(answer)}
                  >
                    {answer.answer}
                  </Checkbox>
                </AnswerBox>
              ))}
            </div>
          </QuestionBox>
        ))}
      </Box>
      <BtnBox>
        <Button onClick={onAdd}
                type="primary"
                style={{marginTop: '30px'}}
        >
          {t('Add answers')}
        </Button>
      </BtnBox>
    </div>
  );
};

const Box = styled.div`
  height: 600px;
  overflow-y: scroll;
`

const AnswerBox = styled.div`
  padding-left: 20px;
`

const QuestionBox = styled.div`
  margin-bottom: 10px;
`

const BtnBox = styled.div`
  text-align: right;
`