import React from 'react';
import { adminApi } from '../../../../../api/admin';
import { CreateIssueView } from '../View';
import { history } from '../../../../../routing';

export const CreateIssue = () => {
  const handleCreateIssue = (params, issueDocuments) => {
    adminApi.createIssue(params, issueDocuments).then((res) => {
      if (res.status === 200) {
        history.push('/issues');
      }
    });
  };

  return <CreateIssueView CreateIssue={handleCreateIssue} />;
};
