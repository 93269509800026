import React, { useContext, useMemo, useState } from 'react';
import { Modal, Typography, Button, Popconfirm, Select } from 'antd';
import { MessageContext } from '../../../../app/MessageProvider';
import { Container } from '../../../../ui';
import { useTranslation } from 'react-i18next';
import {useParams} from "react-router-dom";
import { adminApi } from '../../../../api';

const typeItems = [
  { value: 'personal', label: 'Investor' },
  { value: 'agent', label: 'Agent' },
  { value: 'recommender', label: 'Assistant' },
]

export const UpdateProfileTypeModal = ({ open, onClose, isAgent, isRecommender, refetchInvestorInfo }) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const [type, setType] = useState(null);
  const messageApi = useContext(MessageContext);

  const currentProfileType = useMemo(() => {
    if (isRecommender === undefined || isAgent === undefined) return null;
    return isAgent ? 'agent' : isRecommender ? 'recommender' : 'personal';
  }, [isAgent, isRecommender, type]);

  const handleClose = () => {
    setType(null);
    onClose();
  }

  const changeProfileType = async () => {
    if (type) {
      try {
        const result = await adminApi.updateUserRole({ investorId: +id, role: type.toUpperCase() });

        if(result) {
          refetchInvestorInfo();
          messageApi.success(t('Profile type is changed'));
          setType(null);
          onClose();
        }
      } catch (e) {
        console.error(e);
        messageApi.error(e.message);
      }
    } else {
      messageApi.warning(t('Please choose new profile type'));
    }
  };

  return (
    <Modal open={open} onCancel={handleClose} title={t('Change profile type')}
           footer={[
             <Button key="back" onClick={handleClose}>
               {t("Cancel")}
             </Button>,
             <Popconfirm
               title={t("Change profile type")}
               description={t("Are you sure you want to change the profile's type?")}
               okText={t("Yes")}
               cancelText={t("No")}
               onConfirm={changeProfileType}
             >
               <Button type="primary" disabled={!type} style={{marginLeft: '4px'}}>
                 {t('Change')}
               </Button>
             </Popconfirm>
           ]}>
      <Container marginTop={16} marginBottom={10}>
        <Typography.Text style={{fontSize: '16px'}}>{t('Current type:')}</Typography.Text>{' '}
        <Typography.Text style={{fontSize: '16px'}} strong>{currentProfileType ? typeItems.find((i) => i.value === currentProfileType).label : '-'}</Typography.Text>
      </Container>
      <Select
        style={{ width: '100%', marginBottom: '8px' }}
        placeholder="Select type"
        value={type}
        onChange={setType}
        options={typeItems.filter((type) => {
          return type.value !== currentProfileType
        })}
      />
    </Modal>
  );
};
