import React from 'react';
import { useParams } from 'react-router-dom';

import { useProductsSection } from '../hooks';
import { Section } from '../../constructorCommon';

export const ProductSection = ({sectionProp, onCreated}) => {
  const { id } = useParams();
  const {
    handleCreate,
    handleUpdate,
    handleDelete,
    isCreating,
    isUpdating,
    isDeleting
  } = useProductsSection({productId: id});

  return (
    <Section sectionProp={sectionProp}
             onCreated={onCreated}
             handleCreate={handleCreate}
             handleUpdate={handleUpdate}
             handleDelete={handleDelete}
             isCreating={isCreating}
             isUpdating={isUpdating}
             isDeleting={isDeleting}
             type='products'
    />
  );
};