import React, { useEffect, useState } from 'react';
import { adminApi } from '../../../../../api';
import { AssetsView } from '../View';

const ASSETS_ON_PAGE = 5;
export const Assets = () => {
  const [assets, setAssets] = useState(null);

  useEffect(() => {
    fetchAssets();
    fetchTotalInstruments();
  }, []);

  const fetchAssets = (params) => {
    adminApi
      .getAssets({
        take: ASSETS_ON_PAGE,
        ...params
      })
      .then((res) => {
        if (res.status === 200) {
          setAssets(res.data);
        }
      });
  };

  const fetchTotalInstruments = () => {
    adminApi.getTotalInstruments().then(console.log);
  };

  return <AssetsView assets={assets} ASSETS_ON_PAGE={ASSETS_ON_PAGE} fetchAssets={fetchAssets} />;
};
