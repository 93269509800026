import React, { useMemo } from 'react';
import { GU } from '@aragon/ui';
import {
  Col,
  PageContainer,
  Row,
  Body2Light,
  Title2,
  Button,
  RejectButton,
  ExecuteButton
} from '../../../../../ui';
import {
  BackBar,
  TransactionDetailsBox,
  BankAccountBox,
  BoxLine,
  Box,
  InnerBox
} from '../../../../../components';

export const TransactionDetailsView = ({ transaction, executeTransaction, rejectTransaction }) => {
  console.log(transaction);

  const income = useMemo(
    () => (transaction ? transaction.type === 'FUNDING' : false),
    [transaction]
  );

  if (transaction) {
    const personalAccount = transaction.fromPersonalAccount || transaction.toPersonalAccount || {};
    const paymentAccount = transaction.fromPaymentDetail || transaction.toPaymentDetail || {};

    return (
      <PageContainer paddingTop={2.5 * GU}>
        <Row justifyContent={'space-between'} marginBottom={2.5 * GU}>
          <Title2 marginTop={'6'}>Transaction Details</Title2>

          {transaction.status === 'PENDING' && (
            <Row margin={-GU}>
              <Col>
                <RejectButton onClick={() => rejectTransaction(transaction.id)} />
              </Col>
              <Col>
                <ExecuteButton onClick={() => executeTransaction(transaction.id)} />
              </Col>
            </Row>
          )}
        </Row>

        <BackBar />

        <Row margin={-GU} cols={2}>
          <Col>
            <TransactionDetailsBox data={{ currency: personalAccount.currency, ...transaction }} />

            {transaction.paymentDetails && (
              <Box heading={'PAYMENT DETAILS'}>
                <Body2Light>{transaction.paymentDetails}</Body2Light>
              </Box>
            )}
          </Col>

          <Col>
            <Row flexDirection={income ? 'column-reverse' : 'column'}>
              <Box
                heading={income ? 'Receiver' : 'Sender'}
                padding={0}
                marginTop={'0'}
                marginBottom={2 * GU}
              >
                <InnerBox>
                  <Body2Light>{personalAccount?.accountName}</Body2Light>
                </InnerBox>

                <InnerBox paddingTop={2 * GU}>
                  <BoxLine title={'ID'} data={personalAccount.id} />
                  {/*<BoxLine title={'Blockchain adress'}>*/}
                  {/*  <IdentityBadge*/}
                  {/*    entity={mockAccount.blockchainAddress}*/}
                  {/*    labelOnly*/}
                  {/*  />*/}
                  {/*</BoxLine>*/}
                </InnerBox>
              </Box>

              <BankAccountBox
                data={paymentAccount}
                address={transaction.paymentDetailAddresses[0]}
                heading={'Bank account'}
                marginTop={'0'}
                marginBottom={2 * GU}
              />
            </Row>
          </Col>
        </Row>
      </PageContainer>
    );
  }

  return null;
};
