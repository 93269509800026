import React from 'react';
import { useDispatch } from 'react-redux';
import { investorActions } from '../../../store/investor';
import { history } from '../../../routing';
import { GU } from '@aragon/ui';
import { KYCModule } from '../../../modules';
import { tier2Api, tier2Config as config } from '../../../modules/kycModule';
import styled from 'styled-components';

export const Tier2Page = () => {
  const dispatch = useDispatch();

  const handleSave = async (data, step) => {
    await tier2Api[step](data);
    await dispatch(investorActions.getInvestor(true));
    history.push('/profile');
  };

  return <StyledKYCModule config={config} handleSave={handleSave} />;
};

const StyledKYCModule = styled(KYCModule)`
  > * {
    min-height: calc(100vh - ${8 * GU + 1}px);
  }
`;
