import React from 'react';
import { GU } from '@aragon/ui';
import {
  CompanyInfoBox,
  CompanyRegistrationBox,
  AddressBox
} from '../../../../../../../components';
import { Container, Row, Col } from '../../../../../../../ui';

export const CompanyInfo = ({ data }) => {
  return (
    <Container>
      <CompanyInfoBox data={data} />

      <CompanyRegistrationBox data={data} />

      <Row margin={-GU} cols={2} marginTop={2 * GU}>
        <Col>
          <AddressBox title={'Legal Address'} data={data.addresses.legal[0]} />
        </Col>

        <Col>
          <AddressBox title={'Correspondence Address'} data={data.addresses.correspondence[0]} />
        </Col>
      </Row>
    </Container>
  );
};
