import React from 'react';
import { GU } from '@aragon/ui';
import { Box } from '../../../../components';
import { DatePicker, Input, Row, Col, Body1Light } from '../../../../ui';

export const CompanyRegistrationInputBox = ({ data = {}, changeData }) => {
  return (
    <Box paddingBottom={3 * GU}>
      <Body1Light>Registration details</Body1Light>

      <Row cols={4} margin={-GU} marginTop={3 * GU} colsMarginBottom={2 * GU}>
        <DatePicker
          label={'Registration date'}
          selected={data.registrationDate}
          onChange={(date) => changeData('registrationDate', date)}
          required
        />
      </Row>

      <Row
        cols={4}
        margin={-GU}
        marginTop={2 * GU}
        colsMarginBottom={2 * GU}
        alignItems={'flex-end'}
      >
        <Col width={'50%'}>
          <Input
            label={'Registration number'}
            value={data.registrationNumber}
            onChange={(e) => changeData('registrationNumber', e.target.value)}
            required
          />
        </Col>

        <Col width={'50%'}>
          <Input
            label={'LEI'}
            value={data.lei}
            onChange={(e) => changeData('lei', e.target.value)}
          />
        </Col>

        <Input
          label={'country of registration'}
          value={data.countryOfRegistration}
          onChange={(e) => changeData('countryOfRegistration', e.target.value)}
          required
        />

        <Input
          label={'TAX residency'}
          value={data.taxResidency}
          onChange={(e) => changeData('taxResidency', e.target.value)}
          required
        />

        <Col width={'50%'}>
          <Input
            label={'TAX number'}
            value={data.taxNumber}
            onChange={(e) => changeData('taxNumber', e.target.value)}
            required
          />
        </Col>
      </Row>
    </Box>
  );
};
