import React, { useEffect, useState } from 'react';
import { Header, SearchInput } from '@aragon/ui';
import { AvailableAssetsTable, BackBar, Box, InnerBox, Pagination } from '../../../../components';
import { Col, PageContainer, Title2 } from '../../../../ui';

export const AvailableAssets = ({
  assets,
  assetsInTable,
  updateFilters,
  saveFilters,
  savedFilters
}) => {
  const [search, setSearch] = useState(savedFilters?.search || '');
  const [page, setPage] = useState(savedFilters?.page || 0);

  useEffect(() => {
    updateFilters({
      search: search,
      skip: page * assetsInTable
    });
  }, []);

  const updateSearch = (value) => {
    setSearch(value);
    setPage(0);

    updateFilters({
      search: value
    });
  };

  const onChangePagination = (val) => {
    setPage(val);

    updateFilters({
      search: search,
      skip: val * assetsInTable
    });
  };

  return (
    <PageContainer>
      <Header primary={<Title2>Available Assets</Title2>} />

      <BackBar />

      <Box padding={0}>
        <InnerBox noBorder>
          <Col width={'304px'}>
            <SearchInput value={search} onChange={updateSearch} placeholder={'Search'} wide />
          </Col>
        </InnerBox>

        <InnerBox padding={'0'}>
          <AvailableAssetsTable
            data={assets?.assets}
            onDetailsSideEffect={() => saveFilters({ page: page, search: search })}
            noBorderBottom
          />
        </InnerBox>

        {assets && assets.count > assetsInTable && (
          <Pagination
            selected={page}
            onChange={onChangePagination}
            pages={Math.ceil(assets.count / assetsInTable)}
          />
        )}
      </Box>
    </PageContainer>
  );
};
