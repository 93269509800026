import React from 'react';
import { GU } from '@aragon/ui';
import { TotalAssetsTable, OrdersTableBox } from '../../../../../components';
import { PageContainer, Title2 } from '../../../../../ui';

export const OrdersView = (props) => {
  return (
    <PageContainer marginTop={3 * GU}>
      <Title2 marginBottom={2 * GU}>Orders</Title2>

      <TotalAssetsTable data={{}} />

      <OrdersTableBox {...props} />
    </PageContainer>
  );
};
