export const PHONE_CODES = [
  { country: {
      en: 'Afghanistan',
      de: 'Afghanistan',
      lv: 'Afganistāna',
      ru: 'Афганистан'
    },
    code: '93', iso: 'AF' },
  { country: {
      en: 'Albania',
      de: 'Albania',
      lv: 'Albānija',
      ru: 'Албания'
    },
    code: '355', iso: 'AL' },
  { country: {
      en: 'Algeria',
      de: 'Algeria',
      lv: 'Alžīrija',
      ru: 'Алжир'
    }, code: '213', iso: 'DZ' },
  { country: {
      en: 'American Samoa',
      de: 'American Samoa',
      lv: 'Amerikas Samoa',
      ru: 'Американское Самоа'
    }, code: '1-684', iso: 'AS' },
  { country: {
      en: 'Andorra',
      de: 'Andorra',
      lv: 'Andora',
      ru: 'Андорра'
    }, code: '376', iso: 'AD' },
  { country: {
      en: "Angola",
      de: "Angola",
      lv: "Angola",
      ru: "Ангола"
    }, code: '244', iso: 'AO' },
  { country: {
      en: "Anguilla",
      de: "Anguilla",
      lv: "Angilja",
      ru: "Ангилья"
    }, code: '1-264', iso: 'AI' },
  { country: {
      en: "Antarctica",
      de: "Antarctica",
      lv: "Antarktīda",
      ru: "Антарктика"
    }, code: '672', iso: 'AQ' },
  { country: {
      en: "Antigua and Barbuda",
      de: "Antigua and Barbuda",
      lv: "Antigva un Barbuda",
      ru: "Антигуа и Барбуда"
    }, code: '1-268', iso: 'AG' },
  { country: {
      en: "Argentina",
      de: "Argentina",
      lv: "Argentīna",
      ru: "Аргентина"
    }, code: '54', iso: 'AR' },
  { country: {
      en: "Armenia",
      de: "Armenia",
      lv: "Armēnija",
      ru: "Армения"
    }, code: '374', iso: 'AM' },
  { country: {
      en: "Aruba",
      de: "Aruba",
      lv: "Aruba",
      ru: "Аруба"
    }, code: '297', iso: 'AW' },
  { country: {
      en: "Australia",
      de: "Australia",
      lv: "Austrālija",
      ru: "Австралия"
    }, code: '61', iso: 'AU' },
  { country: {
      en: "Austria",
      de: "Austria",
      lv: "Austrija",
      ru: "Австрия"
    }, code: '43', iso: 'AT' },
  { country: {
      en: "Azerbaijan",
      de: "Azerbaijan",
      lv: "Azerbaidžāna",
      ru: "Азербайджан"
    }, code: '994', iso: 'AZ' },
  { country: {
      en: "Bahamas",
      de: "Bahamas",
      lv: "Bahamu salas",
      ru: "Багамские острова"
    }, code: '1-242', iso: 'BS' },
  { country: {
      en: "Bahrain",
      de: "Bahrain",
      lv: "Bahreina",
      ru: "Бахрейн"
    }, code: '973', iso: 'BH' },
  { country: {
      en: "Bangladesh",
      de: "Bangladesh",
      lv: "Bangladeša",
      ru: "Бангладеш"
    }, code: '880', iso: 'BD' },
  { country: {
      en: "Barbados",
      de: "Barbados",
      lv: "Barbadosa",
      ru: "Барбадос"
    }, code: '1-246', iso: 'BB' },
  { country: {
      en: "Belarus",
      de: "Belarus",
      lv: "Baltkrievija",
      ru: "Беларусь"
    }, code: '375', iso: 'BY' },
  { country: {
      en: "Belgium",
      de: "Belgium",
      lv: "Beļģija",
      ru: "Бельгия"
    }, code: '32', iso: 'BE' },
  { country: {
      en: "Belize",
      de: "Belize",
      lv: "Beliza",
      ru: "Белиз"
    }, code: '501', iso: 'BZ' },
  { country: {
      en: "Benin",
      de: "Benin",
      lv: "Benina",
      ru: "Бенин"
    }, code: '229', iso: 'BJ' },
  { country: {
      en: "Bermuda",
      de: "Bermuda",
      lv: "Bermuda",
      ru: "Бермудские острова"
    }, code: '1-441', iso: 'BM' },
  { country: {
      en: "Bhutan",
      de: "Bhutan",
      lv: "Butāna",
      ru: "Бутан"
    }, code: '975', iso: 'BT' },
  { country: {
      en: "Bolivia",
      de: "Bolivia",
      lv: "Bolīvija",
      ru: "Боливия"
    }, code: '591', iso: 'BO' },
  { country: {
      en: "Bosnia and Herzegovina",
      de: "Bosnia and Herzegovina",
      lv: "Bosnija un Hercegovina",
      ru: "Босния и Герцеговина"
    }, code: '387', iso: 'BA' },
  { country: {
      en: "Botswana",
      de: "Botswana",
      lv: "Botsvana",
      ru: "Ботсвана"
    }, code: '267', iso: 'BW' },
  { country: {
      en: "Brazil",
      de: "Brazil",
      lv: "Brazīlija",
      ru: "Бразилия"
    }, code: '55', iso: 'BR' },
  { country: {
      en: "British Indian Ocean Territory",
      de: "British Indian Ocean Territory",
      lv: "Britu Indijas okeāna teritorija",
      ru: "Британская территория в Индийском океане"
    }, code: '246', iso: 'IO' },
  { country: {
      en: "British Virgin Islands",
      de: "British Virgin Islands",
      lv: "Britu Virdžīnu salas",
      ru: "Британские Виргинские острова"
    }, code: '1-284', iso: 'VG' },
  { country: {
      en: "Brunei",
      de: "Brunei",
      lv: "Bruneja",
      ru: "Бруней"
    }, code: '673', iso: 'BN' },
  { country: {
      en: "Bulgaria",
      de: "Bulgaria",
      lv: "Bulgārija",
      ru: "Болгария"
    }, code: '359', iso: 'BG' },
  { country: {
      en: "Burkina Faso",
      de: "Burkina Faso",
      lv: "Burkina Faso",
      ru: "Буркина-Фасо"
    }, code: '226', iso: 'BF' },
  { country: {
      en: "Burundi",
      de: "Burundi",
      lv: "Burundi",
      ru: "Бурунди"
    }, code: '257', iso: 'BI' },
  { country: {
      en: "Cambodia",
      de: "Cambodia",
      lv: "Kambodža",
      ru: "Камбоджа"
    }, code: '855', iso: 'KH' },
  { country: {
      en: "Cameroon",
      de: "Cameroon",
      lv: "Kamerūna",
      ru: "Камерун"
    }, code: '237', iso: 'CM' },
  { country: {
      en: "Canada",
      de: "Canada",
      lv: "Kanāda",
      ru: "Канада"
    }, code: '1', iso: 'CA' },
  { country: {
      en: "Cape Verde",
      de: "Cape Verde",
      lv: "Kaboverde",
      ru: "Кабо-Верде"
    }, code: '238', iso: 'CV' },
  { country: {
      en: "Cayman Islands",
      de: "Cayman Islands",
      lv: "Kaimanu salas",
      ru: "Каймановы острова"
    }, code: '1-345', iso: 'KY' },
  { country: {
      en: "Central African Republic",
      de: "Central African Republic",
      lv: "Centrālāfrikas Republika",
      ru: "Центральноафриканская Республика"
    }, code: '236', iso: 'CF' },
  { country: {
      en: "Chad",
      de: "Chad",
      lv: "Čada",
      ru: "Чад"
    }, code: '235', iso: 'TD' },
  { country: {
      en: "Chile",
      de: "Chile",
      lv: "Čīle",
      ru: "Чили"
    }, code: '56', iso: 'CL' },
  { country: {
      en: "China",
      de: "China",
      lv: "Ķīna",
      ru: "Китай"
    }, code: '86', iso: 'CN' },
  { country: {
      en: "Christmas Island",
      de: "Christmas Island",
      lv: "Ziemassvētku sala",
      ru: "Остров Рождества"
    }, code: '61', iso: 'CX' },
  { country: {
      en: "Cocos Islands",
      de: "Cocos Islands",
      lv: "Kokosu (Kīlinga) salas",
      ru: "Кокосовые (Килинг) острова"
    }, code: '61', iso: 'CC' },
  { country: {
      en: "Colombia",
      de: "Colombia",
      lv: "Kolumbija",
      ru: "Колумбия"
    }, code: '57', iso: 'CO' },
  { country: {
      en: "Comoros",
      de: "Comoros",
      lv: "Komoru salas",
      ru: "Коморские острова"
    }, code: '269', iso: 'KM' },
  { country: {
      en: "Cook Islands",
      de: "Cook Islands",
      lv: "Kuka salas",
      ru: "Острова Кука"
    }, code: '682', iso: 'CK' },
  { country: {
      en: "Costa Rica",
      de: "Costa Rica",
      lv: "Kostarika",
      ru: "Коста-Рика"
    }, code: '506', iso: 'CR' },
  { country: {
      en: "Croatia",
      de: "Croatia",
      lv: "Horvātija",
      ru: "Хорватия"
    }, code: '385', iso: 'HR' },
  { country: {
      en: "Cuba",
      de: "Cuba",
      lv: "Kuba",
      ru: "Куба"
    }, code: '53', iso: 'CU' },
  { country: {
      en: "Curacao",
      de: "Curacao",
      lv: "Kirasao",
      ru: "Кюрасао"
    }, code: '599', iso: 'CW' },
  { country: {
      en: "Cyprus",
      de: "Cyprus",
      lv: "Kipra",
      ru: "Кипр"
    }, code: '357', iso: 'CY' },
  { country: {
      en: "Czech Republic",
      de: "Czech Republic",
      lv: "Čehija",
      ru: "Чехия"
    }, code: '420', iso: 'CZ' },
  { country: {
      en: "Democratic Republic of the Congo",
      de: "Democratic Republic of the Congo",
      lv: "Kongo Demokrātiskā Republika",
      ru: "Демократическая Республика Конго"
    }, code: '243', iso: 'CD' },
  { country: {
      en: "Denmark",
      de: "Denmark",
      lv: "Dānija",
      ru: "Дания"
    }, code: '45', iso: 'DK' },
  { country: {
      en: "Djibouti",
      de: "Djibouti",
      lv: "Džibutija",
      ru: "Джибути"
    }, code: '253', iso: 'DJ' },
  { country: {
      en: "Dominica",
      de: "Dominica",
      lv: "Dominika",
      ru: "Доминика"
    }, code: '1-767', iso: 'DM' },
  { country: {
      en: "Dominican Republic",
      de: "Dominican Republic",
      lv: "Dominikānas Republika",
      ru: "Доминиканская Республика"
    }, code: '1-809, 1-829, 1-849', iso: 'DO' },
  { country: {
      en: "East Timor",
      de: "East Timor",
      lv: "Austrumtimora",
      ru: "Восточный Тимор"
    }, code: '670', iso: 'TL' },
  { country: {
      en: "Ecuador",
      de: "Ecuador",
      lv: "Ekvadora",
      ru: "Эквадор"
    }, code: '593', iso: 'EC' },
  { country: {
      en: "Egypt",
      de: "Egypt",
      lv: "Ēģipte",
      ru: "Египет"
    }, code: '20', iso: 'EG' },
  { country: {
      en: "El Salvador",
      de: "El Salvador",
      lv: "Salvadora",
      ru: "Сальвадор"
    }, code: '503', iso: 'SV' },
  { country: {
      en: "Equatorial Guinea",
      de: "Equatorial Guinea",
      lv: "Ekvatoriālā Gvineja",
      ru: "Экваториальная Гвинея"
    }, code: '240', iso: 'GQ' },
  { country: {
      en: "Eritrea",
      de: "Eritrea",
      lv: "Eritreja",
      ru: "Эритрея"
    }, code: '291', iso: 'ER' },
  { country: {
      en: "Estonia",
      de: "Estonia",
      lv: "Igaunija",
      ru: "Эстония"
    }, code: '372', iso: 'EE' },
  { country: {
      en: "Ethiopia",
      de: "Ethiopia",
      lv: "Etiopija",
      ru: "Эфиопия"
    }, code: '251', iso: 'ET' },
  { country: {
      en: "Falkland Islands",
      de: "Falkland Islands",
      lv: "Folklenda salas",
      ru: "Фолклендские острова"
    }, code: '500', iso: 'FK' },
  { country: {
      en: "Faroe Islands",
      de: "Faroe Islands",
      lv: "Fēru salas",
      ru: "Фарерские острова"
    }, code: '298', iso: 'FO' },
  { country: {
      en: "Fiji",
      de: "Fiji",
      lv: "Fidži",
      ru: "Фиджи"
    }, code: '679', iso: 'FJ' },
  { country: {
      en: "Finland",
      de: "Finland",
      lv: "Somija",
      ru: "Финляндия"
    }, code: '358', iso: 'FI' },
  { country: {
      en: "France",
      de: "France",
      lv: "Francija",
      ru: "Франция"
    }, code: '33', iso: 'FR' },
  { country: {
      en: "French Polynesia",
      de: "French Polynesia",
      lv: "Franču Polinēzija",
      ru: "Французская Полинезия"
    }, code: '689', iso: 'PF' },
  { country: {
      en: "Gabon",
      de: "Gabon",
      lv: "Gabona",
      ru: "Габон"
    }, code: '241', iso: 'GA' },
  { country: {
      en: "Gambia",
      de: "Gambia",
      lv: "Gambija",
      ru: "Гамбия"
    }, code: '220', iso: 'GM' },
  { country: {
      en: "Georgia",
      de: "Georgia",
      lv: "Gruzija",
      ru: "Грузия"
    }, code: '995', iso: 'GE' },
  { country: {
      en: "Germany",
      de: "Germany",
      lv: "Vācija",
      ru: "Германия"
    }, code: '49', iso: 'DE' },
  { country: {
      en: "Ghana",
      de: "Ghana",
      lv: "Gana",
      ru: "Гана"
    }, code: '233', iso: 'GH' },
  { country: {
      en: "Gibraltar",
      de: "Gibraltar",
      lv: "Gibraltārs",
      ru: "Гибралтар"
    }, code: '350', iso: 'GI' },
  { country: {
      en: "Greece",
      de: "Greece",
      lv: "Grieķija",
      ru: "Греция"
    }, code: '30', iso: 'GR' },
  { country: {
      en: "Greenland",
      de: "Greenland",
      lv: "Grenlande",
      ru: "Гренландия"
    }, code: '299', iso: 'GL' },
  { country: {
      en: "Grenada",
      de: "Grenada",
      lv: "Grenāda",
      ru: "Гренада"
    }, code: '1-473', iso: 'GD' },
  { country: {
      en: "Guam",
      de: "Guam",
      lv: "Guama",
      ru: "Гуам"
    }, code: '1-671', iso: 'GU' },
  { country: {
      en: "Guatemala",
      de: "Guatemala",
      lv: "Gvatemala",
      ru: "Гватемала"
    }, code: '502', iso: 'GT' },
  { country: {
      en: "Guernsey",
      de: "Guernsey",
      lv: "Gērnsija",
      ru: "Гернси"
    }, code: '44-1481', iso: 'GG' },
  { country: {
      en: "Guinea",
      de: "Guinea",
      lv: "Gvineja",
      ru: "Гвинея"
    }, code: '224', iso: 'GN' },
  { country: {
      en: "Guinea-Bissau",
      de: "Guinea-Bissau",
      lv: "Gvineja-Bisava",
      ru: "Гвинея-Бисау"
    }, code: '245', iso: 'GW' },
  { country: {
      en: "Guyana",
      de: "Guyana",
      lv: "Gajāna",
      ru: "Гайана"
    }, code: '592', iso: 'GY' },
  { country: {
      en: "Haiti",
      de: "Haiti",
      lv: "Haiti",
      ru: "Гаити"
    }, code: '509', iso: 'HT' },
  { country: {
      en: "Honduras",
      de: "Honduras",
      lv: "Hondurasa",
      ru: "Гондурас"
    }, code: '504', iso: 'HN' },
  { country: {
      en: "Hong Kong",
      de: "Hong Kong",
      lv: "Honkonga",
      ru: "Гонконг"
    }, code: '852', iso: 'HK' },
  { country: {
      en: "Hungary",
      de: "Hungary",
      lv: "Ungārija",
      ru: "Венгрия"
    }, code: '36', iso: 'HU' },
  { country: {
      en: "Iceland",
      de: "Iceland",
      lv: "Islande",
      ru: "Исландия"
    }, code: '354', iso: 'IS' },
  { country: {
      en: "India",
      de: "India",
      lv: "Indija",
      ru: "Индия"
    }, code: '91', iso: 'IN' },
  { country: {
      en: "Indonesia",
      de: "Indonesia",
      lv: "Indonēzija",
      ru: "Индонезия"
    }, code: '62', iso: 'ID' },
  { country: {
      en: "Iran",
      de: "Iran",
      lv: "Irāna",
      ru: "Иран"
    }, code: '98', iso: 'IR' },
  { country: {
      en: "Iraq",
      de: "Iraq",
      lv: "Irāka",
      ru: "Ирак"
    }, code: '964', iso: 'IQ' },
  { country: {
      en: "Ireland",
      de: "Ireland",
      lv: "Īrija",
      ru: "Ирландия"
    }, code: '353', iso: 'IE' },
  { country: {
      en: "Isle of Man",
      de: "Isle of Man",
      lv: "Menas sala",
      ru: "Остров Мэн"
    }, code: '44-1624', iso: 'IM' },
  { country: {
      en: "Israel",
      de: "Israel",
      lv: "Izraēla",
      ru: "Израиль"
    }, code: '972', iso: 'IL' },
  { country: {
      en: "Italy",
      de: "Italy",
      lv: "Itālija",
      ru: "Италия"
    }, code: '39', iso: 'IT' },
  { country: {
      en: "Ivory Coast",
      de: "Ivory Coast",
      lv: "Kotdivuāra",
      ru: "Кот-д'Ивуар"
    }, code: '225', iso: 'CI' },
  { country: {
      en: "Jamaica",
      de: "Jamaica",
      lv: "Jamaika",
      ru: "Ямайка"
    }, code: '1-876', iso: 'JM' },
  { country: {
      en: "Japan",
      de: "Japan",
      lv: "Japāna",
      ru: "Япония"
    }, code: '81', iso: 'JP' },
  { country: {
      en: "Jersey",
      de: "Jersey",
      lv: "Džērsija",
      ru: "Джерси"
    }, code: '44-1534', iso: 'JE' },
  { country: {
      en: "Jordan",
      de: "Jordan",
      lv: "Jordānija",
      ru: "Иордания"
    }, code: '962', iso: 'JO' },
  { country: {
      en: "Kazakhstan",
      de: "Kazakhstan",
      lv: "Kazahstāna",
      ru: "Казахстан"
    }, code: '7', iso: 'KZ' },
  { country: {
      en: "Kenya",
      de: "Kenya",
      lv: "Kenija",
      ru: "Кения"
    }, code: '254', iso: 'KE' },
  { country: {
      en: "Kiribati",
      de: "Kiribati",
      lv: "Kiribati",
      ru: "Кирибати"
    }, code: '686', iso: 'KI' },
  { country: {
      en: "Kosovo",
      de: "Kosovo",
      lv: "Kosova",
      ru: "Косово"
    }, code: '383', iso: 'XK' },
  { country: {
      en: "Kuwait",
      de: "Kuwait",
      lv: "Kuveita",
      ru: "Кувейт"
    }, code: '965', iso: 'KW' },
  { country: {
      en: "Kyrgyzstan",
      de: "Kyrgyzstan",
      lv: "Kirgizstāna",
      ru: "Киргизстан"
    }, code: '996', iso: 'KG' },
  { country: {
      en: "Laos",
      de: "Laos",
      lv: "Laosa",
      ru: "Лаос"
    }, code: '856', iso: 'LA' },
  { country: {
      en: "Latvia",
      de: "Latvia",
      lv: "Latvija",
      ru: "Латвия"
    }, code: '371', iso: 'LV' },
  { country: {
      en: "Lebanon",
      de: "Lebanon",
      lv: "Libāna",
      ru: "Ливан"
    }, code: '961', iso: 'LB' },
  { country: {
      en: "Lesotho",
      de: "Lesotho",
      lv: "Lesoto",
      ru: "Лесото"
    }, code: '266', iso: 'LS' },
  { country: {
      en: "Liberia",
      de: "Liberia",
      lv: "Libērija",
      ru: "Либерия"
    }, code: '231', iso: 'LR' },
  { country: {
      en: "Libya",
      de: "Libya",
      lv: "Lībija",
      ru: "Ливия"
    }, code: '218', iso: 'LY' },
  { country: {
      en: "Liechtenstein",
      de: "Liechtenstein",
      lv: "Lihtenšteina",
      ru: "Лихтенштейн"
    }, code: '423', iso: 'LI' },
  { country: {
      en: "Lithuania",
      de: "Lithuania",
      lv: "Lietuva",
      ru: "Литва"
    }, code: '370', iso: 'LT' },
  { country: {
      en: "Luxembourg",
      de: "Luxembourg",
      lv: "Liuksemburga",
      ru: "Люксембург"
    }, code: '352', iso: 'LU' },
  { country: {
      en: "Macao",
      de: "Macao",
      lv: "Makao",
      ru: "Макао"
    }, code: '853', iso: 'MO' },
  { country: {
      en: "Macedonia",
      de: "Macedonia",
      lv: "Maķedonija",
      ru: "Македония"
    }, code: '389', iso: 'MK' },
  { country: {
      en: "Madagascar",
      de: "Madagascar",
      lv: "Madagaskara",
      ru: "Мадагаскар"
    }, code: '261', iso: 'MG' },
  { country: {
      en: "Malawi",
      de: "Malawi",
      lv: "Malāvija",
      ru: "Малави"
    }, code: '265', iso: 'MW' },
  { country: {
      en: "Malaysia",
      de: "Malaysia",
      lv: "Malaizija",
      ru: "Малайзия"
    }, code: '60', iso: 'MY' },
  { country: {
      en: "Maldives",
      de: "Maldives",
      lv: "Maldīvijas",
      ru: "Мальдивы"
    }, code: '960', iso: 'MV' },
  { country: {
      en: "Mali",
      de: "Mali",
      lv: "Mali",
      ru: "Мали"
    }, code: '223', iso: 'ML' },
  { country: {
      en: "Malta",
      de: "Malta",
      lv: "Malta",
      ru: "Мальта"
    }, code: '356', iso: 'MT' },
  { country: {
      en: "Marshall Islands",
      de: "Marshall Islands",
      lv: "Māršala Salas",
      ru: "Маршалловы Острова"
    }, code: '692', iso: 'MH' },
  { country: {
      en: "Mauritania",
      de: "Mauritania",
      lv: "Mauritānija",
      ru: "Мавритания"
    }, code: '222', iso: 'MR' },
  { country: {
      en: "Mauritius",
      de: "Mauritius",
      lv: "Maurīcija",
      ru: "Маврикий"
    }, code: '230', iso: 'MU' },
  { country: {
      en: "Mayotte",
      de: "Mayotte",
      lv: "Majota",
      ru: "Майотта"
    }, code: '262', iso: 'YT' },
  { country: {
      en: "Mexico",
      de: "Mexico",
      lv: "Meksika",
      ru: "Мексика"
    }, code: '52', iso: 'MX' },
  { country: {
      en: "Micronesia",
      de: "Micronesia",
      lv: "Mikronēzija",
      ru: "Микронезия"
    }, code: '691', iso: 'FM' },
  { country: {
      en: "Moldova",
      de: "Moldova",
      lv: "Moldova",
      ru: "Молдавия"
    }, code: '373', iso: 'MD' },
  { country: {
      en: "Monaco",
      de: "Monaco",
      lv: "Monako",
      ru: "Монако"
    }, code: '377', iso: 'MC' },
  { country: {
      en: "Mongolia",
      de: "Mongolia",
      lv: "Mongolija",
      ru: "Монголия"
    }, code: '976', iso: 'MN' },
  { country: {
      en: "Montenegro",
      de: "Montenegro",
      lv: "Melnkalne",
      ru: "Черногория"
    }, code: '382', iso: 'ME' },
  { country: {
      en: "Montserrat",
      de: "Montserrat",
      lv: "Monserat",
      ru: "Монтсеррат"
    }, code: '1-664', iso: 'MS' },
  { country: {
      en: "Morocco",
      de: "Morocco",
      lv: "Maroka",
      ru: "Марокко"
    }, code: '212', iso: 'MA' },
  { country: {
      en: "Mozambique",
      de: "Mozambique",
      lv: "Mozambika",
      ru: "Мозамбик"
    }, code: '258', iso: 'MZ' },
  { country: {
      en: "Myanmar",
      de: "Myanmar",
      lv: "Mjanma",
      ru: "Мьянма"
    }, code: '95', iso: 'MM' },
  { country: {
      en: "Namibia",
      de: "Namibia",
      lv: "Namībija",
      ru: "Намибия"
    }, code: '264', iso: 'NA' },
  { country: {
      en: "Nauru",
      de: "Nauru",
      lv: "Nauru",
      ru: "Науру"
    }, code: '674', iso: 'NR' },
  { country: {
      en: "Nepal",
      de: "Nepal",
      lv: "Nepāla",
      ru: "Непал"
    }, code: '977', iso: 'NP' },
  { country: {
      en: "Netherlands",
      de: "Netherlands",
      lv: "Nīderlande",
      ru: "Нидерланды"
    }, code: '31', iso: 'NL' },
  { country: {
      en: "Netherlands Antilles",
      de: "Netherlands Antilles",
      lv: "Nīderlandes Antiļas",
      ru: "Нидерландские Антильские острова"
    }, code: '599', iso: 'AN' },
  { country: {
      en: "New Caledonia",
      de: "New Caledonia",
      lv: "Jaunkaledonija",
      ru: "Новая Каледония"
    }, code: '687', iso: 'NC' },
  { country: {
      en: "New Zealand",
      de: "New Zealand",
      lv: "Jaunzēlande",
      ru: "Новая Зеландия"
    }, code: '64', iso: 'NZ' },
  { country: {
      en: "Nicaragua",
      de: "Nicaragua",
      lv: "Nikaragva",
      ru: "Никарагуа"
    }, code: '505', iso: 'NI' },
  { country: {
      en: "Niger",
      de: "Niger",
      lv: "Niger",
      ru: "Нигер"
    }, code: '227', iso: 'NE' },
  { country: {
      en: "Nigeria",
      de: "Nigeria",
      lv: "Nigērija",
      ru: "Нигерия"
    }, code: '234', iso: 'NG' },
  { country: {
      en: "Niue",
      de: "Niue",
      lv: "Niue",
      ru: "Ниуэ"
    }, code: '683', iso: 'NU' },
  { country: {
      en: "North Korea",
      de: "North Korea",
      lv: "Ziemeļkoreja",
      ru: "Северная Корея"
    }, code: '850', iso: 'KP' },
  { country: {
      en: "Northern Mariana Islands",
      de: "Northern Mariana Islands",
      lv: "Ziemeļu Marianas salas",
      ru: "Северные Марианские острова"
    }, code: '1-670', iso: 'MP' },
  { country: {
      en: "Norway",
      de: "Norway",
      lv: "Norvēģija",
      ru: "Норвегия"
    }, code: '47', iso: 'NO' },
  { country: {
      en: "Oman",
      de: "Oman",
      lv: "Omāna",
      ru: "Оман"
    }, code: '968', iso: 'OM' },
  { country: {
      en: "Pakistan",
      de: "Pakistan",
      lv: "Pakistāna",
      ru: "Пакистан"
    }, code: '92', iso: 'PK' },
  { country: {
      en: "Palau",
      de: "Palau",
      lv: "Palau",
      ru: "Палау"
    }, code: '680', iso: 'PW' },
  { country: {
      en: "Palestine",
      de: "Palestine",
      lv: "Palestīna",
      ru: "Палестина"
    }, code: '970', iso: 'PS' },
  { country: {
      en: "Panama",
      de: "Panama",
      lv: "Panama",
      ru: "Панама"
    }, code: '507', iso: 'PA' },
  { country: {
      en: "Papua New Guinea",
      de: "Papua New Guinea",
      lv: "Papua-Jaungvineja",
      ru: "Папуа-Новая Гвинея"
    }, code: '675', iso: 'PG' },
  { country: {
      en: "Paraguay",
      de: "Paraguay",
      lv: "Paragvaja",
      ru: "Парагвай"
    }, code: '595', iso: 'PY' },
  { country: {
      en: "Peru",
      de: "Peru",
      lv: "Peru",
      ru: "Перу"
    }, code: '51', iso: 'PE' },
  { country: {
      en: "Philippines",
      de: "Philippines",
      lv: "Filipīnas",
      ru: "Филиппины"
    }, code: '63', iso: 'PH' },
  { country: {
      en: "Pitcairn",
      de: "Pitcairn",
      lv: "Pitkērna",
      ru: "Питкэрн"
    }, code: '64', iso: 'PN' },
  { country: {
      en: "Poland",
      de: "Poland",
      lv: "Polija",
      ru: "Польша"
    }, code: '48', iso: 'PL' },
  { country: {
      en: "Portugal",
      de: "Portugal",
      lv: "Portugāle",
      ru: "Португалия"
    }, code: '351', iso: 'PT' },
  { country: {
      en: "Puerto Rico",
      de: "Puerto Rico",
      lv: "Puerto Riko",
      ru: "Пуэрто-Рико"
    }, code: '1-787, 1-939', iso: 'PR' },
  { country: {
      en: "Qatar",
      de: "Qatar",
      lv: "Katara",
      ru: "Катар"
    }, code: '974', iso: 'QA' },
  { country: {
      en: "Republic of the Congo",
      de: "Republic of the Congo",
      lv: "Kongas Republika",
      ru: "Республика Конго"
    }, code: '242', iso: 'CG' },
  { country: {
      en: "Reunion",
      de: "Reunion",
      lv: "Reinjona",
      ru: "Реюньон"
    }, code: '262', iso: 'RE' },
  { country: {
      en: "Romania",
      de: "Romania",
      lv: "Rumānija",
      ru: "Румыния"
    }, code: '40', iso: 'RO' },
  { country: {
      en: "Russia",
      de: "Russia",
      lv: "Krievija",
      ru: "Россия"
    }, code: '7', iso: 'RU' },
  { country: {
      en: "Rwanda",
      de: "Rwanda",
      lv: "Ruanda",
      ru: "Руанда"
    }, code: '250', iso: 'RW' },
  { country: {
      en: "Saint Barthelemy",
      de: "Saint Barthelemy",
      lv: "Svētā Bartolomēja",
      ru: "Сен-Бартельми"
    }, code: '590', iso: 'BL' },
  { country: {
      en: "Saint Helena",
      de: "Saint Helena",
      lv: "Svētā Helēna",
      ru: "Остров Святой Елены"
    }, code: '290', iso: 'SH' },
  { country: {
      en: "Saint Kitts and Nevis",
      de: "Saint Kitts and Nevis",
      lv: "Sentkitsa un Nevisa",
      ru: "Сент-Китс и Невис"
    }, code: '1-869', iso: 'KN' },
  { country: {
      en: "Saint Lucia",
      de: "Saint Lucia",
      lv: "Svētā Lūcija",
      ru: "Сент-Люсия"
    }, code: '1-758', iso: 'LC' },
  { country: {
      en: "Saint Martin",
      de: "Saint Martin",
      lv: "Svētais Mārtiņš",
      ru: "Сен-Мартен"
    }, code: '590', iso: 'MF' },
  { country: {
      en: "Saint Pierre and Miquelon",
      de: "Saint Pierre and Miquelon",
      lv: "Svētais Pjērs un Mikēlona",
      ru: "Сен-Пьер и Микелон"
    }, code: '508', iso: 'PM' },
  { country: {
      en: "Saint Vincent and the Grenadines",
      de: "Saint Vincent and the Grenadines",
      lv: "Svētais Vincents un Grenadīnas",
      ru: "Сент-Винсент и Гренадины"
    }, code: '1-784', iso: 'VC' },
  { country: {
      en: "Samoa",
      de: "Samoa",
      lv: "Samoa",
      ru: "Самоа"
    }, code: '685', iso: 'WS' },
  { country: {
      en: "San Marino",
      de: "San Marino",
      lv: "Sanmarīno",
      ru: "Сан-Марино"
    }, code: '378', iso: 'SM' },
  { country: {
      en: "Sao Tome and Principe",
      de: "Sao Tome and Principe",
      lv: "Sao Tomē un Prinsipi",
      ru: "Сан-Томе и Принсипи"
    }, code: '239', iso: 'ST' },
  { country: {
      en: "Saudi Arabia",
      de: "Saudi Arabia",
      lv: "Saūda Arābija",
      ru: "Саудовская Аравия"
    }, code: '966', iso: 'SA' },
  { country: {
      en: "Senegal",
      de: "Senegal",
      lv: "Senegāla",
      ru: "Сенегал"
    }, code: '221', iso: 'SN' },
  { country: {
      en: "Serbia",
      de: "Serbia",
      lv: "Serbija",
      ru: "Сербия"
    }, code: '381', iso: 'RS' },
  { country: {
      en: "Seychelles",
      de: "Seychelles",
      lv: "Seišelu salas",
      ru: "Сейшельские Острова"
    }, code: '248', iso: 'SC' },
  { country: {
      en: "Sierra Leone",
      de: "Sierra Leone",
      lv: "Sjerraleone",
      ru: "Сьерра-Леоне"
    }, code: '232', iso: 'SL' },
  { country: {
      en: "Singapore",
      de: "Singapore",
      lv: "Singapūra",
      ru: "Сингапур"
    }, code: '65', iso: 'SG' },
  { country: {
      en: "Sint Maarten",
      de: "Sint Maarten",
      lv: "Sintmārtena",
      ru: "Синт-Мартен"
    }, code: '1-721', iso: 'SX' },
  { country: {
      en: "Slovakia",
      de: "Slovakia",
      lv: "Slovākija",
      ru: "Словакия"
    }, code: '421', iso: 'SK' },
  { country: {
      en: "Slovenia",
      de: "Slovenia",
      lv: "Slovēnija",
      ru: "Словения"
    }, code: '386', iso: 'SI' },
  { country: {
      en: "Solomon Islands",
      de: "Solomon Islands",
      lv: "Zālamana salas",
      ru: "Соломоновы Острова"
    }, code: '677', iso: 'SB' },
  { country: {
      en: "Somalia",
      de: "Somalia",
      lv: "Somālija",
      ru: "Сомали"
    }, code: '252', iso: 'SO' },
  { country: {
      en: "South Africa",
      de: "South Africa",
      lv: "Dienvidāfrika",
      ru: "Южная Африка"
    }, code: '27', iso: 'ZA' },
  { country: {
      en: "South Korea",
      de: "South Korea",
      lv: "Dienvidkoreja",
      ru: "Южная Корея"
    }, code: '82', iso: 'KR' },
  { country: {
      en: "South Sudan",
      de: "South Sudan",
      lv: "Dienvidsudāna",
      ru: "Южный Судан"
    }, code: '211', iso: 'SS' },
  { country: {
      en: "Spain",
      de: "Spain",
      lv: "Spānija",
      ru: "Испания"
    }, code: '34', iso: 'ES' },
  { country: {
      en: "Sri Lanka",
      de: "Sri Lanka",
      lv: "Šrilanka",
      ru: "Шри-Ланка"
    }, code: '94', iso: 'LK' },
  { country: {
      en: "Sudan",
      de: "Sudan",
      lv: "Sudāna",
      ru: "Судан"
    }, code: '249', iso: 'SD' },
  { country: {
      en: "Suriname",
      de: "Suriname",
      lv: "Surinama",
      ru: "Суринам"
    }, code: '597', iso: 'SR' },
  { country: {
      en: "Svalbard and Jan Mayen",
      de: "Svalbard and Jan Mayen",
      lv: "Svalbāra un Jana Majena",
      ru: "Шпицберген и Ян-Майен"
    }, code: '47', iso: 'SJ' },
  { country: {
      en: "Swaziland",
      de: "Swaziland",
      lv: "Svazilenda",
      ru: "Свазиленд"
    }, code: '268', iso: 'SZ' },
  { country: {
      en: "Sweden",
      de: "Sweden",
      lv: "Zviedrija",
      ru: "Швеция"
    }, code: '46', iso: 'SE' },
  { country: {
      en: "Switzerland",
      de: "Switzerland",
      lv: "Šveice",
      ru: "Швейцария"
    }, code: '41', iso: 'CH' },
  { country: {
      en: "Syria",
      de: "Syria",
      lv: "Sīrija",
      ru: "Сирия"
    }, code: '963', iso: 'SY' },
  { country: {
      en: "Taiwan",
      de: "Taiwan",
      lv: "Taivāna",
      ru: "Тайвань"
    }, code: '886', iso: 'TW' },
  { country: {
      en: "Tajikistan",
      de: "Tajikistan",
      lv: "Tadžikistāna",
      ru: "Таджикистан"
    }, code: '992', iso: 'TJ' },
  { country: {
      en: "Tanzania",
      de: "Tanzania",
      lv: "Tanzānija",
      ru: "Танзания"
    }, code: '255', iso: 'TZ' },
  { country: {
      en: "Thailand",
      de: "Thailand",
      lv: "Taizeme",
      ru: "Таиланд"
    }, code: '66', iso: 'TH' },
  { country: {
      en: "Togo",
      de: "Togo",
      lv: "Togo",
      ru: "Того"
    }, code: '228', iso: 'TG' },
  { country: {
      en: "Tokelau",
      de: "Tokelau",
      lv: "Tokelau",
      ru: "Токелау"
    }, code: '690', iso: 'TK' },
  { country: {
      en: "Tonga",
      de: "Tonga",
      lv: "Tonga",
      ru: "Тонга"
    }, code: '676', iso: 'TO' },
  { country: {
      en: "Trinidad and Tobago",
      de: "Trinidad and Tobago",
      lv: "Trinidāda un Tobāgo",
      ru: "Тринидад и Тобаго"
    }, code: '1-868', iso: 'TT' },
  { country: {
      en: "Tunisia",
      de: "Tunisia",
      lv: "Tunisija",
      ru: "Тунис"
    }, code: '216', iso: 'TN' },
  { country: {
      en: "Turkey",
      de: "Turkey",
      lv: "Turcija",
      ru: "Турция"
    }, code: '90', iso: 'TR' },
  { country: {
      en: "Turkmenistan",
      de: "Turkmenistan",
      lv: "Turkmenistāna",
      ru: "Туркменистан"
    }, code: '993', iso: 'TM' },
  { country: {
      en: "Turks and Caicos Islands",
      de: "Turks and Caicos Islands",
      lv: "Tērksa un Kaikosa salas",
      ru: "Теркс и Кайкос"
    }, code: '1-649', iso: 'TC' },
  { country: {
      en: "Tuvalu",
      de: "Tuvalu",
      lv: "Tuvalu",
      ru: "Тувалу"
    }, code: '688', iso: 'TV' },
  { country: {
      en: "U.S. Virgin Islands",
      de: "U.S. Virgin Islands",
      lv: "ASV Virdzīnu salas",
      ru: "Виргинские Острова (США)"
    }, code: '1-340', iso: 'VI' },
  { country: {
      en: "Uganda",
      de: "Uganda",
      lv: "Uganda",
      ru: "Уганда"
    }, code: '256', iso: 'UG' },
  { country: {
      en: "Ukraine",
      de: "Ukraine",
      lv: "Ukraina",
      ru: "Украина"
    }, code: '380', iso: 'UA' },
  { country: {
      en: "United Arab Emirates",
      de: "United Arab Emirates",
      lv: "Apvienotie Arābu Emirāti",
      ru: "Объединенные Арабские Эмираты"
    }, code: '971', iso: 'AE' },
  { country: {
      en: "United Kingdom",
      de: "United Kingdom",
      lv: "Apvienotā Karaliste",
      ru: "Великобритания"
    }, code: '44', iso: 'GB' },
  { country: {
      en: "United States",
      de: "United States",
      lv: "Amerikas Savienotās Valstis",
      ru: "Соединенные Штаты Америки"
    }, code: '1', iso: 'US' },
  { country: {
      en: "Uruguay",
      de: "Uruguay",
      lv: "Urugvaja",
      ru: "Уругвай"
    }, code: '598', iso: 'UY' },
  { country: {
      en: "Uzbekistan",
      de: "Uzbekistan",
      lv: "Uzbekistāna",
      ru: "Узбекистан"
    }, code: '998', iso: 'UZ' },
  { country: {
      en: "Vanuatu",
      de: "Vanuatu",
      lv: "Vanuatu",
      ru: "Вануату"
    }, code: '678', iso: 'VU' },
  { country: {
      en: "Vatican",
      de: "Vatican",
      lv: "Vatikāns",
      ru: "Ватикан"
    }, code: '379', iso: 'VA' },
  { country: {
      en: "Venezuela",
      de: "Venezuela",
      lv: "Venecuēla",
      ru: "Венесуэла"
    }, code: '58', iso: 'VE' },
  { country: {
      en: "Vietnam",
      de: "Vietnam",
      lv: "Vjetnama",
      ru: "Вьетнам"
    }, code: '84', iso: 'VN' },
  { country: {
      en: "Wallis and Futuna",
      de: "Wallis and Futuna",
      lv: "Volisa un Futūna",
      ru: "Уоллис и Футуна"
    }, code: '681', iso: 'WF' },
  { country: {
      en: "Western Sahara",
      de: "Western Sahara",
      lv: "Rietumsahāra",
      ru: "Западная Сахара"
    }, code: '212', iso: 'EH' },
  { country: {
      en: "Yemen",
      de: "Yemen",
      lv: "Jemena",
      ru: "Йемен"
    }, code: '967', iso: 'YE' },
  { country: {
      en: "Zambia",
      de: "Zambia",
      lv: "Zambija",
      ru: "Замбия"
    }, code: '260', iso: 'ZM' },
  { country: {
      en: "Zimbabwe",
      de: "Zimbabwe",
      lv: "Zimbabve",
      ru: "Зимбабве"
    }, code: '263', iso: 'ZW' }
];

export const phoneCodesList = (lang) => {
  return PHONE_CODES.map((item) => `(${item.code}) ${item.country[lang]}`)
}

