import React from 'react';
import { Header, GU } from '@aragon/ui';
import { BackBar, DealDetailsBox, Box, ProfileViewer } from '../../../../components';
import { PageContainer, Row, Col, Title2 } from '../../../../ui';
import { BROKER_PROFILE } from '../../../../utils/static';

export const DealDetails = ({ currentDeal }) => {
  return (
    <PageContainer>
      <Header primary={<Title2>Deal details</Title2>} />
      <BackBar />

      <Row margin={-GU} cols={2}>
        <Col>
          <DealDetailsBox data={currentDeal ? currentDeal : undefined} />
        </Col>

        <Col>
          <Box heading={'Broker'} paddingY={2 * GU}>
            <ProfileViewer {...BROKER_PROFILE} />
          </Box>
        </Col>
      </Row>
    </PageContainer>
  );
};
