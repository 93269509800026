import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { kycApi } from '../../../../api/kyc';
import { DocRequestView } from '../../../../components';
import { useDispatch, useSelector } from 'react-redux';
import { entitiesActions } from '../../../../store/entities';
import { useTranslation } from 'react-i18next';
import { investorApi } from '../../../../api';
import { userActions } from '../../../../store/user';

export const DocumentRequest = ({ referralView = false }) => {
  const { referralToken } = useSelector((state) => state.user);
  const { t } = useTranslation();
  const { id, referralId } = useParams();
  const dispatch = useDispatch();
  const { data, refetch, isFetching } = useQuery(['getDocument', referralToken], () =>
    referralView ? kycApi.getReferralDocumentRequest(referralToken, id) : kycApi.getDocumentRequest(id), {
      enabled: (referralView && !!referralToken) || !referralView,
    }
  );
  const [error, setError] = useState(undefined);

  useEffect(() => {
    isFetching
      ? dispatch(entitiesActions.showLoader(t('Loading') + '...'))
      : dispatch(entitiesActions.hideLoader());
  }, [isFetching]);

  useEffect(() => {
    if (id && !referralToken && referralView && referralId) {
      (async () => {
        try {
          const res = await investorApi.getReferralToken(referralId);

          if (res) {
            dispatch(userActions.setReferralToken(res.data.accessToken));
            if (error) setError(undefined);
          }
        } catch (e) {
          setError(e.response.data.message);
        }
      })()
    }
  }, [id, referralToken, referralView, referralId]);

  return (
    <DocRequestView
      isFetching={isFetching}
      data={data}
      refetch={refetch}
      requestId={id}
      referralView={referralView}
    />
  );
};
