import React from 'react';
import { GU } from '@aragon/ui';
import { Body1Light } from '../../../../ui';
import { ZoomImage, Box, InnerBox, BoxLine, AddressBox } from '../../../../components';

export const PaymentDetailsView = ({ paymentDetails }) => {
  if (paymentDetails) {
    return (
      <div>
        <Box heading='Account details' padding={0} innerPaddingY={2 * GU}>
          <InnerBox>
            <BoxLine title='BIC/SWIFT code' data={paymentDetails.bicSwiftCode} />
            <BoxLine title='Bank name' data={paymentDetails.bankName} />
            <BoxLine title='Bank code' data={paymentDetails.bankCode} />
          </InnerBox>
          <InnerBox>
            {paymentDetails.correspondentBankForSwift && (
              <BoxLine
                title='Correspondent for SWIFT'
                data={paymentDetails.correspondentBankForSwift}
              />
            )}
            <BoxLine title='IBAN/account number' data={paymentDetails.ibanAccountNumber} />
            <BoxLine title='Currency' data={paymentDetails.currency} />
          </InnerBox>
        </Box>

        <AddressBox
          title={'bank Address'}
          data={paymentDetails.addresses && paymentDetails.addresses[0]}
          paddingX={3 * GU}
          paddingY={2 * GU}
        />

        {paymentDetails.bankAccountScans && paymentDetails.bankAccountScans[0] && (
          <Box padding={3 * GU}>
            <Body1Light marginBottom={2 * GU}>Documents</Body1Light>
            <ZoomImage
              title='Bank document scan'
              image={paymentDetails.bankAccountScans[0]?.documentURL}
            />
          </Box>
        )}
      </div>
    );
  }

  return null;
};
