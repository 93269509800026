import { useQuery } from 'react-query';
import { adminLocalizationApi, adminLocalizationKeys } from '../../../api';
import { getDocumentTypes } from '../api/index';

export const useDocumentTypes = (questionnaireId) => {
  const { data, isLoading, isError, error } = useQuery({
    queryKey: getDocumentTypes(questionnaireId),
    queryFn: () => getDocumentTypes(questionnaireId),
    select: res => res.data
  });

  return {
    isLoading,
    isError,
    data,
    error
  }
}
