import React, { useState } from 'react';
import { GU } from '@aragon/ui';
import { TableBoxContainer, AdminTransactionsTable } from '../../../../components';
import { Col, Row, ExportButton, DropDown, SearchInput } from '../../../../ui';
import {
  TRANSFER_STATUSES,
  TRANSFER_STATUSES_LIST,
  TRANSFER_TYPES,
  DISPLAY_TRANSFER_TYPE,
  TRANSACTIONS_ON_PAGE
} from '../../../../utils/static';
import { newObject, transactionFiltersToBack } from '../../../../utils/helpers';

export const TransactionsTableBox = ({
  transactions,
  fetchTransactions,
  saveFilters,
  savedFilters
}) => {
  const [page, setPage] = useState(savedFilters?.page || 0);
  const [search, setSearch] = useState(savedFilters?.search || '');
  const [filters, setFilters] = useState({
    typeIndex: savedFilters?.typeIndex || 0,
    statusIndex: savedFilters?.statusIndex || 0
  });

  const refetchTransactions = (selectedPage = page, newFilters = filters) => {
    fetchTransactions(
      transactionFiltersToBack({
        page: selectedPage,
        search: search,
        ...newFilters
      })
    );
  };

  const onUpdateSearch = () => {
    refetchTransactions(0);
    setPage(0);
  };

  const changeFilters = (key, value) => {
    const newFilters = newObject(filters, key, value);
    refetchTransactions(0, newFilters);

    setFilters(newFilters);
    setPage(0);
  };

  const onUpdatePage = (value) => {
    refetchTransactions(value);
    setPage(value);
  };

  if (transactions) {
    return (
      <TableBoxContainer
        title={'Transactions'}
        innerBoxProps={{ paddingBottom: GU }}
        aside={
          <Row margin={-GU}>
            <DropDown
              selected={filters.typeIndex}
              onChange={(val) => changeFilters('typeIndex', val)}
              items={['All types', ...TRANSFER_TYPES.map((item) => DISPLAY_TRANSFER_TYPE[item])]}
              minWidth={'104px'}
            />
            <DropDown
              selected={filters.statusIndex}
              onChange={(val) => changeFilters('statusIndex', val)}
              items={[
                'All statuses',
                ...TRANSFER_STATUSES_LIST.map((item) => TRANSFER_STATUSES[item].title)
              ]}
              minWidth={'104px'}
            />

            <Col>
              <SearchInput
                value={search}
                onChange={setSearch}
                handleSearch={onUpdateSearch}
                placeholder={'Search'}
                minWidth={'304'}
              />
            </Col>

            <Col>
              <ExportButton />
            </Col>
          </Row>
        }
        table={
          <AdminTransactionsTable
            data={transactions?.transactions}
            onDetailsSideEffect={() => saveFilters({ page: page, search: search, ...filters })}
          />
        }
        paginationSettings={{
          visible: transactions.count > TRANSACTIONS_ON_PAGE,
          pages: Math.ceil(transactions.count / TRANSACTIONS_ON_PAGE),
          selected: page,
          onChange: onUpdatePage
        }}
      />
    );
  }

  return null;
};
