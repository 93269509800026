import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { IssuerDetailsView } from '../View';
import { adminApi } from '../../../../../api/admin';

export const IssuerDetails = () => {
  const { id } = useParams();
  const [issuer, setIssuer] = useState(null);

  useEffect(() => {
    fetchIssuer();
  }, []);

  const fetchIssuer = () => {
    adminApi.getIssuer(id).then((res) => {
      if (res.status === 200) {
        setIssuer(res.data);
      }
    });
  };

  return <IssuerDetailsView issuer={issuer} />;
};
