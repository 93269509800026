import React, {useEffect} from 'react';

import { PageContainer } from '../../../ui';
import { Product } from '../../../features/constructorProducts';

export const ProductPage = () => {

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [])

  return (
    <PageContainer>
      <Product/>
    </PageContainer>
  );
};
