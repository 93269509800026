import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useMutation, useQuery } from 'react-query';
import { kycApi } from '../../../../api/kyc';
import { ProfileStatusView } from '../../../common';
import { useLocation } from 'react-router-dom';

export const ProfileStatus = ({ referralView = false, referralId }) => {
  const { referralToken } = useSelector((state) => state.user);
  const [isQuestionnairesGenerated, setIsQuestionnairesGenerated] = useState(false);
  const { investor } = useSelector((state) => state);
  const [rejectedQuestionnaires, setRejectedQuestionnaires] = useState(null);
  const { mutate } = useMutation(kycApi.generateQuestionnaires);
  const { data, isLoading, refetch } = useQuery(['getQuestionnaires', referralToken], referralView ? () => kycApi.getReferralQuestionnaires(referralToken) : kycApi.getQuestionnaires, {
    enabled: (referralView && !!referralToken) || !referralView,
    refetchInterval: 5000,
    refetchOnMount: true,
    refetchOnWindowFocus: true,
    onSuccess: async (res) => {
      try {
        const questLength = res.data?.length || 0;
        if (questLength !== 0) {
          const hasUnaccepted = res.data.some((item) => {
            return item.status === 'UNACCEPTED';
          });

          if (hasUnaccepted) {
            const rejRes = await kycApi.getRejectedEntities();
            const sectionTypes = rejRes.data.sectionTypes;
            if (sectionTypes.length > 0) {
              const rejectedQuests = {};
              sectionTypes.forEach((item) => {
                rejectedQuests[item.id] = item.comment[item.comment.length - 1]?.comment;
              });

              setRejectedQuestionnaires(rejectedQuests);
            }
          }
        }
      } catch (e) {
        console.error(e);
      }
    }
  });

  const { data: sumSubStatusRes, refetch: refetchSumSubStatus } = useQuery(['getSumSubStatus'], kycApi.getSumSubStatus,{
    onSuccess: async (res) => {
      try {
        if(res.data?.myStatus === 'New' || res.data?.myStatus === 'In process') {
          const timeout = new Promise(resolve => {
            setTimeout(() => resolve('refetch'), 5000);
          });
          await timeout.then(() => {
            refetchSumSubStatus();
          })
        }
        // else {
        //   const timeout = new Promise(resolve => {
        //     setTimeout(() => resolve('refetch'), 30000);
        //   });
        //   await timeout.then(() => {
        //     refetchSumSubStatus();
        //   })
        // }
      } catch (e) {
        console.error(e);
      }
    }
  });

  useEffect(() => {
    if (data && !isQuestionnairesGenerated) {
      const questLength = data.data.length || 0;
      if (questLength === 0) {
        (async () => {
          await mutate();
          await refetch();
          setIsQuestionnairesGenerated(true);
        })();
      }
    }
  }, [data, isQuestionnairesGenerated]);

  if (!isLoading) {
    return (
      <ProfileStatusView
        data={data}
        investor={investor}
        rejectedQuestionnaires={rejectedQuestionnaires}
        sumSubStatusRes={sumSubStatusRes}
        refetchQuests={refetch}
        referralView={referralView}
        referralId={referralId}
      />
    );
  }

  return null;
};
