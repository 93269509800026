import React from 'react';
import { Header, GU } from '@aragon/ui';
import {
  SummaryTable,
  MyAssetsTable,
  AvailableAssetsTable,
  MoreBtn,
  Box
} from '../../../../components';
import { Body2Light, PageContainer, Title2 } from '../../../../ui';

export const Assets = ({ cashSummary, assets, myAssets }) => {
  return (
    <PageContainer>
      <Header
        primary={<Title2>Assets</Title2>}
        // secondary={<Button mode="strong" label="Order a report" onClick={() => setOpened(true)}/>}
      />

      <Box padding={0}>
        <SummaryTable data={cashSummary} />
      </Box>

      <Box padding={0} marginTop={'20'}>
        <Body2Light padding={3 * GU}>My Assets</Body2Light>

        <MyAssetsTable data={myAssets?.assets} />

        <MoreBtn link={'/assets/self'} />
      </Box>

      <Box padding={0}>
        <Body2Light padding={3 * GU}>Available Assets</Body2Light>

        <AvailableAssetsTable data={assets?.assets} />

        <MoreBtn link={'/assets/available'} />
      </Box>
    </PageContainer>
  );
};

const mockSummary = {
  equity: '$160,000',
  allCash: '$70,000',
  availableCash: '$80,000',
  assets: '$80,000',
  profitCash: '$12,000',
  profitPercent: '15%',
  lossCash: '$12,000',
  lossPercent: '15%',
  result: '$80,000',
  ROI: '15%'
};
const mockMyAssets = [
  {
    id: '1',
    ticker: 'Lorem',
    type: 'Share',
    issuer: 'Lorem Ipsum',
    issuedate: '9 Nov 2020',
    quantity: '1000',
    issuance: '$80,000',
    lastpurchase: '$80,000',
    profitloss: '$XXXXX',
    percent: 'XX%'
  },
  {
    id: '2',
    ticker: 'Lorem',
    type: 'Bond',
    issuer: 'Lorem Ipsum',
    issuedate: '9 Nov 2020',
    quantity: '500 c',
    issuance: '$80,000',
    lastpurchase: '$80,000',
    profitloss: '$XXXXX',
    percent: 'XX%'
  },
  {
    id: '3',
    ticker: 'Lorem',
    type: 'Bond',
    issuer: 'Lorem Ipsum',
    issuedate: '9 Nov 2020',
    quantity: '1000 ANT',
    issuance: '$80,000',
    lastpurchase: '$80,000',
    profitloss: '$XXXXX',
    percent: 'XX%'
  }
];
const mockAssets = [
  {
    title: 'Asset 1',
    id: 'NT001',
    type: 'market',
    price: '27,96 EUR',
    quantity: '773',
    issuer: 'Issuer 1',
    date: '20/01/2011'
  },
  {
    title: 'Asset 2',
    id: 'NT002',
    type: 'offering',
    price: '27,96 EUR',
    quantity: '773',
    issuer: 'Issuer 1',
    date: '20/01/2011'
  },
  {
    title: 'Asset 3',
    id: 'NT003',
    type: 'sale',
    price: '27,96 EUR',
    quantity: '773',
    issuer: 'Issuer 1',
    date: '20/01/2011'
  }
];
const mockAvailableAssets = [
  {
    assetid: '3',
    ticker: 'ETH',
    type: 'Text',
    issuer: 'Lorem Ipsum',
    issuedate: '9 Nov 2020',
    quantity: '800',
    price: '$80,000'
  },
  {
    assetid: '2',
    ticker: 'ETH',
    type: 'Text',
    issuer: 'Lorem Ipsum',
    issuedate: '9 Nov 2020',
    quantity: '300',
    price: '$80,000'
  }
];
