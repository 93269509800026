import React, { useState } from 'react';
import { Header, GU } from '@aragon/ui';
import { newObject } from '../../../../../utils/helpers';
import { AssetInfoForm } from './AssetInfoForm';
import { IssueParametersForm } from './IssueParametersForm';
import { BackBar, Box, SearchInput } from '../../../../../components';
import { PageContainer, Title2, Row, Body3Light, Button } from '../../../../../ui';
import { ASSET_TYPES_LIST, CURRENCIES, EQUITY_TYPES_LIST } from '../../../../../utils/static';

const YesNoToBoolean = {
  yes: true,
  no: false
};

export const CreateIssueView = ({ CreateIssue }) => {
  const [issueDocuments, setIssueDocuments] = useState([]);
  const [issueInfo, setIssueInfo] = useState({
    assetFullName: '',
    assetTypeKey: '',
    equityTypeKey: '',
    ticker: '',
    currencyKey: '',
    exchange: '',
    isin: '',
    branch: '',
    region: '',
    timing: ''
  });
  const [issueParams, setIssueParams] = useState({
    issuePrice: '',
    numberOfSecurities: '',
    totalNominalValue: '',
    voteRatio: '', //api?
    minQuantityPerOrder: '',
    maxQuantityPerOrder: '',
    issueDate: null,
    instrumentNumber: '',
    issueApplicationNumber: '',
    issueNumber: '',
    issuerAgent: '',
    issueRegistrationCountry: '',
    incomeType: '',
    roadShowPeriod: { start: null, end: null },
    freelyTransferrableKey: -1,
    encumberedKey: -1,
    upcomingOfferingDescription: 'Description',
    issuerId: ''
  });

  const changeAssetInfo = (key, value) => {
    setIssueInfo(newObject(issueInfo, key, value));
  };

  const changeIssueParams = (key, value) => {
    setIssueParams(newObject(issueParams, key, value));
  };

  const addDocuments = (docs) => {
    const clone = [...issueDocuments];
    [...docs].forEach((item) => clone.push(item));

    setIssueDocuments(clone);
  };

  const deleteDocument = (id) => {
    const clone = issueDocuments.filter((item, index) => index !== id);
    setIssueDocuments(clone);
  };

  const onCreateIssue = () => {
    const { assetTypeKey, currencyKey, equityTypeKey, ...otherIssueInfo } = issueInfo;
    const {
      freelyTransferrableKey,
      encumberedKey,
      roadShowPeriod,
      maxQuantityPerOrder,
      ...otherIssueParams
    } = issueParams;
    CreateIssue(
      {
        assetType: ASSET_TYPES_LIST[assetTypeKey],
        currency: CURRENCIES[currencyKey],
        equityType: EQUITY_TYPES_LIST[equityTypeKey],
        maxQuantityPerOrder: 22,
        freelyTransferrable: YesNoToBoolean[freelyTransferrableKey],
        encumbered: YesNoToBoolean[encumberedKey],
        roadShowStartDate: roadShowPeriod.start,
        roadShowEndDate: roadShowPeriod.end,
        ...otherIssueInfo,
        ...otherIssueParams
      },
      issueDocuments
    );
  };

  return (
    <PageContainer>
      <Header primary={<Title2>Create an Issue</Title2>} />
      <BackBar />

      <Box>
        <AssetInfoForm issueInfo={issueInfo} changeAssetInfo={changeAssetInfo} />
      </Box>

      <Box heading={'ISSUE parameters'} padding={0}>
        <IssueParametersForm
          issueParams={issueParams}
          changeIssueParams={changeIssueParams}
          issueDocuments={issueDocuments}
          addDocuments={addDocuments}
          deleteDocument={deleteDocument}
        />
      </Box>

      <Box heading={'Upcoming offering description'} paddingY={2 * GU}>
        <Body3Light>Text</Body3Light>
      </Box>

      <Box heading={'Issuer'} paddingY={2.5 * GU}>
        <SearchInput
          value={issueParams.issuerId}
          onChange={(value) => changeIssueParams('issuerId', value)}
        />
      </Box>

      <Row marginTop={3 * GU} justifyContent={'flex-end'}>
        <Button label={'Create'} onClick={onCreateIssue} mode={'strong'} />
      </Row>
    </PageContainer>
  );
};

// region? vote radio?
const issueTemplate = {
  assetFullName: 'Asset 2',
  assetType: 'EQUITY',
  equityType: 'Fund Share',
  ticker: 'ETH',
  currency: 'EUR',
  exchange: 'GB: LONDON STOCK EXCHANGE',
  isin: 'LT0009694391',
  branch: 1000,
  region: 'some',
  timing: 2000,
  issuePrice: 154,
  numberOfSecurities: 2002,
  totalNominalValue: 100000000000,
  minQuantityPerOrder: 10,
  maxQuantityPerOrder: 100,
  issueDate: new Date(),
  instrumentNumber: 32355,
  issueApplicationNumber: 1000,
  issueNumber: 1,
  issuerAgent: 'text',
  issueRegistrationCountry: 'Belarus',
  incomeType: 'Income',
  roadShowPeriod: new Date(),
  freelyTransferrable: false,
  encumbered: false,
  upcomingOfferingDescription: 'descr',
  issuerId: 1,
  issueDocument: new File(['some'], 'IssueDoc')
};
