import React from 'react';
import { Card, GU } from '@aragon/ui';
import styles from './style.module.css';
import styled from 'styled-components';
import { COLORS } from '../../../utils/static/colors';

const OptionCard = ({ icon, title = '', onClick, className = '', backgroundColor = '#DDE4E9' }) => {
  return (
    <div
      onClick={onClick}
      style={{ display: 'inline-block', marginBottom: `${3 * GU}px` }}
      className={className}
    >
      <Card className={styles['card-custom']} width={`${33 * GU}px`} height={`${32.75 * GU}px`}>
        <div
          className={styles.img}
          style={
            icon
              ? { background: `url("${icon}") center/contain no-repeat` }
              : { background: backgroundColor }
          }
        />

        <Title>{title}</Title>
      </Card>
    </div>
  );
};

export default OptionCard;

const Title = styled.div`
  font-size: 20px;
  font-weight: 300;
  line-height: 30px;
  color: ${COLORS.default};
`;
