import React, {useState, useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Input, Popconfirm } from 'antd';

import { GroupList } from './GroupList';
import { Divider, NestedBox, ActionBox, Label, Space, Box } from '../styled';
import { ProductGroup } from '../../constructorProducts';
import { QuestionnaireGroup } from '../../constructor';

export const Section = ({sectionProp, onCreated,
                          handleCreate,
                          handleUpdate,
                          handleDelete,
                          isCreating,
                          isUpdating,
                          isDeleting,
                          type}) => {
  const { t } = useTranslation();
  const [section, setSection] = useState(sectionProp || {});
  const [showCreateNew, setShowCreateNew] = useState(false);

  useEffect(() => {
    if (!sectionProp?.id) return;

    setSection(sectionProp);
  }, [sectionProp])

  const onChange = (e) => {
    const {name, value} = e.target;
    setSection({...section, [name]: value})
  }

  const handleAction = () => {
    if (section.id) {
      handleUpdate(section);
    } else {
      handleCreate(section).then(res => {
        setSection({});
        onCreated && onCreated();
      });
    }
  }

  const onDelete = () => {
    handleDelete(section);
  }

  const isDisabled = !section.section || (
    section.section === sectionProp?.section &&
    section.section_RU === sectionProp?.section_RU &&
    section.section_LV === sectionProp?.section_LV &&
    section.newNumber === sectionProp?.newNumber &&
    section.sortOrder === sectionProp?.sortOrder
  );

  return (
    <Box isNew={!section.id}>
      <div>
        <Label>{t('Section name')}</Label>
        <Input name='section' value={section.section} onChange={onChange} />
        <Space />
        {type === 'products' && (
          <>
            <Label>{t('Section name')} (RU)</Label>
            <Input name='section_RU' value={section.section_RU} onChange={onChange} />
            <Space />
            <Label>{t('Section name')} (LV)</Label>
            <Input name='section_LV' value={section.section_LV} onChange={onChange} />
            <Space />
          </>
        )}
        <Label>{t('Section order')}</Label>
        <Input name={type === "questionnaire" ? "newNumber" : "sortOrder"}
               value={type === "questionnaire" ? section.newNumber : section.sortOrder}
               style={{ width: '100px' }}
               onChange={onChange}
        />
        <ActionBox>
          <Button
            onClick={handleAction}
            disabled={isDisabled}
            type='primary'
            loading={isCreating || isUpdating}
          >
            {section.id ? t('Update Section') : t('Create Section')}
          </Button>

          {section.id && (
            <Popconfirm
              placement='topRight'
              title={t('Are you sure to delete this section?')}
              onConfirm={onDelete}
              okText={t('Yes')}
              cancelText={t('No')}
            >
              <Button danger loading={isDeleting}>
                {t('Delete Section')}
              </Button>
            </Popconfirm>
          )}
        </ActionBox>
      </div>

      <NestedBox>
        {section.id && <Divider />}
        {type === 'questionnaire' && !!section.questionGroups?.length && (
          <>
            <GroupList list={section.questionGroups} type={type} />
            <Space />
          </>
        )}
        {type === 'products' && !!section.productGroups?.length && (
          <>
            <GroupList list={section.productGroups} type={type} />
            <Space />
          </>
        )}

        {section.id && (
          <>
            {showCreateNew ? (
              <>
                {type === 'questionnaire' && (
                  <QuestionnaireGroup
                    sectionId={section.id}
                    onCreated={() => setShowCreateNew(false)}
                  />
                )}
                {type === 'products' && (
                  <ProductGroup sectionId={section.id} onCreated={() => setShowCreateNew(false)} />
                )}
              </>
            ) : (
              <Button onClick={() => setShowCreateNew(true)}>{t('Add new Group')}</Button>
            )}
          </>
        )}
      </NestedBox>
    </Box>
  );
};