import React, { useEffect, useState } from 'react';
import { GU } from '@aragon/ui';
import { Box, InnerBox, DealsTable, Pagination, Tabs } from '../../../components';
import { PageContainer, Title2, Body2Light, Row, SearchInput } from '../../../ui';
import { DEAL_STATUS_TABS, DEAL_TAB_STATUSES } from '../../../utils/static';

export * from './DealDetails';

export const Deals = ({ deals, fetchDeals, dealsInTable, savedFilters, saveFilters }) => {
  const [tab, setTab] = useState(savedFilters?.tab || 0);
  const [page, setPage] = useState(savedFilters?.page || 0);
  const [search, setSearch] = useState(savedFilters?.search || '');

  useEffect(() => {
    fetchDeals({
      status: DEAL_TAB_STATUSES[tab],
      search: search,
      skip: page * dealsInTable
    });
  }, []);

  const onChangeTab = (value) => {
    fetchDeals({
      status: DEAL_TAB_STATUSES[value]
    });

    setTab(value);
    setPage(0);
  };

  const onChangeSearch = () => {
    fetchDeals({
      status: DEAL_TAB_STATUSES[tab],
      search: search
    });

    setPage(0);
  };

  const onChangePagination = (value) => {
    fetchDeals({
      status: DEAL_TAB_STATUSES[tab],
      search: search,
      skip: value * dealsInTable
    });

    setPage(value);
  };

  return (
    <PageContainer paddingTop={3 * GU}>
      <Title2 marginBottom={3 * GU}>Deals</Title2>

      <Tabs items={DEAL_STATUS_TABS} selected={tab} onChange={onChangeTab} />

      <Box padding={0}>
        <InnerBox paddingBottom={2 * GU} noBorder>
          <Row justifyContent={'space-between'}>
            <Body2Light>Your Deals</Body2Light>

            <SearchInput
              handleSearch={onChangeSearch}
              onChange={setSearch}
              value={search}
              placeholder={'Search'}
              style={{ width: '300px' }}
            />
          </Row>
        </InnerBox>

        <DealsTable
          data={deals?.deals}
          onDetailsSideEffect={() => saveFilters({ tab: tab, page: page, search: search })}
        />

        {deals && deals.count > dealsInTable && (
          <Pagination
            selected={page}
            onChange={onChangePagination}
            pages={Math.ceil(deals.count / dealsInTable)}
          />
        )}
      </Box>
    </PageContainer>
  );
};
