import React, { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { investorApi } from '../../../../../api/investor';
import { GU, Box, Split } from '@aragon/ui';
import {
  RegContainer,
  StepsPanel,
  FooterButtons,
  Info,
  TabsWithInput,
  ImageUpload
} from '../../../../../components';
import {
  AutoComplete,
  Label2,
  Title2,
  Row,
  Col,
  Input,
  Body1Light,
  DropDown
} from '../../../../../ui';
import { individualSteps, CURRENCIES, COUNTRIES } from '../../../../../utils/static';
import { newObject } from '../../../../../utils/helpers';
import styled from 'styled-components';
import styles from '../../styles.module.css';
import { entitiesActions } from '../../../../../store/entities';

const paymentDetailsTemplate = {
  bankAccountScan: null,
  accountName: '',
  bicSwiftCode: '',
  bankName: '',
  bankCode: '',
  country: '',
  city: '',
  address: '',
  correspondentBankForSwift: '',
  ibanAccountNumber: '',
  currencyIndex: null
};

export const Step6 = ({ stepNumber, goBack, goNext }) => {
  const dispatch = useDispatch();
  const [selectedAccount, setSelectedAccount] = useState(0);
  const [paymentDetails, setPaymentDetails] = useState([{ ...paymentDetailsTemplate }]);

  const changeData = (key, value) => {
    let clone = paymentDetails.map((item) => item);
    clone[selectedAccount] = newObject(paymentDetails[selectedAccount], key, value);
    setPaymentDetails(clone);
  };

  const handleAddAccount = () => {
    let clone = paymentDetails.map((item) => item);
    clone.push(paymentDetailsTemplate);
    setPaymentDetails(clone);
    setSelectedAccount(clone.length - 1);
  };

  const handleNext = (index = 0) => {
    dispatch(entitiesActions.showLoader('Processing...'));

    const data = paymentDetails[index];

    if (index < paymentDetails.length) {
      investorApi
        .addPaymentDetails({
          ...data,
          country: data.country,
          currency: CURRENCIES[data.currencyIndex]
        })
        .then((res) => {
          console.log(res);

          if (res.status === 200) {
            handleNext(++index);
          } else {
            //res error
          }
        })
        .catch((e) => {
          dispatch(entitiesActions.addToast('Something went wrong'));
          dispatch(entitiesActions.hideLoader());
        });
    } else {
      dispatch(entitiesActions.hideLoader());
      goNext();
    }
  };

  const disableNext = useMemo(() => {
    for (const payment of paymentDetails) {
      for (const [key, value] of Object.entries(payment)) {
        if (key === 'currencyIndex') {
          if (value === null) {
            return true;
          }
        } else if (!value && key !== 'correspondentBankForSwift' && key !== 'bankAccountScan') {
          return true;
        }
      }
    }
    return false;
  }, [paymentDetails]);

  return (
    <StepsPanelContainer>
      <StepsPanel title={'Registration'} step={stepNumber} steps={individualSteps} />

      <RegContainer>
        <Title style={{ textAlign: 'center' }}>Payment details</Title>

        <Split
          primary={
            <Payments paymentDetails={paymentDetails[selectedAccount]} changeData={changeData} />
          }
          secondary={
            <TabsWithInput
              namesArray={paymentDetails.map((item) => item.accountName)}
              selected={selectedAccount}
              onChangeInput={(e) => changeData('accountName', e.target.value)}
              onAddAccount={handleAddAccount}
              onSelect={(index) => setSelectedAccount(index)}
            />
          }
          invert='horizontal'
        />

        <FooterButtons onBack={goBack} onNext={() => handleNext()} nextDisabled={disableNext} />
      </RegContainer>
    </StepsPanelContainer>
  );
};

const Payments = ({ paymentDetails, changeData }) => {
  return (
    <div>
      <Box className={styles['noPadding']}>
        <div className={styles['innerBox']}>
          <Body1Light>Payment Information</Body1Light>

          <Row margin={-GU} marginTop={3 * GU}>
            <Col padding={GU} width={'70%'}>
              <Input
                label={'BIC/SWIFT code'}
                value={paymentDetails.bicSwiftCode}
                onChange={(e) => changeData('bicSwiftCode', e.target.value)}
                required
              />
            </Col>
          </Row>

          <Row margin={-GU} marginTop={2 * GU}>
            <Col padding={GU} width={'70%'}>
              <Input
                label={'Bank name'}
                value={paymentDetails.bankName}
                onChange={(e) => changeData('bankName', e.target.value)}
                required
              />
            </Col>

            <Col padding={GU} width={'30%'}>
              <Input
                label={'Bank code'}
                value={paymentDetails.bankCode}
                onChange={(e) => changeData('bankCode', e.target.value)}
                required
              />
            </Col>
          </Row>
        </div>
      </Box>

      <Box heading='Bank address' className={styles['noPadding']}>
        <div className={styles['innerBox']}>
          <Row margin={-GU}>
            <Col padding={GU} width={'33%'}>
              <AutoComplete
                label={'Country'}
                items={COUNTRIES}
                value={paymentDetails.country}
                onChange={(value) => changeData('country', value)}
                wide
                required
              />
            </Col>

            <Col padding={GU}>
              <Input
                label={'City'}
                value={paymentDetails.city}
                onChange={(e) => changeData('city', e.target.value)}
                required
              />
            </Col>

            <Col padding={GU}>
              <Input
                label={'Address'}
                value={paymentDetails.address}
                onChange={(e) => changeData('address', e.target.value)}
                required
              />
            </Col>
          </Row>
        </div>
      </Box>

      <Box className={styles['noPadding']}>
        <div className={styles['innerBox']}>
          <Row margin={-GU}>
            <Col padding={GU} width={'70%'} marginBottom={2 * GU}>
              <Input
                label={'CORRESPONDENT BANK FOR SWIFT'}
                value={paymentDetails.correspondentBankForSwift}
                onChange={(e) => changeData('correspondentBankForSwift', e.target.value)}
              />
            </Col>

            <Col padding={GU} width={'70%'}>
              <Input
                label={'IBAN/ACCOUNT NUMBER'}
                value={paymentDetails.ibanAccountNumber}
                onChange={(e) => changeData('ibanAccountNumber', e.target.value)}
                required
              />
            </Col>

            <Col padding={GU} width={'30%'}>
              <DropDown
                label={'CURRENCY'}
                items={CURRENCIES}
                width='100%'
                selected={paymentDetails.currencyIndex}
                onChange={(item) => changeData('currencyIndex', item)}
                required
              />
            </Col>
          </Row>
        </div>
      </Box>

      <Box className={styles['noPadding']} style={{ marginBottom: '25px' }}>
        <div className={styles['innerBox']}>
          <Body1Light>Documents</Body1Light>

          <div
            style={{ display: 'flex', flexDirection: 'column', width: '100%', marginTop: '24px' }}
          >
            <InputLabel marginBottom={GU}>Bank account Scan</InputLabel>
            <Row>
              <ImageUpload
                value={paymentDetails.bankAccountScan}
                onChange={(file) => changeData('bankAccountScan', file)}
              />

              <Info style={{ marginLeft: '16px', height: 'fit-content' }}>
                Allowed file formats: .jpg, .png
                <br />
                Max. resolution: 640x640px
                <br />
                Max. file size: 500kb
              </Info>
            </Row>
          </div>
        </div>
      </Box>
    </div>
  );
};

const StepsPanelContainer = styled.div`
  display: flex;
`;
const Title = styled(Title2)`
  font-weight: 300;
  margin-bottom: ${7 * GU}px;
`;
const InputLabel = styled(Label2)``;
