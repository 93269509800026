import { useCallback, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ConstructorApi } from '../api';
import { MessageContext } from '../../../app/MessageProvider';
import { useProducts } from './useProducts';
import { queryClient } from '../../../app/QueryClientProvider';
import { adminLocalizationApi, adminLocalizationKeys } from '../../../api';

export const useProduct = (id) => {
  const messageApi = useContext(MessageContext);
  const { t } = useTranslation();
  const { data } = useProducts();

  const productData = useMemo(() => {
    if (!data || !data?.length) return undefined;

    return data.find(item => item.id === id);
  }, [id, data]);

  const [isCreating, setIsCreating] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const handleCreate = useCallback(async (product) => {
    try {
      setIsCreating(true);
      await ConstructorApi.createProduct(product);
      await queryClient.refetchQueries({ queryKey: [adminLocalizationKeys.getProductsSections], queryFn: () => adminLocalizationApi.getProductsSections({ productId: product.id }) });
      messageApi.success(t('Created'));
    } catch (e) {
      messageApi.error(e?.message || 'Something went wrong');
    } finally {
      setIsCreating(false);
    }
  }, []);

  const handleUpdate = useCallback(async (product) => {
    try {
      setIsUpdating(true);
      await ConstructorApi.updateProduct(product);
      await queryClient.refetchQueries({ queryKey: [adminLocalizationKeys.getProductsSections], queryFn: () => adminLocalizationApi.getProductsSections({ productId: product.id }) });
      messageApi.success(t('Saved'));
    } catch (e) {
      messageApi.error(e?.message || 'Something went wrong');
    } finally {
      setIsUpdating(false);
    }
  }, []);

  const handleDelete = useCallback(async (product) => {
    try {
      setIsDeleting(true);
      await ConstructorApi.deleteProduct(product);
      await queryClient.refetchQueries({ queryKey: [adminLocalizationKeys.getProductsSections], queryFn: () => adminLocalizationApi.getProductsSections({ productId: product.id }) });
      messageApi.success(t('Deleted'));
    } catch (e) {
      messageApi.error(e?.message || 'Something went wrong');
    } finally {
      setIsDeleting(false);
    }
  }, []);

  return {
    data: productData,
    handleCreate,
    handleUpdate,
    handleDelete,
    isCreating,
    isUpdating,
    isDeleting
  }
}
