import React from 'react';
import { Table, TableRow } from '@aragon/ui';
import { StatusLine } from '../../../../components';
import { Cell, DetailsButton, TableHeader } from '../../components';
import { Body3Light } from '../../../../ui';
import styled from 'styled-components';
import {
  DISPLAY_TRANSACTION_TYPE,
  TRANSFER_STATUSES,
  BROKER_PROFILE
} from '../../../../utils/static';
import { displayFullDate } from '../../../../utils/helpers';
import { history } from '../../../../routing';

const defHeader = ['Id', 'Date', 'Type', 'Status', 'From account', 'To account', 'Equivalent'];
export const TransactionTable = ({ header = defHeader, data, onDetailsSideEffect }) => {
  const onDetailsClick = (id) => {
    onDetailsSideEffect();
    history.push(`/transaction/${id}`);
  };

  const headerItems = header.map((item, key) => <TableHeader title={item} key={key} />);

  const bodyItems =
    data && data.length > 0 ? (
      data.map((item, key) => {
        const fromAccount =
          item.fromAccount || item.fromPaymentDetail || item.fromPersonalAccount || BROKER_PROFILE;
        const toAccount =
          item.toAccount || item.toPaymentDetail || item.toPersonalAccount || BROKER_PROFILE;

        return (
          <TableRow key={key}>
            <Cell>{item.id}</Cell>

            <Cell>{displayFullDate(new Date(item.createdAt))}</Cell>

            <Cell>{DISPLAY_TRANSACTION_TYPE[item.type]}</Cell>

            <Cell>
              <StatusLine
                statusTypes={TRANSFER_STATUSES}
                type={item.status}
                TitleStyle={Body3Light}
              />
            </Cell>

            <Cell>{fromAccount.accountName || fromAccount.name}</Cell>

            <Cell>{toAccount.accountName || toAccount.name}</Cell>

            <Cell>
              {item.amount} {item.currency}
            </Cell>

            <Cell cellProps={{ align: 'right' }}>
              <DetailsButton onClick={() => onDetailsClick(item.id)} />
            </Cell>
          </TableRow>
        );
      })
    ) : (
      <TableRow>
        <Cell cellProps={{ style: { border: 'none' } }}>No transactions</Cell>
      </TableRow>
    );

  return (
    <StyledTable header={<TableRow>{headerItems}</TableRow>} noSideBorders>
      {bodyItems}
    </StyledTable>
  );
};

const StyledTable = styled(Table)`
  th {
    border-left: none;
    border-right: none;
  }
`;
