import React from 'react';
import { history } from '../../../../routing';
import { Button, GU } from '@aragon/ui';
import { Box } from '../../../../components';
import { Row, MaisonBody1, MaisonBody2 } from '../../../../ui';
import styled from 'styled-components';
import fireIcon from '../../../../assets/icons/fire-icon.svg';

export const AssetCard = ({ data }) => {
  if (data) {
    const { title, id, type, price, quantity, issuer, date } = data;

    return (
      <StyledBox>
        <Row marginBottom={0.5 * GU}>
          <MaisonBody1>{title}</MaisonBody1>
          <FireIcon src={fireIcon} alt='' />
        </Row>

        <MaisonBody2 marginBottom={'11'} color='rgba(82, 82, 82, .75)'>
          {id}
        </MaisonBody2>

        <MaisonBody1 marginBottom={2 * GU} color={TYPES[type].color}>
          {TYPES[type].title}
        </MaisonBody1>

        <Row marginBottom={GU} justifyContent={'space-between'}>
          <MaisonBody1>{price} per note</MaisonBody1>
          <MaisonBody1>{quantity} notes</MaisonBody1>
        </Row>

        <Row justifyContent={'space-between'} alignItems={'flex-end'}>
          <Row margin={-0.5 * GU}>
            <MaisonBody1>{issuer}</MaisonBody1>
            <MaisonBody1>{date}</MaisonBody1>
          </Row>

          <Button mode='normal' size='small' onClick={() => history.push(`/asset/${id}`)}>
            <MaisonBody1 color={'#445159'}>Make an order</MaisonBody1>
          </Button>
        </Row>
      </StyledBox>
    );
  }

  return null;
};

const TYPES = {
  market: {
    title: 'Secondary Market',
    color: '#00D985'
  },
  offering: {
    title: 'Upcoming offering',
    color: '#FB7777'
  },
  sale: {
    title: 'Initial Sale',
    color: '#FFB900'
  }
};

const StyledBox = styled(Box)`
  margin-bottom: ${2 * GU}px;
  > div {
    padding: 14px 12px 12px 20px;
  }
`;
const FireIcon = styled.img`
  width: 16px;
  height: 16px;
  margin-left: 4px;
`;
