import React, { useMemo } from 'react';
import { useController } from 'react-hook-form';
import { PhoneInput as _PhoneInput } from '../../../../ui/atoms';
import { PHONE_CODES } from '../../../../utils/static';
import { useTranslation } from 'react-i18next';

const DIVIDE_SYMBOL = '/';
export const PhoneInput = ({ form, label, required, ...props }) => {
  const { t, i18n } = useTranslation();
  const {
    field: { ref, onChange, value, ...field },
    fieldState: { error }
  } = useController({
    ...form,
    rules: {
      ...form.rules,
      validate: {
        isCode: (v) => {
          if (!required && !v.replace(DIVIDE_SYMBOL, '')) return true;
          const dividerIndex = v.indexOf(DIVIDE_SYMBOL);
          const code = dividerIndex >= 0 ? v.slice(0, dividerIndex) : '';

          let codeWithCountry = '';
          const validCode = PHONE_CODES.find((phoneCode) => phoneCode.code === code);
          if (validCode) codeWithCountry = `(${validCode.code}) ${validCode.country[i18n.language]}`;

          if (!code) return 'Enter code';
          if (code) {
            return (
              PHONE_CODES.filter((item) => `(${item.code}) ${item.country[i18n.language]}` === codeWithCountry)[0] || 'Select phone code from list'
            );
          }
        },
        isNumber: (v) => {
          if (!required && !v.replace(DIVIDE_SYMBOL, '')) return true;
          const dividerIndex = v.indexOf(DIVIDE_SYMBOL);
          const number = dividerIndex >= 0 ? v.slice(dividerIndex + 1) : '';

          return number ? true : t('The number is required');
        }
      }
    },
    defaultValue: ''
  });

  const changeCode = (code) => {
    const dividerIndex = value.indexOf(DIVIDE_SYMBOL);
    const number = dividerIndex >= 0 ? value.slice(dividerIndex + 1) : '';

    let codeNumber = '';
    const codeStart = code.indexOf('(');
    const codeEnd = code.indexOf(')');

    if (codeStart >= 0 && codeEnd >= 0) {
      codeNumber = code.slice(codeStart + 1, codeEnd);
    } else if (code) {
      codeNumber = code;
    }

    onChange({ target: { value: `${codeNumber}${DIVIDE_SYMBOL}${number}` } });
  };
  const changeNumber = (e) => {
    const dividerIndex = value.indexOf(DIVIDE_SYMBOL);
    const code = dividerIndex >= 0 ? value.slice(0, dividerIndex) : '';

    let codeNumber = '';
    const codeStart = code.indexOf('(');
    const codeEnd = code.indexOf(')');

    if (codeStart >= 0 && codeEnd >= 0) {
      codeNumber = code.slice(codeStart + 1, codeEnd);
    } else if (code) {
      codeNumber = code;
    }

    onChange({ target: { value: `${codeNumber}${DIVIDE_SYMBOL}${e.target.value}` } });
  };

  const values = useMemo(() => {
    if (value) {
      const valuesArray = value.split(DIVIDE_SYMBOL);

      return {
        code: valuesArray.length > 1 ? valuesArray[0] : '',
        number: valuesArray.length > 1 ? valuesArray[1] : valuesArray[0]
      };
    }

    return { code: '', number: '' };
  }, [value]);

  return (
    <_PhoneInput
      {...field}
      {...props}
      required={required}
      rowLabel={label}
      code={values.code}
      value={values.number}
      setCode={changeCode}
      onChange={changeNumber}
      codeError={error && error.type === 'isCode' && error.message}
      error={error && error.type !== 'isCode' && error.message}
      clearRegex={/[^0-9]/g}
      innerRef={ref}
    />
  );
};
