import React, { useEffect, useState } from 'react';
import { adminApi } from '../../../../../api';
import { DealsView } from '../View';

const DEALS_ON_PAGE = 5;
export const Deals = () => {
  const [deals, setDeals] = useState(null);
  const [totalTurnover, setTotalTurnover] = useState(null);

  useEffect(() => {
    fetchDeals();
    fetchTotalTurnover();
  }, []);

  const fetchDeals = (params) => {
    adminApi
      .getDeals({
        take: DEALS_ON_PAGE,
        ...params
      })
      .then((res) => {
        if (res.status === 200) {
          setDeals(res.data);
        }
      });
  };

  const fetchTotalTurnover = () => {
    adminApi.getTotalTurnover().then((res) => {
      if (res.status === 200) {
        setTotalTurnover(res.data);
      }
    });
  };

  return (
    <DealsView
      totalTurnover={totalTurnover}
      deals={deals}
      ASSETS_ON_PAGE={DEALS_ON_PAGE}
      fetchDeals={fetchDeals}
    />
  );
};
