import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import { kycApi, adminKYCApi } from '../../../../../api';
import { SidePanel } from '@aragon/ui';
import { Container } from '../../../../../ui/layout';
import { DropDown, Button } from '../../../../../ui/atoms';
import { Body2Light } from '../../../../../ui/typography';
import styled from 'styled-components';
import { entitiesActions } from '../../../../../store/entities';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Checkbox } from 'antd';

export const AddProductModal = ({ visible = false, onClose, user, onSuccess }) => {
  switch (user) {
    case 'investor': {
      return <AddModalInvestor visible={visible} onClose={onClose} onSuccess={onSuccess} />;
    }
    case 'admin': {
      return <AddModalAdmin visible={visible} onClose={onClose} onSuccess={onSuccess} />;
    }
    default:
      return null;
  }
};

const AddModalInvestor = ({ onSuccess, visible, ...props }) => {
  const { data, refetch } = useQuery(['getAllowedProductsToAdd'], kycApi.getAllowedProductsToAdd);
  const { mutateAsync } = useMutation(kycApi.requestProduct);

  useEffect(() => {
    if (visible) refetch();
  }, [visible]);

  const allowedProductsList = useMemo(() => (data ? data.data : null), [data]);

  const onAdd = async (productId) => {
    try {
      await mutateAsync(productId);
      if (onSuccess) await onSuccess();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <AddModal allowedProductsList={allowedProductsList} onAdd={onAdd} visible={visible} {...props} />
  );
};

const AddModalAdmin = ({ onSuccess, visible, ...props }) => {
  const { id } = useParams();
  const { data, refetch } = useQuery(['getAllowedProductsToAdd'], () =>
    adminKYCApi.getAllowedProductsToAdd({ investorId: id })
  );
  const { mutateAsync } = useMutation(adminKYCApi.requestProduct);

  useEffect(() => {
    if (visible) refetch();
  }, [visible]);

  const allowedProductsList = useMemo(() => (data ? data.data : null), [data]);

  const onAdd = async (productId, onlyAdmin) => {
    try {
      await mutateAsync({ investorId: id, productId: productId, onlyAdmin: onlyAdmin });
      if (onSuccess) await onSuccess();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <AddModal allowedProductsList={allowedProductsList} onAdd={onAdd} visible={visible} {...props} adminView={true} />
  );
};

const AddModal = ({ allowedProductsList, onAdd, visible, onClose, adminView }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [selected, setSelected] = useState(null);
  const [onlyAdmin, setOnlyAdmin] = useState(false);

  const onAddProduct = async () => {
    try {
      await onAdd(allowedProductsList[selected].id, onlyAdmin);
      setSelected(null);
      onClose();
      dispatch(entitiesActions.addToast(t('Successfully added')));
    } catch (e) {
      console.error(e);
      dispatch(entitiesActions.addToast(t('Something went wrong')));
    }
  };

  return (
    <SidePanel opened={visible} onClose={onClose}>
      <PanelContainer padding={32}>
        {allowedProductsList && allowedProductsList.length > 0 && (
          <DropDown
            label={t('Allowed to add products')}
            items={allowedProductsList.map((item) => adminView ? (item.product ? item.product.name : item.name ?? '') : item.name)}
            selected={selected}
            onChange={setSelected}
          />
        )}
        {adminView && (
          <Body2Light marginLeft={-285} marginTop={15}>
            <Checkbox name="OnlyAdmin"
                      value={onlyAdmin}
                      checked={onlyAdmin}
                      onChange={e => setOnlyAdmin(e.target.checked)}
            >
              {t('OnlyAdmin')}
            </Checkbox>
          </Body2Light>
        )}
        {allowedProductsList && allowedProductsList.length === 0 && (
          <Body2Light>{t('Not allowed products to add')}</Body2Light>
        )}

        <Container marginTop={24}>
          <Button
            label={t('Add')}
            onClick={onAddProduct}
            mode={'strong'}
            disabled={typeof selected !== 'number'}
          />
        </Container>
      </PanelContainer>
    </SidePanel>
  );
};

const PanelContainer = styled(Container)`
  text-align: center;
`;
