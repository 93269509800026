import React, {useState, useCallback} from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Input, Popconfirm } from 'antd';

import { ActionBox, Space, Label, Box } from '../styled';

export const Answer = ({answerProp, questionId, onCreated,
                         handleCreate,
                         handleUpdate,
                         handleDelete,
                         isCreating,
                         isUpdating,
                         isDeleting, type}) => {
  const [answer, setAnswer] = useState(answerProp || {});
  const { t } = useTranslation();

  const handleAction = useCallback(() => {
    if (answer.id) {
      handleUpdate(answer);
    } else {
      handleCreate(answer, questionId).then(res => {
        setAnswer({});
        onCreated && onCreated();
      });
    }
  }, [answer])

  const onDelete = () => {
    handleDelete(answer);
  }

  const isDisabled = !answer.answer || (
    answer.answer === answerProp?.answer &&
    answer.answer_RU === answerProp?.answer_RU &&
    answer.answer_LV === answerProp?.answer_LV &&
    answer.sortOrder === answerProp?.sortOrder &&
    answer.points === answerProp?.points
  )

  return (
    <Box isNew={!answer.id}>
      <div>
        <Label>{t('Answer')}</Label>
        <Input name="answer"
               value={answer.answer}
               onChange={e => setAnswer({...answer, answer: e.target.value})}
        />
        <Space/>
        {type === 'products' && <>
          <Label>{t('Answer (RU)')}</Label>
          <Input name="answer_RU"
                 value={answer.answer_RU}
                 onChange={e => setAnswer({...answer, answer: e.target.value})}
          />
          <Space/>
          <Label>{t('Answer (LV)')}</Label>
          <Input name="answer_LV"
                 value={answer.answer_LV}
                 onChange={e => setAnswer({...answer, answer: e.target.value})}
          />
          <Space/>
        </>}
        <Label>{t('Order')}</Label>
        <Input name="sortOrder"
               value={answer.sortOrder}
               style={{ width: '100px' }}
               onChange={e => setAnswer({...answer, sortOrder: e.target.value})}
        />
        <Space/>
        <Space/>
        <Label>{t('Points(0 or 1)')}</Label>
        <Input name="points"
               value={answer.points}
               type="number"
               min={0}
               max={1}
               step={1}
               style={{ width: '100px' }}
               onChange={e => setAnswer({...answer, points: e.target.value})}
        />
        <ActionBox>
          <Button onClick={handleAction}
                  disabled={isDisabled}
                  type="primary"
                  loading={isCreating || isUpdating}
          >
            {answer.id ? t('Update Answer') : t('Create Answer')}
          </Button>

          {answer.id && (
            <Popconfirm placement="topRight"
                        title={t('Are you sure to delete this answer?')}
                        onConfirm={onDelete}
                        okText={t('Yes')}
                        cancelText={t('No')}
            >
              <Button danger loading={isDeleting}>{t('Delete Answer')}</Button>
            </Popconfirm>
          )}
        </ActionBox>

      </div>
    </Box>
  );
};
