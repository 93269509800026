import React from 'react';
import { useWatch } from 'react-hook-form';
import { Box } from '../../../../components';
import { Container, Body1Light } from '../../../../ui';
import { FormDatePicker, FormInput } from '../../components';
import { NumberInput } from '../../Fields';

export const CitizenshipDocBox = ({ form }) => {
  const { name, control } = form;
  const watched = useWatch({
    control,
    name: ['residency', 'citizenship']
  });

  if (watched[0] && watched[1] && watched[0] !== watched[1]) {
    return (
      <Box paddingBottom={8}>
        <Body1Light marginBottom={24}>{name}</Body1Light>

        {fields.map((field, key) => (
          <Container marginBottom={16} key={key}>
            <RenderField field={field} control={control} />
          </Container>
        ))}
      </Box>
    );
  }

  return null;
};

const RenderField = ({ field, control }) => {
  const RenderedComponent = componentByType[field];
  return (
    <RenderedComponent
      form={{
        name: field,
        control,
        rules: { required: 'Required' }
      }}
      label={field}
    />
  );
};

const fields = ['docType', 'docNumber', 'issuanceDate', 'expirationDate', 'personalNumber'];

const componentByType = {
  docType: FormInput,
  docNumber: NumberInput,
  issuanceDate: FormDatePicker,
  expirationDate: FormDatePicker,
  personalNumber: NumberInput
};
