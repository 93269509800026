import React, { useContext, useMemo } from 'react';
import { Box } from '../../../customAragon';
import { Body2Light, Body3Light, Button, Col, Row, Title4, Container, Text, Title5 } from '../../../../ui';
import styled from 'styled-components';
import { API_URL, COLORS } from '../../../../utils/static';
import { displayFullDate, getErrorMessage } from '../../../../utils/helpers/common';
import {
  DISPLAY_SUMSUB_REJECT_STATUS,
  STATUS_BUTTON_MODE,
  STATUS_COLOR,
  STATUS_DISPLAY
} from '../../../../utils/static/kyc';
import { history } from '../../../../routing';
import { useLocation } from 'react-router-dom';
import { useTranslation, withTranslation } from 'react-i18next';
import { adminApi, investorApi } from '../../../../api';
import { MessageContext } from '../../../../app/MessageProvider';
import { FilePdfOutlined } from '@ant-design/icons';

export const QuestBox = (
  {
    data,
    number,
    title = '',
    status = '',
    error = '',
    enabled,
    onClick,
    onClickEdit,
    adminView,
    referralView,
    referralToken,
    ...props
  }) => {
  const { t, i18n } = useTranslation();
  const boxTitle = title || data?.name;
  const boxStatus = status || data?.status;
  const boxEnabled = enabled || data?.enabled;
  const boxViewPermission = data?.viewPermission;
  const boxError = error || data?.error;
  const messageApi = useContext(MessageContext);

  const downloadPdf = async (pdfUrl) => {
    try {
      const response = referralView ? await investorApi.downloadPdfAgent({
        url: pdfUrl,
        token: referralToken
      }) : await adminApi.downloadPdf(pdfUrl);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${boxTitle.split(' ').join('')}.pdf`);
      link.click();
      setTimeout(() => window.URL.revokeObjectURL(url), 0);
    } catch (e) {
      messageApi.error(e);
    }
  }

  return (
    <StyledBox {...props} active={boxEnabled} bgColor={STATUS_COLOR[boxStatus]}>
      <Row margin={-7} nowrap height={'100%'}>
        {number && (
          <Col auto>
            <NumberCircle active={boxEnabled}>{number}</NumberCircle>
          </Col>
        )}
        <Col auto style={{ overflow: 'hidden' }}>
          <Row flexDirection={'column'} justifyContent={'space-between'} nowrap height={'100%'}>
            <Col>
              <Title weight={300} color={COLORS.default} marginBottom={16}>
                {boxTitle}
              </Title>
            </Col>

            <Container>
              {boxError && (
                <Body3Light marginBottom={10}>
                  {t('Comment')}:{' '}
                  <Text color={COLORS.red} fontWeight={600} textInline>
                    {boxError}
                  </Text>
                </Body3Light>
              )}

              <Body2Light>
                {t('Status')}:{' '}
                <Text color={STATUS_COLOR[boxStatus] || COLORS.aragonBlue} textInline fontWeight={700}>
                  {status || t(STATUS_DISPLAY[boxStatus])}
                </Text>
              </Body2Light>
              {data?.person && (<Body2Light>
                {t('Person')}:{' '}
                <Text textInline fontWeight={400}>
                  {data.person}
                </Text>
              </Body2Light>)}
              {data?.company && (<Body2Light>
                {t('Company')}:{' '}
                <Text textInline fontWeight={400}>
                  {data.company}
                </Text>
              </Body2Light>)}

              <Body2Light marginTop={10}>
                {t('Created')}:{' '}
                <Text textInline fontWeight={400}>
                  {data?.created ? displayFullDate(new Date(data?.created), false, i18n.language) : '-'}
                </Text>
              </Body2Light>
              <Body2Light>
                {t('Updated')}:{' '}
                <Text textInline fontWeight={400}>
                  {data?.updated ? displayFullDate(new Date(data?.updated), false, i18n.language) : '-'}
                </Text>
              </Body2Light>
              <Body2Light>
                {t('Expiration date')}:{' '}
                <Text textInline fontWeight={400}>
                  {data?.expiration ? displayFullDate(new Date(data?.expiration), false, i18n.language) : '-'}
                </Text>
              </Body2Light>
              {!boxViewPermission && <Body2Light>
                {t('Next creation')}:{' '}
                <Text textInline fontWeight={400}>
                  {data?.nextCreation ? displayFullDate(new Date(data?.nextCreation), false, i18n.language) : '-'}
                </Text>
              </Body2Light>}
              {adminView && data?.onlyAdmin && <Body2Light>
                {t('Only admin')}:{' '}
                <Text textInline fontWeight={400}>
                  {data.onlyAdmin ? 'true' : '-'}
                </Text>
              </Body2Light>}

              <Container marginTop={16} style={{ display: 'flex', justifyContent:'space-between', alignItems: 'center', gap: '10px', width: '100%' }}>
                <Container style={{ display: 'flex', gap: '16px' }}>
                  <Button
                    label={t('Open')}
                    onClick={onClick}
                    size={'small'}
                    mode={STATUS_BUTTON_MODE[boxStatus] || 'strong'}
                    fontWeight={300}
                    disabled={adminView ? false : !boxEnabled || !boxViewPermission}
                    width={94}
                  />
                  {adminView &&
                    <Button
                      label={t('Edit')}
                      onClick={onClickEdit}
                      size={'small'}
                      mode={'strong'}
                      fontWeight={300}
                      disabled={false}
                      width={94}
                    />
                  }
                </Container>
                {data?.pdfUrl &&
                  <DownloadPdfButton
                    onClick={() => downloadPdf(data.pdfUrl)}
                  ><FilePdfOutlined style={{ fontSize: '20px' }} /></DownloadPdfButton>
                }
              </Container>
            </Container>
          </Row>
        </Col>
      </Row>
    </StyledBox>
  );
};


export const SumSubBox = withTranslation()((
  {
    t,
    data,
    number,
    title = t('Identification'),
    error = '',
    enabled,
    onClick,
    investor,
    adminView,
    referralView,
    ...props
  }) => {
    const { i18n } = useTranslation();
    const location = useLocation();
    const boxStatus = data?.status;
    const boxComment = data?.comment;
    const boxEnabled = investor.accountStatus === 'ACTIVE' && (adminView || !data?.isAnswered);
    const rejectLabels = useMemo(() => data?.reviewResult?.rejectLabels, [data]);
    const boxError = useMemo(() => {
      if (rejectLabels && rejectLabels.length > 0) {
        return rejectLabels.reduce((result, item) => {
          if (item && DISPLAY_SUMSUB_REJECT_STATUS[item]) {
            return result ? `${result}, ${DISPLAY_SUMSUB_REJECT_STATUS[item]}` : DISPLAY_SUMSUB_REJECT_STATUS[item];
          }

          return result;
        }, '');
      }
      if (boxComment) {
        return boxComment;
      }

      return '';
    }, [rejectLabels, boxComment]);

    const onOpen = () => {
      history.push(
        adminView
          ? `/investor/${investor.id}/sumsub-verification`
          : '/sumsub-verification',
        { prevPath: location.pathname, nextState: { lastProfileTab: investor.lastProfileTab } }
      );
    };

    return (
      <StyledBox {...props} active={boxEnabled} bgColor={STATUS_COLOR[boxStatus]}>
        <Row margin={-7} nowrap height={'100%'}>
          {number && (
            <Col auto>
              <NumberCircle active={boxEnabled}>{number}</NumberCircle>
            </Col>
          )}
          <Col auto style={{ overflow: 'hidden' }}>
            <Row flexDirection={'column'} justifyContent={'space-between'} nowrap height={'100%'}>
              <Col>
                <Title weight={300} color={COLORS.default} marginBottom={16}>
                  {title}
                </Title>
              </Col>

              <Container>
                {boxError && (
                  <Body3Light marginBottom={10}>
                    {t('Comment')}:{' '}
                    <Text color={COLORS.red} fontWeight={600} textInline>
                      {boxError}
                    </Text>
                  </Body3Light>
                )}

                <Body2Light>
                  {t('Status')}:{' '}
                  <Text color={STATUS_COLOR[boxStatus] || COLORS.aragonBlue} textInline fontWeight={700}>
                    {t(boxStatus)}
                  </Text>
                </Body2Light>
                {data?.person && (<Body2Light>
                  {t('Person')}:{' '}
                  <Text textInline fontWeight={400}>
                    {data.person}
                  </Text>
                </Body2Light>)}
                {data?.company && (<Body2Light>
                  {t('Company')}:{' '}
                  <Text textInline fontWeight={400}>
                    {data.company}
                  </Text>
                </Body2Light>)}

                <Body2Light marginTop={10}>
                  {t('Created')}:{' '}
                  <Text textInline fontWeight={400}>
                    {data?.created ? displayFullDate(new Date(data?.created), false, i18n.language) : '-'}
                  </Text>
                </Body2Light>
                <Body2Light>
                  {t('Updated')}:{' '}
                  <Text textInline fontWeight={400}>
                    {data?.updated ? displayFullDate(new Date(data?.updated), false, i18n.language) : '-'}
                  </Text>
                </Body2Light>
                <Body2Light>
                  {t('Expiration date')}:{' '}
                  <Text textInline fontWeight={400}>
                    {data?.expiration ? displayFullDate(new Date(data?.expiration), false, i18n.language) : '-'}
                  </Text>
                </Body2Light>

                <Container marginTop={16}>
                  <Button
                    label={t('Open')}
                    onClick={onOpen}
                    size={'small'}
                    mode={STATUS_BUTTON_MODE[boxStatus] || 'strong'}
                    fontWeight={300}
                    disabled={!boxEnabled || boxStatus === 'In process' || referralView}
                    width={94}
                  />
                </Container>
              </Container>
            </Row>
          </Col>
        </Row>
      </StyledBox>
    );
  }
);

const StyledBox = styled(Box)`
  border-radius: 12px;
  overflow: hidden;
  height: 100%;
  // filter: drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.12));
  // filter: drop-shadow(8px 8px 16px rgba(0, 0, 0, 0.08));
  filter: drop-shadow(8px 12px 16px rgba(0, 0, 0, 0.08));
  border: none;

  :after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    height: 6px;
    width: 100%;
    // background: ${p => p.active ? COLORS.aragonBlue : '#F1F3F7'};
    background: ${p => p.bgColor ? p.bgColor : COLORS.aragonBlue};
  }

  > div,
  > div > div {
    height: 100%;
  }
`;
const Title = styled(Title5)`
  // white-space: nowrap;
  // overflow: hidden;
  // text-overflow: ellipsis;
  font-weight: 400;
`;
const NumberCircle = styled.div`
  width: 24px;
  height: 24px;
  padding: 0 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  flex-shrink: 0;
  flex-grow: 0;
  font-size: 14px;
  font-weight: 300;
  color: ${COLORS.white};
  background: ${(p) =>
  p.active ? 'linear-gradient(200.38deg, #32FFF5 -153.94%, #08BEE5 84.51%)' : COLORS.greyLight};
`;

const DownloadPdfButton = styled.button`
  width: 36px; 
  height: 30px; 
  border: 0; 
  outline: 0;
  background: none;
  cursor: pointer;
  transition: 0.1s ease-in;
    
    &:hover {
        opacity: 0.7;
    }
`;
