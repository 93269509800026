import React from 'react';
import { useTranslation } from 'react-i18next';
import { Collapse } from 'antd';

import { Title4 } from '../../../ui';
import {StyledPanel} from '../styled'
import { ProductSection } from '../../constructorProducts';
import { QuestionnaireSection } from '../../constructor';
import { sortWithNulls } from '../../../utils/helpers';

export const SectionList = ({isLoading, data, type}) => {
  const { t } = useTranslation();

  return (
    <>
      {isLoading && <div>Loading...</div>}

      <Title4>{t('Sections')}</Title4>

      <Collapse accordion>
        {data?.sort((a, b) => {
          if (type === "questionnaire") return sortWithNulls(a.newNumber, b.newNumber);
          if (type === "products") return sortWithNulls(Number(a.sortOrder), Number(b.sortOrder));
          return 0;
        }).map(section => (
          <StyledPanel header={section.section}
                       key={section.id}
          >
            {type === 'products' && <ProductSection sectionProp={section} />}
            {type === 'questionnaire' && <QuestionnaireSection sectionProp={section} />}
          </StyledPanel>
        ))}
      </Collapse>

    </>
  );
};
