import React, { useState } from 'react';
import styled from 'styled-components';
import { GU } from '@aragon/ui';
import {
  TableBoxContainer,
  AdminOrdersTable,
  Tabs,
  AdminOrdersHistoryFilters
} from '../../../../components';
import { ExportButton } from '../../../../ui';
import { ORDER_STATUS_TABS, ORDERS_ON_PAGE } from '../../../../utils/static';
import { newObject, ordersFiltersToBack } from '../../../../utils/helpers';

export const OrdersTableBox = ({ orders, fetchOrders, savedFilters, saveFilters }) => {
  const [page, setPage] = useState(savedFilters?.page || 0);
  const [filters, setFilters] = useState({
    currencyIndex: savedFilters?.currencyIndex || -1,
    userTypeIndex: savedFilters?.userTypeIndex || -1,
    securityTypeIndex: savedFilters?.securityTypeIndex || -1,
    orderTypeIndex: savedFilters?.orderTypeIndex || -1,
    validTillIndex: savedFilters?.validTillIndex || -1,
    mfidStatusIndex: savedFilters?.mfidStatusIndex || -1,
    selectedTab: savedFilters?.selectedTab || 0
  });
  const [searchFilters, setSearchFilters] = useState({
    client: savedFilters?.client || '',
    account: savedFilters?.account || '',
    instrument: savedFilters?.instrument || '',
    residence: savedFilters?.residence || '',
    citizenship: savedFilters?.citizenship || ''
  });

  const refetchOrders = (
    selectedPage = page,
    fetchingFilters = { ...searchFilters, ...filters }
  ) => {
    fetchOrders(
      ordersFiltersToBack({
        page: selectedPage,
        ...fetchingFilters
      })
    );
  };

  const changeFilters = (key, value) => {
    const newFilters = newObject(filters, key, value);
    updateFilters(0, { ...searchFilters, ...newFilters });
    setFilters(newFilters);
  };

  const changeSearchFilters = (key, value) => {
    setSearchFilters(newObject(searchFilters, key, value));
  };

  const updateFilters = (page = 0, fetchingFilters = { ...searchFilters, ...filters }) => {
    refetchOrders(page, fetchingFilters);
    setPage(page);
  };

  const onUpdatePage = (value) => {
    updateFilters(value);
  };

  if (orders) {
    return (
      <div>
        <TabsWrapper>
          <Tabs
            items={ORDER_STATUS_TABS}
            selected={filters.selectedTab}
            onChange={(value) => changeFilters('selectedTab', value)}
          />
        </TabsWrapper>

        <TableBoxContainer
          title={'Orders history'}
          boxProps={{ topRadius: '0' }}
          innerBoxProps={{ paddingBottom: '0' }}
          rowProps={{ alignItems: 'flex-end' }}
          aside={<ExportButton />}
          table={
            <AdminOrdersTable
              data={orders.orders}
              onDetailsSideEffect={() => saveFilters({ page: page, ...filters, ...searchFilters })}
            />
          }
          paginationSettings={{
            visible: orders.count > ORDERS_ON_PAGE,
            selected: page,
            pages: Math.ceil(orders.count / ORDERS_ON_PAGE),
            onChange: onUpdatePage
          }}
        >
          <AdminOrdersHistoryFilters
            filters={filters}
            searchFilters={searchFilters}
            changeFilters={changeFilters}
            changeSearchFilters={changeSearchFilters}
            updateFilters={updateFilters}
            marginTop={3 * GU}
          />
        </TableBoxContainer>
      </div>
    );
  }

  return null;
};

const TabsWrapper = styled.div`
  > div > div {
    margin: ${3 * GU}px 0 0 0;
    position: relative;
    top: 3px;
    z-index: 1;
  }
`;
