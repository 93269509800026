import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Checkbox, Input, Modal, Popconfirm, Radio } from 'antd';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { ActionBox, Divider, Label, NestedBox, Space, Box, AnswerItem } from '../styled';
import { QuestionList } from './QuestionList';
import { Question } from './Question';
import { useGroup } from '../hooks';
import { DependedAnswers } from './DependedAnswers';
const { TextArea } = Input;

const OPERATORS = [
  {
    label: 'All answers',
    value: 'AND',
  },
  {
    label: 'At least one answer',
    value: 'OR',
  }
];

export const Group = ({groupProp, sectionId, onCreated}) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const {
    handleUpdate,
    handleDelete,
    handleCreate,
    isCreating,
    isUpdating,
    isDeleting
  } = useGroup({questionnaireId: id})
  const [group, setGroup] = useState(groupProp || {});
  const [dAnswers, setDAnswers] = useState([]);
  const [showCreateNew, setShowCreateNew] = useState(false);
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [isDepends, setIsDepends] = useState(false);

  useEffect(() => {
    if (!groupProp?.id) return;

    setGroup(groupProp);
    if (groupProp?.visibleConditions?.length) {
      setIsDepends(true);
    }
  }, [groupProp])

  const onChange = (e) => {
    const {name, value} = e.target;
    setGroup({...group, [name]: value})
  }

  const onChangeOperator = ({ target: { value } }) => {
    setGroup({...group, visibleOperator: value})
  };

  const onAddAnswers = (ans) => {
    setDAnswers(ans);

    setIsModalOpened(false)
  }

  const handleAction = () => {
    const dAnswersProp = isDepends ? dAnswers : [];

    if (group.id) {
      handleUpdate({group, dAnswersProp});
    } else {
      handleCreate({group, sectionId, dAnswersProp}).then(res => {
        setGroup({});
        onCreated && onCreated();
      });
    }
  }

  const onDelete = () => {
    handleDelete(group);
  }

  const isDisabled = !group.id && !group.title;

  console.log('group', group);
  return (
    <Box isNew={!group.id}>
      <div>
        <Label>{t('Group Title')}</Label>
        <TextArea name="title" value={group.title} onChange={onChange} rows={4} />
        <Space/>
        <Checkbox name="isDepends"
                  value={isDepends}
                  checked={isDepends}
                  onChange={e => setIsDepends(e.target.checked)}
        >
          {t('This group depends on the answers')}
        </Checkbox>
        <Space/>
        {isDepends && (
          <>
            <div>
              {dAnswers.map(item => (
                <AnswerItem>{item.answer}</AnswerItem>
              ))}
            </div>
            <Space/>
            <Button onClick={() => setIsModalOpened(true)}
                    type="primary"
            >
              {group?.visibleConditions?.length ? "Update dependent Answers" : "Select dependent Answers"}
            </Button>

            <Space/>
            <Radio.Group
              options={OPERATORS}
              onChange={onChangeOperator}
              value={group.visibleOperator}
            />

            <Modal
              title={"Select dependent Answers"}
              width={700}
              style={{
                top: 20,
              }}
              okText={'Add'}
              footer={null}
              open={isModalOpened}
              // onOk={onAddAnswers}
              onCancel={() => setIsModalOpened(false)}
            >
              <DependedAnswers visibleConditions={groupProp?.visibleConditions}
                               onAddAnswers={onAddAnswers}
              />
            </Modal>
          </>
        )}
        <Space/>
        <Label>{t('Order')}</Label>
        <Input name="sortOrder"
               value={group.sortOrder}
               style={{ width: '100px' }}
               onChange={onChange}
        />
        <Space/>
        <Flex>
          <Item>
            <Checkbox name="isRequired"
                      value={group.isMultiple}
                      checked={group.isMultiple}
                      onChange={e => setGroup({...group, isMultiple: e.target.checked})}
            >
              {t('Is Multiple')}
            </Checkbox>
          </Item>

          {group.isMultiple && (
            <>
              <Item>
                <Label>{t('Multiple Min')}</Label>
                <Input name="multipleMin"
                       value={group.multipleMin}
                       onChange={onChange}
                />
              </Item>
              <Item>
                <Label>{t('Multiple Max')}</Label>
                <Input name="multipleMax"
                       value={group.multipleMax}
                       onChange={onChange}
                />
              </Item>
            </>
          )}
        </Flex>


        <ActionBox>
          <Button onClick={handleAction}
                  disabled={isDisabled}
                  type="primary"
                  loading={isCreating || isUpdating}
          >
            {group.id ? t('Update Group') : t('Create Group')}
          </Button>
          {group.id && (
            <Popconfirm placement="topRight"
                        title={t('Are you sure to delete this group?')}
                        onConfirm={onDelete}
                        okText={t('Yes')}
                        cancelText={t('No')}
            >
              <Button danger loading={isDeleting}>{t('Delete Group')}</Button>
            </Popconfirm>
          )}
        </ActionBox>
      </div>

      <NestedBox>
        {group.id && <Divider/>}
        {!!group.questions?.length && (
          <>
            <QuestionList list={group.questions}/>
            <Space/>
          </>
        )}

        {group.id && (
          <>
            {showCreateNew ? (
              <Question groupId={group.id}
                        onCreated={() => setShowCreateNew(false)}
              />
            ) : (
              <Button onClick={() => setShowCreateNew(true)}>
                {t('Add new Question')}
              </Button>
            )}
          </>
        )}
      </NestedBox>

    </Box>
  );
};

const Flex = styled.div`
  display: flex;
`;

const Item = styled.div`
  width: 150px;
  padding-right: 20px;
`;