import React, { useEffect } from 'react';
import { Dashboard } from '../../../pages/investor';
import { useDispatch, useSelector } from 'react-redux';
import { dealsActions } from '../../../store/deals';
import { assetsActions } from '../../../store/assets';
import { investorActions } from '../../../store/investor';
import { walletActions } from '../../../store/wallet';

const assetsInTable = 3;
const myAssetsInTable = 4;
const dealsInTable = 3;
const transactionsInTable = 5;

export const DashboardContainer = () => {
  const dispatch = useDispatch();
  const { deals, assets, wallet } = useSelector((state) => state);
  const { cashSummary } = useSelector((state) => state.investor);

  useEffect(() => {
    dispatch(investorActions.getCashSummary());
    dispatch(assetsActions.getAssets({ take: assetsInTable }));
    dispatch(assetsActions.getMyAssets({ take: myAssetsInTable }));
    dispatch(dealsActions.getDeals({ take: dealsInTable }));
    dispatch(walletActions.getTransactions({ take: transactionsInTable, reverse: true }));
  }, []);

  return (
    <Dashboard
      cashSummary={cashSummary}
      assets={assets.assets}
      myAssets={assets.myAssets}
      deals={deals.deals}
      transactions={wallet.transactions?.transactions}
    />
  );
};
