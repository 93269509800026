import { useQuery } from 'react-query';
import { getAllDocumentSections } from '../api/index';

export const useAllDocumentSections = () => {
  const { isLoading, isError, data, error } = useQuery({
    queryKey: getAllDocumentSections,
    queryFn: getAllDocumentSections,
    select: res => res.data
  });

  return {
    isLoading,
    isError,
    data,
    error
  }
}
