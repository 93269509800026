import React from 'react';
import { GU, Text } from '@aragon/ui';
import { Box, InnerBox, ProfileViewerExtended, Badge, BoxLine } from '../../../../components';
import { Container } from '../../../../ui';
import { COLORS } from '../../../../utils/static/colors';

export const CompanyInfoBox = ({ data }) => {
  return (
    <Box padding={0}>
      <InnerBox paddingBottom={2.5 * GU}>
        <ProfileViewerExtended avatarUrl={data.logoURL} name={data.name} verified>
          <Container paddingTop={2 * GU}>
            <Badge title={data.rating} color={COLORS.aragonLightBlue} />
          </Container>
        </ProfileViewerExtended>
      </InnerBox>

      <InnerBox paddingY={GU}>
        <BoxLine title={'Company Type'}>
          <Badge title={data.type} />
        </BoxLine>
        <BoxLine title={'E-mail'} data={data.email} />
        <BoxLine title={'Web-site'} data={<Text color={COLORS.blue}>{data.website}</Text>} />
      </InnerBox>
    </Box>
  );
};
