import styled from 'styled-components';
import { LoadingRing } from '@aragon/ui';

export const Loading = styled(LoadingRing)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;

  svg {
    height: 80px;
    width: 80px;
  }
`;