import React, { useEffect, useMemo, useState } from 'react';
import { SidePanel, GU, Text, IconCross } from '@aragon/ui';
import { TermsCheckbox, Tabs } from '../../../../components';
import {
  InnerBox,
  Input,
  DropDown,
  Row,
  Body2Light,
  Body1Light,
  Body3Light,
  Button
} from '../../../../ui';
import { COLORS, TRANSFER_TYPES, DISPLAY_TRANSFER_TYPE } from '../../../../utils/static';
import { newObject } from '../../../../utils/helpers';

const FEE = 10;
const defaultData = {
  fromIndex: -1,
  toIndex: -1,
  amount: '',
  paymentDetails: ''
};

export const NewTransferPanel = ({
  paymentDetails,
  personalAccounts,
  availableWithdrawal,
  onSubmitTransfer,
  opened,
  setOpened
}) => {
  const [data, setData] = useState({ ...defaultData });
  const [typeIndex, setTypeIndex] = useState(0);
  const [termsChecked, setTermsChecked] = useState(false);

  useEffect(() => {
    setData({ ...defaultData });
  }, [typeIndex]);

  const changeData = (key, value) => {
    if (key === 'amount') {
      if (value < 0) value = 1;
    }
    setData(newObject(data, key, value));
  };

  const onSubmit = () => {
    const { fromIndex, toIndex, ...otherData } = data;

    const fromToData = {};
    switch (TRANSFER_TYPES[typeIndex]) {
      case 'WITHDRAWAL': {
        fromToData.fromPersonalAccountId = personalAccounts[fromIndex].id;
        fromToData.toPaymentDetailId = paymentDetails[toIndex].id;
        break;
      }
      case 'FUNDING': {
        fromToData.fromPaymentDetailId = paymentDetails[fromIndex].id;
        fromToData.toPersonalAccountId = personalAccounts[toIndex].id;
        break;
      }
    }

    onSubmitTransfer({
      type: TRANSFER_TYPES[typeIndex],
      fee: FEE,
      ...fromToData,
      ...otherData
    });
  };

  const fromToOptions = useMemo(() => {
    const paymentsList = paymentDetails ? paymentDetails.map((item) => item.accountName) : [];
    const personalAccountsList = personalAccounts
      ? personalAccounts.map((item) => item.accountName || 'Personal account')
      : [];

    switch (TRANSFER_TYPES[typeIndex]) {
      case 'WITHDRAWAL': {
        return { from: personalAccountsList, to: paymentsList };
      }
      case 'FUNDING': {
        return { from: paymentsList, to: personalAccountsList };
      }
    }
  }, [typeIndex, paymentDetails, personalAccounts]);

  const largeAmount = useMemo(
    () => TRANSFER_TYPES[typeIndex] === 'WITHDRAWAL' && data.amount > availableWithdrawal,
    [data.amount, availableWithdrawal, typeIndex]
  );

  const buttonActive = useMemo(() => {
    const { amount, fromIndex, toIndex } = data;

    return amount && fromIndex >= 0 && toIndex >= 0 && termsChecked && !largeAmount;
  }, [data, termsChecked, largeAmount]);

  return (
    <SidePanel
      title={<Body1Light>New transfer</Body1Light>}
      opened={opened}
      onClose={() => setOpened(false)}
    >
      <Tabs
        items={[
          ...TRANSFER_TYPES.map((item) => DISPLAY_TRANSFER_TYPE[item]),
          <Text color={COLORS.red}>Subaccount transfer</Text>
        ]}
        selected={typeIndex}
        onChange={(index) => index !== 2 && setTypeIndex(index)}
      />

      <InnerBox paddingY={GU}>
        <Input
          value={data.amount}
          onChange={(e) => changeData('amount', e.target.value)}
          label={'Amount'}
          type={'number'}
          min={'0'}
          required
          adornment={<Body2Light color={COLORS.iconColor}>EUR</Body2Light>}
          adornmentPosition={'end'}
          adornmentSettings={{ padding: 12, width: 80 }}
          wide
          marginBottom={2 * GU}
        />

        <DropDown
          selected={data.fromIndex}
          onChange={(index) => changeData('fromIndex', index)}
          items={fromToOptions.from}
          label={'FROM ACCOUNT'}
          marginBottom={2 * GU}
          required
        />

        <DropDown
          selected={data.toIndex}
          onChange={(index) => changeData('toIndex', index)}
          items={fromToOptions.to}
          label={'To ACCOUNT'}
          marginBottom={2 * GU}
          required
        />

        <Input
          value={data.paymentDetails}
          onChange={(e) => changeData('paymentDetails', e.target.value)}
          label={'Payment details'}
          marginBottom={2 * GU}
        />

        <Row justifyContent={'space-between'} marginBottom={2 * GU}>
          <Body2Light color={COLORS.greyMedium}>Fee</Body2Light>
          <Body2Light color={COLORS.greyMedium}>{FEE} EUR</Body2Light>
        </Row>

        <Button onClick={onSubmit} disabled={!buttonActive} label={'Submit'} mode={'strong'} wide />

        {largeAmount && (
          <Row marginTop={GU} alignItems={'center'}>
            <IconCross size={'small'} color={COLORS.red} />
            <Body3Light marginLeft={GU} marginTop={'2'}>
              Amount is greater than balance available
            </Body3Light>
          </Row>
        )}

        <TermsCheckbox checked={termsChecked} setChecked={setTermsChecked} marginTop={3 * GU} />
      </InnerBox>
    </SidePanel>
  );
};
