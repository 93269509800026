import React, { useMemo } from 'react';
import { GU, IconArrowRight } from '@aragon/ui';
import { Title2, PageContainer, Row, Col, Body2Light, Label } from '../../../ui';
import {
  BackBar,
  Box,
  InnerBox,
  AddressBox,
  BoxLine,
  TransactionDetailsBox,
  TransactionParticipantsBoxes
} from '../../../components';
import { DISPLAY_TRANSFER_TYPE, BROKER_PROFILE } from '../../../utils/static';

export const TransactionDetails = ({ transaction }) => {
  const income = useMemo(
    () => (transaction ? transaction.type === 'FUNDING' : false),
    [transaction]
  );

  if (transaction) {
    const personalAccount = transaction.fromPersonalAccount || transaction.toPersonalAccount || {};
    const paymentAccount = transaction.fromPaymentDetail || transaction.toPaymentDetail || {};
    const sender = transaction.fromAccount;
    const receiver = transaction.toAccount;
    const isTransfer = DISPLAY_TRANSFER_TYPE[transaction.type];

    console.log(sender, receiver);
    return (
      <PageContainer paddingTop={3 * GU}>
        <Title2 marginBottom={2 * GU}>Transaction Details</Title2>
        <BackBar />

        <Row margin={-GU} cols={2}>
          <Col>
            <TransactionDetailsBox data={transaction} />

            {transaction.paymentDetails && (
              <Box heading={'PAYMENT DETAILS'}>
                <Body2Light>{transaction.paymentDetails}</Body2Light>
              </Box>
            )}
          </Col>

          {isTransfer && (
            <Col>
              <Row flexDirection={income ? 'column-reverse' : 'column'}>
                <Box
                  heading={income ? <HeadingWithArrow title={'Account'} /> : 'Account'}
                  padding={0}
                  marginTop={'0'}
                  marginBottom={2 * GU}
                >
                  <InnerBox>
                    <Body2Light>{personalAccount?.accountName}</Body2Light>
                  </InnerBox>

                  <InnerBox paddingTop={2 * GU}>
                    <BoxLine title={'ID'} data={personalAccount.id} />
                    {/*<BoxLine title={'Blockchain adress'}>*/}
                    {/*  <IdentityBadge*/}
                    {/*    entity={mockAccount.blockchainAddress}*/}
                    {/*    labelOnly*/}
                    {/*  />*/}
                    {/*</BoxLine>*/}
                  </InnerBox>
                </Box>

                <Box
                  heading={!income ? <HeadingWithArrow title={'Bank account'} /> : 'Bank account'}
                  padding={0}
                  marginTop={'0'}
                  marginBottom={2 * GU}
                >
                  <InnerBox>
                    <BoxLine title={'BIC/SWIFT code'} data={paymentAccount.bicSwiftCode} />
                    <BoxLine title={'Bank name'} data={paymentAccount.bankName} />
                    <BoxLine title={'Bank code'} data={paymentAccount.bankCode} />
                  </InnerBox>

                  <InnerBox>
                    <BoxLine
                      title={'IBAN/account number'}
                      data={paymentAccount.ibanAccountNumber}
                    />
                  </InnerBox>

                  <AddressBox data={transaction.paymentDetailAddresses[0]} inner />
                </Box>
              </Row>
            </Col>
          )}

          {!isTransfer && (
            <Col>
              <TransactionParticipantsBoxes
                sender={sender}
                receiver={receiver}
                investorId={transaction.investorId}
              />
            </Col>
          )}
        </Row>
      </PageContainer>
    );
  }

  return null;
};

const HeadingWithArrow = ({ title = '' }) => {
  return (
    <Row alignItems={'center'}>
      <IconArrowRight /> <Label marginLeft={2 * GU}>{title}</Label>
    </Row>
  );
};
