import React from 'react';
import { IconAdd, Text } from '@aragon/ui';
import styles from '../style.module.css';
import { Input } from '../../../ui';

const TabsWithInput = ({ namesArray, selected, onChangeInput, onAddAccount, onSelect }) => {
  return (
    <div>
      <div className={styles['tabsContainerInput']}>
        {namesArray.map((item, key) => (
          <div
            className={selected === key ? `${styles['tab']} ${styles['active']}` : styles['tab']}
            onClick={() => onSelect(key)}
          >
            <div style={{ display: 'flex', flexDirection: 'column', width: '92%' }}>
              <Input label={'Account name'} value={item} onChange={onChangeInput} required />
            </div>
          </div>
        ))}
      </div>

      <div className={styles['tabAddMore']} onClick={onAddAccount}>
        <IconAdd color='#8FA4B5' />
        <Text style={{ marginLeft: '9px', marginTop: '2px' }}>Add another account</Text>
      </div>
    </div>
  );
};

export default TabsWithInput;
