import React, {useState, useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Input, Popconfirm } from 'antd';
import { useParams } from 'react-router-dom';

import {GroupList} from './GroupList';
import {Group} from './Group';
import {useSection} from '../hooks';
import { Divider, NestedBox, ActionBox, Label, Space, Box } from '../styled';

export const Section = ({sectionProp, onCreated}) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [section, setSection] = useState(sectionProp || {});
  const {
    handleCreate,
    handleUpdate,
    handleDelete,
    isCreating,
    isUpdating,
    isDeleting
  } = useSection({questionSectionTypeId: id});
  const [showCreateNew, setShowCreateNew] = useState(false);

  useEffect(() => {
    if (!sectionProp?.id) return;

    setSection(sectionProp);
  }, [sectionProp])

  const onChange = (e) => {
    const {name, value} = e.target;
    setSection({...section, [name]: value})
  }

  const handleAction = () => {
    if (section.id) {
      handleUpdate(section);
    } else {
      handleCreate(section).then(res => {
        setSection({});
        onCreated && onCreated();
      });
    }
  }

  const onDelete = () => {
    handleDelete(section);
  }

  const isDisabled = !section.section || (
    section.section === sectionProp?.section &&
    section.newNumber === sectionProp?.newNumber
  );

  console.log('Section section', section)
  return (
    <Box isNew={!section.id}>
      <div>
        <Label>{t('Section name')}</Label>
        <Input name="section" value={section.section} onChange={onChange} />
        <Space/>
        <Label>{t('Section order')}</Label>
        <Input name="newNumber"
               value={section.newNumber}
               style={{ width: '100px' }}
               onChange={onChange}
        />
        <ActionBox>
          <Button onClick={handleAction}
                  disabled={isDisabled}
                  type="primary"
                  loading={isCreating || isUpdating}
          >
            {section.id ? t('Update Section') : t('Create Section')}
          </Button>

          {section.id && (
            <Popconfirm placement="topRight"
                        title={t('Are you sure to delete this section?')}
                        onConfirm={onDelete}
                        okText={t('Yes')}
                        cancelText={t('No')}
            >
              <Button danger loading={isDeleting}>{t('Delete Section')}</Button>
            </Popconfirm>
          )}

        </ActionBox>
      </div>

      <NestedBox>
        {section.id && <Divider/>}
        {!!section.questionGroups?.length && (
          <>
            <GroupList list={section.questionGroups}/>
            <Space/>
          </>
        )}

        {section.id && (
          <>
            {showCreateNew ? (
              <Group sectionId={section.id}
                     onCreated={() => setShowCreateNew(false)}
              />
            ) : (
              <Button onClick={() => setShowCreateNew(true)}>
                {t('Add new Group')}
              </Button>
            )}
          </>
        )}

      </NestedBox>
    </Box>
  );
};