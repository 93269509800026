import React, { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';
import { SyncIndicator } from '@aragon/ui';
import { getSumSubQuestionnaire, kycApi } from '../../api/kyc';
import { userActions } from '../../store/user';
import { SHOWED_SECTIONS_COUNT, STATUSES_WITH_CLEAR } from '../../utils/static/kyc';
import { QuestionnaireView } from '../Questionnaire/View';
import { Toast } from '../kycModule/components/Toast';
import { useDispatch, useSelector } from 'react-redux';
import { history } from '../../routing';
import { kycHelpers } from '../../utils/helpers/kyc';
import { useTranslation } from 'react-i18next';

let appContainer = document.getElementById('app-container');

export const SumsubQuestionnaire = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const { lastProfileTab } = useSelector(state => state.investor);
  const [loader, setLoader] = useState(true);
  const [toast, setToast] = useState('');
  const [currentSection, setCurrentSection] = useState(0);
  const { data, isLoading } = useQuery(
    ['getSumSubQuestionnaire'],
    () => kycApi.getSumSubQuestionnaire(),
    { onSuccess: () => setLoader(false) }
  );

  const questionnaire = useMemo(() => {
    if (data) {
      return data.data;
    }
    return null;
  }, [data]);

  useEffect(() => {
    dispatch(userActions.hideAppContainer());
    return () => dispatch(userActions.showAppContainer());
  }, []);

  const stepsCount = useMemo(() => {
    if (questionnaire && questionnaire.questionSections)
      return Math.ceil(questionnaire.questionSections.length / SHOWED_SECTIONS_COUNT);
    return 0;
  }, [questionnaire]);

  const onNext = async (data) => {
    try {
      setLoader(true);

      const { array } = kycHelpers.collectQuestionnaireCurrentAnswers({
        questionnaire: { questionSections: questionnaire },
        currentSection,
        data
      });
      const oneByOneRequests = true;

      array.forEach((ans) => {
        if (ans.file && ans.file.size > 10485760) {
          throw new Error(`${t('Size of the')} ${ans.questionName} ${t('file is too big. Max 10MB')}`);
        }
      });

      const alreadyClearedQuestions = {};
      if (oneByOneRequests) {
        const post = async (index) => {
          const answer = array[index];
          if (answer && (answer.answer || answer.availableAnswerId || answer.file)) {
            const { clearAll, ...postAnswer } = answer;

            await kycApi.postSumSubAnswer({
              ...postAnswer,
              clearAll: clearAll && !alreadyClearedQuestions[postAnswer.questionId]
            });
            if (clearAll) alreadyClearedQuestions[postAnswer.questionId] = true;

            await post(index + 1);
          }
        };

        await post(0);
      } else {
        await Promise.all(
          array
            .filter((answer) => answer.answer || answer.availableAnswerId || answer.file)
            .map((answer) => kycApi.postSumSubAnswer(answer))
        );
      }

      //change step after posting
      if (currentSection + 1 < stepsCount) setCurrentSection(currentSection + 1);
      else {
        history.push('/profile', { lastProfileTab: lastProfileTab, verificationSubmitted: true });
        kycApi.startSumSubCheck().then().catch();
      }
      if (!appContainer) appContainer = document.getElementById('app-container');
      appContainer.parentNode.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    } catch (e) {
      const message = e.response?.data?.details || e.response?.data?.message || e.data?.message || e.message || 'Something went wrong';
      if (message) setToast(message);
      console.error(e);
    } finally {
      setLoader(false);
    }
  };

  return (
    <div>
      {!isLoading && questionnaire && (
        <QuestionnaireView
          questionnaire={{ questionSections: questionnaire }}
          currentSection={currentSection}
          onNext={onNext}
          onBack={() => (state && state.prevPath ? history.push(state.prevPath, state.nextState) : history.goBack())}
        />
      )}

      <SyncIndicator visible={loader}>{t('Processing')}...</SyncIndicator>
      <Toast toast={toast} removeToast={() => setToast('')} />
    </div>
  );
};
