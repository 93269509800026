import React, {useEffect} from 'react';

import { Questionnaire } from '../../../../../src/modules/Questionnaire';
import { useParams } from 'react-router-dom';

export const EditPage = () => {

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [])

  const { id, questionnaireId } = useParams();

  return (
      <Questionnaire adminView adminEditView investorId ={id} questionnaireId = {questionnaireId}/>
  );
};
