import React from 'react';
import styled from 'styled-components';
import { SearchInput as SI } from '@aragon/ui';

export const SearchInput = ({ handleSearch, ...props }) => {
  const handleSearchKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  return <StyledSearchInput onBlur={handleSearch} onKeyDown={handleSearchKeyDown} {...props} />;
};

const StyledSearchInput = styled(SI)`
  font-weight: 300;
  ${(p) => (p.minWidth ? `min-width: ${p.minWidth}px;` : '')}
`;
