import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, DocumentLine, FooterButtons, StepsPanel, Tabs } from '../../../components';
import { Col, Container, Row } from '../../../ui/layout';
import { Body1Light, Body2Light, Text, Title1, Title2 } from '../../../ui/typography';
import { Section } from '../components/Section';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { Button, BackButton } from '../../../ui/atoms';
import {
  SHOWED_SECTIONS_COUNT,
  STATUSES_WITH_ANSWERS,
  STATUSES_WITH_DISABLED
} from '../../../utils/static/kyc';
import { kycHelpers } from '../../../utils/helpers/kyc';
import { history } from '../../../routing';
import { useDispatch } from 'react-redux';
import { entitiesActions } from '../../../store/entities';
import { useTranslation } from 'react-i18next';
import { displayFullDate } from '../../../utils/helpers';
import { API_URL } from '../../../utils/static';


export const ProductView = (
  {
    product,
    currentSection,
    currentStep,
    onNext,
    onBack,
    adminView = false,
    adminEditView = false,
    referralView = false,
    rejectProduct,
    acceptProduct,
    rejectedQuestions,
    setCurrentSection,
    setCurrentStep,
    disabled = false,
    stepsCount = 0,
    statusSettings,
    ...props
  }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const [preFilledQuestions, setPreFilledQuestions] = useState({});
  const [creators, setCreators] = useState({});
  const [selectedTab, setSelectedTab] = useState(0);

  const defaultValues = useMemo(() => {
    try {
      if (product && (STATUSES_WITH_ANSWERS[product.status] || adminView)) {
        const { values, lastAnsweredSection, preFilledQuestions, creators } = kycHelpers.collectProductsAnswers(product);
        if (!adminView && (product.status === 'DRAFT' || product.status === 'UNACCEPTED')) {
          setCurrentSection(
            Math.min(product.productSections.length - 1, lastAnsweredSection + 1)
          );
          setCurrentStep(
            Math.min(product.productSections.length - 1, lastAnsweredSection + 1)
          );
        }

        if (Object.keys(preFilledQuestions).length > 0) {
          setPreFilledQuestions(preFilledQuestions);
        }
        if (Object.keys(creators).length > 0) {
          setCreators(creators);
        }

        return values;
      }
    } catch (e) {
      console.error(e);
    }

    return null;
  }, [product, adminView]);

  const {
    control,
    handleSubmit,
    trigger,
    watch,
    formState: { errors, submitCount }
  } = useForm({ defaultValues: { ...defaultValues } });
  const [insertedQuestions, setInsertedQuestions] = useState({});

  useEffect(() => {
    if (Object.keys(errors).length > 0)
      dispatch(entitiesActions.addToast(t('Some fields are not valid')));
  }, [submitCount]);

  const toggleAutoInsertQuestion = useCallback((checked, questionId, autoInsertId) => {
    const clone = { ...insertedQuestions };
    clone[questionId] = checked;
    setInsertedQuestions(clone);
  }, []);

  // useEffect(() => {
  //   reset();
  // }, [currentSection]);
  const isRejected = useMemo(() => product?.status === 'UNACCEPTED', [product]);
  const isQuestionRejected = useMemo(() => product?.status === 'QUESTION_UNACCEPTED', [product]);
  const isInProcess = useMemo(() => product?.status === 'PENDING' || product?.status === 'DRAFT', [product]);
  const isApproved = useMemo(() => product?.status === 'ACCEPTED', [product]);

  const backToProfile = () => {
    state && state.prevPath ? history.push(state.prevPath, state.nextState) : history.goBack();
  };
  const startSlicePos = currentSection * SHOWED_SECTIONS_COUNT;

  return (
    <Row nowrap>
      <StepsPanel
        currentStep={currentStep}
        step={currentSection + 1}
        steps={product.productSections.sort((a,b) => a.newNumber ? a.newNumber - b.newNumber : a.number ? a.number - b.newNumber : 0).map((item) => item.section)}
        width={320}
        setStep={(referralView || adminView || adminEditView || isRejected || isQuestionRejected || isInProcess) ? setCurrentSection : null}
        isAdmin={adminView}
        isReferralView={referralView}
        isInProcess={isInProcess}
      />

      <Col>
        <MainContainer paddingY={64} paddingRight={23} paddingBottom={'0'} paddingLeft={15}>
          <div>
            <Row
              margin={-1.5 * 8}
              justifyContent={'flex-end'}
              marginTop={-48}
              marginBottom={24}
              alignCenter
            >
              <Col>
                <BackButton onClick={backToProfile} label={t('Back to profile')} />
              </Col>
              {adminView && rejectProduct && statusSettings && (
                <Col auto>
                  <Button
                    label={statusSettings.isRejected ? t('Rejected') : t('Reject')}
                    onClick={rejectProduct}
                    disabled={!statusSettings.allowReject}
                    paddingX={'26'}
                  />
                </Col>
              )}
              {adminView && acceptProduct && statusSettings && (
                <Col auto>
                  <Button
                    label={statusSettings.isApproved ? t('Approved') : t('Approve')}
                    onClick={acceptProduct}
                    disabled={!statusSettings.allowApprove}
                    mode={'strong'}
                    paddingX={'18'}
                  />
                </Col>
              )}
            </Row>

            {!disabled && (
              <Col auto>
                {product.logoURL && <ImgWrapper>
                  <Img src={`${API_URL}/${product.logoURL}`} alt="" />
                  <ImgLayover />
                  <ImgTitle>
                    <Title1 textAlign={'center'} style={{fontWeight: 700}}>
                      {product.name}
                    </Title1>
                  </ImgTitle>
                </ImgWrapper>}
                {!product.logoURL &&
                  <Title2 textAlign={'center'} marginBottom={24}>
                    {product.name}
                  </Title2>
                }

                <Tabs
                  items={[t('Product info'), t('Downloads')]}
                  selected={selectedTab}
                  onChange={setSelectedTab}
                />

                {selectedTab === 0 && <Container paddingBottom={15}>
                  <Body2Light marginTop={10}>
                    {t('Company')}:{' '}
                    <Text textInline fontWeight={400}>
                      {product.company ?? '-'}
                    </Text>
                  </Body2Light>
                  <Body2Light marginTop={10}>
                    {t('Interest paid')}:{' '}
                    <Text textInline fontWeight={400}>
                      {product.interestPaid ?? '-'}
                    </Text>
                  </Body2Light>
                  <Body2Light marginTop={10}>
                    {t('Minimal amount')}:{' '}
                    <Text textInline fontWeight={400}>
                      {product.minimalAmount ?? '-'}
                    </Text>
                  </Body2Light>
                  <Body2Light marginTop={10}>
                    {t('Term')}:{' '}
                    <Text textInline fontWeight={400}>
                      {product.term ?? '-'}
                    </Text>
                  </Body2Light>
                  <Body2Light marginTop={10}>
                    {t('ISIN')}:{' '}
                    <Text textInline fontWeight={400}>
                      {product.isin ?? '-'}
                    </Text>
                  </Body2Light>
                  <Body2Light marginTop={10}>
                    {t('Investment type')}:{' '}
                    <Text textInline fontWeight={400}>
                      {product.typeProduct ?? '-'}
                    </Text>
                  </Body2Light>
                  <Body2Light marginTop={10}>
                    {t('Interest rate')}:{' '}
                    <Text textInline fontWeight={400}>
                      {product.interestRate ?? '-'}
                    </Text>
                  </Body2Light>
                  <Body2Light marginTop={10}>
                    {t('Redemption')}:{' '}
                    <Text textInline fontWeight={400}>
                      {product.redemption ?? '-'}
                    </Text>
                  </Body2Light>
                  <Body2Light marginTop={10}>
                    {t('Offer period until')}:{' '}
                    <Text textInline fontWeight={400}>
                      {product.offerPeriodUntil ?? '-'}
                    </Text>
                  </Body2Light>
                  <Body2Light marginTop={10}>
                    {t('Processing fee')}:{' '}
                    <Text textInline fontWeight={400}>
                      {product.processingFee ?? '-'}
                    </Text>
                  </Body2Light>
                  <Body2Light marginTop={10}>
                    {t('Offer and conditions')}:{' '}
                    <Text textInline fontWeight={400}>
                      {product.offerAndConditions ?? '-'}
                    </Text>
                  </Body2Light>
                  <Body2Light marginTop={10} marginBottom={25}>
                    {t('Link to central bank of Latvia')}:{' '}
                    <Text textInline fontWeight={400}>
                      <a target='_blank' href={product.linkToCentralBankOfLatvia}>{product.linkToCentralBankOfLatvia ?? '-'}</a>
                    </Text>
                  </Body2Light>
                </Container>}

                {selectedTab === 1 && <>
                  {product.productFileToDownloads && product.productFileToDownloads.length > 0 ?
                    <Container paddingBottom={15}>
                      {product.productFileToDownloads.map((file) =>
                        <DocumentLine
                          doc={{
                            name: file.name ?? "Untitled",
                            documentURL: `${API_URL}/${file.fileURL}`,
                            id: file.id
                          }}
                          hideLastBorder
                          key={file.id}
                        />
                      )}
                    </Container> :
                    <Body2Light style={{marginTop: '25px'}}>{t('No files to download.')}</Body2Light>
                  }
                </>}
              </Col>
            )}

            {disabled && <Title1 textAlign={'center'}>{t('Access denied')}</Title1>}

            {!disabled &&
              product.productSections &&
              product.productSections
            .slice(startSlicePos, startSlicePos + SHOWED_SECTIONS_COUNT)
            .map((sectionObj) => (
              <Box key={sectionObj.id}>
                <Body1Light>{sectionObj.section}</Body1Light>
                <Section
                  productId={product?.id}
                  productStatus={product?.status}
                  productMinimalAmount={product?.minimalAmount}
                  section={sectionObj}
                  control={control}
                  watch={watch}
                  submitCount={submitCount}
                  trigger={trigger}
                  adminView={adminView}
                  referralView={referralView}
                  disableAll={adminView || STATUSES_WITH_DISABLED[product?.status]}
                  rejectedQuestions={rejectedQuestions}
                  insertedQuestions={insertedQuestions}
                  toggleAutoInsertQuestion={toggleAutoInsertQuestion}
                  preFilledQuestions={preFilledQuestions}
                  creators={creators}
                  {...props}
                />
              </Box>
            ))}
          </div>

          {!disabled && product.productSections && (
            <StickyBox paddingY={32} paddingX={40}>
              <FooterButtons
                onNext={adminView ? onNext : () => {handleSubmit(onNext)(insertedQuestions); }}
                onBack={onBack}
                hideBack={!adminView || currentSection === 0}
                hideNext={adminView && stepsCount && currentSection + 1 >= stepsCount}
                noMargin
              />
            </StickyBox>
          )}
        </MainContainer>
      </Col>
    </Row>
  );
};

const MainContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  min-height: 100dvh;
`;
const StickyBox = styled(Container)`
  background: #ffffff;
  box-shadow: 0px -1px 3px rgba(0, 0, 0, 0.15);
  position: sticky;
  bottom: 0;
  margin: 40px -23px 0 -15px;
  z-index: 10;
`;
const ImgWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 320px;
  border-radius: 20px;
    margin-bottom: 24px;
  overflow: hidden;
`;
const ImgLayover = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(20deg, rgba(255,255,255,0.9002451322325805) 0%, rgba(255,255,255,0.75) 25%, rgba(255,255,255,0.2027661406359419) 50%, rgba(255,255,255,0) 100%);
`;
const ImgTitle = styled.div`
    position: absolute;
    bottom: 20px;
    left: 20px;
`;
const Img = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;