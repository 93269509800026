import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { ordersActions } from '../../../../store/orders';
import { OrderDetails } from '../../../../pages/investor';

export const OrderDetailsContainer = () => {
  const dispatch = useDispatch();
  let { id } = useParams();
  const { currentOrder, orders } = useSelector((state) => state.orders);

  useEffect(() => {
    dispatch(ordersActions.getOrder(id, orders?.orders));
  }, [orders]);

  const cancelOrder = () => {
    dispatch(ordersActions.cancelOrder(currentOrder.id));
  };

  return <OrderDetails order={currentOrder} cancelOrder={cancelOrder} />;
};
