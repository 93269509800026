import { Link as LinkInit } from 'react-router-dom';
import { Link as LinkButtonInit } from '@aragon/ui';
import { COLORS } from '../../../utils/static';
import styled from 'styled-components';

export const Link = styled(LinkInit)`
  color: ${(p) => (p.color ? p.color : p.defaultColor ? COLORS.blue : COLORS.aragonBlue)};
  text-decoration: ${(p) => (p.external ? 'underline' : 'none')};
`;
export const ExternalLink = styled(LinkButtonInit)`
  color: ${(p) => (p.color ? p.color : p.defaultColor ? COLORS.blue : COLORS.aragonBlue)};
  text-decoration: ${(p) => (p.external ? 'underline' : 'none')};
`;
export const LinkButton = styled(LinkButtonInit)`
  color: ${(p) => (p.color ? p.color : COLORS.aragonBlue)};
  font-weight: inherit;
`;
