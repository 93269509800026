import React from 'react';
import { useTranslation } from 'react-i18next';
import { Collapse } from 'antd';

import { Title4 } from '../../../ui';
import { Question } from './Question';
import {StyledPanel} from '../styled';

export const QuestionList = ({list}) => {
  const { t } = useTranslation();

  return (
    <>
      <Title4>{t('Questions')}</Title4>

      <Collapse accordion>
        {list?.map(question => (
          <StyledPanel header={question.question || question.id} key={question.id}>
            <Question questionProp={question}/>
          </StyledPanel>
        ))}
      </Collapse>
    </>
  );
};
