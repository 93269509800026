import React from 'react';
import styled from 'styled-components';
import icon from '../../../assets/icons/trash.svg';

export const TrashIcon = ({ style, onClick = () => {}, disabled = false }) => {
  return (
    <Button style={style} onClick={disabled ? null : onClick} disabled={disabled}>
      <img src={icon} alt={''} />
    </Button>
  );
};

const Button = styled.div`
  width: 32px;
  height: 32px;
  padding: 3px;
  background: #ffffff;
  mix-blend-mode: normal;
  border: 1px solid #9da0a3;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  cursor: pointer;
  ${p => p.disabled ? `
    opacity: 0.8;
    cursor: default;
  ` : ''}
`;
