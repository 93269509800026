import React, { useMemo, useState } from 'react';
import { Header, Text, GU } from '@aragon/ui';
import { BackBar, Box, BoxLine, TermsCheckbox, Info, ProfileViewer } from '../../../components';
import { PageContainer, Row, Col, Button, Link, Title2, Body2Light, Body1Light } from '../../../ui';
import {
  COLORS,
  SIDE_CONFIG,
  ASSET_TYPES,
  VALID_TILL_DATE,
  BUY_ASSET_FEE_PERCENT,
  BROKER_PROFILE
} from '../../../utils/static';
import { dateString } from '../../../utils/helpers';
import styled from 'styled-components';

const now = new Date();

export const Checkout = ({ checkoutInfo = null, confirmOrder }) => {
  const [termsCheck, setTermsCheck] = useState(false);

  const buttonActive = useMemo(() => termsCheck, [termsCheck]);

  if (checkoutInfo) {
    const {
      side,
      assetId,
      orderValidTillOption,
      orderValidTill,
      quantity,
      pricePerCapita,
      currency,
      assetType,
      totalPrice,
      fee
    } = checkoutInfo;

    return (
      <div>
        <PageContainer>
          <Header primary={<Title2>Checkout</Title2>} />
          <BackBar />

          <Row cols={2} margin={-GU} marginBottom={2 * GU} marginTop={3 * GU}>
            <Col>
              <Box heading={'Order information'}>
                <BoxLine title={'Side'}>
                  <Text color={COLORS.aragonBlue}>{SIDE_CONFIG[side].title}</Text>
                </BoxLine>
                <BoxLine title={'Order type'} data={'Trade'} />
                <BoxLine title={'Placement date'} data={dateString(now)} />
                <BoxLine
                  title={'Valid till'}
                  data={VALID_TILL_DATE[orderValidTillOption] || dateString(orderValidTill)}
                />
              </Box>

              <Box heading={'Asset information'}>
                <BoxLine title={'Asset ID'}>
                  <Link external defaultColor to={`/assets/${assetId}`}>
                    {assetId}
                  </Link>
                </BoxLine>
                <BoxLine title={'Asset type'}>
                  <Text color={COLORS.aragonBlue}>{ASSET_TYPES[assetType]}</Text>
                </BoxLine>

                <BoxLine title={'Quantity'} data={`${quantity} units`} />
                <BoxLine title={'Price per capita'} data={`${pricePerCapita} ${currency}`} />
              </Box>
            </Col>

            <Col>
              <Box heading={'Broker'} paddingY={2 * GU}>
                <ProfileViewer {...BROKER_PROFILE} />
              </Box>
            </Col>
          </Row>

          <TermsCheckbox checked={termsCheck} setChecked={setTermsCheck} marginBottom={2 * GU} />

          <Info>
            By placing this order, You as the Client confirm that the provision of the services by
            Macte Invest FM AB as the Broker is carried out upon Client’s request without any
            deliberate influence of such Client’s decision by the Broker. Broker is not obliged to
            evaluate the compliance of this order with the Client’s risk profile and has not done
            so, the Client undertakes all the risks associated with and resulting from this trade.
            The Client has a right to ask professionals for an advice before making any decision.
            The Client is informed and fully aware about the possible risks of investing including
            the cases when the losses may exceed the deposits and the Client may lose more than was
            initially invested. Read more
          </Info>
        </PageContainer>

        <FixedContainer>
          <Body2Light color={COLORS.greyMedium}>Account</Body2Light>

          <Row justifyContent={'space-between'} alignItems={'flex-end'}>
            <Col auto>
              <Row margin={-10} marginTop={3 * GU}>
                <Col>
                  <Body2Light color={COLORS.greyMedium}>Total amount:</Body2Light>
                  <Body2Light color={COLORS.greyMedium} marginTop={GU}>
                    Fee:
                  </Body2Light>
                </Col>

                <Col>
                  <Body1Light>
                    {totalPrice + fee} {currency}
                  </Body1Light>
                  <Body2Light marginTop={GU}>
                    {BUY_ASSET_FEE_PERCENT}% &nbsp;
                    <Text color={COLORS.greyMedium}>
                      {fee} {currency}
                    </Text>
                  </Body2Light>
                </Col>
              </Row>
            </Col>

            <Col auto paddingBottom={'5'}>
              <Button
                onClick={confirmOrder}
                label={'Confirm order'}
                mode={'strong'}
                width={'192'}
                disabled={!buttonActive}
              />
            </Col>
          </Row>
        </FixedContainer>
      </div>
    );
  }

  return null;
};

const FixedContainer = styled.div`
  position: sticky;
  bottom: 0;
  margin: 0 -24px 0 -8px;
  background: #ffffff;
  box-shadow: 0px -1px 3px rgba(0, 0, 0, 0.15);
  padding: 24px 24px 24px 17px;
`;
