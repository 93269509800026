import React from 'react';
import { useTranslation } from 'react-i18next';

import { PageContainer } from '../../../ui';
import { Title1 } from '../../../ui';
import {
  QuestionnaireList,
  Questionnaire
} from '../../../features/constructor';

export const Constructor = () => {
  const { t } = useTranslation();

  return (
    <PageContainer>
      <Title1 marginBottom={20}>
        {t('Constructor')}
      </Title1>

      <QuestionnaireList />
      <Questionnaire/>
    </PageContainer>
  );
};
