import { useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { adminLocalizationApi, adminLocalizationKeys } from '../../../api';
import { ConstructorApi } from '../api';
import { modifyAnswers } from '../utils';
import { queryClient } from '../../../app/QueryClientProvider';
import { MessageContext } from '../../../app/MessageProvider';

export const useGroup = ({questionnaireId}) => {
  const messageApi = useContext(MessageContext);
  const { t } = useTranslation();
  const [isUpdating, setIsUpdating] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isCreating, setIsCreating] = useState(false);

  const handleCreate = useCallback(async ({group, sectionId, dAnswersProp}) => {
    try {
      setIsCreating(true);
      const visibleConditions = modifyAnswers(dAnswersProp);
      await ConstructorApi.createGroup({...group, visibleConditions}, sectionId);
      await queryClient.refetchQueries({ queryKey: [adminLocalizationKeys.getQuestionnaireSections], queryFn: () => adminLocalizationApi.getQuestionnaireSections({ questionSectionTypeId: questionnaireId }) });
      messageApi.success(t('Created'));
    } catch (e) {
      messageApi.error(e?.message || 'Something went wrong');
    } finally {
      setIsCreating(false);
    }
  }, []);

  const handleUpdate = useCallback(async ({group, dAnswersProp}) => {
    try {
      setIsUpdating(true);
      const visibleConditions = modifyAnswers(dAnswersProp);
      await ConstructorApi.updateGroup({...group, visibleConditions});
      await queryClient.refetchQueries({ queryKey: [adminLocalizationKeys.getQuestionnaireSections], queryFn: () => adminLocalizationApi.getQuestionnaireSections({ questionSectionTypeId: questionnaireId }) });
      messageApi.success(t('Saved'));
    } catch (e) {
      messageApi.error(e?.message || 'Something went wrong');
    } finally {
      setIsUpdating(false);
    }
  }, []);

  const handleDelete = useCallback(async (group) => {
    try {
      setIsDeleting(true);
      await ConstructorApi.deleteGroup(group);
      await queryClient.refetchQueries({ queryKey: [adminLocalizationKeys.getQuestionnaireSections], queryFn: () => adminLocalizationApi.getQuestionnaireSections({ questionSectionTypeId: questionnaireId }) });
      messageApi.success(t('Deleted'));
    } catch (e) {
      messageApi.error(e?.message || 'Something went wrong');
    } finally {
      setIsDeleting(false);
    }
  }, []);

  return {
    handleCreate,
    handleUpdate,
    handleDelete,
    isCreating,
    isUpdating,
    isDeleting
  }
}
