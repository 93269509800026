import React from 'react';
import { CreateIssuerView } from '../View';
import { formatAddress } from '../../../../../utils/helpers/common';
import { history } from '../../../../../routing';
import { entitiesActions } from '../../../../../store/entities';

export const CreateIssuer = () => {
  const handleCreateIssuer = (data) => {
    const issuerData = {
      ...data.companyInfo,
      ...data.companyReg,
      ...data.representativeInfo,
      ...formatAddress('companyLegal', data.companyLegalAddress),
      ...formatAddress('companyCorrespondence', data.companyActualAddress),
      ...formatAddress('legal', data.legalAddress),
      ...formatAddress('actual', data.actualAddress)
    };

    entitiesActions
      .createIssuer({ issuerData: issuerData, issuerIdDoc: data.idDocument })
      .then(() => {
        history.push('/issues');
      });
  };

  return <CreateIssuerView handleCreateIssuer={handleCreateIssuer} />;
};
