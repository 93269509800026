import React from 'react';
import styled from 'styled-components';

export const DocumentUpload = () => {
  const documentRef = React.useRef();
  const [file, setFile] = React.useState('');

  const handleDocument = (file) => {
    setFile(file);
  };

  const deleteAuthDoc = () => {
    setFile(null);

    try {
      documentRef.current.value = '';
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div>
      {file && (
        <DocumentBlockStyles>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <svg
              width='28'
              height='28'
              viewBox='0 0 29 29'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M14.3962 9.61774C14.6765 9.33748 14.6764 8.88306 14.396 8.60294C14.1158 8.32303 13.6618 8.32312 13.3817 8.60314L8.81376 13.1702C8.61389 13.3702 8.45536 13.6075 8.34721 13.8687C8.23907 14.1299 8.18343 14.4099 8.18348 14.6926C8.18352 14.9753 8.23925 15.2552 8.34748 15.5164C8.45571 15.7775 8.61432 16.0148 8.81426 16.2147C9.0142 16.4146 9.25155 16.5731 9.51275 16.6813C9.77396 16.7894 10.0539 16.845 10.3366 16.845C10.6193 16.845 10.8993 16.7892 11.1604 16.681C11.4216 16.5728 11.6589 16.4142 11.8588 16.2142L17.9488 10.1252C18.6216 9.45223 18.9996 8.53951 18.9995 7.58786C18.9994 6.63621 18.6212 5.72357 17.9483 5.05071C17.2753 4.37786 16.3626 3.99991 15.4109 4C14.4593 4.00009 13.5466 4.37823 12.8738 5.05121L6.47976 11.4442L6.46576 11.4572C4.51076 13.4122 4.51076 16.5802 6.46576 18.5342C8.42076 20.4882 11.5888 20.4882 13.5438 18.5342L13.5568 18.5202L13.5578 18.5212L17.4151 14.6647C17.6954 14.3845 17.6953 13.93 17.4149 13.6499C17.1348 13.37 16.6808 13.3701 16.4007 13.65L12.5298 17.5192C11.1378 18.9112 8.87376 18.9112 7.48176 17.5192C7.14972 17.1864 6.88663 16.7914 6.70758 16.3568C6.52852 15.9221 6.43702 15.4564 6.43832 14.9863C6.43962 14.5162 6.5337 14.051 6.71516 13.6174C6.89661 13.1837 7.16189 12.7901 7.49576 12.4592L7.49476 12.4582L13.8898 6.06521C14.7288 5.22521 16.0948 5.22521 16.9348 6.06521C17.7748 6.90521 17.7738 8.27021 16.9348 9.10921L10.8448 15.1982C10.7085 15.3236 10.529 15.3914 10.3439 15.3876C10.1587 15.3838 9.98224 15.3086 9.85123 15.1777C9.72023 15.0468 9.64485 14.8704 9.64085 14.6853C9.63685 14.5001 9.70453 14.3206 9.82976 14.1842L14.3962 9.61774Z'
                fill='#8FA4B5'
              />
            </svg>
            {file.name}
          </div>
          <div onClick={deleteAuthDoc} style={{ cursor: 'pointer' }}>
            <svg
              width='40'
              height='40'
              viewBox='0 0 40 40'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <g filter='url(#filter0_d)'>
                <path
                  fillRule='evenodd'
                  clipRule='evenodd'
                  d='M4 7C4 4.79086 5.79086 3 8 3L32 3C34.2091 3 36 4.79086 36 7V31C36 33.2091 34.2091 35 32 35H8C5.79086 35 4 33.2091 4 31V7Z'
                  fill='white'
                />
                <path
                  fillRule='evenodd'
                  clipRule='evenodd'
                  d='M4 7C4 4.79086 5.79086 3 8 3L32 3C34.2091 3 36 4.79086 36 7V31C36 33.2091 34.2091 35 32 35H8C5.79086 35 4 33.2091 4 31V7Z'
                  stroke='#DDE4E9'
                />
              </g>
              <path
                d='M26.618 13.941H13.382C13.2105 13.941 13.0461 14.0092 12.9248 14.1304C12.8036 14.2516 12.7355 14.4161 12.7355 14.5875C12.7355 14.759 12.8036 14.9234 12.9248 15.0447C13.0461 15.1659 13.2105 15.234 13.382 15.234H26.618C26.7894 15.234 26.9539 15.1659 27.0751 15.0447C27.1964 14.9234 27.2645 14.759 27.2645 14.5875C27.2645 14.4161 27.1964 14.2516 27.0751 14.1304C26.9539 14.0092 26.7894 13.941 26.618 13.941Z'
                fill='#8FA4B5'
              />
              <path
                d='M25.1481 13.941C25.0631 13.9409 24.9789 13.9576 24.9003 13.99C24.8217 14.0225 24.7504 14.0701 24.6903 14.1302C24.6302 14.1904 24.5825 14.2617 24.55 14.3403C24.5176 14.4188 24.5009 14.503 24.5011 14.588V24.883C24.5011 25.337 24.1311 25.707 23.6771 25.707H16.3231C16.1046 25.7068 15.8952 25.6199 15.7407 25.4654C15.5862 25.3109 15.4993 25.1015 15.4991 24.883V14.588C15.4991 14.4166 15.4309 14.2521 15.3097 14.1309C15.1885 14.0097 15.024 13.9415 14.8526 13.9415C14.6811 13.9415 14.5167 14.0097 14.3954 14.1309C14.2742 14.2521 14.2061 14.4166 14.2061 14.588V24.883C14.2068 25.4443 14.4301 25.9823 14.827 26.3791C15.2238 26.776 15.7618 26.9992 16.3231 27H23.6771C24.2383 26.9992 24.7763 26.776 25.1731 26.3791C25.57 25.9823 25.7933 25.4443 25.7941 24.883V14.588C25.7942 14.5031 25.7776 14.419 25.7452 14.3405C25.7128 14.262 25.6652 14.1907 25.6052 14.1306C25.5452 14.0705 25.4739 14.0228 25.3955 13.9903C25.3171 13.9578 25.233 13.941 25.1481 13.941Z'
                fill='#8FA4B5'
              />
              <path
                d='M21.47 11H18.53C17.9686 11.0005 17.4303 11.2237 17.0333 11.6206C16.6362 12.0174 16.4128 12.5556 16.412 13.117V14.587C16.412 14.7585 16.4801 14.9229 16.6013 15.0441C16.7226 15.1654 16.887 15.2335 17.0585 15.2335C17.23 15.2335 17.3944 15.1654 17.5156 15.0441C17.6369 14.9229 17.705 14.7585 17.705 14.587V13.117C17.705 12.662 18.075 12.293 18.529 12.293H21.471C21.925 12.293 22.295 12.663 22.295 13.117V14.587C22.295 14.7585 22.3631 14.9229 22.4843 15.0441C22.6056 15.1654 22.77 15.2335 22.9415 15.2335C23.1129 15.2335 23.2774 15.1654 23.3986 15.0441C23.5199 14.9229 23.588 14.7585 23.588 14.587V13.117C23.5872 12.5556 23.3638 12.0174 22.9667 11.6206C22.5696 11.2237 22.0314 11.0005 21.47 11ZM18.53 17.618C18.445 17.6179 18.3608 17.6345 18.2822 17.667C18.2037 17.6995 18.1323 17.7471 18.0722 17.8072C18.0121 17.8673 17.9644 17.9387 17.932 18.0172C17.8995 18.0958 17.8829 18.18 17.883 18.265V22.677C17.883 22.8485 17.9511 23.0129 18.0723 23.1341C18.1936 23.2554 18.358 23.3235 18.5295 23.3235C18.701 23.3235 18.8654 23.2554 18.9866 23.1341C19.1079 23.0129 19.176 22.8485 19.176 22.677V18.265C19.1761 18.18 19.1595 18.0958 19.127 18.0172C19.0945 17.9387 19.0469 17.8673 18.9868 17.8072C18.9267 17.7471 18.8553 17.6995 18.7767 17.667C18.6982 17.6345 18.614 17.6179 18.529 17.618H18.53ZM21.47 17.618C21.3851 17.618 21.301 17.6347 21.2225 17.6673C21.1441 17.6998 21.0728 17.7475 21.0128 17.8076C20.9528 17.8677 20.9053 17.939 20.8729 18.0175C20.8405 18.096 20.8239 18.1801 20.824 18.265V22.677C20.824 22.8485 20.8921 23.0129 21.0133 23.1341C21.1346 23.2554 21.299 23.3235 21.4705 23.3235C21.642 23.3235 21.8064 23.2554 21.9276 23.1341C22.0489 23.0129 22.117 22.8485 22.117 22.677V18.265C22.1171 18.1801 22.1005 18.096 22.0681 18.0175C22.0357 17.939 21.9881 17.8677 21.9281 17.8076C21.8681 17.7475 21.7969 17.6998 21.7184 17.6673C21.64 17.6347 21.5559 17.618 21.471 17.618H21.47Z'
                fill='#8FA4B5'
              />
              <defs>
                <filter
                  id='filter0_d'
                  x='0.5'
                  y='0.5'
                  width='39'
                  height='39'
                  filterUnits='userSpaceOnUse'
                  colorInterpolationFilters='sRGB'
                >
                  <feFlood floodOpacity='0' result='BackgroundImageFix' />
                  <feColorMatrix
                    in='SourceAlpha'
                    type='matrix'
                    values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
                  />
                  <feOffset dy='1' />
                  <feGaussianBlur stdDeviation='1.5' />
                  <feColorMatrix
                    type='matrix'
                    values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0'
                  />
                  <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow' />
                  <feBlend
                    mode='normal'
                    in='SourceGraphic'
                    in2='effect1_dropShadow'
                    result='shape'
                  />
                </filter>
              </defs>
            </svg>
          </div>
        </DocumentBlockStyles>
      )}
      {!file && (
        <BeautifulLabel htmlFor='fileInput1'>
          <div style={{ marginRight: '0.8rem', display: 'flex', alignItems: 'center' }}>
            <svg
              width='16'
              height='16'
              viewBox='0 0 16 16'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M8.00014 3.33337C7.90006 3.33337 7.80408 3.37313 7.73332 3.44389C7.66256 3.51466 7.6228 3.61063 7.6228 3.71071V12.29C7.6228 12.3901 7.66256 12.4861 7.73332 12.5569C7.80408 12.6276 7.90006 12.6674 8.00014 12.6674C8.10021 12.6674 8.19619 12.6276 8.26695 12.5569C8.33772 12.4861 8.37747 12.3901 8.37747 12.29V3.71004C8.37729 3.61008 8.33746 3.51428 8.26672 3.44366C8.19597 3.37304 8.1001 3.33337 8.00014 3.33337Z'
                fill='#8FA4B5'
                stroke='#8FA4B5'
                strokeWidth='0.3'
              />
              <path
                d='M12.2893 7.62268H3.71068C3.6106 7.62268 3.51463 7.66244 3.44386 7.7332C3.3731 7.80396 3.33334 7.89994 3.33334 8.00001C3.33334 8.10009 3.3731 8.19607 3.44386 8.26683C3.51463 8.33759 3.6106 8.37735 3.71068 8.37735H12.29C12.3901 8.37735 12.4861 8.33759 12.5568 8.26683C12.6276 8.19607 12.6673 8.10009 12.6673 8.00001C12.6673 7.89994 12.6276 7.80396 12.5568 7.7332C12.4861 7.66244 12.3901 7.62268 12.29 7.62268H12.2893Z'
                fill='#8FA4B5'
                stroke='#8FA4B5'
                strokeWidth='0.3'
              />
            </svg>
          </div>
          Choose File
          <span style={{ color: '#08BEE5' }}>*</span>
        </BeautifulLabel>
      )}
      <input
        type='file'
        style={{ display: 'none' }}
        id='fileInput1'
        onChange={(e) => handleDocument(e.target.files[0])}
      />
    </div>
  );
};

const DocumentBlockStyles = styled.div`
  padding: 0.5rem 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: #08bee5;
  font-size: 15px;
`;

const BeautifulLabel = styled.label`
  margin: 1rem 0rem;
  padding: 0.4rem 0.5rem;
  width: 9rem;
  display: flex;
  align-items: center;
  border: 1px solid #9da0a3;
  border-radius: 4px;
  font-weight: 300;
  font-size: 16px;
  line-height: 22px;
  background-color: #ffffff;
  mix-blend-mode: normal;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15);
  cursor: pointer;
`;
