import {withTranslation} from "react-i18next";
import {Container} from "../../../../ui";
import {ProfileViewerSkeleton} from "../ProfileViewer";
import { Tree, Typography } from 'antd';
import React, { useMemo } from 'react';
import { UserOutlined } from '@ant-design/icons';
import { history } from '../../../../routing';
import { investorActions } from '../../../../store/investor';
import { useDispatch } from 'react-redux';

const roleLabels = {
  AGENT: 'Agent',
  RECOMMENDER: 'Assistant',
  PERSONAL: 'Investor',
}

export const AccountsTree = withTranslation()(({
                                                 t, accounts, emptyTitle = t('Nothing to display'), adminView
}) => {
    const dispatch = useDispatch();
    const { error, list, isLoading } = accounts;

    function getTree(list, modifiedList, level, currentIndex) {
      if (!list) return;

      list.forEach((item, index) => {
        if (item) modifiedList.push({
          title: `{"name": "${item.firstName} ${item.lastName} (${t(roleLabels[item.kycRole])})", "id": "${item.id}"}`,
          key: level === 0 ? `0-${index}` : `${currentIndex}-${index}`,
          icon: <UserOutlined />,
        })
        if (item.subAgents) {
          modifiedList[index] = {...modifiedList[index], children: []};
          getTree(item.subAgents, modifiedList[index].children, level + 1, modifiedList[index].key);
        }
        if (item.subRecommenders) {
          modifiedList[index] = {...modifiedList[index], children: []};
          getTree(item.subRecommenders, modifiedList[index].children, level + 1, modifiedList[index].key);
        }
      });

      return modifiedList;
    }

    const treeList = useMemo(() => {
      if(list) return getTree(list, [], 0, 0);
    }, [list, t]);

    const onSelect = async (keys, info) => {
      const parsedTitle = JSON.parse(info.node.title);
      if (adminView) {
        dispatch(investorActions.setLastProfileTab(0));
        history.push(`/investor/${parsedTitle.id}`, { lastProfileTab: 0 })
      } else {
        history.push(`/referral/${parsedTitle.id}`);
      }
    };

    if (isLoading) return (<Container marginTop={16}>
      {[1, 2, 3].map((item => (
        <Container marginBottom={12} key={item}>
          <ProfileViewerSkeleton />
        </Container>
      )))}
    </Container>);

    if (error) return <Typography.Text type={'danger'}>{t('An error occurred')}</Typography.Text>;
    if (!list || list.length < 1) {
      return <Typography.Text>{emptyTitle}</Typography.Text>;
    }

    return (
      <Container marginTop={16}>
        <Tree
          showLine
          showIcon
          onSelect={onSelect}
          treeData={treeList}
          titleRender={(item) => {
            const parsedTitle = JSON.parse(item.title);
            return <span>{parsedTitle.name ?? ''}</span>;
          }}
        />
      </Container>
    );
    }
);