import React from 'react';
import { GU } from '@aragon/ui';
import { AttachedFile } from '../../../../components';
import { Row, Col, Body2Light, Body3Light, TrashIcon } from '../../../../ui';
import styled from 'styled-components';
import { COLORS } from '../../../../utils/static';

export const DocumentLine = ({
  doc,
  validTill,
  paddingRight,
  onDelete = null,
  index,
  hideLastBorder
}) => {
  return (
    <MainRow alignItems={'center'} margin={-1.25 * GU} hideLastBorder={hideLastBorder}>
      {index && <Body2Light color={COLORS.iconColor}>{index}.</Body2Light>}

      <Col>
        <DocRow justifyContent={'space-between'} paddingRight={paddingRight}>
          <Row alignItems={'center'}>
            <AttachedFile title={doc.name} url={doc.documentURL} />
            <Body3Light marginLeft={2 * GU}>{validTill}</Body3Light>
          </Row>

          {onDelete && <TrashIcon onClick={() => onDelete(doc.id)} />}
        </DocRow>
      </Col>
    </MainRow>
  );
};

const DocRow = styled(Row)`
  min-height: ${7 * GU}px;
  padding: ${1.5 * GU}px 0 ${1.5 * GU}px 0;
  ${(p) => (p.paddingRight ? `padding-right: ${p.paddingRight}px;` : '')}
`;

const MainRow = styled(Row)`
  ${DocRow} {
    border-bottom: 1px solid ${COLORS.greyBasic};
  }

  ${(p) =>
    p.hideLastBorder
      ? `
    :last-child {
      ${DocRow} {
        border-bottom: none;
      }
    }
  `
      : ''}
`;
