import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { walletActions } from '../../../../store/wallet';
import { TransactionDetails } from '../../../../pages/investor';

export const TransactionDetailsContainer = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { currentTransaction, transactions } = useSelector((state) => state.wallet);

  useEffect(() => {
    dispatch(walletActions.getTransaction(id, transactions?.transactions));
  }, [id]);

  return <TransactionDetails transaction={currentTransaction} />;
};
