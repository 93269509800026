import React from 'react';
import { SyncIndicator } from '@aragon/ui';
import { ProfileDocuments as RenderProfileDocuments } from '../../../../components';
import { useQuery } from 'react-query';
import { kycApi } from '../../../../api/kyc';
import { useTranslation } from 'react-i18next';

export const ProfileDocuments = () => {
  const { t } = useTranslation();
  const {
    data: requestedDocs,
    refetch,
    isLoading: isLoading1
  } = useQuery(['getRequestedDocuments'], kycApi.getRequestedDocuments, { refetchInterval: 5000 });
  const { data: questsDocs, isLoading: isLoading2 } = useQuery(
    ['getQuestsDocuments'],
    kycApi.getQuestsDocuments,
    { refetchInterval: 5000 }
  );

  return (
    <div>
      {!isLoading1 && !isLoading2 && (
        <RenderProfileDocuments
          documents={requestedDocs?.data}
          questsDocs={questsDocs?.data}
          refetchDocs={refetch}
        />
      )}

      <SyncIndicator visible={isLoading1 || isLoading2}>{t('Loading')}...</SyncIndicator>
    </div>
  );
};
