import React, { useMemo, useState } from 'react';
import { investorApi } from '../../../../../api/investor';
import {
  RegContainer,
  StepsPanel,
  FooterButtons,
  ImageUpload,
  Info,
  UploadDocumentsBox
} from '../../../../../components';
import styled from 'styled-components';
import { Container, Label2, Title2, Row, Col } from '../../../../../ui';
import { GU } from '@aragon/ui';
import { individualSteps } from '../../../../../utils/static';
import { newObject } from '../../../../../utils/helpers';
import { userActions } from '../../../../../store/user';
import { investorActions } from '../../../../../store/investor';
import { useDispatch } from 'react-redux';
import { entitiesActions } from '../../../../../store/entities';

export const ImportKYCFiles = ({ stepNumber, goBack, goNext }) => {
  const dispatch = useDispatch();
  const [files, setFiles] = useState({
    POF: null,
    MIFID: null,
    KYC: null
  });

  const handleChangeFiles = (key, value) => {
    setFiles(newObject(files, key, value));
  };

  const handleNext = async () => {
    dispatch(entitiesActions.showLoader('Processing...'));

    let MIFIDRes = null;
    let KYCRes = null;

    const POFRes = await investorApi.addProofOfFunds(files.POF);

    if (POFRes.status === 200) {
      MIFIDRes = await investorApi.addMIFID(files.MIFID);
    }

    if (MIFIDRes?.status === 200) {
      KYCRes = await investorApi.addKYC(files.KYC);
    }

    if (KYCRes.status === 200) {
      await investorApi.createPersonalAccount();
      dispatch(investorActions.resetInvestor());
      dispatch(userActions.setRole('investor'));
      dispatch(entitiesActions.hideLoader());
      // history.push('/dashboard')
    }
  };

  const enableNextButton = useMemo(() => {
    const { POF, MIFID, KYC } = files;

    return POF && MIFID && KYC;
  }, [files]);

  return (
    <StepsPanelContainer>
      <StepsPanel title={'Registration'} step={stepNumber} steps={individualSteps} />

      <RegContainer style={{ maxWidth: '992px' }}>
        <Title style={{ textAlign: 'center' }}>Import files</Title>

        <Row margin={-2 * GU} cols={3}>
          <Col>
            <UploadDocumentsBox
              label={'Proof of funds'}
              docs={files.POF && [files.POF]}
              setDocs={(file) => handleChangeFiles('POF', file)}
            />

            <Container marginY={GU * 4}>
              <Info>Upload Proof of Funds document</Info>
            </Container>
          </Col>

          <Col>
            <UploadDocumentsBox
              label={'MIFID'}
              docs={files.MIFID && [files.MIFID]}
              setDocs={(file) => handleChangeFiles('MIFID', file)}
            />
            <Container marginY={GU * 4}>
              <Info>Fill out and upload the MiFID questionnaire</Info>
            </Container>
          </Col>

          <Col>
            <UploadDocumentsBox
              label={'KYC'}
              docs={files.KYC && [files.KYC]}
              setDocs={(file) => handleChangeFiles('KYC', file)}
            />

            <Container marginY={GU * 4}>
              <Info>Fill out and upload the KYC questionnaire</Info>
            </Container>
          </Col>
        </Row>

        <FooterButtons onBack={goBack} onNext={handleNext} nextDisabled={!enableNextButton} />
      </RegContainer>
    </StepsPanelContainer>
  );
};

const StepsPanelContainer = styled.div`
  display: flex;
`;
const Title = styled(Title2)`
  font-weight: 300;
  margin-bottom: ${7 * GU}px;
`;
