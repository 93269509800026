import axios from 'axios';
import { API_URL } from '../../../utils/static';

const createQuestionnaire = (questionnaire) => {
  return axios.post(
    `${API_URL}/api/kyc/v1/editingQuestionnaires/questionSectionType`,
    {...questionnaire}
  )
};
const updateQuestionnaire = (questionnaire) => {
  return axios.put(
    `${API_URL}/api/kyc/v1/editingQuestionnaires/questionSectionType`,
    {...questionnaire, questionSectionTypeId: questionnaire.id}
  )
};

const deleteQuestionnaire = (questionnaire) => {
  return axios.delete(`${API_URL}/api/kyc/v1/editingQuestionnaires/questionSectionType`, {
    data: { questionSectionTypeId: questionnaire.id }
  });
};

const addRoleQuestionnaire = (questionSectionTypeId, role) => {
  return axios.post(
    `${API_URL}/api/kyc/v1/editingQuestionnaires/questionSectionType/role`,
    {
      questionSectionTypeId: questionSectionTypeId,
      role: role
    }
  )
};
const deleteRoleQuestionnaire = (questionSectionTypeId, role) => {
  return axios.delete(`${API_URL}/api/kyc/v1/editingQuestionnaires/questionSectionType/role`, {
    data: {
      questionSectionTypeId: questionSectionTypeId,
      role: role
    }
  });
};


const createSection = (section, questionSectionTypeId) => {
  return axios.post(
    `${API_URL}/api/kyc/v1/editingQuestionnaires/questionSection`,
    {
      questionSectionTypeId: questionSectionTypeId,
      section: section.section,
      newNumber: section.newNumber
    }
  )
};
const updateSection = (section) => {
  return axios.put(
    `${API_URL}/api/kyc/v1/editingQuestionnaires/questionSection`,
    {
      questionSectionId: section.id,
      section: section.section,
      newNumber: section.newNumber
    }
  )
};
const deleteSection = (section) => {
  return axios.delete(`${API_URL}/api/kyc/v1/editingQuestionnaires/questionSection`, {
    data: { questionSectionId: section.id }
  });
};

const createGroup = (group, sectionId) => {
  return axios.post(
    `${API_URL}/api/kyc/v1/editingQuestionnaires/questionGroup`,
    {
      questionSectionId: sectionId,
      title: group.title,
      isMultiple: group.isMultiple,
      multipleMin: group.multipleMin,
      multipleMax: group.multipleMax,
      sortOrder: group.sortOrder,
      visibleConditions: group.visibleConditions,
      visibleOperator: group.visibleOperator
    }
  )
};
const updateGroup = (group) => {
  return axios.put(
    `${API_URL}/api/kyc/v1/editingQuestionnaires/questionGroup`,
    {
      questionGroupId: group.id,
      title: group.title,
      isMultiple: group.isMultiple,
      multipleMin: group.multipleMin,
      multipleMax: group.multipleMax,
      sortOrder: group.sortOrder,
      visibleConditions: group.visibleConditions,
      visibleOperator: group.visibleOperator
    }
  )
};
const deleteGroup = (group) => {
  return axios.delete(`${API_URL}/api/kyc/v1/editingQuestionnaires/questionGroup`, {
    data: { questionGroupId: group.id }
  });
};

const createQuestion = (question, groupId) => {
  return axios.post(
    `${API_URL}/api/kyc/v1/editingQuestionnaires/question`,
    {
      questionGroupId: groupId,
      question: question.question,
      type: question.type,
      tooltip: question.tooltip,
      isRequired: question.isRequired,
      sortOrder: question.sortOrder,
      visibleConditions: question.visibleConditions,
      visibleOperator: question.visibleOperator,
      preFilledValue: question.preFilledValue,
      dateForSelect: question.dateForSelect,
      person: question.person,
      company: question.company,
      minCountSymbols: question.minCountSymbols,
      maxCountSymbols: question.maxCountSymbols,
      countFirstLetterSymbolsCountry: question.countFirstLetterSymbolsCountry
    }
  );
};
const updateQuestion = (question) => {
  return axios.put(
    `${API_URL}/api/kyc/v1/editingQuestionnaires/question`,
    {
      questionId: question.id,
      question: question.question,
      type: question.type,
      tooltip: question.tooltip,
      isRequired: question.isRequired,
      sortOrder: question.sortOrder,
      visibleConditions: question.visibleConditions,
      visibleOperator: question.visibleOperator,
      preFilledValue: question.preFilledValue,
      dateForSelect: question.dateForSelect,
      person: question.person,
      company: question.company,
      minCountSymbols: question.minCountSymbols,
      maxCountSymbols: question.maxCountSymbols,
      countFirstLetterSymbolsCountry: question.countFirstLetterSymbolsCountry,
      addToPdf: question.addToPdf,
    }
  )
};
const deleteQuestion = (question) => {
  return axios.delete(`${API_URL}/api/kyc/v1/editingQuestionnaires/question`, {
    data: { questionId: question.id }
  });
};

const createAnswer = (answer, questionId) => {
  return axios.post(
    `${API_URL}/api/kyc/v1/editingQuestionnaires/availableAnswer`,
    {
      questionId: questionId,
      answer: answer.answer,
      sortOrder: answer.sortOrder,
      points: answer.points
    }
  )
};
const updateAnswer = (answer) => {
  return axios.put(
    `${API_URL}/api/kyc/v1/editingQuestionnaires/availableAnswer`,
    {
      availableAnswerId: answer.id,
      answer: answer.answer,
      sortOrder: answer.sortOrder,
      points: answer.points
    }
  )
};
const deleteAnswer = (answer) => {
  return axios.delete(`${API_URL}/api/kyc/v1/editingQuestionnaires/availableAnswer`, {
    data: { availableAnswerId: answer.id }
  });
};
const addDocumentType = (questionSectionTypeId, kycTypeDocumentId) => {
  return axios.post(
    `${API_URL}/api/kyc/v1/editingQuestionnaires/questionSectionType/documents`,
    {
      questionSectionTypeId: questionSectionTypeId,
      kycTypeDocumentId: kycTypeDocumentId
    }
  )
};
const deleteDocumentType = (questionSectionTypeId, kycTypeDocumentId) => {
  return axios.delete(`${API_URL}/api/kyc/v1/editingQuestionnaires/questionSectionType/documents`, {
    data: {
      questionSectionTypeId: questionSectionTypeId,
      kycTypeDocumentId: kycTypeDocumentId
    }
  });
};


const getRelatedQuestionnaire = ({ questionSectionTypeId }) => {
  return axios.get(`${API_URL}/api/kyc/v1/editingQuestionnaires/questionSectionType/relatedQuestionnaire`, { params: { questionSectionTypeId } });
};

const createRelatedQuestionnaire = (questionSectionTypeId, groupId, conditions, questions) => {
  return axios.post(
    `${API_URL}/api/kyc/v1/editingQuestionnaires/questionSectionType/relatedQuestionnaire`,
    {
      questionSectionTypeId: questionSectionTypeId,
      groupId: groupId,
      conditions: conditions,
      questions: questions,
    }
  )
};
const deleteRelatedQuestionnaire = (relatedQuestionnaireId) => {
  return axios.delete(`${API_URL}/api/kyc/v1/editingQuestionnaires/questionSectionType/relatedQuestionnaire`, {
    data: {
      relatedQuestionnaireId: relatedQuestionnaireId
    }
  });
};

export const ConstructorApi = {
  createQuestionnaire,
  updateQuestionnaire,
  deleteQuestionnaire,
  addRoleQuestionnaire,
  deleteRoleQuestionnaire,
  createSection,
  updateSection,
  deleteSection,
  createGroup,
  updateGroup,
  deleteGroup,
  createQuestion,
  updateQuestion,
  deleteQuestion,
  createAnswer,
  updateAnswer,
  deleteAnswer,
  addDocumentType,
  deleteDocumentType,
  getRelatedQuestionnaire,
  createRelatedQuestionnaire,
  deleteRelatedQuestionnaire,
};
