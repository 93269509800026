import React from 'react';
import { history } from '../../../../routing';
import { Table, TableRow } from '@aragon/ui';
import { Badge, StatusLine } from '../../../../components';
import { Cell, DetailsButton, TableHeader, TableContainer } from '../../components';
import {
  SIDE_CONFIG,
  VALID_TILL_DATE,
  ORDER_TYPES,
  ORDER_STATUSES,
  EXECUTION_PLACE,
  RESPONSIBLE_BROKER,
  DISPLAY_MIFID_STATUS
} from '../../../../utils/static';
import { dateString, displayFullDate } from '../../../../utils/helpers';
import styled from 'styled-components';

const defHeader = [
  'ID',
  'Placed time',
  'Ticker',
  'Side',
  'Settlement \ntype',
  'Execution \nTime',
  'Mifid status',
  'Responsible broker',
  'Amount',
  'Price',
  'Execution \nAmount',
  'Execution \nPrice\u00A0(AVG)',
  'Order \ntype',
  'Execution \nPlace',
  'Valid Till',
  'Status',
  'Account'
];
export const AdminOrdersTable = ({ header = defHeader, data = [], onDetailsSideEffect }) => {
  const onDetailsClick = (id) => {
    onDetailsSideEffect && onDetailsSideEffect();
    history.push(`/order/${id}`);
  };

  const headerItems = header.map((item, key) => <TableHeader title={item} key={key} />);

  const bodyItems =
    data && data.length > 0 ? (
      data.map((item, key) => (
        <TableRow key={key}>
          <Cell>{item.id}</Cell>

          <Cell>{displayFullDate(new Date(item.createdAt))}</Cell>

          <Cell>{item.asset.ticker}</Cell>

          <Cell>
            <Badge title={SIDE_CONFIG[item.side].title} />
          </Cell>

          <Cell>{item.settlementType}</Cell>

          <Cell>{item.executionTime && displayFullDate(new Date(item.executionTime))}</Cell>

          <Cell>{DISPLAY_MIFID_STATUS[item.mifidStatus]}</Cell>

          <Cell>{RESPONSIBLE_BROKER}</Cell>

          <Cell>{item.quantity}</Cell>

          <Cell>
            {item.totalPrice} {item.asset.currency}
          </Cell>

          <Cell>{item.executionAmount}</Cell>

          <Cell>
            {item.executionPrice} {item.asset.currency}
          </Cell>

          <Cell>{ORDER_TYPES[item.type]}</Cell>

          <Cell>{EXECUTION_PLACE}</Cell>

          <Cell>
            {VALID_TILL_DATE[item.orderValidTillOption] ||
              dateString(new Date(item.orderValidTill))}
          </Cell>

          <Cell>
            {ORDER_STATUSES[item.status] && (
              <StatusLine statusTypes={ORDER_STATUSES} type={item.status} />
            )}
          </Cell>

          <Cell>
            {item.investor.firstName} {item.investor.lastName}
          </Cell>

          <Cell cellProps={{ align: 'right' }}>
            <DetailsButton onClick={() => onDetailsClick(item.id)} />
          </Cell>
        </TableRow>
      ))
    ) : (
      <TableRow>
        <Cell cellProps={{ style: { border: 'none' } }}>No orders</Cell>
      </TableRow>
    );

  return (
    <TableContainer>
      <StyledTable
        header={<TableRow style={{ height: '48px' }}>{headerItems}</TableRow>}
        noSideBorders
      >
        {bodyItems}
      </StyledTable>
    </TableContainer>
  );
};

const StyledTable = styled(Table)`
  th {
    border-left: none;
    border-right: none;
    white-space: pre-line;
    line-height: 16px;
  }
`;
