import React from 'react';
import { GU } from '@aragon/ui';
import { Col, Row, Input, DropDown } from '../../../../../../ui';
import {
  ASSET_TYPES_LIST_DISPLAY,
  EQUITY_TYPES_LIST_DISPLAY,
  CURRENCIES
} from '../../../../../../utils/static';

export const AssetInfoForm = ({ issueInfo, changeAssetInfo }) => {
  return (
    <div>
      <Row cols={4} margin={-GU}>
        <Col width={'50%'}>
          <Input
            label={'ASSET FULL NAME'}
            value={issueInfo.assetFullName}
            onChange={(e) => changeAssetInfo('assetFullName', e.target.value)}
          />
        </Col>

        <DropDown
          label={'Asset type'}
          selected={issueInfo.assetTypeKey}
          onChange={(value) => changeAssetInfo('assetTypeKey', value)}
          items={ASSET_TYPES_LIST_DISPLAY}
          wide
        />

        <DropDown
          label={'Equity type'}
          selected={issueInfo.equityTypeKey}
          onChange={(value) => changeAssetInfo('equityTypeKey', value)}
          items={EQUITY_TYPES_LIST_DISPLAY}
          wide
        />
      </Row>

      <Row cols={4} margin={-GU} marginTop={2 * GU}>
        <Input
          label={'Ticker'}
          value={issueInfo.ticker}
          onChange={(e) => changeAssetInfo('ticker', e.target.value)}
        />

        <DropDown
          label={'Currency'}
          selected={issueInfo.currencyKey}
          onChange={(value) => changeAssetInfo('currencyKey', value)}
          items={CURRENCIES}
          wide
        />

        <Col width={'50%'}>
          <Input
            label={'Exchange'}
            value={issueInfo.exchange}
            onChange={(e) => changeAssetInfo('exchange', e.target.value)}
          />
        </Col>
      </Row>

      <Row cols={4} margin={-GU} marginTop={2 * GU}>
        <Input
          label={'Isin'}
          value={issueInfo.isin}
          onChange={(e) => changeAssetInfo('isin', e.target.value)}
        />

        <Input
          label={'Branch'}
          value={issueInfo.branch}
          onChange={(e) => changeAssetInfo('branch', e.target.value)}
        />

        <Input
          label={'Region'}
          value={issueInfo.region}
          onChange={(e) => changeAssetInfo('region', e.target.value)}
        />

        <Input
          label={'Timing'}
          value={issueInfo.timing}
          onChange={(e) => changeAssetInfo('timing', e.target.value)}
        />
      </Row>
    </div>
  );
};
