import React, { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { adminLocalizationApi, adminLocalizationKeys } from '../../../api/admin/localization';
import { Box, FooterButtons, StepsPanel } from '../../../components';
import { Col, Container, Row } from '../../../ui/layout';
import { BackButton } from '../../../ui/atoms/BackButton';
import { SHOWED_SECTIONS_COUNT } from '../../../utils/static/kyc';
import { history } from '../../../routing';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Typography, Select, Button } from 'antd';
import { MessageContext } from '../../../app/MessageProvider';
import { queryClient } from '../../../app/QueryClientProvider';
import { sortWithNulls } from '../../../utils/helpers';
import { useCountries } from '../../../hooks';

export const TranslateQuestionnaire = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const messageApi = useContext(MessageContext);
  const { languages } = useCountries();

  const { data: allQuestionnaires } = useQuery({
    queryKey: adminLocalizationKeys.getAllQuestionnaires,
    queryFn: adminLocalizationApi.getAllQuestionnaires,
    select: data1 => data1.data
  });
  const { data: allQuestionnairesTranslated } = useQuery({
    queryKey: [adminLocalizationKeys.getAllQuestionnairesTranslation, selectedLanguage],
    queryFn: () => adminLocalizationApi.getAllQuestionnairesTranslation(selectedLanguage),
    select: data1 => data1.data
  });
  const { data } = useQuery({
    queryKey: adminLocalizationKeys.getQuestionnaireSections,
    queryFn: () => adminLocalizationApi.getQuestionnaireSections({ questionSectionTypeId: id }),
    select: data1 => data1.data
  });
  const { data: questionnaireTranslation } = useQuery({
    queryKey: [adminLocalizationKeys.getQuestionnaireSectionsTranslation, selectedLanguage],
    queryFn: () => adminLocalizationApi.getQuestionnaireSectionsTranslation({
      questionSectionTypeId: id,
      languageId: selectedLanguage
    }),
    select: data1 => data1.data
  });
  const { data: isActive } = useQuery({
    queryKey: [adminLocalizationKeys.isQuestTranslationActive, selectedLanguage],
    queryFn: () => adminLocalizationApi.isQuestTranslationActive({
      questionSectionTypeId: id,
      languageId: selectedLanguage
    }),
    select: data1 => data1.data
  });

  const [currentSection, setCurrentSection] = useState(0);


  const currentQuest = allQuestionnaires?.find(quest => quest.id === id);
  const currentQuestTranslated = allQuestionnairesTranslated?.find(quest => quest.id === id);

  const saveQuestTranslation = async (value, prevValue) => {
    if (value !== prevValue) {
      try {
        await adminLocalizationApi.setQuestionnaireInfo({
          questionSectionTypeId: id,
          languageId: selectedLanguage,
          name: value
        });

        queryClient.refetchQueries({ queryKey: [adminLocalizationKeys.getAllQuestionnairesTranslation] });
        messageApi.success(t('Saved'));
      } catch (e) {
        console.error(e);
      }
    }
  };

  const saveTranslation = async ({ questionSectionId, questionGroupId, questionId, availableAnswerId, value, prevValue }) => {
    if (value !== prevValue || !prevValue) {
      try {
        await adminLocalizationApi.setQuestionnaireSection({
          questionSectionTypeId: id,
          languageId: selectedLanguage,
          questionSectionId,
          questionGroupId,
          questionId,
          availableAnswerId,
          newText: value
        });

        queryClient.refetchQueries({ queryKey: [adminLocalizationKeys.getQuestionnaireSectionsTranslation] });
        messageApi.success(t('Saved'));
      } catch (e) {
        console.error(e);
      }
    }
  };

  const enableTranslation = async () => {
    try {
      await adminLocalizationApi.activateQuestionnaireTranslation({
        questionSectionTypeId: id,
        languageId: selectedLanguage
      });

      queryClient.refetchQueries({ queryKey: [adminLocalizationKeys.isQuestTranslationActive] });
      messageApi.success(t('Translation enabled'));

    } catch (e) {

    }
  };

  if (!data) return t('Loading') + '...';

  return (
    <Row nowrap>
      <StepsPanel
        step={currentSection + 1}
        steps={data.map((item) => item.section)}
        width={250}
      />

      <Col>
        <MainContainer paddingY={64} paddingRight={23} paddingBottom={'0'} paddingLeft={15}>
          <div>
            <Row
              margin={-1.5 * 8}
              justifyContent={'flex-end'}
              marginTop={-48}
              marginBottom={24}
              alignCenter
            >
              <Col>
                <BackButton onClick={() => history.push('/localization')} label={t('Back')} />
              </Col>
              <Col auto>
                <Row gap={24} nowrap>
                  {isActive && <Button disabled={isActive.active} type="primary" onClick={enableTranslation}>
                    {!isActive.active ? t('Enable translation') : t('Enabled')}
                  </Button>}

                  {languages && <Select
                    style={{ width: 180 }}
                    onChange={(value) => setSelectedLanguage(value)}
                    options={languages.map(item => ({ value: item.id, label: item.language }))}
                    placeholder={t('Select language')}
                  />}
                </Row>
              </Col>
            </Row>

            <Row cols={2} gap={16} nowrap marginBottom={24}>
              {currentQuest && <Typography.Title level={4} style={{ margin: 0 }}>
                {currentQuest.name}
              </Typography.Title>}
              {currentQuestTranslated && <Typography.Title
                editable={selectedLanguage ? { onChange: (value) => saveQuestTranslation(value, currentQuestTranslated.name) } : false}
                level={4}
                disabled={!selectedLanguage}
                style={{ margin: 0 }}
              >
                {currentQuestTranslated.name}
              </Typography.Title>}
            </Row>


            <Row cols={2} gap={16} nowrap>
              <RenderQuestionnaire
                questionnaire={data}
                currentSection={currentSection}
                selectedLanguage={selectedLanguage}
                saveTranslation={saveTranslation}
              />
              <RenderQuestionnaire
                questionnaire={questionnaireTranslation}
                currentSection={currentSection}
                selectedLanguage={selectedLanguage}
                saveTranslation={saveTranslation}
                editable
              />
            </Row>

          </div>


          <StickyBox paddingY={32} paddingX={40}>
            <FooterButtons
              onNext={() => setCurrentSection(currentSection + 1)}
              onBack={() => setCurrentSection(currentSection - 1)}
              hideBack={currentSection === 0}
              hideNext={currentSection + 1 >= data.length}
              noMargin
            />
          </StickyBox>
        </MainContainer>
      </Col>
    </Row>
  );
};

const RenderQuestionnaire = ({ questionnaire, currentSection, selectedLanguage, saveTranslation, editable = false }) => {
  return (
    <div>
      {questionnaire && questionnaire.slice(currentSection, currentSection + SHOWED_SECTIONS_COUNT)
      .map((sectionObj) => (
        <Box key={sectionObj.id}>
          <Typography.Title
            disabled={editable && !selectedLanguage}
            editable={(editable && selectedLanguage) ? {
              onChange: (value) => saveTranslation({
                questionSectionId: sectionObj.id,
                value: value,
                prevValue: sectionObj.section
              })
            } : false}
            level={4}
          >
            {sectionObj.section}
          </Typography.Title>

          <Container paddingLeft={16}>
            {sectionObj.questionGroups?.sort((a, b) => sortWithNulls(Number(a.sortOrder), Number(b.sortOrder))).map(questionGroup => (
              <Container paddingLeft={8}>
                {questionGroup.title && <Typography.Title
                  disabled={editable && !selectedLanguage}
                  editable={(editable && selectedLanguage) ? {
                    onChange: (value) => saveTranslation({
                      questionGroupId: questionGroup.id,
                      value: value,
                      prevValue: questionGroup.title
                    })
                  } : false}
                  level={4}
                >
                  {questionGroup.title}
                </Typography.Title>}

                {questionGroup.questions.sort((a, b) => sortWithNulls(Number(a.sortOrder), Number(b.sortOrder))).map(question => (
                  <Container paddingLeft={16}>
                    {question.question && <Typography.Title
                      disabled={editable && !selectedLanguage}
                      editable={editable && selectedLanguage ? {
                        onChange: (value) => saveTranslation({
                          questionId: question.id,
                          value: value,
                          prevValue: question.question
                        })
                      } : false}
                      level={5}
                    >
                      {question.question}
                    </Typography.Title>}

                    {question.availableAnswers.sort((a, b) => sortWithNulls(Number(a.sortOrder), Number(b.sortOrder))).map(answer => (
                      <Container paddingLeft={16}>
                        <Typography.Text
                          disabled={editable && !selectedLanguage}
                          editable={editable && selectedLanguage ? {
                            onChange: (value) => saveTranslation({
                              availableAnswerId: answer.id,
                              value: value,
                              prevValue: answer.answer
                            })
                          } : false}
                        >
                          {answer.answer}
                        </Typography.Text>
                      </Container>
                    ))}
                  </Container>
                ))}
              </Container>
            ))}
          </Container>
        </Box>
      ))}
    </div>
  );
};

const MainContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  min-height: 100dvh;
`;
const StickyBox = styled(Container)`
  background: #ffffff;
  box-shadow: 0px -1px 3px rgba(0, 0, 0, 0.15);
  position: sticky;
  bottom: 0;
  margin: 40px -23px 0 -15px;
`;
