import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
  html, body {
      overflow: unset !important;
  }
    
  body #root {
    font-family: 'Overpass', sans-serif;
    font-weight: 300;
  }

  .react-datepicker-popper {
    z-index: 15;
  }

  ul[role="listbox"] {
    z-index: 15;
  }
`;
