import React, { useState } from 'react';
import { RegContainer, StepsPanel, FooterButtons, OptionCard } from '../../../../../components';
import styled from 'styled-components';
import { Title2, Label2 } from '../../../../../ui';
import {
  GU,
  Info,
  Box,
  Text,
  Checkbox,
  TextInput,
  Help,
  Radio,
  DropDown,
  Field,
  Link,
  Modal
} from '@aragon/ui';
import { entitySteps } from '../../../../../utils/static';
import { TabsOwners } from '../../../../../components';
import { history } from '../../../../../routing';
import textStyle from '../../../../../utils/textStyle';
import styles from '../../styles.module.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

export const Step9 = () => {
  const [modalOpened, setModalOpened] = useState(false);
  const [startDate, setStartDate] = useState(new Date());

  const openModal = () => setModalOpened(true);
  const closeModal = () => setModalOpened(false);

  const ExampleCustomInput = ({ value, onClick }) => (
    <button className={styles['example-custom-input']} onClick={onClick}>
      {value}
    </button>
  );

  return (
    <StepsPanelContainer>
      <StepsPanel title={'Registration'} step={8} steps={entitySteps} />

      <RegContainer>
        <Title style={{ textAlign: 'center' }}>KYC questionary</Title>

        <Box className={styles['noPadding']}>
          <div className={styles['innerBox']}>
            <Text style={textStyle('body1')}>Personal information</Text>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginBottom: '16px',
                marginTop: '24px'
              }}
            >
              <div style={{ display: 'flex', flexDirection: 'column', width: '49%' }}>
                <InputLabel>First name</InputLabel>
                <TextInput style={{ width: '100%', background: '#F9FAFC' }} />
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', width: '49%' }}>
                <InputLabel>Last name</InputLabel>
                <TextInput style={{ width: '100%', background: '#F9FAFC' }} />
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginBottom: '16px',
                marginTop: '24px'
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  width: '49%'
                }}
              >
                <div style={{ display: 'flex', flexDirection: 'column', width: '48%' }}>
                  <InputLabel>Birth date</InputLabel>
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    dateFormat='dd.MM.yyyy'
                    customInput={<ExampleCustomInput />}
                  />
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', width: '48%' }}>
                  <InputLabel>Country of birth</InputLabel>
                  <DropDown items={['Country', 'Country']} selected={0} width='100%' />
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  width: '49%'
                }}
              >
                <div style={{ display: 'flex', flexDirection: 'column', width: '70%' }}>
                  <InputLabel>Citizenship(s)</InputLabel>
                  <TextInput style={{ width: '100%' }} />
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', width: '26%' }}>
                  <InputLabel>Gender</InputLabel>
                  <DropDown items={['Female', 'Male']} selected={0} width='100%' />
                </div>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginTop: '24px'
              }}
            >
              <div style={{ display: 'flex', flexDirection: 'column', width: '49%' }}>
                <InputLabel>E-mail</InputLabel>
                <TextInput style={{ width: '100%', background: '#F9FAFC' }} />
              </div>
              <div style={{ display: 'flex', width: '49%' }}>
                <Field label='Phone number' style={{ marginBottom: '0', width: '100%' }}>
                  <TextInput placeholder='Number' value='+375' style={{ width: '25%' }} />
                  <TextInput placeholder='Number' style={{ width: '72%', marginLeft: '3%' }} />
                </Field>
              </div>
            </div>
          </div>

          <div className={styles['innerBox']}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginBottom: '16px'
              }}
            >
              <div style={{ display: 'flex', flexDirection: 'column', width: '49%' }}>
                <InputLabel>ID</InputLabel>
                <TextInput style={{ width: '100%', background: '#F9FAFC' }} />
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', width: '49%' }}>
                <InputLabel>Document number</InputLabel>
                <TextInput style={{ width: '100%', background: '#F9FAFC' }} />
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginTop: '24px'
              }}
            >
              <div style={{ display: 'flex', flexDirection: 'column', width: '49%' }}>
                <InputLabel>Issued by</InputLabel>
                <TextInput style={{ width: '100%', background: '#F9FAFC' }} />
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', width: '49%' }}>
                <InputLabel>Issued Country</InputLabel>
                <DropDown items={['Country', 'Country']} selected={0} width='100%' />
              </div>
            </div>
          </div>
        </Box>

        <Box heading='Legal Address' className={styles['noPadding']}>
          <div className={styles['innerBox']}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between'
              }}
            >
              <div style={{ display: 'flex', flexDirection: 'column', width: '23.8%' }}>
                <InputLabel>Country</InputLabel>
                <DropDown items={['Country', 'Country']} selected={0} width='100%' />
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', width: '23.8%' }}>
                <InputLabel>City</InputLabel>
                <TextInput style={{ width: '100%', background: '#F9FAFC' }} />
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', width: '49%' }}>
                <InputLabel>Address</InputLabel>
                <TextInput style={{ width: '100%', background: '#F9FAFC' }} />
              </div>
            </div>
          </div>
        </Box>

        <Box heading='Actual Address' className={styles['noPadding']}>
          <div className={styles['innerBox']}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between'
              }}
            >
              <div style={{ display: 'flex', flexDirection: 'column', width: '23.8%' }}>
                <InputLabel>Country</InputLabel>
                <DropDown items={['Country', 'Country']} selected={0} width='100%' />
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', width: '23.8%' }}>
                <InputLabel>City</InputLabel>
                <TextInput style={{ width: '100%', background: '#F9FAFC' }} />
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', width: '49%' }}>
                <InputLabel>Address</InputLabel>
                <TextInput style={{ width: '100%', background: '#F9FAFC' }} />
              </div>
            </div>
          </div>
        </Box>

        <Box className={styles['noPadding']}>
          <div className={styles['innerBox']}>
            <Text style={textStyle('body1')}>
              Additional question for foreign citizens (this question must be answered only by
              foreigners):
            </Text>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginTop: '6px'
              }}
              className={styles['radioList']}
            >
              <label>
                <Checkbox />
                Any employment in Lithuania
              </label>
              <label>
                <Checkbox />
                Any ownership of real estate in Lithuania
              </label>
              <label>
                <Checkbox />
                Ownership of registered company in Lithuania
              </label>
              <label>
                <Checkbox />
                Spouse as a citizen of the Republic of Lithuania
              </label>
              <label>
                <Checkbox />
                Residence permit in Lithuania
              </label>
              <label>
                <Checkbox />
                Studying in Lithuania
              </label>
              <label>
                <Checkbox />
                Tax refunds
              </label>
              <label>
                <Checkbox />
                State benefits (pension, compensation for land, etc.)
              </label>
              <label>
                <Checkbox />
                No relationship
              </label>
            </div>
          </div>
        </Box>

        <Box className={styles['noPadding']}>
          <div style={{ borderBottom: '1px solid #DDE4E9' }}>
            <Text style={{ display: 'block', padding: '22px 24px 14px' }}>
              Person tax residense
            </Text>
            <div className={styles['headerFinancialInstr']}>
              <div style={{ width: '28%' }}>Country of residence</div>
              <div style={{ width: '70%' }}>Taxpayer Identification Number (TIN)</div>
            </div>
            <div className={styles['dataFinancialInstr']}>
              <div className={styles['dataFinancialInstrRow']}>
                <div style={{ width: '28%' }}>
                  <DropDown items={['Country', 'Country']} selected={0} width='100%' />
                </div>
                <div style={{ width: '45%' }}>
                  <TextInput value='' style={{ width: '100%' }} />
                </div>
                <div style={{ width: '27%' }}>
                  <label style={{ display: 'block', width: '100%' }}>
                    <Checkbox />
                    Country doesn’t issue TIN
                  </label>
                </div>
              </div>
              <div className={styles['dataFinancialInstrRow']}>
                <div style={{ width: '28%' }}>
                  <DropDown items={['Country', 'Country']} selected={0} width='100%' />
                </div>
                <div style={{ width: '45%' }}>
                  <TextInput value='' style={{ width: '100%' }} />
                </div>
                <div style={{ width: '27%' }}>
                  <label style={{ display: 'block', width: '100%' }}>
                    <Checkbox />
                    Country doesn’t issue TIN
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div className={styles['innerBox']}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Text>
                Did you have relations with USA for the last 2 years (born, citizenship, permanent
                residence permit, living in the country)?
              </Text>
              <div style={{ display: 'flex', marginTop: '6px' }} className={styles['radioList']}>
                <label style={{ display: 'block', width: '50%' }}>
                  <Radio id='No1' />
                  Yes
                  <div style={{ width: '91%', marginLeft: '26px', marginTop: '5px' }}>
                    <TextInput
                      placeholder='Please explain your relations'
                      style={{ width: '100%' }}
                    />
                  </div>
                </label>
                <label style={{ display: 'block', width: '50%' }}>
                  <Radio id='Yes1' />
                  No
                </label>
              </div>
            </div>
          </div>
        </Box>

        <Box className={styles['noPadding']}>
          <div className={styles['innerBox']}>
            <Text style={textStyle('body1')}>Information of PEP status</Text>
            <Text
              style={{ display: 'block', padding: '15px 0', color: '#637381', fontSize: '14px' }}
            >
              Please, fill in the fields below in case you are politically exposed person (PEP)
              and/or you are a close family member or close associate* of PEP
            </Text>
            <label style={{ fontSize: '14px' }}>
              <Checkbox />
              PEP
            </label>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginTop: '20px'
              }}
            >
              <div style={{ display: 'flex', flexDirection: 'column', width: '49%' }}>
                <InputLabel>Specific prominent public function of PEP</InputLabel>
                <TextInput style={{ width: '100%' }} />
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', width: '23.8%' }}>
                <InputLabel>Country where person is PEP</InputLabel>
                <DropDown items={['Country', 'Country']} selected={0} width='100%' />
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', width: '23.8%' }}>
                <InputLabel>End date of the position</InputLabel>
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  dateFormat='dd.MM.yyyy'
                  customInput={<ExampleCustomInput />}
                />
              </div>
            </div>
          </div>

          <div className={styles['innerBox']} style={{ paddingTop: '16px' }}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <label style={{ fontSize: '14px' }}>
                <Checkbox />
                Close family member of PEP
              </label>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  marginTop: '20px'
                }}
              >
                <div style={{ display: 'flex', flexDirection: 'column', width: '49%' }}>
                  <InputLabel>First name</InputLabel>
                  <TextInput style={{ width: '100%' }} />
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', width: '49%' }}>
                  <InputLabel>Last name</InputLabel>
                  <TextInput style={{ width: '100%' }} />
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  marginTop: '20px'
                }}
              >
                <div style={{ display: 'flex', flexDirection: 'column', width: '49%' }}>
                  <InputLabel>Birth date</InputLabel>
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    dateFormat='dd.MM.yyyy'
                    customInput={<ExampleCustomInput />}
                  />
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', width: '49%' }}>
                  <InputLabel>ID</InputLabel>
                  <TextInput style={{ width: '100%' }} />
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  marginTop: '20px'
                }}
              >
                <div style={{ display: 'flex', flexDirection: 'column', width: '49%' }}>
                  <InputLabel>Specific prominent public function of PEP</InputLabel>
                  <TextInput style={{ width: '100%' }} />
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', width: '23.6%' }}>
                  <InputLabel>Country where person is PEP</InputLabel>
                  <DropDown items={['Country', 'Country']} selected={0} width='100%' />
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', width: '23.7%' }}>
                  <InputLabel>End date of the position</InputLabel>
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    dateFormat='dd.MM.yyyy'
                    customInput={<ExampleCustomInput />}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className={styles['innerBox']} style={{ paddingTop: '16px' }}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <label style={{ fontSize: '14px' }}>
                <Checkbox />
                Close associate of PEP
              </label>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  marginTop: '20px'
                }}
              >
                <div style={{ display: 'flex', flexDirection: 'column', width: '49%' }}>
                  <InputLabel>First name</InputLabel>
                  <TextInput style={{ width: '100%' }} />
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', width: '49%' }}>
                  <InputLabel>Last name</InputLabel>
                  <TextInput style={{ width: '100%' }} />
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  marginTop: '20px'
                }}
              >
                <div style={{ display: 'flex', flexDirection: 'column', width: '49%' }}>
                  <InputLabel>Birth date</InputLabel>
                  <TextInput style={{ width: '100%' }} />
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', width: '49%' }}>
                  <InputLabel>ID</InputLabel>
                  <TextInput style={{ width: '100%' }} />
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  marginTop: '20px'
                }}
              >
                <div style={{ display: 'flex', flexDirection: 'column', width: '49%' }}>
                  <InputLabel>Specific prominent public function of PEP</InputLabel>
                  <TextInput style={{ width: '100%' }} />
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', width: '23.6%' }}>
                  <InputLabel>Country where person is PEP</InputLabel>
                  <DropDown items={['Country', 'Country']} selected={0} width='100%' />
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', width: '23.7%' }}>
                  <InputLabel>End date of the position</InputLabel>
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    dateFormat='dd.MM.yyyy'
                    customInput={<ExampleCustomInput />}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className={styles['innerBox']}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Text>
                If at least 1 person is stated above, please provide information on main source
                (e.g. investment income, shareholder’s loan, company/share sale, etc.)
              </Text>
              <TextInput style={{ width: '49%', marginTop: '8px' }} />
            </div>
          </div>
        </Box>

        <Box className={styles['noPadding']}>
          <div className={styles['innerBox']}>
            <Text style={textStyle('body1')}>Source of customer funds in account</Text>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginTop: '6px'
              }}
              className={styles['radioList']}
            >
              <label>
                <Checkbox />
                Salary
                <div style={{ width: '91%', marginLeft: '26px', marginTop: '4px' }}>
                  <TextInput
                    placeholder='Please specify activity'
                    style={{ width: '50%', marginRight: '16px' }}
                  />
                  <DropDown items={['up to 999 EUR', 'up to 999 EUR']} selected={0} width='30%' />
                </div>
              </label>
              <label style={{ marginTop: '10px' }}>
                <Checkbox />
                Social benefits/pension
                <div style={{ width: '91%', marginLeft: '26px', marginTop: '4px' }}>
                  <DropDown items={['up to 999 EUR', 'up to 999 EUR']} selected={0} width='30%' />
                </div>
              </label>
              <label style={{ marginTop: '10px' }}>
                <Checkbox />
                Transfers from relatives
                <div style={{ width: '91%', marginLeft: '26px', marginTop: '4px' }}>
                  <DropDown items={['up to 999 EUR', 'up to 999 EUR']} selected={0} width='30%' />
                </div>
              </label>
              <label style={{ marginTop: '10px' }}>
                <Checkbox />
                Self-employed
                <div style={{ width: '91%', marginLeft: '26px', marginTop: '4px' }}>
                  <TextInput
                    placeholder='Please specify activity'
                    style={{ width: '50%', marginRight: '16px' }}
                  />
                  <DropDown items={['up to 999 EUR', 'up to 999 EUR']} selected={0} width='30%' />
                </div>
              </label>
              <label style={{ marginTop: '10px' }}>
                <Checkbox />
                Income from transactions with financial instruments
                <div style={{ width: '91%', marginLeft: '26px', marginTop: '4px' }}>
                  <DropDown items={['up to 999 EUR', 'up to 999 EUR']} selected={0} width='30%' />
                </div>
              </label>
              <label style={{ marginTop: '10px' }}>
                <Checkbox />
                Business ownership income
                <div style={{ width: '91%', marginLeft: '26px', marginTop: '4px' }}>
                  <DropDown items={['up to 999 EUR', 'up to 999 EUR']} selected={0} width='30%' />
                </div>
              </label>
              <label style={{ marginTop: '10px' }}>
                <Checkbox />
                Public grants
                <div style={{ width: '91%', marginLeft: '26px', marginTop: '4px' }}>
                  <DropDown items={['up to 999 EUR', 'up to 999 EUR']} selected={0} width='30%' />
                </div>
              </label>
              <label style={{ marginTop: '10px' }}>
                <Checkbox />
                Income from real estate rent
                <div style={{ width: '91%', marginLeft: '26px', marginTop: '4px' }}>
                  <TextInput
                    placeholder='Please specify address'
                    style={{ width: '50%', marginRight: '16px' }}
                  />
                  <DropDown items={['up to 999 EUR', 'up to 999 EUR']} selected={0} width='30%' />
                </div>
              </label>
              <label style={{ marginTop: '10px' }}>
                <Checkbox />
                <div style={{ width: '91%', marginLeft: '26px', marginTop: '-22px' }}>
                  <TextInput
                    placeholder='Other (please specify)'
                    style={{ width: '50%', marginRight: '16px' }}
                  />
                  <DropDown items={['up to 999 EUR', 'up to 999 EUR']} selected={0} width='30%' />
                </div>
              </label>
            </div>
          </div>
        </Box>

        <Box className={styles['noPadding']}>
          <div style={{ borderBottom: '1px solid #DDE4E9' }}>
            <Text style={{ display: 'block', padding: '22px 24px 14px' }}>
              Clients account with other brokers
            </Text>
            <div className={styles['headerFinancialInstr']}>
              <div style={{ width: '60%' }}>Name of broker</div>
              <div style={{ width: '40%' }}>Country of incorporation</div>
            </div>
            <div className={styles['dataFinancialInstr']}>
              <div className={styles['dataFinancialInstrRow']}>
                <div style={{ width: '60%' }}>
                  <TextInput value='' style={{ width: '100%' }} />
                </div>
                <div style={{ width: '40%' }}>
                  <DropDown items={['Country', 'Country']} selected={0} width='100%' />
                </div>
              </div>
              <div className={styles['dataFinancialInstrRow']}>
                <div style={{ width: '60%' }}>
                  <TextInput value='' style={{ width: '100%' }} />
                </div>
                <div style={{ width: '40%' }}>
                  <DropDown items={['Country', 'Country']} selected={0} width='100%' />
                </div>
              </div>
            </div>
          </div>
        </Box>

        <Box className={styles['noPadding']}>
          <div className={styles['innerBox']}>
            <Text style={textStyle('body1')}>Additional information</Text>
            <textarea
              style={{ marginTop: '12px' }}
              className={styles['textArea']}
              placeholder='Leave your comment here'
            />
          </div>
        </Box>

        <Box className={styles['noPadding']} style={{ marginBottom: '25px' }}>
          <div className={styles['innerBox']}>
            <Text style={{ fontSize: '14px', color: '#637381' }}>01.01.2021</Text>
            <label style={{ display: 'block', width: '55%' }}>
              <Checkbox />
              <Text className={styles['agreement']}>
                I agree with the <Link onClick={openModal}>Information acknowlegment</Link>
              </Text>
            </label>
          </div>
        </Box>

        <Modal className={styles['modal']} visible={modalOpened} onClose={closeModal}>
          <Text style={{ display: 'block', marginBottom: '15px', fontSize: '18px' }}>
            Information acknowlegment
          </Text>
          <Text style={{ fontSize: '16px', lineHeight: '22px', color: '#637381' }}>
            I hereby confirm with the signature that the information stated herein is truthful. I
            hereby confirm with the signature that I am aware of the liability, including criminal
            responsibility that upon providing of incorrect, misleading, incomplete or willful
            non-disclosure of information Macte Invest FMAB reserves the right not to establish a
            business relationship with the Customer, to terminate an existing business relationship
            or not to perform transactions. Macte Invest FMAB also reserves the right to provide
            additional documents (account statements form another bank, documents confirming the
            origin of funds or other documents). I hereby confirm that all financial assets of the
            Customer are of legal origin and was not obtained from an unlawful source or as a result
            of unlawful activity. I must undertake to inform Macte Invest FMAB immediately in
            writing about any changes in the above stated information. I confirm that if such
            notification is not given, I am the owner of the funds held in the accounts to be
            opened/opened in my name, as well as I am not performing any transactions on behalf of
            the third persons and I am not holding third persons’ funds in the accounts opened in my
            name.
          </Text>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginTop: '18px'
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'column', width: '32%' }}>
              <InputLabel>Name, surname</InputLabel>
              <TextInput style={{ width: '100%' }} />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                width: '42%'
              }}
            >
              <div style={{ display: 'flex', flexDirection: 'column', width: '44%' }}>
                <InputLabel>Date</InputLabel>
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  dateFormat='dd.MM.yyyy'
                  customInput={<ExampleCustomInput />}
                />
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', width: '52%' }}>
                <InputLabel>Signature</InputLabel>
                <TextInput style={{ width: '100%' }} />
              </div>
            </div>
          </div>
          <Text style={{ display: 'block', marginTop: '26px' }}>
            Full name and signature of Macte Invest FMAB employee who accepted this questionnaire:
          </Text>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginTop: '18px'
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                width: '56%'
              }}
            >
              <div style={{ display: 'flex', flexDirection: 'column', width: '57%' }}>
                <InputLabel>Name, surname</InputLabel>
                <TextInput style={{ width: '100%' }} />
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', width: '40%' }}>
                <InputLabel>Position</InputLabel>
                <TextInput style={{ width: '100%' }} />
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', width: '22%' }}>
              <InputLabel>Signature</InputLabel>
              <TextInput style={{ width: '100%' }} />
            </div>
          </div>
          <Text
            style={{
              display: 'block',
              width: '75%',
              marginTop: '26px',
              color: '#637381',
              fontSize: '14px',
              lineHeight: '21px'
            }}
          >
            *The purpose of this questionnaire is to collect information about the client according
            current anti-money laundering and tax administration legislation and the overall
            know-your-customer (KYC) principle. Please note that this questionnaire is strictly
            confidential and will not be disclosed to third parties unless it is required by law.
          </Text>
        </Modal>

        <FooterButtons onNext={() => history.push('/registration/investor/10')} />
      </RegContainer>
    </StepsPanelContainer>
  );
};

const StepsPanelContainer = styled.div`
  display: flex;
`;
const Title = styled(Title2)`
  font-weight: 300;
  margin-bottom: ${7 * GU}px;
`;
const InputLabel = styled(Label2)``;
const InfoBlock = styled(Info)`
  margin: ${GU * 4}px 0;
`;
