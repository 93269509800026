import React from 'react';
import { useTranslation } from 'react-i18next';
import { Collapse } from 'antd';

import { Group } from './Group';
import { Title4 } from '../../../ui';
import {StyledPanel} from '../styled'

export const GroupList = ({list}) => {
  const { t } = useTranslation();

  console.log('GroupList: list', list);
  return (
    <>
      <Title4>{t('Groups')}</Title4>

      <Collapse accordion>
        {list?.map(group => (
          <StyledPanel header={group.title} key={group.id}>
            <Group groupProp={group} />
          </StyledPanel>
        ))}
      </Collapse>
    </>
  );
};
