import React from 'react';
import { GU } from '@aragon/ui';
import { Box } from '../../../../components';
import { Row, Col, Input, DatePicker } from '../../../../ui';

export const InvestorInfoInputBox = ({ data, changeData }) => {
  return (
    <Box paddingBottom={3 * GU}>
      <Row margin={-GU} cols={4} colsMarginBottom={2.5 * GU}>
        <Col width={'50%'}>
          <Input
            label={'First name'}
            value={data.firstName}
            onChange={(e) => changeData('firstName', e.target.value)}
          />
        </Col>
        <Col width={'50%'}>
          <Input
            label={'Last name'}
            value={data.lastName}
            onChange={(e) => changeData('lastName', e.target.value)}
          />
        </Col>

        <DatePicker
          label={'Birth date'}
          selected={data.dateOfBirth}
          onChange={(value) => changeData('dateOfBirth', value)}
        />
        <Col>
          <Input
            label={'Mobile phone'}
            value={data.phone}
            onChange={(e) => changeData('phone', e.target.value)}
          />
        </Col>
        <Col width={'50%'}>
          <Input
            label={'E-Mail'}
            value={data.email}
            onChange={(e) => changeData('email', e.target.value)}
          />
        </Col>
      </Row>
    </Box>
  );
};
