import { COLORS } from '../colors';
import { IconCircleCheck, IconClock, IconProhibited } from '@aragon/ui';

export const TRANSFER_TYPES = ['WITHDRAWAL', 'FUNDING'];

export const DISPLAY_TRANSFER_TYPE = {
  WITHDRAWAL: 'Withdrawal',
  FUNDING: 'Funding'
};

export const TRANSACTION_TYPES = [...TRANSFER_TYPES, 'DEAL_TRANSACTION', 'COMMISSION_TRANSACTION'];
export const DISPLAY_TRANSACTION_TYPE = {
  ...DISPLAY_TRANSFER_TYPE,
  DEAL_TRANSACTION: 'Deal',
  COMMISSION_TRANSACTION: 'Commission'
};

export const TRANSFER_STATUSES_LIST = ['PENDING', 'EXECUTED', 'DECLINED'];
export const TRANSFER_STATUSES = {
  PENDING: {
    title: 'Pending...',
    color: COLORS.iconColor,
    icon: IconClock
  },
  EXECUTED: {
    title: 'Complete',
    color: COLORS.mintGreen,
    icon: IconCircleCheck
  },
  DECLINED: {
    title: 'Declined',
    color: COLORS.red,
    icon: IconProhibited
  }
};

export const TRANSACTIONS_ON_PAGE = 5;
