import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { InvestorDocRequest } from '../../containers';
import { EditPage } from '../../pages/admin/Investors/QuestionnaireAnswers/edit';
import {
  Assets,
  Deals,
  Funds,
  Home,
  Investors,
  Issues,
  Management,
  Orders,
  Reporting,
  CreateIssue,
  CreateIssuer,
  InvestorDetails,
  AssetDetails,
  IssuerDetails,
  TransactionDetails,
  OrderDetails,
  QuestionnaireAnswers,
  SumsubAnswers,
  Localization,
  TranslateQuestionnaire,
  Constructor,
  QuestionnairePage,
  ConstructorDocuments,
  DocumentPage,
  ConstructorProducts,
  ProductPage,
  ProductAnswers
} from '../../pages';
import { EditProductPage } from '../../pages/admin/Investors/ProductAnswers/edit';

export const AdminRoutes = () => {
  return (
    <Switch>
      <Route exact path='/home' component={Home} />
      <Route exact path='/investors/:type' component={Investors} />
      <Route exact path='/investor/:id' component={InvestorDetails} />
      <Route
        exact
        path='/investor/:id/questionnaire/:questionnaireId'
        component={QuestionnaireAnswers}
      />
      <Route
        exact
        path='/investor/:id/product/:productId'
        component={ProductAnswers}
      />
      <Route
        exact
        path='/investor/:id/questionnaire/:questionnaireId/edit'
        component={EditPage}
      />
      <Route
        exact
        path='/investor/:id/product/:productId/edit'
        component={EditProductPage}
      />
      <Route exact path='/investor/:id/sumsub-verification' component={SumsubAnswers} />
      <Route
        exact
        path='/investor/:id/documents/request/:requestId'
        component={InvestorDocRequest}
      />
      <Route exact path='/localization' component={Localization} />
      <Route exact path='/localization/:id' component={TranslateQuestionnaire} />


      <Route exact path='/issues' component={Issues} />
      <Route exact path='/issuer/:id' component={IssuerDetails} />
      <Route exact path='/create-issue' component={CreateIssue} />
      <Route exact path='/create-issuer' component={CreateIssuer} />
      <Route exact path='/deals' component={Deals} />
      <Route exact path='/assets' component={Assets} />
      <Route exact path='/asset/:id' component={AssetDetails} />
      <Route exact path='/funds' component={Funds} />
      <Route exact path='/transaction/:id' component={TransactionDetails} />
      <Route exact path='/reporting' component={Reporting} />
      <Route exact path='/management' component={Management} />
      <Route exact path='/orders' component={Orders} />
      <Route exact path='/order/:id' component={OrderDetails} />

      {/*TODO: temporary*/}
      <Route exact path='/constructor' component={Constructor} />
      <Route exact path='/constructor/questionnaires/:id' component={QuestionnairePage} />

      <Route exact path='/constructorDocuments' component={ConstructorDocuments} />
      <Route exact path='/constructorDocuments/sections/:id' component={DocumentPage} />

      <Route exact path='/constructorProducts' component={ConstructorProducts} />
      <Route exact path='/constructorProducts/:id' component={ProductPage} />

      <Redirect to={'/investors?type=PERSONAL'} />
    </Switch>
  );
};
