import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IssuesTableBoxView } from '../../../components';
import { entitiesActions } from '../../../store/entities';

const ISSUES_ON_PAGE = 4;
export const IssuesTableBox = ({ issuerId }) => {
  const dispatch = useDispatch();
  const { issues } = useSelector((state) => state.entities);

  useEffect(() => {
    fetchIssues();
  }, []);

  const fetchIssues = (params) => {
    dispatch(
      entitiesActions.getIssues({
        issuerId: issuerId,
        take: ISSUES_ON_PAGE,
        ...params
      })
    );
  };

  return (
    <IssuesTableBoxView issues={issues} fetchIssues={fetchIssues} ISSUES_ON_PAGE={ISSUES_ON_PAGE} />
  );
};
