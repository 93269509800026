import React, { useState } from 'react';
import { history } from '../../../../routing';
import { useDispatch } from 'react-redux';
import { userActions } from '../../../../store/user';
import { GU } from '@aragon/ui';
import { SupportSidePanel } from '../../../../components';
import { SideBarConfigInvestor, SideBarConfigAdmin, SideBarConfigSuperAdmin } from './config';
import { Body2Light, Col, Container, Button, Body3Light } from '../../../../ui';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

export const AppSideBar = ({ userRole }) => {
  const dispatch = useDispatch();

  const Logout = () => {
    dispatch(userActions.logout());
  };

  return (
    <SideBarContainer>
      {userRole === 'investor' && <InvestorList Logout={Logout} />}

      {userRole === 'admin' && <AdminList Logout={Logout} config={SideBarConfigAdmin} />}

      {userRole === 'superAdmin' && <AdminList Logout={Logout} config={SideBarConfigSuperAdmin} />}
    </SideBarContainer>
  );
};

const InvestorList = ({ Logout }) => {
  const { t } = useTranslation();
  const [openedSupport, setOppenedSupport] = useState(false);

  return (
    <StickyWrapper>
      <List>
        {SideBarConfigInvestor.map((item, key) => (
          <ListItem item={item} key={key} />
        ))}
      </List>

      <Col padding={3 * GU} auto>
        <Button label={t('Support')} onClick={() => setOppenedSupport(true)} wide />

        <Container marginTop={2 * GU}>
          <Button label={t('Logout')} wide onClick={Logout} />
        </Container>
      </Col>

      <SupportSidePanel opened={openedSupport} onClose={() => setOppenedSupport(false)} />
    </StickyWrapper>
  );
};

const AdminList = ({ Logout, config }) => {
  const { t } = useTranslation();
  return (
    <StickyWrapper>
      <List>{config && config.map((item, key) => <ListItem item={item} key={key} />)}</List>

      <Col padding={3 * GU} auto>
        <Button label={t('Logout')} wide onClick={Logout} />
      </Col>
    </StickyWrapper>
  );
};

const ListItem = ({ item, pathname }) => {
  const { t } = useTranslation();

  return (
    <div>
      <Li onClick={() => history.push(item.href)} active={pathname === item.href}>
        {item.icon && <Icon src={item.icon} />}
        <Body2Light paddingTop={'3'}>{t(item.title)}</Body2Light>
      </Li>
      <Container paddingLeft={52}>
        {item.subpages &&
        item.subpages.map((subpage, key) => (
          <Li
            onClick={() => history.push(subpage.href)}
            active={pathname === subpage.href}
            // justifyContent={'flex-end'}
            height={32}
            key={key}
          >
            {/*<Icon src={subpage.icon} />*/}
            <Body3Light paddingTop={'3'}>{t(subpage.title)}</Body3Light>
          </Li>
        ))}
      </Container>
    </div>
  );
};

const SideBarContainer = styled.div`
  width: 222px;
  height: 100%;
  background: #ffffff;
  box-shadow: 2px 0px 3px rgba(0, 0, 0, 0.05);
`;
const StickyWrapper = styled.div`
  height: calc(100vh - 65px);
  position: sticky;
  top: 65px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 28px 0 17px 0;
`;
const List = styled.div``;
const Li = styled.div`
  min-height: ${(p) => (p.height ? `${p.height}px` : '40px')};
  width: 100%;
  padding: 0 24px;
  display: flex;
  align-items: center;
  justify-content: ${(p) => (p.justifyContent ? p.justifyContent : 'flex-start')};
  cursor: pointer;
  transition: 0.2s ease-out;

  ${({ active }) =>
  active
    ? `
    background: #F9FAFC;
    box-shadow: 2px 0px 0px 0px #08BEE5 inset;
  `
    : ``}
  &:hover {
    background: #f9fafc;
    box-shadow: 2px 0px 0px 0px #08bee5 inset;
  }
`;
const Icon = styled.img`
  width: 24px;
  height: 24px;
  margin-right: ${2 * GU}px;
`;
