import React from 'react';
import { useParams } from 'react-router-dom';
import { useMutation, useQueries, useQuery } from 'react-query';
import { adminKYCApi } from '../../../api/admin/kyc';
import { ProfileDocuments as RenderProfileDocuments } from '../../../components/investor/organisms/ProfileDocuments';
import { Loading } from '../../../components';

export const InvestorDocuments = () => {
  const { id } = useParams();
  const { data, refetch, isLoading } = useQuery(
    ['getDocumentRequests'],
    () => adminKYCApi.getDocumentRequests(id),
    { refetchInterval: 5000 }
  );
  const { data: questsDocs } = useQuery(['getQuestsDocuments'], () =>
    adminKYCApi.getQuestsDocuments(id)
  );

  if (isLoading) return <Loading/>;

  if (!isLoading) {
    return (
      <RenderProfileDocuments
        documents={data?.data}
        questsDocs={questsDocs?.data}
        refetchDocs={refetch}
        viewMode={'admin'}
        investorId={id}
      />
    );
  }

  return null;
};
