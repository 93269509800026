import { COUNTRIES } from '../../static';
import { dateFromDatePicker, getFileSrc } from '../common';
import { history } from '../../../routing';

export const formatPaymentToEdit = (payment) => {
  const { addresses, bankAccountScans, ...paymentProps } = payment;
  const { country, ...bankAddress } = addresses[0];

  return {
    ...paymentProps,
    country: COUNTRIES.indexOf(country),
    ...bankAddress,
    bankAccountScanUrl: getFileSrc(bankAccountScans[0].documentURL),
    bankAccountScan: null,
    edited: true
  };
};

export const goToRegistration = (investor) => {
  const state = getState(investor);
  history.push(`/registration/investor/${state.step}`, state);
};

const getState = (investor) => {
  if (!investor.conditionsRegistration.verifiedEmail)
    return { step: 2, email: investor.email, account: investor.account };
  if (!investor.conditionsRegistration.verifiedPhone)
    return { step: 3, phone: investor.phone, account: investor.account };
  if (!investor.lastName) return { step: 4 };
  if (!investor.temporaryResidentship) return { step: 5 };
};

export const formatInvestorObject = (investor) => {
  let accountRole = '';
  let referralCode = '';
  if (investor.isRecommender) {
    accountRole = 'RECOMMENDER';
    referralCode = investor.referralCodeRecommender;
  }
  if (investor.isAgent) {
    accountRole = 'AGENT';
    referralCode = investor.referralCodeAgent;
  }



  return { ...investor, accountRole, referralCode };
};

export const calcAdditionalInfo = (questionnaire) => {
  try {
    const result = [];
    let questionsBlock = [];

    questionnaire.questionSections.forEach((questionSection) => {
      questionSection.questionGroups.forEach((questionGroup) => {
        questionGroup.questions.forEach((question) => {
          let resultAnswer = '';
          const thisAnswer = question?.answers && question?.answers[0];

          if (thisAnswer) {
            switch (question.type) {
              case 'DOC_UPLOAD':
              case 'RECORD_VIDEO':
              case 'SIGNATURE':
              case 'CONFIRMATIONS': {
                break;
              }
              case 'RADIO': {
                resultAnswer = question.availableAnswers?.filter(item => item.id === thisAnswer.availableAnswerId)[0]?.answer || '';
                break;
              }
              case 'CHECKBOX': {
                const answers = question?.answers;

                if (answers && answers.length > 0) {
                  const availableAnswersObj = {};
                  question.availableAnswers.forEach(item => {
                    availableAnswersObj[item.id] = item;
                  });

                  answers.forEach(item => {
                    const answer = availableAnswersObj[item.availableAnswerId].answer;
                    resultAnswer ? resultAnswer += `, ${answer}` : resultAnswer = answer;
                  });
                }

                break;
              }
              case 'DATE': {
                resultAnswer = dateFromDatePicker(thisAnswer.answer);
                break;
              }
              case 'ADDRESS': {
                const address = JSON.parse(thisAnswer.answer);
                resultAnswer = `${address.Country}, ${address.City}, ${address.StreetName}, ${address.HouseNumber}, ${address.ApartmentNumber}`;
                break;
              }
              case 'PHONE': {
                resultAnswer = thisAnswer.answer?.replace('/', '') || '';
                break;
              }
              default: {
                resultAnswer = thisAnswer.answer;
                break;
              }
            }
          }
          questionsBlock.push({
            title: question.question,
            answer: resultAnswer,
            type: question.type || null
          });
        });
      });
      result.push({sectionName: questionSection.section, answers: questionsBlock});
      questionsBlock = [];
    });

    return {
      status: questionnaire.status,
      id: questionnaire.investorQuestionSectionTypeId,
      answers: result
    };
  } catch (e) {
    console.error(e);
  }
};
