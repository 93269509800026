import { makeStyles } from '@material-ui/styles';
import { GU } from '@aragon/ui';

const useStyles = makeStyles({
  title: {
    marginBottom: `${7 * GU}px`
  },
  info: {
    // marginTop: `${3*GU}px`,
    textAlign: 'left'
  },
  backButton: {
    marginTop: `${4 * GU}px`,
    width: `${12 * GU}px`,
    height: `${5 * GU}px`,
    border: (theme) => `1px solid ${theme.border}`,
    boxShadow: ' 0 1px 3px rgba(0,0,0,0.1)',
    padding: 0,
    justifyContent: 'center',
    '& > span': {
      fontWeight: 400
    }
  },
  'login-title': {
    marginTop: `${8 * GU}px`,
    fontWeight: 300,
    color: (theme) => `${theme.contentSecondary}`
  }
});

export default useStyles;
