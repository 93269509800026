import React from 'react';
import { GU, RadioGroup as RG } from '@aragon/ui';
import { Radio } from '../../../components';
import styled from 'styled-components';
import { useController } from 'react-hook-form';
import { Row, Col } from '../../../ui/layout';
import { Body3Light } from '../../../ui/typography';
import { COLORS } from '../../../utils/static/colors';

const defaultRowProps = { margin: -31, colsMarginBottom: GU, flexDirection: 'column' };

export const RadioGroupForm = ({
  form,
  items,
  ids,
  rowProps = defaultRowProps,
  disabled,
  ...props
}) => {
  const {
    field: { ref, ...field },
    fieldState: { error }
  } = useController({
    ...form,
    defaultValue: ''
  });
  const {onChange, value, ...otherField} = field;

  const handleChange = (newValue) => {
    onChange(newValue === value ? '' : newValue);
  };

  return (
    <div>
      <StyledRG {...props} {...otherField} onChange={handleChange} selected={value}>
        <Row {...rowProps}>
          {items?.map((label, key) => {
            const radioId = (ids && ids[key]) || label.toLowerCase();

            return (
              <Col auto key={key}>
                <Radio label={label} id={radioId} disabled={disabled} />
              </Col>
            );
          })}
        </Row>
      </StyledRG>

      {error && (
        <Body3Light color={COLORS.red} marginTop={1.5 * GU}>
          {error.message}
        </Body3Light>
      )}
    </div>
  );
};

export const RadioGroup = ({ items, ids, rowProps = defaultRowProps, ...props }) => {
  return (
    <StyledRG {...props}>
      <Row {...rowProps}>
        {items?.map((label, key) => {
          const radioId = (ids && ids[key]) || label.toLowerCase();

          return <Radio key={key} label={label} id={radioId} />;
        })}
      </Row>
    </StyledRG>
  );
};

const StyledRG = styled(RG)``;
