import React, { useState } from 'react';
import { GU } from '@aragon/ui';
import { TableBoxContainer, SearchInput, InstrumentsTable } from '../../../../components';
import { Col, Row, ExportButton } from '../../../../ui';

export const AssetsTableBox = ({ assets, ASSETS_ON_PAGE, fetchAssets }) => {
  const [selected, setSelected] = useState(0);
  const [search, setSearch] = useState('');

  const refetchAssets = (selectedPage = selected) => {
    fetchAssets({
      search: search,
      skip: selectedPage * ASSETS_ON_PAGE
    });
  };

  const onUpdateSearch = () => {
    refetchAssets(0);
    setSelected(0);
  };

  const handleSearchKeyDown = (e) => {
    if (e.key === 'Enter') {
      onUpdateSearch();
    }
  };

  const onUpdatePage = (value) => {
    refetchAssets(value);
    setSelected(value);
  };

  if (assets) {
    return (
      <TableBoxContainer
        title={'Assets'}
        innerBoxProps={{ paddingBottom: GU }}
        aside={
          <Row margin={-GU}>
            <Col>
              <SearchInput
                value={search}
                onChange={setSearch}
                onBlur={onUpdateSearch}
                onKeyDown={handleSearchKeyDown}
                placeholder={'Search'}
              />
            </Col>

            <Col>
              <ExportButton />
            </Col>
          </Row>
        }
        table={<InstrumentsTable data={assets?.assets} />}
        paginationSettings={{
          visible: assets.count > ASSETS_ON_PAGE,
          selected: selected,
          pages: Math.ceil(assets.count / ASSETS_ON_PAGE),
          onChange: onUpdatePage
        }}
      />
    );
  }

  return null;
};
