import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { assetsActions } from '../../../../store/assets';
import { MyAssets } from '../../../../pages/investor';

const assetsInTable = 5;

export const MyAssetsContainer = () => {
  const dispatch = useDispatch();
  const { myAssets, filters } = useSelector((state) => state.assets);

  const fetchMyAssets = (params) => {
    dispatch(
      assetsActions.getMyAssets({
        take: assetsInTable,
        ...params
      })
    );
  };

  const saveFilters = (filters) => {
    dispatch(assetsActions.setFilters(filters));
  };

  return (
    <MyAssets
      myAssets={myAssets}
      assetsInTable={assetsInTable}
      updateFilters={fetchMyAssets}
      savedFilters={filters}
      saveFilters={saveFilters}
    />
  );
};
