import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { entitiesActions } from '../../../../../store/entities';
import { IssuesView } from '../View';

const ISSUERS_ON_PAGE = 4;

export const Issues = () => {
  const dispatch = useDispatch();
  const { issuers } = useSelector((state) => state.entities);

  useEffect(() => {
    fetchIssuers();
  }, []);

  const fetchIssuers = (params) => {
    dispatch(
      entitiesActions.getIssuers({
        take: ISSUERS_ON_PAGE,
        ...params
      })
    );
  };

  return (
    <IssuesView issuers={issuers} fetchIssuers={fetchIssuers} ISSUERS_ON_PAGE={ISSUERS_ON_PAGE} />
  );
};
