import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Button, Checkbox, Input, Modal, Radio } from 'antd';

import { AddCondition } from './AddCondition';
import { useQuestionnaireAnswer, useRelatedQuestionnaire, useRelatedSections } from '../hooks';
import { AddQuestions } from './AddQuestions';
import { Label, Space } from '../../constructorCommon/styled';


export const AddTrigger = ({ parentQuestionnaireId, questionSectionTypeId, closeModalCheckTrigger, closeTrigger }) => {
  const { t } = useTranslation();

  const { handleGet } = useRelatedSections();
  const {handleCreate} = useRelatedQuestionnaire(questionSectionTypeId);
  const [sections, setSections] = useState([]);
  const [groupId, setGroupId] = useState(null);
  const [conditions, setConditions] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isModalAddConditionOpened, setIsModalAddConditionOpened] = useState(false);
  const [isModalAddQuestionOpened, setIsModalAddQuestionOpened] = useState(false);

  const closeModalAddCondition = (newConditions, groupObj) => {
    setConditions(newConditions);
    setGroupId(groupObj.id);
    setIsModalAddConditionOpened(false);
  }

  const closeModalAddQuestionOpened = (questionSource, questionTarget) => {
    if (!groupId) setGroupId(questionSource.questionGroupId);

    const questionsArray = questions;
    const exists = questionsArray.some(question =>
      question.source === questionSource.id && question.target === questionTarget.id
    );
    if (!exists) {
      const newQuestions = [...questionsArray, { source: questionSource, target: questionTarget }];
      setQuestions(newQuestions);
    }
    setIsModalAddQuestionOpened(false);
  }

  const fetchData = async () => {
    setLoading(true);
    const res = await handleGet(parentQuestionnaireId);
    if (res?.data) {
      setSections(res.data);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [parentQuestionnaireId]);

  const onCreate = () => {

    console.log('conditions', conditions);
    console.log('questions', questions);
    console.log('groupId', groupId);

    let arrayConditions = [];
    let arrayQuestions = [];

    if (conditions.length){
      arrayConditions = conditions.map(condition => condition.id);
    }
    if (questions.length){
      arrayQuestions = questions.map(question => {
        return {
          target: question.target.id,
          source: question.source.id
        }
      });
    }
    console.log('arrayQuestions',arrayQuestions)


    handleCreate(questionSectionTypeId, groupId, arrayConditions, arrayQuestions).then(res => {
      console.log('res?.data', res)
      closeTrigger();
      closeModalCheckTrigger();
    });
  }
  const onAddCondition = () => {
    setIsModalAddConditionOpened(true);
  }

  const onAddQuestion = () => {
    setIsModalAddQuestionOpened(true);
  }

  if (loading) return (
    <div>Loading...</div>
  );
  return (
    <Container>
      <ButtonContainer>
        <Button onClick={onAddCondition} type="primary" style={{marginRight: '20px' }}>
          Add Condition (Available Answer)
        </Button>
        <Button onClick={onAddQuestion} type="primary">
          Add Question
        </Button>
      </ButtonContainer>
      <ScrollBox>
        {conditions.map((condition, conditionIndex) => (
          <div key={conditionIndex}>
            <Label>condition {conditionIndex + 1}</Label>
            <Input name={`condition-${conditionIndex}`} value={condition.question?.question}/>
            <Input name={`answer-${conditionIndex}`} value={condition.answer}/>
          </div>
        ))}
        <Space/>
        {questions.map((question, questionIndex) => (
          <QuestionPair key={questionIndex}>
            <div>
              <Label>questionSource {questionIndex + 1}</Label>
              <Input name={`questionSource-${questionIndex}`} value={question.source?.question}/>
            </div>
            <div>
              <Label>questionTarget {questionIndex + 1}</Label>
              <Input name={`questionTarget-${questionIndex}`} value={question.target?.question}/>
            </div>
          </QuestionPair>
        ))}
        <Modal
          title={'Add condition'}
          width={1400}
          style={{ top: 20 }}
          okText={'Add'}
          footer={null}
          open={isModalAddConditionOpened}
          onCancel={() => setIsModalAddConditionOpened(false)}
        >
          <AddCondition parentQuestionnaireId={parentQuestionnaireId} questionSectionTypeId={questionSectionTypeId}
                        closeModalCheckTrigger={closeModalCheckTrigger} closeModalAddCondition={closeModalAddCondition}
                        availableAnswer={conditions}/>
        </Modal>
        <Modal
          title={'Add question'}
          width={1400}
          style={{ top: 20 }}
          okText={'Add'}
          footer={null}
          open={isModalAddQuestionOpened}
          onCancel={() => setIsModalAddQuestionOpened(false)}
        >
          <AddQuestions parentQuestionnaireId={parentQuestionnaireId} questionSectionTypeId={questionSectionTypeId}
                        closeModalCheckTrigger={closeModalCheckTrigger}
                        closeModalAddQuestionOpened={closeModalAddQuestionOpened} availableAnswer={conditions}/>
        </Modal>
      </ScrollBox>
      <ButtonContainer>
        <Button onClick={onCreate} type="primary"
                disabled={!questions.length > 0 && !conditions.length > 0}
                style={{ marginTop: '30px' }}>
          Create trigger
        </Button>
      </ButtonContainer>
    </Container>
  );
};

const Container = styled.div`
  padding: 20px;
`
const ScrollBox = styled.div`
  height: 700px;
  overflow-y: scroll;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 15px;
  background-color: #f9f9f9;
`
const ButtonContainer = styled.div`
  text-align: right;
`
const QuestionPair = styled.div`
display: flex;
justify-content: space-between;
margin-bottom: 15px;
`
