import React from 'react';
import styled from 'styled-components';
import { Checkbox as CheckboxAragon, GU } from '@aragon/ui';

export const Checkbox = ({ label, marginBottom, ...props }) => {
  return (
    <CheckboxWrapper marginBottom={marginBottom}>
      <CheckboxAragon {...props} />
      <span>{label}</span>
    </CheckboxWrapper>
  );
};

const CheckboxWrapper = styled.label`
  display: flex;
  align-items: center;
  ${(p) => (p.marginBottom ? `margin-bottom: ${p.marginBottom}px;` : '')}

  > button {
    min-width: 18px;
    border: 1px solid #c5c5c5;
  }
  & > span {
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 22px;
    color: #212b36;
    margin-left: ${GU}px;
  }
`;
