import React from 'react';
import { Box } from '../../../customAragon';
import { Body2Light, Body3Light, Button, Col, Row, Container, Text, Title5 } from '../../../../ui';
import styled from 'styled-components';
import { API_URL, COLORS } from '../../../../utils/static';
import { displayFullDate } from '../../../../utils/helpers';
import {
  STATUS_BUTTON_MODE,
  STATUS_COLOR,
  STATUS_DISPLAY, STATUS_NEED_CHECKING
} from '../../../../utils/static/kyc';
import { useTranslation } from 'react-i18next';

export const ProductBox = (
  {
    data,
    number,
    title = '',
    status = '',
    error = '',
    enabled,
    onClick,
    onClickEdit,
    onClickCheck,
    adminView,
    ...props
  }) => {
  const { t, i18n } = useTranslation();
  const boxTitle = title || data?.name;
  const boxStatus = status || data?.status;
  const boxEnabled = enabled || data?.enabled;
  const boxError = error || data?.error;

  return (
    <StyledBox {...props} active={boxEnabled} padding={0} bgColor={STATUS_COLOR[boxStatus]}>
      {data?.logoURL && <ImgWrapper>
        <Img src={`${API_URL}/${data.logoURL}`} alt="" />
        <ImgLayover />
      </ImgWrapper>}
      <Row padding={24} nowrap height={data?.logoURL ? 'auto' : '100%'}>
        {number && (
          <Col auto>
            <NumberCircle active={boxEnabled}>{number}</NumberCircle>
          </Col>
        )}
        <Col auto style={{ overflow: 'hidden' }}>
          <Row flexDirection={'column'} justifyContent={'space-between'} nowrap height={'100%'}>
            <Col>
              <Title weight={300} color={COLORS.default} marginBottom={16}>
                {boxTitle}
              </Title>
            </Col>

            <Container>
              {boxError && (
                <Body3Light marginBottom={10}>
                  {t('Comment')}:{' '}
                  <Text color={COLORS.red} fontWeight={600} textInline>
                    {boxError}
                  </Text>
                </Body3Light>
              )}

              <Body2Light>
                {t('Status')}:{' '}
                <Text color={STATUS_COLOR[boxStatus] || COLORS.aragonBlue} textInline fontWeight={700}>
                  {status || t(STATUS_DISPLAY[boxStatus])}
                </Text>
              </Body2Light>
              {data?.person && (<Body2Light>
                {t('Person')}:{' '}
                <Text textInline fontWeight={400}>
                  {data.person}
                </Text>
              </Body2Light>)}
              {data?.company && (<Body2Light>
                {t('Company')}:{' '}
                <Text textInline fontWeight={400}>
                  {data.company}
                </Text>
              </Body2Light>)}

              {data?.interestRate && (<Body2Light>
                {t('Interest rate')}:{' '}
                <Text textInline fontWeight={400}>
                  {data.interestRate}
                </Text>
              </Body2Light>)}
              {data?.minimalAmount && (<Body2Light>
                {t('Investment amount')}:{' '}
                <Text textInline fontWeight={400}>
                  {data.minimalAmount}
                </Text>
              </Body2Light>)}
              {data?.term && (<Body2Light>
                {t('Term')}:{' '}
                <Text textInline fontWeight={400}>
                  {data.term}
                </Text>
              </Body2Light>)}
              {adminView && data?.onlyAdmin && <Body2Light>
                {t('Only admin')}:{' '}
                <Text textInline fontWeight={400}>
                  {data.onlyAdmin ? 'true' : '-'}
                </Text>
              </Body2Light>}

              <Container marginTop={16}>
                <Container style={{ display: 'flex', gap: '16px' }}>
                  <Button
                    label={t('Open')}
                    onClick={onClick}
                    size={'small'}
                    mode={STATUS_BUTTON_MODE[boxStatus] || 'strong'}
                    fontWeight={300}
                    disabled={adminView ? false : !boxEnabled}
                    width={94}
                  />
                  {adminView &&
                    <Button
                      label={t('Edit')}
                      onClick={onClickEdit}
                      size={'small'}
                      mode={'strong'}
                      fontWeight={300}
                      disabled={false}
                      width={94}
                    />
                  }
                  {boxStatus === STATUS_NEED_CHECKING &&
                    <Button
                      label={t('Check')}
                      onClick={onClickCheck}
                      size={'small'}
                      mode={'yellow'}
                      fontWeight={300}
                      disabled={false}
                      width={94}
                    />
                  }
                </Container>
              </Container>
            </Container>
          </Row>
        </Col>
      </Row>
    </StyledBox>
  );
};

const StyledBox = styled(Box)`
  border-radius: 12px;
  overflow: hidden;
  height: 100%;
  // filter: drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.12));
  // filter: drop-shadow(8px 8px 16px rgba(0, 0, 0, 0.08));
  filter: drop-shadow(8px 12px 16px rgba(0, 0, 0, 0.08));
  border: none;

  :after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    height: 6px;
    width: 100%;
    // background: ${p => p.active ? COLORS.aragonBlue : '#F1F3F7'};
    background: ${p => p.bgColor ? p.bgColor : COLORS.aragonBlue};
  }

  > div,
  > div > div {
    height: 100%;
  }
`;
const Title = styled(Title5)`
  // white-space: nowrap;
  // overflow: hidden;
  // text-overflow: ellipsis;
  font-weight: 400;
`;
const NumberCircle = styled.div`
  width: 24px;
  height: 24px;
  padding: 0 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  flex-shrink: 0;
  flex-grow: 0;
  font-size: 14px;
  font-weight: 300;
  color: ${COLORS.white};
  background: ${(p) =>
  p.active ? 'linear-gradient(200.38deg, #32FFF5 -153.94%, #08BEE5 84.51%)' : COLORS.greyLight};
`;
const ImgWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 180px;
  overflow: hidden;
`;
const ImgLayover = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255,255,255,0.1);
`;
const Img = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;