import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { ProfilePaymentsView } from '../../../components';
import { newObject, formatPaymentToEdit } from '../../../utils/helpers';

export const InvestorPayments = ({ editPayments, onSaveChanges }) => {
  const { paymentDetails } = useSelector((state) => state.investor);
  const [editedPayments, setEditedPayments] = useState(
    paymentDetails.map((item) => formatPaymentToEdit(item))
  );

  const ChangeData = (key, value, selected) => {
    const index = selected - paymentDetails.length;
    let clone = [...editedPayments];
    clone[index] = newObject(editedPayments[index], key, value);
    setEditedPayments(clone);
  };

  return (
    <ProfilePaymentsView
      payments={editPayments ? editedPayments : paymentDetails}
      ChangeData={ChangeData}
      onSave={onSaveChanges}
    />
  );
};
