import React, { useEffect, useState } from 'react';
import { GU } from '@aragon/ui';
import { Row, Col, Container, DropDown, Body3Light, SearchInput } from '../../../../ui';
import { newObject } from '../../../../utils/helpers';
import {
  ORDER_TYPES,
  ORDER_TYPES_LIST,
  ASSET_TYPES,
  ASSET_TYPES_LIST,
  VALID_TILL_OPTIONS,
  COLORS,
  USER_TYPES_LIST_DISPLAY,
  CURRENCIES,
  MIFID_STATUSES,
  DISPLAY_MIFID_STATUS
} from '../../../../utils/static';

export const AdminOrdersHistoryFilters = ({
  filters,
  searchFilters,
  changeFilters,
  changeSearchFilters,
  updateFilters,
  ...props
}) => {
  return (
    <Container {...props}>
      <Row cols={4} margin={-GU}>
        <Col marginBottom={2 * GU}>
          <SearchInput
            value={searchFilters.client}
            onChange={(val) => changeSearchFilters('client', val)}
            handleSearch={updateFilters}
            placeholder={'Client'}
            wide
          />
        </Col>

        <Col marginBottom={2 * GU}>
          <SearchInput
            value={searchFilters.account}
            onChange={(val) => changeSearchFilters('account', val)}
            handleSearch={updateFilters}
            placeholder={'Account'}
            wide
          />
        </Col>

        <Col marginBottom={2 * GU} width={'50%'}>
          <SearchInput
            value={searchFilters.instrument}
            onChange={(val) => changeSearchFilters('instrument', val)}
            handleSearch={updateFilters}
            placeholder={'Instrument (ISIN)'}
            wide
          />
        </Col>
      </Row>

      <Row cols={4} margin={-GU}>
        <Col marginBottom={2 * GU}>
          <DropDown
            items={['All user types', ...USER_TYPES_LIST_DISPLAY]}
            selected={filters.userTypeIndex}
            onChange={(index) => changeFilters('userTypeIndex', index)}
            placeholder={<Body3Light color={COLORS.iconColor}>User type</Body3Light>}
            width='100%'
          />
        </Col>

        <Col marginBottom={2 * GU}>
          <DropDown
            items={['All security types', ...ASSET_TYPES_LIST.map((item) => ASSET_TYPES[item])]}
            selected={filters.securityTypeIndex}
            onChange={(index) => changeFilters('securityTypeIndex', index)}
            placeholder={<Body3Light color={COLORS.iconColor}>Security type</Body3Light>}
            width='100%'
          />
        </Col>

        <Col marginBottom={2 * GU}>
          <DropDown
            items={['All order types', ...ORDER_TYPES_LIST.map((item) => ORDER_TYPES[item])]}
            selected={filters.orderTypeIndex}
            onChange={(index) => changeFilters('orderTypeIndex', index)}
            placeholder={<Body3Light color={COLORS.iconColor}>Order type</Body3Light>}
            width='100%'
          />
        </Col>

        <Col marginBottom={2 * GU}>
          <DropDown
            items={['All', ...VALID_TILL_OPTIONS]}
            selected={filters.validTillIndex}
            onChange={(index) => changeFilters('validTillIndex', index)}
            placeholder={<Body3Light color={COLORS.iconColor}>Valid till</Body3Light>}
            width='100%'
          />
        </Col>
      </Row>

      <Row cols={4} margin={-GU}>
        <Col marginBottom={2 * GU}>
          <SearchInput
            value={searchFilters.residence}
            onChange={(val) => changeSearchFilters('residence', val)}
            handleSearch={updateFilters}
            placeholder={'Residence'}
            wide
          />
        </Col>

        <Col marginBottom={2 * GU}>
          <SearchInput
            value={searchFilters.citizenship}
            onChange={(val) => changeSearchFilters('citizenship', val)}
            handleSearch={updateFilters}
            placeholder={'Citizenship'}
            wide
          />
        </Col>

        <Col marginBottom={2 * GU}>
          <DropDown
            items={['All', ...CURRENCIES]}
            selected={filters.currencyIndex}
            onChange={(index) => changeFilters('currencyIndex', index)}
            placeholder={<Body3Light color={COLORS.iconColor}>Currency</Body3Light>}
            width='100%'
          />
        </Col>

        <Col marginBottom={2 * GU}>
          <DropDown
            items={['All', ...MIFID_STATUSES.map((item) => DISPLAY_MIFID_STATUS[item])]}
            selected={filters.mfidStatusIndex}
            onChange={(index) => changeFilters('mfidStatusIndex', index)}
            placeholder={<Body3Light color={COLORS.iconColor}>MFID status</Body3Light>}
            width='100%'
          />
        </Col>
      </Row>
    </Container>
  );
};
