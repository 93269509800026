import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { investorApi } from '../../../../../api/investor';
import { investorActions } from '../../../../../store/investor';
import { entitiesActions } from '../../../../../store/entities';
import styled from 'styled-components';
import { GU } from '@aragon/ui';
import {
  RegContainer,
  StepsPanel,
  FooterButtons,
  OptionCard,
  Info,
  ConnectMetamask
} from '../../../../../components';
import { individualSteps } from '../../../../../utils/static';
import { Title2, Row, Container } from '../../../../../ui';

export const Step5 = ({ stepNumber, goBack, goNext }) => {
  const dispatch = useDispatch();
  const [connect, setConnect] = useState(true);
  const [walletAddress, setWalletAddress] = useState('');

  const handleNext = async () => {
    try {
      dispatch(entitiesActions.showLoader('Processing...'));

      const res = await investorApi.addEthereumAddress(walletAddress);

      if (res.status === 200) {
        dispatch(investorActions.setEthereumAddress(walletAddress));
        goNext();
      }
    } catch (e) {
      console.error('setEthereumAddress error', e);
      const errorMessage = e.response.data.message;
      if (errorMessage === 'ETHEREUM_ADDRESS_EXIST') {
        dispatch(entitiesActions.addToast('This wallet is already taken'));
      }
    } finally {
      dispatch(entitiesActions.hideLoader());
    }
  };

  return (
    <StepsPanelContainer>
      <StepsPanel title={'Registration'} step={stepNumber} steps={individualSteps} />

      <RegContainer type='centered'>
        <Title onClick={goNext}>Connect wallet</Title>

        {!connect ? (
          <Row>
            <OptionCard
              title={'Connect wallet'}
              className={'col-auto'}
              backgroundColor={'#FFD48C'}
              onClick={() => setConnect(true)} //ask about it
            />

            <OptionCard
              title={'Use SSI'}
              className={'col-auto'}
              backgroundColor={'#08BEE5'}
              onClick={() => {}}
            />
            <Container marginY={GU * 4}>
              <Info title='How to create a wallet?'>
                Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit
                officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud
                amet. Download Metamask
              </Info>
            </Container>
          </Row>
        ) : (
          <Row>
            <ConnectMetamask walletAddress={walletAddress} setWalletAddress={setWalletAddress} />

            <Container marginY={GU * 4}>
              <Info title='Manual'>
                Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit
                officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud
                amet. Download Metamask
              </Info>
            </Container>
          </Row>
        )}

        <FooterButtons onBack={goBack} onNext={handleNext} nextDisabled={!walletAddress} hideBack />
      </RegContainer>
    </StepsPanelContainer>
  );
};

const StepsPanelContainer = styled.div`
  display: flex;
`;
const Title = styled(Title2)`
  font-weight: 300;
  margin-bottom: ${7 * GU}px;
`;
