import { investorApi } from '../../../api/investor';
import { walletReducer } from '../../../store/wallet';

export const getTransactions = (params) => (dispatch) => {
  investorApi.getTransactions(params).then((res) => {
    if (res.status === 200) {
      dispatch(walletReducer.setTransactions(res.data));
    }
  });
};

export const setFilters = (filters) => (dispatch) => {
  dispatch(walletReducer.setFilters(filters));
};
export const removeFilters = () => (dispatch) => {
  dispatch(walletReducer.setFilters(null));
};

export const setNewTransfer = (data) => (dispatch) => {
  dispatch(walletReducer.setNewTransfer(data));
};

export const removeNewTransfer = () => (dispatch) => {
  dispatch(walletReducer.setNewTransfer(null));
};

export const getTransaction = (id, transactions) => async (dispatch) => {
  if (transactions) {
    const currentTransaction = transactions.filter((item) => item.id === id)[0];
    const currency =
      currentTransaction.fromPersonalAccount?.currency ||
      currentTransaction.toPersonalAccount?.currency;

    dispatch(walletReducer.setCurrentTransaction({ currency: currency, ...currentTransaction }));
  } else {
    try {
      const res = await investorApi.getTransactions();
      const transactions = res.data.transactions;

      dispatch(getTransaction(id, transactions));
    } catch (e) {
      console.log('getTransactions', e);
    }
  }
};
