import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { dealsActions } from '../../../../store/deals';
import { DealDetails } from '../../../../pages/investor';

export const DealDetailsContainer = () => {
  const dispatch = useDispatch();
  let { id } = useParams();
  const { currentDeal, deals } = useSelector((state) => state.deals);
  const { investor } = useSelector((state) => state);

  useEffect(() => {
    dispatch(dealsActions.getCurrentDeal(id, deals?.deals));
  }, []);

  return <DealDetails currentDeal={currentDeal} investor={investor} />;
};
