import { useTranslation } from 'react-i18next';
import { Button, Typography } from 'antd';
import React, { useState, useEffect } from 'react';
import { history } from '../../../routing';
import { Container, Title2 } from '../../../ui';
import { Box } from '../../../components';
import { useDocumentSections } from '../hooks';
import { Space } from '../styled';

export const DocumentSectionList = () => {
  const { t } = useTranslation();

  const [queryType, setQueryType] = useState('ALL');
  const { data, isLoading } = useDocumentSections();

  const handleChange = (event) => {
    setQueryType(event.target.value);
  };

  return (
    <>
      <Title2 marginBottom={30}>
        {t('Document Section List')}
      </Title2>
      <Space/>

      {isLoading && <div>Loading...</div>}

      {data?.map((section) => (
        <Box heading={section.type} padding={16}>
          {section.name && <Typography.Title level={5}>{section.name}</Typography.Title>}

          <Container marginTop={12}>
            <Button
              mode={'strong'}
              onClick={() => history.push(`/constructorDocuments/sections/${section.id}`)}
              type='primary'
            >
              Edit
            </Button>
          </Container>
        </Box>
      ))}
    </>
  );
};
