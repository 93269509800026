import React, { useEffect, useState, useMemo } from 'react';
import { IconPlus } from '@aragon/ui';
import { Question } from '../Question';
import { Container, InnerBox, Body2, Button, TrashIcon } from '../../../../ui';
import styled from 'styled-components';
import {useFieldArray, useWatch} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { sortWithNulls } from '../../../../utils/helpers';

const createLinkFromText = (text) => {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  const parts = text.split(urlRegex);
  return parts.map((part, index) =>
    urlRegex.test(part) ? (
      <a href={part} key={index} target="_blank" rel="noopener noreferrer">
        {part}
      </a>
    ) : (
      <span key={index}>{part}</span>
    )
  );
};
export const QuestionGroup = ({ questionGroup, watch, preFilledQuestions, control, ...props }) => {
  const [visible, setVisible] = useState(true);
  const watchedFields = useWatch({
    control,
    name: questionGroup?.visibleConditions ? questionGroup?.visibleConditions?.map((item) => item.id) : []
  });

  //isVisible logic
  useEffect(() => {
    const conditions = questionGroup.visibleConditions;
    const operator = questionGroup.visibleOperator;

    if (conditions && Array.from(conditions).length > 0) {
      let isVisible = visible;

      if (operator === 'AND') {
        isVisible = true;
        for (const [index, value] of watchedFields.entries()) {
          if ((Array.isArray(value) ? !value.includes(conditions[index].value) : conditions[index].value !== value)) {
            isVisible = false;
            break;
          }
        }
      }

      if (operator === 'OR') {
        isVisible = false;
        for (const [index, value] of watchedFields.entries()) {
          if ((Array.isArray(value) ? value.includes(conditions[index].value) : conditions[index].value === value)) {
            isVisible = true;
            break;
          }
        }
      }

      if (operator === 'NOT') {
        isVisible = true;
        for (const [index, value] of watchedFields.entries()) {
          if (Array.isArray(value) ? value.includes(conditions[index].value) : conditions[index].value === value) {
            isVisible = false;
            break;
          }
        }
      }

      if (visible !== isVisible) setVisible(isVisible);
    }
  }, [watchedFields, questionGroup]);

  if (visible) {
    if (!questionGroup.isMultiple) {
      return (
        <QuestionGroupWrapper paddingY={24}>
          {questionGroup.title && <Body2>{createLinkFromText(questionGroup.title)}</Body2>}

          {questionGroup.questions &&
          questionGroup.questions.sort((a, b) => sortWithNulls(Number(a.sortOrder), Number(b.sortOrder))).map((questionObj, index) => (
            <Question
              question={questionObj}
              watch={watch}
              groupVisibleConditions={questionGroup.visibleConditions}
              preFilledQuestions={preFilledQuestions}
              control={control}
              {...props}
              key={index}
            />
          ))}
        </QuestionGroupWrapper>
      );
    } else {
      return (
        <Multiple
          questionGroup={questionGroup}
          preFilledQuestions={preFilledQuestions}
          watch={watch}
          control={control}
          {...props}
        />
      );
    }
  }

  return null;
};

const Multiple = ({ questionGroup, watch, control, preFilledQuestions, ...props }) => {
  const { t } = useTranslation();
  const { fields, append, remove } = useFieldArray({
    control: control,
    name: `group_${questionGroup.id}`
  });

  const appendValues = useMemo(() => {
    const result = {};

    questionGroup.questions.forEach((item) => (result[item.id] = ''));

    return result;
  }, [questionGroup]);

  useEffect(() => {
    const multipleMin = questionGroup.multipleMin || 1;

    if (append && fields.length < multipleMin) {
      setImmediate(() => {
        append(new Array(multipleMin).fill({ ...appendValues }));
      });
    }
  }, [questionGroup, appendValues, append]);

  const multipleMin = useMemo(() => {
    return questionGroup?.multipleMin || 1;
  }, [questionGroup]);


  return (
    <div>
      <QuestionGroupWrapper paddingY={24}>
        {questionGroup.title && <Body2>{createLinkFromText(questionGroup.title)}</Body2>}

        {fields.map((field, fieldIndex) => {
            const groupPreFilledObj = preFilledQuestions[`group_${questionGroup.id}.${fieldIndex}`];

            return (
              <MultipleBox key={field.id} padding={'0'} lastBox={fields.length - 1 === fieldIndex}>
                {questionGroup.questions &&
                questionGroup.questions.map((questionObj, index) => (
                  <Container paddingY={8}>
                    <Question
                      question={questionObj}
                      watch={watch}
                      control={control}
                      groupVisibleConditions={questionGroup.visibleConditions}
                      name={`group_${questionGroup.id}.${fieldIndex}.${questionObj.id}`}
                      key={index}
                      preFilledQuestions={preFilledQuestions}
                      disableQuestion={groupPreFilledObj && groupPreFilledObj[questionObj.id]}
                      {...props}
                    />
                  </Container>
                ))}

                <DeleteButtonContainer>
                  <TrashIcon
                    onClick={() => remove(fieldIndex)}
                    disabled={groupPreFilledObj || fieldIndex < multipleMin}
                  />
                </DeleteButtonContainer>
              </MultipleBox>
            );
          }
        )}

        {!props.disabled && (
          <Button
            label={t('Add')}
            onClick={() => append({ ...appendValues })}
            icon={<IconPlus size={'small'} />}
            disabled={questionGroup.multipleMax && fields.length >= questionGroup.multipleMax}
          />
        )}
      </QuestionGroupWrapper>
    </div>
  );
};

const MultipleBox = styled(InnerBox)`
  // box-shadow:2px 2px 12px rgb(0 0 0 / 12%);
  // padding: 12px;
  // border-radius: 8px;
  position: relative;
  ${p => p.lastBox
  ? 'border: none;'
  : 'padding-bottom: 16px;'
}
`;
const QuestionGroupWrapper = styled(Container)`
  border-bottom: 1px solid #dde4e9;

  :last-child {
    padding-bottom: 0;
    border-bottom: unset;
  }

  > *:not(:last-child) {
    margin-bottom: 16px;
  }
`;
const DeleteButtonContainer = styled.div`
  position: absolute;
  top: -10px;
  right: 0;
  z-index: 2;
`;
