export const ELEMENT_TYPES = {
  input: {
    label: 'Input',
    value: 'INPUT'
  },
  address: {
    label: 'Address',
    value: 'ADDRESS'
  },
  date: {
    label: 'Date',
    value: 'DATE'
  },
  phone: {
    label: 'Phone',
    value: 'PHONE'
  },
  email: {
    label: 'Email',
    value: 'EMAIL'
  },
  radio: {
    label: 'Radio',
    value: 'RADIO'
  },
  checkbox: {
    label: 'Checkbox',
    value: 'CHECKBOX'
  },
  docUpload: {
    label: 'Doc upload',
    value: 'DOC_UPLOAD'
  },
  country: {
    label: 'Country',
    value: 'COUNTRY'
  },
  confirmations: {
    label: 'Confirmations',
    value: 'CONFIRMATIONS'
  },
  signature: {
    label: 'Signature',
    value: 'SIGNATURE'
  },
  text: {
    label: 'Text',
    value: 'TEXT'
  }
}