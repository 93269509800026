import React from 'react';
import { useTranslation } from 'react-i18next';

import { PageContainer } from '../../../ui';
import { Title1 } from '../../../ui';
import { Product, ProductsList } from '../../../features/constructorProducts';

export const ConstructorProducts = () => {
  const { t } = useTranslation();

  return (
    <PageContainer>
      <Title1 marginBottom={20}>
        {t('Constructor products')}
      </Title1>

      <ProductsList />
      <Product/>
    </PageContainer>
  );
};
