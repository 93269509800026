import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { GU, Text } from '@aragon/ui';
import { RegContainer, FooterButtons } from '../../../../components';
import {
  LinkButton,
  RegTitle,
  Input,
  Row,
  Col,
  DropDown,
  Button,
  Body3Light
} from '../../../../ui';
import styled from 'styled-components';
import { COLORS } from '../../../../utils/static/colors';

const CONTACT_TYPES = ['EMAIL', 'PHONE'];

export const SubmitTransferPage = ({ sendCode, submitTransfer }) => {
  const { email, phone } = useSelector((state) => state.investor);
  const [contactIndex, setContactIndex] = useState(0);
  const [code, setCode] = useState('');

  const handleSendCode = () => {
    sendCode(CONTACT_TYPES[contactIndex]);
  };

  const onSubmitTransfer = () => {
    submitTransfer(code);
    setCode('');
  };

  return (
    <RegContainer type='centered'>
      <RegTitle>Submit your transfer operation</RegTitle>

      <Row alignItems={'flex-end'} margin={-GU} marginBottom={5 * GU}>
        <Col>
          <DropDown
            selected={contactIndex}
            onChange={setContactIndex}
            items={[
              <Body3Light>
                Email <Text color={COLORS.aragonBlue}>{email}</Text>
              </Body3Light>,
              <Body3Light>
                Phone number <Text color={COLORS.aragonBlue}>{phone}</Text>
              </Body3Light>
            ]}
            label={'[Contact]'}
            wide
          />
        </Col>

        <Col auto>
          <Button onClick={handleSendCode} label={'Send'} />
        </Col>
      </Row>
      <Input value={code} onChange={(e) => setCode(e.target.value)} label={'Verification code'} />

      <RepeatSendTitle>
        <span>Didn’t recieve the code? </span>
        <LinkButton onClick={handleSendCode}>Try again</LinkButton>
      </RepeatSendTitle>

      <FooterButtons onNext={onSubmitTransfer} nextDisabled={!code} />
    </RegContainer>
  );
};

const RepeatSendTitle = styled.p`
  margin: ${GU * 5}px 0 ${GU * 8}px 0;
`;
