import React, { useState } from 'react';
import { SidePanel } from '@aragon/ui';
import { InnerBox, UploadDocumentsBox } from '../../../../components';
import { Body1Light, Button } from '../../../../ui';
import { useQuery } from 'react-query';
import { kycApi, kycApiKeys } from '../../../../api/kyc';
import { CountrySelect } from '../../../../modules/kycModule/Fields';
import { DatePicker, Form, Input, Select } from 'antd';
import { AntWrapper } from '../../../../ui/typography';

export const InitVerifSidePanel = ({ opened, onClose }) => {
  const { data } = useQuery(kycApiKeys.getVerifDocTypes, kycApi.getVerifDocTypes, { select: data => data.data });
  const [form] = Form.useForm();

  const onSubmit = async (data) => {
    try {
      const { file, ...sectionData } = data;
      const { data: section } = await kycApi.createVerifSection(sectionData);
      if (section) {
        await kycApi.postVerifSectionDoc({ file: file, sectionId: section.sectionId || section.id });
      }

      onClose();
    } catch (e) {

    }
  };


  return (
    <SidePanel title={<Body1Light>Initiate verification process</Body1Light>} opened={opened} onClose={onClose}>
      <AntWrapper>
        <InnerBox>
          <Form
            form={form}
            onFinish={onSubmit}
            layout="vertical"
          >
            <Form.Item label={'First name'} name={'firstName'} rules={[{ required: true }]}>
              <Input size={'middle'} />
            </Form.Item>
            <Form.Item label={'Last name'} name={'lastName'} rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item label={'Date of birth'} name={'dob'} rules={[{ required: true }]}>
              <DatePicker />
            </Form.Item>
            <Form.Item label={'Country'} name={'countryId'} rules={[{ required: true }]}>
              <CountrySelect />
            </Form.Item>
            <Form.Item label={'Document type'} name={'veriffDocumentTypeId'} rules={[{ required: true }]}>
              <Select
                options={data?.map(item => ({ value: item.id, label: item.description }))}
              />
            </Form.Item>
            <Form.Item label={'Document number'} name={'documentNumber'} rules={[{ required: true }]}>
              <Input />
            </Form.Item>

            <Form.Item label={'Document'} name={'file'} trigger={'setDocs'} rules={[{ required: true }]}>
              <UploadDocumentsBox docs={form.getFieldValue('file') ? [form.getFieldValue('file')] : null} />
            </Form.Item>

            <Button
              label={'Submit'}
              onClick={() => form.submit()}
              mode={'strong'}
              wide
            />
          </Form>
        </InnerBox>
      </AntWrapper>
    </SidePanel>
  );
};
