import React, { useEffect, useState } from 'react';
import { ordersActions } from '../../../../store/orders';
import { assetsActions } from '../../../../store/assets';
import { OrdersPage } from '../../../../pages/investor';
import { useDispatch, useSelector } from 'react-redux';
import { investorActions } from '../../../../store/investor';

const ordersInTable = 6;
const assetsInTable = 3;

export const OrdersContainer = () => {
  const dispatch = useDispatch();
  const { orders, filters } = useSelector((state) => state.orders);
  const { assets } = useSelector((state) => state.assets);
  const { cashSummary } = useSelector((state) => state.investor);

  useEffect(() => {
    dispatch(investorActions.getCashSummary());
  }, []);

  const refetchOrders = (params) => {
    dispatch(
      ordersActions.getOrders({
        reverse: true,
        take: ordersInTable,
        ...params
      })
    );
  };

  const refetchAssets = (params) => {
    dispatch(assetsActions.getAssets({ take: assetsInTable, ...params }));
  };

  const saveFilters = (filter) => {
    dispatch(ordersActions.setFilters(filter));
  };

  return (
    <OrdersPage
      ordersInTable={ordersInTable}
      cashSummary={cashSummary}
      orders={orders}
      assets={assets}
      refetchOrders={refetchOrders}
      refetchAssets={refetchAssets}
      savedFilters={filters}
      saveFilters={saveFilters}
    />
  );
};
