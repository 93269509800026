import React, { useState } from 'react';
import { GU } from '@aragon/ui';
import {
  AddressInputBox,
  IdDocumentsInputBox,
  CompanyInfoInputBox,
  CompanyRegistrationInputBox,
  RepresentativeInfoInputBox
} from '../../../../../components/common/InputBoxes';
import { BackBar, Box, DocumentLine, FileUploadButton, Info } from '../../../../../components';
import { Button, PageContainer, Row, Col, Title2, Body1Light, Container } from '../../../../../ui';
import {
  companyInfoTemplate,
  addressTemplate,
  companyRegistrationTemplate,
  representativeInfoTemplate,
  idDocumentsTemplate
} from '../../../../../utils/static';
import { newObject } from '../../../../../utils/helpers';

export const CreateIssuerView = ({ handleCreateIssuer }) => {
  const [companyInfo, setCompanyInfo] = useState({ ...companyInfoTemplate });
  const [companyReg, setCompanyReg] = useState({ ...companyRegistrationTemplate });
  const [companyLegalAddress, setCompanyLegalAddress] = useState({ ...addressTemplate });
  const [companyActualAddress, setCompanyActualAddress] = useState({ ...addressTemplate });
  const [representativeInfo, setRepresentativeInfo] = useState({ ...representativeInfoTemplate });
  const [legalAddress, setLegalAddress] = useState({ ...addressTemplate });
  const [actualAddress, setActualAddress] = useState({ ...addressTemplate });
  const [idDocument, setIdDocument] = useState({ ...idDocumentsTemplate });
  const [document, setDocument] = useState(null);

  const changeState = (dispatcher, state, key, value) => {
    dispatcher(newObject(state, key, value));
  };

  const onCreateIssuer = () => {
    handleCreateIssuer({
      companyInfo: companyInfo,
      companyReg: companyReg,
      representativeInfo: representativeInfo,
      idDocument: idDocument,
      companyLegalAddress: companyLegalAddress,
      companyActualAddress: companyActualAddress,
      legalAddress: legalAddress,
      actualAddress: actualAddress,
      document: document
    });
  };

  return (
    <PageContainer paddingTop={3 * GU}>
      <Title2 marginBottom={2.5 * GU}>Create Issuer profile</Title2>

      <BackBar />

      <CompanyInfoInputBox
        data={companyInfo}
        changeData={(key, value) => changeState(setCompanyInfo, companyInfo, key, value)}
        required
      />

      <CompanyRegistrationInputBox
        data={companyReg}
        changeData={(key, value) => changeState(setCompanyReg, companyReg, key, value)}
      />

      <AddressInputBox
        title={'Legal address'}
        data={companyLegalAddress}
        changeData={(key, value) =>
          changeState(setCompanyLegalAddress, companyLegalAddress, key, value)
        }
      />

      <AddressInputBox
        title={'Correspondence Adress'}
        data={companyActualAddress}
        changeData={(key, value) =>
          changeState(setCompanyActualAddress, companyActualAddress, key, value)
        }
      />

      <RepresentativeInfoInputBox
        data={representativeInfo}
        changeData={(key, value) =>
          changeState(setRepresentativeInfo, representativeInfo, key, value)
        }
      />

      <AddressInputBox
        title={'Legal address'}
        data={legalAddress}
        changeData={(key, value) => changeState(setLegalAddress, legalAddress, key, value)}
      />

      <AddressInputBox
        title={'Actual address'}
        data={actualAddress}
        changeData={(key, value) => changeState(setActualAddress, actualAddress, key, value)}
      />

      <IdDocumentsInputBox
        data={idDocument}
        changeData={(key, value) => changeState(setIdDocument, idDocument, key, value)}
        hideMartial
      />

      <Box paddingBottom={1.5 * GU}>
        <Row margin={-43}>
          <Col width={'326px'}>
            <Body1Light marginBottom={0.5 * GU}>Power of attorney</Body1Light>

            {document ? (
              <Container>
                <DocumentLine
                  doc={{ name: document.name, id: 1 }}
                  onDelete={() => setDocument(null)}
                  hideLastBorder
                />
              </Container>
            ) : (
              <FileUploadButton
                onChange={(value) => setDocument(value[0])}
                paddingY={1.5 * GU}
                buttonProps={{ size: 'small' }}
              />
            )}
          </Col>

          <Col auto marginTop={1.5 * GU}>
            <Info style={{ minWidth: '264px' }}>
              [предоставляется если данные
              <br /> заполняет не CEO]
            </Info>
          </Col>
        </Row>
      </Box>

      <Row marginTop={3 * GU} justifyContent={'flex-end'}>
        <Button label={'Create'} onClick={onCreateIssuer} mode={'strong'} />
      </Row>
    </PageContainer>
  );
};
