import React, { useRef, useEffect } from 'react';
import { createBrowserHistory } from 'history';

export const history = createBrowserHistory();

let appContainer = document.getElementById('app-container');
export const ScrollToTop = ({ children, ...props }) => {
  const ref = useRef();

  useEffect(() => {
    history.listen(() => {
      try {
        if (!appContainer) {
          appContainer = document.getElementById('app-container');
        }
        appContainer.parentNode.scrollTo(0, 0);
      } catch (e) {
        console.error('SCROLL_TOP', e);
      }
    });
  }, []);

  return (
    <div ref={ref} {...props}>
      {children}
    </div>
  );
};

export const scrollToTop = () => {
  if (!appContainer) appContainer = document.getElementById('app-container');
  appContainer.parentNode.scrollTo({
    top: 0,
    behavior: 'smooth'
  });
};
