import React, { useEffect, useState } from 'react';
import { Header } from '@aragon/ui';
import {
  MyAssetsTable,
  Box,
  InnerBox,
  BackBar,
  SearchInput,
  Pagination
} from '../../../../components';
import { Col, PageContainer, Title2 } from '../../../../ui';
import styled from 'styled-components';

export const MyAssets = ({ myAssets, assetsInTable, updateFilters, savedFilters, saveFilters }) => {
  const [search, setSearch] = useState(savedFilters?.search || '');
  const [page, setPage] = useState(savedFilters?.page || 0);

  useEffect(() => {
    updateFilters({
      search: search,
      skip: page * assetsInTable
    });
  }, []);

  const updateSearch = (val) => {
    updateFilters({
      search: val
    });

    setSearch(val);
    setPage(0);
  };

  const onChangePagination = (val) => {
    updateFilters({
      search: search,
      skip: val * assetsInTable
    });

    setPage(val);
  };

  return (
    <PageContainer>
      <Header
        primary={<Title2>My Assets</Title2>}
        // secondary={<Button mode="strong" label="Order a report" onClick={() => setOpened(true)}/>}
      />
      <BackBar />

      <Box padding={0}>
        <InnerBox noBorder>
          <Col width={'304px'}>
            <SearchInput value={search} onChange={updateSearch} placeholder={'Search'} wide />
          </Col>
        </InnerBox>

        <InnerBox padding={'0'} noBorder>
          <MyAssetsTable
            data={myAssets && myAssets.assets}
            onDetailsSideEffect={() => saveFilters({ search: search, page: page })}
          />
        </InnerBox>

        {myAssets && myAssets.count > assetsInTable && (
          <Pagination
            selected={page}
            onChange={onChangePagination}
            pages={Math.ceil(myAssets.count / assetsInTable)}
          />
        )}
      </Box>
    </PageContainer>
  );
};
