import React from 'react';
import { useTranslation } from 'react-i18next';

import { PageContainer } from '../../../ui';
import { Title1, Title2 } from '../../../ui';
import {
  DocumentSection,
  DocumentSectionList
} from '../../../features/constructorDocuments';

export const ConstructorDocuments = () => {
  const { t } = useTranslation();

  return (
    <PageContainer>
      <Title1 marginBottom={20}>
        {t('Constructor documents')}
      </Title1>

      <DocumentSectionList />
      <DocumentSection/>
    </PageContainer>
  );
};
