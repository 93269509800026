import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Checkbox, Button } from 'antd';

import { useAllDocumentSections } from '../../constructorDocuments/hooks';
import { useDocumentType } from '../hooks';
import { history } from '../../../routing';

export const DependedDocument = ({kycTypeDocuments, questionSectionTypeId}) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const {data} = useAllDocumentSections(id);
  const [questions, setQuestions] = useState([]);
  const [documentSections, setDocumentSections] = useState([]);
  const [loading, setLoading] = useState(true);
  const [documentTypes, setDocumentTypes] = useState([]);

  const { handleCreate, handleDelete } = useDocumentType();
  useEffect(() => {
    if (!data || !data.length) return;

    let qsts = [];
    let answrs = [];

    setLoading(false);
    setDocumentSections(data);

    setDocumentTypes(kycTypeDocuments);
  }, [data ])

  const onChange = (e, documentType) => {

    let newDocumentTypes;

    if (e.target.checked) {
      newDocumentTypes = [...documentTypes, documentType];
      handleCreate(questionSectionTypeId, documentType.id);
    } else {
      newDocumentTypes = documentTypes.filter(a => a.id !== documentType.id);
      handleDelete(questionSectionTypeId, documentType.id);
    }

    setDocumentTypes(newDocumentTypes);
  }

  const onAdd = () => {

  }

  if (loading) return (
    <div>Loading...</div>
  )

  return (
    <div>
      <Box>
        {documentSections.map(questionSection => (
          <QuestionBox>
            <div>{questionSection?.name}</div>
            <div>
              {questionSection?.kycTypeDocuments?.map(kycDocument => (
                <AnswerBox>
                  <Checkbox onChange={(e) => onChange(e, kycDocument)}
                            checked={documentTypes.some(type => type.id === kycDocument.id)}
                  >
                    {kycDocument.name}
                  </Checkbox>
                </AnswerBox>
              ))}
            </div>
          </QuestionBox>
        ))}
      </Box>
    </div>
  );
};

const Box = styled.div`
  height: 600px;
  overflow-y: scroll;
`

const AnswerBox = styled.div`
  padding-left: 20px;
`

const QuestionBox = styled.div`
  margin-bottom: 10px;
`

const BtnBox = styled.div`
  text-align: right;
`
