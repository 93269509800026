import axios from 'axios';
import { API_URL } from '../../../utils/static';

//QUESTS
export const getInvestorQuestionnaires = (id) => {
  return axios.get(`${API_URL}/api/kyc/v1/admin/investor/questionSectionTypes`, {
    params: { investorId: id }
  });
};
export const getQuestionnaireAnswers = ({ investorId, questionnaireId }) => {
  return axios.get(`${API_URL}/api/kyc/v1/admin/investor/questionSectionTypes/answers`, {
    params: { investorId: investorId, questionSectionTypeId: questionnaireId }
  });
};
export const setQuestionnaireStatus = ({
  investorId,
  status,
  questionnaireId,
  questionId,
  comment = ''
}) => {
  return axios.put(`${API_URL}/api/kyc/v1/admin/questionnaireSettings`, {
    investorId: investorId,
    status: status,
    questionSectionTypeId: questionnaireId,
    questionId: questionId,
    comment: comment
  });
};
const getAllowedQuestsToAdd = ({ investorId }) => {
  return axios.get(`${API_URL}/api/kyc/v1/admin/questionnaires`, { params: { investorId } });
};
const requestQuest = ({ investorId, questId, onlyAdmin}) => {
  return axios.post(`${API_URL}/api/kyc/v1/admin/questionnaires`, {
    investorId: investorId,
    questionSectionTypeId: questId,
    onlyAdmin: onlyAdmin,
  });
};
export const getRejectedEntities = (investorId) => {
  return axios.get(`${API_URL}/api/kyc/v1/admin/questionnaire`, {
    params: { status: 'UNACCEPTED', investorId }
  });
};
//SUMSUB
export const getInvestorSumSubStatus = (id) => {
  return axios.get(`${API_URL}/api/kyc/v1/admin/verification/investorStatus`, {
    params: { investorId: id }
  });
};
export const getInvestorSumSubAnswers = (id) => {
  return axios.get(`${API_URL}/api/kyc/v1/admin/verification/questionnaire`, {
    params: { investorId: id }
  });
};
const setInvestorSumSubStatus = ({investorId, status}) => {
  return axios.put(
    `${API_URL}/api/kyc/v1/admin/verification/investorStatus`,
    {investorId: investorId, sumSubStatus: status }
    )
};

//DOCS
//statuses: ACCEPTED, UNACCEPTED
const setDocumentStatus = ({ docId, status }) => {
  return axios.put(`${API_URL}/api/kyc/v1/admin/questionnaire/document`, {
    kycDocumentId: docId,
    status
  });
};
const setDocumentComment = ({ docId, comment = '' }) => {
  return axios.post(`${API_URL}/api/kyc/v1/admin/questionnaire/document/comment`, {
    kycDocumentId: docId,
    comment
  });
};
const getDocumentsSections = (investorId) => {
  return axios.get(`${API_URL}/api/kyc/v1/admin/questionnaire/document/sections`, {
    params: { investorId }
  });
};
//upload doc
const uploadDocument = ({ requestDocumentId, file, investorId, onlyAdmin }) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('requestDocumentId', requestDocumentId);
  formData.append('investorId', investorId);
  formData.append('onlyAdmin', onlyAdmin);
  return axios.post(`${API_URL}/api/kyc/v1/admin/questionnaire/document`, formData);
};
const getDocumentSectionTypes = ({ sectionId, investorId }) => {
  return axios.get(`${API_URL}/api/kyc/v1/admin/questionnaire/document/types`, {
    params: { kycDocumentSectionId: sectionId, investorId: investorId }
  });
};
const addDocumentRequest = ({ docTypeId, investorId, onlyAdmin }) => {
  return axios.post(`${API_URL}/api/kyc/v1/admin/questionnaire/document/requestDocument`, {
    kycTypeDocumentId: docTypeId,
    onlyAdmin,
    investorId
  });
};
const getDocumentRequests = (investorId) => {
  return axios.get(`${API_URL}/api/kyc/v1/admin/questionnaire/document/requestDocuments`, {
    params: { investorId }
  });
};
const getQuestsDocuments = (investorId) => {
  return axios.get(`${API_URL}/api/kyc/v1/admin/questionnaire/document/documentAnswers`, {
    params: { investorId }
  });
};
const getDocumentRequest = ({ investorId, requestId }) => {
  return axios.get(`${API_URL}/api/kyc/v1/admin/questionnaire/document/requestDocument`, {
    params: { investorId, requestDocumentId: requestId }
  });
};
const updateRequestStatus = ({ kycDocumentId, status }) => {
  //STATUSES: [ACCEPTED, UNACCEPTED]
  return axios.put(`${API_URL}/api/kyc/v1/admin/questionnaire/document`, { kycDocumentId, status });
};
const addRequestComment = ({ kycDocumentId, comment }) => {
  return axios.post(`${API_URL}/api/kyc/v1/admin/questionnaire/document/comment`, {
    kycDocumentId,
    comment
  });
};

//DEEP CHECK
const getInvestorDeepCheck = (investorId) => {
  return axios.get(`${API_URL}/api/kyc/v1/admin/checkInvestor`, { params: { investorId } });
};
/**
 * @param investorId
 * @param type: FULL_KYC, SANCTIONS
 * @returns {Promise<AxiosResponse<T>>}
 */
const initInvestorDeepCheck = ({ investorId, type }) => {
  return axios.post(`${API_URL}/api/kyc/v1/admin/checkInvestor`, { investorId, type });
};

export const setInvestorStatus = (data) => {
  return axios.put(`${API_URL}/api/kyc/v1/admin/investor/accountStatus`, data);
};

export const tier2Upgrade = (id) => {
  return axios.post(`${API_URL}/api/kyc/v1/admin/upgradeTier2`, { investorId: id });
};

export const tier3Upgrade = (id) => {
  return axios.post(`${API_URL}/api/kyc/v1/admin/upgradeTier3`, { investorId: id });
};

export const upgradeTier = (tier, id) => {
  switch (tier) {
    case 2: {
      return tier2Upgrade(id);
    }
    case 3: {
      return tier3Upgrade(id);
    }
    default: {
      return null;
    }
  }
};
const getInvestorProducts = (id) => {
  return axios.get(`${API_URL}/api/kyc/v1/admin/investor/products/my`, {
    params: { investorId: id }
  });
};
const getProductAnswers = ({ investorId, investorProductId }) => {
  return axios.get(`${API_URL}/api/kyc/v1/admin/investor/products/questions`, {
    params: { investorId, investorProductId }
  });
};
const setProductStatus = ({
                                         investorId,
                                         status,
                                         productId,
                                         questionId,
                                         comment = ''
                                       }) => {
  return axios.put(`${API_URL}/api/kyc/v1/admin/products/status`, {
    investorId: investorId,
    status: status,
    investorProductId: productId,
    productQuestionId: questionId,
    comment: comment
  });
};
const getProductRejectedEntities = (investorId) => {
  return axios.get(`${API_URL}/api/kyc/v1/admin/investor/products/questions/status`, {
    params: { status: 'UNACCEPTED', investorId }
  });
};
const getAllowedProductsToAdd = ({ investorId }) => {
  return axios.get(`${API_URL}/api/kyc/v1/admin/investor/products`, { params: { investorId } });
};
const requestProduct = ({ investorId, productId, onlyAdmin}) => {
  return axios.post(`${API_URL}/api/kyc/v1/admin/investor/products`, {
    investorId: investorId,
    productId: productId,
    onlyAdmin: onlyAdmin,
  });
};

export const adminKYCApi = {
  getInvestorDeepCheck,
  initInvestorDeepCheck,
  getInvestorQuestionnaires,
  getQuestionnaireAnswers,
  setQuestionnaireStatus,
  getAllowedQuestsToAdd,
  requestQuest,
  getRejectedEntities,
  getInvestorSumSubStatus,
  getInvestorSumSubAnswers,
  setInvestorSumSubStatus,
  setDocumentStatus,
  setDocumentComment,
  getDocumentsSections,
  getDocumentSectionTypes,
  uploadDocument,
  addDocumentRequest,
  getDocumentRequests,
  getQuestsDocuments,
  getDocumentRequest,
  updateRequestStatus,
  addRequestComment,
  setInvestorStatus,
  tier2Upgrade,
  tier3Upgrade,
  upgradeTier,
  getInvestorProducts,
  getProductAnswers,
  setProductStatus,
  getProductRejectedEntities,
  getAllowedProductsToAdd,
  requestProduct
};
