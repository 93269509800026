import React from 'react';
import { SectionList } from '../../constructorCommon';
import { useQuestionnaireSections } from '../hooks';

export const QuestionnaireSectionList = ({questionnaireId = ''}) => {
  const {data, isLoading} = useQuestionnaireSections(questionnaireId);

  return (
    <SectionList data={data} isLoading={isLoading} type={'questionnaire'}/>
  );
};
