import { API_URL, ORDER_STATUS_TABS, ORDER_TAB_STATUSES } from '../../../utils/static';
import { ERRORS } from '../../static/errors';
import axios from 'axios';
import { Fragment } from 'react';

export const newObject = (obj, key, value) => {
  let copy = Object.assign({}, obj);
  copy[key] = value;
  return copy;
};

export const changeObjectState = (dispatcher, prevState, key, value) => {
  dispatcher(newObject(prevState, key, value));
};

export const getFileSrc = (url, isReferral) => {
  return isReferral ? `${API_URL}/agent/${url}` : `${API_URL}/${url}`;
};

export const getFilenameFromUrl = (url) => {
  const nameParts = url.split('-');
  const filteredParts = nameParts.filter((item, index) => index > nameParts.length - 2);
  return filteredParts.join('-');
};

export const getUrlName = (url, index) => {
  try {
    const array = url.split('/');
    const array2 = array[array.length - 1].split('-');
    return array2[array2.length - 1];
  } catch (e) {
    return `File ${index}`;
  }
};

const addZero = (val) => {
  return `0${val}`.slice(-2);
};

export const dateString = (date) => {
  if (date)
    return `${addZero(date.getDate())}.${addZero(date.getMonth() + 1)}.${date.getFullYear()}`;

  return '';
};

export const dateFromDatePicker = (date) => {
  if (date) {
    const formattedDate = new Date(date.replace(/^"(.*)"$/, '$1'));
    return `${addZero(formattedDate.getDate())}.${addZero(
      formattedDate.getMonth() + 1
    )}.${formattedDate.getFullYear()}`;
  }

  return '';
};

export const displayFullDate = (date, withoutTime = false, lang = 'en') => {
  const monthNames = [
    {en: 'Jan', ru: 'Янв', lv: 'Jan', de: 'Jan'},
    {en: 'Feb', ru: 'Фев', lv: 'Feb', de: 'Feb'},
    {en: 'Mar', ru: 'Март', lv: 'Marts', de: 'März'},
    {en: 'Apr', ru: 'Апр', lv: 'Apr', de: 'Apr'},
    {en: 'May', ru: 'Май', lv: 'Maijs', de: 'Mai'},
    {en: 'Jun', ru: 'Июн', lv: 'Jun', de: 'Juni'},
    {en: 'Jul', ru: 'Июл', lv: 'Jul', de: 'Juli'},
    {en: 'Aug', ru: 'Авг', lv: 'Aug', de: 'Aug'},
    {en: 'Sep', ru: 'Сент', lv: 'Sep', de: 'Sep'},
    {en: 'Oct', ru: 'Окт', lv: 'Okt', de: 'Okt'},
    {en: 'Nov', ru: 'Нояб', lv: 'Nov', de: 'Nov'},
    {en: 'Dec', ru: 'Дек', lv: 'Dec', de: 'Dez'}
  ];

  let day = date.getDate();
  let month = date.getMonth();
  let year = date.getFullYear();
  let hours = date.getHours();
  let minutes = date.getMinutes();

  return `${day}\u00a0${monthNames[month][lang]}\u00a0${year}${
    withoutTime ? '' : ` ${addZero(hours)}:${addZero(minutes)}`
  }`;
};

export const getOrdersTabStatuses = (selectedTab) =>
  ORDER_TAB_STATUSES[ORDER_STATUS_TABS[selectedTab]];

export const capitalize = (string) => string.charAt(0).toUpperCase() + string.slice(1);

export const formatAddress = (name, address) => {
  const formattedAddress = {};
  for (const [key, value] of Object.entries(address)) {
    formattedAddress[`${name}${capitalize(key)}`] = value;
  }

  return formattedAddress;
};

export const isObjectEmpty = (obj) => {
  for (let i in obj) return false;
  return true;
};

export const objectToFormData = (obj) => {
  let formData = new FormData();
  Object.keys(obj).forEach((key) => {
    formData.append(key, obj[key]);
  });

  return formData;
};

export const getErrorMessage = (err) => {
  const defaultError = 'Something went wrong';
  try {
    return ERRORS[err.response.data.message] || defaultError;
  } catch {
    return defaultError;
  }
};

export const downloadFileByLink = async (name, url, referralToken) => {
  const res = await axios.get(url, {
    responseType: 'blob',
    ...(referralToken && {
      headers: {
        'Authorization': `Bearer ${referralToken}`
      }
    })
  });

  if (res && res.data) {
    downloadFile(name, URL.createObjectURL(res.data));
  }
};
export const downloadFile = (name, url) => {
  let tempLink = document.createElement('a');

  tempLink.setAttribute('href', url);
  tempLink.setAttribute('download', name);

  document.documentElement.appendChild(tempLink);
  tempLink.click();

  document.documentElement.removeChild(tempLink);
};
export const getUrlFromText = (text) => {
  const link = text.match(/https?:\/\/[^\s]+/);
  if (!link) return text;

  const textWithoutLink = text.replace(link, '');
  return <Fragment>{textWithoutLink}{' '}<a href={link[0]} target='_blank' rel="noreferrer">{link[0]}</a></Fragment>;
}
export const sortWithNulls = (a, b) => {
    // equal items sort equally
    if (a === b) {
      return 0;
    }

    // nulls sort after anything else
    if (a === null || a === 0) {
      return 1;
    }
    if (b === null || b === 0) {
      return -1;
    }

    return a < b ? -1 : 1;
}
export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}