export const entitySteps = [
  'Create account',
  'Verify e-mail',
  'Verify phone number',
  'Personal data',
  'Company information',
  'Connect wallet',
  'Payment details',
  'MIFID questionary',
  'KYC questionary'
];
export const individualSteps = [
  'Create an account for verification',
  'Verify e-mail',
  'Verify phone number'
  // 'Connect wallet',
  // 'Personal data',
  // 'Payment details',
  // 'POF, MIFID, KYC',
  // 'MIFID questionary',
  // 'KYC questionary'
];
