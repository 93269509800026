import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { ProfileProductsView } from '../../../common';
import { adminKYCApi } from '../../../../api';

export const InvestorProducts = ({ investorData }) => {
  const { id } = useParams();
  const { data, isLoading, refetch } = useQuery(
    ['getInvestorProducts'],
    () => adminKYCApi.getInvestorProducts(id),
    { refetchInterval: 60000, refetchOnWindowFocus: true }
  );

  if (!isLoading) {
    return (
      <ProfileProductsView
        data={data}
        investor={investorData}
        refetchProducts={refetch}
        adminView
      />
    );
  }

  return null;
};
