import React from 'react';
import { history } from '../../../../routing';
import { Table, TableRow, Text } from '@aragon/ui';
import { ProfileViewer, StatusLine } from '../../../../components';
import { Cell, DetailsButton, TableHeader, EmptyBody } from '../../components';
import { COLORS, DISPLAY_TRANSFER_TYPE, TRANSFER_STATUSES } from '../../../../utils/static';
import styled from 'styled-components';
import { displayFullDate } from '../../../../utils/helpers/common';

const defHeader = ['ID', 'Type', 'Sender', 'Receiver', 'Date', 'Equivalent', 'Currency', 'Status'];
export const AdminTransactionsTable = ({ header = defHeader, data = [], onDetailsSideEffect }) => {
  const onDetailsClick = (id) => {
    onDetailsSideEffect && onDetailsSideEffect();
    history.push(`/transaction/${id}`);
  };

  const headerItems = header.map((item, key) => <TableHeader title={item} key={key} />);

  const bodyItems =
    data && data.length > 0 ? (
      data.map((item, key) => {
        const currency = item.toPersonalAccount?.currency || item.fromPersonalAccount?.currency;

        return (
          <TableRow key={key}>
            <Cell>{item.id}</Cell>

            <Cell>
              <Text color={COLORS.aragonBlue}>{DISPLAY_TRANSFER_TYPE[item.type]}</Text>
            </Cell>

            <Cell>{''}</Cell>
            <Cell>{''}</Cell>

            <Cell>{displayFullDate(new Date(item.createdAt))}</Cell>

            <Cell>{item.amount}</Cell>

            <Cell>{currency}</Cell>

            <Cell>
              <StatusLine statusTypes={TRANSFER_STATUSES} type={item.status} />
            </Cell>

            {/*<Cell>*/}
            {/*  <ProfileViewer*/}
            {/*    name={`${item.firstName} ${item.lastName}`}*/}
            {/*    size={'small'}*/}
            {/*  />*/}
            {/*</Cell>*/}

            <Cell cellProps={{ align: 'right' }}>
              <DetailsButton onClick={() => onDetailsClick(item.id)} />
            </Cell>
          </TableRow>
        );
      })
    ) : (
      <EmptyBody title={'No issues'} />
    );

  return (
    <StyledTable
      header={<TableRow style={{ height: '48px' }}>{headerItems}</TableRow>}
      noSideBorders
    >
      {bodyItems}
    </StyledTable>
  );
};

const StyledTable = styled(Table)`
  th {
    border-left: none;
    border-right: none;
    white-space: pre-line;
    line-height: 16px;
  }
`;
