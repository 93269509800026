import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuestionnaireGroup } from '../hooks';
import { Group } from '../../constructorCommon';

export const QuestionnaireGroup = ({groupProp, sectionId, onCreated}) => {
  const { id } = useParams();
  const {
    handleUpdate,
    handleDelete,
    handleCreate,
    isCreating,
    isUpdating,
    isDeleting
  } = useQuestionnaireGroup({questionnaireId: id});

  return (
    <Group groupProp={groupProp} sectionId={sectionId} onCreated={onCreated}
           handleUpdate={handleUpdate}
           handleDelete={handleDelete}
           handleCreate={handleCreate}
           isCreating={isCreating}
           isUpdating={isUpdating}
           isDeleting={isDeleting}
           type="questionnaire"/>
  );
};
