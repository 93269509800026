import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { investorApi } from '../../../../api/investor';
import { investorActions } from '../../../../store/investor';
import { ProfilePaymentsView } from '../../../../components';
import { paymentDetailsTemplate, COUNTRIES, CURRENCIES } from '../../../../utils/static';
import { newObject } from '../../../../utils/helpers';

export const ProfilePayments = () => {
  const dispatch = useDispatch();
  const { paymentDetails } = useSelector((state) => state.investor);
  const [editedPayments, setEditedPayments] = useState([]);

  const FetchPayments = () => {
    dispatch(investorActions.fetchPayments());
  };

  useEffect(() => {
    if (!paymentDetails) {
      FetchPayments();
    }
  }, []);

  const AddAccount = () => {
    const clone = [...editedPayments];
    clone.push({ ...paymentDetailsTemplate, edited: true });
    setEditedPayments(clone);
  };

  const ChangeData = (key, value, selected) => {
    const index = selected - paymentDetails.length;
    let clone = [...editedPayments];
    clone[index] = newObject(editedPayments[index], key, value);
    setEditedPayments(clone);
  };

  let rejectedPayments = [];
  const SavePayments = (index = 0) => {
    if (index < editedPayments.length) {
      const data = { ...editedPayments[index] };
      try {
        delete data.edited;
      } catch (e) {
        console.log(e);
      }

      investorApi
        .addPaymentDetails({
          ...data,
          country: COUNTRIES[+data.country],
          currency: CURRENCIES[+data.currency]
        })
        .then((res) => {
          console.log(res);

          if (res.status === 200) {
            SavePayments(++index);
          } else {
            rejectedPayments.push({ ...data, edited: true });
            SavePayments(++index);
          }
        })
        .catch((err) => {
          rejectedPayments.push({ ...data, edited: true });
          SavePayments(++index);
        });
    } else {
      FetchPayments();
      setEditedPayments([...rejectedPayments]);
      rejectedPayments = [];
    }
  };

  return (
    <ProfilePaymentsView
      payments={paymentDetails && [...paymentDetails, ...editedPayments]}
      AddAccount={AddAccount}
      ChangeData={ChangeData}
      onSave={SavePayments}
    />
  );
};
