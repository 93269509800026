import React from 'react';
import { Table, TableRow } from '@aragon/ui';
import { DetailsButton, Cell, TableHeader } from '../../components';
import styled from 'styled-components';
import { BROKER_PROFILE, COLORS, DISPLAY_TRANSACTION_TYPE } from '../../../../utils/static';
import { displayFullDate } from '../../../../utils/helpers';
import { history } from '../../../../routing';

const defHeader = ['Price', 'Date', 'Type', 'Sender', 'Receiver'];
export const CashTransactionsTable = ({ header = defHeader, data }) => {
  const headerItems = header.map((item, key) => <TableHeader title={item} key={key} />);

  const bodyItems =
    data && data.length > 0 ? (
      data.map((item, key) => {
        const fromAccount =
          item.fromAccount || item.fromPaymentDetail || item.fromPersonalAccount || BROKER_PROFILE;
        const toAccount =
          item.toAccount || item.toPaymentDetail || item.toPersonalAccount || BROKER_PROFILE;
        const isFunding = item.type === 'FUNDING';

        return (
          <TableRow key={key}>
            <Cell color={isFunding ? COLORS.mintGreen : COLORS.red}>
              {isFunding ? '+' : '-'}
              {item.amount} {item.currency}
            </Cell>
            <Cell>{displayFullDate(new Date(item.createdAt), true)}</Cell>
            <Cell>{DISPLAY_TRANSACTION_TYPE[item.type]}</Cell>
            <Cell>{fromAccount.accountName || fromAccount.name}</Cell>
            <Cell>{toAccount.accountName || toAccount.name}</Cell>

            <Cell cellProps={{ align: 'right' }}>
              <DetailsButton onClick={() => history.push(`/transaction/${item.id}`)} />
            </Cell>
          </TableRow>
        );
      })
    ) : (
      <TableRow>
        <Cell cellProps={{ style: { border: 'none' } }}>No transactions</Cell>
      </TableRow>
    );

  return (
    <StyledTable header={<TableRow>{headerItems}</TableRow>} noSideBorders>
      {bodyItems}
    </StyledTable>
  );
};

const StyledTable = styled(Table)`
  th {
    border-left: none;
    border-right: none;
  }
`;
