import React, {useState, useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Button, Checkbox, Input, Popconfirm, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import { history } from '../../../routing';
import { Container } from '../../../ui';
import { useDocumentSection, useDocumentType } from '../hooks';
import { DocumentTypeList } from './DocumentTypeList';
import { DocumentType } from './DocumentType';
import { Box, NestedBox, ActionBox, Label, Space } from '../styled';

export const DocumentSection = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const {data, isError, isLoading, handleCreate, handleUpdate, handleChangeRole, isUpdating, isChangingRole} = useDocumentSection(id);
  const [documentSection, setDocumentSection] = useState({});
  const [showCreateNew, setShowCreateNew] = useState(false);
  const { handleDelete, isDeleting } = useDocumentSection(id);
  useEffect(() => {
    if (!data?.id) return;

    setDocumentSection(data);
  }, [data])

  const onChange = (e) => {
    const {name, value} = e.target;
    setDocumentSection({...documentSection, [name]: value});
  }
  const onDelete = () => {
    handleDelete(documentSection);
    history.push(`/constructorDocuments/`);
  }

  const onChangeRole = (e) => {
    const {name, checked} = e.target;

    handleChangeRole(name, checked);
  }

  const handleAction = () => {
    if (documentSection.id) {
      handleUpdate(documentSection);
    } else {
      handleCreate(documentSection).then((res) => {
        if(res?.data?.id) {
          history.push(`/constructorDocuments/sections/${res.data.id}`);
        }
      });
    }
  }

  if (isError && !data) {
    return (
      <Container paddingY={24}>
        <div>
          {t('Error')}
        </div>
      </Container>
    );
  }

  const isDisabled = !documentSection.name;
  if (isLoading) return <div>Loading...</div>
  return (
    <Container paddingY={20}>
      {/*<Title2>{t('Questionnaire')}</Title2>*/}

      <Box isNew={true}>

        <Label>{t('Name')}</Label>
        <Input name="name" value={documentSection.name} onChange={onChange}/>
        <Space/>
        {documentSection.id && (
          <Container paddingY={20}>
            <Label>{t('Name_RU')}</Label>
            <Input name="name_RU" value={documentSection.name_RU} onChange={onChange}/>
            <Space/>
            <Label>{t('Name_LV')}</Label>
            <Input name="name_LV" value={documentSection.name_LV} onChange={onChange}/>
            <Space/>
          </Container>
        )}

        {documentSection.id && (
          <div style={{ display: 'inline-block' }}>
            <Space/>
            <Label>{t('Roles')}</Label>
            <Spin indicator={<LoadingOutlined spin/>}
                  spinning={isChangingRole}
            >
              <Checkbox name="PERSONAL"
                        checked={documentSection?.roles?.includes('PERSONAL')}
                        onChange={onChangeRole}
                        style={{ marginRight: '15px' }}
              >
                {t('PERSONAL')}
              </Checkbox>
              <Checkbox name="COMPANY"
                        checked={documentSection?.roles?.includes('COMPANY')}
                        onChange={onChangeRole}
                        style={{ marginRight: '15px' }}
              >
                {t('COMPANY')}
              </Checkbox>
              <Checkbox name="AGENT"
                        checked={documentSection?.roles?.includes('AGENT')}
                        onChange={onChangeRole}
                        style={{ marginRight: '15px' }}
              >
                {t('AGENT')}
              </Checkbox>
              <Checkbox name="RECOMMENDER"
                        checked={documentSection?.roles?.includes('RECOMMENDER')}
                        onChange={onChangeRole}
                        style={{ marginRight: '15px' }}
              >
                {t('ASSISTANT')}
              </Checkbox>
            </Spin>
          </div>
        )}
        <ActionBox>
          <Button onClick={handleAction}
                  disabled={isDisabled}
                  type="primary"
                  loading={isUpdating}
          >
            {documentSection.id ? t('Update Document Section') : t('Create Document Section')}
          </Button>
          {documentSection.id && (
            <Popconfirm placement="topRight"
                        title={t('Are you sure to delete this document section?')}
                        onConfirm={onDelete}
                        okText={t('Yes')}
                        cancelText={t('No')}
            >
              <Button danger loading={isDeleting}>{t('Delete Document Section')}</Button>
            </Popconfirm>
          )}
        </ActionBox>
      </Box>

      {documentSection.id && (
        <Container paddingY={20}>
          <NestedBox>
            <DocumentTypeList documentSectionId={id}/>
            <Space/>

            {showCreateNew ? (
              <DocumentType onCreated={() => setShowCreateNew(false)}/>
            ) : (
              <Button onClick={() => setShowCreateNew(true)}>
                {t('Add new Type')}
              </Button>
            )}

          </NestedBox>
        </Container>
      )}

    </Container>
  );

};
