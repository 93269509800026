import React from 'react';
import { useTranslation } from 'react-i18next';
import { Collapse } from 'antd';

import { Title4 } from '../../../ui';
import { useDocumentTypes } from '../hooks';
import {StyledPanel} from '../styled'
import { DocumentType } from './DocumentType';

export const DocumentTypeList = ({documentSectionId = ''}) => {
  const { t } = useTranslation();
  const {data, isError, isLoading} = useDocumentTypes(documentSectionId);

  return (
    <>
      {isLoading && <div>Loading...</div>}

      <Title4>{t('Document Types')}</Title4>

      <Collapse accordion>
        {data?.map(documentType => (
          <StyledPanel header={documentType.name}
                       key={documentType.id}
          >
            <DocumentType sectionProp={documentType} />
          </StyledPanel>

        ))}
      </Collapse>

    </>
  );
};
