import axios from 'axios';
import { API_URL } from '../../../utils/static';


// role: [AGENT, RECOMMENDER]
// requestType: [ROLE, REFERRAL_CODE]


const setRefferalCode = ({ investorId, code, role }) => {
  switch (role) {
    case 'AGENT': {
      return axios.post(`${API_URL}/api/kyc/v1/admin/referralCodes`, {
        investorId,
        referralCodeAgent: code
      });
    }
    case 'RECOMMENDER': {
      return axios.post(`${API_URL}/api/kyc/v1/admin/referralCodes`, {
        investorId,
        referralCodeRecommender: code
      });
    }
  }
};

const getInvestorRequest = ({ investorId, role, requestType }) => (
  axios.get(`${API_URL}/api/kyc/v1/admin/requestCheck`, { params: { investorId, role, requestType } })
);

const resolveRequest = (id) => (
  axios.post(`${API_URL}/api/kyc/v1/admin/requestCheck`, { requestCheckId: id })
);

const setReferralCodeAgent = ({investorId, referralCodeAgent}) => (
    axios.post(`${API_URL}/api/kyc/v1/admin/setReferralCodeAgent`, { investorId: investorId, referralCodeAgent: referralCodeAgent })
);

const setReferralCodeRecommender = ({investorId, referralCodeRecommender}) => (
    axios.post(`${API_URL}/api/kyc/v1/admin/setReferralCodeRecommender`, { investorId: investorId, referralCodeRecommender: referralCodeRecommender })
);

const getRecommendersList = ({ investorId }) => (
    axios.get(`${API_URL}/api/kyc/v1/admin/recommenders`, { params: { investorId } })
);

const getAgentsList = ({ investorId }) => (
    axios.get(`${API_URL}/api/kyc/v1/admin/agents`, { params: { investorId } })
);

const getReferralsList = ({ investorId, role }) => {
  switch (role) {
    case 'AGENT': {
      return axios.get(`${API_URL}/api/kyc/v1/admin/agent/investors`, { params: { agentId: investorId } });
    }
    case 'RECOMMENDER': {
      return axios.get(`${API_URL}/api/kyc/v1/admin/recommender/investors`, { params: { recommenderId: investorId } });
    }
  }
};
const getReferralsTree = ({ investorId }) => {
  return axios.get(`${API_URL}/api/kyc/v1/admin/investor/pyramid/down`, { params: { investorId: investorId } });
};

export const adminReferralApi = {
  setRefferalCode,
  getInvestorRequest,
  resolveRequest,
  setReferralCodeAgent,
  setReferralCodeRecommender,
  getRecommendersList,
  getAgentsList,
  getReferralsList,
  getReferralsTree
};

export const adminReferralApiKeys = {
  getInvestorRequest: 'adminReferralApi.getInvestorRequest',
  getRecommendersList: 'adminReferralApi.getRecommendersList',
  getAgentsList: 'adminReferralApi.getAgentsList',
  getReferralsList: 'adminReferralApi.getReferralsList',
  getReferralsTree: 'adminReferralApi.getReferralsTree'
};
