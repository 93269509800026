export const modifyAnswers = (dAnswers) => {
  // "visibleConditions": [
  //   {"id":"d4d634b7-69d0-4147-b015-a9a703c59690","value":"fbe576c8-012e-4ee1-86a3-752fa35d2222"},
  //   {"id":"d4d634b7-69d0-4147-b015-a9a703c59690","value":"fed2d99d-e7be-44da-a4ff-955d76c01393"}
  // ],
  const visibleConditions = [];
  dAnswers.forEach(item => {
    visibleConditions.push({
      id: item.questionId,
      value: item.id
    })
  })

  return visibleConditions;
}