import React from 'react';
import { GU } from '@aragon/ui';
import { TotalAssetsTable, AssetsTableBox } from '../../../../../components';
import { PageContainer, Title2 } from '../../../../../ui';

export const AssetsView = ({ assets, ASSETS_ON_PAGE, fetchAssets }) => {
  return (
    <PageContainer marginTop={3 * GU}>
      <Title2 marginBottom={2 * GU}>Assets</Title2>

      <TotalAssetsTable data={{}} />

      <AssetsTableBox
        assets={assets && assets}
        ASSETS_ON_PAGE={ASSETS_ON_PAGE}
        fetchAssets={fetchAssets}
      />
    </PageContainer>
  );
};
