import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Header, DateRangePicker, FloatIndicator, ToastHub, Toast, IconCalendar } from '@aragon/ui';
import DatePickerAragon from '@aragon/ui/dist/DatePicker';
import { AssetCard, WishlistItem } from '../../../components';
import { Input, DatePicker, Button } from '../../../ui';
import styled from 'styled-components';
import { COLORS } from '../../../utils/static/colors';
import { dateString } from '../../../utils/helpers';

const InvestorWishlist = () => {
  const [date, setDate] = useState(null);
  const [message, setMessage] = useState('');
  const ref = useRef();

  const onChange = (value) => {
    setDate(value);
  };

  const tryD = () => {
    ref.current.click();
  };

  const someFunc = (toast) => {
    toast('some');
  };

  return (
    <div>
      <Header primary='Wishlist' />

      <DateRangePicker />

      <FloatIndicator>Some</FloatIndicator>

      <DatePicker label={'some'} value={date} onChange={onChange} />

      <div style={{ marginBottom: '20px', display: 'flex', justifyContent: 'space-between' }}>
        <WishlistItem
          currency='ETH'
          quantity='Quantity'
          volume='Volume'
          address='0x281c36aee917b24d8e5f59481f6639d81e4cf7125b09fb93a2b43c31ef3fc115'
          date='10/05/17'
        />
        <WishlistItem
          currency='ETH'
          quantity='Quantity'
          volume='Volume'
          address='0x281c36aee917b24d8e5f59481f6639d81e4cf7125b09fb93a2b43c31ef3fc115'
          date='10/05/17'
        />
        <WishlistItem
          currency='ETH'
          quantity='Quantity'
          volume='Volume'
          address='0x281c36aee917b24d8e5f59481f6639d81e4cf7125b09fb93a2b43c31ef3fc115'
          date='10/05/17'
        />
        <WishlistItem
          currency='ETH'
          quantity='Quantity'
          volume='Volume'
          address='0x281c36aee917b24d8e5f59481f6639d81e4cf7125b09fb93a2b43c31ef3fc115'
          date='10/05/17'
        />
      </div>

      <AssetCard title='<Название актива>' type='<Asset ID>' color='#00D985' />

      <Input value={message} onChange={(e) => setMessage(e.target.value)} />

      <ToastHub timeout={2000} toast={someFunc}>
        <Toast>
          {(toast) => (
            <Button ref={ref} onClick={() => toast(message)}>
              Click me
            </Button>
          )}
        </Toast>
      </ToastHub>

      <Button label={'some'} onClick={tryD} />
    </div>
  );
};

export default InvestorWishlist;
