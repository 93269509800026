import React from 'react';
import { GU, DropDown, Button } from '@aragon/ui';
import { Col, Row, Input, Body1Light, Label2 } from '../../../../ui';
import { ImageUpload, Info, Box, InnerBox } from '../../../../components';
import { COUNTRIES, CURRENCIES } from '../../../../utils/static';
import styled from 'styled-components';

export const PaymentDetailsForm = ({ paymentDetails, changeData, title = '', onSave }) => {
  if (paymentDetails) {
    return (
      <div>
        <Box padding={0}>
          <InnerBox>
            {title && <Body1Light marginBottom={3 * GU}>{title}</Body1Light>}

            <Row margin={-GU} marginBottom={2 * GU}>
              <Col padding={GU} width={'70%'}>
                <Input
                  label={
                    <span>
                      BIC/SWIFT code <span style={{ color: '#08BEE5' }}>*</span>
                    </span>
                  }
                  value={paymentDetails.bicSwiftCode}
                  onChange={(e) => changeData('bicSwiftCode', e.target.value)}
                />
              </Col>
            </Row>

            <Row margin={-GU}>
              <Col padding={GU} width={'70%'}>
                <Input
                  label={
                    <>
                      Bank name <span style={{ color: '#08BEE5' }}>*</span>
                    </>
                  }
                  value={paymentDetails.bankName}
                  onChange={(e) => changeData('bankName', e.target.value)}
                />
              </Col>

              <Col padding={GU} width={'30%'}>
                <Input
                  label={<>Bank code</>}
                  value={paymentDetails.bankCode}
                  onChange={(e) => changeData('bankCode', e.target.value)}
                />
              </Col>
            </Row>
          </InnerBox>
        </Box>

        <Box heading='Bank address' padding={0}>
          <InnerBox>
            <Row margin={-GU}>
              <Col padding={GU} width={'33%'}>
                <InputLabel>Country</InputLabel>
                <DropDown
                  items={COUNTRIES}
                  selected={+paymentDetails.country}
                  width='100%'
                  onChange={(item) => changeData('country', item)}
                />
              </Col>

              <Col padding={GU}>
                <Input
                  label={'City'}
                  value={paymentDetails.city}
                  onChange={(e) => changeData('city', e.target.value)}
                />
              </Col>

              <Col padding={GU}>
                <Input
                  label={'Address'}
                  value={paymentDetails.address}
                  onChange={(e) => changeData('address', e.target.value)}
                />
              </Col>
            </Row>
          </InnerBox>
        </Box>

        <Box padding={0}>
          <InnerBox>
            <Row margin={-GU}>
              <Col padding={GU} width={'70%'} marginBottom={2 * GU}>
                <Input
                  label={'CORRESPONDENT BANK FOR SWIFT'}
                  value={paymentDetails.correspondentBankForSwift}
                  onChange={(e) => changeData('correspondentBankForSwift', e.target.value)}
                />
              </Col>

              <Col padding={GU} width={'70%'}>
                <Input
                  label={'IBAN/ACCOUNT NUMBER'}
                  value={paymentDetails.ibanAccountNumber}
                  onChange={(e) => changeData('ibanAccountNumber', e.target.value)}
                />
              </Col>

              <Col padding={GU} width={'30%'}>
                <InputLabel>CURRENCY</InputLabel>
                <DropDown
                  items={CURRENCIES}
                  width='100%'
                  selected={+paymentDetails.currency}
                  onChange={(item) => changeData('currency', item)}
                />
              </Col>
            </Row>
          </InnerBox>
        </Box>

        <Box padding={0}>
          <InnerBox>
            <Body1Light marginBottom={2 * GU}>Documents</Body1Light>

            <InputLabel marginBottom={GU}>Bank account Scan</InputLabel>
            <Row>
              <ImageUpload
                value={paymentDetails.bankAccountScan}
                preview={paymentDetails.bankAccountScanUrl}
                onChange={(file) => changeData('bankAccountScan', file)}
              />

              <Info style={{ marginLeft: '16px', height: 'fit-content' }}>
                Allowed file formats: .jpg, .png
                <br />
                Max. resolution: 640x640px
                <br />
                Max. file size: 500kb
              </Info>
            </Row>
          </InnerBox>
        </Box>

        <Row marginTop={3 * GU} justifyContent={'flex-end'}>
          <Button label={'Save'} mode={'strong'} onClick={() => onSave()} />
        </Row>
      </div>
    );
  }

  return <div />;
};

const InputLabel = styled(Label2)``;
