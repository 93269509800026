import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Box } from '../../../customAragon';
import {Body2Light, Title4, Title5, Text} from '../../../../ui';
import { COLORS } from '../../../../utils/static';

// todo: move to the env file
const DOMAIN = "https://kyc.spiritcapital.eu"

export const OfferBox = ({data}) => {
  const {
    id,
    title,
    info,
    presentation,
    presentationFile,
    sheet,
    sheetFile,
    extraSheet,
    extraSheetFile,
    brochure,
    brochureFile,
    extraBrochure,
    extraBrochureFile
  } = data;
  const { t } = useTranslation();

  return (
    <StyledBox active={true}>
      <NumberWrapper>
        <NumberCircle active={true}>{id}</NumberCircle>
      </NumberWrapper>

      <Title weight={300} color={COLORS.default}>{title}</Title>
      <Info>{info}</Info>

      {presentation && presentationFile && (
        <Body2Light marginTop={10}>
          {/*<Label>{t('Offer presentation')}</Label>:&nbsp;*/}
          <FileLink href={`${DOMAIN}/offers/${presentationFile}`} target="_blank">{presentation}</FileLink>
        </Body2Light>
      )}

      {sheet && sheetFile && (
        <Body2Light marginTop={10}>
          {/*<Label>{t('Offer sheet')}</Label>:&nbsp;*/}
          <FileLink href={`${DOMAIN}/offers/${sheetFile}`} target="_blank">{sheet}</FileLink>
        </Body2Light>
      )}

      {extraSheet && extraSheetFile && (
        <Body2Light marginTop={0}>
          {/*<Label>{t('Offer extra sheet')}</Label>:&nbsp;*/}
          <FileLink href={`${DOMAIN}/offers/${extraSheetFile}`} target="_blank">{extraSheet}</FileLink>
        </Body2Light>
      )}

      {brochure && brochureFile && (
        <Body2Light marginTop={10}>
          {/*<Label>{t('Offer brochure')}</Label>:&nbsp;*/}
          <FileLink href={`${DOMAIN}/offers/${brochureFile}`} target="_blank">{brochure}</FileLink>
        </Body2Light>
      )}

      {extraBrochure && extraBrochureFile && (
        <Body2Light marginTop={0}>
          {/*<Label>{t('Offer extra brochure')}</Label>:&nbsp;*/}
          <FileLink href={`${DOMAIN}/offers/${extraBrochureFile}`} target="_blank">{extraBrochure}</FileLink>
        </Body2Light>
      )}
    </StyledBox>
  );
};

const StyledBox = styled(Box)`
  border-radius: 12px;
  overflow: hidden;
  filter: drop-shadow(8px 12px 16px rgba(0, 0, 0, 0.08));
  border: none;
  height: 100%;

  :after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    height: 6px;
    width: 100%;
    background: ${p => p.bgColor ? p.bgColor : COLORS.aragonBlue};
  }
  
  > div,
  > div > div {
    height: 100%;
  }
`;
const Title = styled(Title5)`
  font-weight: 400;
`;
const Info = styled.div`
  font-size: 18px;
  line-height: 1.1;
`;
const Label = styled(Text)`
  color: #8c8787;
  display: inline;
`;
const FileLink = styled.a`
  cursor: pointer;
  text-decoration: underline;
`

const NumberWrapper = styled.div`
  text-align: right;
`;
const NumberCircle = styled.div`
  width: 24px;
  height: 24px;
  padding: 0 4px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  flex-shrink: 0;
  flex-grow: 0;
  font-size: 14px;
  font-weight: 300;
  color: ${COLORS.white};
  background: ${(p) =>
    p.active ? 'linear-gradient(200.38deg, #32FFF5 -153.94%, #08BEE5 84.51%)' : COLORS.greyLight};
`;
