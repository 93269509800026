import React from 'react';
import { Info as AragonInfo } from '@aragon/ui';
import { Body4Light } from '../../../ui';
import { COLORS } from '../../../utils/static';

export const Info = ({ children, ...props }) => {
  return (
    <AragonInfo {...props}>
      <Body4Light color={COLORS.aragonDarkBlue} style={{ textAlign: 'left' }}>
        {children}
      </Body4Light>
    </AragonInfo>
  );
};
