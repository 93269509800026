import React from 'react';
import { RepresentativeInfoBox, AddressBox, IdDocumentsBox } from '../../../../../../../components';
import { Container } from '../../../../../../../ui';

export const RepresentativeInfo = ({ data }) => {
  return (
    <Container>
      <RepresentativeInfoBox data={data.issuer} />

      <AddressBox data={data.addresses.legal[0]} />

      <IdDocumentsBox data={data.idDocument} />
    </Container>
  );
};
