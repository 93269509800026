import { investorApi } from '../../../api/investor';
import { ordersReducer } from '../../../store/orders';

export const getOrders = (params) => (dispatch) => {
  investorApi.getOrders(params).then((res) => {
    if (res.status === 200) {
      dispatch(ordersReducer.setOrders(res.data));
    }
  });
};

export const getOrder = (id, orders) => async (dispatch) => {
  if (orders) {
    const currentOrder = orders.filter((item) => item.id === id)[0];

    dispatch(ordersReducer.setCurrentOrder(currentOrder));
  } else {
    try {
      const res = await investorApi.getOrders();
      dispatch(getOrder(id, res.data.orders));
    } catch (e) {
      console.log('getOrdersRequest', e);
    }
  }
};

export const setFilters = (filters) => (dispatch) => {
  dispatch(ordersReducer.setFilters(filters));
};
export const removeFilters = () => (dispatch) => {
  dispatch(ordersReducer.setFilters(null));
};

export const cancelOrder = (id) => (dispatch) => {
  investorApi.cancelOrder(id).then((res) => {
    if (res.status === 200) {
      dispatch(ordersReducer.setCurrentOrder(res.data));
    }
  });
};
