export const ELEMENT_PRE_FILLED = {
  null: {
    label: '-',
    value: null
  },
  firstName: {
    label: 'First Name',
    value: 'firstName'
  },
  lastName: {
    label: 'Last Name',
    value: 'lastName'
  },
  email: {
    label: 'Email',
    value: 'email'
  },
  phone: {
    label: 'Phone',
    value: 'phone'
  },
}
