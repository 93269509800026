import React from 'react';
import { Bar, BackButton } from '@aragon/ui';
import { Body2Light } from '../../../../ui';
import { history } from '../../../../routing';
import { useTranslation } from 'react-i18next';

export const BackBar = ({ url = null }) => {
  const { t } = useTranslation();
  const onBack = () => {
    url ? history.push(url) : history.goBack();
  };

  return <Bar primary={<BackButton label={<Body2Light>{t('Back')}</Body2Light>} onClick={onBack} />} />;
};
