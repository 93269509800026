import React from 'react';
import { GU } from '@aragon/ui';
import { Box, InnerBox } from '../../../../components';
import { Input, Col, Row } from '../../../../ui';

export const AddressInputBox = ({ title = 'Address', data, changeData }) => {
  return (
    <Box heading={title} padding={0}>
      <InnerBox>
        <Row margin={-GU} cols={4} alignItems={'flex-end'}>
          <Col padding={GU}>
            <Input
              label={'Country'}
              value={data.country}
              onChange={(e) => changeData('country', e.target.value)}
            />
          </Col>
          <Col padding={GU}>
            <Input
              label={'City'}
              value={data.city}
              onChange={(e) => changeData('city', e.target.value)}
            />
          </Col>
          <Col padding={GU} width={'50%'}>
            <Input
              label={'Address'}
              value={data.address}
              onChange={(e) => changeData('address', e.target.value)}
            />
          </Col>
        </Row>
      </InnerBox>
    </Box>
  );
};
