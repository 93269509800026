import React, { useMemo, useState } from 'react';
import { Modal } from '@aragon/ui';
import { Col, Container, Hr, PageContainer, Row } from '../../../../ui/layout';
import { BackButton } from '../../../../ui/atoms/BackButton';
import { history } from '../../../../routing';
import { Box } from '../../../customAragon/Box';
import { Body1Light, Body2Light, Text, Title4 } from '../../../../ui/typography';
import { COLORS } from '../../../../utils/static/colors';
import {
  ENABLED_DOC_STATUSES_ADMIN, ENABLED_DOC_TO_VIEW_STATUSES_ADMIN,
  ENABLED_STATUSES_INVESTOR,
  STATUS_DISPLAY
} from '../../../../utils/static/kyc';
import {
  dateString,
  downloadFile,
  downloadFileByLink,
  getFileSrc,
  getUrlFromText
} from '../../../../utils/helpers/common';
import { Button } from '../../../../ui/atoms/Button';
import { ExternalLink } from '../../../../ui/atoms/Link';
import { useMutation } from 'react-query';
import { kycApi } from '../../../../api/kyc';
import { UploadDocumentsBox } from '../../InputBoxes/UploadDocuments';
import styled from 'styled-components';
import { entitiesActions } from '../../../../store/entities';
import { adminKYCApi } from '../../../../api/admin/kyc';
import { Input } from '../../../../ui/atoms/Input';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Checkbox } from 'antd';

export const DocRequestView = (
  {
    isFetching,
    data,
    refetch,
    requestId,
    investorId,
    viewMode = 'investor',
    approveRequest,
    rejectRequest
  }) => {
  const { t } = useTranslation();
  const { investor } = useSelector((state) => state);
  const [modalVisible, setModalVisible] = useState(false);
  const [addCommentToId, setAddCommentToId] = useState(-1);

  const requestInfo = useMemo(() => {
    if (data && data.data) {
      const { kycDocuments, ...requestObj } = data.data;
      return {
        ...requestObj,
        files:
          kycDocuments && kycDocuments.length > 0
            ? kycDocuments.map((file) => ({
              id: file.id,
              comment: file.comments[0] ? file.comments[0].comment : '',
              file: getFileSrc(file.documentURL),
              onlyAdmin: file.onlyAdmin
            }))
            : [{}]
      };
    }

    return {};
  }, [data]);

  const onBack = () => {
    viewMode === 'admin'
      ? history.push(`/investor/${investorId}`, { lastProfileTab: investor.lastProfileTab })
      : history.push('/profile', { lastProfileTab: investor.lastProfileTab });
  };

  const currentDocId = useMemo(() => {
    try {
      return requestInfo.files[0]?.id;
    } catch (e) {
    }

    return null;
  }, [requestInfo]);

  return (
    <StyledPageContainer paddingTop={24}>
      <Container marginBottom={32}>
        <BackButton onClick={onBack}/>
      </Container>

      {!isFetching && requestInfo && (
        <Box heading={requestInfo.kycTypeDocument?.title} headingSize={14} headingPaddingY={10}>
          <Row marginBottom={32}>
            <Col>
              <Body1Light>
                {t('Status')}:{' '}
                <Text
                  color={COLORS.aragonBlue}
                  inline
                  fontWeight={400}
                >
                  {requestInfo.status ? t(STATUS_DISPLAY[requestInfo.status]) : '-'}
                </Text>
              </Body1Light>

              <Body1Light marginTop={10}>
                {t('Created')}:{' '}
                <Text inline>
                  {requestInfo.created ? dateString(new Date(requestInfo.created)) : '-'}
                </Text>
              </Body1Light>
              <Body1Light>
                {t('Updated')}:{' '}
                <Text inline>
                  {requestInfo.updated ? dateString(new Date(requestInfo.updated)) : '-'}
                </Text>
              </Body1Light>
              <Body1Light>
                {t('Expiration date')}:{' '}
                <Text inline>
                  {requestInfo.expiration ? dateString(new Date(requestInfo.expiration)) : '-'}
                </Text>
              </Body1Light>
              {requestInfo.onlyAdmin &&
                <Body1Light>
                  {t('Only admin')}:{' '}
                  <Text inline>{'true'}</Text>
                </Body1Light>}
            </Col>

            <Row margin={-8}>
              <Col auto>
                <Button
                  label={t('Upload file')}
                  onClick={() => setModalVisible(true)}
                  mode={'strong'}
                  size={'small'}
                  disabled={
                    (viewMode === 'admin' && requestInfo.status && !ENABLED_DOC_TO_VIEW_STATUSES_ADMIN[requestInfo.status])
                    || (viewMode !== 'admin' && (investor.accountStatus !== 'ACTIVE' ||
                      (requestInfo.status && !ENABLED_STATUSES_INVESTOR[requestInfo.status])))
                  }
                />
              </Col>
              {viewMode === 'admin' && (
                <>
                  <Col auto>
                    <Button
                      label={t('Approve')}
                      onClick={() => approveRequest(currentDocId)}
                      mode={'strong'}
                      size={'small'}
                      disabled={!ENABLED_DOC_STATUSES_ADMIN[requestInfo.status]}
                    />
                  </Col>
                  <Col auto>
                    <Button
                      label={t('Reject')}
                      onClick={() => rejectRequest(currentDocId)}
                      size={'small'}
                      disabled={!ENABLED_DOC_STATUSES_ADMIN[requestInfo.status]}
                    />
                  </Col>
                </>
              )}
            </Row>
          </Row>

          {requestInfo.files.map((file, index) => (
            <Row margin={-16} marginBottom={8} justifyContent={'space-between'}>
              <Body1Light fontWeight={400}>
                {t('Attached file')}:&nbsp;
                {file.file ? (
                  <>
                    <ExternalLink onClick={() => downloadFileByLink('file', file.file)} external>
                      {t('File')}{requestInfo.files.length - index}
                    </ExternalLink>
                    {viewMode === 'admin' && file.onlyAdmin && (
                      <> {t('(Only admin)')}</>
                    )}
                  </>
                ) : (
                  '-'
                )}
              </Body1Light>
              <Col auto>
                {file.comment && <Body2Light color={COLORS.red}>{file.comment}</Body2Light>}
                {viewMode === 'admin' && file.file && !file.comment && (
                  <Button
                    label={t('New comment')}
                    onClick={() => setAddCommentToId(file.id)}
                    size={'small'}
                    mode={'strong'}
                  />
                )}
              </Col>
            </Row>
          ))}

          {requestInfo.kycTypeDocument?.descriptions && (
            <Container marginTop={32}>
              <Body1Light marginBottom={8}>{t('File description')}</Body1Light>
              <Body2Light>{getUrlFromText(requestInfo.kycTypeDocument?.descriptions)}</Body2Light>
            </Container>
          )}
        </Box>
      )}

      <UploadFileModal
        visible={modalVisible}
        onClose={() => setModalVisible(false)}
        refetchRequest={refetch}
        requestId={requestId}
        adminView={viewMode === 'admin'}
        investorId={investorId}
      />
      <AddCommentModal
        visible={addCommentToId !== -1}
        onClose={() => setAddCommentToId(-1)}
        refetchRequest={refetch}
        docId={addCommentToId}
      />
    </StyledPageContainer>
  );
};

const AddCommentModal = ({ visible, onClose, docId, refetchRequest }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [comment, setComment] = useState('');

  const addDocComment = async () => {
    try {
      dispatch(entitiesActions.showLoader(t('Saving') + '...'));
      await adminKYCApi.addRequestComment({ kycDocumentId: docId, comment });
      await refetchRequest();
      onClose();
    } catch (e) {
      console.error(e);
      dispatch(entitiesActions.addToast(t('Something went wrong')));
    } finally {
      dispatch(entitiesActions.hideLoader());
    }
  };

  return (
    <Modal visible={visible} onClose={onClose} padding={48}>
      <Input label={t('Comment')} value={comment} onChange={(e) => setComment(e.target.value)} />
      <Container marginTop={16} style={{ textAlign: 'center' }}>
        <Button label={t('Add comment')} onClick={addDocComment} mode={'strong'} />
      </Container>
    </Modal>
  );
};
const UploadFileModal = ({ visible, onClose, requestId, refetchRequest, adminView, investorId }) => {
  const { t } = useTranslation();
  const [file, setFile] = useState(null);
  const { mutateAsync } = useMutation(adminView ? adminKYCApi.uploadDocument : kycApi.uploadDocument);
  const [onlyAdmin, setOnlyAdmin] = useState(false);

  const onUpload = async () => {
    try {
      await mutateAsync({ requestDocumentId: requestId, file: file, onlyAdmin: onlyAdmin, ...(adminView && { investorId }) });
      await refetchRequest();
      onClose();
      setFile(null);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Modal visible={visible} onClose={onClose} padding={48}>
      <UploadDocumentsBox
        docs={file ? [file] : null}
        setDocs={(file) => setFile(file)}
        buttonProps={{
          label: t('Pick file')
        }}
      />

      {adminView && (
        <Body2Light marginTop={24}>
          <Checkbox name="OnlyAdmin"
                    value={onlyAdmin}
                    checked={onlyAdmin}
                    onChange={e => setOnlyAdmin(e.target.checked)}
          >
            {t('OnlyAdmin')}
          </Checkbox>
        </Body2Light>
      )}

      <Container marginTop={24} style={{ textAlign: 'center' }}>
        <Button label={t('Upload')} onClick={onUpload} mode={'strong'} disabled={!file} />
      </Container>
    </Modal>
  );
};

const StyledPageContainer = styled(PageContainer)`
  padding-bottom: 32px;
  max-width: 1200px;
  margin: 0 auto;
`;
