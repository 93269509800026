import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { TransactionDetailsView } from '../View';
import { adminApi } from '../../../../../api/admin';

export const TransactionDetails = () => {
  const { id } = useParams();
  const [transaction, setTransaction] = useState(null);

  useEffect(() => {
    fetchTransaction().then();
  }, []);

  const fetchTransaction = async () => {
    try {
      const res = await adminApi.getTransaction(id);
      setTransaction(res.data);
    } catch (e) {
      console.log('fetchTransaction error:', e);
    }
  };

  const updateTransactionStatus = async (data) => {
    try {
      await adminApi.updateTransactionStatus(data);
      fetchTransaction().then();
    } catch (e) {
      console.log('updateTransactionStatus error:', e);
    }
  };

  const executeTransaction = (id) => {
    updateTransactionStatus({ transactionId: id, status: 'EXECUTED' }).then();
  };

  const rejectTransaction = (id) => {
    updateTransactionStatus({ transactionId: id, status: 'DECLINED' }).then();
  };

  return (
    <TransactionDetailsView
      transaction={transaction}
      executeTransaction={executeTransaction}
      rejectTransaction={rejectTransaction}
    />
  );
};
