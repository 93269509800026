import React from 'react';
import styled from 'styled-components';

export const Tooltip = ({
  text,
}) => {
  return (
    <Box>
      <Icon>?</Icon>
      <TextBox>
        {text}
      </TextBox>
    </Box>
  );
};

const Box = styled.span`
  position: relative;
  top: -5px;
  display: inline-block;
  margin-left: 5px;
`;

const TextBox = styled.div`
  display: none;
  position: absolute;
  padding: 5px;
  z-index: 100;
  width: 200px;
  left: -200px;
  background: #ffffff;
  top: -30px;
  text-transform: capitalize;
  border-radius: 7px;
  box-shadow: 0 2px 7px #e0e0e0;
  font-size: 12px;
  line-height: 16px;
`;

const Icon = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  width: 16px;
  height: 16px;
  border-radius: 16px;
  background: rgb(8, 190, 229);
  //margin-left: 5px;
  cursor: help;
  font-size: 12px;
  
  &:hover + ${TextBox} {
    display: block;
  }
`;


