import React, { useState } from 'react';
import { getFileSrc } from '../../../../utils/helpers';
import { GU } from '@aragon/ui';
import { StatusLine } from '../../../../components';
import {
  Title5,
  Row,
  Col,
  Body1Light,
  Body3Light,
  RobotoBody4,
  Container,
  Button
} from '../../../../ui';
import { COLORS } from '../../../../utils/static/colors';
import styled from 'styled-components';
import TempAvatar from '../../../../assets/icons/avatar.png';
import { Skeleton, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import {Img} from '../../atoms';
import { EditOutlined } from '@ant-design/icons';
import { UpdateNameModal } from '../../../admin/modals';


/**
 * @param avatarUrl - server img url
 * @param avatar - front img url
 * @param name
 * @param region
 * @param position
 * @param verified
 * @param size - ['large' (def), 'medium', 'small']
 * @param nameOnly - true/false
 * @param props
 */

export const ProfileViewer = (
  {
    avatarUrl,
    avatar,
    firstName,
    lastName,
    region,
    position,
    verified = false,
    size = 'large',
    nameOnly = false,
    adminView,
    refetchInvestorInfo,
    ...props
  }) => {
  const { t } = useTranslation();
  const [isOpenChangeNameModal, setIsOpenChangeNameModal] = useState(false);

  const RenderTitle = (firstName, lastName, size) => {
    switch (size) {
      case 'small':
        return <Body3Light>{firstName} {lastName}</Body3Light>;
      case 'medium':
        return <Body1Light>{firstName} {lastName}</Body1Light>;
      default:
        return <Title5>{firstName} {lastName}</Title5>;
    }
  };

  return (
    <Row alignItems={'center'} {...props}>
      {!nameOnly && (
        <Avatar
          src={avatar ? avatar : (avatarUrl && getFileSrc(avatarUrl)) || TempAvatar}
          size={size}
        />
      )}
      <div>
        {RenderTitle(firstName, lastName, size)}

        {size === 'large' && (
          <Row margin={-0.5 * GU} alignItems={'center'} marginTop={0.5 * GU}>
            {region && <Body3Light color={COLORS.greyMedium}>{region}</Body3Light>}
            {position && <RobotoBody4 color={COLORS.aragonBlue}>{position}</RobotoBody4>}
            {verified && <StatusLine type={'accepted'} title={t('Verified')} colored />}
          </Row>
        )}
      </div>
      {adminView &&
        <>
          <div style={{marginLeft: '10px'}}>
            <Button type="text" icon={<EditOutlined />} onClick={() => setIsOpenChangeNameModal(true)} />
          </div>
          <UpdateNameModal
            open={isOpenChangeNameModal}
            onClose={() => setIsOpenChangeNameModal(false)}
            initialFirstName={firstName}
            initialLastName={lastName}
            refetchInvestorInfo={refetchInvestorInfo}
          />
        </>
      }

    </Row>
  );
};

export const ProfileViewerExtended = ({ children, rowProps, profileContainerProps, ...props }) => {
  return (
    <Row justifyContent={rowProps?.justifyContent || 'space-between'} {...rowProps}>
      <Container {...profileContainerProps}>
        <ProfileViewer {...props} size='large' />
      </Container>

      <Col auto>{children}</Col>
    </Row>
  );
};

export const ProfileViewerExtendedByDetails = ({ handleDetails, ...props }) => {
  const { t } = useTranslation();
  return (
    <ProfileViewerExtended profileContainerProps={{ marginTop: GU }} {...props}>
      <Button onClick={handleDetails} size={'small'} width={'75'}>
        <Body3Light>{t('Details')}</Body3Light>
      </Button>
    </ProfileViewerExtended>
  );
};

export const ProfileViewerSkeleton = () => (
  <Space>
    <Skeleton.Avatar shape={'circle'} active />
    <Skeleton.Input size={'small'} active />
  </Space>
);

const AvatarSize = {
  large: '64px',
  medium: '32px',
  small: '24px'
};
const MarginBySize = {
  large: `${2 * GU}px`,
  medium: `${GU}px`,
  small: `${GU}px`
};

const Avatar = styled(Img)`
  width: ${(p) => AvatarSize[p.size]};
  height: ${(p) => AvatarSize[p.size]};
  border-radius: 100%;
  margin-right: ${(p) => MarginBySize[p.size]};
  object-fit: cover;
`;
