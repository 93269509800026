import React, { useState } from 'react';
import { Split } from '@aragon/ui';
import { AccountSelector, PaymentDetailsView, PaymentDetailsForm } from '../../../../components';

export const ProfilePaymentsView = ({ payments = [], AddAccount, ChangeData, onSave }) => {
  const [selected, setSelected] = useState(0);

  const ChangeName = (e) => {
    ChangeData('accountName', e.target.value, selected);
  };

  if (payments.length) {
    return (
      <Split
        invert={'horizontal'}
        secondary={
          <AccountSelector
            accounts={
              payments &&
              payments.map(({ accountName, edited }) => ({
                accountName: accountName,
                edited: edited
              }))
            }
            selected={selected}
            onSetAccount={(value) => setSelected(value)}
            onAddAccount={AddAccount}
            ChangeName={ChangeName}
          />
        }
        primary={
          payments &&
          (!payments[selected].edited ? (
            <PaymentDetailsView paymentDetails={payments[selected]} />
          ) : (
            <PaymentDetailsForm
              paymentDetails={payments[selected]}
              changeData={(key, value) => ChangeData(key, value, selected)}
              onSave={() => {
                onSave();
                setSelected(0);
              }}
            />
          ))
        }
      />
    );
  }

  return <div>No payments</div>;
};
