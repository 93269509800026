import InvestorRegStart from './registration/investor/index';
import MainPage from './MainPage';
import InvestorReg from './registration/investor/InvestorReg';
import InvestorWishlist from './investor/Wishlist/index';
import UpcomingOffering from './investor/Dashboard/upcomingOffering';
import OperationDetail from './investor/Dashboard/operationDetail';
export * from './login';
export * from './admin';
export * from './investor';
export * from './EnterPage';
export * from './kyc';

export {
  InvestorRegStart,
  MainPage,
  InvestorReg,
  InvestorWishlist,
  UpcomingOffering,
  OperationDetail
};
