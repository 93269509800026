import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useCountries } from '../../../hooks';
import { KYCModule, MifidQuestionnaire, Questionnaire } from '../../../modules';
import { tier1Api, tier1Config as config, step4ToServer } from '../../../modules/kycModule';
import { SyncIndicator } from '@aragon/ui';
import { StepsPanel } from '../../../components';
import { Col, Row } from '../../../ui/layout';
import { Toast } from '../../../modules/kycModule/components/Toast';
import { history } from '../../../routing';

export const Tier1Page = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const COUNTRIES = useCountries();
  const [loader, setLoader] = useState(false);
  const [toast, setToast] = useState('');
  const [otherData, setOtherData] = useState(location.state || null);
  const [currentStep, setCurrentStep] = useState(location.state?.step || 5);

  useEffect(() => {
    // if (!history.location.state?.account) {
    //   history.push('/')
    // }
  }, []);

  const handleSave = async (data, step) => {
    let res;
    switch (step) {
      case 3: {
        const formatData = step4ToServer(data, COUNTRIES);
        res = await tier1Api[step](formatData, dispatch);
        break;
      }
      default: {
        res = await tier1Api[step](data, dispatch);
      }
    }

    if (step === 0) {
      setOtherData({ ...res });
    }
  };

  const updateCurrentStep = (nextStep) => {
    if (nextStep <= config.length - 1) {
      setCurrentStep(nextStep);
    }
  };

  const moduleProps = {
    config: config,
    currentStep: currentStep,
    setCurrentStep: updateCurrentStep,
    handleSave: handleSave,
    setShowLoader: (isVisible) => setLoader(isVisible),
    showError: (message) => setToast(message),
    otherData: otherData
  };

  return (
    <Row nowrap>
      <StepsPanel
        title={'Tier 1'}
        step={currentStep + 1}
        steps={config.map((item) => item.title)}
      />

      <Col>
        {config[currentStep].type === 'KYC' && <KYCModule {...moduleProps} />}
        {config[currentStep].type === 'questionnaire' && (
          <Questionnaire {...moduleProps} type={config[currentStep].type} />
        )}
        {config[currentStep].type === 'MIFID' && (
          <MifidQuestionnaire {...moduleProps} type={config[currentStep].type} />
        )}
      </Col>

      <SyncIndicator visible={loader}>Processing...</SyncIndicator>
      <Toast toast={toast} removeToast={() => setToast('')} />
    </Row>
  );
};
