import React, { useEffect, useMemo, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { SyncIndicator } from '@aragon/ui';
import { kycApi } from '../../api/kyc';
import { history } from '../../routing';
import { useMutation, useQuery } from 'react-query';
import { Toast } from '../kycModule/components/Toast';
import { ProductView } from './View';
import { kycHelpers } from '../../utils/helpers/kyc';
import { SHOWED_SECTIONS_COUNT } from '../../utils/static/kyc';
import { userActions } from '../../store/user';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { investorApi } from '../../api';

let appContainer = document.getElementById('app-container');

const getRejectedAnswersIds = (obj, questionId, answersIds, newAnswer) => {
  const resultArray = answersIds;
  Object.keys(obj).forEach(key => {

    if (key === 'questionId' && obj[key] === questionId && obj.childQuestionnaireId && obj.answer !== newAnswer.answer) {
      const duplicateIds = resultArray.filter((item) => item.childQuestionnaireId === obj.childQuestionnaireId);

      if (duplicateIds.length > 0) {
        duplicateIds.forEach((id) => id.answersIds.push(id.status === 'CREATED' || id.status === 'WITHOUT_CHANGE' ? newAnswer.id : id.status === 'DELETED' ? obj.id : null));
      } else {
        resultArray.push(
          {
            childQuestionnaireId: obj.childQuestionnaireId,
            status: 'CREATED',
            answersIds: [newAnswer.id]
          },
          {
            childQuestionnaireId: obj.childQuestionnaireId,
            status: 'DELETED',
            answersIds: [obj.id]
          }
        )
      }
    }
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      getRejectedAnswersIds(obj[key], questionId, resultArray, newAnswer)
    }
  })
  return resultArray;
}

export const Product = ({ adminView, adminEditView = false, referralView = false, investorId, productId }) => {
  const { referralToken } = useSelector((state) => state.user);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id: paramId, referralId } = useParams();
  const id = adminView ? productId : paramId;
  const { state } = useLocation();
  const { investor } = useSelector((state) => state);
  const [loader, setLoader] = useState(true);
  const [toast, setToast] = useState('');
  const [rejectedQuestions, setRejectedQuestions] = useState(null);
  const [rejectedSectionsIndexes, setRejectedSectionsIndexes] = useState(null);
  const [currentSection, setCurrentSection] = useState(0);
  const [currentStep, setCurrentStep] = useState(0);
  const [initialStep, setInitialStep] = useState(0);
  const [error, setError] = useState(undefined);

  const { mutateAsync: checkProductStatus } = useMutation(adminView ? kycApi.checkProductStatusForAdmin : kycApi.checkProductStatus);
  const {
    data: res,
    isFetching
  } = useQuery(['getProductsSections', referralToken], () => adminView ? kycApi.getProductsSectionsForAdmin(id, investorId) : referralView ? kycApi.getReferralProductsSections(referralToken, id) : kycApi.getProductsSections(id) , {
    enabled: (referralView && !!referralToken) || !referralView,
    cacheTime: 'Infnity',
    onSuccess: async ({ data: product }) => {
      try {
        if (product?.status === 'QUESTION_UNACCEPTED' || product?.status === 'UNACCEPTED') {
          const res = adminView ? await kycApi.getRejectedProductEntitiesForAdmin(investorId) : await kycApi.getRejectedProductEntities;
          const rejectedQuestions = res?.data?.question;

          const { rejectedObj, sectionsIndexes } = kycHelpers.getProductRejectedObject(
            product,
            rejectedQuestions
          );

          if (rejectedObj) setRejectedQuestions(rejectedObj);
          if (product?.status === 'QUESTION_UNACCEPTED') {
            if (sectionsIndexes.length > 0) {
              setRejectedSectionsIndexes(sectionsIndexes);
              setCurrentSection(sectionsIndexes[0]);
              setInitialStep(sectionsIndexes[0]);
            } else await nextStep();
          }
        }
      } catch (e) {
      } finally {
        setLoader(false);
      }
    }
  });
  const product = useMemo(() => (res ? res.data : null), [res]);

  useEffect(() => {
    dispatch(userActions.hideAppContainer());
    return () => dispatch(userActions.showAppContainer());
  }, []);

  useEffect(() => {
    if (paramId && !referralToken && referralView && referralId) {
      (async () => {
        try {
          const res = await investorApi.getReferralToken(referralId);

          if (res) {
            dispatch(userActions.setReferralToken(res.data.accessToken));
            if (error) setError(undefined);
          }
        } catch (e) {
          setError(e.response.data.message);
        }
      })()
    }
  }, [paramId, referralToken, referralId]);

  const stepsCount = useMemo(() => {
    if (rejectedSectionsIndexes) return rejectedSectionsIndexes.length;
    if (product && product.productSections)
      return Math.ceil(product.productSections.length / SHOWED_SECTIONS_COUNT);
    return 0;
  }, [product]);

  const onNext = async (data, insertedQuestions) => {
    try {
      setLoader(true);

      const { array, oneByOneRequests } = kycHelpers.collectProductsCurrentAnswers({
        product,
        data,
        insertedQuestions,
        currentSection
      });

      const answersIds = [];

      const alreadyClearedQuestions = {};
      //post data
      if (oneByOneRequests) {
        const post = async (index) => {
          const thisAnswer = array[index];

          if (
            thisAnswer &&
            (thisAnswer.answer || thisAnswer.availableAnswerId || thisAnswer.file)
          ) {
            const { clearAll, prePostCallback, key, ...answer } = thisAnswer;

            if (prePostCallback) {
              answer[key] = await prePostCallback();
            }
            const res = adminView ? await kycApi.saveQuestionProductForAdmin({
              ...answer,
              clearAll: clearAll && !alreadyClearedQuestions[answer.questionId],
              investorId: investorId
            }) : await kycApi.saveQuestionProduct({
              ...answer,
              clearAll: clearAll && !alreadyClearedQuestions[answer.questionId]
            });
            if (clearAll) alreadyClearedQuestions[answer.questionId] = true;

            if (rejectedQuestions) getRejectedAnswersIds(product, thisAnswer.questionId, answersIds, res.data);

            await post(index + 1);
          }
        };

        await post(0);

      } else {
        await Promise.all(
          array
            .filter((answer) => answer.answer || answer.availableAnswerId || answer.file)
            .map((answer) => new Promise(async (resolve, reject) => {
              try {
                const { prePostCallback, key, ...answerParams } = answer;

                if (prePostCallback) {
                  answerParams[key] = await prePostCallback();
                }
                if (adminView) answerParams.investorId = investorId;
                const res = adminView ? await kycApi.saveQuestionProductForAdmin(answerParams) : await kycApi.saveQuestionProduct(answerParams);

                if (rejectedQuestions) getRejectedAnswersIds(product, answer.questionId, answersIds, res.data);

                resolve();
              } catch (e) {
                reject();
              }
            }))
        );
      }

      //change step after posting
      await nextStep(answersIds);
    } catch (e) {
      alert(e?.message);
      console.error(e);
    } finally {
      setLoader(false);
    }
  };

  const onBack = () => {
    if (currentSection > 0) setCurrentSection(currentSection - 1);
    else state && state.prevPath ? history.push(state.prevPath, state.nextState) : history.goBack();
  };

  const nextStep = async (answersIds) => {
    const finish = async () => {
      await checkProductStatus({ id: id, ...(answersIds && answersIds.length > 0 && { rejectedSettings: answersIds }), investorId: investorId });
      adminView ? history.push('/investor/' + investorId) : history.push('/profile', { lastProfileTab: investor.lastProfileTab });
    };

    if (rejectedSectionsIndexes) {
      const nextStepIndex = currentStep + 1;
      const stepsLength = rejectedSectionsIndexes.length;

      if (nextStepIndex < stepsLength) {
        setCurrentStep(nextStepIndex);
        setCurrentSection(rejectedSectionsIndexes[nextStepIndex]);
        setInitialStep(rejectedSectionsIndexes[nextStepIndex]);
      } else {
        await finish();
      }
    }
    if (!rejectedSectionsIndexes) {
      if (currentSection + 1 < stepsCount) {
        setCurrentSection(currentSection + 1);
        if (currentSection >= initialStep) setInitialStep(currentSection + 1)
      }
      else await finish();
    }

    if (!appContainer) appContainer = document.getElementById('app-container');
    appContainer.parentNode.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const loading = (
    isFetching || !product || product.investorProductId !== id
  );

  return (
    <div>
      {!loading && (
        <ProductView
          adminEditView={adminEditView}
          referralView={referralView}
          product={product}
          currentSection={currentSection}
          currentStep={initialStep}
          onNext={onNext}
          onBack={onBack}
          rejectedQuestions={rejectedQuestions}
          setCurrentSection={setCurrentSection}
          setCurrentStep={setInitialStep}
          disabled={investor?.accountStatus !== 'ACTIVE'}
        />
      )}

      <SyncIndicator visible={loader}>{t('Processing')}...</SyncIndicator>
      <Toast toast={toast} removeToast={() => setToast('')}/>
    </div>
  );
};

export * from './View';
