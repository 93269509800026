import {
  ORDERS_ON_PAGE,
  DEAL_STATUSES,
  CURRENCIES,
  ASSET_TYPES_LIST,
  ORDER_TYPES_LIST,
  VALID_TILL_OPTIONS,
  MIFID_STATUSES
} from '../../../utils/static';
import { getOrdersTabStatuses } from '../common';

export const orderHasDealStatus = (status) => {
  return DEAL_STATUSES[status];
};

export const ordersFiltersToBack = (filters) => {
  const defaultFilters = {
    take: ORDERS_ON_PAGE,
    reverse: true
  };

  if (filters) {
    return {
      skip: filters.page * ORDERS_ON_PAGE,
      status: getOrdersTabStatuses(filters.selectedTab),
      currency: CURRENCIES[filters.currencyIndex - 1],
      assetType: ASSET_TYPES_LIST[filters.securityTypeIndex - 1],
      type: ORDER_TYPES_LIST[filters.orderTypeIndex - 1],
      orderValidTillOption: VALID_TILL_OPTIONS[filters.validTillIndex - 1],
      mifidStatus: MIFID_STATUSES[filters.mfidStatusIndex - 1],
      ...defaultFilters
    };
  }

  return defaultFilters;
};
