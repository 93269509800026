import { adminApi } from '../../../api/admin';
import { entitiesReducer } from '../../../store/entities';

export const getIssues =
  (params = {}) =>
  (dispatch) => {
    adminApi.getIssues(params).then((res) => {
      if (res.status === 200) {
        dispatch(entitiesReducer.setIssues(res.data));
      }
    });
  };

export const getIssuers =
  (params = {}) =>
  (dispatch) => {
    adminApi.getIssuers(params).then((res) => {
      if (res.status === 200) {
        dispatch(entitiesReducer.setIssuers(res.data));
      }
    });
  };

export const createIssuer = ({ issuerData, issuerIdDoc }) => {
  return new Promise(async (resolve) => {
    const res = await adminApi.createIssuer(issuerData);

    if (res.status === 200) {
      const { issuer } = res.data;

      adminApi.addIssuerIdDocument({ issuerId: issuer.id, ...issuerIdDoc }).then((res) => {
        if (res.status === 200) {
          resolve();
        }
      });
    }
  });
};

export const showLoader = (label) => (dispatch) => {
  dispatch(entitiesReducer.showLoader(label));
};

export const hideLoader = () => (dispatch) => {
  dispatch(entitiesReducer.hideLoader());
};

export const addToast = (message) => (dispatch) => {
  dispatch(entitiesReducer.setToast(message));
};
export const hideToast = () => (dispatch) => {
  dispatch(entitiesReducer.setToast(''));
};

export const setCountries = (countries) => (dispatch) => {
  dispatch(entitiesReducer.setCountries(countries));
};
export const setLanguages = (languages) => (dispatch) => {
  dispatch(entitiesReducer.setLanguages(languages));
};
