import React from 'react';
import { sendPhoneCode } from '../../serverInteraction';
import { GU } from '@aragon/ui';
import { LinkButton } from '../../../../ui';
import { FormInput } from '../../components';
import styled from 'styled-components';
import {CodeCountdown} from "../../../../components";

export const VerifyPhone = ({ form, otherData }) => {

  return (
    <div>
      <Subtitle>
        The verification code has been sent on your phone number:
        <br />
        <LinkButton>{otherData?.phone}</LinkButton>
      </Subtitle>

      <FormInput
        form={{
          ...form,
          rules: { required: 'Required' }
        }}
        label={'Verification code'}
        type={'number'}
      />

      <RepeatSendTitle>
          <CodeCountdown sendCode={sendPhoneCode} isInitialCall={true}/>
      </RepeatSendTitle>
    </div>
  );
};

const Subtitle = styled.p`
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 28px;
  color: #212b36;
  margin-bottom: 62px;
`;
const RepeatSendTitle = styled.p`
  margin: ${GU * 5}px 0 ${GU * 8}px 0;
`;
