import React, { useState } from 'react';
import { GU } from '@aragon/ui';
import { TableBoxContainer, SearchInput, DealsTable } from '../../../../components';
import { Col, Row, ExportButton } from '../../../../ui';

export const DealsTableBox = ({ deals, DEALS_ON_PAGE, fetchDeals }) => {
  const [selected, setSelected] = useState(0);
  const [search, setSearch] = useState('');

  const refetchDeals = (selectedPage = selected) => {
    fetchDeals({
      search: search,
      skip: selectedPage * DEALS_ON_PAGE
    });
  };

  const onUpdateSearch = () => {
    refetchDeals(0);
    setSelected(0);
  };

  const handleSearchKeyDown = (e) => {
    if (e.key === 'Enter') {
      onUpdateSearch();
    }
  };

  const onUpdatePage = (value) => {
    refetchDeals(value);
    setSelected(value);
  };

  if (deals) {
    return (
      <TableBoxContainer
        title={'Deals'}
        innerBoxProps={{ paddingBottom: GU }}
        aside={
          <Row margin={-GU}>
            <Col>
              <SearchInput
                value={search}
                onChange={setSearch}
                onBlur={onUpdateSearch}
                onKeyDown={handleSearchKeyDown}
                placeholder={'Search'}
              />
            </Col>

            <Col>
              <ExportButton />
            </Col>
          </Row>
        }
        // table={<DealsTable
        //   data={deals?.deals}
        // />}

        paginationSettings={{
          visible: deals.count > DEALS_ON_PAGE,
          selected: selected,
          pages: Math.ceil(deals.count / DEALS_ON_PAGE),
          onChange: onUpdatePage
        }}
      />
    );
  }

  return null;
};
