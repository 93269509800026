// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_card-custom__1xakg {\n  border: 1px solid rgba(209, 209, 209, 0.5) !important;\n  border-radius: 3px !important;\n  text-align: center;\n  cursor: pointer !important;\n  white-space: pre-line;\n  line-height: 19px;\n  color: rgba(0, 0, 0, 0.75);\n}\n.style_card-custom__1xakg > .style_img__3ShSs {\n  width: 104px;\n  height: 104px;\n  background: #dde4e9;\n  margin-bottom: 20px;\n}\n", "",{"version":3,"sources":["webpack://src/components/registrationComponents/OptionCard/style.module.css"],"names":[],"mappings":"AAAA;EACE,qDAAqD;EACrD,6BAA6B;EAC7B,kBAAkB;EAClB,0BAA0B;EAC1B,qBAAqB;EACrB,iBAAiB;EACjB,0BAA0B;AAC5B;AACA;EACE,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,mBAAmB;AACrB","sourcesContent":[".card-custom {\n  border: 1px solid rgba(209, 209, 209, 0.5) !important;\n  border-radius: 3px !important;\n  text-align: center;\n  cursor: pointer !important;\n  white-space: pre-line;\n  line-height: 19px;\n  color: rgba(0, 0, 0, 0.75);\n}\n.card-custom > .img {\n  width: 104px;\n  height: 104px;\n  background: #dde4e9;\n  margin-bottom: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card-custom": "style_card-custom__1xakg",
	"img": "style_img__3ShSs"
};
export default ___CSS_LOADER_EXPORT___;
