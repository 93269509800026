export { Step1 } from './Step1';
export { Step2 } from './Step2';
export { Step3 } from './Step3';
export { Step4 } from './legacy_toDel/Step4';
export { Step5 } from './legacy_toDel/Step5';
export { Step6 } from './legacy_toDel/Step6';
export { Step7 } from './legacy_toDel/Step7';
export { Step8 } from './legacy_toDel/Step8';
export { Step9 } from './legacy_toDel/Step9';
export { Step10 } from './legacy_toDel/Step10';
export * from './legacy_toDel/ImportKYCFiles';
