import React from 'react';
import { GU } from '@aragon/ui';
import {Input, Label2, Row} from '../../../../ui';
import { ImageUpload, Info } from '../../../../components';
import { COLORS } from '../../../../utils/static/colors';
import styled from 'styled-components';
import {useController} from "react-hook-form";

export const ImageUploadBox = ({
  label,
  labelMarginBottom = GU,
  preview,
  value,
  onChange,
  required = false,
  hideInfo = false
}) => {
  return (
    <div>
      <Label2 marginBottom={labelMarginBottom}>
        {label}
        {required && <Span color={COLORS.aragonBlue}> *</Span>}
      </Label2>
      <Row>
        <ImageUpload value={value} preview={preview} onChange={onChange} />

        {!hideInfo && (
          <Info style={{ marginLeft: '16px', height: 'fit-content' }}>
            Allowed file formats: .jpg, .png
            <br />
            Max. resolution: 640x640px
            <br />
            Max. file size: 500kb
          </Info>
        )}
      </Row>
    </div>
  );
};

export const ImageUploadBoxForm = ({form, ...props}) => {
  const {
    field: { ref, ...field },
    fieldState: { error }
  } = useController({
    ...form,
    defaultValue: ''
  });

  return (
    <ImageUploadBox
      {...field}
      {...props}
      error={error && error.message}
    />
  )
};

const Span = styled.span`
  ${(p) => (p.color ? `color: ${p.color}` : '')}
`;
