import { investorApi } from '../../../api/investor';
import { dealsReducer } from '../../../store/deals';

export const getDeals = (params) => (dispatch) => {
  investorApi.getDeals(params).then((res) => {
    if (res.status === 200) {
      dispatch(dealsReducer.setDeals(res.data));
    }
  });
};

export const getCurrentDeal = (id, deals) => async (dispatch) => {
  if (deals) {
    const currentDeal = deals.filter((item) => item.id === id)[0];

    dispatch(dealsReducer.setCurrentDeal(currentDeal));
  } else {
    try {
      const res = await investorApi.getDeals();
      dispatch(getCurrentDeal(id, res.data.deals));
    } catch (e) {
      console.log('getDealsRequest', e);
    }
  }
};

export const setFilters = (filters) => (dispatch) => {
  dispatch(dealsReducer.setFilters(filters));
};

export const removeFilters = () => (dispatch) => {
  dispatch(dealsReducer.setFilters(null));
};
