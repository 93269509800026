import React from 'react';
import { GU } from '@aragon/ui';
import { TotalFunds, TransactionsTableBox } from '../../../../../components';
import { PageContainer, Title2 } from '../../../../../ui';

export const FundsView = (props) => {
  return (
    <PageContainer marginTop={3 * GU}>
      <Title2 marginBottom={2 * GU}>Funds</Title2>

      <TotalFunds data={{}} />

      <TransactionsTableBox {...props} />
    </PageContainer>
  );
};
