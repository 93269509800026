import React, { useState } from 'react';
import { Header, Button, GU } from '@aragon/ui';
import { NewTransferPanel } from '../../../../containers';
import { SummaryTable, TransactionHistoryBox, Box } from '../../../../components';
import { Title2, PageContainer } from '../../../../ui';

export const WalletPage = ({
  cashSummary,
  transactions,
  transactionsInTable,
  updateFilters,
  ...props
}) => {
  const [opened, setOpened] = useState(false);

  return (
    <PageContainer>
      <Header
        primary={<Title2>Wallet</Title2>}
        secondary={<Button onClick={() => setOpened(true)} label='New transfer' mode='strong' />}
      />

      <Box padding={0}>
        <SummaryTable data={cashSummary} />
      </Box>

      <TransactionHistoryBox
        transactions={transactions}
        transactionsInTable={transactionsInTable}
        updateFilters={updateFilters}
        marginTop={2 * GU}
        {...props}
      />

      <NewTransferPanel opened={opened} setOpened={setOpened} />
    </PageContainer>
  );
};
