import React from 'react';
import { useTranslation } from 'react-i18next';
import { Collapse } from 'antd';

import { Title4 } from '../../../ui';
import { Answer } from './Answer';
import {StyledPanel} from '../styled';

export const AnswerList = ({list}) => {
  const { t } = useTranslation();

  return (
    <>
      <Title4>{t('Answers')}</Title4>

      <Collapse accordion>
        {list?.map(answer => (
          <StyledPanel header={answer.answer} key={answer.id}>
            <Answer answerProp={answer}/>
          </StyledPanel>
        ))}
      </Collapse>
    </>
  );
};
