import React, { useEffect, useState } from 'react';
import { history } from '../../routing';
import { useForm } from 'react-hook-form';
import { GU, SyncIndicator } from '@aragon/ui';
import { RegContainer, FooterButtons } from '../../components';
import { Container, PageContainer, Title2 } from '../../ui';
import { RenderField } from './RenderField';
import { Toast } from './components';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';

export * from './configs';
export * from './serverInteraction';

export const KYCModule = ({ currentStep = null, setCurrentStep = null, ...props }) => {
  if (currentStep !== null && setCurrentStep !== null) {
    return <Module currentStep={currentStep} setCurrentStep={setCurrentStep} {...props} />;
  }

  return <InnerStepsCount {...props} />;
};

const InnerStepsCount = (props) => {
  const location = useLocation();
  const [currentStep, setCurrentStep] = useState(location.state?.step || 0);

  return <Module currentStep={currentStep} setCurrentStep={setCurrentStep} {...props} />;
};

const Module = ({
  config,
  currentStep,
  setCurrentStep,
  handleSave,
  setShowLoader,
  showError,
  otherData,
  ...props
}) => {
  const { control, handleSubmit, reset } = useForm();

  useEffect(() => {
    reset();
  }, [currentStep]);

  const onSubmit = async (data) => {
    try {
      setShowLoader(true);

      await handleSave(data, currentStep);

      if (currentStep < config.length - 1) {
        setCurrentStep(currentStep + 1);
      }
    } catch (e) {
      console.error('SAVE_STEP', e);
      const errorMessage =
        errorsList[e?.response?.data?.message] || errorsList[e] || 'Something went wrong';
      showError(errorMessage);
    } finally {
      setShowLoader(false);
    }
  };

  const onBack = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    } else {
      history.goBack();
    }
  };

  if (!config) return null;

  return (
    <div {...props}>
      <RegContainer type={'centered'}>
        <PageContainer paddingTop={56}>
          <Title onClick={() => setCurrentStep(5)}>{config[currentStep].title}</Title>

          <form>
            {config[currentStep].fields.map((field, key) => (
              <Container marginBottom={3 * GU} key={key}>
                <RenderField
                  form={{
                    name: field,
                    control
                  }}
                  otherData={otherData}
                />
              </Container>
            ))}
          </form>

          <FooterButtons onNext={handleSubmit(onSubmit)} onBack={onBack} />
        </PageContainer>
      </RegContainer>
    </div>
  );
};

const Title = styled(Title2)`
  font-weight: 300;
  margin-bottom: ${8 * GU}px;
`;

const errorsList = {
  EMAIL_EXIST: 'Email already taken',
  PHONE_EXIST: 'Phone number already taken',
  CONFIRM_CODE_IS_WRONG: 'Wrong code',
  DOC_SUBTYPE_REQUIRED: 'Document subtype is required'
};
