import React from 'react';
import { GU, Text } from '@aragon/ui';
import { Box, InnerBox, ProfileViewer, BoxLine } from '../../../../components';
import { displayFullDate } from '../../../../utils/helpers/common';

export const RepresentativeInfoBox = ({ data }) => {
  return (
    <Box padding={0}>
      <InnerBox paddingBottom={2.5 * GU}>
        <ProfileViewer
          avatarUrl={data.logoURL}
          name={`${data.firstName} ${data.lastName}`}
          position={data.positionInTheCompany}
        />
      </InnerBox>

      <InnerBox paddingY={GU}>
        <BoxLine title={'Birth date'} data={displayFullDate(new Date(data.dateOfBirth), true)} />
        <BoxLine title={'Phone number'} data={data.phone} />
        <BoxLine title={'E-mail'} data={data.email} />
      </InnerBox>
    </Box>
  );
};
