import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Typography } from 'antd';

import { history } from '../../../routing';
import { Container, Title2 } from '../../../ui';
import { Box } from '../../../components';
import { useQuestionnaires } from '../hooks';

export const QuestionnaireList = () => {
  const { t } = useTranslation();
  const { data, isLoading } = useQuestionnaires();

  return (
    <>
      <Title2 marginBottom={30}>
        {t('Questionaire List')}
      </Title2>

      {isLoading && <div>Loading...</div>}

      {data?.map((questionnaire) => (
        <Box heading={questionnaire.type} padding={16}>
          {questionnaire.name && <Typography.Title level={5}>{questionnaire.name}</Typography.Title>}
          <Typography.Text level={5}>{questionnaire.title}</Typography.Text>

          <Container marginTop={12}>
            <Button
              mode={'strong'}
              onClick={() => history.push(`/constructor/questionnaires/${questionnaire.id}`)}
              type='primary'
            >
              Edit
            </Button>
          </Container>
        </Box>
      ))}
    </>
  );
};
