import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Checkbox, Input, Modal, Popconfirm, Radio } from 'antd';
import styled from 'styled-components';

import { ActionBox, Divider, Label, NestedBox, Space, Box, AnswerItem } from '../styled';
import { QuestionList } from './QuestionList';
import { Question } from './Question';
import { QuestionnaireDependedAnswers, QuestionnaireQuestion } from '../../constructor';
import { ProductDependedAnswers, ProductQuestion } from '../../constructorProducts';
const { TextArea } = Input;

const OPERATORS = [
  {
    label: 'All answers',
    value: 'AND',
  },
  {
    label: 'At least one answer',
    value: 'OR',
  }
];

export const Group = ({groupProp, sectionId, onCreated,
                        handleUpdate,
                        handleDelete,
                        handleCreate,
                        isCreating,
                        isUpdating,
                        isDeleting, type}) => {
  const { t } = useTranslation();
  const [group, setGroup] = useState(groupProp || {});
  const [dAnswers, setDAnswers] = useState([]);
  const [showCreateNew, setShowCreateNew] = useState(false);
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [isDepends, setIsDepends] = useState(false);

  useEffect(() => {
    if (!groupProp?.id || isModalOpened) return;

    setGroup(groupProp);
    if (!!groupProp?.visibleConditions?.length) {
      setIsDepends(true);
    }
  }, [groupProp])

  const onChange = (e) => {
    const {name, value} = e.target;
    setGroup({...group, [name]: value})
  }

  const onChangeOperator = ({ target: { value } }) => {
    setGroup({...group, visibleOperator: value})
  };

  const onAddAnswers = (ans) => {
    setDAnswers(ans);

    setIsModalOpened(false)
  }

  const onChangeCheckbox = (e) => {
    const {name, checked} = e.target;
    setGroup({...group, [name]: checked})
  }

  const handleAction = () => {
    const dAnswersProp = isDepends ? (dAnswers.length > 0 ? dAnswers : !!groupProp?.visibleConditions?.length ? null : []) : [];

    if (group.id) {
      handleUpdate({group, dAnswersProp});
    } else {
      handleCreate({group, sectionId, dAnswersProp}).then(res => {
        setGroup({});
        onCreated && onCreated();
      });
    }
  }

  const onDelete = () => {
    handleDelete(group);
  }

  const isDisabled = (!group.id && !group.title) || (
    group.title === groupProp?.title &&
    group.title_RU === groupProp?.title_RU &&
    group.title_LV === groupProp?.title_LV &&
    dAnswers.length === 0 &&
    !!groupProp?.visibleConditions?.length === isDepends &&
      group.sortOrder === groupProp?.sortOrder &&
      group.isMultiple === groupProp?.isMultiple &&
      group.multipleMax === groupProp?.multipleMax &&
      group.multipleMin === groupProp?.multipleMin &&
      group.visibleOperator === groupProp?.visibleOperator &&
      group.addToPdf === groupProp?.addToPdf
  );

  return (
    <Box isNew={!group.id}>
      <div>
        <Label>{t('Group Title')}</Label>
        <Input name='title' value={group.title} onChange={onChange} />
        <Space />
        {type === 'products' && (
          <>
            <Label>{t('Group Title')} (RU)</Label>
            <Input name='title_RU' value={group.title_RU} onChange={onChange} />
            <Space />
            <Label>{t('Group Title')} (LV)</Label>
            <Input name='title_LV' value={group.title_LV} onChange={onChange} />
            <Space />
          </>
        )}
        {group.id && type === 'questionnaire' && (
          <Checkbox name='addToPdf' checked={group.addToPdf} onChange={onChangeCheckbox}>
            {t('Add to PDF')}
          </Checkbox>
        )}
        <Space />
        <Checkbox
          name='isDepends'
          value={isDepends}
          checked={isDepends}
          onChange={(e) => {
            setIsDepends(e.target.checked);
            if (!e.target.checked) setDAnswers([]);
          }}
        >
          {t('This group depends on the answers')}
        </Checkbox>
        <Space />
        {isDepends && (
          <>
            <div>
              {dAnswers.map((item) => (
                <AnswerItem>{item.answer}</AnswerItem>
              ))}
            </div>
            <Space />
            <Button onClick={() => setIsModalOpened(true)} type='primary'>
              {group?.visibleConditions?.length
                ? t('Update dependent Answers')
                : t('Select dependent Answers')}
            </Button>

            <Space />
            <Radio.Group
              options={OPERATORS}
              onChange={onChangeOperator}
              value={group.visibleOperator}
            />

            <Modal
              title={t('Select dependent Answers')}
              width={700}
              style={{
                top: 20
              }}
              okText={'Add'}
              footer={null}
              open={isModalOpened}
              // onOk={onAddAnswers}
              onCancel={() => setIsModalOpened(false)}
            >
              {type === 'questionnaire' && (
                <QuestionnaireDependedAnswers
                  visibleConditions={groupProp?.visibleConditions}
                  onAddAnswers={onAddAnswers}
                />
              )}
              {type === 'products' && (
                <ProductDependedAnswers
                  visibleConditions={groupProp?.visibleConditions}
                  onAddAnswers={onAddAnswers}
                />
              )}
            </Modal>
          </>
        )}
        <Space />
        <Label>{t('Order')}</Label>
        <Input
          name='sortOrder'
          value={group.sortOrder}
          style={{ width: '100px' }}
          onChange={onChange}
        />
        <Space />
        <Flex>
          <Item>
            <Checkbox
              name='isRequired'
              value={group.isMultiple}
              checked={group.isMultiple}
              onChange={(e) => setGroup({ ...group, isMultiple: e.target.checked })}
            >
              {t('Is Multiple')}
            </Checkbox>
          </Item>

          {group.isMultiple && (
            <>
              <Item>
                <Label>{t('Multiple Min')}</Label>
                <Input name='multipleMin' value={group.multipleMin} onChange={onChange} />
              </Item>
              <Item>
                <Label>{t('Multiple Max')}</Label>
                <Input name='multipleMax' value={group.multipleMax} onChange={onChange} />
              </Item>
            </>
          )}
        </Flex>

        <ActionBox>
          <Button
            onClick={handleAction}
            disabled={isDisabled}
            type='primary'
            loading={isCreating || isUpdating}
          >
            {group.id ? t('Update Group') : t('Create Group')}
          </Button>
          {group.id && (
            <Popconfirm
              placement='topRight'
              title={t('Are you sure to delete this group?')}
              onConfirm={onDelete}
              okText={t('Yes')}
              cancelText={t('No')}
            >
              <Button danger loading={isDeleting}>
                {t('Delete Group')}
              </Button>
            </Popconfirm>
          )}
        </ActionBox>
      </div>

      <NestedBox>
        {group.id && <Divider />}
        {!!group.questions?.length && (
          <>
            <QuestionList list={group.questions} type={type} />
            <Space />
          </>
        )}
        {!!group.productQuestions?.length && (
          <>
            <QuestionList list={group.productQuestions} type={type} />
            <Space />
          </>
        )}

        {group.id && (
          <>
            {showCreateNew ? (
              <>
                {type === 'products' && (
                  <ProductQuestion groupId={group.id} onCreated={() => setShowCreateNew(false)} />
                )}
                {type === 'questionnaire' && (
                  <QuestionnaireQuestion
                    groupId={group.id}
                    onCreated={() => setShowCreateNew(false)}
                  />
                )}
              </>
            ) : (
              <Button onClick={() => setShowCreateNew(true)}>{t('Add new Question')}</Button>
            )}
          </>
        )}
      </NestedBox>
    </Box>
  );
};

const Flex = styled.div`
  display: flex;
`;

const Item = styled.div`
  width: 150px;
  padding-right: 20px;
`;