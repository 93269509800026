import { useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { adminLocalizationApi, adminLocalizationKeys } from '../../../api';
import { ConstructorApi } from '../api';
import { queryClient } from '../../../app/QueryClientProvider';
import { MessageContext } from '../../../app/MessageProvider';

export const useQuestionnaireSection = ({questionSectionTypeId}) => {
  const messageApi = useContext(MessageContext);
  const { t } = useTranslation();
  const [isCreating, setIsCreating] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const handleCreate = useCallback(async (section) => {
    try {
      setIsCreating(true);
      await ConstructorApi.createSection(section, questionSectionTypeId);
      await queryClient.refetchQueries({ queryKey: [adminLocalizationKeys.getQuestionnaireSections], queryFn: () => adminLocalizationApi.getQuestionnaireSections({ questionSectionTypeId: questionSectionTypeId }) });
      messageApi.success(t('Created'));
    } catch (e) {
      messageApi.error(e?.message || 'Something went wrong');
    } finally {
      setIsCreating(false);
    }
  }, []);

  const handleUpdate = useCallback(async (section) => {
    try {
      setIsUpdating(true);
      await ConstructorApi.updateSection(section);
      await queryClient.refetchQueries({ queryKey: [adminLocalizationKeys.getQuestionnaireSections], queryFn: () => adminLocalizationApi.getQuestionnaireSections({ questionSectionTypeId: questionSectionTypeId }) });
      messageApi.success(t('Saved'));
    } catch (e) {
      messageApi.error(e?.message || 'Something went wrong');
    } finally {
      setIsUpdating(false);
    }
  }, []);

  const handleDelete = useCallback(async (section) => {
    try {
      setIsDeleting(true);
      await ConstructorApi.deleteSection(section);
      await queryClient.refetchQueries({ queryKey: [adminLocalizationKeys.getQuestionnaireSections], queryFn: () => adminLocalizationApi.getQuestionnaireSections({ questionSectionTypeId: questionSectionTypeId }) });
      messageApi.success(t('Deleted'));
    } catch (e) {
      messageApi.error(e?.message || 'Something went wrong');
    } finally {
      setIsDeleting(false);
    }
  }, []);

  return {
    handleCreate,
    handleUpdate,
    handleDelete,
    isCreating,
    isUpdating,
    isDeleting
  }
}
