import React from 'react';
import { useTranslation } from 'react-i18next';
import { Collapse } from 'antd';

import { Title4 } from '../../../ui';
import { StyledPanel } from '../styled'
import { ProductGroup } from '../../constructorProducts';
import { QuestionnaireGroup } from '../../constructor';

export const GroupList = ({list, type}) => {
  const { t } = useTranslation();

  console.log('GroupList: list', list);
  return (
    <>
      <Title4>{t('Groups')}</Title4>

      <Collapse accordion>
        {list?.map(group => (
          <StyledPanel header={group.title} key={group.id}>
            {type === "products" && <ProductGroup groupProp={group} />}
            {type === "questionnaire" && <QuestionnaireGroup groupProp={group} />}
          </StyledPanel>
        ))}
      </Collapse>
    </>
  );
};
