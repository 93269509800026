import { investorApi } from '../../../api';
import { assetsReducer } from '../../assets';
import { dealsReducer } from '../../deals';

export const getAssets = (params) => async (dispatch) => {
  await investorApi.getAssets(params).then((res) => {
    if (res.status === 200) {
      const { count, assets } = res.data;
      const assetsModel = assets.map((item) => assetToModel(item));

      dispatch(assetsReducer.setAssets({ count: count, assets: assetsModel }));
    }
  });
};

export const getAsset = (id) => async (dispatch) => {
  await investorApi.getAsset(id).then((res) => {
    if (res.status === 200) {
      dispatch(assetsReducer.setCurrentAsset({ ...res.data }));
    }
  });
};

export const getMyAssets = (params) => async (dispatch) => {
  await investorApi.getMyAssets(params).then((res) => {
    if (res.status === 200) {
      const { count, myAssets } = res.data;

      dispatch(
        assetsReducer.setMyAssets({
          count: count,
          assets: myAssets.map((item) => {
            const { asset, ...details } = item;

            return { asset: assetToModel(asset), ...details };
          })
        })
      );
    }
  });
};

export const setFilters = (filters) => (dispatch) => {
  dispatch(assetsReducer.setFilters(filters));
};

export const removeFilters = () => (dispatch) => {
  dispatch(assetsReducer.setFilters(null));
};

const assetToModel = (asset) => {
  const { id, ticker, assetType, currency, issue } = asset;
  const { issuer, issueDate, numberOfSecurities, issuePrice } = issue;

  return {
    id: id,
    ticker: ticker,
    currency: currency,
    type: assetType,
    issuer: `${issuer.firstName} ${issuer.lastName}`,
    issueDate: issueDate,
    quantity: numberOfSecurities,
    price: issuePrice
  };
};
