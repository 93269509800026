import React, { useMemo, forwardRef } from 'react';
import { useController, Controller } from 'react-hook-form';
import { TextInput, GU } from '@aragon/ui';
import { Row, Col, Body4Light, Body3Light, Label2 as InputLabel } from '../../../ui';
import { COLORS } from '../../../utils/static';
import styled from 'styled-components';
import IconAttention from '../../../assets/icons/icon-attention.svg';
import {Tooltip} from '../Tooltip';

export const Input = forwardRef(
  (
    {
      label,
      tooltip,
      icon,
      marginBottom,
      marginRight,
      required = false,
      isRequired = false,
      adornmentOptions = null,
      adornment,
      textTransform = null,
      error = '',
      onChange,
      maxLength,
      clearRegex = null,
      isNumber = false,
      ...props
    },
    ref
  ) => {
    const handleChange = (e) => {
      if (maxLength) {
          if (e?.target?.value?.length <= maxLength) onChange(e);
        } else onChange(e);
    };

    const onClearField = (e) => {
      if (clearRegex) e.target.value = e.target.value.replace(clearRegex, '');
    };

    const adornmentProps = useMemo(() => {
      if (error) {
        return {
          adornment: <AttentionIcon src={IconAttention} />,
          adornmentPosition: 'end',
          adornmentSettings: { padding: 12, width: 62 },
          wide: true
        };
      }

      if (adornmentOptions) {
        const { title, position, padding, width } = adornmentOptions;

        return {
          adornment: <Body3Light color={COLORS.iconColor}>{title}</Body3Light>,
          adornmentPosition: position || 'end',
          adornmentSettings: { padding: padding || 8, width: width || 58 },
          wide: true
        };
      }

      return {
        adornment: adornment ? adornment : <div/>,
        adornmentPosition: 'end',
        adornmentSettings: { padding: 12, width: 0 },
        wide: true
      };
    }, [error, adornmentOptions, adornment]);

    return (
      <InputWrapper marginBottom={marginBottom} marginRight={marginRight}>
        {label && (
          <InputLabel>
            {label}
            {(required || isRequired) && <Span color={COLORS.aragonBlue}>&nbsp;*</Span>}
            {tooltip && <Tooltip text={tooltip}/>}
          </InputLabel>
        )}

        <Row alignItems={'center'}>
          <Col wide={!icon}>
            <InputContainer textTransform={textTransform} error={error}>
              <TextInput
                type={isNumber ? 'number' : 'text'}
                onInput={clearRegex ? onClearField : null}
                onChange={handleChange}
                {...props}
                {...adornmentProps}
                ref={ref}
              />

              {error && (
                <Error>
                  <Body4Light color={COLORS.greyMedium}>{error}</Body4Light>
                </Error>
              )}
            </InputContainer>
          </Col>

          <Col auto marginLeft={2 * GU}>
            {icon}
          </Col>
        </Row>
      </InputWrapper>
    );
  }
);

export const TextArea = ({ height, ...props }) => {
  return <StyledTextArea multiline height={height} {...props} />;
};

const InputWrapper = styled.div`
  text-align: left;
  ${(p) => (p.marginBottom ? `margin-bottom: ${p.marginBottom}px;` : '')}
  ${(p) => (p.marginRight ? `margin-right: ${p.marginRight}px;` : '')}
`;
const Span = styled.span`
  ${(p) => (p.color ? `color: ${p.color}` : '')}
`;

const InputContainer = styled.div`
  position: relative;

  & input {
    width: 100%;
    font-weight: 300;
    ${(p) => (p.textTransform ? `text-transform: ${p.textTransform};` : '')}
    ${(p) => (p.error ? `border-color: ${COLORS.red};` : '')}
        
        ::-webkit-outer-spin-button,
        ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }
`;
const StyledTextArea = styled(Input)`
  resize: none;
  ${(p) => (p.height ? `height: ${p.height}px;` : '')}
`;

const AttentionIcon = styled.img`
  width: 16px;
  height: 16px;
`;
const Error = styled.div`
  position: absolute;
  bottom: calc(50% + 8px + 9px);
  right: 0;
  min-width: 167px;
  padding: ${GU}px;
  background: ${COLORS.darkBabyBlue};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
  border-radius: 3px;

  :after {
    content: '';
    position: absolute;
    right: 16px;
    bottom: -3px;
    width: 8px;
    height: 8px;
    background: ${COLORS.darkBabyBlue};
    border-radius: 2px;
    transform: rotate(45deg) skew(10deg, 10deg);
    box-shadow: 3px 3px 5px rgb(0 0 0 / 12%);
  }
`;
