import { IconUser, IconGroup } from '@aragon/ui';
import { COLORS } from '../../../utils/static';

export const DOCUMENT_TYPES = ['PROOF_OF_FUNDS', 'MIFID', 'KYC'];
export const DISPLAY_DOCUMENT_TYPE = {
  PROOF_OF_FUNDS: 'Proof of funds',
  KYC: 'KYC',
  MIFID: 'MIFID'
};

export const INVESTOR_TYPES = ['PERSONAL', 'COMPANY'];

export const INVESTOR_TYPES_CONFIG = {
  PERSONAL: {
    title: 'Natural person',
    color: COLORS.iconColor,
    icon: IconUser
  },
  COMPANY: {
    title: 'Legal entity',
    color: COLORS.iconColor,
    icon: IconGroup
  }
};

export const INVESTOR_STATUSES = ['NOT_ACTIVE', 'ACTIVE', 'REJECTED'];

export const INVESTOR_STATUS_TABS = ['For approval', 'Active Investors', 'All Investors', 'Blocked'];
export const INVESTOR_STATUS_DESCRIPTION = ['Individual Clients Under Review', 'Active Individual Clients', 'All Individual Clients', 'Blocked Individual Clients'];
export const LEGAL_STATUS_TABS = ['Under Review', 'Active', 'All', 'Blocked'];
export const LEGAL_STATUS_DESCRIPTION = ['Legal Entities Under Review', 'Active Legal Entities', 'All Legal Entities', 'Blocked Legal Entities'];

export const STATUS_TAB_VALUE = ['ACTIVE', 'ACTIVE', null, 'NOT_ACTIVE'];
export const IS_IN_REVIEW_TAB_VALUE = [true, null, null, null];

export const DISPLAY_INVESTOR_ACCOUNT_STATUS = {
  ACTIVE: 'Active',
  NOT_ACTIVE: 'Blocked'
};

export const USER_TYPES = {
  USER: 'User'
};

export const USER_TYPES_LIST = ['USER'];
export const USER_TYPES_LIST_DISPLAY = USER_TYPES_LIST.map((item) => USER_TYPES[item]);
