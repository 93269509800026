import React, { useEffect, useMemo } from 'react';
import { AssetInfo } from '../../../../pages/investor';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { assetsActions } from '../../../../store/assets';

export const AssetInfoContainer = () => {
  const dispatch = useDispatch();
  let { id } = useParams();
  const { currentAsset, myAssets } = useSelector((state) => state.assets);
  const { personalAccounts } = useSelector((state) => state.investor);

  useEffect(() => {
    if (!currentAsset || currentAsset.id !== id) {
      dispatch(assetsActions.getAsset(id));
    }
  }, [id]);

  const myAssetQuantity = useMemo(() => {
    if (currentAsset && myAssets) {
      let myAsset = myAssets.assets.filter((item) => item.asset.id === currentAsset.id)[0];

      return myAsset ? myAsset.quantity : '';
    }

    return null;
  }, [currentAsset, myAssets]);

  return (
    <AssetInfo
      currentAsset={currentAsset?.id === id && currentAsset}
      personalAccounts={personalAccounts}
      myAssetQuantity={myAssetQuantity}
    />
  );
};
