import React from 'react';
import { useParams } from 'react-router-dom';
import { BackBar } from '../../../../../components';
import { PageContainer } from '../../../../../ui';

export const AssetDetailsView = () => {
  const { id } = useParams();

  return (
    <PageContainer>
      Asset {id}
      <BackBar />
    </PageContainer>
  );
};
