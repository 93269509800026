import React from 'react';
import styled from 'styled-components';
import CheckOutlined from '@ant-design/icons/lib/icons/CheckOutlined';
import GlobalOutlined from '@ant-design/icons/lib/icons/GlobalOutlined';
import { Dropdown } from 'antd';
import { useTranslation } from 'react-i18next';
import { LANGUAGES } from '../../../../app/localization/static';


export const RegContainer = ({ children }) => {
  const { i18n } = useTranslation();

  const setLanguage = (value) => {
    const newLang = LANGUAGES.find(i => i.code === value.key)?.code?.toLowerCase();
    i18n.changeLanguage(newLang);

    localStorage.setItem('language', newLang);
  };

  return (
    <div>
      <Header>
        <Dropdown
          menu={{
            items: LANGUAGES.map(item => ({
              key: item.code,
              label: item.language,
              icon: i18n.language === item.code.toLowerCase() ? <CheckOutlined /> : null
            })),
            onClick: setLanguage
          }}
          trigger={'click'}
        >
          <GlobalOutlined />
        </Dropdown>
      </Header>

      {children}
    </div>
  );
};

const Header = styled.div`
  position: fixed;
  width: 100%;
  height: 60px;
  padding: 0 32px 10px 32px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  font-size: 20px;
`;
