import React from 'react';
import { useTranslation } from 'react-i18next';
import { Collapse } from 'antd';

import { Title4 } from '../../../ui';
import { QuestionnaireAnswer } from '../../constructor';
import {StyledPanel} from '../styled';
import { ProductAnswer } from '../../constructorProducts';
import { sortWithNulls } from '../../../utils/helpers';

export const AnswerList = ({list, type}) => {
  const { t } = useTranslation();

  return (
    <>
      <Title4>{t('Answers')}</Title4>

      <Collapse accordion>
        {list?.sort((a, b) => sortWithNulls(Number(a.sortOrder), Number(b.sortOrder))).map(answer => (
          <StyledPanel header={answer.answer} key={answer.id}>
            {type === 'questionnaire' && <QuestionnaireAnswer answerProp={answer} />}
            {type === 'products' && <ProductAnswer answerProp={answer} />}
          </StyledPanel>
        ))}
      </Collapse>
    </>
  );
};
