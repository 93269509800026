import { useQuery } from 'react-query';
import { adminLocalizationApi, adminLocalizationKeys } from '../../../api';

export const useProducts = () => {
  const { isLoading, isError, data, error, refetch } = useQuery({
    queryKey: adminLocalizationKeys.getAllProducts,
    queryFn: adminLocalizationApi.getAllProducts,
    select: res => res.data
  });

  return {
    isLoading,
    isError,
    data,
    error,
    refetch
  }
}