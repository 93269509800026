import { useMemo, useCallback, useState, useContext } from 'react';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';

import { queryClient } from '../../../app/QueryClientProvider';
import { ConstructorApi, getDocumentTypes } from '../api';
import { MessageContext } from '../../../app/MessageProvider';
import { getDocumentSections } from '../api/index';
import { entitiesActions } from '../../../store/entities';
import { useDispatch } from 'react-redux';

export const useDocumentSection = (id) => {
  const messageApi = useContext(MessageContext);
  const { t } = useTranslation();
  const { isLoading, isError, data, error } = useQuery({
    queryKey: getDocumentSections,
    queryFn: getDocumentSections,
    select: res => res.data
  });
  const [isChangingRole, setIsChangingRole] = useState(false);

  const [isUpdating, seIstUpdating] = useState(false);

  const [isDeleting, setIsDeleting] = useState(false);

  const questionnaire = useMemo(() => {
    if (!data || !data?.length) return undefined;

    return data.find(item => item.id === id);
  }, [id, data]);

  const dispatch = useDispatch();

  const handleCreate = useCallback(async (qst) => {
    try {
      seIstUpdating(true);
      const res = await ConstructorApi.createDocumentSection(qst);
      await queryClient.refetchQueries({ queryKey: [getDocumentSections] });
      messageApi.success(t('created'));
      return res;
    } catch (e) {
      const errorMessage = e.response?.data?.message;
      if (errorMessage === 'NAME_EXIST') {
        dispatch(entitiesActions.addToast(t('NAME EXIST')));
      }
      messageApi.error(e?.message || 'Something went wrong');
    } finally {
      seIstUpdating(false);
    }
  }, []);

  const handleUpdate = useCallback(async (qst) => {
    try {
      seIstUpdating(true);
      await ConstructorApi.updateDocumentSection(qst);
      await queryClient.refetchQueries({ queryKey: [getDocumentSections] });
      messageApi.success(t('Saved'));
    } catch (e) {
      const errorMessage = e.response?.data?.message;
      if (errorMessage === 'NAME_EXIST') {
        dispatch(entitiesActions.addToast(t('NAME EXIST')));
      }
      messageApi.error(e?.message || 'Something went wrong');
    } finally {
      seIstUpdating(false);
    }
  }, []);

  const handleDelete = useCallback(async (section) => {
    try {
      setIsDeleting(true);
      await ConstructorApi.deleteDocumentSection(section);
      await queryClient.refetchQueries({ queryKey: [getDocumentSections] });
      messageApi.success(t('Deleted'));
    } catch (e) {
      messageApi.error(e?.message || 'Something went wrong');
    } finally {
      setIsDeleting(false);
    }
  }, []);

  const handleChangeRole = useCallback(async (role, checked) => {
    try {
      setIsChangingRole(true);

      if (checked) {
        await ConstructorApi.addRoleDocumentSection(id, role);
      } else {
        await ConstructorApi.deleteRoleDocumentSection(id, role);
      }
      await queryClient.refetchQueries({ queryKey: [getDocumentSections] });

      if (checked) {
        messageApi.success(t('Role Added'));
      } else {
        messageApi.success(t('Role Removed'));
      }

    } catch (e) {
      messageApi.error(e?.message || 'Something went wrong');
    } finally {
      setIsChangingRole(false);
    }
  }, []);

  return {
    data: questionnaire,
    handleCreate,
    handleUpdate,
    handleDelete,
    handleChangeRole,
    isChangingRole,
    isDeleting,
    isLoading,
    isUpdating,
    isError,
    error,
  }
}
