import React from 'react';
import { Table, TableRow, Text, GU } from '@aragon/ui';
import { ProfileViewer, StatusLine } from '../../../../components';
import { Cell, DetailsButton, TableHeader } from '../../components';
import { history } from '../../../../routing';
import {
  COLORS,
  DISPLAY_INVESTOR_ACCOUNT_STATUS,
  INVESTOR_TYPES_CONFIG
} from '../../../../utils/static';
import { Body3Light } from '../../../../ui';
import { dateString, displayFullDate } from '../../../../utils/helpers/common';
import { useTranslation } from 'react-i18next';

const defHeader = [
  'Client name',
  'Tier',
  'E-mail',
  'Representative',
  'Client status',
  'Created at',
  'Updated at'
];
export const InvestorsTable = ({ header = defHeader, data = [] }) => {
  const { t } = useTranslation();
  const headerItems = header.map((item, key) => <TableHeader title={t(item)} key={key} />);

  const bodyItems =
    data && data.length > 0 ? (
      data.map((item, key) => (
        <TableRow key={key}>
          <Cell>
            <ProfileViewer name={`${item.firstName} ${item.lastName}`} nameOnly size={'small'} />
          </Cell>
          <Cell>
            <Text>{item.tier ? `${t('Tier')} ${item.tier}` : '-'}</Text>
          </Cell>
          <Cell>
            <Text color={COLORS.blue}>{item.email}</Text>
          </Cell>
          <Cell>{item.representatives ? item?.representatives[0]?.answer : '-'}</Cell>
          <Cell>{t(DISPLAY_INVESTOR_ACCOUNT_STATUS[item.accountStatus])}</Cell>
          <Cell>{dateString(new Date(item.createdAt))}</Cell>
          <Cell>{dateString(new Date(item.updatedAt))}</Cell>

          <Cell cellProps={{ align: 'right' }}>
            <DetailsButton onClick={() => history.push(`/investor/${item.id}`)} />
          </Cell>
        </TableRow>
      ))
    ) : (
      <TableRow>
        <Cell cellProps={{ style: { border: 'none' } }}>{t('No investors')}</Cell>
      </TableRow>
    );

  return (
    <Table header={<TableRow>{headerItems}</TableRow>} noSideBorders>
      {bodyItems}
    </Table>
  );
};
