import React from 'react';
import { AssetCard } from '../../../../components';
import { Col, Row } from '../../../../ui';

//Unused
export const AssetsGrid = ({ items = [], ...props }) => (
  <Row cols={3} minCols={2} margin={'-14'} {...props}>
    {items.map((item, key) => (
      <Col key={key}>
        <AssetCard data={item} />
      </Col>
    ))}
  </Row>
);
