import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Select, Checkbox, Button, Input, Popconfirm, Modal, Radio } from 'antd';

import { ELEMENT_PRODUCT_TYPES, ELEMENT_TYPES } from '../constants';
import { ELEMENT_PRE_FILLED } from '../constantsPreFilled';
import { AnswerList } from './AnswerList';
import { QuestionnaireAnswer, QuestionnaireDependedAnswers } from '../../constructor';
import { ProductAnswer, ProductDependedAnswers } from '../../constructorProducts';
import { ActionBox, Space, Label, Divider, NestedBox, Box, AnswerItem } from '../styled';

const OPERATORS = [
  {
    label: 'All answers',
    value: 'AND',
  },
  {
    label: 'At least one answer',
    value: 'OR',
  }
];

const ELEMENT_DATE_FOR_SELECT = [
  {
    label: '-',
    value: null
  },
  {
    label: "no later than today's date",
    value: 'OLD'
  },
  {
    label: "not earlier than today\'s date",
    value: 'NEW'
  }
];

const ELEMENT_IS_TITLE_FOR_SELECT = [
  {
    label: '-',
    value: null
  },
  {
    label: "First Name(for person)",
    value: 'FirstName',
    title: 'person',
  },
  {
    label: "Last Name(for person)",
    value: 'LastName',
    title: 'person',
  },
  {
    label: "Legal Name(for company)",
    value: 'LegalName',
    title: 'company',
  }
];

export const Question = ({questionProp, groupId, onCreated,
                           handleCreate,
                           handleUpdate,
                           handleDelete,
                           isCreating,
                           isUpdating,
                           isDeleting, type}) => {
  const [question, setQuestion] = useState(questionProp || {});
  const { t } = useTranslation();
  const [showCreateNew, setShowCreateNew] = useState(false);
  const [isDepends, setIsDepends] = useState(false);
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [dAnswers, setDAnswers] = useState([]);
  const [isTitle, setIsTitle] = useState(false);
  const [iban, setIban] = useState(false);

  useEffect(() => {
    if (!questionProp?.id || isModalOpened) return;

    setQuestion(questionProp);
    if (questionProp?.person || questionProp?.company) setIsTitle(true);
    if (!!questionProp?.visibleConditions?.length) setIsDepends(true);
    if (questionProp?.minCountSymbols && questionProp?.maxCountSymbols && questionProp?.countFirstLetterSymbolsCountry) setIban(true);

  }, [questionProp]);

  const listTypes = useMemo(() => {
    const list = [];

    if (type === 'questionnaire') {
      for(const prop in ELEMENT_TYPES) {
        list.push(ELEMENT_TYPES[prop])
      }
    }
    if (type === 'products') {
      for(const prop in ELEMENT_PRODUCT_TYPES) {
        list.push(ELEMENT_PRODUCT_TYPES[prop])
      }
    }

    return list;
  }, [ELEMENT_TYPES, ELEMENT_PRODUCT_TYPES])

  const listPreFilled = useMemo(() => {
    const list = [];

    for(const prop in ELEMENT_PRE_FILLED) {
      list.push(ELEMENT_PRE_FILLED[prop])
    }

    return list;
  }, [ELEMENT_PRE_FILLED])

  const listDateForSelect = useMemo(() => {
    const list = [];

    for(const prop in ELEMENT_DATE_FOR_SELECT) {
      list.push(ELEMENT_DATE_FOR_SELECT[prop])
    }

    return list;
  }, [ELEMENT_DATE_FOR_SELECT])

  const listIsTitleForSelect = useMemo(() => {
    const list = [];

    for(const prop in ELEMENT_IS_TITLE_FOR_SELECT) {
      list.push(ELEMENT_IS_TITLE_FOR_SELECT[prop])
    }

    return list;
  }, [ELEMENT_IS_TITLE_FOR_SELECT])

  const handleAction = useCallback(() => {
    const dAnswersProp = isDepends ? (dAnswers.length > 0 ? dAnswers : !!questionProp?.visibleConditions?.length ? null : []) : [];

    if (question.id) {
      handleUpdate({question, dAnswersProp});
    } else {
      handleCreate({question, groupId, dAnswersProp}).then(res => {
        setQuestion({});
        onCreated && onCreated();
      });
    }
  }, [question, dAnswers, isDepends])

  const onDelete = () => {
    handleDelete(question);
  }

  const onAddAnswers = (ans) => {
    setDAnswers(ans);

    setIsModalOpened(false)
  }

  const changeIsTitle = (checked) => {
    if(checked) setIsTitle(true);
    if(!checked) {
      setQuestion({ ...question, person: null, company: null });
      setIsTitle(false);
    }
  }

  const setOnChangeIsTitle = (value, item) => {

    console.log('value', value)
    console.log('item', [item.title])
    if (!value) {
      setQuestion({...question, person: null, company: null});
    }
    else if (item.title === 'person'){
      setQuestion({...question, person: value, company: null});
    }
    else if (item.title === 'company'){
      setQuestion({...question, company: value, person: null});
    }
  }

  const onChangeOperator = ({ target: { value } }) => {
    setQuestion({...question, visibleOperator: value})
  };

  const onChangeCheckbox = (e) => {
    const {name, checked} = e.target;
    setQuestion({...question, [name]: checked})
  }

  const canHaveAnswer = question.id && (
    question.type === ELEMENT_TYPES.radio.value ||
    question.type === ELEMENT_TYPES.checkbox.value
  );

  const isDisabled = !question.question || !question.type || (
    question.question === questionProp?.question &&
    question.question_RU === questionProp?.question_RU &&
    question.question_LV === questionProp?.question_LV &&
    question.tooltip === questionProp?.tooltip &&
    question.type === questionProp?.type &&
    question.addToPdf === questionProp?.addToPdf &&
    dAnswers.length === 0 &&
    !!questionProp?.visibleConditions?.length === isDepends &&
    question.sortOrder === questionProp?.sortOrder &&
    question.isRequired === questionProp?.isRequired &&
    question.preFilledValue === questionProp?.preFilledValue &&
    (question.type === 'INPUT' ? (!!questionProp?.person || !!questionProp?.company) === isTitle : true) &&
    (question.type === 'INPUT' ? (!!questionProp?.minCountSymbols && !!questionProp?.maxCountSymbols && !!questionProp?.countFirstLetterSymbolsCountry) === iban : true) &&
    question.minCountSymbols === questionProp?.minCountSymbols &&
    question.maxCountSymbols === questionProp?.maxCountSymbols &&
    question.countFirstLetterSymbolsCountry === questionProp?.countFirstLetterSymbolsCountry &&
    question.person === questionProp?.person &&
    question.country === questionProp?.country &&
    question.dateForSelect === questionProp?.dateForSelect &&
    question.onlyLatin === questionProp?.onlyLatin
  );

  return (
    <Box isNew={!question.id}>
      <div>
        <Label>{t('Question')}</Label>
        <Input
          name='question'
          value={question.question}
          onChange={(e) => setQuestion({ ...question, question: e.target.value })}
        />
        <Space />
        {type === 'products' && (
          <>
            <Label>{t('Question')} (RU)</Label>
            <Input
              name='question_RU'
              value={question.question_RU}
              onChange={(e) => setQuestion({ ...question, question: e.target.value })}
            />
            <Space />
            <Label>{t('Question')} (LV)</Label>
            <Input
              name='question_LV'
              value={question.question_LV}
              onChange={(e) => setQuestion({ ...question, question: e.target.value })}
            />
            <Space />
          </>
        )}
        <Label>Type</Label>
        <Select
          name='type'
          onChange={(value) => setQuestion({ ...question, type: value })}
          value={question.type}
          placeholder={t('Select type')}
          style={{ width: '100%' }}
        >
          {listTypes.map((item) => (
            <Select.Option value={item.value}>{item.label}</Select.Option>
          ))}
        </Select>
        <Space />
        <Label>Tooltip</Label>
        <Input
          name='tooltip'
          value={question.tooltip}
          onChange={(e) => setQuestion({ ...question, tooltip: e.target.value })}
        />
        <Space />
        {question.id && type === 'questionnaire' && (
          <Checkbox name='addToPdf' checked={question.addToPdf} onChange={onChangeCheckbox}>
            {t('Add to PDF')}
          </Checkbox>
        )}
        <Space />
        <Checkbox
          name='isDepends'
          value={isDepends}
          checked={isDepends}
          onChange={(e) => {
            setIsDepends(e.target.checked);
            if (!e.target.checked) setDAnswers([]);
          }}
        >
          {t('This question depends on the answers to other questions')}
        </Checkbox>
        <Space />
        {isDepends && (
          <>
            <div>
              {dAnswers.map((item) => (
                <AnswerItem>{item.answer}</AnswerItem>
              ))}
            </div>
            <Space />
            <Button onClick={() => setIsModalOpened(true)} type='primary'>
              {question?.visibleConditions?.length
                ? 'Update dependent Answers'
                : 'Select dependent Answers'}
            </Button>

            <Space />
            <Radio.Group
              options={OPERATORS}
              onChange={onChangeOperator}
              value={question.visibleOperator}
            />

            <Modal
              title={'Select dependent Answers'}
              width={700}
              style={{
                top: 20
              }}
              okText={'Add'}
              footer={null}
              open={isModalOpened}
              // onOk={onAddAnswers}
              onCancel={() => setIsModalOpened(false)}
            >
              {type === 'questionnaire' && (
                <QuestionnaireDependedAnswers
                  visibleConditions={questionProp?.visibleConditions}
                  onAddAnswers={onAddAnswers}
                />
              )}
              {type === 'products' && (
                <ProductDependedAnswers
                  visibleConditions={questionProp?.visibleConditions}
                  onAddAnswers={onAddAnswers}
                />
              )}
            </Modal>
          </>
        )}
        <Space />
        <Checkbox
          name='isRequired'
          value={question.isRequired}
          checked={question.isRequired}
          onChange={(e) => setQuestion({ ...question, isRequired: e.target.checked })}
        >
          {t('Is required')}
        </Checkbox>
        <Space />
        <Label>{t('Order')}</Label>
        <Input
          name='sortOrder'
          value={question.sortOrder}
          style={{ width: '100px' }}
          onChange={(e) => setQuestion({ ...question, sortOrder: e.target.value })}
        />
        <Space />
        {(question.type === 'INPUT' || question.type === 'EMAIL' || question.type === 'PHONE') && (
          <>
            <Label>{t('Pre Filled Value')}</Label>
            <Select
              name='preFilledValue'
              onChange={(value) => setQuestion({ ...question, preFilledValue: value })}
              value={question.preFilledValue}
              placeholder={t('Select Pre Filled Value')}
              style={{ width: '100%' }}
            >
              {listPreFilled.map((item) => (
                <Select.Option value={item.value}>{item.label}</Select.Option>
              ))}
            </Select>
            <Space />
          </>
        )}

        {type === 'questionnaire' && (
          <>
              <Checkbox name="onlyLatin"
                        value={question.onlyLatin}
                        checked={question.onlyLatin}
                        onChange={e => setQuestion({ ...question, onlyLatin: e.target.checked })}
              >
                {t('Only Latin')}
              </Checkbox>

            <Space/>
          </>
        )}
        {question.type === 'INPUT' && (
          <>
            <Checkbox
              name='IsTitle'
              value={isTitle}
              checked={isTitle}
              onChange={(e) => changeIsTitle(e.target.checked)}
            >
              {t('Is Title')}
            </Checkbox>

            <Space />
          </>
        )}
        {isTitle && (
          <>
            <Select
              name='isTitle'
              onChange={(value, title) => setOnChangeIsTitle(value, title)}
              value={question.person || question.company}
              placeholder={t('Select Is Title Value')}
              style={{ width: '100%' }}
            >
              {listIsTitleForSelect.map((item) => (
                <Select.Option value={item.value} title={item.title}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
            <Space />
          </>
        )}

        {question.type === 'INPUT' && (
          <>
            <Checkbox
              name='iban'
              value={iban}
              checked={iban}
              onChange={(e) => setIban(e.target.checked)}
            >
              {t('IBAN Question')}
            </Checkbox>

            <Space />
          </>
        )}
        {iban && (
          <>
            <Space />
            <Label>{t('Min Count Symbols')}</Label>
            <Input
              name='minCountSymbols'
              type='number'
              min={0}
              max={100}
              step={1}
              value={question.minCountSymbols}
              style={{ width: '100px' }}
              onChange={(e) => setQuestion({ ...question, minCountSymbols: e.target.value })}
            />

            <Label>{t('Max Count Symbols')}</Label>
            <Input
              name='maxCountSymbols'
              type='number'
              min={0}
              max={100}
              step={1}
              value={question.maxCountSymbols}
              style={{ width: '100px' }}
              onChange={(e) => setQuestion({ ...question, maxCountSymbols: e.target.value })}
            />

            <Label>{t('Number Of First Letters Of Characters')}</Label>
            <Input
              name='countFirstLetterSymbolsCountry'
              type='number'
              min={0}
              max={100}
              step={1}
              value={question.countFirstLetterSymbolsCountry}
              style={{ width: '100px' }}
              onChange={(e) =>
                setQuestion({ ...question, countFirstLetterSymbolsCountry: e.target.value })
              }
            />
            <Space />
            <Space />
          </>
        )}

        {question.type === 'DATE' && (
          <>
            <Label>{t('Date Limit')}</Label>
            <Select
              name='dateForSelect'
              onChange={(value) => setQuestion({ ...question, dateForSelect: value })}
              value={question.dateForSelect}
              placeholder={t('Select Date Limit')}
              style={{ width: '100%' }}
            >
              {listDateForSelect.map((item) => (
                <Select.Option value={item.value}>{item.label}</Select.Option>
              ))}
            </Select>
            <Space />
          </>
        )}

        <ActionBox>
          <Button
            onClick={handleAction}
            disabled={isDisabled}
            type='primary'
            loading={isCreating || isUpdating}
          >
            {question.id ? t('Update Question') : t('Create Question')}
          </Button>
          {question.id && (
            <Popconfirm
              placement='topRight'
              title={t('Are you sure to delete this question?')}
              onConfirm={onDelete}
              okText={t('Yes')}
              cancelText={t('No')}
            >
              <Button danger loading={isDeleting}>
                {t('Delete Question')}
              </Button>
            </Popconfirm>
          )}
        </ActionBox>
      </div>

      {canHaveAnswer && (
        <NestedBox>
          {question.id && <Divider />}
          {type === 'questionnaire' && !!question.availableAnswers?.length && (
            <>
              <AnswerList list={question.availableAnswers} type={type} />
              <Space />
            </>
          )}
          {type === 'products' && !!question.productAvailableAnswers?.length && (
            <>
              <AnswerList list={question.productAvailableAnswers} type={type} />
              <Space />
            </>
          )}

          {showCreateNew ? (
            <>
              {type === 'products' && (
                <ProductAnswer questionId={question.id} onCreated={() => setShowCreateNew(false)} />
              )}
              {type === 'questionnaire' && (
                <QuestionnaireAnswer
                  questionId={question.id}
                  onCreated={() => setShowCreateNew(false)}
                />
              )}
            </>
          ) : (
            <Button onClick={() => setShowCreateNew(true)}>{t('Add new Answer')}</Button>
          )}
        </NestedBox>
      )}
    </Box>
  );
};
