import React from 'react';
import { GU } from '@aragon/ui';
import { AddressBox, BoxLine, Box, InnerBox } from '../../../../components';

export const BankAccountBox = ({ data, address, ...props }) => {
  return (
    <Box padding={0} {...props}>
      <InnerBox paddingY={2 * GU}>
        <BoxLine title={'BIC/SWIFT code'} data={data.bicSwiftCode} />
        <BoxLine title={'Bank name'} data={data.bankName} />
        <BoxLine title={'Bank code'} data={data.bankCode} />
      </InnerBox>

      <InnerBox paddingY={GU} paddingBottom={2 * GU}>
        <BoxLine title={'IBAN/account number'} data={data.ibanAccountNumber} />
      </InnerBox>

      <AddressBox data={address} inner paddingY={2 * GU} />
    </Box>
  );
};
