import axios from 'axios';
import { API_URL } from '../../utils/static/api';
import { objectToFormData } from '../../utils/helpers';

export const getInvestors = (params) => {
  return axios.get(`${API_URL}/api/v1/admin/investors`, { params: params });
};

export const getInvestor = (id) => {
  return axios.get(`${API_URL}/api/v1/admin/investor`, { params: { investorId: id } });
};
export const getInvestorCitizenship = (id) => {
  return axios.get(`${API_URL}/api/kyc/v1/admin/investor/citizenship`, {
    params: { investorId: id }
  });
};
const getInvestorAddInfoQuestId = (id) => {
  return axios.get(`${API_URL}/api/kyc/v1/admin/questionnaires/PersonalData`, {
    params: { investorId: id }
  });
};

export const getInvestorAddresses = (id) => {
  return axios.get(`${API_URL}/api/v1/admin/investor/addresses`, { params: { investorId: id } });
};

export const getInvestorIdDocs = (id) => {
  return axios.get(`${API_URL}/api/v1/admin/investor/idDocuments`, { params: { investorId: id } });
};

export const getInvestorPaymentDetails = (id) => {
  return axios.get(`${API_URL}/api/v1/admin/investor/paymentDetails`, {
    params: { investorId: id }
  });
};

export const createIssue = (data, issueDocuments) => {
  let reqBody = new FormData();
  Object.keys(data).forEach((key) => {
    reqBody.append(key, data[key]);
  });

  issueDocuments.forEach((doc) => {
    reqBody.append('issueDocument', doc);
  });

  return axios.post(`${API_URL}/api/v1/admin/issue`, reqBody);
};

export const getIssues = (params) => {
  return axios.get(`${API_URL}/api/v1/admin/issues`, { params: params });
};

export const getIssuers = (params) => {
  return axios.get(`${API_URL}/api/v1/admin/issuers`, { params: params });
};

export const getIssuer = (id) => {
  return axios.get(`${API_URL}/api/v1/admin/issuer`, { params: { issuerId: id } });
};

export const getAssets = (params) => {
  return axios.get(`${API_URL}/api/v1/admin/assets`, { params: params });
};

export const getTotalInstruments = (params) => {
  return axios.get(`${API_URL}/api/v1/admin/assets/totalNumberOfInstruments`, { params: params });
};

export const getDeals = (params) => {
  return axios.get(`${API_URL}/api/v1/admin/deals`, { params: params });
};

export const getTotalTurnover = (params) => {
  return axios.get(`${API_URL}/api/v1/admin/deals/totalTurnover`, { params: params });
};

export const getTransactions = (params) => {
  return axios.get(`${API_URL}/api/v1/admin/transactions`, { params: params });
};

export const getTransaction = (id) => {
  return axios.get(`${API_URL}/api/v1/admin/transaction`, { params: { transactionId: id } });
};

export const getFundsSummary = (params) => {
  return axios.get(`${API_URL}/api/v1/admin/funds/summary`, { params: params });
};

export const getOrders = (params) => {
  return axios.get(`${API_URL}/api/v1/admin/orders`, { params: params });
};

export const getOrder = (id) => {
  return axios.get(`${API_URL}/api/v1/admin/order`, { params: { orderId: id } });
};

export const createIssuer = (data) => {
  return axios.post(`${API_URL}/api/v1/admin/issuer`, objectToFormData(data));
};

export const addIssuerIdDocument = (idDoc) => {
  return axios.post(`${API_URL}/api/v1/admin/issuer/idDocument`, objectToFormData(idDoc));
};

export const updateInvestor = (data) => {
  return axios.put(`${API_URL}/api/v1/admin/investor`, data);
};

export const updateInvestorAddress = (data) => {
  return axios.put(`${API_URL}/api/v1/admin/investor/address`, data);
};

export const updateInvestorIdDoc = (data) => {
  return axios.put(`${API_URL}/api/v1/admin/investor/idDocument`, objectToFormData(data));
};

export const updateTransactionStatus = (data) => {
  return axios.put(`${API_URL}/api/v1/admin/transaction/status`, data);
};

export const downloadPdf = (url) => {
  return axios.get(`${API_URL}/${url}`, {
    responseType: 'blob'
  });
};

const updateUserRole = ({investorId, role}) => {
  return axios.put(`${API_URL}/api/kyc/v1/admin/investor/role`, { investorId, role });
};

export * from './kyc';
export * from './localization';
export * from './referrals';

export const adminApi = {
  getInvestors,
  getInvestor,
  getInvestorCitizenship,
  getInvestorAddInfoQuestId,
  getInvestorIdDocs,
  getInvestorAddresses,
  getInvestorPaymentDetails,
  createIssue,
  getIssues,
  getIssuers,
  getIssuer,
  getAssets,
  getTotalInstruments,
  getDeals,
  getTotalTurnover,
  getTransactions,
  getTransaction,
  getFundsSummary,
  getOrders,
  getOrder,
  createIssuer,
  addIssuerIdDocument,
  updateInvestor,
  updateInvestorAddress,
  updateInvestorIdDoc,
  updateTransactionStatus,
  downloadPdf,
  updateUserRole
};
