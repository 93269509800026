import React from 'react';
import { checkoutActions } from '../../../../store/checkout';
import { BuySellAssetView } from '../../../../components';
import { useDispatch } from 'react-redux';
import { history } from '../../../../routing';

export const BuySellAsset = ({ side = 'BUY', issuer, ...props }) => {
  const dispatch = useDispatch();

  const onMakeOrder = (data) => {
    dispatch(
      checkoutActions.setCheckoutInfo(
        {
          side: side,
          issuer: issuer,
          ...props.asset,
          ...data
        },
        () => {
          history.push('/checkout');
        }
      )
    );
  };

  return <BuySellAssetView onMakeOrder={onMakeOrder} side={side} {...props} />;
};
