import React, { useMemo, useState } from 'react';
import {
  RegContainer,
  StepsPanel,
  FooterButtons,
  InnerBox,
  Box,
  IdDocumentsInputBox
} from '../../../../../components';
import styled from 'styled-components';
import { Input, Title2, Label2, Row, Col, Body1Light, AutoComplete } from '../../../../../ui';
import { GU } from '@aragon/ui';
import { individualSteps, COUNTRIES } from '../../../../../utils/static';
import { useDispatch } from 'react-redux';
import { newObject } from '../../../../../utils/helpers';
import { investorApi } from '../../../../../api/investor';
import { investorActions } from '../../../../../store/investor';
import { entitiesActions } from '../../../../../store/entities';

//ToDO: implement IdDocuments InputBox component
export const Step4 = ({ stepNumber, goBack, goNext }) => {
  const dispatch = useDispatch();
  const [generalInfo, setGeneralInfo] = useState({
    firstName: '',
    lastName: '',
    taxResidency: '',
    tinNR: ''
  });
  const [permanentAddress, setPermanentAddress] = useState({
    country: '',
    city: '',
    address: ''
  });
  const [actualAddress, setActualAddress] = useState({
    country: '',
    city: '',
    address: ''
  });
  const [idDocument, setIdDocument] = useState({
    birthDate: null,
    idDocumentPhoto: null,
    selfieWithIdPhoto: null,
    idDocument: '',
    documentNumber: '',
    personalCode: '',
    issuingState: '',
    issuingOrganization: '',
    dateOfIssuance: '',
    dateOfExpiry: '',
    maritalStatus: '',
    nameSurnameOfSpouse: ''
  });

  const changeLegalAddress = (key, value) => {
    setPermanentAddress(newObject(permanentAddress, key, value));
  };

  const changeActualAddress = (key, value) => {
    setActualAddress(newObject(actualAddress, key, value));
  };

  const changeGeneralInfo = (key, value) => {
    setGeneralInfo(newObject(generalInfo, key, value));
  };

  const changeIdDocument = (key, value) => {
    setIdDocument(newObject(idDocument, key, value));
  };

  const handleNext = async () => {
    try {
      dispatch(entitiesActions.showLoader('Processing...'));

      let lastResStatus = false;

      await investorApi
        .addGeneralInfo({
          dateOfBirth: idDocument.birthDate,
          ...generalInfo
        })
        .then((res) => {
          lastResStatus = res.status === 200;
        });

      if (lastResStatus) {
        await investorApi
          .addAddress({
            type: 'PERMANENT_RESIDENTIAL',
            ...permanentAddress
          })
          .then((res) => {
            lastResStatus = res.status === 200;
          });
      }

      if (lastResStatus) {
        await investorApi
          .addAddress({
            type: 'ACTUAL',
            ...actualAddress
          })
          .then((res) => {
            lastResStatus = res.status === 200;
          });
      }

      if (lastResStatus) {
        await investorApi.addIdDocument({ ...idDocument }).then((res) => {
          dispatch(investorActions.setInvestor({ idDocument: res.data }));
          lastResStatus = res.status === 200;

          if (lastResStatus) goNext();
        });
      }

      // goNext();
    } catch (e) {
      console.log('addPersonalData', e);
      const errorMessage = e.response.data.message;
      if (errorMessage === 'ID_DOCUMENT_EXIST') {
        dispatch(entitiesActions.addToast('This ID is already taken'));
      } else {
        dispatch(entitiesActions.addToast('Something went wrong'));
      }
    } finally {
      dispatch(entitiesActions.hideLoader());
    }
  };

  const disableNext = useMemo(() => {
    for (const [key, value] of Object.entries(generalInfo)) {
      if (!value) {
        console.log(key);
        return true;
      }
    }
    for (const [key, value] of Object.entries(permanentAddress)) {
      if (!value) {
        console.log(key);
        return true;
      }
    }
    for (const [key, value] of Object.entries(actualAddress)) {
      if (!value) {
        console.log(key);
        return true;
      }
    }
    for (const [key, value] of Object.entries(idDocument)) {
      if (!value && key !== 'personalCode' && key !== 'nameSurnameOfSpouse') return true;
    }

    return false;
  }, [generalInfo, permanentAddress, actualAddress, idDocument]);

  return (
    <StepsPanelContainer>
      <StepsPanel title={'Registration'} step={stepNumber} steps={individualSteps} />

      <RegContainer>
        <Title style={{ textAlign: 'center' }} onClick={goNext}>
          Personal data
        </Title>

        <Box padding={0}>
          <InnerBox>
            <Body1Light>General information</Body1Light>

            <Row margin={-GU} marginTop={3 * GU}>
              <Col padding={GU}>
                <Input
                  label={'First Name'}
                  value={generalInfo.firstName}
                  onChange={(e) => changeGeneralInfo('firstName', e.target.value)}
                  required
                />
              </Col>
              <Col padding={GU}>
                <Input
                  label={'Last name'}
                  value={generalInfo.lastName}
                  onChange={(e) => changeGeneralInfo('lastName', e.target.value)}
                  required
                />
              </Col>
            </Row>
          </InnerBox>
        </Box>

        <Box heading='Permanent residential address' padding={0}>
          <InnerBox>
            <Row margin={-GU}>
              <Col padding={GU} width={'25%'}>
                <AutoComplete
                  label={'Country'}
                  items={COUNTRIES}
                  value={permanentAddress.country}
                  onChange={(value) => changeLegalAddress('country', value)}
                  required
                  wide
                />
              </Col>
              <Col padding={GU} width={'25%'}>
                <Input
                  label={'City'}
                  value={permanentAddress.city}
                  onChange={(e) => changeLegalAddress('city', e.target.value)}
                  required
                />
              </Col>
              <Col padding={GU} width={'50%'}>
                <Input
                  label={'Address'}
                  value={permanentAddress.address}
                  onChange={(e) => changeLegalAddress('address', e.target.value)}
                  required
                />
              </Col>
            </Row>
          </InnerBox>
        </Box>

        <Box heading='Actual Address' padding={0}>
          <InnerBox>
            <Row margin={-GU}>
              <Col padding={GU} width={'25%'}>
                <AutoComplete
                  label={'Country'}
                  items={COUNTRIES}
                  value={actualAddress.country}
                  onChange={(value) => changeActualAddress('country', value)}
                  required
                  wide
                />
              </Col>
              <Col padding={GU} width={'25%'}>
                <Input
                  label={'City'}
                  value={actualAddress.city}
                  onChange={(e) => changeActualAddress('city', e.target.value)}
                  required
                />
              </Col>
              <Col padding={GU} width={'50%'}>
                <Input
                  label={'Address'}
                  value={actualAddress.address}
                  onChange={(e) => changeActualAddress('address', e.target.value)}
                  required
                />
              </Col>
            </Row>
          </InnerBox>
        </Box>

        <Box paddingX={3.5 * GU}>
          <Row margin={-0.75 * GU}>
            <Col padding={0.75 * GU} width={'25%'}>
              <Input
                label={'TAX residency'}
                value={generalInfo.taxResidency}
                onChange={(e) => changeGeneralInfo('taxResidency', e.target.value)}
                required
              />
            </Col>
            <Col padding={0.75 * GU} width={'50%'}>
              <Input
                label={'TIN NR'}
                value={generalInfo.tinNR}
                onChange={(e) => changeGeneralInfo('tinNR', e.target.value)}
                required
              />
            </Col>
          </Row>
        </Box>

        <IdDocumentsInputBox data={idDocument} changeData={changeIdDocument} />

        <FooterButtons onBack={goBack} onNext={handleNext} nextDisabled={disableNext} />
      </RegContainer>
    </StepsPanelContainer>
  );
};

const StepsPanelContainer = styled.div`
  display: flex;
`;
const Title = styled(Title2)`
  font-weight: 300;
  margin-bottom: ${3 * GU}px;
`;
const InputLabel = styled(Label2)``;
const DatePickerWrapper = styled.div`
  & > div {
    display: block;
  }
`;
