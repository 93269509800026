import React from 'react';
import { useTranslation } from 'react-i18next';
import { Collapse } from 'antd';

import { Title4 } from '../../../ui';
import { QuestionnaireQuestion } from '../../constructor';
import {StyledPanel} from '../styled';
import { ProductQuestion } from '../../constructorProducts/components/ProductQuestion';

export const QuestionList = ({list, type}) => {
  const { t } = useTranslation();

  return (
    <>
      <Title4>{t('Questions')}</Title4>

      <Collapse accordion>
        {list?.map(question => (
          <StyledPanel header={question.question || question.id} key={question.id}>
            {type === 'questionnaire' && <QuestionnaireQuestion questionProp={question}/>}
            {type === 'products' && <ProductQuestion questionProp={question}/>}
          </StyledPanel>
        ))}
      </Collapse>
    </>
  );
};
