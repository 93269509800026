import React, { useEffect } from 'react';
import { investorApi } from '../../../api/investor';
import { checkoutActions } from '../../../store/checkout';
import { useDispatch, useSelector } from 'react-redux';
import { history } from '../../../routing';
import { Checkout } from '../../../pages/investor';
import { VALID_TILL_DAY_OPTION } from '../../../utils/static';
import { entitiesActions } from '../../../store/entities';

export const CheckoutContainer = () => {
  const dispatch = useDispatch();
  const { info } = useSelector((state) => state.checkout);

  useEffect(() => {
    if (!info) history.goBack();
  }, []);

  const confirmOrder = () => {
    let { issuer, assetType, currency, orderValidTill, ...checkoutInfo } = info;

    if (checkoutInfo.orderValidTillOption === VALID_TILL_DAY_OPTION) {
      let date = new Date();
      date.setDate(date.getDate() + 1);

      orderValidTill = date;
    }

    investorApi
      .makeOrder({
        ...checkoutInfo,
        orderValidTill: orderValidTill,
        paymentMethod: 'ACCOUNT' //static on first iteration
      })
      .then((res) => {
        if (res.status === 200) {
          history.push('/orders');

          dispatch(checkoutActions.deleteCheckoutInfo());
        }
      })
      .catch((e) => {
        try {
          const errorMessage = e.response.data.message;
          if (errorMessage === 'NOT_ENOUGH_MONEY_IN_THE_ACCOUNT') {
            dispatch(entitiesActions.addToast('Not enough money to make order'));
          }
        } catch (e) {
          console.log(e);
        }
      });
  };

  return <Checkout checkoutInfo={info} confirmOrder={confirmOrder} />;
};
