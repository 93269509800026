import React from 'react';
import { GU } from '@aragon/ui';
import { Box, InnerBox, BoxLine, ProfileViewer } from '../../../../components';

export const IssuerDetailsBox = ({ data }) => {
  if (data) {
    const { company, issuerAddresses, ...issuerData } = data;
    const legalAddress = formatLegalAddress(issuerAddresses);

    return (
      <Box heading='Issuer' padding={0}>
        <InnerBox padding={2 * GU}>
          {company && (
            <ProfileViewer
              avatarUrl={company.logoURL}
              name={company.name}
              region={issuerData.region}
              verified
            />
          )}
        </InnerBox>

        <InnerBox>
          {legalAddress && <BoxLine title='Legal adress' data={legalAddress} />}

          {(issuerData.firstName || issuerData.lastName) && (
            <BoxLine title='Account name' data={`${issuerData.firstName} ${issuerData.lastName}`} />
          )}

          {issuerData.positionInTheCompany && (
            <BoxLine title='President' data={issuerData.positionInTheCompany} />
          )}

          {company && company.registrationNumber && (
            <BoxLine title='Registration number' data={company.registrationNumber} />
          )}
        </InnerBox>

        {issuerData.turnover && issuerData.assetsSum && (
          <InnerBox>
            {issuerData.turnover && <BoxLine title='Turnover' data={issuerData.turnover} />}
            {issuerData.assetsSum && <BoxLine title='Assets sum' data={issuerData.assetsSum} />}
          </InnerBox>
        )}
      </Box>
    );
  }

  return null;
};

const formatLegalAddress = (issuerAddresses) => {
  let legalAddress = issuerAddresses
    ? { ...issuerAddresses.filter((item) => item.type === 'LEGAL')[0] }
    : null;

  if (legalAddress) {
    const { country, city, address } = legalAddress;

    legalAddress.country = `${country ? `${country}` : ''}`;
    legalAddress.city = `${country && (city || address) ? ', ' : ''}${city ? `${city}` : ''}`;
    legalAddress.address = `${city && address ? ', ' : ''}${address ? `${address}` : ''}`;
  }

  return `${legalAddress.country}${legalAddress.city}${legalAddress.address}`;
};
