import React from 'react';
import { history } from '../../../../routing';
import { Table, TableRow, Text } from '@aragon/ui';
import { ProfileViewer } from '../../../../components';
import { Cell, DetailsButton, TableHeader, EmptyBody } from '../../components';
import { COLORS } from '../../../../utils/static';
import styled from 'styled-components';

const defHeader = ['ID', 'company Name', 'E-mail', 'Country', 'Representative'];
export const IssuersTable = ({ header = defHeader, data = [] }) => {
  const headerItems = header.map((item, key) => <TableHeader title={item} key={key} />);

  const bodyItems =
    data && data.length > 0 ? (
      data.map((item, key) => (
        <TableRow key={key}>
          <Cell>{item.id}</Cell>

          <Cell>
            <ProfileViewer
              name={item.company.name}
              avatarUrl={item.company.logoURL}
              size={'small'}
            />
          </Cell>

          <Cell>
            <Text color={COLORS.blue}>{item.company.email}</Text>
          </Cell>

          <Cell>{item.company.countryOfRegistration}</Cell>

          <Cell>
            <ProfileViewer name={`${item.firstName} ${item.lastName}`} size={'small'} />
          </Cell>

          <Cell cellProps={{ align: 'right' }}>
            <DetailsButton onClick={() => history.push(`issuer/${item.id}`)} />
          </Cell>
        </TableRow>
      ))
    ) : (
      <EmptyBody title={'No issues'} />
    );

  return (
    <StyledTable
      header={<TableRow style={{ height: '48px' }}>{headerItems}</TableRow>}
      noSideBorders
    >
      {bodyItems}
    </StyledTable>
  );
};

const StyledTable = styled(Table)`
  th {
    border-left: none;
    border-right: none;
    white-space: pre-line;
    line-height: 16px;
  }
`;
