import React, { useState } from 'react';
import { Header, Bar, BackButton, Text, Box } from '@aragon/ui';
import textStyle from '../../../utils/textStyle';
import styles from '../style.module.css';
import coin from '../../../assets/icons/coin_icon.svg';

const OperationDetail = () => {
  return (
    <div>
      <Header primary='Your Deal detail' />
      <Bar primary={<BackButton />} />
      <Box className={styles['detailBox']}>
        <div style={{ display: `flex`, justifyContent: `space-between`, marginBottom: `30px` }}>
          <div>
            <img style={{ verticalAlign: `-12px` }} src={coin} alt='' />
            <Text style={{ fontSize: `48px`, marginLeft: `36px` }} color='#21D48E'>
              +100
            </Text>
          </div>
          <div style={{ display: `flex`, flexDirection: `column`, marginTop: `8px` }}>
            <Text style={{ fontSize: `15px` }}>22 Feb 2018 14:15</Text>
            <Text style={{ fontSize: `15px` }} color='#21D48E'>
              Asset
            </Text>
          </div>
        </div>
        <Text style={textStyle('body5')}>
          Parties. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit
          officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.
        </Text>
      </Box>
    </div>
  );
};

export default OperationDetail;
