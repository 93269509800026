import { API_URL } from '../../static';
import {getFilenameFromUrl} from "../common";

//collect already answered questionnaire
const collectQuestionnaireAnswers = (questionnaire) => {
  const values = {};
  const preFilledQuestions = {};
  let lastAnsweredSection = -1;

  questionnaire.questionSections.forEach((section, sectionIndex) => {
    section.questionGroups.forEach((questionGroup) => {
      let collectedQuestions = [];

      questionGroup.questions.forEach((question) => {
        if (questionGroup.isMultiple) {
          switch (question.type) {
            case 'DOC_UPLOAD': {
              try {
                const answers = question.answers;
                if (answers && answers.length > 0) {
                  answers.forEach((answer, index) => {
                    const url = `${API_URL}/${answer.documentURL}`;

                    if (answer.preFilled) {
                      preFilledQuestions[`group_${questionGroup.id}.${index}`] = {
                        ...preFilledQuestions[`group_${questionGroup.id}.${index}`],
                        [question.id]: true
                      };
                    }

                    !collectedQuestions[index]
                      ? (collectedQuestions[index] = { [question.id]: url })
                      : (collectedQuestions[index][question.id] = url);
                  });

                  if (answers.filter((answer) => !answer.isRejected).length > 0) {
                    lastAnsweredSection = sectionIndex;
                  }
                }
              } catch (e) {}

              break;
            }
            default: {
              const answers = question.answers;
              if (answers && answers.length > 0) {
                answers.forEach((answer, index) => {
                  if (answer.preFilled) {
                    preFilledQuestions[`group_${questionGroup.id}.${index}`] = {
                      ...preFilledQuestions[`group_${questionGroup.id}.${index}`],
                      [question.id]: true
                    };
                  }

                  !collectedQuestions[index]
                    ? (collectedQuestions[index] = { [question.id]: answer.answer })
                    : (collectedQuestions[index][question.id] = answer.answer);
                });
                if (answers.filter((answer) => !answer.isRejected && !answer.preFilled).length > 0) {
                  lastAnsweredSection = sectionIndex;
                }
              }
            }
          }
        } else {
          switch (question.type) {
            case 'DOC_UPLOAD':
            case 'RECORD_VIDEO':
            case 'SIGNATURE': {
              const answerObj = question.answers[0];
              if (answerObj) {
                values[question.id] = `${API_URL}/${answerObj.documentURL}`;
                if (answerObj.preFilled) preFilledQuestions[question.id] = true;
                if (!answerObj.isRejected && !answerObj.preFilled) lastAnsweredSection = sectionIndex;
              }
              break;
            }
            case 'RADIO': {
              const answerObj = question.answers[0];
              if (answerObj) {
                values[question.id] = answerObj.availableAnswerId;
                if (answerObj.preFilled) preFilledQuestions[question.id] = true;
                if (!answerObj.isRejected && !answerObj.preFilled) lastAnsweredSection = sectionIndex;
              }
              break;
            }
            case 'INPUT':
            case 'AGENT_CODE':{
              const answerObj = question.answers[0];
              if (answerObj) {
                values[question.id] = answerObj.answer;
                if (answerObj.preFilled) preFilledQuestions[question.id] = true;
                if (!answerObj.isRejected && !answerObj.preFilled) lastAnsweredSection = sectionIndex;
              }
              break;
            }
            case 'CHECKBOX':
            case 'CONFIRMATIONS': {
              const answers = question.answers;
              if (answers.length > 0) {
                values[question.id] = answers.map((item) => {
                  if (item.preFilled) preFilledQuestions[question.id] = true;
                  if (!item.isRejected && !item.preFilled) lastAnsweredSection = sectionIndex;
                  return item.availableAnswerId;
                });
              }
              break;
            }
            case 'ADDRESS': {
              const answerObj = question.answers[0];
              if (answerObj) {
                values[question.id] = JSON.parse(answerObj.answer);
                if (answerObj.preFilled) preFilledQuestions[question.id] = true;
                if (!answerObj.isRejected && !answerObj.preFilled) lastAnsweredSection = sectionIndex;
              }
              break;
            }
            case 'PHONE': {
              const answerObj = question.answers[0];
              if (answerObj) {
                if (answerObj.numberOfSymbolsInCode) {
                  values[question.id] = [answerObj.answer.slice(0, answerObj.numberOfSymbolsInCode), answerObj.answer.slice(answerObj.numberOfSymbolsInCode + 1)].join('/')
                } else {
                  values[question.id] = answerObj.answer
                }
                if (answerObj.preFilled) preFilledQuestions[question.id] = true;
                if (!answerObj.isRejected && !answerObj.preFilled) lastAnsweredSection = sectionIndex;
              }
              break;
            }
            default: {
              const answerObj = question.answers[0];
              if (answerObj) {
                values[question.id] = answerObj.answer;
                if (answerObj.preFilled) preFilledQuestions[question.id] = true;
                if (!answerObj.isRejected && !answerObj.preFilled) lastAnsweredSection = sectionIndex;
              }
            }
          }
        }
      });

      if (questionGroup.isMultiple && collectedQuestions.length > 0) {
        values[`group_${questionGroup.id}`] = collectedQuestions;
      }
    });
  });


  return { values, lastAnsweredSection, preFilledQuestions };
};

//collect already answered products
const collectProductsAnswers = (product) => {
  const values = {};
  const preFilledQuestions = {};
  let lastAnsweredSection = -1;

  product.productSections.forEach((section, sectionIndex) => {
    section.productGroups.forEach((productGroup) => {
      let collectedQuestions = [];

      productGroup.productQuestions.forEach((question) => {
        if (productGroup.isMultiple) {
          switch (question.type) {
            case 'DOC_UPLOAD': {
              try {
                const answers = question.productAnswers;
                if (answers && answers.length > 0) {
                  answers.forEach((answer, index) => {
                    const url = `${API_URL}/${answer.documentURL}`;

                    if (answer.preFilled) {
                      preFilledQuestions[`group_${productGroup.id}.${index}`] = {
                        ...preFilledQuestions[`group_${productGroup.id}.${index}`],
                        [question.id]: true
                      };
                    }

                    !collectedQuestions[index]
                      ? (collectedQuestions[index] = { [question.id]: url })
                      : (collectedQuestions[index][question.id] = url);
                  });

                  if (answers.filter((answer) => !answer.isRejected).length > 0) {
                    lastAnsweredSection = sectionIndex;
                  }
                }
              } catch (e) {}

              break;
            }
            default: {
              const answers = question.productAnswers;
              if (answers && answers.length > 0) {
                answers.forEach((answer, index) => {
                  if (answer.preFilled) {
                    preFilledQuestions[`group_${productGroup.id}.${index}`] = {
                      ...preFilledQuestions[`group_${productGroup.id}.${index}`],
                      [question.id]: true
                    };
                  }

                  !collectedQuestions[index]
                    ? (collectedQuestions[index] = { [question.id]: answer.productAnswer })
                    : (collectedQuestions[index][question.id] = answer.productAnswer);
                });
                if (answers.filter((answer) => !answer.isRejected && !answer.preFilled).length > 0) {
                  lastAnsweredSection = sectionIndex;
                }
              }
            }
          }
        } else {
          switch (question.type) {
            case 'DOC_UPLOAD':
            case 'RECORD_VIDEO':
            case 'SIGNATURE': {
              const answerObj = question.productAnswers[0];
              if (answerObj) {
                values[question.id] = `${API_URL}/${answerObj.documentURL}`;
                if (answerObj.preFilled) preFilledQuestions[question.id] = true;
                if (!answerObj.isRejected && !answerObj.preFilled) lastAnsweredSection = sectionIndex;
              }
              break;
            }
            case 'RADIO': {
              const answerObj = question.productAnswers[0];
              if (answerObj) {
                values[question.id] = answerObj.productAvailableAnswerId;
                if (answerObj.preFilled) preFilledQuestions[question.id] = true;
                if (!answerObj.isRejected && !answerObj.preFilled) lastAnsweredSection = sectionIndex;
              }
              break;
            }
            case 'INPUT':
            case 'INTEGER':
            case 'MINIMAL_AMOUNT': {
              const answerObj = question.productAnswers[0];
              if (answerObj) {
                values[question.id] = answerObj.productAnswer;
                if (answerObj.preFilled) preFilledQuestions[question.id] = true;
                if (!answerObj.isRejected && !answerObj.preFilled) lastAnsweredSection = sectionIndex;
              }
              break;
            }
            case 'CHECKBOX':
            case 'CONFIRMATIONS': {
              const answers = question.productAnswers;
              if (answers.length > 0) {
                values[question.id] = answers.map((item) => {
                  if (item.preFilled) preFilledQuestions[question.id] = true;
                  if (!item.isRejected && !item.preFilled) lastAnsweredSection = sectionIndex;
                  return item.productAvailableAnswerId;
                });
              }
              break;
            }
            case 'ADDRESS': {
              const answerObj = question.productAnswers[0];
              if (answerObj) {
                values[question.id] = JSON.parse(answerObj.productAnswer);
                if (answerObj.preFilled) preFilledQuestions[question.id] = true;
                if (!answerObj.isRejected && !answerObj.preFilled) lastAnsweredSection = sectionIndex;
              }
              break;
            }
            case 'PHONE': {
              const answerObj = question.productAnswers[0];
              if (answerObj) {
                if (answerObj.numberOfSymbolsInCode) {
                  values[question.id] = [answerObj.productAnswer.slice(0, answerObj.numberOfSymbolsInCode), answerObj.productAnswer.slice(answerObj.numberOfSymbolsInCode + 1)].join('/')
                } else {
                  values[question.id] = answerObj.productAnswer
                }
                if (answerObj.preFilled) preFilledQuestions[question.id] = true;
                if (!answerObj.isRejected && !answerObj.preFilled) lastAnsweredSection = sectionIndex;
              }
              break;
            }
            default: {
              const answerObj = question.productAnswers[0];
              if (answerObj) {
                values[question.id] = answerObj.productAnswer;
                if (answerObj.preFilled) preFilledQuestions[question.id] = true;
                if (!answerObj.isRejected && !answerObj.preFilled) lastAnsweredSection = sectionIndex;
              }
            }
          }
        }
      });

      if (productGroup.isMultiple && collectedQuestions.length > 0) {
        values[`group_${productGroup.id}`] = collectedQuestions;
      }
    });
  });


  return { values, lastAnsweredSection, preFilledQuestions };
};

//collect questionnaire current answers before post on back
const collectQuestionnaireCurrentAnswers = ({
  questionnaire,
  currentSection,
  data,
  insertedQuestions = {},
  questionIdKey = 'questionId'
}) => {
  const array = [];
  let oneByOneRequests = false;
  let answerId = undefined;
  let childQuestionnaireId = undefined;

  const push = ({questionId, questionName, key, answer, answerId, childQuestionnaireId, group = 0, prePostCallback = null}) =>
    array.push({
      questionSectionTypeId: questionnaire.investorQuestionSectionTypeId,
      questionName: questionName,
      [questionIdKey]: questionId,
      [key]: answer,
      clearAll: true,
      group: group,
      ...(answerId && {answerId: answerId}),
      key: key,
      prePostCallback: prePostCallback,
      childQuestionnaireId: childQuestionnaireId
    });

  const prePostFile = async (url) => {
    const blobRes = await fetch(url);
    const blob = await blobRes.blob();
    return new File([blob], getFilenameFromUrl(url))
  };

  questionnaire.questionSections[currentSection].questionGroups.forEach((questionGroup) => {
    const multipleAnswer = data[`group_${questionGroup.id}`];
    if (multipleAnswer) oneByOneRequests = true;

    questionGroup.questions.forEach((question) => {
      if (question.autoInsert && insertedQuestions[question.id]) {
        data[question.id] = data[question.autoInsert.id];
      }

      const answer = question.answers.find((ans) => ans.questionId === question.id);
      if (answer) answerId = answer.id;
      if (answer) childQuestionnaireId = answer.childQuestionnaireId;

      switch (question.type) {
        case 'DOC_UPLOAD':
        case 'RECORD_VIDEO':
        case 'SIGNATURE': {
          const answer = data[question.id];
          if (answer) {
            push({
              questionId: question.id,
              questionName: questionGroup.title,
              ...(answerId && {answerId: answerId}),
              key: 'file',
              answer: answer,
              prePostCallback: typeof answer === 'string' ? () => prePostFile(answer) : null,
              ...(childQuestionnaireId && {childQuestionnaireId: childQuestionnaireId}),
            });
          }

          if (multipleAnswer) {
            multipleAnswer.forEach((answer, index) => {
              if (answer[question.id]) {
                push({
                  questionId: question.id,
                  questionName: questionGroup.title,
                  key: 'file',
                  ...(answerId && {answerId: answerId}),
                  answer: answer[question.id],
                  group: index + 1,
                  prePostCallback: typeof answer[question.id] === 'string' ? () => prePostFile(answer[question.id]) : null,
                  ...(childQuestionnaireId && {childQuestionnaireId: childQuestionnaireId}),
                });
              }
            });
          }
          break;
        }
        case 'RADIO': {
          const answer = data[question.id];
          if (answer) push({questionId: question.id, key: 'availableAnswerId', answer: answer, ...(answerId && {answerId: answerId}), ...(childQuestionnaireId && {childQuestionnaireId: childQuestionnaireId}),});

          if (multipleAnswer) {
            multipleAnswer.forEach((answer, index) => {
              if (answer[question.id]) {
                push({questionId: question.id, key: 'availableAnswerId', answer: answer[question.id], ...(answerId && {answerId: answerId}), group: index + 1, ...(childQuestionnaireId && {childQuestionnaireId: childQuestionnaireId}),});
              }
            });
          }
          break;
        }
        case 'CHECKBOX':
        case 'CONFIRMATIONS': {
          const answers = data[question.id];
          if (answers && answers.length > 0) {
            answers.forEach((item) => {
              if (item) push({questionId: question.id, key: 'availableAnswerId', answer: item, ...(answerId && {answerId: answerId}), ...(childQuestionnaireId && {childQuestionnaireId: childQuestionnaireId}),});
            });
          }
          if (!oneByOneRequests && answers.length > 1) {
            oneByOneRequests = true;
          }
          break;
        }
        case 'DATE': {
          const answer = data[question.id];
          if (answer)
            push({questionId: question.id, key: 'answer', ...(answerId && {answerId: answerId}), answer: typeof answer === 'string' ? answer : answer.toISOString(), ...(childQuestionnaireId && {childQuestionnaireId: childQuestionnaireId}),});
          if (multipleAnswer) {
            multipleAnswer.forEach((answersObj, index) => {
              const answer = answersObj[question.id];
              if (answer) {
                push({
                  questionId: question.id,
                  key: 'answer',
                  ...(answerId && {answerId: answerId}),
                  answer: typeof answer === 'string' ? answer : answer.toISOString(),
                  group: index +1,
                  ...(childQuestionnaireId && {childQuestionnaireId: childQuestionnaireId}),
                });
              }
            });
          }
          break;
        }
        default: {
          const answer = data[question.id];

          if (question.type === 'PHONE' && answer === '/') break;

          if (answer)
            push({
              questionId: question.id,
              key: 'answer',
              ...(answerId && {answerId: answerId}),
              answer: typeof answer === 'string' ? answer : JSON.stringify(answer),
              ...(childQuestionnaireId && {childQuestionnaireId: childQuestionnaireId}),
            });
          if (multipleAnswer) {
            multipleAnswer.forEach((answersObj, index) => {
              const answer = answersObj[question.id];
              if (answer)
                push({
                  questionId: question.id,
                  key: 'answer',
                  ...(answerId && {answerId: answerId}),
                  answer: typeof answer === 'string' ? answer : JSON.stringify(answer),
                  group: index +1,
                  ...(childQuestionnaireId && {childQuestionnaireId: childQuestionnaireId}),
                });
            });
          }
        }
      }
    });
  });

  return { oneByOneRequests: oneByOneRequests, array: array };
};

//collect products current answers before post on back
const collectProductsCurrentAnswers = ({ product,
                                         currentSection,
                                         data,
                                         insertedQuestions = {},
                                         questionIdKey = 'questionId'
}) => {
  const array = [];
  let oneByOneRequests = false;
  let answerId = undefined;
  let childQuestionnaireId = undefined;

  const push = ({questionId, questionName, key, answer, answerId, childQuestionnaireId, group = 0, prePostCallback = null}) =>
    array.push({
      productQuestionId: questionId,
      investorProductId: product.investorProductId,
      questionName: questionName,
      [questionIdKey]: questionId,
      [key]: answer,
      clearAll: true,
      group: group,
      ...(answerId && {answerId: answerId}),
      key: key,
      prePostCallback: prePostCallback,
      childQuestionnaireId: childQuestionnaireId
    });

  console.log(product)

  const prePostFile = async (url) => {
    const blobRes = await fetch(url);
    const blob = await blobRes.blob();
    return new File([blob], getFilenameFromUrl(url))
  };

  product.productSections[currentSection].productGroups.forEach((productGroup) => {
    const multipleAnswer = data[`group_${productGroup.id}`];
    if (multipleAnswer) oneByOneRequests = true;

    productGroup.productQuestions.forEach((question) => {
      if (question.autoInsert && insertedQuestions[question.id]) {
        data[question.id] = data[question.autoInsert.id];
      }

      const answer = question.productAnswers.find((ans) => ans.questionId === question.id);
      if (answer) answerId = answer.id;
      if (answer) childQuestionnaireId = answer.childQuestionnaireId;

      switch (question.type) {
        case 'DOC_UPLOAD':
        case 'RECORD_VIDEO':
        case 'SIGNATURE': {
          const answer = data[question.id];
          if (answer) {
            push({
              questionId: question.id,
              questionName: productGroup.title,
              ...(answerId && {answerId: answerId}),
              key: 'file',
              answer: answer,
              prePostCallback: typeof answer === 'string' ? () => prePostFile(answer) : null,
              ...(childQuestionnaireId && {childQuestionnaireId: childQuestionnaireId}),
            });
          }

          if (multipleAnswer) {
            multipleAnswer.forEach((answer, index) => {
              if (answer[question.id]) {
                push({
                  questionId: question.id,
                  questionName: productGroup.title,
                  key: 'file',
                  ...(answerId && {answerId: answerId}),
                  answer: answer[question.id],
                  group: index + 1,
                  prePostCallback: typeof answer[question.id] === 'string' ? () => prePostFile(answer[question.id]) : null,
                  ...(childQuestionnaireId && {childQuestionnaireId: childQuestionnaireId}),
                });
              }
            });
          }
          break;
        }
        case 'RADIO': {
          const answer = data[question.id];
          if (answer) push({questionId: question.id, key: 'availableAnswerId', answer: answer, ...(answerId && {answerId: answerId}), ...(childQuestionnaireId && {childQuestionnaireId: childQuestionnaireId}),});

          if (multipleAnswer) {
            multipleAnswer.forEach((answer, index) => {
              if (answer[question.id]) {
                push({questionId: question.id, key: 'availableAnswerId', answer: answer[question.id], ...(answerId && {answerId: answerId}), group: index + 1, ...(childQuestionnaireId && {childQuestionnaireId: childQuestionnaireId}),});
              }
            });
          }
          break;
        }
        case 'CHECKBOX':
        case 'CONFIRMATIONS': {
          const answers = data[question.id];
          if (answers && answers.length > 0) {
            answers.forEach((item) => {
              if (item) push({questionId: question.id, key: 'availableAnswerId', answer: item, ...(answerId && {answerId: answerId}), ...(childQuestionnaireId && {childQuestionnaireId: childQuestionnaireId}),});
            });
          }
          if (!oneByOneRequests && answers.length > 1) {
            oneByOneRequests = true;
          }
          break;
        }
        case 'DATE': {
          const answer = data[question.id];
          if (answer)
            push({questionId: question.id, key: 'answer', ...(answerId && {answerId: answerId}), answer: typeof answer === 'string' ? answer : answer.toISOString(), ...(childQuestionnaireId && {childQuestionnaireId: childQuestionnaireId}),});
          if (multipleAnswer) {
            multipleAnswer.forEach((answersObj, index) => {
              const answer = answersObj[question.id];
              if (answer) {
                push({
                  questionId: question.id,
                  key: 'answer',
                  ...(answerId && {answerId: answerId}),
                  answer: typeof answer === 'string' ? answer : answer.toISOString(),
                  group: index +1,
                  ...(childQuestionnaireId && {childQuestionnaireId: childQuestionnaireId}),
                });
              }
            });
          }
          break;
        }
        default: {
          const answer = data[question.id];

          if (question.type === 'PHONE' && answer === '/') break;

          if (answer)
            push({
              questionId: question.id,
              key: 'answer',
              ...(answerId && {answerId: answerId}),
              answer: typeof answer === 'string' ? answer : JSON.stringify(answer),
              ...(childQuestionnaireId && {childQuestionnaireId: childQuestionnaireId}),
            });
          if (multipleAnswer) {
            multipleAnswer.forEach((answersObj, index) => {
              const answer = answersObj[question.id];
              if (answer)
                push({
                  questionId: question.id,
                  key: 'answer',
                  ...(answerId && {answerId: answerId}),
                  answer: typeof answer === 'string' ? answer : JSON.stringify(answer),
                  group: index +1,
                  ...(childQuestionnaireId && {childQuestionnaireId: childQuestionnaireId}),
                });
            });
          }
        }
      }
    });
  });

  return { oneByOneRequests: oneByOneRequests, array: array };
};

const getRejectedObject = (questionnaire, rejectedQuestions) => {
  //collect rejected sections
  let rejectedObj = null;
  const sectionsIndexes = [];
  questionnaire.questionSections.forEach((section, sectionIndex) => {
    let hasRejectedQuestion = false;

    section.questionGroups.forEach((questionGroup) => {
      questionGroup.questions.forEach((question) => {
        const rejectObject = rejectedQuestions.filter((item) => item.questionId === question.id)[0];
        const result =
          rejectObject?.comment?.[rejectObject?.comment.length - 1]?.comment || 'To update';
        if (rejectObject) {
          hasRejectedQuestion = true;
          rejectedObj
            ? (rejectedObj[question.id] = result)
            : (rejectedObj = { [question.id]: result });
        }
      });
    });

    if (hasRejectedQuestion) sectionsIndexes.push(sectionIndex);
  });

  return { rejectedObj: rejectedObj, sectionsIndexes: sectionsIndexes };
};

const getProductRejectedObject = (product, rejectedQuestions) => {
  //collect rejected sections
  let rejectedObj = null;
  const sectionsIndexes = [];
  product.productSections.forEach((section, sectionIndex) => {
    let hasRejectedQuestion = false;

    section.productGroups.forEach((productGroup) => {
      productGroup.productQuestions.forEach((question) => {
        const rejectObject = rejectedQuestions.filter((item) => item.productQuestionId === question.id)[0];
        const result =
          rejectObject?.comment?.[rejectObject?.comment.length - 1]?.comment || 'To update';
        if (rejectObject) {
          hasRejectedQuestion = true;
          rejectedObj
            ? (rejectedObj[question.id] = result)
            : (rejectedObj = { [question.id]: result });
        }
      });
    });

    if (hasRejectedQuestion) sectionsIndexes.push(sectionIndex);
  });

  return { rejectedObj: rejectedObj, sectionsIndexes: sectionsIndexes };
};

export const kycHelpers = {
  collectQuestionnaireAnswers,
  collectProductsAnswers,
  collectQuestionnaireCurrentAnswers,
  collectProductsCurrentAnswers,
  getRejectedObject,
  getProductRejectedObject
};
