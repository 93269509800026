import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { investorApi, kycApi } from '../../../../api';
import { investorActions } from '../../../../store/investor';
import { useDispatch } from 'react-redux';
import { RegContainer, StepsPanel, FooterButtons, ImageUploadBoxForm } from '../../../../components';
import styled from 'styled-components';
import {
  Title2,
  IconCross,
  IconCheck,
  IconView,
  ExternalLink
} from '../../../../ui';
import { CountryInput, PhoneInput } from '../../../../modules/kycModule/Fields';
import { Container } from '../../../../ui';
import { GU, Checkbox } from '@aragon/ui';
import {
  EMAIL_REGEX,
  PASSWORD_REGEX,
  individualSteps
} from '../../../../utils/static';
import { entitiesActions } from '../../../../store/entities';
import { history } from '../../../../routing';
import { useCountries } from '../../../../hooks';
import { useForm } from 'react-hook-form';
import { FormInput } from '../../../../modules/kycModule/components';
import { useTranslation } from 'react-i18next';
import privacyPolicyENG from '../../../../assets/documents/Privacy_Policy_Spirit_ENG.pdf';
import privacyPolicyLAT from '../../../../assets/documents/Privacy_Policy_Spirit_LAT.pdf';
import privacyPolicyRUS from '../../../../assets/documents/Privacy_Policy_Spirit_RUS.pdf';


export const Step1 = ({ stepNumber, goNext }) => {
  const { state } = useLocation();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const COUNTRIES = useCountries();
  const { control, handleSubmit, getValues, setError } = useForm();
  const [isEmailValid, setEmailValid] = useState(null);
  const [isPhoneValid, setPhoneValid] = useState(null);
  const [isShowPassword, setShowPassword] = useState(false);
  const [isShowPassword2, setShowPassword2] = useState(false);
  const [isTermsChecked, setTermsChecked] = useState(false);

  useEffect(() => {
    if (!state || !state.account) history.push('/registration/investor');
  }, []);

  const checkValidation = (email, phone) => {
    return new Promise((resolve, reject) => {
      if (email || phone) {
        investorApi
        .phoneEmailValidation(phone, email.toLowerCase())
        .then((res) => {
          if (res.status === 200) {
            const { data } = res;

            setEmailValid(data.validEmail);
            setPhoneValid(data.validPhone);
            if (!data.validEmail || !data.validPhone) {
              reject('UNIQUE_FAILED');
            }

            resolve();
          } else {
            reject();
          }
        })
        .catch(() => {
          reject();
        });
      }
    });
  };

  const getCitizenshipObj = (citizenship) => {
    return COUNTRIES.filter((item) => item.country === citizenship)[0];
  };

  const handleNext = async (data) => {
    try {
      dispatch(entitiesActions.showLoader(t('Processing') + '...'));

      const { email, phone, password, firstName, lastName, avatar, citizenship } = data;

      await checkValidation(email, phone.replace('/', ''));

      const citizenshipId = getCitizenshipObj(citizenship);
      if (!citizenshipId || !citizenshipId.id) {
        setError('citizenship', { type: 'custom', message: t('Citizenship must be selected from the list') });
        throw new Error('Citizenship');
      }

      const res = await investorApi.createInvestor({
        email: email.toLowerCase(),
        numberOfSymbolsInCode: phone.split('/')[0].length,
        phone: phone.replace('/', ''),
        password: password,
        account: state.account,
        firstName: firstName,
        lastName: lastName
      });

      if (res.status === 201) {
        const { accessToken, role, ...investor } = res.data;
        localStorage.setItem('token', accessToken);
        localStorage.setItem('userRole', role);
        axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
        dispatch(investorActions.setInvestor(investor));

        goNext();

        await investorApi.setCitizenship(citizenshipId.id);
        if (avatar) await investorApi.uploadAvatar(avatar);
      }
    } catch (e) {
      if (e === 'UNIQUE_FAILED') {
        dispatch(entitiesActions.addToast(t('Email or phone is already taken')));
      } else {
        dispatch(entitiesActions.addToast(t('Something went wrong')));
      }
      // checkValidation();
    } finally {
      dispatch(entitiesActions.hideLoader());
      kycApi.generateQuestionnaires().then().catch();
    }
  };


  return (
    <StepsPanelContainer>
      <StepsPanel title={t('Registration')} step={stepNumber} steps={individualSteps.map((item) => t(item))} />

      <RegContainer type='centered' style={{marginBottom: '60px'}}>
        <Title onClick={goNext}>{t('Creating an account for verification')}</Title>

        <FormInput
          label={t('E-mail')}
          form={{
            control,
            name: 'email',
            rules: {
              required: t('Required'),
              validate: (v) => EMAIL_REGEX.test(v.toLowerCase()) || t('Invalid e-mail address')
            }
          }}
          icon={isEmailValid ? <IconCheck /> : <IconCross hide={isEmailValid === null} />}
          textTransform={'lowercase'}
          marginBottom={3 * GU}
          required
        />

        <PhoneInput
          form={{ control, name: 'phone', rules: { required: t('Required') } }}
          icon={isPhoneValid ? <IconCheck /> : <IconCross hide={isPhoneValid === null} />}
          marginBottom={3 * GU}
          required
        />

        <FormInput
          form={{ control, name: 'firstName', rules: { required: t('Required') } }}
          label={t('First name')}
          maxLength={20}
          marginBottom={3 * GU}
          marginRight={6 * GU}
          required
        />

        <FormInput
          form={{ control, name: 'lastName', rules: { required: t('Required') } }}
          label={t('Last name')}
          maxLength={20}
          marginBottom={3 * GU}
          marginRight={6 * GU}
          required
        />

        <Container marginBottom={3 * GU} paddingRight={48}>
          <CountryInput
            label={t('Citizenship')}
            form={{ control, name: 'citizenship', rules: { required: t('Required') } }}
            required
            wide
          />
        </Container>

        <Container marginBottom={3 * GU}>
          <ImageUploadBoxForm
            label={t('Avatar')}
            form={{ control, name: 'avatar' }}
            hideInfo
          />
        </Container>

        <FormInput
          form={{
            control,
            name: 'password',
            rules: {
              required: t('Required'),
              pattern: {
                value: PASSWORD_REGEX,
                message: t('At least 8 characters, both numbers and latin symbols in upper- and lowercase.')
              },
              deps: ['repeatPassword']
            }
          }}
          type={isShowPassword ? 'text' : 'password'}
          label={t('Password (At least 8 characters, both numbers and latin symbols in upper- and lowercase)')}
          icon={<IconView onClick={() => setShowPassword(!isShowPassword)} />}
          marginBottom={3 * GU}
          required
        />

        <FormInput
          form={{
            control,
            name: 'repeatPassword',
            rules: {
              required: t('Required'),
              validate: (v) => getValues('password') === v || t('Passwords must match')
            }
          }}
          type={isShowPassword2 ? 'text' : 'password'}
          label={t('Repeat password')}
          icon={<IconView onClick={() => setShowPassword2(!isShowPassword2)} />}
          marginBottom={3 * GU}
          required
        />

        <Container marginBottom={26}>
          <CheckboxWrapper>
            <Checkbox checked={isTermsChecked} onChange={(checked) => setTermsChecked(checked)} />
            <span>
            {t('I agree with')}{' '}
              <ExternalLink href={i18n.language === 'ru' ? privacyPolicyRUS : i18n.language === 'lv' ? privacyPolicyLAT : privacyPolicyENG}>
              {t('Privacy Policy')}
            </ExternalLink>
          </span>
          </CheckboxWrapper>
        </Container>

        <FooterButtons onNext={handleSubmit(handleNext)} nextDisabled={!isTermsChecked} />
      </RegContainer>
    </StepsPanelContainer>
  );
};

const StepsPanelContainer = styled.div`
  display: flex;
`;
const Title = styled(Title2)`
  font-weight: 300;
  margin-bottom: ${8 * GU}px;
`;
const IconCheckWrapper = styled.div`
  width: ${4 * GU}px;
  height: ${4 * GU}px;
  padding: 5px;
  background: #2cc68f;
  color: #ffffff;
  margin-left: ${2 * GU}px;
  border-radius: 100%;
`;
const CheckboxWrapper = styled.label`
  display: flex;
  margin-bottom: 8px;
  align-items: center;

  & > span {
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 22px;
    color: #212b36;
    margin-left: ${GU}px;
  }
`;
