import React from 'react';
import { Table, TableRow, IconArrowDown, IconArrowUp, Text, GU } from '@aragon/ui';
import { TableHeader, Cell as DefaultCell } from '../../components';
import { Row, Title3, Label, Body1Light } from '../../../../ui';
import styled from 'styled-components';
import { COLORS, CURRENCY_SYMBOL } from '../../../../utils/static';

const defHeader = [
  'Equity',
  'Assets',
  'Total cash',
  'Available cash',
  'Profit/Loss',
  'Total result',
  'Roi'
];
const disabledHeader = ['Profit/Loss', 'Roi', 'Total result'];

export const SummaryTable = ({ header = defHeader, data }) => {
  const headerItems = header.map((item, key) => (
    <TableHeader title={item} color={disabledHeader.includes(item) && COLORS.greyLight} key={key} />
  ));

  if (data) {
    const {
      currency,
      availableCash,
      assetsCash,
      totalCash,
      allEquityCash,
      allHoldInCash,
      allHoldOutCash
    } = data;
    const currencySymbol = CURRENCY_SYMBOL[currency];

    return (
      <StyledTable header={<TableRow>{headerItems}</TableRow>}>
        {data && (
          <TableRow>
            <Cell>
              {allEquityCash}
              {currencySymbol}
            </Cell>

            <Cell>
              {assetsCash}
              {currencySymbol}
            </Cell>

            <Cell>
              {totalCash}
              {currencySymbol}

              <Label color={COLORS.greyLight} marginTop={GU}>
                Hold in
              </Label>
              <Body1Light color={COLORS.greyMedium}>
                {allHoldInCash}
                {currencySymbol}
              </Body1Light>
            </Cell>

            <Cell>
              {availableCash}
              {currencySymbol}

              <Label color={COLORS.greyLight} marginTop={GU}>
                Hold out
              </Label>
              <Body1Light color={COLORS.greyMedium}>
                {allHoldOutCash}
                {currencySymbol}
              </Body1Light>
            </Cell>

            <Cell>
              <Row alignItems={'center'}>
                <IconArrowUp color={COLORS.greyLight} />
                <Text color={COLORS.greyLight}>{mockSummary.profitCash}</Text>
                <PercentText color={COLORS.greyLight}>({mockSummary.profitPercent})</PercentText>
              </Row>
            </Cell>

            <Cell color={COLORS.greyLight}>{mockSummary.result}</Cell>

            <Cell color={COLORS.greyLight}>{mockSummary.ROI}</Cell>
          </TableRow>
        )}
      </StyledTable>
    );
  }

  return null;
};

const Cell = ({ children, ...props }) => (
  <DefaultCell Container={Title3} cellProps={{ padding: `${3 * GU}px 10px` }} {...props}>
    {children}
  </DefaultCell>
);

const mockSummary = {
  equity: '$160,000',
  allCash: '$70,000',
  availableCash: '$80,000',
  assets: '$80,000',
  profitCash: '$XXXXX',
  profitPercent: 'XX%',
  lossCash: '$12,000',
  lossPercent: '15%',
  result: '$80,000',
  ROI: '15%'
};

const StyledTable = styled(Table)`
  tbody {
    vertical-align: top;
  }

  td,
  td:first-child {
    border: none;
  }
`;
const PercentText = styled(Title3)`
  color: ${(p) => (p.color ? p.color : COLORS.greyMedium)};
  margin-left: 8px;
`;
