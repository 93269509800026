import React, { useEffect, useRef } from 'react';
import { ToastHub, Toast as AragonToast } from '@aragon/ui';
import styled from 'styled-components';

const timeout = 3000;

export const Toast = ({ toast, removeToast }) => {
  const ref = useRef();

  useEffect(() => {
    if (toast) {
      try {
        ref.current.click();
        removeToast();
      } catch (e) {
        console.log(e);
      }
    }
  }, [toast]);

  return (
    <ToastHubStyled timeout={timeout}>
      <AragonToast>{(addToast) => <div ref={ref} onClick={() => addToast(toast)} />}</AragonToast>
    </ToastHubStyled>
  );
};

const ToastHubStyled = styled(ToastHub)`
  div {
    height: unset;
    min-height: 42px;
  }
`