import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { kycApi } from '../../../api/kyc';
import { Box, Pagination } from '../../../components';
import { useDispatch } from 'react-redux';
import { entitiesActions } from '../../../store/entities';
import { Container, InnerBox } from '../../../ui/layout';
import { Body1Light } from '../../../ui/typography';
import { displayFullDate } from '../../../utils/helpers/common';
import { useTranslation } from 'react-i18next';
import { Link } from '../../../ui';

const ITEMS_ON_PAGE = 10;

const logTexts = ['status product updated', 'status updated']

const parseType = (type) => {
  let title = type;
  let restText = '';
  logTexts.forEach((text) => {
    if (type.includes(text)) {
      restText = text.trim();
      title = type.replace(text, '').trim();
      title = title.replace('+', '').trim();
      title = title.replace(',', '').trim();
    }
  })
  return { title: title, restText: restText };
}

export const InvestorActivity = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [page, setPage] = useState(0);
  const { data, refetch, isFetching } = useQuery(['getInvestorActivity'], () =>
    kycApi.getInvestorActivity({
      investorId: id,
      reverse: true,
      take: ITEMS_ON_PAGE,
      skip: page * ITEMS_ON_PAGE
    })
  );

  useEffect(() => {
    isFetching
      ? dispatch(entitiesActions.showLoader(t('Fetching') + '...'))
      : dispatch(entitiesActions.hideLoader());
  }, [isFetching]);
  useEffect(() => refetch(), [page]);

  const logs = useMemo(() => {
    if (data && data.data) {
      return { logs: data.data.logs, count: data.data.count };
    }

    return null;
  }, [data]);

  return (
    <Box heading={t('Activity log')} paddingX={'0'} paddingY={8}>
      {logs &&
      logs.logs &&
      logs.logs.map((log, key) => {
        const logText = parseType(log.type);
        return (
        <InnerBox key={key} padding={16}>
          {log.createdAt && (
            <Body1Light marginBottom={12}>
              <strong>{displayFullDate(new Date(log.createdAt))}</strong>
            </Body1Light>
          )}

          {log.type && <Container marginBottom={4}>
            {log.investorProductId ?
              <Link to={`/investor/${id}/product/${log.investorProductId}`}>{logText.title}</Link>
              : log.investorQuestionSectionTypeId
                ? <Link to={`/investor/${id}/questionnaire/${log.investorQuestionSectionTypeId}`}>{logText.title}</Link>
                : (!log.investorProductId && !log.investorQuestionSectionTypeId && logText.title === 'User verification')
                  ? <Link to={`/investor/${id}/sumsub-verification`}>{logText.title}</Link>
                  : <span>{logText.title}</span>}
            {' '}
            <span>{logText.restText}</span>
          </Container>}
          {log.text && <Container>{log.text}</Container>}
        </InnerBox>
      )})}

      {logs && logs.count && (
        <Pagination
          visible={logs.count > ITEMS_ON_PAGE}
          selected={page}
          pages={Math.ceil(logs.count / ITEMS_ON_PAGE)}
          onChange={setPage}
        />
      )}
    </Box>
  );
};
