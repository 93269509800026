import React from 'react';
import { GU } from '@aragon/ui';
import { Box, BoxLine, InnerBox, ProfileViewer } from '../../../index';
import { BROKER_PROFILE } from '../../../../utils/static';

export const TransactionParticipantsBoxes = ({ sender, receiver, investorId }) => {
  return (
    <div>
      <Box heading={'Sender'} padding={0}>
        {sender ? <InvestorBox investor={sender} investorId={investorId} /> : <BrokerBox />}
      </Box>

      <Box heading={'Receiver'} padding={0}>
        {receiver ? <InvestorBox investor={receiver} investorId={investorId} /> : <BrokerBox />}
      </Box>
    </div>
  );
};

const InvestorBox = ({ investor, investorId }) => {
  return (
    <div>
      <InnerBox paddingY={2 * GU}>
        <ProfileViewer name={investor.accountName} avatarUrl={investor.avatarUrl} />
      </InnerBox>
      <InnerBox paddingY={GU}>
        <BoxLine title={'Account ID'} data={investor.id || investorId} />
      </InnerBox>
    </div>
  );
};

const BrokerBox = () => {
  return (
    <div>
      <InnerBox paddingY={2 * GU}>
        <ProfileViewer {...BROKER_PROFILE} />
      </InnerBox>
      <InnerBox paddingY={GU}>
        <BoxLine title={'Account'} data={BROKER_PROFILE.name} />
      </InnerBox>
    </div>
  );
};
