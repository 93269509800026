import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { ProfileStatusView } from '../../../common/organisms/ProfileStatusView';
import { adminKYCApi } from '../../../../api/admin/kyc';
import { Loading } from '../../../common';

export const ProfileStatus = ({ investorData }) => {
  const { id } = useParams();
  const { data, isLoading, refetch } = useQuery(
    ['getInvestorQuestionnaires'],
    () => adminKYCApi.getInvestorQuestionnaires(id),
    { refetchInterval: 5000, refetchOnWindowFocus: true }
  );
  const { data: sumSubRes } = useQuery(
    ['getInvestorSumSubStatus'],
    () => adminKYCApi.getInvestorSumSubStatus(id)
  );

  if (isLoading) return <Loading/>;

  if (!isLoading) {
    return (
      <ProfileStatusView
        data={data}
        investor={investorData}
        sumSubStatusRes={sumSubRes}
        refetchQuests={refetch}
        adminView
      />
    );
  }

  return null;
};
