import React from 'react';
import { GU } from '@aragon/ui';
import { Box, InnerBox, BoxLine } from '../../../../components';
import { Body2Light, Title5, Row } from '../../../../ui';
import { COLORS, ASSET_TYPES } from '../../../../utils/static';
import ethIcon from '../../../../assets/icons/eth_icon.svg';

export const AssetDetailsBox = ({ data }) => {
  const { issue, ...asset } = data;

  if (issue && asset) {
    return (
      <Box padding={0}>
        <InnerBox paddingX={'21'}>
          <Title5>{asset.fullName}</Title5>
        </InnerBox>

        <InnerBox>
          <BoxLine title='Asset ID' data={asset.id} />
          <BoxLine title='Security type'>
            <Body2Light color={COLORS.aragonBlue}>{ASSET_TYPES[asset.assetType]}</Body2Light>
          </BoxLine>
          <BoxLine title='Ticker'>
            <Row margin={-0.5 * GU} alignItems={'center'}>
              {/*<img src={ethIcon} alt="" />*/}
              <Body2Light>{asset.ticker}</Body2Light>
            </Row>
          </BoxLine>
          <BoxLine title='Exchange' data={asset.exchange} />
          <BoxLine title='ISIN' data={asset.isin} />
          <BoxLine title='Currency' data={asset.currency} />
        </InnerBox>

        <InnerBox>
          <BoxLine title='Issue date' data={issue.issueDate.substr(0, 10)} />
          <BoxLine
            title='Quantity (maximum available)'
            data={`${issue.numberOfSecurities} units`}
          />
          <BoxLine title='Volume' data={`${asset.volume} units`} />
          <BoxLine title='Price per capita' data={issue.issuePrice} />
          <BoxLine
            title='Min - Max quantity'
            data={`${issue.minQuantityPerOrder} - ${issue.maxQuantityPerOrder}`}
          />
        </InnerBox>

        <InnerBox>
          <BoxLine title='Branch' data={asset.branch} />
          <BoxLine title='Region' data={asset.region} />
          <BoxLine title='Timing' data={asset.timing} />
        </InnerBox>

        <InnerBox>
          <BoxLine title='Equity type' data={asset.equityType} />
          {/*<BoxLine title="Equity out" data={issue.EquityOut} />*/}
          {/*<BoxLine title="Equity bought" data={issue.EquityBought} />*/}
          {/*<BoxLine title="P/E" data={issue.PE} />*/}
          {/*<BoxLine title="Dividends per Equity" data={issue.Dividends} />*/}
        </InnerBox>
      </Box>
    );
  }

  return null;
};
