import React from 'react';

import { ProfileOffers as RenderProfileOffers } from '../../../../components';

const OFFERS = [
  {
    id: 1,
    title: "VIVAT 6 (E2023)",
    info: "6% kupons, līdz 31/12/2028, EUR/CHF, minimums 5000 EUR/CHF",
    presentation: "Prezentacija VIVAT6, VIVAT8 no VIVATIIAG ENG",
    presentationFile: "Prezentacija VIVAT6, VIVAT8 no VIVATIIAG ENG.pdf",
    sheet: "Informacijas lapa VIVAT6 no VIVATIIAG E2023 ENG EUR",
    sheetFile: "Informacijas lapa VIVAT6 no VIVATIIAG E2023 ENG EUR.pdf",
    extraSheet: "Informacijas lapa VIVAT6 no VIVATIIAG E2023 ENG CHF",
    extraSheetFile: "Informacijas lapa VIVAT6 no VIVATIIAG E2023 ENG CHF.pdf",
    brochure: "Prospekts VIVAT6 no VIVATIIAG E2023 ENG",
    brochureFile: "Prospekts VIVAT6 no VIVATIIAG E2023 ENG.pdf",
    extraBrochure: "",
    extraBrochureFile: ""
  },
  {
    id: 2,
    title: "VIVAT 8 (E2023)",
    info: "8% kupons, līdz 31/12/2028, EUR/CHF, minimums 100 000 EUR/CHF",
    presentation: "Prezentacija VIVAT6, VIVAT8 no VIVATIIAG ENG",
    presentationFile: "Prezentacija VIVAT6, VIVAT8 no VIVATIIAG ENG.pdf",
    sheet: "Informacijas lapa VIVAT8 no VIVATIIAG E2023 ENG EUR",
    sheetFile: "Informacijas lapa VIVAT8 no VIVATIIAG E2023 ENG EUR.pdf",
    extraSheet: "Informacijas lapa VIVAT8 no VIVATIIAG E2023 ENG CHF",
    extraSheetFile: "Informacijas lapa VIVAT8 no VIVATIIAG E2023 ENG CHF.pdf",
    brochure: "Prospekts VIVAT8 no VIVATIIAG E2023 ENG",
    brochureFile: "Prospekts VIVAT8 no VIVATIIAG E2023 ENG.pdf",
    extraBrochure: "",
    extraBrochureFile: ""
  },
  {
    id: 3,
    title: "Exclusive (E2023)",
    info: "8% kupons, līdz 31/12/2028, EUR/CHF, minimums 100 000 EUR/CHF",
    presentation: "",
    presentationFile: "",
    sheet: "Informacijas lapa Exclusive no MultitalentInvestment 3 E2023 ENG EUR",
    sheetFile: "Informacijas lapa Exclusive no MultitalentInvestment 3 E2023 ENG EUR.pdf",
    extraSheet: "Informacijas lapa Exclusive no MultitalentInvestment 3 E2023 ENG CHF",
    extraSheetFile: "Informacijas lapa Exclusive no MultitalentInvestment 3 E2023 ENG CHF.pdf",
    brochure: "Prospekts Exclusive-Prestige no MultitalentInvestment 3 E2023 ENG",
    brochureFile: "Prospekts Exclusive-Prestige no MultitalentInvestment 3 E2023 ENG.pdf",
    extraBrochure: "",
    extraBrochureFile: ""
  },
  {
    id: 4,
    title: "Prestige (E2023)",
    info: "6% kupons, līdz 31/12/2028, EUR/CHF, minimums 5000 EUR/CHF",
    presentation: "",
    presentationFile: "",
    sheet: "Informacijas lapa Prestige no MultitalentInvestment 3 E2023 ENG EUR",
    sheetFile: "Informacijas lapa Prestige no MultitalentInvestment 3 E2023 ENG EUR.pdf",
    extraSheet: "Informacijas lapa Prestige no MultitalentInvestment 3 E2023 ENG CHF",
    extraSheetFile: "Informacijas lapa Prestige no MultitalentInvestment 3 E2023 ENG CHF.pdf",
    brochure: "Prospekts Exclusive-Prestige no MultitalentInvestment 3 E2023 ENG",
    brochureFile: "Prospekts Exclusive-Prestige no MultitalentInvestment 3 E2023 ENG.pdf",
    extraBrochure: "",
    extraBrochureFile: ""
  },
  {
    id: 5,
    title: "SolarINVEST 26",
    info: "5% kupons, līdz 31/12/2026, EUR/CHF, minimums 5000 EUR/CHF",
    presentation: "",
    presentationFile: "",
    sheet: "Informacijas lapa SolarINVEST26 no MRSun E2023 ENG EUR",
    sheetFile: "Informacijas lapa SolarINVEST26 no MRSun E2023 ENG EUR.pdf",
    extraSheet: "Informacijas lapa SolarINVEST26 no MRSun E2023 ENG CHF",
    extraSheetFile: "Informacijas lapa SolarINVEST26 no MRSun E2023 ENG CHF.pdf",
    brochure: "Prospekts SolarINVEST26-SolarINVEST28 no MRSun E2023 ENG",
    brochureFile: "Prospekts SolarINVEST26-SolarINVEST28 no MRSun E2023 ENG.pdf",
    extraBrochure: "Prospekta pielikums 1 SolarINVEST26-SolarINVEST28 no MRSun E2023 ENG",
    extraBrochureFile: "Prospekta pielikums 1 SolarINVEST26-SolarINVEST28 no MRSun E2023 ENG.pdf"
  },
  {
    id: 6,
    title: "SolarINVEST 28",
    info: "7% kupons, līdz 31/12/2028, EUR/CHF, minimums 5000 EUR/CHF",
    presentation: "",
    presentationFile: "",
    sheet: "Informacijas lapa SolarINVEST28 no MRSun E2023 ENG EUR",
    sheetFile: "Informacijas lapa SolarINVEST28 no MRSun E2023 ENG EUR.pdf",
    extraSheet: "Informacijas lapa SolarINVEST28 no MRSun E2023 ENG CHF",
    extraSheetFile: "Informacijas lapa SolarINVEST28 no MRSun E2023 ENG CHF.pdf",
    brochure: "Prospekts SolarINVEST26-SolarINVEST28 no MRSun E2023 ENG",
    brochureFile: "Prospekts SolarINVEST26-SolarINVEST28 no MRSun E2023 ENG.pdf",
    extraBrochure: "Prospekta pielikums 1 SolarINVEST26-SolarINVEST28 no MRSun E2023 ENG",
    extraBrochureFile: "Prospekta pielikums 1 SolarINVEST26-SolarINVEST28 no MRSun E2023 ENG.pdf"
  }
]

export const ProfileOffers = () => {
  return (
    <div>
      <RenderProfileOffers offers={OFFERS} />
    </div>
  );
};
