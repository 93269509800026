import React from 'react';
import { Table, TableRow, Text } from '@aragon/ui';
import { ProfileViewer } from '../../../../components';
import { Cell, DetailsButton, TableHeader, EmptyBody } from '../../components';
import { ASSET_TYPES, COLORS } from '../../../../utils/static';
import { displayFullDate } from '../../../../utils/helpers';
import styled from 'styled-components';

const defHeader = ['ID', 'Issuer', 'Type', 'status', 'Issue Cap', 'Date'];
export const IssuesTable = ({ header = defHeader, data = [], onDetailsClick = () => {} }) => {
  const headerItems = header.map((item, key) => <TableHeader title={item} key={key} />);

  const bodyItems =
    data && data.length > 0 ? (
      data.map((item, key) => (
        <TableRow key={key}>
          <Cell>{item.id}</Cell>

          <Cell>
            <ProfileViewer
              name={
                <Text color={COLORS.blue}>
                  {item.issuer.firstName} {item.issuer.lastName}
                </Text>
              }
              size={'small'}
            />
          </Cell>

          <Cell>
            <Text color={COLORS.aragonBlue}>{ASSET_TYPES[item.asset.assetType]}</Text>
          </Cell>

          <Cell>{item.status}?</Cell>

          <Cell>?</Cell>

          <Cell>{displayFullDate(new Date(item.issueDate), true)}</Cell>

          <Cell cellProps={{ align: 'right' }}>
            <DetailsButton onClick={() => onDetailsClick(item.id)} />
          </Cell>
        </TableRow>
      ))
    ) : (
      <EmptyBody title={'No issues'} />
    );

  return (
    <StyledTable
      header={<TableRow style={{ height: '48px' }}>{headerItems}</TableRow>}
      noSideBorders
    >
      {bodyItems}
    </StyledTable>
  );
};

const StyledTable = styled(Table)`
  th {
    border-left: none;
    border-right: none;
    white-space: pre-line;
    line-height: 16px;
  }
`;
