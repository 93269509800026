import React from 'react';
import { RegContainer, StepsPanel, FooterButtons } from '../../../../../components';
import styled from 'styled-components';
import { Label2, Title2 } from '../../../../../ui';
import { GU, Box, Split, Text, TextInput, DropDown, Info } from '@aragon/ui';
import { individualSteps } from '../../../../../utils/static';
import { history } from '../../../../../routing';
import textStyle from '../../../../../utils/textStyle';
import styles from '../../styles.module.css';
import accordion from '../../../../../assets/icons/AccordionIcon.svg';
import { FileUpload } from '../../../../../components';

export const Step7 = () => {
  return (
    <StepsPanelContainer>
      <StepsPanel title={'Registration'} step={6} steps={individualSteps} />

      <RegContainer>
        <Title style={{ textAlign: 'center' }}>Proof of funds</Title>

        <Box className={styles['noPadding']}>
          <div className={styles['innerBox']}>
            <Text style={textStyle('body1')}>Funds information</Text>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginTop: '24px',
                width: '49%'
              }}
            >
              <div style={{ display: 'flex', flexDirection: 'column', width: '48%' }}>
                <InputLabel>Currency</InputLabel>
                <DropDown items={['USD', 'EUR']} selected={0} width='100%' />
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', width: '48%' }}>
                <InputLabel>Total monthly income</InputLabel>
                <TextInput style={{ width: '100%' }} />
              </div>
            </div>
          </div>
        </Box>

        <Box className={styles['noPadding']}>
          <div>
            <div className={styles['headerFinancialInstr']}>
              <div style={{ width: '50%' }}>Type of activity</div>
              <div style={{ width: '50%' }}>Description of Activity</div>
            </div>
            <div className={styles['dataFinancialInstr']}>
              <div className={styles['dataFinancialInstrRow']}>
                <div style={{ width: '50%' }}>
                  <TextInput style={{ width: '100%' }} />
                </div>
                <div style={{ width: '50%' }}>
                  <TextInput style={{ width: '100%' }} />
                </div>
              </div>
            </div>
          </div>
        </Box>

        <Box className={styles['noPadding']}>
          <div>
            <div className={styles['headerFinancialInstr']}>
              <div style={{ width: '50%' }}>Source of income</div>
              <div style={{ width: '50%' }}>Amount of funds</div>
            </div>
            <div className={styles['dataFinancialInstr']}>
              <div className={styles['dataFinancialInstrRow']}>
                <div style={{ width: '50%' }}>
                  <TextInput value='Salary' style={{ width: '100%' }} />
                </div>
                <div style={{ width: '50%' }}>
                  <DropDown items={['< 1000', '1 000 - 4 999']} selected={0} width='100%' />
                </div>
              </div>
              <div className={styles['dataFinancialInstrRow']}>
                <div style={{ width: '50%' }}>
                  <TextInput value='Social benefits' style={{ width: '100%' }} />
                </div>
                <div style={{ width: '50%' }}>
                  <DropDown items={['< 1000', '1 000 - 4 999']} selected={0} width='100%' />
                </div>
              </div>
              <div className={styles['dataFinancialInstrRow']}>
                <div style={{ width: '50%' }}>
                  <TextInput value='Transfer from relatives' style={{ width: '100%' }} />
                </div>
                <div style={{ width: '50%' }}>
                  <DropDown items={['< 1000', '1 000 - 4 999']} selected={0} width='100%' />
                </div>
              </div>
              <div className={styles['dataFinancialInstrRow']}>
                <div style={{ width: '50%' }}>
                  <TextInput value='Self-employed activity' style={{ width: '100%' }} />
                </div>
                <div style={{ width: '50%' }}>
                  <DropDown items={['< 1000', '1 000 - 4 999']} selected={0} width='100%' />
                </div>
              </div>
              <div className={styles['dataFinancialInstrRow']}>
                <div style={{ width: '50%' }}>
                  <TextInput
                    value='Income from transaction s with financial instruments'
                    style={{ width: '100%' }}
                  />
                </div>
                <div style={{ width: '50%' }}>
                  <DropDown items={['< 1000', '1 000 - 4 999']} selected={0} width='100%' />
                </div>
              </div>
              <div className={styles['dataFinancialInstrRow']}>
                <div style={{ width: '50%' }}>
                  <TextInput value='Business ownership income' style={{ width: '100%' }} />
                </div>
                <div style={{ width: '50%' }}>
                  <DropDown items={['< 1000', '1 000 - 4 999']} selected={0} width='100%' />
                </div>
              </div>
              <div className={styles['dataFinancialInstrRow']}>
                <div style={{ width: '50%' }}>
                  <TextInput value='Public grants' style={{ width: '100%' }} />
                </div>
                <div style={{ width: '50%' }}>
                  <DropDown items={['< 1000', '1 000 - 4 999']} selected={0} width='100%' />
                </div>
              </div>
            </div>
          </div>
        </Box>

        <Box className={styles['noPadding']} style={{ marginBottom: '24px' }}>
          <Text style={{ display: 'block', padding: '24px 0 14px 24px' }}>
            Documents for compliance
          </Text>
          <div>
            <div className={styles['headerFinancialInstr']}>
              <div style={{ width: '7%' }} />
              <div style={{ width: '43%' }}>Title</div>
              <div style={{ width: '50%' }}>Attached Files</div>
            </div>
            <div className={styles['dataAccordion']}>
              <div className={styles['dataAccordionRow']}>
                <div className={styles['dataTopRow']}>
                  <div style={{ width: '7%' }}>
                    <img src={accordion} alt='' />
                  </div>
                  <div style={{ width: '45%' }}>
                    <Text>Risk statement</Text>
                  </div>
                  <div style={{ width: '48%' }}>
                    <Text style={{ color: '#8FA4B5' }}>Non selected</Text>
                  </div>
                </div>
                <div className={styles['dataBottomRow']}>
                  <div style={{ width: '7%' }}></div>
                  <div style={{ width: '45%', paddingRight: '10px' }}>
                    <Text style={{ fontSize: '14px', lineHeight: '21px', color: '#637381' }}>
                      All anexes marked in the contract have to be filled in and signed by the
                      client. Original has to be provided.
                    </Text>
                  </div>
                  <div style={{ width: '48%' }}>
                    <FileUpload />
                    <TextInput placeholder='You can leave a comment' style={{ width: '100%' }} />
                  </div>
                </div>
              </div>
              <div className={styles['dataAccordionRow']}>
                <div className={styles['dataTopRow']}>
                  <div style={{ width: '7%' }}>
                    <img src={accordion} alt='' />
                  </div>
                  <div style={{ width: '45%' }}>
                    <Text>Risk statement</Text>
                  </div>
                  <div style={{ width: '48%' }}>
                    <Text style={{ color: '#8FA4B5' }}>Non selected</Text>
                  </div>
                </div>
              </div>
              <div className={styles['dataAccordionRow']}>
                <div className={styles['dataTopRow']}>
                  <div style={{ width: '7%' }}>
                    <img src={accordion} alt='' />
                  </div>
                  <div style={{ width: '45%' }}>
                    <Text>Risk statement</Text>
                  </div>
                  <div style={{ width: '48%' }}>
                    <Text style={{ color: '#8FA4B5' }}>Non selected</Text>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Box>

        <FooterButtons onNext={() => history.push('/registration/investor/8')} />
      </RegContainer>
    </StepsPanelContainer>
  );
};

const StepsPanelContainer = styled.div`
  display: flex;
`;
const Title = styled(Title2)`
  font-weight: 300;
  margin-bottom: ${7 * GU}px;
`;
const InfoBlock = styled(Info)`
  margin: ${GU * 4}px 0;
`;
const InputLabel = styled(Label2)``;
