import React from 'react';
import { useProductsSections } from '../hooks';
import { SectionList } from '../../constructorCommon';

export const ProductSectionList = ({productId = ''}) => {
  const {data, isLoading} = useProductsSections(productId);

  console.log('QuestionnaireSectionList: data', data);
  return (
    <SectionList data={data} isLoading={isLoading} type={'products'}/>
  );
};
