import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Step1, Step2, Step3 } from './steps';
import { history } from '../../../routing';

const goNext = (currentStep) => {
  history.push(`/registration/investor/${++currentStep}`);
};
const goBack = (currentStep) => {
  let newStep = --currentStep;
  if (newStep < 1) newStep = 1;
  history.push(`/registration/investor/${newStep}`);
};

const InvestorReg = () => {
  const { page } = useParams();

  const renderStep = useCallback((page) => {
    switch (+page) {
      case 1:
        return <Step1 stepNumber={page} goNext={() => goNext(page)} goBack={() => goBack(page)} />;
      case 2:
        return <Step2 stepNumber={page} goNext={() => goNext(page)} goBack={() => goBack(page)} />;
      case 3:
        return <Step3 stepNumber={page} goNext={() => goNext(page)} goBack={() => goBack(page)} />;
      default:
        return null;
    }
  }, []);

  return renderStep(page);
};

export default InvestorReg;
