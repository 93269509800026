import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { dealsActions } from '../../../../store/deals';
import { Deals } from '../../../../pages/investor';

const dealsInTable = 5;

export const DealsContainer = () => {
  const dispatch = useDispatch();
  const { deals, filters } = useSelector((state) => state.deals);

  const fetchDeals = (params) => {
    dispatch(
      dealsActions.getDeals({
        take: dealsInTable,
        reverse: true,
        ...params
      })
    );
  };

  const saveFilters = (filters) => {
    dispatch(dealsActions.setFilters(filters));
  };

  return (
    <Deals
      deals={deals && deals}
      dealsInTable={dealsInTable}
      fetchDeals={fetchDeals}
      savedFilters={filters}
      saveFilters={saveFilters}
    />
  );
};
