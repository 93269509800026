import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { history } from '../../../routing';
import { Header, GU } from '@aragon/ui';
import {
  SummaryTable,
  AvailableAssetsTable,
  OrdersTable,
  MoreBtn,
  InnerBox,
  OrdersHistoryFilters,
  Tabs,
  Pagination,
  SearchInput,
  Box
} from '../../../components';
import { Button, Row, Col, Body2Light, PageContainer, Title2 } from '../../../ui';
import {
  ASSET_TYPES_LIST,
  ORDER_STATUS_TABS,
  ORDER_TAB_STATUSES,
  ORDER_TYPES_LIST,
  VALID_TILL_OPTIONS
} from '../../../utils/static';
import { getOrdersTabStatuses, newObject } from '../../../utils/helpers';

export * from './orderDetail';

const formatOrderFilters = (filters, selectedTab) => {
  const { ticker, currencyIndex, securityTypeIndex, orderTypeIndex, validTillIndex, dateRange } =
    filters;

  return {
    status: getOrdersTabStatuses(selectedTab),
    ticker: ticker,
    currency: CURRENCY_VALUE[CURRENCIES[currencyIndex]],
    assetType: ASSET_TYPES_LIST[securityTypeIndex - 1],
    type: ORDER_TYPES_LIST[orderTypeIndex - 1],
    orderValidTillOption: VALID_TILL_OPTIONS[validTillIndex - 1],
    startDate: dateRange.start,
    endDate: dateRange.end
  };
};

export const OrdersPage = ({
  cashSummary,
  orders,
  assets,
  refetchOrders,
  refetchAssets,
  ordersInTable,
  savedFilters,
  saveFilters
}) => {
  const [selectedTab, setSelectedTab] = useState(savedFilters?.selectedTab || 0);
  const [filters, setFilters] = useState({
    ticker: savedFilters?.ticker || '',
    currencyIndex: savedFilters?.currencyIndex || -1,
    securityTypeIndex: savedFilters?.securityTypeIndex || -1,
    orderTypeIndex: savedFilters?.orderTypeIndex || -1,
    validTillIndex: savedFilters?.validTillIndex || -1,
    dateRange: savedFilters?.dateRange || { start: null, end: null }
  });
  const [page, setPage] = useState(savedFilters?.page || 0);
  const [searchAssets, setSearchAssets] = useState(savedFilters?.searchAssets || '');

  useEffect(() => {
    refetchOrders({
      ...formatOrderFilters(filters, selectedTab),
      skip: page * ordersInTable
    });

    refetchAssets({ search: searchAssets });
  }, []);

  const changeFilters = (key, value) => {
    const newFilters = newObject(filters, key, value);
    onUpdateFilters(newFilters);
    setFilters(newFilters);
  };

  const onUpdateSelectedTab = (value) => {
    refetchOrders(formatOrderFilters(filters, value));
    setSelectedTab(value);
    setPage(0);
  };

  const onUpdateFilters = (filters) => {
    refetchOrders(formatOrderFilters(filters, selectedTab));
    setPage(0);
  };

  const onChangePagination = (value) => {
    refetchOrders({
      ...formatOrderFilters(filters, selectedTab),
      skip: value * ordersInTable
    });

    setPage(value);
  };

  const updateAssetsSearch = (value) => {
    refetchAssets({ search: value });

    setSearchAssets(value);
  };

  const onDetailsClick = () => {
    saveFilters({
      selectedTab: selectedTab,
      page: page,
      searchAssets: searchAssets,
      ...filters
    });
  };

  return (
    <PageContainer>
      <Header
        primary={<Title2>Orders</Title2>}
        secondary={
          <Row margin={-GU}>
            <Col>
              <Button
                onClick={() => history.push('/wallet')}
                label='Money Transfer'
                mode='strong'
              />
            </Col>
            <Col>
              <Button
                onClick={() => history.push('/assets/available')}
                label='Make buy order'
                mode='strong'
              />
            </Col>
            <Col>
              <Button
                onClick={() => history.push('/assets/self')}
                label='Make sell order'
                mode='strong'
              />
            </Col>
          </Row>
        }
      />

      <Box padding={0}>
        <SummaryTable data={cashSummary} />
      </Box>

      <TabsWrapper>
        <Tabs items={ORDER_STATUS_TABS} selected={selectedTab} onChange={onUpdateSelectedTab} />
      </TabsWrapper>

      <Box padding={0} topRadius={'0'}>
        <InnerBox paddingY={GU} noBorder>
          <Body2Light marginTop={2 * GU}>Order History</Body2Light>

          <OrdersHistoryFilters
            selectedStatuses={getOrdersTabStatuses(selectedTab)}
            onUpdateFilters={(params) => refetchOrders(params)}
            filters={filters}
            changeFilters={changeFilters}
            marginTop={4 * GU}
          />
        </InnerBox>

        <OrdersTable
          data={orders ? orders?.orders || [] : null}
          onDetailsSideEffect={onDetailsClick}
        />

        {orders && orders.count > ordersInTable && (
          <Pagination
            selected={page}
            onChange={onChangePagination}
            pages={Math.ceil(orders.count / ordersInTable)}
          />
        )}
      </Box>

      <Box padding={0}>
        <Row
          justifyContent={'space-between'}
          paddingX={3 * GU}
          paddingTop={3 * GU}
          paddingBottom={GU}
        >
          <Body2Light>Available Assets</Body2Light>

          <SearchInput
            value={searchAssets}
            onChange={updateAssetsSearch}
            placeholder='Search'
            style={{ minWidth: '300px' }}
          />
        </Row>

        <AvailableAssetsTable data={assets?.assets} onDetailsSideEffect={onDetailsClick} />
        <MoreBtn link={'/assets/available'} />
      </Box>
    </PageContainer>
  );
};

const CURRENCIES = ['All', 'EUR'];
const CURRENCY_VALUE = {
  All: null,
  EUR: 'EUR'
};

const TabsWrapper = styled.div`
  > div > div {
    margin: ${3 * GU}px 0 0 0;
    position: relative;
    top: 3px;
    z-index: 1;
  }
`;
