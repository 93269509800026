import axios from 'axios';
import { API_URL } from '../../../utils/static';

const createProduct = (product) => {
  return axios.post(
    `${API_URL}/api/kyc/v1/editingProduct/product`,
    {
      type: product.type,
      name: product.name,
      title: product.title,
      descriptions: product.descriptions,
      isRequired: product.isRequired
    }
  );
};
const updateProduct = (product) => {
  return axios.put(
    `${API_URL}/api/kyc/v1/editingProduct/product`,
    {
      productId: product.id,
      type: product.type,
      name: product.name,
      name_RU: product.name_RU,
      name_LV: product.name_LV,
      title: product.title,
      title_RU: product.title_RU,
      title_LV: product.title_LV,
      descriptions: product.descriptions,
      descriptions_RU: product.descriptions_RU,
      descriptions_LV: product.descriptions_LV,
      typeProduct: product.typeProduct,
      typeProduct_RU: product.typeProduct_RU,
      typeProduct_LV: product.typeProduct_LV,
      interestRate: product.interestRate,
      interestRate_RU: product.interestRate_RU,
      interestRate_LV: product.interestRate_LV,
      isin: product.isin,
      term: product.term,
      term_RU: product.term_RU,
      term_LV: product.term_LV,
      interestPaid: product.interestPaid,
      interestPaid_RU: product.interestPaid_RU,
      interestPaid_LV: product.interestPaid_LV,
      redemption: product.redemption,
      redemption_RU: product.redemption_RU,
      redemption_LV: product.redemption_LV,
      offerPeriodUntil: product.offerPeriodUntil,
      processingFee: product.processingFee,
      staggeredArrangement: product.staggeredArrangement,
      minimalAmount: product.minimalAmount === '' ? null : product.minimalAmount,
      stockDeposit: product.stockDeposit,
      offerAndConditions: product.offerAndConditions,
      offerAndConditions_RU: product.offerAndConditions_RU,
      offerAndConditions_LV: product.offerAndConditions_LV,
      filesToDownload1: product.filesToDownload1,
      filesToDownload1_RU: product.filesToDownload1_RU,
      filesToDownload1_LV: product.filesToDownload1_LV,
      filesToDownload2: product.filesToDownload2,
      filesToDownload2_RU: product.filesToDownload2_RU,
      filesToDownload2_LV: product.filesToDownload2_LV,
      filesToDownload3: product.filesToDownload3,
      filesToDownload3_RU: product.filesToDownload3_RU,
      filesToDownload3_LV: product.filesToDownload3_LV,
      company: product.company,
      company_RU: product.company_RU,
      company_LV: product.company_LV,
      linkToCentralBankOfLatvia: product.linkToCentralBankOfLatvia,
      isRequired: product.isRequired
    }
  )
};
const deleteProduct = (product) => {
  return axios.delete(`${API_URL}/api/kyc/v1/editingProduct/product`, {
    data: { productId: product.id }
  });
};

const createSection = (productId, section) => {
  return axios.post(
    `${API_URL}/api/kyc/v1/editingProduct/productSection`,
    {
      productId: productId,
      section: section.section,
      section_RU: section.section_RU,
      section_LV: section.section_LV,
      section_DE: section.section_DE,
      sortOrder: section.sortOrder
    }
  )
};
const updateSection = (section) => {
  return axios.put(
    `${API_URL}/api/kyc/v1/editingProduct/productSection`,
    {
      productSectionId: section.id,
      section: section.section,
      section_RU: section.section_RU,
      section_LV: section.section_LV,
      section_DE: section.section_DE,
      sortOrder: section.sortOrder
    }
  )
};
const deleteSection = (section) => {
  return axios.delete(`${API_URL}/api/kyc/v1/editingProduct/productSection`, {
    data: { productSectionId: section.id }
  });
};

const createGroup = (group, sectionId) => {
  return axios.post(
    `${API_URL}/api/kyc/v1/editingProduct/productGroup`,
    {
      productSectionId: sectionId,
      title: group.title,
      title_RU: group.title_RU,
      title_LV: group.title_LV,
      title_DE: group.title_DE,
      isMultiple: group.isMultiple,
      multipleMin: group.multipleMin,
      multipleMax: group.multipleMax,
      sortOrder: group.sortOrder,
      visibleConditions: group.visibleConditions,
      visibleOperator: group.visibleOperator
    }
  )
};
const updateGroup = (group) => {
  return axios.put(
    `${API_URL}/api/kyc/v1/editingProduct/productGroup`,
    {
      productGroupId: group.id,
      title: group.title,
      title_RU: group.title_RU,
      title_LV: group.title_LV,
      title_DE: group.title_DE,
      isMultiple: group.isMultiple,
      multipleMin: group.multipleMin,
      multipleMax: group.multipleMax,
      sortOrder: group.sortOrder,
      visibleConditions: group.visibleConditions,
      visibleOperator: group.visibleOperator
    }
  )
};
const deleteGroup = (group) => {
  return axios.delete(`${API_URL}/api/kyc/v1/editingProduct/productGroup`, {
    data: { productGroupId: group.id }
  });
};

const createQuestion = (question, productGroupId) => {
  return axios.post(
    `${API_URL}/api/kyc/v1/editingProduct/productQuestion`,
    {
      productGroupId: productGroupId,
      question: question.question,
      type: question.type,
      question_RU: question.question_RU,
      question_LV: question.question_LV,
      question_DE: question.question_DE,
      tooltip: question.tooltip,
      isRequired: question.isRequired,
      sortOrder: question.sortOrder,
      visibleConditions: question.visibleConditions,
      visibleOperator: question.visibleOperator,
      preFilledValue: question.preFilledValue,
      dateForSelect: question.dateForSelect,
      minCountSymbols: question.minCountSymbols,
      maxCountSymbols: question.maxCountSymbols,
      countFirstLetterSymbolsCountry: question.countFirstLetterSymbolsCountry
    }
  );
};
const updateQuestion = (question) => {
  return axios.put(
    `${API_URL}/api/kyc/v1/editingProduct/productQuestion`,
    {
      productQuestionId: question.id,
      question: question.question,
      question_RU: question.question_RU,
      question_LV: question.question_LV,
      question_DE: question.question_DE,
      tooltip: question.tooltip,
      isRequired: question.isRequired,
      sortOrder: question.sortOrder,
      visibleConditions: question.visibleConditions,
      visibleOperator: question.visibleOperator,
      preFilledValue: question.preFilledValue,
      dateForSelect: question.dateForSelect,
      minCountSymbols: question.minCountSymbols,
      maxCountSymbols: question.maxCountSymbols,
      countFirstLetterSymbolsCountry: question.countFirstLetterSymbolsCountry,
    }
  )
};
const deleteQuestion = (question) => {
  return axios.delete(`${API_URL}/api/kyc/v1/editingProduct/productQuestion`, {
    data: { productQuestionId: question.id }
  });
};

const createAnswer = (answer, questionId) => {
  return axios.post(
    `${API_URL}/api/kyc/v1/editingProduct/productAvailableAnswer`,
    {
      productQuestionId: questionId,
      answer: answer.answer,
      answer_RU: answer.answer_RU,
      answer_LV: answer.answer_LV,
      answer_DE: answer.answer_DE,
      sortOrder: answer.sortOrder,
      points: answer.points
    }
  )
};
const updateAnswer = (answer) => {
  return axios.put(
    `${API_URL}/api/kyc/v1/editingProduct/productAvailableAnswer`,
    {
      productAvailableAnswerId: answer.id,
      answer: answer.answer,
      answer_RU: answer.answer_RU,
      answer_LV: answer.answer_LV,
      answer_DE: answer.answer_DE,
      sortOrder: answer.sortOrder,
      points: answer.points
    }
  )
};
const deleteAnswer = (answer) => {
  return axios.delete(`${API_URL}/api/kyc/v1/editingProduct/productAvailableAnswer`, {
    data: { productAvailableAnswerId: answer.id }
  });
};

export const ConstructorApi = {
  createProduct,
  updateProduct,
  deleteProduct,
  createSection,
  updateSection,
  deleteSection,
  createGroup,
  updateGroup,
  deleteGroup,
  createQuestion,
  updateQuestion,
  deleteQuestion,
  createAnswer,
  updateAnswer,
  deleteAnswer
};
