import React from 'react';
import { GU } from '@aragon/ui';
import { Box, InnerBox, StatusLine, BoxLine } from '../../../../components';
import { Title5, Body2Light, Link } from '../../../../ui';
import { COLORS, TRANSFER_STATUSES, DISPLAY_TRANSACTION_TYPE } from '../../../../utils/static';
import { displayFullDate } from '../../../../utils/helpers';

export const TransactionDetailsBox = ({ data }) => {
  const { amount, currency, status, id, type, createdAt, order, dealId, parentTransferId } = data;

  return (
    <Box padding={0}>
      <InnerBox paddongCss={'32px 24px 22px 21px'}>
        <Title5>
          {amount} {currency}
        </Title5>
        <StatusLine statusTypes={TRANSFER_STATUSES} type={status} />
      </InnerBox>

      <InnerBox paddingY={2 * GU}>
        <BoxLine title={'Transaction ID'} data={id} />
        <BoxLine title={'Type'}>
          <Body2Light color={COLORS.aragonBlue}>{DISPLAY_TRANSACTION_TYPE[type]}</Body2Light>
        </BoxLine>
        <BoxLine title={'Date & Time'} data={displayFullDate(new Date(createdAt))} />
        <BoxLine title={'Currency'} data={currency} />
        {/*<BoxLine title={'Order'}>*/}
        {/*  <Link external>{order}</Link>*/}
        {/*</BoxLine>*/}
        {type === 'DEAL_TRANSACTION' && dealId && (
          <BoxLine title={'Deal'}>
            <Link external defaultColor to={`/deal/${dealId}`}>
              id{dealId}
            </Link>
          </BoxLine>
        )}

        {type === 'COMMISSION_TRANSACTION' && parentTransferId && (
          <BoxLine title={'Parent transaction'}>
            <Link external defaultColor to={`/transaction/${parentTransferId}`}>
              id{parentTransferId}
            </Link>
          </BoxLine>
        )}
      </InnerBox>
    </Box>
  );
};
