import React, { useState } from 'react';
import { Header, Button, Bar, BackButton, Split, Text, Box, SidePanel } from '@aragon/ui';
import textStyle from '../../../utils/textStyle';
import styles from '../style.module.css';
import { ParamText } from '../../../components/index';
import logo from '../../../assets/icons/eth_icon.svg';
import fire from '../../../assets/icons/fire-icon.svg';

const UpcomingOffering = () => {
  const [opened, setOpened] = useState(false);

  return (
    <div>
      <Header
        primary='Upcoming offering'
        secondary={<Button mode='strong' label='Buy' onClick={() => setOpened(true)} />}
      />
      <Bar primary={<BackButton />} />
      <Split
        primary={
          <div>
            <Box className={styles['noPadding']}>
              <div className={styles['innerBox']}>
                <Text style={textStyle('title3')}>Offering title</Text>
                <img style={{ verticalAlign: '0', marginLeft: '8px' }} src={fire} alt='' />
                <Text
                  style={{
                    display: 'block',
                    fontSize: '12px',
                    fontWeight: '400',
                    lineHeight: '1.5',
                    marginBottom: '13px'
                  }}
                  color='#FF6969'
                >
                  Upcoming offering
                </Text>
                <Text style={textStyle('body2')}>
                  Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit
                  officia consequat duis enim velit mollit. Exercitation veniam consequat sunt
                  nostrud amet.
                </Text>
              </div>
              <div className={styles['innerBox']}>
                <ParamText param1='Asset ID' param2='ID' />
                <ParamText
                  param1='Ticker'
                  param2={
                    <div>
                      <img
                        style={{ verticalAlign: '-2px', marginRight: '8px' }}
                        src={logo}
                        alt=''
                      />
                      ETH
                    </div>
                  }
                />
              </div>
              <div className={styles['innerBox']}>
                <ParamText param1='Issue date' param2='2020-09-08' />
                <ParamText param1='Quantity' param2='Quantity' />
                <ParamText param1='Volume' param2='1000 units' />
                <ParamText param1='Price per capita' param2='Price per capita' />
                <ParamText param1='Min - Max quantity' param2='Min - Max quantity' />
              </div>
              <div className={styles['innerBox']}>
                <ParamText param1='Branch' param2='Branch' />
                <ParamText param1='Region' param2='Region' />
                <ParamText param1='Timing' param2='Timing' />
              </div>
              <div className={styles['innerBox']}>
                <ParamText param1='Estimated income' param2='Estimated income' />
                <ParamText param1='Frequency of income payment' param2='2 month' />
              </div>
              <div className={styles['innerBox']}>
                <ParamText param1='Availability of insurance' param2='NO' />
                <ParamText param1='Availability of collateral' param2='YES' />
              </div>
            </Box>
            <Box heading='Issuer' className={styles['noPadding']}>
              <div className={styles['innerBox']}>
                <ParamText
                  param1='Legal adress'
                  param2='3517 W. Gray St. Utica, Pennsylvania 57867'
                />
                <ParamText param1='Account name' param2='Account name' />
                <ParamText param1='President' param2='Lorem Ipsum' />
                <ParamText param1='Registration number' param2='57218924814867928jwkef823' />
              </div>
              <div className={styles['innerBox']}>
                <ParamText param1='Turnover' param2='234' />
                <ParamText param1='Assets sum' param2='143245' />
              </div>
            </Box>
          </div>
        }
        secondary={
          <Box heading='Issuer'>
            <ParamText param1='Param1' param2='1' />
            <ParamText param1='Param2' param2='2' />
            <ParamText param1='Param3' param2='3' />
            <ParamText param1='Param4' param2='4' />
          </Box>
        }
      />
      <SidePanel title='Buy' opened={opened} onClose={() => setOpened(false)}>
        <div className={styles['innerBox']}>
          <Text style={textStyle('body1Wide')}>Title</Text>
          <Text style={textStyle('body1')} color='#637381'>
            ID
          </Text>
        </div>
        <div className={styles['innerBox']}>
          <Text style={textStyle('label2')}>Select Quantity</Text>
          <ParamText param1='Price per capita' param2='34' />
          <ParamText param1='Comission' param2='1%' />
        </div>
        <div className={styles['innerBox']}>
          <ParamText param1='Total Price' param2='234' />
          <Button label='Buy' mode='strong' wide='true' />
        </div>
        <div className={styles['innerBox']}>
          <Text style={textStyle('label2')}>Estimated income</Text>
          <ParamText param1='1 month' param2='$Estimated income' />
          <ParamText param1='2 month' param2='$Estimated income' />
        </div>
      </SidePanel>
    </div>
  );
};

export default UpcomingOffering;
