import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Button, Checkbox, Input, DatePicker } from 'antd';
import { Container, ExternalLink, Title4, Title5 } from '../../../ui';
import { useProduct } from '../hooks';
import { ActionBox, Box, Label, NestedBox, Space } from '../../constructorCommon/styled';
import { history } from '../../../routing';
import { ProductSectionList } from './ProductSectionList';
import { ProductSection } from './ProductSection';
import { DocumentLine, FileUploadButton, UploadDocumentsBox, ZoomImage } from '../../../components';
import { API_URL } from '../../../utils/static';

const { TextArea } = Input;

export const Product = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const {data, handleCreate, handleUpdate, handleFileUpload, handleFileDelete, refetch} = useProduct(id);
  const [showCreateNew, setShowCreateNew] = useState(false);
  const [product, setProduct] = useState({});
  const [image, setImage] = useState(null);
  const [files, setFiles] = useState([]);

  useEffect(() => {
    if (!data?.id) return;

    setProduct(data);
  }, [data])

  const onChange = (e) => {
    const {name, value} = e.target;
    setProduct({...product, [name]: value})
  }

  const onChangeNumber = (e) => {
    const {name, value} = e.target;

    if (!Number(value) && value.length > 0) {
      return;
    }

    setProduct({...product, [name]: value})
  }

  const onChangeDate = (value, dateString) => {
    setProduct({...product, offerPeriodUntil: new Date(dateString).toISOString()})
  }

  const onChangeIsRequired = (e) => {
    const {name, checked} = e.target;
    setProduct({...product, [name]: checked})
  }

  const handleAction = () => {
    if (product.id) {
      handleUpdate({
        ...product,
        ...(image && { logoURL: image })
      }).then(() => {
        refetch();
        setImage(null);
      });
    } else {
      handleCreate(product).then((res) => {
        if(res?.data?.id) {
          history.push(`/constructorProducts/${res.data.id}`);
        }
      });
    }
  }
  const handleUploadFiles = () => {
    if (files.length > 0) {
      const promises = files.map((file) => {
        return handleFileUpload({
          file: file,
          productId: product.id,
          name: file.name
        })
      })
      Promise.all(promises).then(() => setFiles([]));
    }
  }
  const handleDeleteFile = (fileId) => {
    handleFileDelete(fileId);
  }

  const isDisabled = !product.type;

  return (
    <Container paddingY={20}>

      <Box isNew={true}>
        {product.id && <>
          <Label>{t('Product image')}</Label>
          {product.logoURL &&
            <ZoomImage
              image={product.logoURL}
            />
          }
          <UploadDocumentsBox
            docs={image ? [image] : null}
            setDocs={(image) => setImage(image)}
            buttonProps={{
              label: t('Upload image')
            }}
          />
          <Space />
        </>}

        <Label>{t('Title')}</Label>
        <Input name="title" value={product.title} onChange={onChange} />
        <Space />

        {product.id && (
          <>
            <Label>{t('Title_RU')}</Label>
            <Input name="title_RU" value={product.title_RU} onChange={onChange} />
            <Space />
            <Label>{t('Title_LV')}</Label>
            <Input name="title_LV" value={product.title_LV} onChange={onChange} />
            <Space />
            <Space />
          </>
        )}

        <Label>{t('Name')}</Label>
        <Input name="name" value={product.name} onChange={onChange} />
        <Space />

        {product.id && (
          <>
            <Label>{t('Name_RU')}</Label>
            <Input name="name_RU" value={product.name_RU} onChange={onChange} />
            <Space />
            <Label>{t('Name_LV')}</Label>
            <Input name="name_LV" value={product.name_LV} onChange={onChange} />
            <Space />
            <Space />
          </>
        )}

        <Label>{t('Type')}</Label>
        <Input name="type" value={product.type} onChange={onChange} />
        <Space />
        {product.id && <Space />}

        <Label>{t('Description')}</Label>
        <TextArea name="descriptions" value={product.descriptions} onChange={onChange} rows={3} />
        <Space />

        {product.id && (
          <>
            <Label>{t('Description_RU')}</Label>
            <TextArea name="descriptions_RU" value={product.descriptions_RU} onChange={onChange} rows={3} />
            <Space />
            <Label>{t('Descriptions_LV')}</Label>
            <TextArea name="descriptions_LV" value={product.descriptions_LV} onChange={onChange} rows={3} />
            <Space />
            <Space />
            <Label>{t('Product Type - Bonds or Funds')}</Label>
            <Input name="typeProduct" value={product.typeProduct} onChange={onChange} />
            <Space />
            <Label>{t('Product Type - Bonds or Funds (RU)')}</Label>
            <Input name="typeProduct_RU" value={product.typeProduct_RU} onChange={onChange} />
            <Space />
            <Label>{t('Product Type - Bonds or Funds (LV)')}</Label>
            <Input name="typeProduct_LV" value={product.typeProduct_LV} onChange={onChange} />
            <Space />
            <Space />
            <Label>{t('Interest rate')}</Label>
            <Input name="interestRate" value={product.interestRate} onChange={onChange} />
            <Space />
            <Label>{t('Interest rate (RU)')}</Label>
            <Input name="interestRate_RU" value={product.interestRate_RU} onChange={onChange} />
            <Space />
            <Label>{t('Interest rate (LV)')}</Label>
            <Input name="interestRate_LV" value={product.interestRate_LV} onChange={onChange} />
            <Space />
            <Space />
            <Label>{t('ISIN number')}</Label>
            <Input name="isin" value={product.isin} onChange={onChange} />
            <Space />
            <Space />
            <Label>{t('Term')}</Label>
            <Input name="term" value={product.term} onChange={onChange} />
            <Space />
            <Label>{t('Term (RU)')}</Label>
            <Input name="term_RU" value={product.term_RU} onChange={onChange} />
            <Space />
            <Label>{t('Term (LV)')}</Label>
            <Input name="term_LV" value={product.term_LV} onChange={onChange} />
            <Space />
            <Space />
            <Label>{t('Interest paid')}</Label>
            <Input name="interestPaid" value={product.interestPaid} onChange={onChange} />
            <Space />
            <Label>{t('Interest paid (RU)')}</Label>
            <Input name="interestPaid_RU" value={product.interestPaid_RU} onChange={onChange} />
            <Space />
            <Label>{t('Interest paid (LV)')}</Label>
            <Input name="interestPaid_LV" value={product.interestPaid_LV} onChange={onChange} />
            <Space />
            <Space />
            <Label>{t('Redemption')}</Label>
            <Input name="redemption" value={product.redemption} onChange={onChange} />
            <Space />
            <Label>{t('Redemption (RU)')}</Label>
            <Input name="redemption_RU" value={product.redemption_RU} onChange={onChange} />
            <Space />
            <Label>{t('Redemption (LV)')}</Label>
            <Input name="redemption_LV" value={product.redemption_LV} onChange={onChange} />
            <Space />
            <Space />
            <Label>{t('Offer period')}</Label>
            <DatePicker name="offerPeriodUntil" onChange={onChangeDate} />
            <Space />
            <Space />
            <Checkbox name="processingFee"
                      checked={product.processingFee}
                      onChange={onChangeIsRequired}
            >
              {t('Processing fee')}
            </Checkbox>
            <Space />
            <Space />
            <Checkbox name="staggeredArrangement"
                      checked={product.staggeredArrangement}
                      onChange={onChangeIsRequired}
            >
              {t('Staggered arrangement')}
            </Checkbox>
            <Space />
            <Space />
            <Checkbox name="stockDeposit"
                      checked={product.stockDeposit}
                      onChange={onChangeIsRequired}
            >
              {t('Stock deposit')}
            </Checkbox>
            <Space />
            <Space />
            <Label>{t('Minimal amount')}</Label>
            <Input name="minimalAmount" type="number" min={0} step={1} value={product.minimalAmount}
                   onChange={onChangeNumber} />
            <Space />
            <Space />
            <Label>{t('Offer and conditions')}</Label>
            <Input name="offerAndConditions" value={product.offerAndConditions} onChange={onChange} />
            <Space />
            <Label>{t('Offer and conditions (RU)')}</Label>
            <Input name="offerAndConditions_RU" value={product.offerAndConditions_RU} onChange={onChange} />
            <Space />
            <Label>{t('Offer and conditions (LV)')}</Label>
            <Input name="offerAndConditions_LV" value={product.offerAndConditions_LV} onChange={onChange} />
            <Space />
            <Space />
            <Label>{t('Company')}</Label>
            <Input name="company" value={product.company} onChange={onChange} />
            <Space />
            <Label>{t('Company (RU)')}</Label>
            <Input name="company_RU" value={product.company_RU} onChange={onChange} />
            <Space />
            <Label>{t('Company (LV)')}</Label>
            <Input name="company_LV" value={product.company_LV} onChange={onChange} />
            <Space />
            <Space />
            <Label>{t('Link to Central bank of Latvia')}</Label>
            <Input name="linkToCentralBankOfLatvia" value={product.linkToCentralBankOfLatvia} onChange={onChange} />
            <Space />
            <Checkbox name="isRequired"
                      checked={product?.isRequired}
                      onChange={onChangeIsRequired}
            >
              {t('Is required')}
            </Checkbox>
            <Space />
          </>
        )}

        <ActionBox>
          <Button onClick={handleAction}
                  disabled={isDisabled}
                  type="primary"
          >
            {product.id ? t('Update Product') : t('Create Product')}
          </Button>
        </ActionBox>
      </Box>

      {product.id && (
        <>
        <Container paddingY={20}>
          <NestedBox>
            <ProductSectionList productId={id} />
            <Space />

            {showCreateNew ? (
              <ProductSection onCreated={() => setShowCreateNew(false)} />
            ) : (
              <Button onClick={() => setShowCreateNew(true)}>
                {t('Add new Section')}
              </Button>
            )}

          </NestedBox>
        </Container>

        <Container paddingX={20} paddingY={10}>
          {product.productFileToDownloads && product.productFileToDownloads.length > 0 &&
            <>
              <Title4>{t('Downloads')}</Title4>
              {product.productFileToDownloads.map((file, index) =>
                <DocumentLine
                  doc={{
                    name: file.name ?? "Untitled",
                    documentURL: `${API_URL}/${file.fileURL}`,
                    id: file.id
                  }}
                  onDelete={() => handleDeleteFile(file.id)}
                  index={index + 1}
                  key={file.id}
                />
              )}
              <Space/>
            </>}
          <Space/>
          <Title4>{t('Upload new downloads')}</Title4>
          <UploadDocumentsBox
            docs={files}
            setDocs={setFiles}
            buttonProps={{
              label: files.length > 0 ? t('Choose more files') : t('Choose files')
            }}
            multiple
          />
          <Space />
          <Space />
          <Button
            onClick={handleUploadFiles}
            disabled={files.length === 0}
            type="primary"
          >
            {t('Upload files')}
          </Button>
          <Space />
        </Container>
        </>
      )}

    </Container>
  );
};
