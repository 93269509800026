import React from 'react';
import { GU } from '@aragon/ui';
import { KYCModule } from '../../../modules/kycModule';
import config from '../../../modules/kycModule/configs/tier2Config.json';
import styled from 'styled-components';
import { history } from '../../../routing';

export const Tier3Page = () => {
  const handleSave = async (data, step) => {};

  return null;

  // return (
  //   <StyledKYCModule
  //     config={config}
  //     handleSave={handleSave}
  //   />
  // )
};

const StyledKYCModule = styled(KYCModule)`
  > * {
    min-height: calc(100vh - ${8 * GU + 1}px);
  }
`;
