import React from 'react';
import { Table, TableRow, GU } from '@aragon/ui';
import styled from 'styled-components';
import { Box } from '../../../../components';
import { TableHeader, Cell as DefaultCell } from '../../components';
import { Row, Col, Title3, Body2Light } from '../../../../ui';
import { COLORS } from '../../../../utils/static';

const defHeader = ['Total number of instruments', 'Total capitalization', 'Total payments'];
const disabledHeader = [];

export const TotalAssetsTable = ({ header = defHeader, data }) => {
  const headerItems = header.map((item, key) => (
    <TableHeader title={item} color={disabledHeader.includes(item) && COLORS.greyLight} key={key} />
  ));

  if (data) {
    return (
      <Box padding={0}>
        <StyledTable header={<TableRow>{headerItems}</TableRow>}>
          {data && (
            <TableRow>
              <Cell>
                <Row margin={-2 * GU} nowrap>
                  <Col>
                    456
                    <DescrLine title={'bonds'} />
                  </Col>
                  <Col>
                    234
                    <DescrLine title={'equity'} />
                  </Col>
                </Row>
              </Cell>

              <Cell>
                <Row margin={-2 * GU} nowrap>
                  <Col>
                    456
                    <DescrLine title={'bonds'} />
                  </Col>
                  <Col>
                    234
                    <DescrLine title={'equity'} />
                  </Col>
                </Row>
              </Cell>

              <Cell>
                <Row margin={-2 * GU} nowrap>
                  <Col>
                    456
                    <DescrLine title={'coupons'} />
                  </Col>
                  <Col>
                    234
                    <DescrLine title={'dividends'} />
                  </Col>
                  <Col>
                    234
                    <DescrLine title={'rent'} />
                  </Col>
                </Row>
              </Cell>
            </TableRow>
          )}
        </StyledTable>
      </Box>
    );
  }

  return null;
};

const Cell = ({ children, ...props }) => (
  <DefaultCell Container={Title3} cellProps={{ padding: `${3 * GU}px ${3 * GU}px` }} {...props}>
    {children}
  </DefaultCell>
);

const DescrLine = ({ title, children }) => (
  <Body2Light marginTop={1.5 * GU} color={COLORS.greyMedium}>
    {title || children}
  </Body2Light>
);

const StyledTable = styled(Table)`
  tbody {
    vertical-align: top;
  }

  th {
    padding-left: ${3 * GU}px;
    padding-right: ${3 * GU}px;
  }
  th:last-child {
    width: 100%;
  }

  td,
  td:first-child {
    border: none;
  }
  tr:first-child > td {
    border-right: none;
  }
`;
