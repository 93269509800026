import React, { useEffect, useState } from 'react';
import { Header, GU } from '@aragon/ui';
import { InvestorActivity, InvestorDocuments, InvestorReferralRequests } from '../../../../../containers';
import {
  Tabs,
  ProfileInfoView,
  BackBar,
  StatusLine,
  InvestorProducts
} from '../../../../../components';
import { ProfileStatus } from '../../../../../components/admin';
import { Button, PageContainer, Row, Col, Title2, Body3Light } from '../../../../../ui';
import { INVESTOR_TYPES_CONFIG } from '../../../../../utils/static';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { investorActions } from '../../../../../store/investor';
import { useLocation } from 'react-router-dom';

export const InvestorDetailsView = ({ investorData, approveInvestor, rejectInvestor, refetchInvestorInfo }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { userRole } = useSelector((state) => state.user);
  const { state } = useLocation();
  const [selectedTab, setSelectedTab] = useState(state?.lastProfileTab || 0);
  const [isEditSection, setIsEditSection] = useState(false);
  const [saveTab, setSaveTab] = useState('');

  useEffect(() => {
    dispatch(investorActions.setLastProfileTab(selectedTab));
  }, [selectedTab]);

  const handleSave = () => {
    setSaveTab('ProfileInfo');
  };

  const saveCallback = () => {
    setIsEditSection(false);
    setSaveTab('');
  };

  return (
    <PageContainer>
      <Header
        primary={
          <Row margin={-GU} alignItems={'center'}>
            <Title2>{t('Investor for approval')}</Title2>
            {investorData.account && (
              <StatusLine
                statusTypes={INVESTOR_TYPES_CONFIG}
                type={investorData.account}
                TitleStyle={Body3Light}
                titleMargin={GU}
              />
            )}
          </Row>
        }
        secondary={
          <Row margin={-1.5 * GU}>
            <Col>
              <Button
                label={investorData.accountStatus === 'NOT_ACTIVE' ? t('Blocked') : t('Block')}
                onClick={rejectInvestor}
                disabled={investorData.accountStatus === 'NOT_ACTIVE'}
                paddingX={'26'}
              />
            </Col>
            <Col>
              <Button
                label={t('Active')}
                onClick={approveInvestor}
                disabled={investorData.accountStatus === 'ACTIVE'}
                mode={'strong'}
                paddingX={'18'}
              />
            </Col>
          </Row>
        }
      />

      <BackBar url={'/investors/PERSONAL'} />

      <Tabs
        items={[t('Personal data'), t('Questionnaires'), t('Documents'), t('Referral requests'), t('Products'), t('Activity')]}
        selected={selectedTab}
        onChange={setSelectedTab}
        containerProps={{ marginTop: 3 * GU }}
      />

      {/*<Container marginBottom={2*GU}>*/}
      {/*  {!isEditSection && <Button*/}
      {/*    onClick={() => setIsEditSection(true)}*/}
      {/*    label={'Edit section'}*/}
      {/*    paddingX={2*GU}*/}
      {/*    disabled={!investorData.registrationFinished}*/}
      {/*  />}*/}

      {/*  {isEditSection && <Row margin={-GU}>*/}
      {/*    <Col auto>*/}
      {/*      <Button*/}
      {/*        label={'Cancel'}*/}
      {/*        onClick={() => setIsEditSection(false)}*/}
      {/*        paddingX={3*GU}*/}
      {/*      />*/}
      {/*    </Col>*/}
      {/*    <Col auto>*/}
      {/*      <Button*/}
      {/*        label={'Save'}*/}
      {/*        onClick={handleSave}*/}
      {/*        mode={'strong'}*/}
      {/*        paddingX={3*GU}*/}
      {/*      />*/}
      {/*    </Col>*/}
      {/*  </Row>}*/}
      {/*</Container>*/}
      {/*<ProfileInfo/>*/}

      {selectedTab === 0 && investorData && (
        <ProfileInfoView
          investor={investorData}
          isEdit={isEditSection}
          saveTab={saveTab}
          saveCallback={saveCallback}
          viewMode={userRole}
          refetchInvestorInfo={refetchInvestorInfo}
        />
      )}

      {selectedTab === 1 && investorData?.id && (
        <ProfileStatus investorData={investorData} adminView />
      )}

      {/*Documents*/}
      {selectedTab === 2 && <InvestorDocuments />}

      {/*Referral requests*/}
      {selectedTab === 3 && <InvestorReferralRequests />}

      {/*Products*/}
      {selectedTab === 4 && <InvestorProducts investorData={investorData} />}

      {/*Activity*/}
      {selectedTab === 5 && <InvestorActivity />}
    </PageContainer>
  );
};
