import React from 'react';
import styled from 'styled-components';
import { GU, IconCross as IconCheckAragon } from '@aragon/ui';

export const IconCross = ({ onClick, hide = false, ...props }) => {
  return (
    <IconCheckWrapper onClick={onClick} hide={hide} {...props}>
      <IconCheckAragon />
    </IconCheckWrapper>
  );
};

const IconCheckWrapper = styled.div`
  width: ${4 * GU}px;
  height: ${4 * GU}px;
  padding: 4px;
  background: #ff6969;
  color: #ffffff;
  border-radius: 100%;
  visibility: ${({ hide }) => (hide ? 'hidden' : 'visible')};
`;
