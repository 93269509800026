import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { walletActions } from '../../../../store/wallet';
import { WalletPage } from '../../../../pages/investor';
import { investorActions } from '../../../../store/investor';

const transactionsInTable = 7;

export const WalletContainer = () => {
  const dispatch = useDispatch();
  const { transactions, filters } = useSelector((state) => state.wallet);
  const { cashSummary } = useSelector((state) => state.investor);

  const fetchTransactions = (params) => {
    dispatch(
      walletActions.getTransactions({
        take: transactionsInTable,
        reverse: true,
        ...params
      })
    );
  };

  const saveFilters = (filters) => {
    dispatch(walletActions.setFilters(filters));
  };

  useEffect(() => {
    dispatch(investorActions.getCashSummary());
  }, []);

  return (
    <WalletPage
      cashSummary={cashSummary}
      transactions={transactions}
      transactionsInTable={transactionsInTable}
      updateFilters={fetchTransactions}
      savedFilters={filters}
      saveFilters={saveFilters}
    />
  );
};
