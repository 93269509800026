import React, { useEffect } from 'react';
import { Assets } from '../../../../pages/investor';
import { useSelector, useDispatch } from 'react-redux';
import { assetsActions } from '../../../../store/assets';
import { investorActions } from '../../../../store/investor';

const assetsInTable = 4;

export const AssetsContainer = () => {
  const dispatch = useDispatch();
  const { assets, myAssets } = useSelector((state) => state.assets);
  const { cashSummary } = useSelector((state) => state.investor);

  useEffect(() => {
    dispatch(investorActions.getCashSummary());
    dispatch(assetsActions.getAssets({ take: assetsInTable }));
    dispatch(assetsActions.getMyAssets({ take: assetsInTable }));
  }, []);

  return <Assets assets={assets} myAssets={myAssets} cashSummary={cashSummary} />;
};
