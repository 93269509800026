import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Button, Checkbox, Modal, Radio } from 'antd';

import { useRelatedQuestionnaire, useRelatedSections } from '../hooks';



export const AddQuestions = ({
                               parentQuestionnaireId,
                               questionSectionTypeId,
                               closeModalCheckTrigger,
                               closeModalAddQuestionOpened,
                               availableAnswers
                             }) => {
  const { t } = useTranslation();

  const { handleGet } = useRelatedSections();
  const [sectionsParant, setSectionsParant] = useState([]);
  const [sections, setSections] = useState([]);
  const [groupId, setGroupId] = useState(null);
  const [conditions, setConditions] = useState(availableAnswers);
  const [loading, setLoading] = useState(true);

  const [selectedQuestions, setSelectedQuestions] = useState({
    left: null,
    right: null
  });
  const [triggerQuestions, setTriggerQuestions] = useState([]);
  const onChange = (e, question, group) => {
    setSelectedQuestions((prev) => ({
      ...prev,
      [group]: question.id
    }));
  };

  const fetchData = async () => {
    setLoading(true);
    const resParentQuestionnaire = await handleGet(parentQuestionnaireId);
    if (resParentQuestionnaire?.data) {
      setSectionsParant(resParentQuestionnaire.data);
    }
    const resquestionSectionType = await handleGet(questionSectionTypeId);
    if (resquestionSectionType?.data) {
      setSections(resquestionSectionType.data);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [parentQuestionnaireId, availableAnswers]);

  const onAdd = () => {
    const leftQuestionData = sectionsParant.find(section =>
      section.questionGroups.some(qg => qg.questions.some(q => q.id === selectedQuestions.left))
    );

    const rightQuestionData = sections.find(section =>
      section.questionGroups.some(qg => qg.questions.some(q => q.id === selectedQuestions.right))
    );

    const leftQuestion = leftQuestionData?.questionGroups
      .flatMap(qg => qg.questions)
      .find(q => q.id === selectedQuestions.left);

    const rightQuestion = rightQuestionData?.questionGroups
      .flatMap(qg => qg.questions)
      .find(q => q.id === selectedQuestions.right);

    if (leftQuestion && rightQuestion) {
      setTriggerQuestions(prev => [
        ...prev,
        { left: leftQuestion, right: rightQuestion }
      ]);
      setSelectedQuestions({ left: null, right: null });
    }
    closeModalAddQuestionOpened(leftQuestion, rightQuestion);
  };


  if (loading) return (
    <div>Loading...</div>
  );

  return (
    <Container>
      <LeftPanel>
        <h2>Parent questionnaire</h2>
        <ScrollBox style={{ borderRight: '1px solid #ccc' }}>
          {sectionsParant.map(section => (
            <SectionBox key={section.id}>
              <SectionTitle>{section.section}</SectionTitle>
              <QuestionGroupBox>
                {section.questionGroups.map(questionGroup => (
                  <QuestionBox key={questionGroup.id}>
                    <QuestionGroupTitle>{questionGroup.title}</QuestionGroupTitle>
                    <AnswersBox>
                      {questionGroup.questions.map(question => (
                        <QuestionItem key={question.id}>
                          <QuestionText>{question.question}</QuestionText>
                          <CheckboxItem key={question.id}>
                            <Checkbox
                              checked={selectedQuestions.left === question.id}
                              onChange={(e) => onChange(e, question, 'left')}
                            >
                            </Checkbox>
                          </CheckboxItem>
                        </QuestionItem>
                      ))}
                    </AnswersBox>
                  </QuestionBox>
                ))}
              </QuestionGroupBox>
            </SectionBox>
          ))}
        </ScrollBox>
      </LeftPanel>
      <RightPanel>
        <h2>Daughter questionnaire</h2>
        <ScrollBox>
          {sections.map(section => (
            <SectionBox key={section.id}>
              <SectionTitle>{section.section}</SectionTitle>
              <QuestionGroupBox>
                {section.questionGroups.map(questionGroup => (
                  <QuestionBox key={questionGroup.id}>
                    <QuestionGroupTitle>{questionGroup.title}</QuestionGroupTitle>
                    <AnswersBox>
                      {questionGroup.questions.map(question => (
                        <QuestionItem key={question.id}>
                          <QuestionText>{question.question}</QuestionText>
                          <CheckboxItem key={question.id}>
                            <Checkbox
                              checked={selectedQuestions.right === question.id}
                              onChange={(e) => onChange(e, question, 'right')}
                            >
                            </Checkbox>
                          </CheckboxItem>
                        </QuestionItem>
                      ))}
                    </AnswersBox>
                  </QuestionBox>
                ))}
              </QuestionGroupBox>
            </SectionBox>
          ))}
        </ScrollBox>
      </RightPanel>

      <ButtonContainer>
        <Button
          onClick={onAdd}
          disabled={!selectedQuestions.left || !selectedQuestions.right}
          type="primary"
          style={{ width: '100%' }}
        >
          Add trigger
        </Button>
      </ButtonContainer>
    </Container>
  );
};


const Container = styled.div`
display: flex;
height: 100vh;
`

const LeftPanel = styled.div`
flex: 1; 
background-color: #f0f0f0; 
padding: 20px; 
`

const RightPanel = styled.div`
flex: 1;
background-color: #ffffff; 
padding: 20px; 
`

const QuestionBox = styled.div`
  margin-bottom: 30px;
`

const ScrollBox = styled.div`
  height: 800px;
  overflow-y: scroll;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 15px;
  background-color: #f9f9f9;
`
const SectionBox = styled.div`
  margin-bottom: 30px;
  padding: 10px;
  border-bottom: 1px solid #e0e0e0;
`
const SectionTitle = styled.h2`
  margin: 0;
  font-size: 1.5em;
`
const QuestionGroupBox = styled.div`
  margin-top: 10px;
`
const QuestionGroupTitle = styled.h3`
  margin: 0;
  font-size: 1.2em;
`
const AnswersBox = styled.div`
  margin-top: 10px;
`
const QuestionItem = styled.div`
  margin-bottom: 15px;
`
const QuestionText = styled.p`
  margin: 0;
  font-weight: bold;
`
const AvailableAnswersBox = styled.div`
  margin-top: 5px;
`
const CheckboxItem = styled.div`
  margin-bottom: 5px;
`
const ButtonContainer = styled.div`
  text-align: right;
`

