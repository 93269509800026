import React, { useState } from 'react';
import { Col, Row, Typography } from 'antd';
import { Box } from '../../../customAragon';
import { AntdTypographyWrapper } from '../../../../ui';
import { UserAddOutlined } from '@ant-design/icons';
import { COLORS } from '../../../../utils/static';
import styled from 'styled-components';
import { AddReferralModal, AddRecommenderModal, AddAgentModal } from '../../../investor/modals';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { AccountsList, AccountsTree } from '../../molecules';


export const ProfileReferralsView = ({ refferalsPyramid, recommeders, agents }) => {
  const { t } = useTranslation();
  const { investor } = useSelector((state) => state);
  const [addRefferalOpened, setAddRefferalOpened] = useState(false);
  const [addRecommenderOpened, setAddRecommenderOpened] = useState(false);
  const [addAgentOpened, setAddAgentOpened] = useState(false);


  return (
    <AntdTypographyWrapper>
      <Box>
        <Row wrap={false} gutter={24}>
          {investor.accountRole && <Col span={8}>
            <Typography.Title level={4}>
              {t('My referrals')} <AddButton onClick={() => setAddRefferalOpened(true)} />
            </Typography.Title>
            <AccountsTree
              accounts={refferalsPyramid}
              emptyTitle={t('No referrals yet')}
            />
          </Col>}

          <Col span={8}>
            <Typography.Title level={4}>
              {t('My assistants')} <AddButton onClick={() => setAddRecommenderOpened(true)} />
            </Typography.Title>
            <AccountsList
              accounts={recommeders}
              emptyTitle={t('No assistants yet')}
            />
          </Col>

          <Col span={8}>
            <Typography.Title level={4}>
              {t('My agent')} <AddButton onClick={() => setAddAgentOpened(true)} />
            </Typography.Title>
            <AccountsList
              accounts={agents}
              emptyTitle={t('No agent yet')}
            />
          </Col>
        </Row>
      </Box>


      {investor.accountRole && <AddReferralModal
        open={addRefferalOpened}
        onClose={() => setAddRefferalOpened(false)}
      />}
      <AddRecommenderModal
        open={addRecommenderOpened}
        onClose={() => setAddRecommenderOpened(false)}
        isFirst={recommeders && recommeders.length === 0}
      />
      <AddAgentModal
        open={addAgentOpened}
        onClose={() => setAddAgentOpened(false)}
        isFirst={agents && agents.length === 0}
      />

    </AntdTypographyWrapper>
  );
};

const AddButton = styled(UserAddOutlined)`
  width: 32px;
  height: 32px;
  color: ${COLORS.aragonBlue};
  cursor: pointer;
  margin-left: 8px;
  border-radius: 6px;
  transition: 0.2s;
  :hover {
    box-shadow: 0px 2px 10px 2px rgba(8, 190, 229, 0.15) inset;
  }
  :active {
    box-shadow: 0px 2px 10px 2px rgba(8, 190, 229, 0.32) inset;
  }

  > svg {
    width: 100%;
  }
`;
