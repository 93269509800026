import React, { useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { GU } from '@aragon/ui';
import { Button } from '../../../../ui';
import { Container, Row, Col } from '../../../../ui/layout';
import { Title4 } from '../../../../ui/typography';
import { QuestBox, SumSubBox } from '../../index';
import { history } from '../../../../routing';
import styled from 'styled-components';
import { COLORS } from '../../../../utils/static/colors';
import {
  ENABLED_QUESTS_TO_VIEW_STATUSES_ADMIN,
  ENABLED_STATUSES_INVESTOR,
  STATUS_DISPLAY,
  STATUS_NEW,
  STATUS_PASSED,
  STATUSES_IN_PROGRESS
} from '../../../../utils/static/kyc';
import { AddQuestionnaireModal } from '../../../../modules/Questionnaire/components/modals/AddQuestionnaire';
import { InitVerifSidePanel } from '../../../investor/organisms';
import { useTranslation } from 'react-i18next';

const isEnabled = (accountStatus, questStatus, adminView) => {
  if (accountStatus !== 'ACTIVE' && !adminView) return false;
  return adminView
    ? ENABLED_QUESTS_TO_VIEW_STATUSES_ADMIN[questStatus]
    : ENABLED_STATUSES_INVESTOR[questStatus];
};

export const ProfileStatusView = (
  {
    data,
    investor,
    adminView = false,
    rejectedQuestionnaires,
    sumSubStatusRes,
    refetchQuests
  }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const [addQuestVisible, setAddQuestVisible] = useState(false);

  const sumSubData = useMemo(() => {
    if (sumSubStatusRes && sumSubStatusRes.data) {
      const dataSumSub = sumSubStatusRes.data;

      return {
        ...dataSumSub?.investorVerificationQuestionSection,
        ...(location.state && location.state.verificationSubmitted && dataSumSub?.investorVerificationQuestionSection.status === 'New' && {status: 'In process'}),
        reviewResult: data?.reviewResult,
        comment: dataSumSub?.comment
      };
    }

    return null;
  }, [sumSubStatusRes, location]);

  const questionnairesSorted = useMemo(() => {
    if (data) {
      try {
        const questNew = [];
        const questInProgress = [];
        const questPassed = [];

        data.data.forEach((quest) => {
          const questStatus = quest.status;
          const error =
            questStatus === 'UNACCEPTED' && rejectedQuestionnaires
              ? rejectedQuestionnaires[quest.id]
              : null;
          const formattedQuest = {
            ...quest,
            error: error,
            enabled: isEnabled(investor.accountStatus, questStatus, adminView),
            onClick: (id) => {
              history.push(
                adminView ? `/investor/${investor.id}/questionnaire/${id}` : `/questionnaire/${id}`,
                { prevPath: location.pathname, nextState: { lastProfileTab: investor.lastProfileTab } }
              );
            },
            onClickEdit: (id) => {
              history.push(
                adminView ? `/investor/${investor.id}/questionnaire/${id}/edit` : `/questionnaire/${id}`,
                { prevPath: location.pathname, nextState: { lastProfileTab: investor.lastProfileTab } }
              );
            }
          };

          if (questStatus === STATUS_NEW) questNew.push(formattedQuest);
          else if (questStatus === STATUS_PASSED) questPassed.push(formattedQuest);
          else if (STATUSES_IN_PROGRESS[questStatus]) questInProgress.push(formattedQuest);
        });

        return {
          new: questNew,
          inProgress: questInProgress,
          passed: questPassed
        };
      } catch (e) {
      }
    }

    return null;
  }, [data, rejectedQuestionnaires, adminView, investor, location]);

  if (questionnairesSorted && investor) {
    return (
      <Container style={{ position: 'relative' }}>
        {questionnairesSorted.inProgress && questionnairesSorted.inProgress.length > 0 && (
          <QuestList title={t('Questionnaires in progress')} list={questionnairesSorted.inProgress} adminView = {adminView}/>
        )}
        {questionnairesSorted.new && questionnairesSorted.new.length > 0 && (
          <QuestList title={t('Questionnaires new')} list={questionnairesSorted.new} adminView = {adminView}/>
        )}
        {questionnairesSorted.passed && questionnairesSorted.passed.length > 0 && (
          <QuestList title={t('Questionnaires passed')} list={questionnairesSorted.passed} adminView = {adminView}/>
        )}
        <AddQuestContainer>
          <Button
            label={'+'}
            onClick={() => setAddQuestVisible(true)}
            paddingX={'20'}
            size={'small'}
            mode={'strong'}
            disabled={investor.accountStatus !== 'ACTIVE'}
          />
          <AddQuestionnaireModal
            visible={addQuestVisible}
            onClose={() => setAddQuestVisible(false)}
            user={adminView ? 'admin' : 'investor'}
            onSuccess={refetchQuests}
          />
        </AddQuestContainer>

        {sumSubStatusRes && (
          <SumsubContainer>
            <Title4 marginBottom={12} color={COLORS.default}>{t('Verification')}</Title4>

            <Row cols={3} margin={-8} colsMarginBottom={2 * GU}>
              <Col>
                <SumSubBox
                  data={sumSubData}
                  investor={investor}
                  adminView={adminView}
                />
              </Col>
            </Row>
          </SumsubContainer>
        )}
      </Container>
    );
  }

  return null;
};

const QuestList = ({ title = '', list, adminView }) => {
  if (list) {
    return (
      <SumsubContainer>
        <Title4 marginBottom={12} color={COLORS.default}>{title}</Title4>

        <Row cols={3} margin={-8} colsMarginBottom={2 * GU}>
          {list.map((item, index) => (
            <Col key={index}>
              <QuestBox
                data={item}
                // status={STATUS_DISPLAY[item.status]}
                onClick={() => item.onClick(item.id)}
                onClickEdit={() => item.onClickEdit(item.id)}
                adminView={adminView}
              />
            </Col>
          ))}
        </Row>
      </SumsubContainer>
    );
  }

  return null;
};

const AddQuestContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;
const SumsubContainer = styled.div`
  position: relative;
  margin-top: 50px;
`;
