import React, {useEffect} from 'react';

import { useParams } from 'react-router-dom';
import { Product } from '../../../../modules';

export const EditProductPage = () => {

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [])

  const { id, productId } = useParams();

  return (
      <Product adminView adminEditView investorId={id} productId={productId}/>
  );
};
