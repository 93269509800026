import React from 'react';
import { QuestionGroup } from '../QuestionGroup';
import { Container } from '../../../../ui/layout';
import { sortWithNulls } from '../../../../utils/helpers';

export const Section = ({ section, ...props }) => {
  return (
    <Container>
      {section.questionGroups &&
        section.questionGroups.sort((a, b) => sortWithNulls(Number(a.sortOrder), Number(b.sortOrder))).map((questionGroup, index) => (
          <QuestionGroup questionGroup={questionGroup} key={index} {...props} />
        ))}
    </Container>
  );
};
