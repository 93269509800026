import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { GU } from '@aragon/ui';
import {
  Info,
  DateRangePicker,
  RadioGroup,
  DocumentLine,
  FileUploadButton,
  InnerBox
} from '../../../../../../components';
import { Input, Col, Container, Row, Body2Light, Label, Label2 } from '../../../../../../ui';
import styled from 'styled-components';

export const IssueParametersForm = ({
  issueParams,
  changeIssueParams,
  issueDocuments,
  addDocuments,
  deleteDocument
}) => {
  return (
    <div>
      <InnerBox padding={3 * GU}>
        <Row cols={4} margin={-GU}>
          <Input
            label={'issue price'}
            value={issueParams.issuePrice}
            onChange={(e) => changeIssueParams('issuePrice', e.target.value)}
            adornmentOptions={{ title: 'EUR' }}
          />

          <Input
            label={'Number of securities'}
            value={issueParams.numberOfSecurities}
            onChange={(e) => changeIssueParams('numberOfSecurities', e.target.value)}
          />

          <Input
            label={'total nominal VALUE'}
            value={issueParams.totalNominalValue}
            onChange={(e) => changeIssueParams('totalNominalValue', e.target.value)}
            adornmentOptions={{ title: 'EUR' }}
          />
        </Row>
      </InnerBox>

      <InnerBox padding={3 * GU}>
        <Row cols={2} margin={-GU}>
          <Input
            label={'Vote ratio'}
            value={issueParams.voteRatio}
            onChange={(e) => changeIssueParams('voteRatio', e.target.value)}
          />

          <Input
            label={'Min - Max quantity PER ORDER '}
            value={issueParams.minQuantityPerOrder}
            onChange={(e) => changeIssueParams('minQuantityPerOrder', e.target.value)}
            adornmentOptions={{ title: 'units', padding: 16, width: 86 }}
          />
        </Row>
      </InnerBox>

      <InnerBox padding={3 * GU}>
        <Row cols={4} margin={-GU}>
          <Col width={'50%'}>
            <DatePickerWrapper>
              <DatePicker
                selected={issueParams.issueDate}
                onChange={(date) => changeIssueParams('issueDate', date)}
                dateFormat='dd.MM.yyyy'
                customInput={<Input label={'issue date'} wide />}
              />
            </DatePickerWrapper>
          </Col>

          <Input
            label={'Instrument number'}
            value={issueParams.instrumentNumber}
            onChange={(e) => changeIssueParams('instrumentNumber', e.target.value)}
          />

          <Input
            label={'Issue application number'}
            value={issueParams.issueApplicationNumber}
            onChange={(e) => changeIssueParams('issueApplicationNumber', e.target.value)}
          />
        </Row>

        <Row cols={4} margin={-GU} marginTop={2 * GU}>
          <Input
            label={'[Номер выпуска]'}
            value={issueParams.issueNumber}
            onChange={(e) => changeIssueParams('issueNumber', e.target.value)}
          />

          <Input
            label={'Issuer agent'}
            value={issueParams.issuerAgent}
            onChange={(e) => changeIssueParams('issuerAgent', e.target.value)}
          />

          <Col width={'50%'}>
            <Input
              label={'Issue registration country'}
              value={issueParams.issueRegistrationCountry}
              onChange={(e) => changeIssueParams('issueRegistrationCountry', e.target.value)}
            />
          </Col>
        </Row>

        <Row cols={4} margin={-GU} marginTop={2 * GU}>
          <Input
            label={'Income type'}
            value={issueParams.incomeType}
            onChange={(e) => changeIssueParams('incomeType', e.target.value)}
          />

          <Col width={'50%'}>
            <DateRangePicker
              label={'Road Show Period'}
              startDate={issueParams.roadShowPeriod.start}
              endDate={issueParams.roadShowPeriod.end}
              onChange={(value) => changeIssueParams('roadShowPeriod', value)}
            />
          </Col>
        </Row>
      </InnerBox>

      <InnerBox paddingY={'0'}>
        <InnerBox paddingCss={`${2 * GU}px 0 ${3 * GU}px 0`}>
          <Body2Light marginBottom={2 * GU}>Freely transferrable</Body2Light>

          <RadioGroup
            selected={issueParams.freelyTransferrableKey}
            onChange={(value) => changeIssueParams('freelyTransferrableKey', value)}
            items={['Yes', 'No']}
          />
        </InnerBox>

        <InnerBox paddingCss={`${2 * GU}px 0 ${3 * GU}px 0`}>
          <Body2Light marginBottom={2 * GU}>Encumbered</Body2Light>

          <RadioGroup
            selected={issueParams.encumberedKey}
            onChange={(value) => changeIssueParams('encumberedKey', value)}
            items={['Yes', 'No']}
          />
        </InnerBox>
      </InnerBox>

      <InnerBox>
        <Label>issue documents</Label>

        <Row cols={2} margin={-GU} marginTop={1.5 * GU}>
          <Col>
            {issueDocuments.length > 0 && (
              <Container marginBottom={2 * GU}>
                {issueDocuments.map((doc, key) => (
                  <DocumentLine
                    doc={{ name: doc.name, id: key }}
                    onDelete={(id) => deleteDocument(id)}
                    index={key + 1}
                    key={key}
                  />
                ))}
              </Container>
            )}

            <FileUploadButton onChange={(value) => addDocuments(value)} multiple />
          </Col>

          <Col>
            <Info>
              Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit
              officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud
              amet.
            </Info>
          </Col>
        </Row>
      </InnerBox>
    </div>
  );
};

const DatePickerWrapper = styled.div`
  > div {
    width: 100%;
  }
`;
