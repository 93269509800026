import React from 'react';
import { Box, IconAdd, Text } from '@aragon/ui';
import styles from '../style.module.css';

const TabsOwners = ({ array, selected }) => {
  return (
    <div>
      <Box className={styles['tabsContainer']}>
        {array.map((item, key) => (
          <div
            className={selected === key ? `${styles['tab']} ${styles['active']}` : styles['tab']}
          >
            {key + 1}. {item}
          </div>
        ))}
      </Box>
      <div className={styles['tabAddMore']}>
        <IconAdd color='#8FA4B5' size='medium' />
        <Text style={{ marginLeft: '9px', marginTop: '2px' }}>Add more</Text>
      </div>
    </div>
  );
};

export default TabsOwners;
