import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Button, Checkbox, Input, Modal, Popconfirm, Radio } from 'antd';

import { useDocumentType, useQuestionnaires, useQuestionnaire, useRelatedQuestionnaire } from '../hooks';
import { Label, Space } from '../../constructorCommon/styled';
import { Triggers } from './Triggers';
import { RelatedQuestionnaire } from './RelatedQuestionnaire';


export const CheckTriggers = ({questionnaire}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [isModalRelatedQuestionnaireOpened, setIsModalRelatedQuestionnaireOpened] = useState(false);
  const [triggers, setTriggers] = useState([]);
  const { handleDelete, handleGet } = useRelatedQuestionnaire(questionnaire.id);

  const fetchData = async () => {
    setLoading(true);
    const res = await handleGet(questionnaire.id);
    if (res?.data) {
      console.log('res', res?.data)
      setTriggers(res.data);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);


  const onAdd = () => {
    setIsModalRelatedQuestionnaireOpened(true);
    fetchData();
  }
  const onDelete = async (relatedQuestionnaireId) => {
    await handleDelete(relatedQuestionnaireId);
    await fetchData();
  }

  const closeModalCheckTrigger = () => {
    fetchData();
    setIsModalRelatedQuestionnaireOpened(false);
  }

  if (loading) return (
    <div>Loading...</div>
  )


  return (
    <Container>
      <ScrollBox>
        <div>
          {triggers.map((trigger, index) => (
            <QuestionBox key={trigger.id}>
              <div key={index}>
                <Label>trigger {index + 1}</Label>

                <Input name={`relatedQuestionnaireType-${index}`}
                       value={trigger.questionSectionType.type} />
                <Input name={`relatedQuestionnaireName-${index}`}
                       value={trigger.questionSectionType.name} />
                <Input name={`relatedQuestionSection-${index}`} value={trigger.questionSection.section} />
                <Input name={`relatedQuestionnaireGroup-${index}`} value={trigger.group.title} />
                <Space />

                {trigger.conditions.map((condition, conditionIndex) => (
                  <div key={conditionIndex}>
                    <Label>condition {conditionIndex + 1}</Label>
                    <Input name={`condition-${conditionIndex}`} value={condition.question?.question} />
                    <Input name={`answer-${conditionIndex}`} value={condition.answer} />
                  </div>
                ))}
                <Space />

                {trigger.questions.map((question, questionIndex) => (
                  <QuestionPair key={questionIndex}>
                    <div>
                      <Label>questionSource {questionIndex + 1}</Label>
                      <Input name={`questionSource-${questionIndex}`} value={question.questionSource?.question} />
                    </div>
                    <div>
                      <Label>questionTarget {questionIndex + 1}</Label>
                      <Input name={`questionTarget-${questionIndex}`} value={question.questionTarget?.question} />
                    </div>
                  </QuestionPair>
                ))}

                <ButtonContainer>
                  <Popconfirm placement="topRight"
                              title={t('Are you sure to delete trigger?')}
                              onConfirm={() => onDelete(trigger.id)}
                              okText={t('Yes')}
                              cancelText={t('No')}>
                    <Button danger>{t('Delete Trigger')}</Button>
                  </Popconfirm>
                </ButtonContainer>

              </div>
            </QuestionBox>
          ))}
        </div>

        <Modal
          title={'Select Parent Questionnaire'}
          width={1400}
          style={{ top: 20 }}
          okText={'Add'}
          footer={null}
          open={isModalRelatedQuestionnaireOpened}
          onCancel={() => setIsModalRelatedQuestionnaireOpened(false)}>
          <RelatedQuestionnaire questionSectionTypeId={questionnaire.id}
                                closeModalCheckTrigger={closeModalCheckTrigger} />
        </Modal>
      </ScrollBox>

      <ButtonContainer>
        <Button onClick={onAdd} type="primary" style={{ marginTop: '30px' }}>
          {t('Add Trigger')}
        </Button>
      </ButtonContainer>
    </Container>
  );

};

const Container = styled.div`
padding: 20px;
`

const ScrollBox = styled.div`
height: 800px;
overflow-y: auto;
border: 1px solid #e0e0e0;
border-radius: 8px;
background-color: #f9f9f9;
padding: 15px;
`

const QuestionBox = styled.div`
margin-bottom: 15px;
padding: 10px;
border: 1px solid #e0e0e0;
border-radius: 5px;
background-color: #ffffff;
`

const QuestionPair = styled.div`
display: flex;
justify-content: space-between;
margin-bottom: 15px;
`

const ButtonContainer = styled.div`
text-align: right;
`
