import React, { useMemo } from 'react';
import { useController } from 'react-hook-form';
import { DatePicker } from '../../../../ui';

export const FormDatePicker = ({ form, ...props }) => {
  const {
    field,
    fieldState: { error }
  } = useController({
    ...form,
    defaultValue: ''
  });

  const { value, ...otherField } = field;

  const formatValue = useMemo(() => {
    try {
      return value && typeof value === 'string' ? new Date(value) : value;
    } catch (e) {
      return null;
    }
  }, [value]);

  const today = new Date();
  let minDate = null, maxDate = null;
  if (props.question.dateForSelect) {
    if (props.question.dateForSelect === 'OLD') maxDate = today;
    if (props.question.dateForSelect === 'NEW') minDate = today;
  }

  return (
    <DatePicker
      selected={formatValue}
      error={error}
      showYearDropdown
      minDate={minDate}
      maxDate={maxDate}
      {...otherField}
      {...props}
    />
  );
};
