import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
  html, body {
      overflow: unset !important;
  }
    
  body #root {
    font-family: 'Overpass', sans-serif;
    font-weight: 300;
  }

  .react-datepicker-popper {
    z-index: 15;
  }

  ul[role="listbox"] {
    z-index: 15;
  }
  
  span.ant-tree-title {
      font-size: 16px;
  }
  div.ant-tree-treenode.ant-tree-treenode-switcher-open {
      padding: 0 0 6px 0;
  }
  div.ant-tree-treenode.ant-tree-treenode-switcher-close {
      padding: 0 0 6px 0;
  }
`;
