import React, {useEffect} from 'react';

import { PageContainer } from '../../../ui';
import { DocumentSection } from '../../../features/constructorDocuments';

export const DocumentPage = () => {

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [])

  return (
    <PageContainer>
      <DocumentSection/>
    </PageContainer>
  );
};
