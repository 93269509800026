import React, { useEffect, useMemo, useState } from 'react';
import { SidePanel, GU, Text } from '@aragon/ui';
import { InnerBox, BoxLine } from '../../../../components';
import {
  DatePicker,
  DropDown,
  Input,
  Row,
  Body1Light,
  Body2Light,
  Label2,
  Button
} from '../../../../ui';
import { newObject } from '../../../../utils/helpers';
import {
  COLORS,
  VALID_TILL_DATE_OPTION,
  VALID_TILL_OPTIONS,
  SIDE_CONFIG,
  BUY_ASSET_FEE_PERCENT
} from '../../../../utils/static';

export const BuySellAssetView = ({
  opened,
  onClose,
  asset = {},
  personalAccounts,
  side,
  onMakeOrder
}) => {
  const [data, setData] = useState({
    orderValidTillOption: 0,
    orderValidTill: null,
    quantity: '',
    pricePerCapita: '',
    totalPrice: '',
    paymentAccount: 0
  });

  useEffect(() => {
    if (side === 'BUY' && asset.pricePerCapita !== data.pricePerCapita) {
      changeData('pricePerCapita', asset.pricePerCapita);
    }
  }, [asset]);

  useEffect(() => {
    changeData('pricePerCapita', side === 'BUY' ? asset.pricePerCapita : '');
  }, [side]);

  const changeData = (key, value) => {
    if (key === 'quantity' || key === 'pricePerCapita') {
      if (value < 0) value = 1;
      const newData = newObject(data, key, value);
      const amount = newData.pricePerCapita * newData.quantity;
      const Fee = (amount * BUY_ASSET_FEE_PERCENT) / 100;
      const totalPrice = side === 'BUY' ? amount + Fee : amount - Fee;

      setData(newObject(newData, 'totalPrice', totalPrice));
    } else {
      setData(newObject(data, key, value));
    }
  };

  const makeOrder = () => {
    const { paymentAccount, orderValidTillOption, totalPrice, ...props } = data;

    const price = props.pricePerCapita * props.quantity;

    onMakeOrder({
      ...props,
      totalPrice: price,
      fee: (price * BUY_ASSET_FEE_PERCENT) / 100,
      orderValidTillOption: VALID_TILL_OPTIONS[orderValidTillOption],
      personalAccountId: personalAccounts[paymentAccount].id
    });
  };

  //render props
  const datePickerActive = useMemo(
    () => VALID_TILL_OPTIONS[data.orderValidTillOption] === VALID_TILL_DATE_OPTION,
    [data]
  );

  const buttonActive = useMemo(() => {
    const { orderValidTill, quantity, pricePerCapita, paymentAccount, totalPrice } = data;
    const validTill = !datePickerActive || (datePickerActive && orderValidTill);

    return (
      validTill &&
      quantity &&
      pricePerCapita &&
      paymentAccount.toString() &&
      totalPrice &&
      (side === 'BUY' || quantity <= asset.myQuantity)
    );
  }, [data, datePickerActive]);

  const { assetId, title } = asset;

  return (
    <SidePanel
      title={<Body1Light>{SIDE_CONFIG[side].title}</Body1Light>}
      opened={opened}
      onClose={onClose}
    >
      <InnerBox>
        <Body1Light color={'#000000'}>{title}</Body1Light>
        <Body2Light color={COLORS.greyMedium} marginTop={0.5 * GU}>
          {assetId}
        </Body2Light>
      </InnerBox>

      <InnerBox>
        <Label2>Order valid till</Label2>
        <Row marginBottom={3 * GU} margin={-GU} cols={2}>
          <DropDown
            items={VALID_TILL_OPTIONS}
            selected={data.orderValidTillOption}
            onChange={(val) => changeData('orderValidTillOption', val)}
          />

          <DatePicker
            selected={data.orderValidTill}
            onChange={(date) => changeData('orderValidTill', date)}
            disabled={!datePickerActive}
          />
        </Row>

        <Input
          value={data.quantity}
          onChange={(e) => changeData('quantity', e.target.value)}
          label={'Select Quantity'}
          type={'number'}
          min='1'
          step={'1'}
          marginBottom={2 * GU}
          adornment={
            side === 'SELL' && (
              <Body2Light color={COLORS.iconColor}>of {asset.myQuantity}</Body2Light>
            )
          }
          adornmentPosition={'end'}
          adornmentSettings={{ width: '90', padding: '12' }}
          wide
        />

        {side === 'BUY' && (
          <BoxLine title='Price per capita' data={`${data.pricePerCapita} ${asset.currency}`} />
        )}

        {side === 'SELL' && (
          <Input
            value={data.pricePerCapita}
            onChange={(e) => changeData('pricePerCapita', e.target.value)}
            type={'number'}
            min='0.01'
            label={'Price per capita'}
            marginBottom={2 * GU}
          />
        )}

        {side === 'SELL' && (
          <BoxLine title='Total assets price'>{data.pricePerCapita * data.quantity || '0'}</BoxLine>
        )}
        <BoxLine title='Fee'>
          <Text>
            {BUY_ASSET_FEE_PERCENT}% &thinsp;
            <Text color={COLORS.greyMedium}>
              {(data.pricePerCapita * data.quantity * BUY_ASSET_FEE_PERCENT) / 100} {asset.currency}
            </Text>
          </Text>
        </BoxLine>
      </InnerBox>

      <InnerBox paddingTop={2 * GU}>
        <BoxLine
          title='Total Price'
          data={`${data.totalPrice || '0'} ${asset.currency}`}
          TextStyle={Body1Light}
        />

        <DropDown
          label={SIDE_CONFIG[side].method}
          items={personalAccounts.map((item) =>
            item.accountName ? item.accountName : 'Personal account'
          )}
          selected={data.paymentAccount}
          onChange={(val) => changeData('paymentAccount', val)}
          wide
          marginBottom={2 * GU}
          marginTop={3 * GU}
        />

        <Button
          onClick={makeOrder}
          disabled={!buttonActive}
          label={SIDE_CONFIG[side].title}
          mode='strong'
          wide
        />
      </InnerBox>
    </SidePanel>
  );
};
