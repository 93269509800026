import React, { useEffect, useState } from 'react';
import { GU } from '@aragon/ui';
import { Box, TransactionTable, SearchInput, Pagination } from '../../../../components';
import { Row, Col, Body2Light, DropDown } from '../../../../ui';
import { newObject } from '../../../../utils/helpers';
import {
  TRANSFER_STATUSES_LIST,
  TRANSFER_STATUSES,
  TRANSACTION_TYPES,
  DISPLAY_TRANSACTION_TYPE
} from '../../../../utils/static';

const formatFilters = (filters) => ({
  type: TRANSACTION_TYPES[filters.typeIndex - 1],
  status: TRANSFER_STATUSES_LIST[filters.statusIndex - 1],
  search: filters.search && filters.search
});

export const TransactionHistoryBox = ({
  transactions,
  transactionsInTable,
  updateFilters,
  savedFilters,
  saveFilters,
  ...props
}) => {
  const [filters, setFilters] = useState({
    typeIndex: savedFilters?.typeIndex || -1,
    statusIndex: savedFilters?.statusIndex || -1,
    search: savedFilters?.search || ''
  });
  const [page, setPage] = useState(savedFilters?.page || 0);

  useEffect(() => {
    updateFilters({
      skip: page * transactionsInTable,
      ...formatFilters(filters)
    });
  }, []);

  const changeFilters = (key, value) => {
    const filter = newObject(filters, key, value);
    updateFilters(formatFilters(filter));

    setFilters(filter);
    setPage(0);
  };

  const updatePage = (value) => {
    updateFilters({ skip: value * transactionsInTable, ...formatFilters(filters) });

    setPage(value);
  };

  return (
    <Box padding={0} {...props}>
      <Row
        justifyContent={'space-between'}
        alignItems={'center'}
        paddingX={3 * GU}
        paddingY={2 * GU}
      >
        <Body2Light>Transaction History</Body2Light>

        <Row margin={-GU}>
          <DropDown
            selected={filters.typeIndex}
            onChange={(val) => changeFilters('typeIndex', val)}
            items={[
              'All types',
              ...TRANSACTION_TYPES.map((item) => DISPLAY_TRANSACTION_TYPE[item])
            ]}
            minWidth={'104px'}
          />
          <DropDown
            selected={filters.statusIndex}
            onChange={(val) => changeFilters('statusIndex', val)}
            items={[
              'All statuses',
              ...TRANSFER_STATUSES_LIST.map((item) => TRANSFER_STATUSES[item].title)
            ]}
            minWidth={'104px'}
          />

          <Col>
            <SearchInput
              value={filters.search}
              onChange={(val) => changeFilters('search', val)}
              placeholder='Search'
              style={{ minWidth: '300px' }}
            />
          </Col>
        </Row>
      </Row>

      <TransactionTable
        data={transactions?.transactions}
        onDetailsSideEffect={() => saveFilters({ page: page, ...filters })}
      />

      {transactions && transactions.count > transactionsInTable && (
        <Pagination
          selected={page}
          onChange={updatePage}
          pages={Math.ceil(transactions.count / transactionsInTable)}
        />
      )}
    </Box>
  );
};
