import React, {useState, useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Checkbox, Input, Popconfirm, Spin } from 'antd';
import { useParams } from 'react-router-dom';


import {useDocumentType} from '../hooks';
import { Divider, NestedBox, ActionBox, Label, Space, Box } from '../styled';
import { TextArea } from '../../../ui';
import { LoadingOutlined } from '@ant-design/icons';

export const DocumentType = ({sectionProp, onCreated}) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [section, setSection] = useState(sectionProp || {});
  const {
    handleCreate,
    handleUpdate,
    handleDelete,
    handleChangeRole,
    handleChangeIsRequired,
    isCreating,
    isUpdating,
    isDeleting,
    isChangingRole,
    isChangingIsRequired,
  } = useDocumentType({kycDocumentSectionId: id});
  const [showCreateNew, setShowCreateNew] = useState(false);

  useEffect(() => {
    if (!sectionProp?.id) return;

    setSection(sectionProp);
  }, [sectionProp])

  const onChange = (e) => {
    const {name, value} = e.target;
    setSection({...section, [name]: value})
  }
  const onChangeRole = (e) => {
    const {name, checked} = e.target;

    handleChangeRole(name, checked, section);
  }
  const onChangeIsRequired = (e) => {
    const {name, checked} = e.target;

    handleChangeIsRequired(name, checked, section);
  }


  const handleAction = () => {
    if (section.id) {
      handleUpdate(section);
    } else {
      handleCreate(section).then(res => {
        if (res?.data?.id) {
          setSection({});
          onCreated && onCreated();
        }
      });
    }
  }

  const onDelete = () => {
    handleDelete(section);
  }

  // const isDisabled = !section.id || (
  //   section.name === sectionProp?.name
  // );
  const isDisabled = !section.name;

  return (
    <Box isNew={!section.id}>
      <div>
        <Label>{t('Name')}</Label>
        <Input name="name" value={section.name} onChange={onChange}/>
        <Space/>
        <Label>{t('Name_RU')}</Label>
        <Input name="name_RU" value={section.name_RU} onChange={onChange}/>
        <Space/>
        <Label>{t('Name_LV')}</Label>
        <Input name="name_LV" value={section.name_LV} onChange={onChange}/>
        <Space/>
        <Label>{t('Title')}</Label>
        <Input name="title" value={section.title} onChange={onChange}/>
        <Space/>
        <Label>{t('Title_RU')}</Label>
        <Input name="title_RU" value={section.title_RU} onChange={onChange}/>
        <Space/>
        <Label>{t('Title_LV')}</Label>
        <Input name="title_LV" value={section.title_LV} onChange={onChange}/>
        <Space/>
        <Label>{t('ExpirationText')}</Label>
        <Input name="expirationText" value={section.expirationText} onChange={onChange}/>
        <Space/>
        <Label>{t('ExpirationText_RU')}</Label>
        <Input name="expirationText_RU" value={section.expirationText_RU} onChange={onChange}/>
        <Space/>
        <Label>{t('ExpirationText_LV')}</Label>
        <Input name="expirationText_LV" value={section.expirationText_LV} onChange={onChange}/>
        <Space/>
        <Label>{t('ExpirationMonths')}</Label>
        <Input name="expirationMonths" type="number" value={section.expirationMonths} onChange={onChange}/>
        <Space/>
        <Label>{t('Descriptions')}</Label>
        <TextArea name="descriptions" value={section.descriptions} onChange={onChange} rows={4}/>
        <Space/>
        <Label>{t('Descriptions_RU')}</Label>
        <TextArea name="descriptions_RU" value={section.descriptions_RU} onChange={onChange} rows={4}/>
        <Space/>
        <Label>{t('Descriptions_LV')}</Label>
        <TextArea name="descriptions_LV" value={section.descriptions_LV} onChange={onChange} rows={4}/>
        <Space/>


        <div style={{ display: 'inline-block' }}>
          <Space/>
          <Label>{t('Roles')}</Label>
          <Spin indicator={<LoadingOutlined spin/>}
                spinning={isChangingRole}
          >
            <Checkbox name="PERSONAL"
                      checked={section?.roles?.some(role => role.role === 'PERSONAL')}
                      onChange={onChangeRole}
                      style={{ marginRight: '35px' }}
            >
              {t('PERSONAL')}
            </Checkbox>
            <Checkbox name="COMPANY"
                      checked={section?.roles?.some(role => role.role === 'COMPANY')}
                      onChange={onChangeRole}
                      style={{ marginRight: '35px' }}
            >
              {t('COMPANY')}
            </Checkbox>
            <Checkbox name="AGENT"
                      checked={section?.roles?.some(role => role.role === 'AGENT')}
                      onChange={onChangeRole}
                      style={{ marginRight: '35px' }}
            >
              {t('AGENT')}
            </Checkbox>
            <Checkbox name="RECOMMENDER"
                      checked={section?.roles?.some(role => role.role === 'RECOMMENDER')}
                      onChange={onChangeRole}
                      style={{ marginRight: '35px' }}
            >
              {t('ASSISTANT')}
            </Checkbox>
          </Spin>
          <Space/>
          <Label>{t('Required')}</Label>
          <Spin indicator={<LoadingOutlined spin/>}
                spinning={isChangingIsRequired}
          >
            <Checkbox name="PERSONAL"
                      checked={section?.roles?.some(role => role.role === 'PERSONAL' && role.isRequired === true)}
                      onChange={onChangeIsRequired}
                      style={{ marginRight: '35px' }}
            >
              {t('PERSONAL')}
            </Checkbox>
            <Checkbox name="COMPANY"
                      checked={section?.roles?.some(role => role.role === 'COMPANY' && role.isRequired === true)}
                      onChange={onChangeIsRequired}
                      style={{ marginRight: '35px' }}
            >
              {t('COMPANY')}
            </Checkbox>
            <Checkbox name="AGENT"
                      checked={section?.roles?.some(role => role.role === 'AGENT' && role.isRequired === true)}
                      onChange={onChangeIsRequired}
                      style={{ marginRight: '35px' }}
            >
              {t('AGENT')}
            </Checkbox>
            <Checkbox name="RECOMMENDER"
                      checked={section?.roles?.some(role => role.role === 'RECOMMENDER' && role.isRequired === true)}
                      onChange={onChangeIsRequired}
                      style={{ marginRight: '35px' }}
            >
              {t('ASSISTANT')}
            </Checkbox>
          </Spin>
        </div>

        <ActionBox>
          <Button onClick={handleAction}
                  disabled={isDisabled}
                  type="primary"
                  loading={isCreating || isUpdating}
          >
            {section.id ? t('Update Document Type') : t('Create Document Type')}
          </Button>

          {section.id && (
            <Popconfirm placement="topRight"
                        title={t('Are you sure to delete this document type?')}
                        onConfirm={onDelete}
                        okText={t('Yes')}
                        cancelText={t('No')}
            >
              <Button danger loading={isDeleting}>{t('Delete Document Type')}</Button>
            </Popconfirm>
          )}

        </ActionBox>
      </div>

      <NestedBox>
        {section.id && <Divider/>}


      </NestedBox>
    </Box>
  );
};
