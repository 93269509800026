import React, { useMemo } from 'react';
import { Header, GU, Text } from '@aragon/ui';
import {
  BoxLine,
  BackBar,
  Box,
  InnerBox,
  StatusLine,
  Badge,
  OrderDetailsBox
} from '../../../components';
import { Button, Title5, PageContainer, Link, Title2 } from '../../../ui';
import {
  COLORS,
  ORDER_STATUSES,
  SIDE_CONFIG,
  VALID_TILL_DATE,
  RESPONSIBLE_BROKER,
  EXECUTION_PLACE,
  ORDER_TYPES,
  DISPLAY_MIFID_STATUS,
  CANCELED_ORDER_STATUSES
} from '../../../utils/static';
import { dateString, displayFullDate, orderHasDealStatus } from '../../../utils/helpers';

export const OrderDetails = ({ order, cancelOrder }) => {
  const canCancelOrder = useMemo(() => {
    return order ? CANCELED_ORDER_STATUSES.includes(order.status) : false;
  }, [order]);

  if (order) {
    return (
      <PageContainer>
        <Header
          primary={<Title2>Order details</Title2>}
          secondary={
            <div>
              {canCancelOrder && (
                <Button onClick={cancelOrder} label='Cancel' style={{ color: COLORS.red }} />
              )}
            </div>
          }
        />
        <BackBar />

        <OrderDetailsBox order={order} />
      </PageContainer>
    );
  }

  return null;
};
