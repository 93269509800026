import React from 'react';
import { history } from '../../../routing';
import { Header, GU } from '@aragon/ui';
import {
  SummaryTable,
  DealsTable,
  CashTransactionsTable,
  MyAssetsTable,
  AvailableAssetsTable,
  MoreBtn,
  Box
} from '../../../components';
import { Body2Light, PageContainer, Title2 } from '../../../ui';

export const Dashboard = ({ cashSummary, assets, myAssets, deals, transactions }) => {
  return (
    <PageContainer>
      <Header primary={<Title2>Dashboard</Title2>} />

      {/*Summary*/}
      {cashSummary && (
        <Box padding={0} marginBottom={'55'}>
          <SummaryTable data={cashSummary} />
        </Box>
      )}

      {/*Assets*/}
      <Box padding={0}>
        <Body2Light padding={3 * GU}>Available Assets</Body2Light>

        <AvailableAssetsTable data={assets?.assets} />

        <MoreBtn link={'/assets/available'} />
      </Box>

      {/*My Assets*/}
      <Box padding={0}>
        <Body2Light padding={3 * GU}>Your Assets</Body2Light>

        <MyAssetsTable data={myAssets?.assets} />

        <MoreBtn link={'/assets/self'} />
      </Box>

      {/*Deals*/}
      <Box padding={0} marginTop={2 * GU}>
        <Body2Light padding={3 * GU}>Your Deals</Body2Light>

        <DealsTable data={deals?.deals} />

        <MoreBtn link={'/deals'} />
      </Box>

      {/*Cash transactions*/}
      <Box padding={0} marginTop={2 * GU}>
        <Body2Light padding={3 * GU}>Cash transactions</Body2Light>

        <CashTransactionsTable data={transactions} />

        <MoreBtn link={'/wallet'} />
      </Box>
    </PageContainer>
  );
};

const mockAssets = [
  {
    title: 'Asset 1',
    id: 'NT001',
    type: 'market',
    price: '27,96 EUR',
    quantity: '773',
    issuer: 'Issuer 1',
    date: '20/01/2011'
  },
  {
    title: 'Asset 2',
    id: 'NT002',
    type: 'offering',
    price: '27,96 EUR',
    quantity: '773',
    issuer: 'Issuer 1',
    date: '20/01/2011'
  },
  {
    title: 'Asset 3',
    id: 'NT003',
    type: 'sale',
    price: '27,96 EUR',
    quantity: '773',
    issuer: 'Issuer 1',
    date: '20/01/2011'
  }
];
const mockMyAssets = [
  {
    id: '1',
    ticker: 'Lorem',
    type: 'Share',
    issuer: 'Lorem Ipsum',
    issuedate: '9 Nov 2020',
    quantity: '1000',
    issuance: '$80,000',
    lastpurchase: '$80,000',
    profitloss: '$XXXXX',
    percent: 'XX%'
  },
  {
    id: '2',
    ticker: 'Lorem',
    type: 'Bond',
    issuer: 'Lorem Ipsum',
    issuedate: '9 Nov 2020',
    quantity: '500 c',
    issuance: '$80,000',
    lastpurchase: '$80,000',
    profitloss: '$XXXXX',
    percent: 'XX%'
  },
  {
    id: '3',
    ticker: 'Lorem',
    type: 'Bond',
    issuer: 'Lorem Ipsum',
    issuedate: '9 Nov 2020',
    quantity: '1000 ANT',
    issuance: '$80,000',
    lastpurchase: '$80,000',
    profitloss: '$XXXXX',
    percent: 'XX%'
  }
];
const mockDeals = [
  {
    id: 'DL3',
    date: '9 Nov 2020 XX:XX',
    assetId: 'PRSH001',
    assetType: 'Share',
    quantity: '1000 ANT',
    status: 'Executed',
    price: '800 EUR',
    fee: '3 EUR',
    feePercent: '1',
    direction: 'Buy',
    profit: 300,
    profitPercent: 5
  },
  {
    id: 'DL2',
    date: '9 Nov 2020 XX:XX',
    assetId: 'SHR001',
    assetType: 'Note',
    quantity: '500 ANT',
    status: 'Executed',
    price: '300 EUR',
    fee: '3 EUR',
    feePercent: '1',
    direction: 'Sell',
    profit: 300,
    profitPercent: 5
  },
  {
    id: 'DL2',
    date: '9 Nov 2020 XX:XX',
    assetId: 'NT001',
    assetType: 'Note',
    quantity: '300 ANT',
    status: 'In process...',
    price: '300 EUR',
    fee: '3 EUR',
    feePercent: '1',
    direction: 'Sell',
    profit: -300,
    profitPercent: -5
  }
];
const mockCashTransactions = [
  {
    price: '+100 USD',
    date: '2020-08-09',
    type: 'Buy',
    sender: 'Lorem Ipsum',
    receiver: 'Lorem Ipsum'
  },
  {
    price: '+200 USD',
    date: '2020-08-09',
    type: 'Sell',
    sender: 'Lorem Ipsum',
    receiver: 'Lorem Ipsum'
  },
  {
    price: '-100 USD',
    date: '2020-08-09',
    type: 'Withdrawal',
    sender: 'Lorem Ipsum',
    receiver: 'Lorem Ipsum'
  }
];
