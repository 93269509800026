import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { entitiesActions } from '../store/entities';
import { useTranslation } from 'react-i18next';
import { investorLocalizationApi } from '../api';
import { getCountries } from '../modules/kycModule';

export const useCountries = () => {
  const dispatch = useDispatch();
  const { i18n} = useTranslation();
  const { countries, languages } = useSelector((state) => state.entities);

  const getAvailableLanguages = useCallback(async () => {
    try {
      const result = await investorLocalizationApi.getAvailableLanguages();
      if (result) dispatch(entitiesActions.setLanguages(result.data));
    } catch (e) {
      dispatch(entitiesActions.addToast('Languages fetch error'));
    }
  },[dispatch]);

  const changeCurrentLanguage = useCallback((value) => {
    const newLang = languages.find(i => i.code === value.key)?.code?.toLowerCase();
    console.log(newLang);
    i18n.changeLanguage(newLang).then(() => {
      localStorage.setItem('language', newLang);
      setCountries().then(() => {
        console.log('current lang and countries are changed');
      });
    });
  },[languages, i18n]);

  const setCountries = useCallback(async () => {
    if (languages) {
      try {
        const currentLang = languages.find(i => i.code === i18n.language.toUpperCase());
        const resCountries = await getCountries(currentLang.id);
        console.log(resCountries);
        if (resCountries) dispatch(entitiesActions.setCountries(resCountries.data));
      } catch (e) {
        console.error('FETCH_COUNTRIES', e);
        dispatch(entitiesActions.addToast('Countries fetch error'));
      }
    }
  }, [dispatch, languages]);

  return {
    languages,
    countries,
    setCountries,
    getAvailableLanguages,
    changeCurrentLanguage
  };
};
