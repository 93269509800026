import styled from 'styled-components';
import { Collapse } from 'antd';
const { Panel } = Collapse;

export const Box = styled.div`
  ${({ isNew }) =>
  isNew
    ? `
    border: 1px solid #bababa;
    padding: 15px;
    margin-bottom: 10px;
    background-color: white;
  `
    : ``}
`;

export const NestedBox = styled.div`
  margin-left: 20px
`;


export const Label = styled.div`
  color: #696969;
  font-size: 12px;
`;

export const ActionBox = styled.div`
  margin-top: 20px;
  text-align: right;
  
  button {
    margin-left: 10px;
  }
`;

export const Divider = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  border: 1px solid #a6b7d7;
`;

export const Space = styled.div`
    height: 10px;
`;

export const StyledPanel = styled(Panel)`
  background-color: rgba(114, 174, 248, 0.3)!important;
  border-bottom: 1px solid #93a6d8!important;
`

export const AnswerItem = styled.span`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  margin-bottom: 10px;
  padding: 0 5px;
  background-color: #e9e9ff;
  min-height: 30px;
`;