import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Checkbox, Modal, Radio } from 'antd';
import { useDocumentType, useQuestionnaires, useQuestionnaire } from '../hooks';
import { Space } from '../styled';
import { AddTrigger } from './AddTrigger';

export const RelatedQuestionnaire = ({questionSectionTypeId, closeModalCheckTrigger}) => {
  const { t } = useTranslation();
  const {data} = useQuestionnaires(questionSectionTypeId);
  const [questionnaires, setQuestionnaires] = useState([]);
  const [loading, setLoading] = useState(true);
  const [parentQuestionnaire, setParentQuestionnaire] = useState(null);
  const [isTriggerOpened, setIsTriggerOpened] = useState(false);
  const [selectedQuestionnaireId, setSelectedQuestionnaireId] = useState(null);

  useEffect(() => {
    if (!data || !data.length) return;

    setLoading(false);
    setQuestionnaires(data);

  }, [data ])

  const onClick = (e, questionnaire) => {
    if (e.target.checked) {
      setParentQuestionnaire(questionnaire);
      setIsTriggerOpened(true);
      setSelectedQuestionnaireId(null);
    }
  }
  const closeTrigger = () => {
    setIsTriggerOpened(false);
  }


  if (loading) return (
    <div>Loading...</div>
  )

  return (
    <Container>
      <ScrollBox>
        {questionnaires.map(questionnaire => (
          <QuestionBox key={questionnaire.id}>
            <QuestionType>{questionnaire.type}</QuestionType>
            <RadioContainer>
              <Radio
                checked={selectedQuestionnaireId === questionnaire.id}
                onChange={(e) => {
                  e.preventDefault();
                  onClick(e, questionnaire);
                }}
              >
                {questionnaire.name}
                <Space />
              </Radio>
            </RadioContainer>
          </QuestionBox>
        ))}

        {parentQuestionnaire && (
          <Modal
            title={'Create Trigger'}
            width={1200}
            style={{ top: 10 }}
            okText={'Add'}
            footer={null}
            open={isTriggerOpened}
            onCancel={() => setIsTriggerOpened(false)}
          >
            <AddTrigger parentQuestionnaireId={parentQuestionnaire.id} questionSectionTypeId = {questionSectionTypeId} closeModalCheckTrigger={closeModalCheckTrigger} closeTrigger = {closeTrigger}/>
          </Modal>
        )}
      </ScrollBox>
    </Container>
  );
};

const Container = styled.div`
  padding: 20px;
`
const ScrollBox = styled.div`
  height: 800px;
  overflow-y: auto;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  background-color: #f9f9f9;
  padding: 15px;
`
const QuestionBox = styled.div`
  margin-bottom: 15px;
  padding: 10px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  background-color: #ffffff;
`
const QuestionType = styled.div`
  font-weight: bold;
  color: #555;
`
const RadioContainer = styled.div`
  margin-top: 5px;
`

