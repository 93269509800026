import React, { useState } from 'react';
import { Header, Split, GU } from '@aragon/ui';
import { BuySellAsset } from '../../../../containers';
import {
  Box,
  AttachedFile,
  BackBar,
  AssetDetailsBox,
  IssuerDetailsBox
} from '../../../../components';
import { Button, Row, Col, Title2 } from '../../../../ui';

//ToDo divide general and tables
export const AssetInfo = ({ currentAsset = {}, personalAccounts = [], myAssetQuantity }) => {
  const [opened, setOpened] = useState(false);
  const [openedSide, setOpenedSide] = useState('BUY');

  const toggleSideBar = (orderSide) => {
    if (orderSide) {
      setOpenedSide(orderSide);
      setOpened(true);
    } else {
      setOpened(false);
    }
  };

  const { issuer, issueDocuments, issue, ...asset } = currentAsset;

  return (
    <div>
      <Header
        primary={<Title2>Asset Info</Title2>}
        secondary={
          <div>
            {asset && asset.quantityMyAsset > 0 && (
              <Row margin={-GU}>
                <Col>
                  <Button label='Buy more' onClick={() => toggleSideBar('BUY')} />
                </Col>
                <Col>
                  <Button mode='strong' label='Sell' onClick={() => toggleSideBar('SELL')} />
                </Col>
              </Row>
            )}

            {asset && asset.quantityMyAsset === 0 && (
              <Button onClick={() => toggleSideBar('BUY')} mode='strong' label='Buy' />
            )}
          </div>
        }
      />
      <BackBar />

      <Split
        primary={
          <div>
            <AssetDetailsBox data={{ ...asset, issue: issue }} />

            <IssuerDetailsBox data={issuer} />
          </div>
        }
        secondary={
          issueDocuments && (
            <Box heading='issue documents'>
              {issueDocuments.map((item, key) => (
                <AttachedFile url={item?.documentURL} key={key} />
              ))}
            </Box>
          )
        }
      />

      <BuySellAsset
        opened={opened}
        onClose={toggleSideBar}
        asset={{
          assetId: asset.id,
          title: asset.fullName,
          currency: asset.currency,
          assetType: asset.assetType,
          pricePerCapita: issue && issue.issuePrice,
          myQuantity: asset.quantityMyAsset
        }}
        issuer={issuer}
        personalAccounts={personalAccounts}
        side={openedSide}
      />
    </div>
  );
};
