import React from 'react';
import { GU } from '@aragon/ui';
import { Box, InnerBox, BoxLine, ZoomImage } from '../../../../components';
import { Body1Light } from '../../../../ui';

export const IdDocumentsBox = ({ data }) => {
  if (data) {
    return (
      <Box padding={0} marginTop={2 * GU}>
        <InnerBox>
          <Body1Light marginBottom={2 * GU}>ID documents</Body1Light>
          <ZoomImage title='ID photo' image={data.idDocumentPhotoURL} />
          <ZoomImage title='Selfie with ID' image={data.selfieWithIdPhotoURL} />

          <BoxLine title={'ID'} data={data.idDocument} />
          <BoxLine title={'Document number'} data={data.documentNumber} />
          {data.personalCode && <BoxLine title={'Personal code'} data={data.personalCode} />}
          <BoxLine title={'Birth date'} data={data.birthDate?.substr(0, 10)} />
          <BoxLine title={'Issuing state'} data={data.issuingState} />
          <BoxLine title={'Issuing organization'} data={data.issuingOrganization} />
          <BoxLine title={'Date of issuance'} data={data.dateOfIssuance?.substr(0, 10)} />
          <BoxLine title={'Date of expiry'} data={data.dateOfExpiry?.substr(0, 10)} />
        </InnerBox>

        {(data.maritalStatus || data.nameSurnameOfSpouse) && (
          <InnerBox>
            <BoxLine title={'Marital status'} data={data.maritalStatus} />
            {data.nameSurnameOfSpouse && (
              <BoxLine title={'Name Surname of spouse'} data={data.nameSurnameOfSpouse} />
            )}
          </InnerBox>
        )}
      </Box>
    );
  }

  return null;
};
