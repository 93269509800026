import React from 'react';
import {
  Box,
  Text,
  IconHeart,
  Button,
  ContextMenu,
  ContextMenuItem,
  TransactionBadge
} from '@aragon/ui';
import styles from '../style.module.css';
import logo from '../../../assets/icons/eth_icon.svg';
import coin from '../../../assets/icons/coin_icon2.svg';

const WishlistItem = ({ currency, quantity, volume, address, date }) => {
  return (
    <Box className={styles['wishlistCard']}>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <ContextMenu>
          <ContextMenuItem>Some Action</ContextMenuItem>
          <ContextMenuItem>Another Action</ContextMenuItem>
        </ContextMenu>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: '-20px',
          marginBottom: '14px'
        }}
      >
        <img src={coin} alt='' />
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <img style={{ marginBottom: '4px', marginRight: '8px' }} src={logo} alt='' />
        <Text
          style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          color='#717171'
        >
          {currency}
        </Text>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: '8px'
        }}
      >
        <Button size='small'>View details</Button>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-evenly',
          marginTop: '12px',
          marginBottom: '4px'
        }}
      >
        <Text>{quantity}</Text>
        <Text>{volume}</Text>
      </div>
      <Text style={{ display: 'flex', justifyContent: 'space-evenly', marginBottom: '4px' }}>
        Price per capita
      </Text>
      <div style={{ display: 'flex', justifyContent: 'space-evenly', marginTop: '16px' }}>
        <Text>Estimated income</Text>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-evenly', marginTop: '8px' }}>
        <TransactionBadge transaction={address} />
        <Text>10/05/17</Text>
      </div>
    </Box>
  );
};

export default WishlistItem;
