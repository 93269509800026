import React from 'react';
import { useParams } from 'react-router-dom';

import { useProductAnswer } from '../hooks';
import { Answer } from '../../constructorCommon';

export const ProductAnswer = ({answerProp, questionId, onCreated}) => {
  const { id } = useParams();
  const {
    handleCreate,
    handleUpdate,
    handleDelete,
    isCreating,
    isUpdating,
    isDeleting
  } = useProductAnswer({productId: id});

  return (
    <Answer answerProp={answerProp} questionId={questionId} onCreated={onCreated}
            handleUpdate={handleUpdate}
            handleDelete={handleDelete}
            handleCreate={handleCreate}
            isCreating={isCreating}
            isUpdating={isUpdating}
            isDeleting={isDeleting}
            type="products"/>
  )
};

