import React, { useEffect } from 'react';
import { NewTransferPanel as NewTransferView } from '../../../../components';
import { useDispatch, useSelector } from 'react-redux';
import { investorActions } from '../../../../store/investor';
import { walletActions } from '../../../../store/wallet';
import { history } from '../../../../routing';

export const NewTransferPanel = ({ ...props }) => {
  const dispatch = useDispatch();
  const { paymentDetails, personalAccounts, cashSummary } = useSelector((state) => state.investor);

  useEffect(() => {
    if (!paymentDetails) {
      dispatch(investorActions.fetchPayments());
    }
  }, [paymentDetails]);

  const onSubmitTransfer = async (data) => {
    await dispatch(walletActions.setNewTransfer(data));
    history.push('/submit-transfer');
  };

  return (
    <NewTransferView
      paymentDetails={paymentDetails}
      personalAccounts={personalAccounts}
      availableWithdrawal={cashSummary?.availableCash}
      onSubmitTransfer={onSubmitTransfer}
      {...props}
    />
  );
};
