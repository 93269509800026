import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import {
  EnterPage,
  InvestorRegStart,
  InvestorReg,
  LoginPage,
  LoginViaEmail,
  LoginViaWallet,
  Tier1Page,
  ResetPassword
} from '../../pages';
import { AgentRegStart } from '../../pages/registration/agent';
import { AssistantRegStart } from '../../pages/registration/assistant';


export const RegLoginRoutes = () => {
  return (
    <Switch>
      <Route exact path='/' component={EnterPage} />
      <Route exact path='/registration/investor' component={InvestorRegStart} />
      <Route exact path='/registration/investor/:page' component={InvestorReg} />
      <Route exact path='/registration/agent' component={AgentRegStart} />
      <Route exact path='/registration/agent/:page' component={InvestorReg} />
      <Route exact path='/registration/assistant' component={AssistantRegStart} />
      <Route exact path='/registration/assistant/:page' component={InvestorReg} />
      {/*<Route exact path="/login" component={LoginPage}/>*/}
      <Route exact path='/login' component={LoginViaEmail} />
      {/*<Route exact path="/login/wallet" component={LoginViaWallet}/>*/}
      <Route exact path='/reset-password' component={ResetPassword} />

      {/*<Route exact path='/tier-1' component={Tier1Page} />*/}

      <Redirect to={'/'} />
    </Switch>
  );
};
