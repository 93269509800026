import React from 'react';
import { history } from '../../../../routing';
import { displayFullDate } from '../../../../utils/helpers';
import { Table, TableRow } from '@aragon/ui';
import { Cell, DetailsButton, TableHeader } from '../../components';
import { ASSET_TYPES } from '../../../../utils/static';
import styled from 'styled-components';

const defHeader = ['Asset ID', 'Ticker', 'Type', 'Issuer', 'Issue Date', 'Quantity', 'Price'];

export const AvailableAssetsTable = ({
  header = defHeader,
  data = [],
  noBorderBottom = false,
  onDetailsSideEffect = null
}) => {
  const onDetailsClick = (id) => {
    onDetailsSideEffect && onDetailsSideEffect();
    history.push(`/assets/${id}`);
  };

  const headerItems = header.map((item, key) => <TableHeader title={item} key={key} />);

  const bodyItems =
    data && data.length > 0 ? (
      data.map((item, key) => (
        <TableRow key={key}>
          <Cell>{item.id}</Cell>
          <Cell>{item.ticker}</Cell>
          <Cell>{ASSET_TYPES[item.type]}</Cell>
          <Cell>{item.issuer}</Cell>
          <Cell>{displayFullDate(new Date(item.issueDate), true)}</Cell>
          <Cell>{item.quantity}</Cell>
          <Cell>
            {item.price} {item.currency}
          </Cell>
          <Cell cellProps={{ align: 'right' }}>
            <DetailsButton onClick={() => onDetailsClick(item.id)} />
          </Cell>
        </TableRow>
      ))
    ) : (
      <TableRow>
        <Cell cellProps={{ style: { border: 'none' } }}>No assets</Cell>
      </TableRow>
    );

  return (
    <StyledTable
      header={<TableRow>{headerItems}</TableRow>}
      noSideBorders
      noBorderBottom={noBorderBottom}
    >
      {bodyItems}
    </StyledTable>
  );
};

const StyledTable = styled(Table)`
  th {
    border-left: none;
    border-right: none;
  }
  td {
    background: transparent;
  }

  ${(p) =>
    p.noBorderBottom
      ? `
    tr:last-child > td {
      border-bottom: none;
    }
  `
      : ''}
`;
