import React, {useEffect} from 'react';

import { PageContainer } from '../../../ui';
import { Questionnaire } from '../../../features/constructor';

export const QuestionnairePage = () => {

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [])

  return (
    <PageContainer>
      <Questionnaire/>
    </PageContainer>
  );
};
