import React from 'react';
import { Button as ButtonAragon, IconExternal, IconDown, GU, Text } from '@aragon/ui';
import styled from 'styled-components';
import { Row, Col } from '../../../ui';
import { COLORS } from '../../../utils/static/colors';

const modeBg = {
  orange: COLORS.orange,
  yellow: COLORS.yellow,
  blue: COLORS.lightBlue
};
const modeColor = {
  orange: COLORS.white,
  yellow: COLORS.white,
  blue: COLORS.white
};
export const Button = styled(ButtonAragon)`
  overflow: hidden;
  ${p => p.mode && !p.disabled ? `
    ${modeBg[p.mode] ? `background: ${modeBg[p.mode]};` : ''}
    ${modeColor[p.mode] ? `color: ${modeColor[p.mode]};` : ''}
  ` : ''}
  ${(p) =>
    p.paddingX
      ? `
    padding-left: ${p.paddingX}px;
    padding-right: ${p.paddingX}px;
    min-width: unset;
  `
      : ''}

  ${(p) =>
    p.paddingLeft
      ? `
    padding-left: ${p.paddingLeft}px;
  `
      : ''}
  
  ${(p) =>
    p.paddingRight
      ? `
    padding-right: ${p.paddingRight}px;
  `
      : ''}
  
  ${(p) =>
    p.width
      ? `
    width: 100%;
    max-width: ${p.width}px;
    min-width: ${p.width}px;
    padding: 0;
  `
      : ``}
  
  ${(p) =>
    p.minWidth
      ? `
    min-width: ${p.minWidth}px;
  `
      : ``}
  
  ${(p) => (p.fontWeight ? `font-weight: ${p.fontWeight};` : '')}
`;

export const ExportButton = ({
  label = 'Export',
  fontWeight = '300',
  paddingX = 1.5 * GU,
  ...props
}) => (
  <Button fontWeight={fontWeight} paddingX={paddingX} {...props}>
    <Row margin={-0.5 * GU} alignItems={'center'}>
      <Col>
        <IconExternal size={'medium'} color={COLORS.iconColor} style={{ display: 'block' }} />
      </Col>
      <Col>{label}</Col>
      <div>
        <IconDown size={'small'} color={COLORS.iconColor} style={{ display: 'block' }} />
      </div>
    </Row>
  </Button>
);

export const RejectButton = ({ label = 'Reject', ...props }) => {
  return (
    <Button paddingX={21} {...props}>
      <Text color={COLORS.red}>{label}</Text>
    </Button>
  );
};

export const ExecuteButton = (props) => {
  return <Button label={'Execute'} mode={'strong'} paddingX={23} {...props} />;
};
