import React, { useState } from 'react';
import { GU, IconClose } from '@aragon/ui';
import { Body2Light, Row, Col } from '../../../ui';
import styled from 'styled-components';
import ZoomIconUrl from '../../../assets/icons/zoom_icon.svg';
import { getFileSrc } from '../../../utils/helpers';
import { COLORS } from '../../../utils/static/colors';

const ZoomImage = ({ title, image }) => {
  const [visibleModal, setVisibleModal] = useState(false);

  const togglePhotoModal = () => {
    setVisibleModal(!visibleModal);
  };

  const ImageSrc = image && getFileSrc(image);

  return (
    <Container>
      {title && <Body2Light color='#637381' marginBottom={GU}>
        {title}
      </Body2Light>}

      <ImgWrapper onClick={togglePhotoModal}>
        <Img src={ImageSrc} alt='' />
        <ZoomIcon src={ZoomIconUrl} />
      </ImgWrapper>

      {visibleModal && (
        <ImageModal onClick={togglePhotoModal}>
          <ImageWrapper>
            <div>
              <Col>
                <Row justifyContent={'flex-end'} marginBottom={3 * GU}>
                  <CloseButton />
                </Row>
              </Col>

              <OpenedImage src={ImageSrc} alt='' />
            </div>
          </ImageWrapper>
        </ImageModal>
      )}
    </Container>
  );
};

export default ZoomImage;

const CloseButton = (props) => {
  return (
    <CloseButtonWrapper {...props}>
      <IconClose color={COLORS.white} />
    </CloseButtonWrapper>
  );
};

const Container = styled.div`
  margin-bottom: ${2 * GU}px;
`;
const ZoomIcon = styled.img`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: 0.05s ease-in;
  visibility: hidden;
  z-index: 1;
`;
const ImgWrapper = styled.div`
  position: relative;
  width: 64px;
  height: 64px;
  border-radius: 4px;
  overflow: hidden;
  border: 1px solid #9da0a3;
  cursor: pointer;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transition: 0.15s ease-out;
  }

  &:hover:after {
    background: rgba(0, 0, 0, 0.31);
  }

  &:hover ${ZoomIcon} {
    visibility: visible;
  }
`;
const Img = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const ImageModal = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.4);
  z-index: 101;
`;
const ImageWrapper = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  max-width: 637px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
const OpenedImage = styled.img`
  width: 100%;
  height: 100%;
  max-height: 500px;
  object-fit: contain;
`;

const CloseButtonWrapper = styled.div`
  width: 46px;
  height: ${5 * GU}px;
  background: ${COLORS.default};
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  > svg {
    width: ${4 * GU}px;
    height: ${4 * GU}px;
  }
`;
