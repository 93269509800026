import React from 'react';
import { useParams } from 'react-router-dom';

import { useProductsQuestion } from '../hooks';
import { Question } from '../../constructorCommon';


export const ProductQuestion = ({questionProp, groupId, onCreated}) => {
  const { id } = useParams();
  const {
    handleCreate,
    handleUpdate,
    handleDelete,
    isCreating,
    isUpdating,
    isDeleting
  } = useProductsQuestion({productId: id});

  return (
    <Question questionProp={questionProp} groupId={groupId} onCreated={onCreated} handleUpdate={handleUpdate}
              handleDelete={handleDelete}
              handleCreate={handleCreate}
              isCreating={isCreating}
              isUpdating={isUpdating}
              isDeleting={isDeleting}
              type="products"/>
  );
};
