import React, { useEffect, useState } from 'react';
import { adminApi } from '../../../../../api';
import { FundsView } from '../View';
import { walletActions } from '../../../../../store/wallet';
import { useDispatch, useSelector } from 'react-redux';
import { transactionFiltersToBack } from '../../../../../utils/helpers';

export const Funds = () => {
  const dispatch = useDispatch();
  const { filters } = useSelector((state) => state.wallet);
  const [transactions, setTransactions] = useState(null);

  useEffect(() => {
    fetchTransactions(transactionFiltersToBack(filters));
    // fetchFundsSummary();
  }, []);

  const fetchTransactions = (params) => {
    adminApi.getTransactions(params).then((res) => {
      if (res.status === 200) {
        setTransactions(res.data);
      }
    });
  };

  const fetchFundsSummary = () => {
    adminApi.getFundsSummary().then(console.log);
  };

  const saveFilters = (filters) => {
    dispatch(walletActions.setFilters(filters));
  };

  return (
    <FundsView
      transactions={transactions && transactions}
      fetchTransactions={fetchTransactions}
      savedFilters={filters}
      saveFilters={saveFilters}
    />
  );
};
