import { useQuery } from 'react-query';
import { adminLocalizationApi, adminLocalizationKeys } from '../../../api';

export const useProductsSections = (productId) => {
  const { data, isLoading, isError, error } = useQuery({
    queryKey: adminLocalizationKeys.getProductsSections,
    queryFn: () => adminLocalizationApi.getProductsSections({ productId: productId }),
    select: res => res.data
  });

  return {
    isLoading,
    isError,
    data,
    error
  }
}
