import { createSlice } from '@reduxjs/toolkit';

let initialState = {
  issues: null,
  issuers: null,
  loader: {
    visible: false,
    label: ''
  },
  toast: '',
  countries: null,
  languages: null
};

const entities = createSlice({
  name: 'entitiesReducer',
  initialState: initialState,
  reducers: {
    setIssues(state, action) {
      state.issues = action.payload;
    },
    setIssuers(state, action) {
      state.issuers = action.payload;
    },
    showLoader(state, action) {
      state.loader.visible = true;
      state.loader.label = action.payload;
    },
    hideLoader(state, action) {
      state.loader.visible = false;
    },
    setToast(state, action) {
      state.toast = action.payload;
    },
    setCountries(state, action) {
      state.countries = action.payload;
    },
    setLanguages(state, action) {
      state.languages = action.payload;
    }
  }
});

export const { setIssues, setIssuers, showLoader, hideLoader, setToast, setCountries, setLanguages } =
  entities.actions;

export default entities.reducer;
