import React from 'react';
import { IconUp, IconDown } from '@aragon/ui';
import { COLORS } from '../../../../utils/static';
import styled from 'styled-components';

export const AccordionButton = ({ opened = false, onClick = () => {} }) => {
  return (
    <Button>
      <IconUpWrapper opened={opened}>
        <IconUp size={'small'} />
      </IconUpWrapper>

      <IconDownWrapper opened={opened}>
        <IconDown size={'small'} />
      </IconDownWrapper>
    </Button>
  );
};

const Button = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${COLORS.iconColor};
`;
const IconWrapper = styled.div`
  display: flex;
  transition: 0.2s ease-out;
`;
const IconUpWrapper = styled(IconWrapper)`
  transform: ${(p) => (p.opened ? 'rotate(180deg)' : 'rotate(0deg)')};
`;
const IconDownWrapper = styled(IconWrapper)`
  transform: ${(p) => (p.opened ? 'rotate(-180deg)' : 'rotate(0deg)')};
`;
