import React from 'react';
import { GU } from '@aragon/ui';
import { StatusLine, BoxLine, Badge, Box, InnerBox } from '../../../../components';
import { Link, Title5 } from '../../../../ui';
import { dateString, displayFullDate, orderHasDealStatus } from '../../../../utils/helpers';
import {
  COLORS,
  EXECUTION_PLACE,
  DISPLAY_MIFID_STATUS,
  ORDER_STATUSES,
  ORDER_TYPES,
  RESPONSIBLE_BROKER,
  SIDE_CONFIG,
  VALID_TILL_DATE
} from '../../../../utils/static';

export const OrderDetailsBox = ({ order, ...props }) => {
  return (
    <Box padding={0} {...props}>
      <InnerBox>
        <Title5 marginBottom={GU}>{order.id}</Title5>
        <StatusLine statusTypes={ORDER_STATUSES} type={order.status} />
      </InnerBox>

      <InnerBox>
        <BoxLine title='Side'>
          <Badge title={SIDE_CONFIG[order.side].title} />
        </BoxLine>
        <BoxLine title='Settlement type' data={order.settlementType} />
        <BoxLine title='Placed time' data={displayFullDate(new Date(order.createdAt))} />
        <BoxLine
          title='Valid till'
          data={
            VALID_TILL_DATE[order.orderValidTillOption] ||
            dateString(new Date(order.orderValidTill))
          }
        />
        {order.executionTime && (
          <BoxLine title='Execution time' data={displayFullDate(new Date(order.executionTime))} />
        )}
        <BoxLine title='Mifid status' data={DISPLAY_MIFID_STATUS[order.mifidStatus]} />
        <BoxLine title='Responsible broker' data={RESPONSIBLE_BROKER} />
        <BoxLine title='Order type' data={ORDER_TYPES[order.type]} />
        {orderHasDealStatus(order.status) && (
          <BoxLine title='Deal'>
            <Link to={`/deal/${order.id}`} color={COLORS.blue}>
              DL{order.id}
            </Link>
          </BoxLine>
        )}
      </InnerBox>

      <InnerBox>
        <BoxLine title='Ticker' data={order.asset.ticker} />
        <BoxLine title='Amount' data={order.quantity} />
        <BoxLine title='Execution amount' data={order.executionAmount} />
        <BoxLine title='Price' data={`${order.totalPrice} ${order.asset.currency}`} />
        <BoxLine title='Execution price' data={`${order.executionPrice} ${order.asset.currency}`} />
        <BoxLine title='Account' data={'Personal account'} />
        <BoxLine title='Execution place' data={EXECUTION_PLACE} />
      </InnerBox>
    </Box>
  );
};
