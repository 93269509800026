import React from 'react';
import { Text } from '@aragon/ui';
import styles from '../style.module.css';
import textStyle from '../../../utils/textStyle';

const ParamText = ({ param1, param2 }) => {
  return (
    <div className={styles['flexText']}>
      <Text color='#637381'>{param1}</Text>
      <Text>{param2}</Text>
    </div>
  );
};

export default ParamText;
