import React, { useState } from 'react';
import { RegContainer, StepsPanel, FooterButtons, OptionCard } from '../../../../../components';
import styled from 'styled-components';
import { Title2, Label2 } from '../../../../../ui';
import {
  GU,
  Info,
  Box,
  Text,
  Checkbox,
  TextInput,
  Help,
  Radio,
  DropDown,
  Modal,
  Link
} from '@aragon/ui';
import { individualSteps } from '../../../../../utils/static';
import { history } from '../../../../../routing';
import textStyle from '../../../../../utils/textStyle';
import styles from '../../styles.module.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

export const Step8 = () => {
  const [modalOpened, setModalOpened] = useState(false);
  const [startDate, setStartDate] = useState(new Date());

  const openModal = () => setModalOpened(true);
  const closeModal = () => setModalOpened(false);

  const ExampleCustomInput = ({ value, onClick }) => (
    <button className={styles['example-custom-input']} onClick={onClick}>
      {value}
    </button>
  );

  return (
    <StepsPanelContainer>
      <StepsPanel title={'Registration'} step={7} steps={individualSteps} />

      <RegContainer>
        <Title style={{ textAlign: 'center' }}>MIFID questionary</Title>

        <Box className={styles['noPadding']}>
          <div className={styles['innerBox']}>
            <Text style={textStyle('body1')}>Client details</Text>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginBottom: '16px',
                marginTop: '24px'
              }}
            >
              <div style={{ display: 'flex', flexDirection: 'column', width: '49%' }}>
                <InputLabel>First name</InputLabel>
                <TextInput style={{ width: '100%', background: '#F9FAFC' }} />
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', width: '49%' }}>
                <InputLabel>Last name</InputLabel>
                <TextInput style={{ width: '100%', background: '#F9FAFC' }} />
              </div>
            </div>

            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between'
              }}
            >
              <div style={{ display: 'flex', flexDirection: 'column', width: '49%' }}>
                <InputLabel>Personal code</InputLabel>
                <TextInput style={{ width: '100%', background: '#F9FAFC' }} />
              </div>
            </div>
          </div>
        </Box>

        <Box heading='Actual Address' className={styles['noPadding']}>
          <div className={styles['innerBox']}>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <div style={{ display: 'flex', flexDirection: 'column', width: '23.5%' }}>
                <InputLabel>Country</InputLabel>
                <TextInput style={{ width: '100%', background: '#F9FAFC' }} />
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', width: '23.5%' }}>
                <InputLabel>City</InputLabel>
                <TextInput style={{ width: '100%', background: '#F9FAFC' }} />
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', width: '49%' }}>
                <InputLabel>Address</InputLabel>
                <TextInput style={{ width: '100%', background: '#F9FAFC' }} />
              </div>
            </div>
          </div>
        </Box>

        <Box className={styles['noPadding']}>
          <div className={styles['innerBox']}>
            <Text style={textStyle('body1Wide')}>Educational and employment details of person</Text>
            <Text style={{ display: 'block', margin: '22px 0 6px' }}>Education:</Text>
            <div style={{ display: 'flex', marginBottom: '5px' }} className={styles['radioList']}>
              <label style={{ display: 'block', width: '15%' }}>
                <Checkbox />
                Primary
              </label>
              <label style={{ display: 'block', width: '25%' }}>
                <Checkbox />
                Specialized secondary
              </label>
              <label style={{ display: 'block', width: '35%' }}>
                <Checkbox />
                Bachelor degree or similar education
              </label>
              <label style={{ display: 'block', width: '25%' }}>
                <Checkbox />
                Doctorate
              </label>
            </div>
            <div style={{ display: 'flex' }} className={styles['radioList']}>
              <label style={{ display: 'block', width: '15%' }}>
                <Checkbox />
                Secondary
              </label>
              <label style={{ display: 'block', width: '25%' }}>
                <Checkbox />
                Professional
              </label>
              <label style={{ display: 'block', width: '60%' }}>
                <Checkbox />
                Master degree or similar education
              </label>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between'
              }}
            >
              <div style={{ display: 'flex', flexDirection: 'column', width: '49%' }}>
                <Text style={{ display: 'block', margin: '22px 0 6px' }}>Specialization</Text>
                <TextInput style={{ width: '100%' }} />
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', width: '49%' }}>
                <Text style={{ display: 'block', margin: '22px 0 6px' }}>Current Employment</Text>
                <TextInput
                  placeholder='job position, company’s name, field of business'
                  style={{ width: '100%' }}
                />
              </div>
            </div>
          </div>
        </Box>

        <Box className={styles['noPadding']}>
          <div className={styles['innerBox']}>
            <div style={{ display: 'flex' }}>
              <Text style={textStyle('body1')}>Client classification</Text>
              <div style={{ marginLeft: '10px', marginTop: '3px' }}>
                <Help>Help</Help>
              </div>
            </div>

            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Text style={{ display: 'block', marginTop: '22px' }}>
                Are you included in the list of the holders of the internal information of an issuer
                whose financial instruments are traded on a regulated market?
              </Text>
              <div
                style={{ display: 'flex', marginBottom: '20px', marginTop: '6px' }}
                className={styles['radioList']}
              >
                <label style={{ display: 'block', width: '12%' }}>
                  <Radio id='No1' />
                  No
                </label>
                <label style={{ display: 'block', width: '12%' }}>
                  <Radio id='Yes1' />
                  Yes
                </label>
              </div>
              <TextInput
                placeholder='Please specify the issuer, company name'
                style={{ width: '50%' }}
              />
            </div>
          </div>

          <div className={styles['innerBox']}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Text style={{ display: 'block' }}>
                Have you been classified as a Professional client in any country within EU or EEA?
              </Text>
              <div
                style={{ display: 'flex', marginBottom: '20px', marginTop: '6px' }}
                className={styles['radioList']}
              >
                <label style={{ display: 'block', width: '12%' }}>
                  <Radio id='No1' />
                  No
                </label>
                <label style={{ display: 'block', width: '12%' }}>
                  <Radio id='Yes1' />
                  Yes
                </label>
              </div>
              <TextInput
                placeholder='specify the country, institution provided the status, date, any specific financial instrument'
                style={{ width: '50%' }}
              />
            </div>
          </div>

          <div className={styles['innerBox']}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Text style={{ display: 'block' }}>
                Value of your personal financial instrument portfolio:
              </Text>
              <Text className={styles['subText']}>
                (incl. cash deposits, financial instruments, (incl. equities, bonds, units in
                collective investment schemes/ funds, etc.)
              </Text>
              <div style={{ display: 'flex', marginTop: '6px' }} className={styles['radioList']}>
                <label style={{ display: 'block', width: '28%' }}>
                  <Radio id='No1' />
                  50 000 - 200 000 EUR
                </label>
                <label style={{ display: 'block', width: '28%' }}>
                  <Radio id='Yes1' />
                  200 000 – 500 000 EUR
                </label>
                <label style={{ display: 'block', width: '28%' }}>
                  <Radio id='Yes1' />
                  More than 500 000 EUR
                </label>
              </div>
            </div>
          </div>

          <div className={styles['innerBox']}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Text style={{ display: 'block' }}>
                What is significant size of transaction on the relevant market in your opinion:
              </Text>
              <div style={{ display: 'flex', marginTop: '6px' }} className={styles['radioList']}>
                <label style={{ display: 'block', width: '26%' }}>
                  <Radio id='No1' />
                  Less than 10 000 EUR
                </label>
                <label style={{ display: 'block', width: '26%' }}>
                  <Radio id='Yes1' />
                  10 000 – 50 000 EUR
                </label>
                <label style={{ display: 'block', width: '26%' }}>
                  <Radio id='Yes1' />
                  50 000 – 200 000 EUR
                </label>
                <label style={{ display: 'block', width: '26%' }}>
                  <Radio id='Yes1' />
                  More than 200 000 EUR
                </label>
              </div>
            </div>
          </div>

          <div className={styles['innerBox']}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Text style={{ display: 'block' }}>
                Do you have an experience of working in financial sector for at least 1 year?
              </Text>
              <div
                style={{ display: 'flex', marginBottom: '20px', marginTop: '6px' }}
                className={styles['radioList']}
              >
                <label style={{ display: 'block', width: '12%' }}>
                  <Radio id='No1' />
                  No
                </label>
                <label style={{ display: 'block', width: '12%' }}>
                  <Radio id='Yes1' />
                  Yes
                </label>
              </div>
              <TextInput placeholder='specify position' style={{ width: '50%' }} />
            </div>
          </div>
        </Box>

        <Box className={styles['noPadding']}>
          <div className={styles['innerBox']}>
            <Text style={textStyle('body1')}>Financial objectives</Text>

            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Text style={{ display: 'block', marginTop: '22px' }}>
                1. How do you evaluate your investment knowledge and understanding of financial
                markets in general?
              </Text>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginTop: '6px'
                }}
                className={styles['radioList']}
              >
                <label>
                  <Radio />
                  extremely limited – little or no knowledge
                </label>
                <label>
                  <Radio />
                  limited – I understand the basic
                </label>
                <label>
                  <Radio />
                  average – I understand the main investment principles
                </label>
                <label>
                  <Radio />
                  good – I understand most investment principles
                </label>
                <label>
                  <Radio />
                  very knowledgeable – i am professional investor or frequent trader
                </label>
              </div>
            </div>
          </div>
          <div className={styles['innerBox']}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Text style={{ display: 'block' }}>
                2. Do you usually keep yourself informed about financial market developments?
              </Text>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginTop: '6px'
                }}
                className={styles['radioList']}
              >
                <label>
                  <Radio />
                  No, never
                </label>
                <label>
                  <Radio />
                  Yes, occasionally
                </label>
                <label>
                  <Radio />
                  Yes, on a regular basis
                </label>
              </div>
            </div>
          </div>

          <div className={styles['innerBox']}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Text style={{ display: 'block' }}>
                3. What type of investment service(s) have you used previously:
              </Text>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginTop: '6px'
                }}
                className={styles['radioList']}
              >
                <label>
                  <Checkbox />
                  None
                </label>
                <label>
                  <Checkbox />I have traded financial instruments without assistance of a
                  professional (execution only)
                </label>
                <label>
                  <Checkbox />I have traded financial instruments with the assistance of a
                  professional (investment advice)
                </label>
                <label>
                  <Checkbox />I have delegated the management of my assets to a professional
                  (Discretionary Portfolio Management)
                </label>
              </div>
            </div>
          </div>

          <div className={styles['innerBox']}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Text style={{ display: 'block' }}>4. Services you are interested in:</Text>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginTop: '6px'
                }}
                className={styles['radioList']}
              >
                <label>
                  <Checkbox />I would like to take my own investment decisions without assistance of
                  a professional (execution only)
                </label>
                <label>
                  <Checkbox />I would like to be assisted in my investment decisions, but I want to
                  be the one validating each decision (investment advice)
                </label>
                <label>
                  <Checkbox />I would like to delegate the management of my investments to the Macte
                  Invest FM AB (financial instrument portfolio management agreement)
                </label>
              </div>
            </div>
          </div>
        </Box>

        <Box className={styles['noPadding']}>
          <div>
            <div className={styles['headerFinancialInstr']}>
              <div style={{ width: '45%' }}>Type of financial instrument</div>
              <div style={{ width: '11%' }}>Understanding of financial instruments</div>
              <div style={{ width: '12%' }}>Trading experience</div>
              <div style={{ width: '16%' }}>Average number of deals</div>
              <div style={{ width: '16%' }}>Average trading volume of transaction</div>
            </div>
            <div className={styles['dataFinancialInstr']}>
              <div className={styles['dataFinancialInstrRow']}>
                <div style={{ width: '45%' }}>
                  <TextInput value='Equities' style={{ width: '100%', background: '#F9FAFC' }} />
                </div>
                <div style={{ width: '11%' }}>
                  <DropDown items={['Yes', 'No']} selected={0} width='100%' />
                </div>
                <div style={{ width: '12%' }}>
                  <DropDown
                    items={['0 years', '< 1 years', '1-3 years', '> 3 years']}
                    selected={0}
                    width='100%'
                  />
                </div>
                <div style={{ width: '16%' }}>
                  <DropDown
                    items={['< 10 per year', '10-50 per year', '> 50 per year']}
                    selected={0}
                    width='100%'
                  />
                </div>
                <div style={{ width: '16%' }}>
                  <DropDown items={['10k EUR', '10k EUR']} selected={0} width='100%' />
                </div>
              </div>
              <div className={styles['dataFinancialInstrRow']}>
                <div style={{ width: '45%' }}>
                  <TextInput
                    value='Investment funds (including simple ETFs)'
                    style={{ width: '100%', background: '#F9FAFC' }}
                  />
                </div>
                <div style={{ width: '11%' }}>
                  <DropDown items={['Yes', 'No']} selected={1} width='100%' />
                </div>
                <div style={{ width: '12%' }}>
                  <DropDown
                    items={['0 years', '< 1 years', '1-3 years', '> 3 years']}
                    selected={1}
                    width='100%'
                  />
                </div>
                <div style={{ width: '16%' }}>
                  <DropDown
                    items={['< 10 per year', '10-50 per year', '> 50 per year']}
                    selected={1}
                    width='100%'
                  />
                </div>
                <div style={{ width: '16%' }}>
                  <DropDown
                    items={['< 10k EUR', '10k-50k EUR', '50k-100k EUR', '> 100k EUR']}
                    selected={1}
                    width='100%'
                  />
                </div>
              </div>
              <div className={styles['dataFinancialInstrRow']}>
                <div style={{ width: '45%' }}>
                  <TextInput
                    value='Fixed income instruments (government, corporate bonds)'
                    style={{ width: '100%', background: '#F9FAFC' }}
                  />
                </div>
                <div style={{ width: '11%' }}>
                  <DropDown items={['Yes', 'No']} selected={0} width='100%' />
                </div>
                <div style={{ width: '12%' }}>
                  <DropDown
                    items={['0 years', '< 1 years', '1-3 years', '> 3 years']}
                    selected={2}
                    width='100%'
                  />
                </div>
                <div style={{ width: '16%' }}>
                  <DropDown
                    items={['< 10 per year', '10-50 per year', '> 50 per year']}
                    selected={1}
                    width='100%'
                  />
                </div>
                <div style={{ width: '16%' }}>
                  <DropDown
                    items={['< 10k EUR', '10k-50k EUR', '50k-100k EUR', '> 100k EUR']}
                    selected={2}
                    width='100%'
                  />
                </div>
              </div>
              <div className={styles['dataFinancialInstrRow']}>
                <div style={{ width: '45%' }}>
                  <TextInput
                    value='Investment funds (including non-UCITS, Hedge funds, complex ETF, ETN, ETC)'
                    style={{ width: '100%', background: '#F9FAFC' }}
                  />
                </div>
                <div style={{ width: '11%' }}>
                  <DropDown items={['Yes', 'No']} selected={1} width='100%' />
                </div>
                <div style={{ width: '12%' }}>
                  <DropDown
                    items={['0 years', '< 1 years', '1-3 years', '> 3 years']}
                    selected={3}
                    width='100%'
                  />
                </div>
                <div style={{ width: '16%' }}>
                  <DropDown
                    items={['< 10 per year', '10-50 per year', '> 50 per year']}
                    selected={2}
                    width='100%'
                  />
                </div>
                <div style={{ width: '16%' }}>
                  <DropDown
                    items={['< 10k EUR', '10k-50k EUR', '50k-100k EUR', '> 100k EUR']}
                    selected={3}
                    width='100%'
                  />
                </div>
              </div>
              <div className={styles['dataFinancialInstrRow']}>
                <div style={{ width: '45%' }}>
                  <TextInput
                    value='Complex fixed income instruments (including structured bonds)'
                    style={{ width: '100%', background: '#F9FAFC' }}
                  />
                </div>
                <div style={{ width: '11%' }}>
                  <DropDown items={['Yes', 'No']} selected={0} width='100%' />
                </div>
                <div style={{ width: '12%' }}>
                  <DropDown
                    items={['0 years', '< 1 years', '1-3 years', '> 3 years']}
                    selected={0}
                    width='100%'
                  />
                </div>
                <div style={{ width: '16%' }}>
                  <DropDown
                    items={['< 10 per year', '10-50 per year', '> 50 per year']}
                    selected={0}
                    width='100%'
                  />
                </div>
                <div style={{ width: '16%' }}>
                  <DropDown
                    items={['< 10k EUR', '10k-50k EUR', '50k-100k EUR', '> 100k EUR']}
                    selected={1}
                    width='100%'
                  />
                </div>
              </div>
              <div className={styles['dataFinancialInstrRow']}>
                <div style={{ width: '45%' }}>
                  <TextInput
                    value='Exchange tradeable derivatives (futures, options)'
                    style={{ width: '100%', background: '#F9FAFC' }}
                  />
                </div>
                <div style={{ width: '11%' }}>
                  <DropDown items={['Yes', 'No']} selected={1} width='100%' />
                </div>
                <div style={{ width: '12%' }}>
                  <DropDown
                    items={['0 years', '< 1 years', '1-3 years', '> 3 years']}
                    selected={2}
                    width='100%'
                  />
                </div>
                <div style={{ width: '16%' }}>
                  <DropDown
                    items={['< 10 per year', '10-50 per year', '> 50 per year']}
                    selected={2}
                    width='100%'
                  />
                </div>
                <div style={{ width: '16%' }}>
                  <DropDown
                    items={['< 10k EUR', '10k-50k EUR', '50k-100k EUR', '> 100k EUR']}
                    selected={3}
                    width='100%'
                  />
                </div>
              </div>
              <div className={styles['dataFinancialInstrRow']}>
                <div style={{ width: '45%' }}>
                  <TextInput
                    value='OTC tradeable instruments (options, CDS)'
                    style={{ width: '100%', background: '#F9FAFC' }}
                  />
                </div>
                <div style={{ width: '11%' }}>
                  <DropDown items={['Yes', 'No']} selected={1} width='100%' />
                </div>
                <div style={{ width: '12%' }}>
                  <DropDown
                    items={['0 years', '< 1 years', '1-3 years', '> 3 years']}
                    selected={1}
                    width='100%'
                  />
                </div>
                <div style={{ width: '16%' }}>
                  <DropDown
                    items={['< 10 per year', '10-50 per year', '> 50 per year']}
                    selected={2}
                    width='100%'
                  />
                </div>
                <div style={{ width: '16%' }}>
                  <DropDown
                    items={['< 10k EUR', '10k-50k EUR', '50k-100k EUR', '> 100k EUR']}
                    selected={3}
                    width='100%'
                  />
                </div>
              </div>

              <div className={styles['dataFinancialInstrRow']}>
                <div style={{ width: '45%' }}>
                  <TextInput
                    value='FX (including swap, forward)'
                    style={{ width: '100%', background: '#F9FAFC' }}
                  />
                </div>
                <div style={{ width: '11%' }}>
                  <DropDown items={['Yes', 'No']} selected={1} width='100%' />
                </div>
                <div style={{ width: '12%' }}>
                  <DropDown
                    items={['0 years', '< 1 years', '1-3 years', '> 3 years']}
                    selected={0}
                    width='100%'
                  />
                </div>
                <div style={{ width: '16%' }}>
                  <DropDown
                    items={['< 10 per year', '10-50 per year', '> 50 per year']}
                    selected={1}
                    width='100%'
                  />
                </div>
                <div style={{ width: '16%' }}>
                  <DropDown
                    items={['< 10k EUR', '10k-50k EUR', '50k-100k EUR', '> 100k EUR']}
                    selected={1}
                    width='100%'
                  />
                </div>
              </div>

              <div className={styles['dataFinancialInstrRow']}>
                <div style={{ width: '45%' }}>
                  <TextInput
                    value='Interest Rate Swap deals (FRA, IRS, CIRS)'
                    style={{ width: '100%', background: '#F9FAFC' }}
                  />
                </div>
                <div style={{ width: '11%' }}>
                  <DropDown items={['Yes', 'No']} selected={1} width='100%' />
                </div>
                <div style={{ width: '12%' }}>
                  <DropDown
                    items={['0 years', '< 1 years', '1-3 years', '> 3 years']}
                    selected={1}
                    width='100%'
                  />
                </div>
                <div style={{ width: '16%' }}>
                  <DropDown
                    items={['< 10 per year', '10-50 per year', '> 50 per year']}
                    selected={2}
                    width='100%'
                  />
                </div>
                <div style={{ width: '16%' }}>
                  <DropDown
                    items={['< 10k EUR', '10k-50k EUR', '50k-100k EUR', '> 100k EUR']}
                    selected={0}
                    width='100%'
                  />
                </div>
              </div>
            </div>
          </div>
        </Box>

        <Box className={styles['noPadding']}>
          <div className={styles['innerBox']}>
            <Text style={textStyle('body1')}>Knowledge in financial instruments</Text>

            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Text style={{ display: 'block', marginTop: '22px' }}>
                The statement “by buying the share the investment becomes an owner of the company,
                therefore he is entitled to vote at shareholders meetings, potential dividend
                payment and through possible price fluctuation he bears the risks of the company’s
                operation” is{' '}
              </Text>
              <div style={{ display: 'flex', marginTop: '6px' }} className={styles['radioList']}>
                <label style={{ display: 'block', width: '12%' }}>
                  <Radio id='No1' />
                  True
                </label>
                <label style={{ display: 'block', width: '12%' }}>
                  <Radio id='Yes1' />
                  False
                </label>
                <label style={{ display: 'block', width: '12%' }}>
                  <Radio id='Yes1' />
                  Don't know
                </label>
              </div>
            </div>
          </div>

          <div className={styles['innerBox']}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Text style={{ display: 'block' }}>
                The statement “bonds may be sold before their maturity without any loss at any time”
                is
              </Text>
              <div style={{ display: 'flex', marginTop: '6px' }} className={styles['radioList']}>
                <label style={{ display: 'block', width: '12%' }}>
                  <Radio id='No1' />
                  True
                </label>
                <label style={{ display: 'block', width: '12%' }}>
                  <Radio id='Yes1' />
                  False
                </label>
                <label style={{ display: 'block', width: '12%' }}>
                  <Radio id='Yes1' />
                  Don't know
                </label>
              </div>
            </div>
          </div>

          <div className={styles['innerBox']}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Text style={{ display: 'block' }}>
                The statement “the risks implied by investment funds may vary on a very wide scale
                from even risk-free ones (liquidity funds) to high risk ones (equity funds)” is
              </Text>
              <div style={{ display: 'flex', marginTop: '6px' }} className={styles['radioList']}>
                <label style={{ display: 'block', width: '12%' }}>
                  <Radio id='No1' />
                  True
                </label>
                <label style={{ display: 'block', width: '12%' }}>
                  <Radio id='Yes1' />
                  False
                </label>
                <label style={{ display: 'block', width: '12%' }}>
                  <Radio id='Yes1' />
                  Don't know
                </label>
              </div>
            </div>
          </div>

          <div className={styles['innerBox']}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Text style={{ display: 'block' }}>
                The statement “for any structured investment with capital protection, capital
                protection means that the issuer of the product is obliged to pay the par value
                (nominal value) of the product in full upon expiry” is
              </Text>
              <div style={{ display: 'flex', marginTop: '6px' }} className={styles['radioList']}>
                <label style={{ display: 'block', width: '12%' }}>
                  <Radio id='No1' />
                  True
                </label>
                <label style={{ display: 'block', width: '12%' }}>
                  <Radio id='Yes1' />
                  False
                </label>
                <label style={{ display: 'block', width: '12%' }}>
                  <Radio id='Yes1' />
                  Don't know
                </label>
              </div>
            </div>
          </div>

          <div className={styles['innerBox']}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Text style={{ display: 'block' }}>
                The statement “buyer of call option has the right and obligation to sell the
                underlying asset” is{' '}
              </Text>
              <div style={{ display: 'flex', marginTop: '6px' }} className={styles['radioList']}>
                <label style={{ display: 'block', width: '12%' }}>
                  <Radio id='No1' />
                  True
                </label>
                <label style={{ display: 'block', width: '12%' }}>
                  <Radio id='Yes1' />
                  False
                </label>
                <label style={{ display: 'block', width: '12%' }}>
                  <Radio id='Yes1' />
                  Don't know
                </label>
              </div>
            </div>
          </div>

          <div className={styles['innerBox']}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Text style={{ display: 'block' }}>
                The statement “losses from futures deals are limited, only the margin /the limit
                applicable to the deal may be lost” is
              </Text>
              <div style={{ display: 'flex', marginTop: '6px' }} className={styles['radioList']}>
                <label style={{ display: 'block', width: '12%' }}>
                  <Radio id='No1' />
                  True
                </label>
                <label style={{ display: 'block', width: '12%' }}>
                  <Radio id='Yes1' />
                  False
                </label>
                <label style={{ display: 'block', width: '12%' }}>
                  <Radio id='Yes1' />
                  Don't know
                </label>
              </div>
            </div>
          </div>

          <div className={styles['innerBox']}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Text style={{ display: 'block' }}>
                The statement “entering long EUR/USD position means bet that USD will strengthen
                against EUR” is
              </Text>
              <div style={{ display: 'flex', marginTop: '6px' }} className={styles['radioList']}>
                <label style={{ display: 'block', width: '12%' }}>
                  <Radio id='No1' />
                  True
                </label>
                <label style={{ display: 'block', width: '12%' }}>
                  <Radio id='Yes1' />
                  False
                </label>
                <label style={{ display: 'block', width: '12%' }}>
                  <Radio id='Yes1' />
                  Don't know
                </label>
              </div>
            </div>
          </div>
        </Box>

        <Box className={styles['noPadding']}>
          <div className={styles['innerBox']}>
            <Text style={textStyle('body1Wide')}>
              Financial instruments you are intrested in or think to trade
            </Text>
            <Text style={{ display: 'block', margin: '22px 0 6px' }}>Education:</Text>
            <div style={{ display: 'flex' }} className={styles['radioList']}>
              <label style={{ display: 'block', width: '55%' }}>
                <Checkbox />
                Equities
              </label>
              <label style={{ display: 'block', width: '45%' }}>
                <Checkbox />
                Exchange tradable derivatives (futures, options)
              </label>
            </div>

            <div style={{ display: 'flex' }} className={styles['radioList']}>
              <label style={{ display: 'block', width: '55%' }}>
                <Checkbox />
                Investment funds (including simple ETFs)
              </label>
              <label style={{ display: 'block', width: '45%' }}>
                <Checkbox />
                OTC tradable instruments (options, CDS)
              </label>
            </div>

            <div style={{ display: 'flex' }} className={styles['radioList']}>
              <label style={{ display: 'block', width: '55%' }}>
                <Checkbox />
                Fixed income instruments (government, corporate bonds)
              </label>
              <label style={{ display: 'block', width: '45%' }}>
                <Checkbox />
                FX (including swap, forward)
              </label>
            </div>

            <div style={{ display: 'flex' }} className={styles['radioList']}>
              <label style={{ display: 'block', width: '55%' }}>
                <Checkbox />
                Investment funds (including non-UCITS, Hedge funds, complex ETF, ETN, ETC)
              </label>
              <label style={{ display: 'block', width: '45%' }}>
                <Checkbox />
                Interest Rate Swap deals (FRA, IRS, CIRS)
              </label>
            </div>

            <div style={{ display: 'flex' }} className={styles['radioList']}>
              <label style={{ display: 'block', width: '100%' }}>
                <Checkbox />
                Complex fixed income instruments (including structured bonds)
              </label>
            </div>

            <div style={{ display: 'flex' }} className={styles['radioList']}>
              <label style={{ display: 'block', width: '50%' }}>
                <Checkbox />
                <div style={{ width: '91%', marginLeft: '26px', marginTop: '-22px' }}>
                  <TextInput
                    placeholder='Other financial instruments: please specify'
                    style={{ width: '100%' }}
                  />
                </div>
              </label>
            </div>
          </div>
        </Box>

        <Box className={styles['noPadding']}>
          <div className={styles['innerBox']}>
            <Text style={textStyle('body1')}>Asset managment</Text>

            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Text style={{ display: 'block', marginTop: '22px' }}>
                Have you ever used asset management services (trust)?
              </Text>
              <div style={{ display: 'flex', marginTop: '6px' }} className={styles['radioList']}>
                <label style={{ display: 'block', width: '12%' }}>
                  <Radio id='No1' />
                  Yes
                </label>
                <label style={{ display: 'block', width: '15%' }}>
                  <Radio id='Yes1' />
                  No
                </label>
              </div>
            </div>
          </div>

          <div className={styles['innerBox']}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Text style={{ display: 'block' }}>
                Concepts and understanding of asset management:
              </Text>
              <div style={{ display: 'flex', marginTop: '6px' }} className={styles['radioList']}>
                <label style={{ display: 'block', width: '12%' }}>
                  <Radio id='No1' />
                  Yes
                </label>
                <label style={{ display: 'block', width: '15%' }}>
                  <Radio id='Yes1' />
                  No
                </label>
              </div>
            </div>
          </div>

          <div className={styles['innerBox']}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Text style={{ display: 'block' }}>Experience (years):</Text>
              <div style={{ display: 'flex', marginTop: '6px' }} className={styles['radioList']}>
                <label style={{ display: 'block', width: '12%' }}>
                  <Radio id='No1' />
                  None
                </label>
                <label style={{ display: 'block', width: '12%' }}>
                  <Radio id='Yes1' />
                  1-3
                </label>
                <label style={{ display: 'block', width: '20%' }}>
                  <Radio id='Yes1' />
                  Greater than 3
                </label>
              </div>
            </div>
          </div>

          <div className={styles['innerBox']}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Text style={{ display: 'block' }}>Investment strategy:</Text>
              <div style={{ display: 'flex', marginTop: '6px' }} className={styles['radioList']}>
                <DropDown items={['Conservative', 'Conservative']} selected={0} width='25%' />
              </div>
            </div>
          </div>

          <div className={styles['innerBox']}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Text style={{ display: 'block' }}>Experience (years):</Text>
              <div style={{ display: 'flex', marginTop: '6px' }} className={styles['radioList']}>
                <label style={{ display: 'block', width: '25%' }}>
                  <Radio id='No1' />
                  50 000-100 000 EUR
                </label>
                <label style={{ display: 'block', width: '25%' }}>
                  <Radio id='Yes1' />
                  100 001-1 000 000 EUR
                </label>
                <label style={{ display: 'block', width: '25%' }}>
                  <Radio id='Yes1' />1 000 001–5 000 000 EUR
                </label>
                <label style={{ display: 'block', width: '25%' }}>
                  <Radio id='Yes1' />> 5 000 000 EUR
                </label>
              </div>
            </div>
          </div>
        </Box>

        <Box className={styles['noPadding']}>
          <div className={styles['innerBox']}>
            <Text style={textStyle('body1')}>Financing against financial instrument</Text>

            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Text style={{ display: 'block', marginTop: '22px' }}>
                Have you ever used financing (borrowed funds, broker loan) for financial instrument
                transaction?
              </Text>
              <div style={{ display: 'flex', marginTop: '6px' }} className={styles['radioList']}>
                <label style={{ display: 'block', width: '12%' }}>
                  <Radio id='No1' />
                  Yes
                </label>
                <label style={{ display: 'block', width: '15%' }}>
                  <Radio id='Yes1' />
                  No
                </label>
              </div>
            </div>
          </div>

          <div className={styles['innerBox']}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Text style={{ display: 'block' }}>
                Concepts and understanding of financing, broker loan against financial instrument
                portfolio.
              </Text>
              <div style={{ display: 'flex', marginTop: '6px' }} className={styles['radioList']}>
                <label style={{ display: 'block', width: '12%' }}>
                  <Radio id='No1' />
                  Yes
                </label>
                <label style={{ display: 'block', width: '15%' }}>
                  <Radio id='Yes1' />
                  No
                </label>
              </div>
            </div>
          </div>
        </Box>

        <Box className={styles['noPadding']}>
          <div className={styles['innerBox']}>
            <Text style={textStyle('body1')}>Financial information</Text>

            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Text style={{ display: 'block', marginTop: '22px' }}>
                Concepts and understanding of financing, broker loan against financial instrument
                portfolio.
              </Text>
              <div style={{ display: 'flex' }} className={styles['radioList']}>
                <label style={{ display: 'block', width: '30%' }}>
                  <Checkbox />
                  Salary
                </label>
                <label style={{ display: 'block', width: '35%' }}>
                  <Checkbox />
                  Dividends/interests
                </label>
                <label style={{ display: 'block', width: '35%' }}>
                  <Checkbox />
                  Business ownership
                </label>
              </div>
              <div style={{ display: 'flex' }} className={styles['radioList']}>
                <label style={{ display: 'block', width: '100%' }}>
                  <Checkbox />
                  Income from investments
                </label>
              </div>
              <div style={{ display: 'flex' }}>
                <label style={{ display: 'block', width: '45%' }}>
                  <Checkbox />
                  <div style={{ width: '100%', marginLeft: '26px', marginTop: '-22px' }}>
                    <TextInput placeholder='Other: please specify' style={{ width: '100%' }} />
                  </div>
                </label>
              </div>
            </div>
          </div>

          <div className={styles['innerBox']}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Text style={{ display: 'block' }}>Annual income, tax deducted:</Text>
              <div style={{ display: 'flex', marginBottom: '5px', marginTop: '6px' }}>
                <DropDown
                  items={['< 5 000 EUR', '5 000-25 000 EUR', '> 25 000 EUR']}
                  selected={0}
                  width='48%'
                />
              </div>
            </div>
          </div>

          <div className={styles['innerBox']}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Text style={{ display: 'block' }}>
                The total value of your assets (financial assets, real estate, etc):
              </Text>
              <div style={{ display: 'flex', marginBottom: '5px', marginTop: '6px' }}>
                <DropDown
                  items={['< 15 000 EUR', '15 000-25 000 EUR', '> 25 000 EUR']}
                  selected={1}
                  width='48%'
                />
              </div>
            </div>
          </div>

          <div className={styles['innerBox']}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Text style={{ display: 'block' }}>Breakdown of your wealth (%)</Text>
              <Text
                style={{
                  display: 'block',
                  fontSize: '14px',
                  color: '#637381',
                  marginTop: '6px',
                  marginBottom: '5px'
                }}
              >
                The total should be 100%
              </Text>
              <div style={{ display: 'flex' }} className={styles['radioList']}>
                <label style={{ display: 'block', width: '50%' }}>
                  <Checkbox />
                  Liquid assets (deposits, cash)
                </label>
                <label style={{ display: 'block', width: '50%' }}>
                  <Checkbox />
                  Business assets (direct ownership)
                </label>
              </div>
              <div style={{ display: 'flex' }} className={styles['radioList']}>
                <label style={{ display: 'block', width: '50%' }}>
                  <Checkbox />
                  Investments (securities, financial assets, etc
                </label>
                <label style={{ display: 'block', width: '50%' }}>
                  <Checkbox />
                  Real estate
                </label>
              </div>
              <div style={{ display: 'flex' }} className={styles['radioList']}>
                <label style={{ display: 'block', width: '45%' }}>
                  <Checkbox />
                  <div style={{ width: '100%', marginLeft: '26px', marginTop: '-22px' }}>
                    <TextInput placeholder='Other: please specify' style={{ width: '100%' }} />
                  </div>
                </label>
              </div>
            </div>
          </div>

          <div className={styles['innerBox']}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Text style={{ display: 'block' }}>
                The total value of your liabilities (mortgage loan, leasing, consumer credit, etc.):
              </Text>
              <div style={{ display: 'flex', marginBottom: '5px', marginTop: '6px' }}>
                <DropDown items={['No liabilities', 'No liabilities']} selected={1} width='48%' />
              </div>
            </div>
          </div>

          <div className={styles['innerBox']}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Text style={{ display: 'block' }}>Annual liabilities, all premium</Text>
              <div style={{ display: 'flex', marginBottom: '5px', marginTop: '6px' }}>
                <DropDown items={['< 5 000 EUR', '5 000-20 000 EUR']} selected={0} width='48%' />
              </div>
            </div>
          </div>
        </Box>

        <Box className={styles['noPadding']}>
          <div className={styles['innerBox']}>
            <Text style={textStyle('body1')}>Attitude towards risk</Text>

            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Text style={{ display: 'block', marginTop: '22px' }}>
                1. Which one is the most specific to you from the following statements?
              </Text>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginTop: '6px'
                }}
                className={styles['radioList']}
              >
                <label>
                  <Radio />
                  The safety of my capital is the most important to me and i‘m not ready to take any
                  risk (0% of loss)
                </label>
                <label>
                  <Radio />I am uncomfortable with taking risks with my financial investments and
                  can tolerate a small loss (up to 10%)
                </label>
                <label>
                  <Radio />I am willing to take limited risks of capital losses (up to 20%) in
                  return for average capital growth opportunities
                </label>
                <label>
                  <Radio />I am comfortable taking risks of capital losses (up to 50%) in return for
                  high capital growth opportunities
                </label>
                <label>
                  <Radio />I am very comfortable with risk therefore I am prepared to potentially
                  suffer significant losses (more than 50%)
                </label>
              </div>
            </div>
          </div>

          <div className={styles['innerBox']}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Text style={{ display: 'block' }}>
                2. How would you react during the strong decline of the financial markets (e.g. the
                value of your portfolio decreased by 20%)?
              </Text>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginTop: '6px'
                }}
                className={styles['radioList']}
              >
                <label>
                  <Radio />I would sell all or most of my positions to avoid further losses
                </label>
                <label>
                  <Radio />I would keep my current positions and wait for the market to recover
                </label>
                <label>
                  <Radio />I would increase my positions to utilize the opportunities of the market
                  downturn
                </label>
              </div>
            </div>
          </div>

          <div className={styles['innerBox']}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Text style={{ display: 'block' }}>
                3. During the investment period, are you dependent on the invested amount to fund
                your living expenses?{' '}
              </Text>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginTop: '6px'
                }}
                className={styles['radioList']}
              >
                <label>
                  <Radio />
                  Yes, investments to me are very important to ensure the financial well-being, and
                  I don’t have access to other assets and income sources
                </label>
                <label>
                  <Radio />
                  Yes, investments are important to me, but I have other assets and income source to
                  ensure the financial well-being
                </label>
                <label>
                  <Radio />
                  No, I am not dependent on the invested amount or potential annual returns
                </label>
              </div>
            </div>
          </div>

          <div className={styles['innerBox']}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Text style={{ display: 'block' }}>
                4. From the following four options which portfolio is your virtual selection
              </Text>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginTop: '6px'
                }}
                className={styles['radioList']}
              >
                <label>
                  <Radio />
                  Portfolio with the possibility of receiving 2% returns without any loss
                </label>
                <label>
                  <Radio />
                  Portfolio with the possibility of receiving 7% returns with a possible loss 3%
                </label>
                <label>
                  <Radio />
                  Portfolio with the possibility of receiving 15% returns with a possible loss 7%
                </label>
                <label>
                  <Radio />
                  Portfolio with the possibility of receiving 25% returns with a possible loss 15%
                </label>
              </div>
            </div>
          </div>
        </Box>

        <Box className={styles['noPadding']}>
          <div className={styles['innerBox']}>
            <Text style={textStyle('body1')}>Investment objectives and time</Text>

            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Text style={{ display: 'block', marginTop: '22px' }}>
                1. Which of the following risk profiles corresponds better to your personal
                investment objectives?
              </Text>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginTop: '6px'
                }}
                className={styles['radioList']}
              >
                <label>
                  <Radio />
                  Preservation of capital. I want to preserve the value of my investments (no
                  exposure to market risk)
                </label>
                <label>
                  <Radio />
                  Secure investment with income focus. I want to receive regular income and preserve
                  the value of my investments (low exposure to market risk)
                </label>
                <label>
                  <Radio />
                  Mix of income and growth. I want to maintain regular income while having potential
                  for capital growth (moderate exposure to market risk)
                </label>
                <label>
                  <Radio />
                  Performance objective with growth focus. I am ready to take high risk to get the
                  maximum return on the capital growth of my investments (substantial exposure to
                  market risk)
                </label>
                <label>
                  <Radio />
                  Speculation. I want to utilize the opportunities of short-term market
                  fluctuations. High exposure to market risk.
                </label>
              </div>
            </div>
          </div>

          <div className={styles['innerBox']}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Text style={{ display: 'block' }}>
                2. Do you intend to make any withdrawals from your investment portfolio held at
                Macte Invest FM AB?
              </Text>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginTop: '6px'
                }}
                className={styles['radioList']}
              >
                <label>
                  <Radio />I plan on a regular basis to withdraw a certain amount of money from
                  investment portfolio
                </label>
                <label>
                  <Radio />I am not planning to decrease or increase investment portfolio
                </label>
                <label>
                  <Radio />I plan regularly increase investment portfolio
                </label>
              </div>
            </div>
          </div>

          <div className={styles['innerBox']}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Text style={{ display: 'block' }}>
                3. What is the desired period of your investments
              </Text>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginTop: '6px'
                }}
                className={styles['radioList']}
              >
                <div style={{ display: 'flex' }}>
                  <label style={{ display: 'block', width: '45%' }}>
                    <Radio id='No1' />
                    Very short term, up to 1 year
                  </label>
                  <label style={{ display: 'block', width: '55%' }}>
                    <Radio id='Yes1' />
                    Long term, 5 to 10 years
                  </label>
                </div>
                <div style={{ display: 'flex' }}>
                  <label style={{ display: 'block', width: '45%' }}>
                    <Radio id='No1' />
                    Short term, 1 to 3 years
                  </label>
                  <label style={{ display: 'block', width: '55%' }}>
                    <Radio id='Yes1' />
                    Very long term, over 10 years
                  </label>
                </div>
                <div style={{ display: 'flex' }}>
                  <label style={{ display: 'block', width: '45%' }}>
                    <Radio id='No1' />
                    Medium term, 3 to 5 years
                  </label>
                  <label style={{ display: 'block', width: '55%' }}>
                    <Radio />
                    <div style={{ width: '91%', marginLeft: '26px', marginTop: '-22px' }}>
                      <TextInput
                        placeholder='You can specify exact period'
                        style={{ width: '100%' }}
                      />
                    </div>
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div className={styles['innerBox']}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Text style={{ display: 'block' }}>
                4. What is your expected yearly return of investments?
              </Text>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginTop: '6px'
                }}
                className={styles['radioList']}
              >
                <div style={{ display: 'flex', marginTop: '6px' }}>
                  <label style={{ display: 'block', width: '19%' }}>
                    <Radio id='No1' />
                    Up to 4%
                  </label>
                  <label style={{ display: 'block', width: '19%' }}>
                    <Radio id='Yes1' />
                    8-15%
                  </label>
                  <label style={{ display: 'block', width: '62%' }}>
                    <Radio />
                    <div style={{ width: '91%', marginLeft: '26px', marginTop: '-22px' }}>
                      <TextInput
                        placeholder='You can specify exact period'
                        style={{ width: '100%' }}
                      />
                    </div>
                  </label>
                </div>
                <div style={{ display: 'flex', marginTop: '-8px' }}>
                  <label style={{ display: 'block', width: '19%' }}>
                    <Radio id='No1' />
                    4-8%
                  </label>
                  <label style={{ display: 'block', width: '81%' }}>
                    <Radio id='Yes1' />
                    15% and more
                  </label>
                </div>
              </div>
            </div>
          </div>
        </Box>

        <Box className={styles['noPadding']}>
          <div className={styles['innerBox']}>
            <Text style={textStyle('body1')}>Investment strategy</Text>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: '6px'
                  }}
                  className={styles['radioList']}
                >
                  <label>
                    <Radio />
                    Conservative (Moderate return with minimal risk tolerance)
                  </label>
                  <label>
                    <Radio />
                    Balanced (Optimal balance of risk and return)
                  </label>
                  <label>
                    <Radio />
                    Speculative (Probability of big returns with high risk tolerance)
                  </label>
                </div>
              </div>
              <Info
                style={{ width: '50%', height: '70%' }}
                title='Information on the investment strategy under financial instrument
portfolio managment agreement '
              >
                Read about strategy
              </Info>
            </div>
          </div>
        </Box>

        <Box className={styles['noPadding']}>
          <div className={styles['innerBox']}>
            <Text style={textStyle('body1')}>Additional information</Text>
            <textarea
              style={{ marginTop: '12px' }}
              className={styles['textArea']}
              placeholder='Leave your comment here'
            />
          </div>
        </Box>

        <Box className={styles['noPadding']} style={{ marginBottom: '25px' }}>
          <div className={styles['innerBox']}>
            <Text style={{ fontSize: '14px', color: '#637381' }}>01.01.2021</Text>
            <label style={{ display: 'block', width: '55%' }}>
              <Checkbox />
              <Text className={styles['agreement']}>
                I agree with the <Link onClick={openModal}>Information acknowlegment</Link>
              </Text>
            </label>
          </div>
        </Box>

        <Modal className={styles['modal']} visible={modalOpened} onClose={closeModal}>
          <Text style={{ display: 'block', marginBottom: '15px', fontSize: '18px' }}>
            Information acknowlegment
          </Text>
          <Text style={{ fontSize: '16px', lineHeight: '22px', color: '#637381' }}>
            I hereby confirm with the signature that the information stated herein is truthful. I
            hereby confirm with the signature that I am aware of the liability, including criminal
            responsibility that upon providing of incorrect, misleading, incomplete or willful
            non-disclosure of information Macte Invest FMAB reserves the right not to establish a
            business relationship with the Customer, to terminate an existing business relationship
            or not to perform transactions. Macte Invest FMAB also reserves the right to provide
            additional documents (account statements form another bank, documents confirming the
            origin of funds or other documents). I hereby confirm that all financial assets of the
            Customer are of legal origin and was not obtained from an unlawful source or as a result
            of unlawful activity. I must undertake to inform Macte Invest FMAB immediately in
            writing about any changes in the above stated information. I confirm that if such
            notification is not given, I am the owner of the funds held in the accounts to be
            opened/opened in my name, as well as I am not performing any transactions on behalf of
            the third persons and I am not holding third persons’ funds in the accounts opened in my
            name.
          </Text>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginTop: '18px'
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'column', width: '32%' }}>
              <InputLabel>Name, surname</InputLabel>
              <TextInput style={{ width: '100%' }} />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                width: '42%'
              }}
            >
              <div style={{ display: 'flex', flexDirection: 'column', width: '44%' }}>
                <InputLabel>Date</InputLabel>
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  dateFormat='dd.MM.yyyy'
                  customInput={<ExampleCustomInput />}
                />
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', width: '52%' }}>
                <InputLabel>Signature</InputLabel>
                <TextInput style={{ width: '100%' }} />
              </div>
            </div>
          </div>
          <Text style={{ display: 'block', marginTop: '26px' }}>
            Full name and signature of Macte Invest FMAB employee who accepted this questionnaire:
          </Text>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginTop: '18px'
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                width: '56%'
              }}
            >
              <div style={{ display: 'flex', flexDirection: 'column', width: '57%' }}>
                <InputLabel>Name, surname</InputLabel>
                <TextInput style={{ width: '100%' }} />
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', width: '40%' }}>
                <InputLabel>Position</InputLabel>
                <TextInput style={{ width: '100%' }} />
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', width: '22%' }}>
              <InputLabel>Signature</InputLabel>
              <TextInput style={{ width: '100%' }} />
            </div>
          </div>
          <Text
            style={{
              display: 'block',
              width: '75%',
              marginTop: '26px',
              color: '#637381',
              fontSize: '14px',
              lineHeight: '21px'
            }}
          >
            *The purpose of this questionnaire is to collect information about the client according
            current anti-money laundering and tax administration legislation and the overall
            know-your-customer (KYC) principle. Please note that this questionnaire is strictly
            confidential and will not be disclosed to third parties unless it is required by law.
          </Text>
        </Modal>

        <FooterButtons onNext={() => history.push('/registration/investor/9')} />
      </RegContainer>
    </StepsPanelContainer>
  );
};

const StepsPanelContainer = styled.div`
  display: flex;
`;
const Title = styled(Title2)`
  font-weight: 300;
  margin-bottom: ${7 * GU}px;
`;
const InputLabel = styled(Label2)``;
const InfoBlock = styled(Info)`
  margin: ${GU * 4}px 0;
`;
