import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { OrderDetailsView } from '../View';
import { getOrder } from '../../../../../api/admin';

export const OrderDetails = () => {
  const { id } = useParams();
  const [order, setOrder] = useState(null);

  useEffect(() => {
    fetchOrder().then();
  }, []);

  const fetchOrder = async () => {
    try {
      const res = await getOrder(id);
      setOrder(res.data);
    } catch (e) {
      console.log('fetchOrder error:', e);
    }
  };

  return <OrderDetailsView order={order && order} />;
};
