import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { SyncIndicator } from '@aragon/ui';
import { kycApi } from '../../api/kyc';
import { history } from '../../routing';
import { useMutation, useQuery } from 'react-query';
import { Toast } from '../kycModule/components/Toast';
import { QuestionnaireView } from './View';
import { kycHelpers } from '../../utils/helpers/kyc';
import { SHOWED_SECTIONS_COUNT, STATUSES_WITH_CLEAR } from '../../utils/static/kyc';
import { userActions } from '../../store/user';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { investorApi } from '../../api';
import { MessageContext } from '../../app/MessageProvider';

let appContainer = document.getElementById('app-container');

const getRejectedAnswersIds = (obj, questionId, answersIds, newAnswer) => {
  const resultArray = answersIds;
  Object.keys(obj).forEach(key => {

    if (key === 'questionId' && obj[key] === questionId && obj.childQuestionnaireId && obj.answer !== newAnswer.answer) {
      const duplicateIds = resultArray.filter((item) => item.childQuestionnaireId === obj.childQuestionnaireId);

      if (duplicateIds.length > 0) {
        duplicateIds.forEach((id) => id.answersIds.push(id.status === 'CREATED' || id.status === 'WITHOUT_CHANGE' ? newAnswer.id : id.status === 'DELETED' ? obj.id : null));
      } else {
        resultArray.push(
          {
            childQuestionnaireId: obj.childQuestionnaireId,
            status: 'CREATED',
            answersIds: [newAnswer.id]
          },
          {
            childQuestionnaireId: obj.childQuestionnaireId,
            status: 'DELETED',
            answersIds: [obj.id]
          }
        )
      }
    }
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      getRejectedAnswersIds(obj[key], questionId, resultArray, newAnswer)
    }
  })
  return resultArray;
}

export const Questionnaire = ({ adminView, adminEditView = false, referralView = false, investorId, questionnaireId }) => {
  const { referralToken } = useSelector((state) => state.user);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id: paramId, referralId } = useParams();
  const id = adminView ? questionnaireId : paramId;
  const { state } = useLocation();
  const { investor } = useSelector((state) => state);
  const [loader, setLoader] = useState(true);
  const [toast, setToast] = useState('');
  const [rejectedQuestions, setRejectedQuestions] = useState(null);
  const [rejectedSectionsIndexes, setRejectedSectionsIndexes] = useState(null);
  const [currentSection, setCurrentSection] = useState(0);
  const [currentStep, setCurrentStep] = useState(0);
  const [initialStep, setInitialStep] = useState(0);
  const [error, setError] = useState(undefined);
  const messageApi = useContext(MessageContext);

  const { mutateAsync: checkQuestionnaireStatus } = useMutation(adminView ? kycApi.checkQuestionnaireStatusForAdmin : referralView ? kycApi.checkReferralQuestionnaireStatus : kycApi.checkQuestionnaireStatus);
  const {
    data: res,
    isFetching
  } = useQuery(['getQuestionnaireSections', referralToken], () => adminView ? kycApi.getQuestionnaireSectionsForAdmin(id, investorId) : referralView ? kycApi.getReferralQuestionnaireSections(referralToken, id) : kycApi.getQuestionnaireSections(id) , {
    enabled: (referralView && !!referralToken) || !referralView,
    cacheTime: 'Infnity',
    onSuccess: async ({ data: questionnaire }) => {
      try {
        if (questionnaire?.status === 'QUESTION_UNACCEPTED' || questionnaire?.status === 'UNACCEPTED') {
          const res = adminView ? await kycApi.getRejectedEntitiesForAdmin(investorId) : await kycApi.getRejectedEntities();
          const rejectedQuestions = res?.data?.question;

          const { rejectedObj, sectionsIndexes } = kycHelpers.getRejectedObject(
            questionnaire,
            rejectedQuestions
          );

          if (rejectedObj) setRejectedQuestions(rejectedObj);
          if (questionnaire?.status === 'QUESTION_UNACCEPTED') {
            if (sectionsIndexes.length > 0) {
              setRejectedSectionsIndexes(sectionsIndexes);
              setCurrentSection(sectionsIndexes[0]);
              setInitialStep(sectionsIndexes[0]);
            } else await nextStep();
          }
        }
      } catch (e) {
      } finally {
        setLoader(false);
      }
    }
  });
  const questionnaire = useMemo(() => (res ? res.data : null), [res]);

  useEffect(() => {
    dispatch(userActions.hideAppContainer());
    return () => dispatch(userActions.showAppContainer());
  }, []);

  useEffect(() => {
    if (paramId && !referralToken && referralView && referralId) {
      (async () => {
        try {
          const res = await investorApi.getReferralToken(referralId);

          if (res) {
            dispatch(userActions.setReferralToken(res.data.accessToken));
            if (error) setError(undefined);
          }
        } catch (e) {
          setError(e.response.data.message);
        }
      })()
    }
  }, [paramId, referralToken, referralId]);

  const stepsCount = useMemo(() => {
    if (rejectedSectionsIndexes) return rejectedSectionsIndexes.length;
    if (questionnaire && questionnaire.questionSections)
      return Math.ceil(questionnaire.questionSections.length / SHOWED_SECTIONS_COUNT);
    return 0;
  }, [questionnaire, rejectedSectionsIndexes]);

  const onNext = async (data, insertedQuestions) => {
    try {
      setLoader(true);

      const { array } = kycHelpers.collectQuestionnaireCurrentAnswers({
        questionnaire,
        data,
        insertedQuestions,
        currentSection
      });

      const alreadyClearedQuestions = {};

      const answersIds = [];

      //post data
      const answersArray = [];
      const files = {};

      array.filter((answer) => answer.answer || answer.availableAnswerId || answer.file).map(async (answer) => {
        const { clearAll, prePostCallback, key, file, ...answerParams } = answer;

        if (prePostCallback && key !== 'file') {
          answer[key] = await prePostCallback();
        }

        if (key === 'file') {
          if (prePostCallback) {
            const result = await prePostCallback();
            if (result) files[answer.fieldname] = result;
          } else {
            files[answer.fieldname] = answer.file;
          }
        }

        answersArray.push({
          ...answerParams,
          clearAll: clearAll && !alreadyClearedQuestions[answerParams.questionId]
        });

        if (clearAll) alreadyClearedQuestions[answerParams.questionId] = true;
      })

      try {
        const res = adminView ? await kycApi.saveAnswersForAdmin({
          answers: answersArray,
          files: files,
          investorId: investorId
        }) : referralView ? await kycApi.saveReferralAnswers({
          answers: answersArray,
          files: files,
          token: referralToken,
          creatorId: investor.id,
        }) : await kycApi.saveAnswers({
          answers: answersArray,
          files: files
        });

        if (res && rejectedQuestions) {
          res.data.forEach((item) => {
              getRejectedAnswersIds(questionnaire, item.questionId, answersIds, res.data);
            }
          )
        }
      } catch (e) {
        messageApi.error(e?.message);
        console.error(e);
      }

      //change step after posting
      await nextStep(answersIds);
    } catch (e) {
      messageApi.error(e?.message);
      console.error(e);
    } finally {
      setLoader(false);
    }
  };

  const onBack = () => {
    if (currentSection > 0) setCurrentSection(currentSection - 1);
    else state && state.prevPath ? history.push(state.prevPath, state.nextState) : history.goBack();
  };

  const nextStep = async (answersIds) => {
    const finish = async () => {
      await checkQuestionnaireStatus({
        id: id,
        ...(answersIds && answersIds.length > 0 && { rejectedSettings: answersIds }),
        ...(referralView && referralToken && { token: referralToken }),
        investorId: investorId
      });
      adminView ? history.push('/investor/' + investorId) : referralView ? history.push('/referral/' + referralId, { lastReferralProfileTab: investor.lastReferralProfileTab }) : history.push('/profile', { lastProfileTab: investor.lastProfileTab });
    };

    if (rejectedSectionsIndexes) {
      const nextStepIndex = currentStep + 1;
      const stepsLength = rejectedSectionsIndexes.length;

      if (nextStepIndex < stepsLength) {
        setCurrentStep(nextStepIndex);
        setCurrentSection(rejectedSectionsIndexes[nextStepIndex]);
        setInitialStep(rejectedSectionsIndexes[nextStepIndex]);
      } else {
        await finish();
      }
    }
    if (!rejectedSectionsIndexes) {
      if (currentSection + 1 < stepsCount) {
        setCurrentSection(currentSection + 1);
        if (currentSection >= initialStep) setInitialStep(currentSection + 1)
      }
      else await finish();
    }

    if (!appContainer) appContainer = document.getElementById('app-container');
    appContainer.parentNode.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const loading = (
    isFetching || !questionnaire || questionnaire.investorQuestionSectionTypeId !== id
  );
  return (
    <div>
      {!loading && (
        <QuestionnaireView
          adminEditView={adminEditView}
          referralView={referralView}
          questionnaire={questionnaire}
          currentSection={currentSection}
          currentStep={initialStep}
          onNext={onNext}
          onBack={onBack}
          rejectedQuestions={rejectedQuestions}
          setCurrentSection={setCurrentSection}
          setCurrentStep={setInitialStep}
          disabled={investor?.accountStatus !== 'ACTIVE'}
          disabledNext={loader}
        />
      )}

      <SyncIndicator visible={loader}>{t('Processing')}...</SyncIndicator>
      <Toast toast={toast} removeToast={() => setToast('')}/>
    </div>
  );
};

export * from './View';
