import React from 'react';
import { SyncIndicator } from '@aragon/ui';
import { Loading, ProfileDocuments as RenderProfileDocuments } from '../../../../components';
import { useQuery } from 'react-query';
import { kycApi } from '../../../../api/kyc';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

export const ProfileDocuments = ({ referralView = false, referralId }) => {
  const { referralToken } = useSelector((state) => state.user);
  const { t } = useTranslation();
  const {
    data: requestedDocs,
    refetch,
    isLoading: isLoading1
  } = useQuery(['getRequestedDocuments', referralToken], referralView ? () => kycApi.getReferralRequestedDocuments(referralToken) : kycApi.getRequestedDocuments, {
    enabled: (referralView && !!referralToken) || !referralView,
    refetchIntervalInBackground: 60000,
    refetchInterval: 5000,
    refetchOnMount: true,
    refetchOnWindowFocus: true,
  });
  const { data: questsDocs, isLoading: isLoading2 } = useQuery(
    ['getQuestsDocuments', referralToken],
    referralView ? () => kycApi.getReferralQuestsDocuments(referralToken) : kycApi.getQuestsDocuments,
    {
      enabled: (referralView && !!referralToken) || !referralView,
      refetchIntervalInBackground: 60000,
      refetchInterval: 5000,
      refetchOnMount: true,
      refetchOnWindowFocus: true,
    }
  );

  return (
    <div>

      {(!isLoading1 && !isLoading2) ? (
        <RenderProfileDocuments
          documents={requestedDocs?.data}
          questsDocs={questsDocs?.data}
          refetchDocs={refetch}
          viewMode={referralView ? 'referral' : 'investor'}
          referralId={referralId}
          referralToken={referralToken}
        />
      ) : <Loading/>}

      <SyncIndicator visible={isLoading1 || isLoading2}>{t('Loading')}...</SyncIndicator>
    </div>
  );
};
