import React, { useContext } from 'react';
import { ProfileReferralsView } from '../../../common/organisms';
import { useQuery } from 'react-query';
import { investorApi, investorKeys } from '../../../../api/investor';
import { MessageContext } from '../../../../app/MessageProvider';
import { useTranslation } from 'react-i18next';

export const ProfileReferrals = () => {
  const { t } = useTranslation();
  const messageApi = useContext(MessageContext);

  const refferalsPyramid = useQuery({
    queryKey: investorKeys.getRefferalAccountsPyramid,
    queryFn: investorApi.getMyRefferalAccountsPyramid,
    select: data1 => data1.data,
    onError: () => messageApi.error(t('Referrals fetch error'))
  });
  const reccomenders = useQuery({
    queryKey: investorKeys.getRecommendersAccounts,
    queryFn: investorApi.getMyRecommenderAccounts,
    select: data1 => data1.data,
    onError: () => messageApi.error(t('Recommenders fetch error'))
  });
  const agents = useQuery({
    queryKey: investorKeys.getAgentAccounts,
    queryFn: investorApi.getMyAgentAccounts,
    select: data1 => data1.data,
    onError: () => messageApi.error(t('Agents fetch error'))
  });


  return (
    <ProfileReferralsView
      refferalsPyramid={{ list: refferalsPyramid.data, error: refferalsPyramid.isError, isLoading: refferalsPyramid.isLoading }}
      recommeders={{ list: reccomenders.data, error: reccomenders.isError, isLoading: reccomenders.isLoading }}
      agents={{ list: agents.data, error: agents.isError, isLoading: agents.isLoading }}
    />
  );
};
